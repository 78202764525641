import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { PlatformLocation } from '@angular/common';

@Injectable()
export class AppInfoService {

    public RiverEntity: any = {};
    public currentUser: any = {
        id: 1,
        color: "red",
        subUsers: []
    };
    public homeTabs = {
        dashboard: "1",
        scheduler: "2",
        task: "3",
        report: "4",
    }

    public chatMessageEnum = {
        User: 0,
        Group: 1,
        NotChat: 2
    }

    public parameters: any;

    public chatBoxMessageOpen: any = false;
    public chatSelectedUserGlobal: any = null;

    constructor(private platformLocation: PlatformLocation) { }

    public get title() {
        return 'RIVER';
    }

    public get appUrl() {
        return/* "http://localhost:53007/";//"http://localhost:53007/";//*/(this.platformLocation as any).location.origin + "/";
    }

    public get httpOptions() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8'
            })
        };
    }

    public get httpOptionsFile() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'multipart/form-data'
            })
        };
    }


   
}
