import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { CdkDragDrop, CdkDragStart, CdkDragEnd, moveItemInArray, copyArrayItem, transferArrayItem } from '@angular/cdk/drag-drop';

import { ComponentService } from '../component.service';
import { EventEmitterService, AppInfoService } from '../../shared/services';

import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';

@Component({
    selector: 'grid-component',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.css']
})
export class DxGridComponent implements ComponentParameters, OnInit {
    @Input() data: any;
    @Input() isEdit: any;
    @Input() lang: string;
    @Input() anotherPopupId: number = 0;

    borderTypes = [{ "name": "Düz Çizgi", "value": "ridge" }, { "name": "Nokta", "value": "dotted" }, { "name": "Çizgili", "value": "dashed" }]

    showDisplay: any = "block";

    onGridDragClass: string = "active";
    actions = [
        {
            icon: "edit",
            alignment: "left",
            hint: "Edit",
            action: "edit"
        },
        {
            icon: "trash",
            alignment: "right",
            hint: "Delete",
            action: "delete"
        },
    ];

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;

    rowsPopup: boolean = false;
    rowsPermissionPopup: boolean = false;
    selectedRowsPermission: any;
    languageLocal: any = "";

    gridStyle = { "marginTop": "", "border": "" };
    constructor(private componentService: ComponentService, private eventEmitterService: EventEmitterService, public appInfo: AppInfoService, public changeDetectorRef: ChangeDetectorRef) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
        this.languageLocal = this.componentService.getLanguageSource(); //language
        this.setLanguageToDatas()

        this.componentService.createCdkList(this.anotherPopupId);
    }
    //
    ngOnInit() {
        this.data.uuid = this.componentService.createuuid();
        if (!this.isEdit) this.checkUserAuthorization();

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (!this.data.hide && this.data.outsideVisible ? "block" : "none") : "block";

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (!this.data.hide && this.data.outsideVisible ? "block" : "none") : "block";
                 
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });

    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (!this.data.hide && this.data.outsideVisible ? "block" : "none") : "block";
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    checkUserAuthorization() {
        for (let i = 0; i < this.data.rows.length; i++) {
            let userId = this.appInfo.currentUser.id;
            let rGroupIndex = this.data.rows[i].permissions.readonlyGroup.indexOf(userId);
            let rUsersIndex = this.data.rows[i].permissions.readonlyUsers.indexOf(userId);
            let hGroupIndex = this.data.rows[i].permissions.hideGroup.indexOf(userId);
            let hUsersIndex = this.data.rows[i].permissions.hideUsers.indexOf(userId);

            let isHide = hGroupIndex != -1 || hUsersIndex != -1;
            let isReadonly = rGroupIndex != -1 || rUsersIndex != -1;

            if (isHide) this.data.rows[i].hide = true;
            else this.data.rows[i].hide = false;
            if (isReadonly) this.data.rows[i].readonly = true;
            else this.data.rows[i].readonly = false;
        }
    }


    itemClick(e, item, list) {
        if (e.itemData.action == "edit") {
            this.eventEmitterService.showPopop(item.uuid);
            //item.showEditPop = true;
        }
        else if (e.itemData.action == "delete") {
            let msg = item.type == "grid" || item.type == "panel" ? "<i>" + this.languageLocal.theseComponentElementsDeleted + "</i>" : "<i>" + this.languageLocal.messageSure+"</i>"
            let result = confirm(msg, this.languageLocal.warning);
            result.then((dialogResult) => {
                if (dialogResult) this.removeField(item, list);
            });
        }
    }


    removeField(item, list) {
        list.splice(list.indexOf(item), 1);
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        this.showToast(this.languageLocal.deleted, "success");
    }


    dragStart() {
        this.onGridDragClass = "active";
    }

    onDragEnded(event: CdkDragEnd): void {

        //event.source._dragRef.reset();
    }
    cdkGridDrop(event: CdkDragDrop<string[]>, list?: any[]) {

        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {

            let type = event.previousContainer.element.nativeElement.dataset.type;
            if (type && type == "copy") {
                let field: any = event.previousContainer.data[event.previousIndex];
                if (field.type != "grid") {
                    localStorage.setItem("currentFieldGrid", JSON.stringify(event.previousContainer.data[event.previousIndex]));
                    let currentFieldGrid: any = JSON.parse(localStorage.getItem("currentFieldGrid"));
                    localStorage.removeItem("currentFieldGrid");
                    //localStorage.removeItem(key);localStorage.clear();
                    let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
                    currentFieldGrid.id = this.componentService.createId(currentFieldGrid.type, currentFormSelected.components, this.anotherPopupId);
                    list.splice(event.currentIndex, 0, currentFieldGrid);
                }
                else {
                    this.showToast(this.languageLocal.invalidTarget, "warning");
                }

                /*copyArrayItem(event.previousContainer.data,
                  event.container.data,
                  event.previousIndex,
                  event.currentIndex);*/
            }
            else {
                transferArrayItem(event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex);
            }
        }
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        this.componentService.createCdkList(this.anotherPopupId);
        this.onGridDragClass = "";
    }


    addRow(count) { 
        let cols = [];
        for (let i = 0; i < count; i++) {
            var col = {
                size: 1,
                components: []
            }
            cols.push(col)
        }
        let permission = { "readonlyGroup": [], "readonlyUsers": [], "hideGroup": [], "hideUsers": [] }
        let row = { permissions: permission, cols: cols };
        this.data.rows.push(row);
        this.componentService.createCdkList(this.anotherPopupId);
        //this.eventEmitterService.layoutChange();
    }

    deleteRow(index) {
        if (index > 0) this.data.rows.splice(index, 1);
    }

    addCol(row) {
        var col = {
            size: 1,
            components: []
        }
        row.cols.push(col);
        this.componentService.createCdkList( this.anotherPopupId);
        //this.eventEmitterService.layoutChange();
    }

    deleteCol(row, index) {
        row.cols.splice(index, 1);
    }

    addPermission(row) {
        this.selectedRowsPermission = row.permissions;
        this.rowsPermissionPopup = true;
    }

    showToast(message, status) {
        notify(message, status, 3000);
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // Tüm charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    setLanguageToDatas() {
        this.borderTypes = [
            { "name": this.languageLocal.ridge/* "Düz Çizgi"*/, "value": "ridge" },
            { "name": this.languageLocal.dotted/*"Nokta"*/, "value": "dotted" },
            { "name": this.languageLocal.dashed/*"Çizgili"*/, "value": "dashed" }
        ];
        this.actions = [
            {
                icon: "edit",
                alignment: "left",
                hint: this.languageLocal.edit /*"Edit"*/,
                action: "edit"
            },
            {
                icon: "trash",
                alignment: "right",
                hint: this.languageLocal.delete /* "Delete"*/,
                action: "delete"
            },
        ];
    }

}
