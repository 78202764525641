import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { EventEmitterService } from "../../shared/services/event-emitter.service";

import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';
import { FormModel } from '../../shared/models/form-model';
import { ComponentService } from "../component.service";
import { AppInfoService, FormService, GenericService, TaskService } from '../../shared/services';
import { CurrentFormDataModel } from '../../shared/models/form-data-model';
import { InstanceService } from '../../shared/services/data/instance.service';
import { Subscription } from 'rxjs';

enum FormType {
    Default = 1,
    Menu = 2
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dx-render',
    templateUrl: './formrender.html',
    styleUrls: ['./formrender.css']
})
export class FormRender implements OnInit, OnDestroy {
    @Input() form: any;
    @Input() isEdit: boolean;
    @Input() isStarted: boolean;
    @Input() anotherPopupId: number = 0;
    @Input() formType: any = FormType.Default;


    // @Input() selectedSchemaId: boolean;

    /*formStatus =
    {
        Processing : 1, //while yes
        SaveAndSend : 2, //while break
        Approve : 3, // while break
        Disapprove : 4, //while break
        Returned : 5, //while yes
        Cancelled : 6 // while break
    }*/


    languageCode: string;
    forms: any;

    colCountByScreen: Object;
    currentFormData = [];
    buttonActions: any = [];
    selectedstatus: number = 1;

    currentFormStatus: any;


    isFormEditable: boolean = false;
    currentProcessFormId: number = 0;
    prevUserId: number = 0;

    //rejectReason: string;
    //showRejectReasonPopup: boolean = false;
    isMenuForm: boolean = false;
    constructor(public formService: FormService,
        private appInfo: AppInfoService,
        private componentService: ComponentService,
        private eventEmitterService: EventEmitterService,
        public taskService: TaskService,
        public genericService: GenericService,
        public changeDetectorRef: ChangeDetectorRef,
        public instanceService: InstanceService) {
        //this.isSendForm = false;
        if (typeof this.eventEmitterService.subsSendForm == "undefined") {
            this.eventEmitterService.subsSendForm = this.eventEmitterService.invokeSendFormFunction.subscribe(sub => {
                this.sendFormData(sub.status, sub.name, sub.reason, sub.anotherPopupIdLocal);
            });
        }

    }
    deletedFileIds: any = [];
    invokeChangeEventLocal: Subscription;
    invokeFileDeleteLocal: Subscription;
    currentFormSelectedGlobal;

    ngOnInit() {
        if (typeof this.form != "undefined") {

            if (this.appInfo.currentUser.deflang) this.languageCode = this.appInfo.currentUser.deflang.replace(/-/gi, '');
            else this.languageCode = "trTR";

            this.currentProcessFormId = this.form.processFormId;

            this.renderForm();

            this.invokeChangeEventLocal = this.eventEmitterService.invokeColumnSumFunction.subscribe(sub => {
                try {
                    this.currentFormSelectedGlobal = this.componentService.currentFormSelected(this.anotherPopupId);
                    let curentForm = this.currentFormSelectedGlobal.find(x => x.id == sub.id);
                    if (curentForm || sub.id == "sys_re_set_val") {
                        curentForm.value = sub.sum;
                    }

                } catch (e) {

                }
            });

            this.invokeFileDeleteLocal = this.eventEmitterService.invokeDeletedFileIdsFunction.subscribe(sub => {
                try {
                    this.deletedFileIds = sub
                } catch (e) {

                }
            });

        }

    }

    ngOnDestroy() {
        console.log("render DESTROYED")
        try { this.invokeChangeEventLocal.unsubscribe(); } catch (e) { }
        //this.invokeFileDeleteLocal.unsubscribe();
        try { this.eventEmitterService.clearDeleteFileIds(); } catch (e) { }
    }

    locationButtons: any = [];
    paymentButtons: any = [];
    zoomButtons: any = [];
    //liveWebButton: any;

    renderForm() {

        if (typeof this.form.formJson !== "undefined") {
            this.prevUserId = this.form.prevUserId;
            let formJson = JSON.parse(this.form.formJson);
            let formData = JSON.parse(this.form.formValues);

            this.componentService.checkUserAuthorization(this.form.readonly, formJson.components, this.appInfo.currentUser.id, this.anotherPopupId);

            //  this.componentServi ce.hideComponent List(formJson.components);
            //formJson.components = formJson.components.filter(f => !f.hide);

            let currentFormSelected;
            if (this.anotherPopupId == 0) {
                this.componentService.currentProcessFormId = this.form.processFormId;
                this.componentService.currentForm = formJson;
                this.componentService.currentForm.isReadonly = this.form.readonly;
                this.componentService.currentForm.formNo = this.form.formNo || 0;
                currentFormSelected = this.componentService.currentForm;
            } else {
                let childObj = this.componentService.childPopups.find(x => x.id == this.anotherPopupId);
                childObj.currentForm = formJson;
                childObj.isReadonly = this.form.readonly;
                childObj.formNo = this.form.formNo || 0;
                childObj.currentProcessFormId = this.form.processFormId;
                currentFormSelected = childObj.currentForm;
            }

            this.componentService.currentUserStaus = this.form.userStatus || 0;
            this.setStatus(this.form.userStatus);
            this.componentService.userHasApprove = this.form.userStatus == this.componentService.userStatus.FinalApprove || this.form.userStatus == this.componentService.userStatus.InterimApprove;
            this.componentService.setFormValues(currentFormSelected.components, formData, this.anotherPopupId);
            this.executeExp(this.anotherPopupId);
            this.invokeFunctions();

            let menus = this.componentService.getComponentsByType("menu", this.anotherPopupId);
            if (menus && menus.length > 0) {
                this.isMenuForm = true;
            }
            else {
                this.isMenuForm = false;
            }

            let curentFormFiles = this.componentService.getFilesComponentList(currentFormSelected.components, this.anotherPopupId);
            let _idArray = [];
            curentFormFiles.forEach(file => {
                try {
                    file.value.forEach(fileVal => {
                        try {
                            let compSend = {
                                id: fileVal,
                                componentId: file.id,
                                type: file.type,
                                row: -1,
                                column: "-1"
                            };
                            _idArray.push(compSend);
                        } catch (ex) { }//   src: 'data:' + fileType + ';base64,' + base64BlobDat a,
                    });
                } catch (ex) { }
            });

            let datagridComps = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
            if (this.currentProcessFormId != 0) {

                let datagridCompsFilter = datagridComps.filter(x => x.type == "datagrid");
                if (datagridCompsFilter.length > 0) {
                    datagridCompsFilter.forEach(_dataGrid => {
                        let _row = 0;
                        let datagridHeaderFiles = _dataGrid.headers.filter(x => x.type == "file" || x.type == "picturebox");

                        _dataGrid.value.forEach(_dataGridValue => {
                            datagridHeaderFiles.forEach(_headerFile => {

                                let IdArrayLast = [];
                                let _column = _headerFile.field;
                                let valArray = _dataGridValue[_column];
                                if (typeof valArray != "undefined" && valArray != "") {

                                    valArray.forEach(valId => {
                                        IdArrayLast.push(valId);
                                    });

                                    let compSend = {
                                        id: IdArrayLast,
                                        componentId: _dataGrid.id,
                                        type: _dataGrid.type,
                                        row: _row,
                                        column: _column//"-1"
                                    };

                                    //if (_dataFile.file.size != -1)
                                    _idArray.push(compSend);

                                }
                                else if (_headerFile.type == "picturebox")
                                    this.datagridPicturebox(datagridComps, _dataGrid, _column, _row);

                            });
                            _row++;
                        });

                    });
                }

                if (_idArray.length > 0) {
                    let _idArrayInt = _idArray.map(ele => ele.id);
                    let _idIntLast = [];
                    _idArrayInt.forEach(_idObj => {
                        if (Array.isArray(_idObj)) {
                            _idObj.forEach(_idObjTemp => {
                                _idIntLast.push(_idObjTemp);
                            });
                        }
                        else
                            _idIntLast.push(_idObj);
                    });
                    if (this.formType == FormType.Default) {
                        this.getFormFilesService(_idIntLast, datagridComps);
                    } else {
                        this.getFormFilesServiceMenu(_idIntLast, datagridComps);
                    }


                }
            }
            else {

                if (_idArray.length > 0) {
                    let _idArrayInt = _idArray.map(ele => ele.id);
                    let _idIntLast = [];
                    _idArrayInt.forEach(_idObj => {
                        if (Array.isArray(_idObj)) {
                            _idObj.forEach(_idObjTemp => {
                                _idIntLast.push(_idObjTemp);
                            });
                        }
                        else
                            _idIntLast.push(_idObj);
                    });

                    if (this.formType == FormType.Default) {
                        this.getFormFilesService(_idIntLast, datagridComps);
                    } else {
                        this.getFormFilesServiceMenu(_idIntLast, datagridComps);
                    }

                }

            }

            let curentFormButtons = this.componentService.getButtonComponentList(currentFormSelected.components, this.anotherPopupId);

            this.locationButtons = [];
            curentFormButtons.forEach(obj => {

                obj.buttons.forEach(objChild => {

                    if (objChild.isLocation)
                        this.locationButtons.push({ buttonCompId: obj.id, button: objChild });
                });
            });

            if (this.locationButtons.length > 0)
                this.getGeoLocation();


            this.getFormPreferences(currentFormSelected, formData);

        }
    }

    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace).replace(/[\u200B-\u200D\uFEFF]/g, '');
    }

    datagridPicturebox(datagridComps, _dataGrid, _column, _row) {
        // _dataGrid.value


        let _dataValue = _dataGrid.value[_row];
        let _header = _dataGrid.headers.find(f => f.field == _column);
        let _pictureboxChangeHeader = _dataGrid.sqlExpressions.find(x => x.field == _column);
        let _connectionStringId = 0
        if (_pictureboxChangeHeader.connectionStringId) _connectionStringId = _pictureboxChangeHeader.connectionStringId;

        let replacedQuery = this.componentService.cloneModel(_pictureboxChangeHeader.query);
        let fields = Object.keys(_dataValue);
        fields.forEach(f => {
            let pref = "{" + f + "}";
            let value = _dataValue[f];
            replacedQuery = this.replaceAll(replacedQuery, pref, value);
        });


        //   this.outOfDatagridGetSql(replacedQuery, /*rowInde x,*/ exp.connectionStringId, exp.field, /*newRow,*/ field, data);

        let sqlExpressionTagControl = replacedQuery.match(/\{(\w)+\}/ig);
        //let ifControl = false;
        if (replacedQuery != "" && _connectionStringId != 0 && (sqlExpressionTagControl == null || typeof sqlExpressionTagControl == "undefined" || sqlExpressionTagControl.length == 0 || (
            (sqlExpressionTagControl.length == 1 && sqlExpressionTagControl[0] == "{CURRENTUSERID}")
        ))
        ) {
            this.genericService.executeQuerySecurited(replacedQuery, _connectionStringId).subscribe((result: any) => {
                if (result && result.length) {
                    let _value = "";
                    let keys = Object.keys(result[0]);
                    if (keys.length > 0) {
                        let finalData = [];
                        keys.forEach(f => {
                            finalData.push(result[0][f]);
                        });
                        _value = finalData.join(",");
                    }

                    // let headerGeneral = _dataGrid.headers.find(f => f.field == fieldGeneral);
                    // let generalName = headerGeneral ? headerGeneral.caption : "";
                    let doc: any = {};
                    let document: any = {
                        base64BlobData: _value,
                        name: "",
                        fileType: "image/png",
                        extension: "png",
                        size: -1,
                        isImg: true
                    };
                    doc.row = _dataValue.ID;
                    doc.file = document;

                    let docsIndex = _header.files.findIndex(f => f.row == _dataValue.ID);
                    if (docsIndex != -1)
                        _header.files[docsIndex] = doc;
                    else
                        _header.files.push(doc);

                }
            });
        }
        //datagridComps 
        //header.dataGridSettings.query, /*row.rowInde x,*/ header.dataGridSettings.connectionStringId
    }

    getFormPreferences(currentFormSelected, formData) {

        let dataGridEventList = []
        let preferencesMultiElementFinish = 0;
        let preferencesFinish = 0;
        if ((this.anotherPopupId == 0 && currentFormSelected.formNo == 0) || (this.anotherPopupId != 0)) {


            let preferencesForm = currentFormSelected.preferences;

            if (typeof preferencesForm == "undefined")
                preferencesForm = { formOpenedEvents: [], formOpenedMultiElementEvents: [] };

            preferencesForm.formOpenedEvents = preferencesForm.formOpenedEvents && Array.isArray(preferencesForm.formOpenedEvents) ? preferencesForm.formOpenedEvents : [];
            preferencesForm.formOpenedMultiElementEvents = preferencesForm.formOpenedMultiElementEvents && Array.isArray(preferencesForm.formOpenedMultiElementEvents) ? preferencesForm.formOpenedMultiElementEvents : [];

            if (preferencesForm) {
                if (preferencesForm.formOpenedEvents.length > 0) {

                    let preferencesArray = [];
                    preferencesForm.formOpenedEvents.forEach(_formOpenedEvent => {
                        let sqlExpressionTagControl = _formOpenedEvent.query.match(/\{(\w)+\}/ig);

                        if (_formOpenedEvent.query != "" && (sqlExpressionTagControl == null || typeof sqlExpressionTagControl == "undefined" || sqlExpressionTagControl.length == 0 || (
                            (sqlExpressionTagControl.length == 1 && sqlExpressionTagControl[0] == "{CURRENTUSERID}")
                        ))
                        ) {
                            this.genericService.executeQuerySecurited(_formOpenedEvent.query, _formOpenedEvent.connectionId).subscribe((result: any) => {

                                if (result && result.length) {

                                    let keys = Object.keys(result[0]);
                                    let formDataTemp = formData.find(x => x["key"] == _formOpenedEvent.elementId);
                                    if (formDataTemp.componentType == "selectbox") {
                                        formDataTemp.value = result[0][keys[0]];
                                    }
                                    else if (formDataTemp.componentType == "datagrid") {

                                        dataGridEventList.push({ elementId: _formOpenedEvent.elementId, data: result })

                                    }
                                    else {
                                        let finalData = [];
                                        if (keys.length > 0) {
                                            keys.forEach(_f => {
                                                finalData.push(result[0][_f]);
                                            });
                                        }
                                        formDataTemp.value = finalData.join(", ");
                                    }

                                }
                                preferencesFinish = preferencesFinish + 1;
                            });
                        }
                        else
                            preferencesFinish = preferencesFinish + 1;
                    });


                }



                if (preferencesForm.formOpenedMultiElementEvents.length > 0) {

                    let preferencesArray = [];
                    preferencesForm.formOpenedMultiElementEvents.forEach(_formOpenedMultiElementEvent => {
                        let sqlExpressionTagControl = _formOpenedMultiElementEvent.query.match(/\{(\w)+\}/ig);

                        if (_formOpenedMultiElementEvent.query != "" && (sqlExpressionTagControl == null || typeof sqlExpressionTagControl == "undefined" || sqlExpressionTagControl.length == 0 || (
                            (sqlExpressionTagControl.length == 1 && sqlExpressionTagControl[0] == "{CURRENTUSERID}")
                        ))
                        ) {
                            this.genericService.executeQuerySecurited(_formOpenedMultiElementEvent.query, _formOpenedMultiElementEvent.connectionId).subscribe((result: any) => {

                                if (result && result.length) {

                                    let keys = Object.keys(result[0]);

                                    keys.forEach((keyEL: any) => {
                                        let formDataTemp = formData.find(x => x["key"] == keyEL);
                                        if (formDataTemp.componentType == "selectbox") {
                                            formDataTemp.value = result[0][keyEL];
                                        }
                                        else if (formDataTemp.componentType == "datagrid") {

                                            dataGridEventList.push({ elementId: keyEL, data: [result[0][keyEL]] })
                                        }
                                        else {
                                            formDataTemp.value = result[0][keyEL];
                                        }

                                    });


                                }
                                preferencesMultiElementFinish = preferencesMultiElementFinish + 1;
                            });
                        }
                        else
                            preferencesMultiElementFinish = preferencesMultiElementFinish + 1;
                    });

                }


                if (preferencesForm.formOpenedMultiElementEvents.length > 0 || preferencesForm.formOpenedEvents.length > 0) {
                    let tThis = this;

                    let checkPrefExist = setInterval(function () {
                        if (preferencesFinish == preferencesForm.formOpenedEvents.length && (preferencesMultiElementFinish == preferencesForm.formOpenedMultiElementEvents.length)) {
                            clearInterval(checkPrefExist);
                            tThis.componentService.setFormValues(currentFormSelected.components, formData, tThis.anotherPopupId);
                            try { tThis.changeDetectorRef.detectChanges(); } catch (ex) { }


                            // selectboxChangeEvent 'ın Open event setFormValues metodu ile datagrid value değerini doldurduktan sonra çalışması için yapıldı.
                            dataGridEventList.forEach((el: any) => {
                                tThis.eventEmitterService.selectboxChangeEvent("", el.elementId, "", el.data, false);
                            })

                        }
                    }, 300);
                }














            }
        }

    }

    getFormFilesService(_idIntLast, datagridComps) {

        this.instanceService.getFormFilesData(_idIntLast).subscribe((s: any) => {
            let currentComp;

            let componentTempIds = [];
            if (s.files.length > 0) {
                s.files.forEach(currentCompReturned => {

                    currentComp = datagridComps.find(x => x.id == currentCompReturned.componentId);
                    let componentType = currentComp.type;

                    if (componentType == "datagrid") {

                        let currentCompHeaderColumn = currentComp.headers.find(x => x.field == currentCompReturned.columnId);

                        let document: any = {
                            id: currentCompReturned.id,
                            base64BlobData: currentCompReturned.fileData,
                            name: currentCompReturned.fileName,
                            fileType: currentCompReturned.fileType,
                            extension: currentCompReturned.fileExtension,
                            size: currentCompReturned.fileSize,
                            isImg: currentCompReturned.isImg,
                            description: currentCompReturned.description,
                            dmsPath: currentCompReturned.dmsPath
                        };

                        if (typeof document.description == "undefined") {
                            document.description = ""
                        }

                        let doc: any = {};
                        doc.row = currentCompReturned.rowId;
                        doc.file = document;
                        currentCompHeaderColumn.files.push(doc);

                    }
                    else {

                        if (componentTempIds.indexOf(currentCompReturned.componentId) == -1) {
                            currentComp.valueTemp = [];
                            componentTempIds.push(currentCompReturned.componentId);
                        }

                        let document: any = {
                            id: currentCompReturned.id,
                            base64BlobData: currentCompReturned.fileData,
                            name: currentCompReturned.fileName,
                            fileType: currentCompReturned.fileType,
                            extension: currentCompReturned.fileExtension,
                            size: currentCompReturned.fileSize,
                            isImg: currentCompReturned.isImg,
                            description: currentCompReturned.description,
                            dmsPath: currentCompReturned.dmsPath
                        };

                        if (typeof document.description == "undefined") {
                            document.description = ""
                        }

                        if (componentType == "picturebox") {
                            if (document.base64BlobData && document.base64BlobData != "")
                                document.src = 'data:' + document.fileType + ';base64,' + document.base64BlobData;
                            else
                                document.src = '/assets/img/default-placeholder.png';
                        }

                        if (componentType == "signature") {
                            document.src = 'data:' + document.fileType + ';base64,' + document.base64BlobData;
                        }

                        currentComp.valueTemp.push(document);

                    }

                });
            }

            this.changeDetectorRef.detectChanges();

        });
    }

    getFormFilesServiceMenu(_idIntLast, datagridComps) {

        this.formService.getFormFilesData(_idIntLast).subscribe((s: any) => {
            let currentComp;

            let componentTempIds = [];
            if (s.files.length > 0) {
                s.files.forEach(currentCompReturned => {

                    currentComp = datagridComps.find(x => x.id == currentCompReturned.componentId);
                    let componentType = currentComp.type;

                    if (componentType == "datagrid") {

                        let currentCompHeaderColumn = currentComp.headers.find(x => x.field == currentCompReturned.columnId);

                        let document: any = {
                            id: currentCompReturned.id,
                            base64BlobData: currentCompReturned.fileData,
                            name: currentCompReturned.fileName,
                            fileType: currentCompReturned.fileType,
                            extension: currentCompReturned.fileExtension,
                            size: currentCompReturned.fileSize,
                            isImg: currentCompReturned.isImg,
                            description: currentCompReturned.description
                        };

                        if (typeof document.description == "undefined") {
                            document.description = ""
                        }

                        let doc: any = {};
                        doc.row = currentCompReturned.rowId;
                        doc.file = document;
                        currentCompHeaderColumn.files.push(doc);

                    }
                    else {

                        if (componentTempIds.indexOf(currentCompReturned.componentId) == -1) {
                            currentComp.valueTemp = [];
                            componentTempIds.push(currentCompReturned.componentId);
                        }

                        let document: any = {
                            id: currentCompReturned.id,
                            base64BlobData: currentCompReturned.fileData,
                            name: currentCompReturned.fileName,
                            fileType: currentCompReturned.fileType,
                            extension: currentCompReturned.fileExtension,
                            size: currentCompReturned.fileSize,
                            isImg: currentCompReturned.isImg,
                            description: currentCompReturned.description
                        };

                        if (typeof document.description == "undefined") {
                            document.description = ""
                        }

                        if (componentType == "picturebox") {
                            if (document.base64BlobData && document.base64BlobData != "")
                                document.src = 'data:' + document.fileType + ';base64,' + document.base64BlobData;
                            else
                                document.src = '/assets/img/default-placeholder.png';
                        }

                        if (componentType == "signature") {
                            document.src = 'data:' + document.fileType + ';base64,' + document.base64BlobData;
                        }

                        currentComp.valueTemp.push(document);

                    }

                });
            }

            this.changeDetectorRef.detectChanges();

        });
    }

    invokeFunctions() {

        if (typeof this.eventEmitterService.subsFormChange == "undefined") {
            this.eventEmitterService.invokeFormChangesFunction.subscribe((s) => {
                this.currentFormSelectedGlobal = this.componentService.currentFormSelected(s.anotherPopupIdLocal);
                this.executeExp(s.anotherPopupIdLocal);
                this.componentService.evulateExpretion(this.currentFormSelectedGlobal.expretion);
            });
        }
        //if (typeof this.eventEmitterService.subsMenuSubmit == "undefined") {
        //    this.eventEmitterService.subsMenuSubmit = this.eventEmitterService.invokeMenuSubmitFunction.subscribe((sub: any) => {
        //        this.sendFormData(sub.status, sub.name, "");
        //    });
        //}

    }

    setStatus(userStatus) {
        if (userStatus == this.componentService.userStatus.DefaultStatus) {
            this.buttonActions = [
                { "status": this.componentService.formStatus.Processing, "text": "Kaydet", "type": "default" },
                { "status": this.componentService.formStatus.SaveAndSend, "text": "Kaydet ve Gönder", "type": "success" }
            ];
        }
        else if (userStatus == this.componentService.userStatus.InterimApprove) {
            this.buttonActions = [
                { "status": this.componentService.formStatus.Processing, "text": "Kaydet", "type": "default" },
                { "status": this.componentService.formStatus.SaveAndSend, "text": "Kaydet ve Gönder", "type": "success" },
                //{ "status": this.componentService.formStatus.Returned, "text": "Geri Gönder", "type": "danger" },
                { "status": this.componentService.formStatus.Disapprove, "text": "Reddet", "type": "danger" },
                { "status": this.componentService.formStatus.DisapproveTerminate, "text": "Reddet ve Bitir", "type": "danger" }
            ];
        }
        else if (userStatus == this.componentService.userStatus.FinalApprove) {
            this.buttonActions = [
                { "status": this.componentService.formStatus.Processing, "text": "Kaydet", "type": "default" },
                { "status": this.componentService.formStatus.Approve, "text": "Onayla", "type": "success" },
                { "status": this.componentService.formStatus.Disapprove, "text": "Reddet", "type": "danger" }
            ];
        }
        else if (userStatus == this.componentService.userStatus.Completed) {
            this.buttonActions = [
                { "status": this.componentService.formStatus.Processing, "text": "Kaydet", "type": "default" },
                { "status": this.componentService.formStatus.Completed, "text": "Bitir", "type": "success" }
            ];
        }
        else {
            this.buttonActions = [
                { "status": this.componentService.formStatus.Processing, "text": "Kaydet", "type": "default" },
                { "status": this.componentService.formStatus.SaveAndSend, "text": "Kaydet ve Gönder", "type": "success" }
            ];
        }
    }

    screen(width) {
        return width < 720 ? "sm" : "md";
    }

    executeExp(anotherPopupIdLocal) {

        this.currentFormSelectedGlobal = this.componentService.currentFormSelected(anotherPopupIdLocal);
        if (this.currentFormSelectedGlobal.expression) eval(this.currentFormSelectedGlobal.expression)
    }

    getById(id, anotherPopupIdLocal) {

        this.currentFormSelectedGlobal = this.componentService.currentFormSelected(anotherPopupIdLocal);
        return this.componentService.getById(id, this.currentFormSelectedGlobal.components, anotherPopupIdLocal);
    }

    colSum(id, colm, anotherPopupIdLocal) {

        this.currentFormSelectedGlobal = this.componentService.currentFormSelected(anotherPopupIdLocal);

        return this.componentService.colSum(id, colm, this.currentFormSelectedGlobal.components, anotherPopupIdLocal);
    }


    setValue(selectboxId, elementId, key, anotherPopupIdLocal) {

        this.currentFormSelectedGlobal = this.componentService.currentFormSelected(anotherPopupIdLocal);

        return this.componentService.setValue(selectboxId, elementId, key, this.currentFormSelectedGlobal.components, anotherPopupIdLocal);
    }

    buttonNameGlobal: any = "";

    sendFormData(status, name, reason, anotherPopupIdLocal) {

        this.eventEmitterService.setHomePopupVisibleFunc({ isShow: true });

        this.currentFormSelectedGlobal = this.componentService.currentFormSelected(anotherPopupIdLocal);
        let curentFormButtons = this.componentService.getButtonComponentList(this.currentFormSelectedGlobal.components, anotherPopupIdLocal);

        this.locationButtons = [];
        curentFormButtons.forEach(obj => {

            obj.buttons.forEach(objChild => {

                if (objChild.isLocation)
                    this.locationButtons.push({ buttonCompId: obj.id, button: objChild });

                if (objChild.isPayment)
                    this.paymentButtons.push({ buttonCompId: obj.id, button: objChild });

                if (objChild.isZoom)
                    this.zoomButtons.push({ buttonCompId: obj.id, button: objChild });


            });

        });


        this.currentFormSelectedGlobal.components.forEach(el => {
            this.eventEmitterService.saveSystemInfoEvent(el.id, el.value);
        });

        this.currentFormData = [];
        this.buttonNameGlobal = name;
        this.createValuesData(this.currentFormSelectedGlobal.components);

        let datagridComps = this.componentService.getComponentList(this.currentFormSelectedGlobal.components, anotherPopupIdLocal);

        let curentFormFiles = this.componentService.getFilesComponentList(this.currentFormSelectedGlobal.components, anotherPopupIdLocal);
        let objArray = [];

        let descriptionUpdate = [];
        curentFormFiles.forEach(file => {
            if (typeof file.valueTemp != "undefined" && file.valueTemp != "") {

                file.valueTemp.forEach(fileVal => {
                    //value array içine gir
                    //base64BlobDat a
                    //extension 
                    if (typeof fileVal.id == "undefined" || fileVal.id == 0) {
                        let obj: any = {
                            fileId: 0,
                            componentId: file.id,
                            rowId: "-1",
                            columnId: "-1",
                            fileData: fileVal.base64BlobData,
                            fileExtension: fileVal.extension,
                            fileType: fileVal.fileType,
                            isImage: fileVal.isImg,
                            name: fileVal.name,
                            size: fileVal.size,
                            description: fileVal.description,
                            dmsPath: fileVal.dmsPath
                        };

                        if (typeof obj.description == "undefined") {
                            obj.description = ""
                        }

                        objArray.push(obj);
                    }
                    else {
                        let obj: any = {
                            fileId: fileVal.id,
                            componentId: "",
                            rowId: "",
                            columnId: "",
                            fileData: "",
                            fileExtension: "",
                            fileType: "",
                            isImage: false,
                            name: "",
                            size: 0,
                            description: fileVal.description,
                            dmsPath: fileVal.dmsPath
                        };

                        if (typeof obj.description == "undefined") {
                            obj.description = ""
                        }

                        objArray.push(obj);
                    }

                });
            }
            file.valueTemp = [];
        });

        let validationDatagrid = [];
        let deleteFiles = [];
        let datagridCompsFilter = datagridComps.filter(x => x.type == "datagrid" && x.hide == false && x.show == true && (typeof x.outsideVisible == "undefined" || x.outsideVisible == true));
        if (datagridCompsFilter.length > 0) {
            debugger;
            datagridCompsFilter.forEach(_dataGrid => {

                let datagridRequired = [];
                let datagridReqI = 0;
                let datagridReqIValidationFalse = 0;
                //let datagridReqIValidation  = true; 
                _dataGrid.headers.forEach(_dgridH => {
                    if (_dgridH.properties.required == true) {
                        datagridRequired.push({ name: _dgridH.field, type: _dgridH.type, hide: _dgridH.hide });
                    }

                    if (_dataGrid.value.length == 1 &&
                        (typeof _dataGrid.value[0][_dgridH.field] == "undefined" ||
                            (
                                (_dgridH.type == "int" && (_dataGrid.value[0][_dgridH.field] == "" || _dataGrid.value[0][_dgridH.field] == "0")) ||
                                (_dgridH.type == "double" && (_dataGrid.value[0][_dgridH.field] == "" || _dataGrid.value[0][_dgridH.field] == "0")) ||
                                (_dgridH.type == "text" && _dataGrid.value[0][_dgridH.field] == "") ||
                                (_dgridH.type == "date" && _dataGrid.value[0][_dgridH.field] == null) ||
                                (_dgridH.type == "datetime" && _dataGrid.value[0][_dgridH.field] == null) ||
                                (_dgridH.type == "datagrid" && _dataGrid.value[0][_dgridH.field] == "") ||
                                (_dgridH.type == "combobox" && _dataGrid.value[0][_dgridH.field] == "") ||
                                (_dgridH.type == "file" && (_dataGrid.value[0][_dgridH.field] == "" || _dataGrid.value[0][_dgridH.field] == []))
                            )
                        )
                    ) {
                        datagridReqIValidationFalse = datagridReqIValidationFalse + 1;
                    }

                    datagridReqI = datagridReqI + 1;
                });
                // "text"   "double" "int" "date"  "datetime" "datagrid"  "combobox"
                debugger;
                if (datagridReqIValidationFalse != datagridReqI || _dataGrid.value.length > 1) {

                    _dataGrid.value.forEach(_dgridV => {
                        // if (typeof _dgridV.DefaultColumn != "undefined" && _dgridV.DefaultColumn != 1) {

                        datagridRequired.forEach(_dgridReq => {
                            if (typeof _dgridV[_dgridReq.name] == "undefined" ||
                                (
                                    (_dgridReq.type == "int" && (_dgridV[_dgridReq.name] == "" || _dgridV[_dgridReq.name] == "0")) ||
                                    (_dgridReq.type == "double" && (_dgridV[_dgridReq.name] == "" || _dgridV[_dgridReq.name] == "0")) ||
                                    (_dgridReq.type == "text" && _dgridV[_dgridReq.name] == "") ||
                                    (_dgridReq.type == "date" && _dgridV[_dgridReq.name] == null) ||
                                    (_dgridReq.type == "datetime" && _dgridV[_dgridReq.name] == null) ||
                                    (_dgridReq.type == "datagrid" && _dgridV[_dgridReq.name] == "") ||
                                    (_dgridReq.type == "combobox" && _dgridV[_dgridReq.name] == "") ||
                                    (_dgridReq.type == "file" && (_dataGrid.value[0][_dgridReq.field] == "" || _dataGrid.value[0][_dgridReq.field] == []))
                                )
                            ) {
                                let headerFind = _dataGrid.headers.find(x => x.field == _dgridReq.name);
                                if (headerFind && (typeof _dgridReq.hide != "undefined" && _dgridReq.hide == false)) {
                                    let headerFindCaption = headerFind.caption;
                                    if (headerFindCaption)
                                        validationDatagrid.push(headerFindCaption);
                                }
                            }
                        });
                        // }
                    });
                    // }
                }



                let datagridHeader = _dataGrid.headers.filter(x => x.type == "file" || x.type == "picturebox");
                datagridHeader.forEach(_datagridHeader => {

                    let dataFiles = _datagridHeader.files;
                    let columnId = _datagridHeader.field;
                    dataFiles.forEach(_dataFile => {
                        if (typeof _dataFile.file.id == "undefined" || _dataFile.file.id == 0) {
                            let obj: any = {
                                fileId: _dataFile.file.id,
                                componentId: _dataGrid.id,
                                rowId: _dataFile.row.toString(),
                                columnId: columnId.toString(),
                                fileData: _dataFile.file.base64BlobData,
                                fileExtension: _dataFile.file.extension,
                                fileType: _dataFile.file.fileType,
                                isImage: _dataFile.file.isImg,
                                name: _dataFile.file.name,
                                size: _dataFile.file.size,
                                description: _dataFile.file.description,
                                dmsPath: _dataFile.file.dmsPath
                            };

                            if (typeof obj.description == "undefined") {
                                obj.description = ""
                            }

                            if (_dataFile.file.size != -1)
                                objArray.push(obj);
                        }
                        //else if (_datagridHeader.type == "picturebox") {
                        if (_datagridHeader.type == "picturebox" && _dataFile.file.size != -1) {

                            deleteFiles = this.componentService.datagridFilePicturebox.filter(x => x.anotherPopupId == this.anotherPopupId).map(ele => ele.id);//.push({ : , id: header.files[pictureboxRowsValueTempIndex].file.id });
                            this.componentService.datagridFilePicturebox = this.componentService.datagridFilePicturebox.filter(x => x.anotherPopupId != this.anotherPopupId);
                            //  deleteFiles.push(_dataFile.file.id);
                        }
                        //else {
                        //    let obj: any = {
                        //        fileId: _dataFile.file.id,
                        //        componentId: "",
                        //        rowId: "",
                        //        columnId:"",
                        //        fileData:"",
                        //        fileExtension: "",
                        //        fileType: "",
                        //        isImage: false,
                        //        name: "",
                        //        size:"",
                        //        description: _dataFile.file.description
                        //    };
                        //    objArray.push(obj);
                        //}
                    });
                });
            });

        }

        if (validationDatagrid.length > 0) {
            this.eventEmitterService.setHomePopupVisibleFunc({ isShow: false });
            this.componentService.showAlert(validationDatagrid.join(', ') + " Kolonları zorunlu alanlardır. Lütfen Doldurunuz.", "error", 15000);
            return false;
        }

        let filesIds = [];

        if (objArray.length > 0) {
            let _processFormId = 0;
            if (anotherPopupIdLocal == 0) {
                _processFormId = this.componentService.currentProcessFormId;
            }
            else {
                let childObj = this.componentService.childPopups.find(x => x.id == anotherPopupIdLocal);
                if (childObj)
                    _processFormId = childObj.currentProcessFormId;
            }
            this.instanceService.saveFormFilesData(objArray, _processFormId, deleteFiles).subscribe((s: any) => {
                if (s.status == true) {
                    if (s.files.length > 0) {
                        filesIds = s.files.map(ele => ele.Id);
                        let _thisLocal = this;

                        _thisLocal.currentFormData.forEach(formData => {

                            let _fileVal = s.files.filter(x => x.ComponentId == formData.key);
                            if (_fileVal != null && typeof _fileVal != "undefined" && _fileVal.length > 0) {
                                if (formData.type == "datagrid") {

                                    _fileVal.forEach(_fileValObj => {
                                        let _tempData = formData.value.find(x => x.ID == parseInt(_fileValObj.RowId));
                                        if (_tempData)
                                            _tempData[_fileValObj.ColumnId] = _fileVal.filter(z => z.RowId == _fileValObj.RowId && z.ColumnId == _fileValObj.ColumnId).map(ele => ele.Id);
                                    });

                                }
                                else
                                    formData.value = _fileVal.map(ele => ele.Id);
                            }

                        });
                    }

                    this.callSaveFormData(status, reason, name, filesIds, anotherPopupIdLocal);
                }

            });

        } else {
            this.callSaveFormData(status, reason, name, filesIds, anotherPopupIdLocal);
        }

    }

    callSaveFormData(status, reason, name, filesIds, anotherPopupIdLocal) {
        let _processFormId = 0;
        let _selectedSchemaId = 0;
        if (anotherPopupIdLocal == 0) {
            _processFormId = this.componentService.currentProcessFormId;
            _selectedSchemaId = this.componentService.selectedSchemaId;
        }
        else {
            let childObj = this.componentService.childPopups.find(x => x.id == anotherPopupIdLocal);
            if (childObj) {
                _processFormId = childObj.currentProcessFormId;
                _selectedSchemaId = childObj.selectedSchemaId;
            }
        }
        let fData = new CurrentFormDataModel();
        fData.FromUserId = this.appInfo.currentUser.id;
        fData.ToUserId = this.appInfo.currentUser.id;
        fData.ProcessFormId = _processFormId;

        fData.FormVariable = JSON.stringify(this.currentFormData, null, 4);
        fData.Description = this.getStatusText(status) + " (" + this.appInfo.currentUser.name + ")";
        fData.Status = status; //this.selectedstatus;
        fData.UserStatus = this.componentService.currentUserStaus;
        fData.RejectionReason = reason;
        fData.Users = this.form.users;
        fData.FilesIds = filesIds;
        fData.DeletedFilesIds = [];

        fData.DeletedFilesIds = this.deletedFileIds;

        let locationButton = this.locationButtons.find(x => x.button.property.name == name && x.button.property.status == status);

        if (typeof locationButton != "undefined" && this.locationGlobal != null)
            fData.GeoLocation = JSON.stringify({ lat: this.locationGlobal.latitude, lng: this.locationGlobal.longitude });

        let paymentButton = this.paymentButtons.find(x => x.button.property.name == name && x.button.property.status == status);
        let curentCustomer = "0";
        let curentPrice = "0";
        let curentHirePurchase = "0";
        let curentPaymentDescription = "";
        let paymentProvider = "0";
        let curentCustomerFormId = "0";
        let customerButton = {};
        if (typeof paymentButton != "undefined") {

            this.currentFormSelectedGlobal = this.componentService.currentFormSelected(anotherPopupIdLocal);

            let curentComponents = this.componentService.getComponentList(this.currentFormSelectedGlobal.components, anotherPopupIdLocal);
            curentCustomer = curentComponents.find(x => x.id == paymentButton.button.customerId).value;
            if (paymentButton.button.priceComponent && paymentButton.button.priceComponent != "")
                curentPrice = curentComponents.find(x => x.id == paymentButton.button.priceComponent).value;
            if (paymentButton.button.hirePurchaseComponent && paymentButton.button.hirePurchaseComponent != "")
                curentHirePurchase = curentComponents.find(x => x.id == paymentButton.button.hirePurchaseComponent).value;
            if (paymentButton.button.descriptionComponent && paymentButton.button.descriptionComponent != "")
                curentPaymentDescription = curentComponents.find(x => x.id == paymentButton.button.descriptionComponent).value;
            customerButton = paymentButton.button.customer;
            paymentProvider = paymentButton.button.paymentProvider;
            if (paymentButton.button.customerFormId && paymentButton.button.customerFormId != "")
                curentCustomerFormId = paymentButton.button.customerFormId;

        }

        this.instanceService.saveFormData(fData, _selectedSchemaId, curentCustomerFormId, customerButton, curentCustomer, curentPrice, curentHirePurchase, curentPaymentDescription, paymentProvider).subscribe((s: any) => {

            if (s != null) {

                let schedulerChangeData_I = 0;
                let schedulerChangeData_ITotal = 0;
                let schedulerChangeDataKeys = [];

                try {
                    schedulerChangeDataKeys = Object.keys(this.componentService.schedulerChangeData);
                    schedulerChangeDataKeys.forEach(_key => {
                        if (this.componentService.schedulerChangeData[_key].length > 0) {
                            schedulerChangeData_ITotal = schedulerChangeData_ITotal + this.componentService.schedulerChangeData[_key].length;
                            this.formService.updateJustMenuData(this.componentService.schedulerChangeData[_key]).subscribe((result: any) => {
                                this.componentService.schedulerChangeData[_key] = [];
                                schedulerChangeData_I = schedulerChangeData_I + 1;
                            });
                        }
                    });
                } catch (ex) { }

                let tThis = this;
                let schedulerChangeDataInterval;
                try {
                    schedulerChangeDataInterval = setInterval(function () {
                        if (schedulerChangeData_ITotal == 0 || (schedulerChangeData_ITotal - 1 == schedulerChangeData_I)) {
                            clearInterval(schedulerChangeDataInterval);
                            tThis.componentService.schedulerChangeData = undefined;
                        }
                    }, 300);
                }
                catch (ex) {
                    if (schedulerChangeDataInterval)
                        clearInterval(schedulerChangeDataInterval);
                }

                var jsonReturn = JSON.parse(s);

                this.eventEmitterService.updateTasks(anotherPopupIdLocal);
                this.componentService.isSentFormToDb = true;

                let localRuntimeSelectboxDatasource = [];
                let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
                let curentComponents = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
                this.componentService.runtimeSelectboxDatasource.forEach(runtimeSelectbox => {
                    let _c = curentComponents.find(x => x.id == runtimeSelectbox.componentId);
                    if (_c && _c.isSelectboxInsertManualData) {
                        localRuntimeSelectboxDatasource.push(runtimeSelectbox);
                    }
                });

                if (localRuntimeSelectboxDatasource.length > 0) {
                    this.eventEmitterService.setHomePopupVisibleFunc({ isShow: true });
                    this.runtimeSelectboxDatasource(anotherPopupIdLocal, paymentButton, jsonReturn, localRuntimeSelectboxDatasource);
                }
                else {
                    this.homePopupClear(anotherPopupIdLocal, paymentButton, jsonReturn);
                }

            }
        });
    }
    runtimeSelectboxDatasource(anotherPopupIdLocal, paymentButton, jsonReturn, localRuntimeSelectboxDatasource) {
        try {
            this.instanceService.getProcessFormWithDataId(jsonReturn.id).subscribe((_pForm: any) => {
                let pFormJson = JSON.parse(_pForm.formJson);
                localRuntimeSelectboxDatasource.forEach(localRuntimeSelectbox => {
                    pFormJson.components = this.componentService.changeComponentListObject(pFormJson.components, localRuntimeSelectbox.componentId, "dataSource", localRuntimeSelectbox.dataSource);
                });
                _pForm.formJson = JSON.stringify(pFormJson);
                this.instanceService.setProcessFormJson(_pForm).subscribe((_pFormJson: any) => {
                    this.componentService.runtimeSelectboxDatasource = [];
                    this.homePopupClear(anotherPopupIdLocal, paymentButton, jsonReturn);
                    //this.componentService.runtimeSelectboxDatasource
                });
            });
        } catch (ex) {
            this.eventEmitterService.setHomePopupVisibleFunc({ isShow: false });

        }
    }
    homePopupClear(anotherPopupIdLocal, paymentButton, jsonReturn) {
        if (anotherPopupIdLocal == 0) {
            this.componentService.currentProcessFormId = 0;
        }
        else {
            let childObj = this.componentService.childPopups.find(x => x.id == anotherPopupIdLocal);
            if (childObj)
                childObj.currentProcessFormId = 0;
        }

        this.showToast("Form Başarıyla Gönderildi", "success");

        if (typeof paymentButton != "undefined") {
            let _domain = window.location.protocol + "//" + window.location.host + "/" + jsonReturn.url;
            window.open(_domain, "_blank");
        }
        this.eventEmitterService.setHomePopupVisibleFunc({ isShow: false });
    }



    locationGlobal: any;
    getCurrentPosition(options = {}) {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    }

    async getGeoLocation() {

        if (window.navigator && window.navigator.geolocation) {
            await window.navigator.geolocation.getCurrentPosition(
                position => {
                    this.locationGlobal = position.coords;
                },
                error => {
                    switch (error.code) {
                        case 1:
                            this.locationGlobal = null;
                            break;
                        case 2:
                            this.locationGlobal = null;
                            break;
                        case 3:
                            this.locationGlobal = null;
                            break;
                    }
                }
            );
        };
    }

    //süreç iptal edilecek
    cancelInstance(anotherPopupIdLocal) {
        let msg = "<i>İşlemi onaylıyor musunuz?</i>"
        let result = confirm(msg, "Süreç İptal Edilecek!");
        result.then((dialogResult) => {
            if (dialogResult) {
                let _processFormId = 0;
                if (anotherPopupIdLocal == 0) {
                    _processFormId = this.componentService.currentProcessFormId;
                }
                else {
                    let childObj = this.componentService.childPopups.find(x => x.id == anotherPopupIdLocal);
                    if (childObj)
                        _processFormId = childObj.currentProcessFormId;
                }

                this.currentFormData = [];

                this.currentFormSelectedGlobal = this.componentService.currentFormSelected(anotherPopupIdLocal);

                this.createValuesData(this.currentFormSelectedGlobal.components);
                let fData = new CurrentFormDataModel();
                fData.FromUserId = this.appInfo.currentUser.id;
                fData.ToUserId = this.appInfo.currentUser.id;
                fData.ProcessFormId = _processFormId;
                fData.FormVariable = JSON.stringify(this.currentFormData, null, 4);
                fData.Description = "Süreç, kullanıcı tarafından iptal edildi";
                fData.Status = this.componentService.formStatus.Cancelled;
                fData.RejectionReason = "";
                fData.IsAvalible = true;
                this.instanceService.saveFormData(fData, 0, "0", {}, "0", "0", "1", "", "").subscribe((s: any) => {
                    if (s != null) {
                        this.eventEmitterService.submitForm();
                    }
                });
            }
        });
    }

    getStatusText(status) {
        let ststu = "";
        if (status == this.componentService.formStatus.Approve) ststu = "Onayladı";
        else if (status == this.componentService.formStatus.Disapprove) ststu = "Reddedildi";
        else if (status == this.componentService.formStatus.SaveAndSend) ststu = "Gönderildi";
        else if (status == this.componentService.formStatus.Returned) ststu = "Geri Gönderildi";
        else if (status == this.componentService.formStatus.Cancelled) ststu = "İptal Edildi";
        else if (status == this.componentService.formStatus.DisapproveTerminate) ststu = "Reddedildi ve Süreç Bitirildi";
        else if (status == this.componentService.formStatus.Processing) ststu = "Kaydedildi";

        return ststu;
    }

    createValuesData(components) {

        for (let i = 0; i < components.length; i++) {
            if (components[i].isInput == true
                || components[i].inputType == "datagrid"
                || components[i].inputType == "file"
                || components[i].inputType == "map"
                || components[i].inputType == "menu"
                || components[i].type == "gallery"
                || components[i].type == "codeeditor"
                || components[i].type == "htmleditor"
                || components[i].type == "comment") {
                var obj: any = {};
                obj["key"] = components[i].id;
                obj["value"] = components[i].value;
                obj["type"] = components[i].inputType;
                obj["componentType"] = components[i].type;
                obj["isAuto"] = components[i].isAuto;
                obj["autoText"] = components[i].autoText;
                obj["isUserChange"] = components[i].isUserChange;
                if (components[i].isInput) {
                    components[i].isUserChange = typeof components[i].isUserChange == "undefined" ? false : components[i].isUserChange
                }
                if (components[i].type == "livestream") {
                    obj["meetingType"] = components[i].meetingType;
                }
                if (components[i].type == "button") {

                    // let selectedButton = components[i];//.find(x => x.property.name == this.buttonNameGlobal);
                    obj["name"] = this.buttonNameGlobal;// components[i].nameValue;
                    // obj["isLiveWeb"] = selectedButton.isLiveWeb;
                    //  obj["liveWeb"] = selectedButton.liveWeb;
                }

                if (components[i].inputType == "datagrid") {

                    var gridVal = components[i].value;
                    var lastId = gridVal.length > 0 ? gridVal[gridVal.length - 1].ID : 0;
                    obj["lastid"] = lastId;

                    var headerAndType = this.getGridHeaders(components[i]);
                    obj["headers"] = headerAndType.headers;
                    obj["headerTypes"] = headerAndType.headerTypes;
                    obj["rowCount"] = components[i].value.length;
                    obj["selectedRowCount"] = components[i].selectedRows;
                    obj["userChangeColumns"] = components[i].userChangeColumns;
                }
                else if (components[i].type == "map") {

                    obj["markers"] = components[i].markers;
                }
                else {
                    obj["markers"] = [];
                    obj["headers"] = "";
                    obj["headerTypes"] = "";
                    obj["rowCount"] = 0;
                    obj["selectedRowCount"] = 0;
                    obj["userChangeColumns"] = [];
                }
                this.currentFormData.push(obj);
            }
            else if (components[i].type == "grid") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                        this.createValuesData(components[i].rows[j].cols[k].components);
                    }
                }
            }
            else if (components[i].type == "panel" || components[i].type == "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    this.createValuesData(components[i].items[j].components);
                }
            }


        }
    }


    getGridHeaders(grid) {
        let headers = [];
        let headerTypes = [];
        grid.headers.forEach(header => {
            var obj: any = {};
            //if (header.type != "file") {

            headers.push(header.field);
            headerTypes.push(header.type);
            //}
        });
        return { headers: headers.join(","), headerTypes: headerTypes.join(",") };
    }

    errorHandler(error) {
        let errorMessage = '';
        errorMessage = error.error instanceof ErrorEvent ? error.error.message : 'Error Code: ${error.status}\nMessage: ${error.message}';
        console.log(errorMessage);
        this.showToast(errorMessage, "error");
    }

    changeStatus(event) {

    }

    showToast(message, status) {
        notify(message, status, 3000);
    }
}
