import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, throwError } from 'rxjs';
import { AppInfoService } from '../app-info.service';
import notify from "devextreme/ui/notify";
import { ComponentService } from '../../../formdesigner/component.service';
@Injectable()
export class AuthService {
    loggedIn = false;
    isFormBuilder = false;
    reportingPage = false;
    constructor(private router: Router, private componentService: ComponentService, public appInfo: AppInfoService, private http: HttpClient, private spinner: NgxSpinnerService) { }

    logInWithGuid(guid: string) {
        let params = new HttpParams().set("guid", guid);
        this.http.get<string>(this.appInfo.appUrl + "api/auth/loginWithGuid", { params: params }).subscribe((result: any) => {
            if (result.entity == null)
                this.logOut();
            else
                this.loginResult(result.entity, false, result.taskInfo);

        }, error => {
        });
    }
    logIn(userName: string, password: string, rememberMe: boolean, setLanguage: any) {

        this.spinner.show("mainspinner");
        let params = new HttpParams().set("userName", userName).set("password", password).set("lang", setLanguage);
        this.http.get<string>(this.appInfo.appUrl + "api/auth/login", { params: params }).subscribe((entity: any) => {

            let taskInfo = { schemeId: entity.openFormId };
            taskInfo = entity.openFormId && entity.openFormId != 0 ? taskInfo : null;
            this.loginResult(entity, rememberMe, taskInfo);

        }, error => {
            this.spinner.hide("mainspinner");
            this.showToast("Üzgünüz, işlem sırasında bir hata oluştu:(", "error");
            console.log(error);
        });
    }
    loginResult(entity: any, rememberMe: boolean, taskInfo: any) {

        if (entity.isConnect) {

            let googleMapKey = entity.integrationApi.GOOGLEMAP;
            localStorage.setItem("GMAPI", btoa(typeof googleMapKey != "undefined" && googleMapKey != null ? googleMapKey : ""));//AIzaSyBfeMCzcIXRN-rBUA19hqwFgngihg99VwQ

            entity.IntegrationApi = {};

            this.appInfo.RiverEntity = entity;
            this.appInfo.currentUser = entity.currentUser;

            this.appInfo.parameters = entity.parameters;

            debugger;
            localStorage.setItem("localUserSession", JSON.stringify(entity));
            // sessionStorage.setItem("sessionUser", "true");

            this.loggedIn = true;

            if (rememberMe) localStorage.setItem("rememberMe", "true");
            else localStorage.setItem("rememberMe", "false");

            this.spinner.hide("mainspinner");
            this.router.routeReuseStrategy.shouldReuseRoute = function () {
                return false;
            }
            if (taskInfo == null) {
                this.router.onSameUrlNavigation = 'reload';
                this.redirectTo('/');
            }
            else {
                this.router.navigate(['home'], { state: { id: taskInfo.schemeId, instanceId: taskInfo.instanceId, processFormsId: taskInfo.processFormsId } });
            }

        }
        else {
            this.spinner.hide("mainspinner");
            this.showToast(entity.message, "warning");
        }
    }
    redirectTo(uri: string) {

        let _domain = window.location.protocol + "//" + window.location.host;
        window.open(_domain, "_self");
        //this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        //    this.router.navigate([uri], { state: { pageUuid: this.componentService.createuuid() } }));
    }
    updateColor(formData) {
        let params = new HttpParams().set("id", formData.id).set("color", formData.color);
        return this.http.get(this.appInfo.appUrl + "api/auth/updatecolor", { params: params });

        // this.spinner.show("mainspinner");
        // let params = new HttpParams().set("userId", formData.id).set("color", formData.color);
        // this.http.get<boolean>(this.appInfo.appUrl + "api/auth/updatecolor", { params: params }).subscribe((entity: boolean) => {
        //     return entity;
        // }, error => {
        //   this.spinner.hide("mainspinner");
        //   this.showToast("Üzgünüz, işlem sırasında bir hata oluştu:(", "error");

        // }); 
    }
    updateProfile(profileData): Observable<boolean> {
        return this.http.post<boolean>(this.appInfo.appUrl + "api/auth/updateprofile", JSON.stringify(profileData), this.appInfo.httpOptions);
    }
    logOut() {
        debugger;
        this.loggedIn = false;
        localStorage.setItem("rememberMe", "false");
        //sessionStorage.setItem("sessionUser", "false");
        localStorage.removeItem("localUserSession");
        localStorage.setItem("GMAPI", "");
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        }

        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/login'], { state: { pageUuid: this.componentService.createuuid() } });
    }

    get isLoggedIn() {
        return this.loggedIn;
    }

    showToast(message, status) {
        notify(message, status, 3000);
    }
}

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(private router: Router, private authService: AuthService, private appInfo: AppInfoService) { }

    redirectTo(uri: string) {

        let _domain = window.location.protocol + "//" + window.location.host;
        window.open(_domain, "_self");
        //this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        //    this.router.navigate([uri]));
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        this.authService.isFormBuilder = false;
        this.authService.reportingPage = false;
        
        let rememberMe = localStorage.getItem("rememberMe");
        let _localUserSession= localStorage.getItem("localUserSession");
        // let sessionUser = sessionStorage.getItem("sessionUser");
        // if ((rememberMe && rememberMe == "true") || (sessionUser && sessionUser == "true")) {
        if (_localUserSession && JSON.parse(_localUserSession).currentUser) {
            this.authService.loggedIn = true;
            let entity = JSON.parse(_localUserSession);
            this.appInfo.RiverEntity = entity;
            this.appInfo.currentUser = entity.currentUser;
            //this.router.navigate(['/']);
            return true;
        }
       else {
            this.router.navigate(['/login']);
        }
        //}
        //else {
        //    const isLoggedIn = this.authService.isLoggedIn;
        //    const isLoginForm = route.routeConfig.path === 'login';

        //    if (isLoggedIn && isLoginForm) {
        //        this.router.routeReuseStrategy.shouldReuseRoute = function () {
        //            return false;
        //        }
        //        this.router.onSameUrlNavigation = 'reload';
        //        //  this.router.navigate(['/']);
        //        this.redirectTo('/');
        //        return false;
        //    }

        //    if (!isLoggedIn && !isLoginForm) {
        //        this.router.navigate(['/login']);
        //    }
        //    return isLoggedIn || isLoginForm;
        //}
    }
}

@Injectable()
export class FormbuilderGuardService implements CanActivate {
    constructor(private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        this.authService.isFormBuilder = true;
        return true;
    }
}

@Injectable()
export class ReportingGuardService implements CanActivate {
    constructor(private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        this.authService.reportingPage = true;
        return true;
    }
}
