import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, OnDestroy, AfterViewInit, ContentChild, ElementRef } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService, FormService, GenericService, AppInfoService } from '../../shared/services';
import CustomStore from 'devextreme/data/custom_store';
import { ComponentService } from '../component.service';

import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';
import { EditorComponent } from 'ngx-monaco-editor';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';

export class SqlScriptModelListDatagrid {
    id: number;
    connectionId: number;
    elementId: string;
    query: string;
}


@Component({
    selector: 'datalist-component',
    templateUrl: './datalist.component.html',
    styleUrls: ['./datalist.component.css']
})
export class DxDataListComponent implements ComponentParameters, OnInit, OnDestroy, AfterViewInit {


    @ViewChild(DxDataGridComponent, { static: false }) gridContainer;
    @Input() data: any;
    @Input() isEdit: any;
    @Input() lang: string;
    @Input() anotherPopupId: number = 0;

    //dropDownOptions: object;

    allMode: string;
    checkBoxesMode: string;

    fieldTypes: any[] = [
        { "type": "text", "value": "text", "name": "Text" },
        { "value": "double", "name": "Ondalýk Sayý" },
        { "value": "int", "name": "Tam Sayý" },
        { "value": "checkbox", "name": "Checkbox" },
        { "value": "date", "name": "Date" },
        { "value": "datetime", "name": "DateTime" },
        { "value": "datagrid", "name": "Data Grid" },
        { "value": "combobox", "name": "Combobox" },
        { "value": "file", "name": "File" },
        { "value": "map", "name": "Map" },
        { "value": "picturebox", "name": "Picturebox" },
        { "value": "fileviewer", "name": "File Viewer" }
    ];

    headers: any = {
        "name": "",
        "width": "",
        "field": "",
        "type": "text",
        "expression": {},
    }

    fontWeightTypes = [{ value: "normal", text: "Normal" }, { value: "bold", text: "Bold" }]
    filterOperatorsColor = {
        "string": [{ type: "like" }, { type: "startsWith" }, { type: "endsWith" }, { type: "equals" }],
        "number": [{ type: "=" }, { type: "!=" }, { type: "<" }, { type: ">" }, { type: "<=" }, { type: ">=" }],
        "object": [{ type: "=" }, { type: "equals" }]
    }
    showDisplay: any = "flex";


    languageCodes = []

    designerDataSourceList: any;
    designerDatasourceModel: any;

    dataSources: any;
    dataSourcePopup: boolean = false;
    isFromDataSource: boolean;
    showSettingPopup: boolean = false;
    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showChamgeEventPopup: boolean = false;
    isMonacoLoaded: boolean = false;

    invokeChangeEventLocal: Subscription;

    connections: any = [];
    selecboxComponents: any = [];

    datalistSelectionModes: any = ["multiple", "single"];

    sqlScriptModel: SqlScriptModelListDatagrid;
    languageLocal: any = "";

    @ViewChild("gridContainer", { static: false }) datalistItem: DxDataGridComponent;

    constructor(public appInfo: AppInfoService,
        private eventEmitterService: EventEmitterService,
        private componentService: ComponentService,
        private genericService: GenericService,
        public changeDetectorRef: ChangeDetectorRef) {

        this.languageLocal = this.componentService.getLanguageSource(); //language
        this.languageCodes = this.componentService.getlanguageCodes();
        this.setLanguageToDatas()

        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);//
                let currentComps = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
                let currentCompDatagrids = currentComps.filter(x => x.type == "datagrid");
                this.allDatagridComponents = currentCompDatagrids.map(m => m.id);

                this.showSettingPopup = true;
            }
        });

        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {

            if (this.data.id != sub.id && sub.elementId == this.data.id) {
                if (sub.data) {

                    let result = sub.data;
                    if (result && result.length > 0 && Array.isArray(result)) {
                        let cols = Object.keys(result[0]);
                        this.showHeaders = [];
                        if (cols) {
                            cols.forEach(el => {
                                this.showHeaders.push({ field: el, width: 150 })
                            })
                            this.data.headers.forEach(el => {
                                let foundedHeaderIndex = this.showHeaders.findIndex(f => f.field == el.field)
                                this.showHeaders[foundedHeaderIndex].width = el.width
                            })
                        }
                        this.data.dataSource = result;
                        let tThis = this;
                        setTimeout(() => {
                            let _dataVal = tThis.datasourceValChange(tThis.data.dataSource, tThis.data.value, tThis);

                            tThis.data.value = tThis.data.isDefaultFirstValue && tThis.data.dataSource.length > 0 && tThis.data.value == "" ? tThis.data.dataSource[0][tThis.data.valueExpr] : _dataVal;

                            try { tThis.changeDetectorRef.detectChanges(); } catch (ex) { }

                            let _event_ = { selectedRowKeys: [tThis.data.value] };
                            tThis.onSelectionChanged(_event_);
                        }, 1000);

                        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                    }
                    else {
                        this.data.value = result;
                        this.data.dataSource = [];
                        this.showHeaders = []
                    }

                }
                else if (sub.elementId == "clean_element" || sub.value == "clean_element") this.data.dataSource = [];
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });
        this.allMode = 'allPages';
        this.checkBoxesMode = 'onClick';
    }

    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
    }

    currentFormSelectedGlobal;
    allDatagridComponents: any = [];
    ngOnInit() {
       
        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);
        this.connections = this.appInfo.RiverEntity.connections;
         
        //if (typeof this.functionScript == "undefined")
        //    this.functionScript = { code: "", elementId: "", column: "" };
    
        if (typeof this.data.isSelectInsertManualData == "undefined")
            this.data.isSelectInsertManualData = false;

        if (typeof this.data.selectionMode == "undefined")
            this.data.selectionMode = "multiple";

        if (!this.isEdit) {
            this.executeScript();

            if (this.data.isSelectInsertManualData) {
                this.setRuntimeDataSource();
            }
        }
        else {

            this.fillDesignerDataSource();
        }


        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.width = typeof this.data.width == "undefined" ? "100%" : this.data.width;
        this.data.height = typeof this.data.height == "undefined" ? "350px" : this.data.height;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";


        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });

        if (typeof this.data.isDefaultFirstValue === "undefined")
            this.data.isDefaultFirstValue = false;

        let fff = this.data;
        //  this.dropDownOptions = { width: this.data.width };
        this.currentFormSelectedGlobal = this.componentService.currentFormSelected(this.anotherPopupId);

        this.data.headers = typeof this.data.headers == "undefined" ? [] : this.data.headers;
        this.data.colorExpressions = typeof this.data.colorExpressions == "undefined" ? [] : this.data.colorExpressions ;

    }

    addEmptyHeader() {
        this.headers.name = this.componentService.createuuid();
        let header = this.componentService.cloneModel(this.headers);
        this.data.headers.push(header);
        //this.resetHeaderObject();
    }
    deleteHeader(index) {
        let result = confirm("<i>" + this.languageLocal.areYouSure + "</i>", this.languageLocal.columnDeleted);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.data.headers.splice(index, 1);
            }
        });
    }

    saveColumnSettings($event) {
        let _control = true;
        const pattern = /[A-Za-z_]/;
        this.data.headers.forEach(f => {
            if (!pattern.test(f.field[0]))
                _control = false;
        });
        if (_control) {
            this.showGridColPopup = false;
        }
        else
            this.showToast(this.languageLocal.toastColumnNameNumber, "warning");
    }
    showToast(message, status) {
        notify(message, status, 3000);
    }
    datagridAllColumns = [];
    onChangeSelectedDatagrid(event) {
        if (event.value) {
            this.currentFormSelectedGlobal = this.componentService.currentFormSelected(this.anotherPopupId);
            let curentComponents = this.componentService.getComponentList(this.currentFormSelectedGlobal.components, this.anotherPopupId);
            let selDg = curentComponents.filter(x => x.id == this.data.selectedDatagrid);
            this.data.selectionMode = "single";//
            this.datagridAllColumns = selDg[0] ? selDg[0].headers.map(m => m.field) : [];
        }
    }

    runtimeDatasourcePopup = false;
    saveRuntimeDataSource() {
        this.data.valueExpr = "value";
        this.data.displayExpr = "text";
        this.runtimeDatasourcePopup = false;

        this.datalistItem.instance.getDataSource().reload();
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        this.setRuntimeDataSource();
    }
    selectInsertManualDataPopup() {
        this.data.isManual = true;
        this.runtimeDatasourcePopup = true;
    }
    setRuntimeDataSource() {

        let runtimeSelectbox = this.componentService.runtimeSelectboxDatasource.find(x => x.componentId == this.data.id);
        if (runtimeSelectbox) {
            runtimeSelectbox.dataSource = this.data.dataSource;
        }
        else {
            this.componentService.runtimeSelectboxDatasource.push({ componentId: this.data.id, dataSource: this.data.dataSource });
        }
    }
    selectInsertManualData(eVal) {
        if (this.data.isSelectInsertManualData) {
            this.data.dataStorage.connectionId = 0;
            this.data.dataStorage.query = "";
            this.data.desktopDatasource = null;
            this.data.isManual = true;
        }
    }

    ngAfterViewInit() {
        if (!this.isEdit) {

        };
    }
    onSelectionChanged(event) {

        this.data.value = event.selectedRowKeys;

        if (this.data.selectedDatagrid && this.data.selectedDatagrid != "") {
            let _datalistFilterColumnValue = "";
            if (typeof this.data.datalistFilterColumn != "undefined" && this.data.datalistFilterColumn != null && this.data.datalistFilterColumn != "") {
                let _dataDataSourceFind = this.data.dataSource.find(x => x[this.data.valueExpr] == this.data.value[0]);
                _datalistFilterColumnValue = typeof _dataDataSourceFind != "undefined" ? _dataDataSourceFind[this.data.datalistFilterColumn] : "";
            }
            else
                _datalistFilterColumnValue = this.data.value[0];
             
            this.data.selectedDatagridColumn = this.data.selectedDatagridColumn == 0 || this.data.selectedDatagridColumn == -1 || this.data.selectedDatagridColumn == null ? undefined : this.data.selectedDatagridColumn;
            let _data = { element: this.data.id, value: _datalistFilterColumnValue, elementLabel: this.defaultLanguage.Label, filteredElement: this.data.selectedDatagrid, filteredColumn: this.data.selectedDatagridColumn };
            this.eventEmitterService.ChangeFilterOutElement(_data);
        }

        //if (event.currentSelectedRowKeys.length > 0) {

        //    let _data = event.selectedRowsData;
        //    if (_data) { 
        //        this.data.value = _data[0][this.data.valueExpr]; 
        //        dropDow nBoxComponent.close();

        //        this.data.sqlScripts.forEach(s => {
        //            let pref = "{" + this.data.id + "}";
        //            let value = this.data.value;
        //            let query = s.query.replace(pref, value);
        //            this.genericService.executeQuerySecurited(query, s.connectionId).subscribe((result: any) => {
        //                
        //                if (result) {
        //                    let element = this.componentService.getById(s.elementId, this.componentServi ce.current Form.components);
        //                    if (element.type) {
        //                        
        //                        if (element.component == "DxDatagridSelectComponent" || element.type == "gallery") {
        //                            this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, event.value, result);
        //                        }
        //                        else {
        //                            let keys = Object.keys(result[0]);
        //                            if (keys.length > 0) {
        //                                let finalData = [];
        //                                keys.forEach(f => {
        //                                    finalData.push(result[0][f]);
        //                                });
        //                                let dataText = finalData.join(",");
        //                                this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, event.value, dataText);
        //                            }
        //                        }
        //                    }
        //                }
        //            });
        //        });


        //    }
        //    else {
        //        this.eventEmitterService.selectboxChangeEvent(this.data.id, "clean_element", null, null);
        //    }

        //}

    }

    fillDesignerDataSource() {
        this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };

        this.genericService.fillDesignerDataSource().subscribe((result: any) => {

            this.designerDataSourceList = result;
            this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };
            if (this.data.desktopDatasource != null) {
                this.designerDatasourceModel = this.designerDataSourceList.find(x => x.ID == this.data.desktopDatasource);

                if (typeof this.designerDatasourceModel.ID === "undefined")
                    this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };
            }

            if (!this.isEdit) {

                this.genericServiceExecuteScript(this.designerDatasourceModel.SQLSTR, this.designerDatasourceModel.CONNID);
            }

        });

    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {

        this.data.desktopDatasource = this.designerDatasourceModel.ID;

        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);

        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    resetDataSource() {
        this.data.dataSource = [];
        this.data.dataSourceKey = "";
    }

    onChangeDatasourceValue(event) {
        if (event.value) {

            this.designerDatasourceModel = this.designerDataSourceList.find(x => x.ID == event.value);

        }
    }


    onValueChanged(event) {

    }

    onDataSourceValueChanged(event) {
        this.data.dataSourceKey = event.value
    }

    public executeScript() {

        if (this.data.dataStorage.connectionId > 0 && this.data.dataStorage.query != "") {
            this.genericServiceExecuteScript(this.data.dataStorage.query, this.data.dataStorage.connectionId);
        }
        else if (this.data.dataSource && this.data.dataSource.length > 0) {

            let filteredData = this.data.dataSource.filter(f => f.lang == this.lang);
            if (filteredData && filteredData.length > 0)
                this.data.dataSource = filteredData;


            let tThis = this;
            setTimeout(() => {
                let _dataVal = tThis.datasourceValChange(tThis.data.dataSource, tThis.data.value, tThis);
                tThis.data.value = tThis.data.isDefaultFirstValue && tThis.data.dataSource.length > 0 && tThis.data.value == "" ? tThis.data.dataSource[0][tThis.data.valueExpr] : _dataVal;

                try { tThis.changeDetectorRef.detectChanges(); } catch (ex) { }


                let _event_ = { selectedRowKeys: [tThis.data.value] };
                tThis.onSelectionChanged(_event_);

            }, 1000);

        }
        else if (this.data.desktopDatasource != null) {

            this.fillDesignerDataSource();

        }
    }
    showGridColPopup = false;
    showGridColPopupLoading = false;
    openshowGridColPopup() {
        this.showGridColPopup = true;
        this.showGridColPopupLoading = true;
    }
    getDatalistContainerStyle() {
        let myStyles = {
            'width': (this.data.width && typeof this.data.width != "undefined" && this.data.width != null) ? (this.data.width + (this.componentService.isInteger(this.data.width) ? "px" : "")) : "100%",
            'height': (this.data.height && typeof this.data.height != "undefined" && this.data.height != null) ? (this.data.height + (this.componentService.isInteger(this.data.height) ? "px" : "")) : "350px",
            'min-width': (this.data.width && typeof this.data.width != "undefined" && this.data.width != null) ? (this.data.width + (this.componentService.isInteger(this.data.width) ? "px" : "")) : "100%"
            //'display':this.showDisplay
        };
        return myStyles;

    }

    showHeaders = []
    public genericServiceExecuteScript(_Query, _ConnectionId) {

        this.currentFormSelectedGlobal = this.componentService.currentFormSelected(this.anotherPopupId);
        let curentComponents = this.componentService.getComponentList(this.currentFormSelectedGlobal.components, this.anotherPopupId);
        var pattern = /\{(\w)+\}/ig;
        var patt = new RegExp(pattern);
        if (patt.test(_Query)) {
            let _match = _Query.match(pattern);
            if (_match.length > 0) {
                _match.forEach(f => {
                    let _k = curentComponents.find(k => k.id == f.replace("{", "").replace("}", ""));
                    if (_k) {
                        let _value = _k.value == null ? "" : _k.value;
                        _Query = this.componentService.replaceAllUsingType(_Query, f, _value); // _Query = _Query.replace(f, _value);
                    }
                });
            }
            //query = this.genericService.cleanSqlSyntax(query.replace(pattern, ""))
        }


        this.genericService.executeQuerySecurited(_Query, _ConnectionId).subscribe((result: any) => {
            
            if (result && result.length > 0) {
                let cols = Object.keys(result[0]);
                if (cols) {
                    cols.forEach(el => {
                        this.showHeaders.push({ field: el , width : 150 })
                    })
                    this.data.headers.forEach(el => {
                        let foundedHeaderIndex = this.showHeaders.findIndex(f => f.field == el.field)
                        this.showHeaders[foundedHeaderIndex].width = el.width
                    })
                }
                this.data.dataSource = result;
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }

                let tThis = this;
                setTimeout(() => {
                    let _dataVal = tThis.datasourceValChange(tThis.data.dataSource, tThis.data.value, tThis);
                    tThis.data.value = tThis.data.isDefaultFirstValue && tThis.data.dataSource.length > 0 && tThis.data.value == "" ? tThis.data.dataSource[0][tThis.data.valueExpr] : _dataVal;

                    try { tThis.changeDetectorRef.detectChanges(); } catch (ex) { }


                    let _event_ = { selectedRowKeys: [tThis.data.value] };
                    tThis.onSelectionChanged(_event_);
                }, 1000);


            }
            else {
                this.data.dataSource = [];
            }
        });
    }

    datasourceValChange(_datasource, _val, tThis) {
        let _valReturn = _val;
        if (_datasource.length > 0) {
            let _valFind = _datasource.find(x => x[tThis.data.valueExpr] == _val);
            if (_valFind)
                _valReturn = _valFind[tThis.data.valueExpr];
        }
        return _valReturn;
    }

    openDataPopup(type) {
        this.isFromDataSource = type == "1";
        if (this.isFromDataSource) {
            this.data.isManual = false;
            let selectboxes = this.componentService.getComponentsByType("selectbox", this.anotherPopupId);
            this.selecboxComponents = [];
            if (selectboxes) {
                selectboxes.forEach(item => {
                    if (item.id != this.data.id) {
                        let element: any = {};
                        element.id = item.id;
                        element.name = this.componentService.getLang(this.lang, item.language).Label;
                        this.selecboxComponents.push(element);
                    }
                });
            }
        }
        else {
            this.data.isManual = true;
        }

        this.dataSourcePopup = true;
    }

    saveDataSource() {
        if (this.data.isManual && this.data.dataStorage.connectionId > 0 && this.data.dataStorage.query != "") {
            let msg = "<i>" + this.languageLocal.messageSqlLinkDeleted + "</i>"
            let result = confirm(msg, this.languageLocal.warning);
            result.then((dialogResult) => {

                if (dialogResult) {
                    this.data.dataStorage.connectionId = 0;
                    this.data.dataStorage.query = "";
                    this.dataSourcePopup = false;
                    this.data.valueExpr = "value";
                    //this.data.displayExpr = "text";
                }
            });
        }
        else {

            if (this.data.isManual) {
                this.data.valueExpr = "value";
                //this.data.displayExpr = "text";
            }

            this.dataSourcePopup = false;
        }
    }

    resetDataSources() {
        this.data.dataSourceKey = "";
        this.data.dataSource = [];

        notify(this.languageLocal.notifyDataReset, "success", 3000);
    }

    apply() {
        this.executeScript();
    }

    prepareGrid() {
        new CustomStore({
            key: "Id",
            load: () => this.data.dataSource,
            insert: (values) => this.insert(JSON.stringify(values)),
            update: (key, values) => this.update(key, JSON.stringify(values)),
            remove: (key) => this.remove(key)
        });
    }

    executeExp() {
        this.eventEmitterService.executeExpretion(this.anotherPopupId);
    }

    insert(values: any = {}): any {
        this.data.dataSource.push(values);
    }

    update(key, values: any = {}): any {
        const index = this.data.dataSource.findIndex(item => item.key === key);
        this.data.dataSource[index] = values;
    }

    remove(key): any {
        const index = this.data.dataSource.findIndex(item => item.key === key);
        this.data.dataSource.splice(index, 1);
    }

    monacoOnInit() {
        this.isMonacoLoaded = true;
        let monaco = window['monaco'];
        this.setCustomKeywords(monaco);
    }

    registeredLanguage: any;
    setCustomKeywords(monaco) {
        if (this.registeredLanguage)
            this.registeredLanguage.dispose();

        this.registeredLanguage = monaco.languages.registerCompletionItemProvider('plaintext', {
            provideCompletionItems: () => {
                var suggestions = [];
                this.currentFormSelectedGlobal = this.componentService.currentFormSelected(this.anotherPopupId);
                let elements = this.componentService.getComponentList(this.currentFormSelectedGlobal.components, this.anotherPopupId).map(m => m.id);
                for (let i = 0; i < elements.length; i++) {
                    suggestions.push({
                        label: elements[i],
                        kind: monaco.languages.CompletionItemKind.Snippet,
                        insertText: "" + elements[i] + "",
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        documentation: 'method'
                    })
                }
                return { suggestions: suggestions };
            }
        });
    }

    formElements: any;
    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace).replace(/[\u200B-\u200D\uFEFF]/g, '');
    }
    onRowPrepared(event) {
       
        
        if (event.rowType === "data" && this.data.colorExpressions.length > 0) {

            this.data.colorExpressions.forEach(expression => {

                let replacedQuery = expression.conditionText

                replacedQuery = this.componentService.cloneModel(expression.conditionText);
                let fields = Object.keys(event.data);

                fields.forEach(f => {
                    let pref = "{" + f + "}";
                    let value = event.data[f];
                    if (value != null && typeof value != "undefined" && typeof value.getMonth === 'function') {
                        value = value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate();
                        replacedQuery = this.replaceAll(replacedQuery, pref, value);
                    }
                    else
                        replacedQuery = this.replaceAll(replacedQuery, pref, value);
                });

                let currentExpression = expression.expression
                if (expression.expression == "=") { currentExpression = "==" }
                let query = event.data[expression.fieldName] + " " + currentExpression + " " + replacedQuery
                if (expression.fieldType == "number") {
                    let functionn = "if(" + query.replace(/[\u200B-\u200D\uFEFF]/g, '') + ")" + "{return true;} else { return false; }";
                    let result = eval('(function() {' + functionn + '}())');
                    if (result == true) {
                        //boya

                        try {

                            event.rowElement.style.backgroundColor = expression.backgroundColor;
                            event.rowElement.style.color = expression.fontColor;
                        } catch (err) {

                        }


                    }
                }
                if (expression.fieldType == "string") {

                    if (expression.expression == "like") {
                        if (event.data[expression.fieldName].toString().includes(replacedQuery)) {
                            event.rowElement.style.backgroundColor = expression.backgroundColor;
                            event.rowElement.style.color = expression.fontColor;
                        }
                    }
                    if (expression.expression == "startsWith") {
                        if (event.data[expression.fieldName].startsWith(replacedQuery)) {
                            event.rowElement.style.backgroundColor = expression.backgroundColor;
                            event.rowElement.style.color = expression.fontColor;
                        }
                    }
                    if (expression.expression == "endsWith") {
                        if (event.data[expression.fieldName].endsWith(replacedQuery)) {
                            event.rowElement.style.backgroundColor = expression.backgroundColor;
                            event.rowElement.style.color = expression.fontColor;
                        }
                    }
                    if (expression.expression == "equals") {
                        if (event.data[expression.fieldName] == replacedQuery) {
                            event.rowElement.style.backgroundColor = expression.backgroundColor;
                            event.rowElement.style.color = expression.fontColor;
                        }
                    }

                }

                if (expression.fieldType == "object") {
                    if (expression.expression == "equals") {
                        if (event.data[expression.fieldName].toString() == replacedQuery) {
                            event.rowElement.style.backgroundColor = expression.backgroundColor;
                            event.rowElement.style.color = expression.fontColor;
                        }
                    }
                }
            });
        }

    }

    currentColorExpression = { "id": 0, "backgroundColor": "", "fontColor": "", "fieldName": "", "fieldType": "", "conditionText": "" }
    addColorExpressions() {

        let data = JSON.parse(JSON.stringify(this.currentColorExpression))
        if (data.fieldName == '') return
        data.fieldName = this.currentColorExpression.fieldName
        data.id = this.currentColorExpression.id
        data.backgroundColor = this.currentColorExpression.backgroundColor
        data.fontColor = this.currentColorExpression.fontColor
        data.fieldType = this.currentColorExpression.fieldType
        data.conditionText = this.currentColorExpression.conditionText

        if (data.id != 0) {
            let updateIndex = this.data.colorExpressions.findIndex(f => f.id == data.id)
            if (typeof updateIndex != "undefined") {
                this.data.colorExpressions[updateIndex] = data
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        } else {
            if (this.data.colorExpressions.length == 0)
                data.id = 1
            else if (this.data.colorExpressions.length > 0)
                data.id = this.data.colorExpressions[this.data.colorExpressions.length - 1].id + 1

            this.data.colorExpressions.push(data)
        }
        this.currentColorExpression = { "id": 0, "backgroundColor": "", "fontColor": "", "fieldName": "", "fieldType": "", "conditionText": "" }
        
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }


    }
    deleteColorExpressions(i) {
        this.data.colorExpressions.splice(i, 1);
    }

    editRowcolor(colorExp) {
        this.currentColorExpression = colorExp
    }

    fieldTypesValueChanged = (e: any, header: any): void => {

        if (e.value.length && typeof e.event != "undefined") {
            if (e.value == "datetime")
                header.format = "dd/MM/yyyy HH:mm";
            else if (e.value == "date")
                header.format = "dd/MM/yyyy";
            else if (e.value == "double" || e.value == "int")
                header.format = "#,##0.00";
            else if (e.value == "file")
                header.width = "200";
        }
    }

    onValueChangeColorExpressionFieldSelect($event) {
        
        if (!!$event && !!$event.value) {
            if ($event.value == "") { this.currentColorExpression.fieldType = ""; return; }
            let typeObject = this.data.headers.find(el => el.field == $event.value)

            if (typeObject.type.toLowerCase() == "text") this.currentColorExpression.fieldType = "string"
            if (typeObject.type.toLowerCase() == "double") this.currentColorExpression.fieldType = "number"
            if (typeObject.type.toLowerCase() == "int") this.currentColorExpression.fieldType = "number"
            if (typeObject.type.toLowerCase() == "checkbox") this.currentColorExpression.fieldType = "object"
            if (typeObject.type.toLowerCase() == "date") this.currentColorExpression.fieldType = "object"
            if (typeObject.type.toLowerCase() == "datetime") this.currentColorExpression.fieldType = "object"
            if (typeObject.type.toLowerCase() == "datagrid") this.currentColorExpression.fieldType = "object"
            if (typeObject.type.toLowerCase() == "combobox") this.currentColorExpression.fieldType = "object"
            if (typeObject.type.toLowerCase() == "file") this.currentColorExpression.fieldType = "object"
            if (typeObject.type.toLowerCase() == "map") this.currentColorExpression.fieldType = "object"
            if (typeObject.type.toLowerCase() == "picturebox") this.currentColorExpression.fieldType = "object"
            if (typeObject.type.toLowerCase() == "fileviewer") this.currentColorExpression.fieldType = "object"

        }
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // Tüm charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    setLanguageToDatas() {
        this.fieldTypes = [
            { "type": "text", "value": "text", "name": "Text" },
            { "value": "double", "name": this.languageLocal.double /*"Ondalýk Sayý" */ },
            { "value": "int", "name": this.languageLocal.integer /*"Tam Sayý"*/ },
            { "value": "checkbox", "name": "Checkbox" },
            { "value": "date", "name": "Date" },
            { "value": "datetime", "name": "DateTime" },
            { "value": "datagrid", "name": "Data Grid" },
            { "value": "combobox", "name": "Combobox" },
            { "value": "file", "name": "File" },
            { "value": "map", "name": "Map" },
            { "value": "picturebox", "name": "Picturebox" },
            { "value": "fileviewer", "name": "File Viewer" }
        ];
    }
}
