import { Component, OnInit, Input, ChangeDetectorRef, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { GenericService, AppInfoService, ReportService } from '../../shared/services';
import { DashboardControl, ResourceManager } from 'devexpress-dashboard';
import { Subscription } from 'rxjs';
import { debug } from 'console';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.css']
})
export class DxGalleryComponent implements ComponentParameters, OnInit, AfterViewInit, OnDestroy {
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;
    @Input() anotherPopupId: number = 0;

    showDisplay: any = "block";

    ismonacoActive: any = false;
    invokeChangeEventLocal: Subscription;
    invokeGalleryChangeEventLocal: Subscription;

    layoutEnum = { tile: 1, slider: 2, list: 3 };
    imageFormatEnum = { base64: 1, binary: 2 };


    imageFormats = [{ "format": "1", "name": "Base64" }, { "format": "2", "name": "Binary" }];
    layouts = [{ "value": 1, "name": "Galeri" }, { "value": 2, "name": "Slider" }, { "value": 3, "name": "List" }];
    currencyAll = [{ "value": '₺', "name": "TL" }, { "value": '$', "name": "Dolar" }, { "value": '€', "name": "Euro" }, { "value": '£', "name": "Sterlin" }, { "value": '₽', "name": "Ruble" }];
    //gridCols = ["1", "2", "3", "4", "6", "12"];

    filterTypeEnum = {
        "showAll": 1,
        "showByCategory": 2,
        "showSelected": 3,
        "showBySearch": 4,
        "showByStock": 5
    }
    //filterTypes = [
    //    { "value": 1, "name": "Tümünü Göster" },
    //    { "value": 2, "name": "Kategoriye Göre Göster" },
    //    { "value": 3, "name": "Seçilenleri Göster" }
    //];
    gridCols = [
        { "value": "2", "name": "6" },
        { "value": "3", "name": "4" },
        { "value": "4", "name": "3" },
        { "value": "6", "name": "2" },
        { "value": "12", "name": "1" }
    ];
    itemPossition = [{ "value": "bottom", "name": "Fotoğrafın Altı" }, { "value": "top", "name": "Fotoğrafın Üstü" }];

    categories = [];
    allData: any = [];
    galeryData: any = [];
    selectedCategory: any;
    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    quantityPermissionPopup: boolean = false;
    pricePermissionPopup: boolean = false;
    photosPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    isMonacoLoaded: boolean = false;
    imageZoomPopup: boolean = false;
    imageVariantZoomPopup: boolean = false;
    isThumbtack: boolean = true;

    selectedItemSrc: any;
    selectedCategoryId: number;

    connections: any = [];
    tableList: any = [];
    columnList: any = [];
    selectedTable = "";

    formElements: any;
    headerText = "Tümü";
    dashboards: any = []

    galleryChangeColumn = [];
    languageLocal: any = "";

    private dashboardControl!: DashboardControl;
    constructor(private eventEmitterService: EventEmitterService,
        public appInfo: AppInfoService,
        private element: ElementRef,
        public changeDetectorRef: ChangeDetectorRef,
        public reportService: ReportService,
        public componentService: ComponentService,
        private genericService: GenericService) {
        ResourceManager.embedBundledResources();
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
                let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
                this.formElements = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId).map(m => m.id);
            }
        });
        this.languageLocal = this.componentService.getLanguageSource(); //language
        this.setLanguageToDatas()


        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {
            if (sub.elementId == this.data.id) {

                this.applyImagesToModel(sub.data, true, true);
                /*setTimeout(f => {
                  this.changeDetectorRef.detectChanges();
                }, 300);*/
            }
        });


        this.invokeGalleryChangeEventLocal = this.eventEmitterService.invokeGalleryChangeEvent.subscribe(sub => {
            if (this.data.id != sub.id && sub.elementId == this.data.id) {

                if (sub.data) {
                    if (this.galeryData.length != 0) {
                        this.galleryChangeColumn = sub.data;
                        if (this.selectedCategory == null)
                            this.onFilterValueChanged(this.filterTypeEnum.showAll);
                        else
                            this.onFilterValueChanged(this.filterTypeEnum.showByCategory);
                        //  this.onSearchChange(this.gallerySearch);
                    }
                    else
                        this.galleryChangeColumn = sub.data;

                    this.calculateTotal();
                    //if (this.galeryData.length != 0) {
                    //    for (let i = 0; i < this.galeryData.length; i++) {
                    //        sub.data.forEach(val => {
                    //            this.galeryData[i][val.column] = val.value;
                    //        });
                    //    }
                    //    this.galleryChangeColumn = sub.data;
                    //}
                    //else {
                    //    this.galleryChangeColumn = sub.data;
                    //}
                }
            }
        });


    }
    changeAllCheckbox(e) {
        //showGridHeader
        this.data.storage.sqlHideColumns.forEach(field => {
            field.showGridHeader = e.value;
        });
    }
    getMaxQuantityColumnVisible(_data) {
        return _data.storage.maxQuantityColumn != '' && typeof _data.storage.maxQuantityColumn != 'undefined';
    }
    changeStorageColumns() {
        // this.data.storage.sqlHideColumns = [];
        //let keysSearch = this.allColumnsSql.filter(f =>
        //     f != this.data.storage.keyColumn &&
        //     f != this.data.storage.categoryColumn &&
        //     f != this.data.storage.maxQuantityColumn &&
        //     f != this.data.storage.photoColumn &&
        //     f != this.data.storage.priceColumn &&
        //     f != this.data.storage.videoColumn &&
        //     f != this.data.storage.titleColumn);

        // keysSearch.forEach(item => {
        //     let _t: any = {};
        //     _t.name = item;
        //     _t.hideColumn = false;
        //     this.data.storage.sqlHideColumns.push(_t);
        // });
    }
    defaultHideColumn = false;
    allColumnsSql = [];
    sqlValueSetAll() {
        this.data.storage.sqlHideColumns = [];
        if (this.data.storage.query != "" && this.data.storage.connectionStringId > 0) {
            this.genericService.executeQuerySecurited(this.data.storage.query, this.data.storage.connectionStringId).subscribe((result: any) => {
                if (result && result.length > 0) {

                    this.allColumnsSql = Object.keys(result[0]);
                    let keysSearch = [];
                    keysSearch = this.allColumnsSql.filter(f =>
                        f != this.data.storage.keyColumn &&
                        f != this.data.storage.categoryColumn &&
                        f != this.data.storage.maxQuantityColumn &&
                        f != this.data.storage.photoColumn &&
                        f != this.data.storage.priceColumn &&
                        f != this.data.storage.videoColumn &&
                        f != this.data.storage.titleColumn);

                    keysSearch.forEach(item => {
                        let _t: any = {};
                        _t.name = item;
                        _t.hideColumn = false;
                        _t.showOnDetail = false;
                        this.data.storage.sqlHideColumns.push(_t);
                    });
                    //try {
                    //    this.changeDetectorRef.detectChanges();
                    //} catch (ex) { }


                    //result.forEach(item => {
                    //    let cat: any = {};
                    //    cat.name = item[this.data.category.valueCol]; 
                    //    this.categories.push(cat);
                    //});
                    //try {
                    //    this.changeDetectorRef.detectChanges();
                    //} catch (ex) { }
                }

            });
        }

    }
    tabSelectionChanged(event) {
        this.ismonacoActive = false;
        this.isMonacoLoaded = false;
        setTimeout(f => { this.ismonacoActive = true; this.isMonacoLoaded = true; }, 800);
    }
    photosPopupOpen() {
        this.photosPopup = true;
        setTimeout(f => { this.ismonacoActive = true; }, 1000);
    }
    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
        this.invokeGalleryChangeEventLocal.unsubscribe();
    }
    ngOnInit() {

        this.data.uuid = this.componentService.createuuid();
        this.connections = this.appInfo.RiverEntity.connections;

        if (this.isEdit) this.getDashboards();


        //componentService.currentProcessFormId currentProcessFormId
        if (!this.isEdit) {
            this.loadCategories();
            // this.prepareImages();
        }
        //data.maxQuantity
        this.data.isQuantityEditable = typeof this.data.isQuantityEditable == "undefined" ? false : this.data.isQuantityEditable;
        this.data.showVariant = typeof this.data.isQuantityEditable == "undefined" ? false : this.data.showVariant;
        this.data.options.QuantityCategoryDisable = typeof this.data.options.QuantityCategoryDisable == "undefined" ? false : this.data.options.QuantityCategoryDisable;
        this.data.options.ProductImageHide = typeof this.data.options.ProductImageHide == "undefined" ? false : this.data.options.ProductImageHide;
        this.data.options.ProductImageHide = typeof this.data.options.ProductImageHide == "undefined" ? false : this.data.options.ProductImageHide;
        this.data.pagingSize = typeof this.data.pagingSize == "undefined" ? 12 : this.data.pagingSize;
        this.data.disableQuantityTextbox = typeof this.data.disableQuantityTextbox == "undefined" ? false : this.data.disableQuantityTextbox;
        this.pageLength = this.data.pagingSize;

        this.data.images = typeof this.data.images == "undefined" ? { connectionId: 0 } : this.data.images;

        this.data.variants = typeof this.data.variants == "undefined" ? { connectionId: 0 } : this.data.variants;

        this.data.variants.images = typeof this.data.variants.images == "undefined" ? { connectionId: 0 } : this.data.variants.images;


        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;
        this.data.showVariant = typeof this.data.showVariant == "undefined" ? true : this.data.showVariant;


        this.showDisplay = !this.isEdit ? (!this.data.hide && this.data.outsideVisible ? "block" : "none") : "block";


        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (!this.data.hide && this.data.outsideVisible ? "block" : "none") : "block";

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });

        this.data.storage.totalColumnElements = typeof this.data.storage.totalColumnElements == "undefined" ? {} : this.data.storage.totalColumnElements;
        this.data.storage.multiplesOfQuantity = typeof this.data.storage.multiplesOfQuantity == "undefined" ? 1 : this.data.storage.multiplesOfQuantity;
        this.data.storage.queryPrice = typeof this.data.storage.queryPrice == "undefined" ? "" : this.data.storage.queryPrice;
        this.data.storage.queryQuantity = typeof this.data.storage.queryQuantity == "undefined" ? "" : this.data.storage.queryQuantity;
        try {
            this.data.storage.sqlHideColumns.forEach(element => {
                element.showOnDetail = typeof element.showOnDetail == "undefined" ? false : element.showOnDetail;
            });
        } catch { }
        this.data.storage.pricePermissions = typeof this.data.storage.pricePermissions == "undefined" ? { "readonlyGroup": [], "readonlyUsers": [], "hideGroup": [], "hideUsers": [] } : this.data.storage.pricePermissions;
        this.data.storage.quantityPermissions = typeof this.data.storage.quantityPermissions == "undefined" ? { "readonlyGroup": [], "readonlyUsers": [], "hideGroup": [], "hideUsers": [] } : this.data.storage.quantityPermissions;

        if (!this.isEdit) this.checkUserAuthorization();
    }

    checkUserAuthorization() {

        let userId = this.appInfo.currentUser.id;

        if (typeof this.data.storage.pricePermissions != "undefined") {
            //let price_rGroupIndex = this.data.storage.pricePermissions.readonlyGroup.indexOf(userId);
            //let price_rUsersIndex = this.data.storage.pricePermissions.readonlyUsers.indexOf(userId);
            let price_hGroupIndex = this.data.storage.pricePermissions.hideGroup.indexOf(userId);
            let price_hUsersIndex = this.data.storage.pricePermissions.hideUsers.indexOf(userId);

            if (price_hGroupIndex !== -1 || price_hUsersIndex !== -1) {
                this.data.isPriceEditable = false;
            }
        }

        if (typeof this.data.storage.quantityPermissions != "undefined") {

            //let quantity_rGroupIndex = this.data.storage.pricePermissions.readonlyGroup.indexOf(userId);
            //let quantity_rUsersIndex = this.data.storage.pricePermissions.readonlyUsers.indexOf(userId);
            let quantity_hGroupIndex = this.data.storage.quantityPermissions.hideGroup.indexOf(userId);
            let quantity_hUsersIndex = this.data.storage.quantityPermissions.hideUsers.indexOf(userId);

            if (quantity_hGroupIndex !== -1 || quantity_hUsersIndex !== -1) {
                this.data.isQuantityEditable = false;
            }
        }

    }

    ngAfterViewInit(): void {
        if (!this.isEdit && this.data.dashboardId > 0) {
            this.dashboardControl = new DashboardControl(this.element.nativeElement.querySelector(".gallery-dashboard-container"), {
                endpoint: this.appInfo.appUrl + "api/dashboard",//"http://localhost:51857/api/dashboard/",
                initialDashboardId: this.data.dashboardId + "_" + this.appInfo.currentUser.id,
                workingMode: "Viewer",
            });
            this.dashboardControl.render();
        }
    }

    getDashboards() {
        this.reportService.getReportWithoutCategory().subscribe(result => {
            this.dashboards = result;
        });
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (!this.data.hide && this.data.outsideVisible ? "block" : "none") : "block";
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    loadCategories() {

        if (this.data.category.query != "" && this.data.category.connectionId > 0) {
            this.genericService.executeQuerySecurited(this.data.category.query, this.data.category.connectionId).subscribe((result: any) => {
                if (result && result.length > 0) {
                    result.forEach(item => {
                        let cat: any = {};
                        cat.value = item[this.data.category.valueCol];
                        cat.name = item[this.data.category.displayCol];
                        this.categories.push(cat);
                    });
                    try {
                        this.changeDetectorRef.detectChanges();
                    } catch (ex) { }
                }

                this.prepareImagesPageLoad();

            });
        }
    }

    changeThumbtack() {
        this.isThumbtack = !this.isThumbtack;
    }
    //onCategoryItemClick(e) {
    //  this.selectedCategory = e.itemData;
    //  this.headerText = e.itemData.name;
    //  let val = e.itemData.valu e;
    //  let filteredData =  this.allData.filter(f => f[this.data.storage.categoryColumn]==val);
    //  this.applyImagesToModel(filteredData, false);
    //  this.data.category.filterType = this.filterTypeEnum.showByCategory;
    //}

    filterByCategory(category) {
        this.gallerySearch = "";
        this.selectedCategory = category;
        this.headerText = category.name;
        let filteredData = this.allData.filter(f => f[this.data.storage.categoryColumn] == category.value);
        this.applyImagesToModel(filteredData, true, true);
        this.data.category.filterType = this.filterTypeEnum.showByCategory;
    }

    gallerySearch = "";

    onEnterKey(e) {
        this.onSearchChange(this.gallerySearch);
    }
    onSearchChange(searchValue: string): void {

        let category = this.selectedCategory;
        //  this.gallerySearch = searchValue;
        //let ggg = this.selectedCategory;
        //    this.filterTypeEnum.showSelected
        let filteredTemp = this.allData.filter(f => typeof this.selectedCategory == "undefined" || this.selectedCategory == null || f[this.data.storage.categoryColumn] == category.value);
        //let filteredTemp;
        //if (this.filterType == this.filterTypeEnum.showByCategory)
        //    filteredTemp = this.allData.filter(f => typeof this.selectedCategory == "undefined" || this.selectedCategory == null || f[this.data.storage.categoryColumn] == category.value);
        //else if (this.filterType == this.filterTypeEnum.showAll)
        //    filteredTemp = this.allData;
        //else if (this.filterType == this.filterTypeEnum.showAll)
        //    filteredTemp = this.allData.filter(f => f[this.data.storage.maxQuantityColumn] > 0);


        let keysTemp = Object.keys(this.allData[0]);
        let keysSearch = [];
        keysSearch = keysTemp.filter(f =>
            f != this.data.storage.keyColumn &&
            f != this.data.storage.categoryColumn &&
            f != this.data.storage.maxQuantityColumn &&
            f != this.data.storage.photoColumn &&
            f != this.data.storage.priceColumn &&
            f != this.data.storage.videoColumn);



        let filteredData = filteredTemp.filter(function (currentElement) {
            let returnVal = false;
            keysSearch.forEach(f => {
                if (!returnVal && currentElement[f] != null && typeof currentElement[f] != "undefined") {
                    try {
                        returnVal = currentElement[f].toString().toLowerCase().indexOf(searchValue.toLowerCase()) != -1;
                    } catch (ex) {
                        returnVal = currentElement[f].indexOf(searchValue.toLowerCase()) != -1;
                    }
                }
            });
            return returnVal;
        });


        // let filteredData = this.allData.filter(f => f[this.data.storage.titleColumn].toLowerCase().indexOf(this.gallerySearch.toLowerCase()) > -1 && (typeof this.selectedCategory == "undefined" || f[this.data.storage.categoryColumn] == category.value));
        this.applyImagesToModel(filteredData, true, true);
        this.data.category.filterType = this.filterTypeEnum.showBySearch;

    }

    //filterMultiple(_obj) { 

    //}

    //flexFilter = function (data,info) {

    //     // Set our variables
    //     var matchesFilter, matches = [], count = 0;

    //     /***********
    //       Helper function to loop through the filter criteria to find 
    //       matching values.
    //      Each filter criteria is treated as "AND". So each item must 
    //       match all the filter criteria to be considered a match.
    //      Multiple filter values in a filter field are treated as "OR"
    //       i.e. ["Blue", "Green"] will yield items matching a value 
    //       of Blue OR Green.
    //     ***********﻿*/
    //     matchesFilter = function (item) {
    //         for (var n = 0; n < info.length; n++) {
    //             if (info[n]["Values"].indexOf(item[info[n]["Field"]]) > -1) {
    //                 count++;
    //             }
    //         }
    //         // If TRUE, then the current item in the array meets 
    //         // ﻿all the filter criteria
    //         return count == info.length;
    //     }

    //     // Loop through each item in the array
    //    for (var i = 0; i < data.length; i++) {

    //         // Determine if the current item matches the filter criteria
    //        if (matchesFilter(data[i])) {
    //            matches.push(data[i]);
    //         }

    //     }

    //     // Give us a new array containing the objects matching the filter criteria
    //     return matches;
    // }


    prepareImagesPageLoad() {
        if (this.data.storage.query != "" && this.data.storage.connectionStringId > 0) {
            this.genericService.executeQuerySecurited(this.data.storage.query, this.data.storage.connectionStringId).subscribe(result => {
                if (result && result.length > 0) {
                    this.allData = result;

                    if (this.componentService.currentProcessFormId != 0) {
                        this.onFilterValueChanged(3);
                    }
                }

            });
        }
    }

    prepareImages() {
        if (this.data.storage.query != "" && this.data.storage.connectionStringId > 0) {
            this.genericService.executeQuerySecurited(this.data.storage.query, this.data.storage.connectionStringId).subscribe(result => {
                if (result && result.length > 0) {
                    this.allData = result;

                    if (this.componentService.currentProcessFormId != 0) {
                        this.onFilterValueChanged(3);
                    }
                    else
                        this.applyImagesToModel(result, true, true);

                }

            });
        }
    }

    filterType = this.filterTypeEnum.showAll;
    onFilterValueChanged(_val) {
        this.filterType = _val;
        this.galeryData = [];
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        let filteredData = [];
        if (_val == this.filterTypeEnum.showAll) {
            filteredData = this.allData;
            this.headerText = this.languageLocal.all;
            this.selectedCategory = null;
        }
        else if (_val == this.filterTypeEnum.showByCategory) {
            if (this.selectedCategory) {
                this.headerText = this.selectedCategory.name;
                filteredData = this.allData.filter(f => f[this.data.storage.categoryColumn] == this.selectedCategory.value);
            }
            else {
                this.headerText = "";
            }
        }
        else if (_val == this.filterTypeEnum.showSelected) {
            this.headerText = this.languageLocal.selected;
            this.selectedCategory = null;
            this.allData.forEach(f => {
                let item = this.data.value.find(k => k.galleryKey == f[this.data.storage.keyColumn]);

                if (item) {
                    f.quantity = item.galleryQuantity;
                    f.unit = item.galleryUnit;
                    filteredData.push(f);
                }
            });

            if (filteredData.length <= 0) this.galeryData = [];
        }
        else if (_val == this.filterTypeEnum.showByStock) {
            this.headerText = this.languageLocal.inStock;
            //this.selectedCategory = null;
            if (this.selectedCategory == null) {
                filteredData = this.allData.filter(f => f[this.data.storage.maxQuantityColumn] > 0);
            } else {
                filteredData = this.allData.filter(f => f[this.data.storage.maxQuantityColumn] > 0 && f[this.data.storage.categoryColumn] == this.selectedCategory.value);
            }


            if (filteredData.length <= 0) this.galeryData = [];
        }
         
        this.applyImagesToModel(filteredData, true, true);
    }

    imageHeightFunc(_cols) {
        //background: #CCCCCC;width: 100%;display: flex;
        var height = _cols == '12' ? '70vw' : _cols == '6' ? '35vw' : _cols == '3' ? '15vw' : _cols == '2' ? '9vw' : '15vw';
        const styles = { 'height': height, 'background': '#CCCCCC', 'width': '100%', 'display': 'flex' };
        return styles;
    }

    listImageTableStyle(hideImage) {
        //background: #CCCCCC;width: 100%;display: flex;
        var width = hideImage ? '23px' : "57px";
        const styles = { 'height': '57px', 'width': width, 'max-width': width, 'padding': '4px' };
        return styles;
    }

    onChangePage(e) {//: Array<any>

        this.htmlDomChange();

        // (<HTMLElement><unknown>(<HTMLElement><unknown>document.getElementsByClassName("page-item first-item")).children)[0].innerHTML = "";

        if (e.length > 0) {
            let _position = (e[0].ID / this.pageLength) + 1;//(e[0].ID == 0 ? 1 : (e[0].ID - this.pageLength + 2));
            if (this.currentPageNumber != _position) {
                this.currentPageNumber = _position;
                this.applyImagesToModel(this.fullTotalData, true, false);
            }
        }
    }

    htmlDomChange() {

        try { (<HTMLElement><unknown>((document.querySelectorAll(".page-item.first-item > a.page-link"))[0])).innerHTML = "<<"; } catch (ex) { }
        try { (<HTMLElement><unknown>((document.querySelectorAll(".page-item.previous-item > a.page-link"))[0])).innerHTML = "<"; } catch (ex) { }
        try { (<HTMLElement><unknown>((document.querySelectorAll(".page-item.last-item > a.page-link"))[0])).innerHTML = ">>"; } catch (ex) { }
        try { (<HTMLElement><unknown>((document.querySelectorAll(".page-item.next-item > a.page-link"))[0])).innerHTML = ">"; } catch (ex) { }
        try { (<HTMLElement><unknown>((document.querySelectorAll("jw-pagination > ul.pagination"))[0])).style.cssText = "justify-content: center; margin-top: 10px;"; } catch (ex) { }

    }


    currentPageNumber = 1;
    totalPageAll = 0;
    pageLength = 0;
    emptyTotalData = [];
    fullTotalData = [];
    //fillAllGaleryCategory
    applyImagesToModel(imagesData, applyDetect, currentPageClear) {

        this.totalPageAll = imagesData.length;

        if (currentPageClear) {
            this.currentPageNumber = 1;
            this.emptyTotalData = [];
            for (let i = 0; i < imagesData.length; i++) {
                this.emptyTotalData.push({ ID: i, NAME: "" });
            }
            //  if (applyDetect) {
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            //  }

        }
        //this.emptyTotalData = imagesData;
        //if (applyDetect) 
        //    this.changeDetectorRef.detectChanges();

        this.fullTotalData = imagesData;
        this.galeryData = [];
        if (imagesData && imagesData.length > 0) {
            let columns = Object.keys(imagesData[0]); //Kolon isimleri(ilk data kolon ismi tüm datalar için aynı)

            let _i = (this.currentPageNumber - 1) * this.pageLength;
            let forTwo = (this.pageLength * this.currentPageNumber) > imagesData.length ? imagesData.length : (this.pageLength * this.currentPageNumber); // (imagesData.length - (_i * this.currentPageNumber)) > (this.pageLength + 8) ? this.pageLength : imagesData.length; //(imagesData.length - (_i * this.currentPageNumber));


            // this.emptyTotalData = 
            for (let i = _i; i < forTwo; i++) {
                let data: any = {};
                data.fields = [];
                let src = imagesData[i][this.data.storage.photoColumn]; //fotoğraf 
                let str = src ? src.substring(0, 5) : "";
                if (str && str == "data:") data.src = src; // fotoğraf data tipi mime type varsa
                else data.src = 'data:image/jpg;base64,' + src;
                data.title = this.galleryChangeColumns(imagesData[i], this.data.storage.titleColumn, imagesData[i][this.data.storage.titleColumn]);
                data.price = this.galleryChangeColumns(imagesData[i], this.data.storage.priceColumn, imagesData[i][this.data.storage.priceColumn]);

                if (this.data.storage.maxQuantityColumn != null && typeof this.data.storage.maxQuantityColumn != "undefined" && this.data.storage.maxQuantityColumn != "") {
                    data.maxQuantity = this.galleryChangeColumns(imagesData[i], this.data.storage.maxQuantityColumn, imagesData[i][this.data.storage.maxQuantityColumn]);
                }
                else {
                    data.maxQuantity = 99999999;
                }
                data.key = imagesData[i][this.data.storage.keyColumn];
                //data.imageheight = this.data.options.cols == '4' ? '15vw' : this.data.options.cols == '6' ? '9vw' : '15vw'

                //Check if column hided or show on detail 
                let dataStorageSqlHideColumns = this.data.storage.sqlHideColumns.filter(x => x.hideColumn == true || x.showOnDetail == true).map(m => m.name);

                data.columns = columns.filter(f =>
                    f != this.data.storage.photoColumn
                    && f != this.data.storage.keyColumn
                    && f != this.data.storage.videoColumn
                    && f != this.data.storage.titleColumn
                    && f != this.data.storage.priceColumn
                    && f != this.data.storage.maxQuantityColumn
                    && dataStorageSqlHideColumns.indexOf(f) == -1);

                //check if column is not hidden and show on detail
                debugger;
                let dataStorageSqlDetailColumns = this.data.storage.sqlHideColumns.filter(x => x.hideColumn == false && x.showOnDetail == true).map(m => m.name);

                data.showOnDetailColumns = columns.filter(f =>
                    f != this.data.storage.photoColumn
                    && f != this.data.storage.keyColumn
                    && f != this.data.storage.videoColumn
                    && f != this.data.storage.titleColumn
                    && f != this.data.storage.priceColumn
                    && f != this.data.storage.maxQuantityColumn
                    && f != this.data.storage.categoryColumn
                    && dataStorageSqlDetailColumns.includes(f) == true);

                data.showDetail = false;

                data.isEditable = false;
                data.isEditableQuantity = false;
                data.videoUrl = imagesData[i][this.data.storage.videoColumn];
                let item = this.data.value.find(f => f.galleryKey == data.key);
                if (item) {
                    data.quantity = item.galleryQuantity;
                    data.unit = item.galleryUnit;
                    data.price = this.galleryChangeColumns(imagesData[i], this.data.storage.priceColumn, item.galleryPrice)// item.galleryPrice;
                    data.variantName = item.variantName;
                    data.variantId = item.variantId;
                }
                else {
                    data.quantity = 0;
                    data.unit = "";
                }

                //sorgudan gelen kolon isim ve değerleri alınıyor
                let obj: any = {};
                columns.forEach(f => {
                    if (f != this.data.storage.photoColumn
                        && f != this.data.storage.keyColumn
                        && f != this.data.storage.videoColumn
                        && f != this.data.storage.titleColumn
                        && f != this.data.storage.priceColumn) {
                        obj[f] = imagesData[i][f];
                    }
                });
                data.fields = obj;


                //Birimler için tanımlanmış sql script çalıştırılıyor
                data.unitItems = [];
                if (this.data.storage.unitQuery != "" && this.data.storage.unitConnectionId > 0) {
                    let query = this.data.storage.unitQuery;
                    columns.forEach(f => {
                        if (f != this.data.storage.photoColumn) {
                            let pref = "{" + f + "}";
                            let value = imagesData[i][f];
                            query = this.componentService.replaceAllUsingType(query, pref, value);//query.replace(pref, value);
                        }
                    });
                    this.genericService.executeQuerySecurited(query, this.data.storage.unitConnectionId).subscribe(result => {
                        if (result && result.length > 0) {
                            let keys = Object.keys(result[0]);
                            for (let i = 0; i < result.length; i++) {
                                if (keys.length > 0) {
                                    let finalData = [];
                                    keys.forEach(f => {
                                        finalData.push(result[i][f]);
                                    });
                                    let unitText = finalData.join(",");
                                    data.unitItems.push(unitText);
                                }
                            }
                        }
                        let galleryDataLocal = this.componentService.cloneModel(data);
                        galleryDataLocal.unit = galleryDataLocal.unitItems.length > 0 ? galleryDataLocal.unitItems[0] : "";
                        this.galeryData.push(galleryDataLocal);

                        this.categoryDisabled = this.galeryData.filter(f => f.quantity > 0).length > 0;

                        if (applyDetect && this.galeryData.length == (forTwo - _i)) {

                            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                        }
                    });
                }
                else {

                    let galleryDataLocal = this.componentService.cloneModel(data);
                    galleryDataLocal.unit = galleryDataLocal.unitItems.length > 0 ? galleryDataLocal.unitItems[0] : "";

                    this.galeryData.push(galleryDataLocal);

                    this.categoryDisabled = this.galeryData.filter(f => f.quantity > 0).length > 0;

                    if (applyDetect && this.galeryData.length == (forTwo - _i)) {
                        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                    }
                }
            }
        }

    }

    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace).replace(/[\u200B-\u200D\uFEFF]/g, '');
    }

    galleryChangeColumns(galleryVal, column, defaultVal) {
        if (typeof this.galleryChangeColumn.find(x => x.column == column) != "undefined") {
            let _valTemp;
            let _val = this.galleryChangeColumn.find(x => x.column == column).value;
            var pattern = /\[(\w)+\]/ig;
            var patt = new RegExp(pattern);
            if (patt.test(_val)) {
                let _match = _val.match(pattern);
                if (_match.length > 0) {
                    _match.forEach(f => {

                        _valTemp = _val.replace(f, galleryVal[f.replace("[", "").replace("]", "")].toString());
                    });
                }
                else
                    _valTemp = _val;
            }
            else
                _valTemp = _val;

            if (typeof _valTemp != "undefined" && _valTemp != "") {
                try {
                    _valTemp = eval(_valTemp);
                } catch (e) {
                    _valTemp = _valTemp;
                }
            }
            else
                _valTemp = "";

            return _valTemp;
            //_val.replaceAll();
            //galleryVal[]
        }
        else
            return defaultVal
    }

    variantsData: any = [];
    variantsGaleryData: any = [];
    variantGalerySelect: any = 0;
    variantsPopup: any = false;
    openVariantPopup(galery) {

        this.variantsPopup = true;

        if (this.data.variants.query != "" && this.data.variants.connectionId > 0) {
            var date = new Date();
            var timestamp = date.getTime();

            this.variantGalerySelect = galery.key;

            let _query = "SELECT * FROM(" + this.data.variants.query + ") [IMAGES " + timestamp + "] WHERE " + this.data.variants.joinColumn + "='" + galery.key + "'";
            this.genericService.executeQuerySecurited(_query, this.data.category.connectionId).subscribe((result: any) => {
                if (result && result.length > 0) {

                    result.forEach(item => {
                        let _var: any = {};

                        let _src = item[this.data.variants.image];
                        let str = _src ? _src.substring(0, 5) : "";
                        if (str && str == "data:") _var.src = _src; // fotoğraf data tipi mime type varsa
                        else _var.src = 'data:image/jpg;base64,' + _src;

                        _var.title = item[this.data.variants.name];
                        _var.id = item[this.data.variants.id];
                        this.variantsData.push(_var);
                    });

                    try {
                        this.changeDetectorRef.detectChanges();
                    } catch (ex) { }
                }

            });
        }

    }

    selectVariant(_id, _title) {

        this.variantsData = [];
        this.variantsPopup = false;

        let _gal = this.galeryData.find(x => x.key == this.variantGalerySelect);
        if (_gal) {
            _gal.variantName = _title;
            _gal.variantId = _id;
        }

        let item = this.data.value.find(f => f.galleryKey == this.variantGalerySelect);
        if (item) {
            item.variantName = _title;
            item.variantId = _id;
        }

        let itemVariants = this.variantsGaleryData.find(f => f.id == this.variantGalerySelect);
        if (itemVariants) {
            itemVariants.variantName = _title;
            itemVariants.variantId = _id;
            itemVariants.id = this.variantGalerySelect;
        }
        else {
            let itemVariantObj = { variantName: "", variantId: 0, id: 0 };
            itemVariantObj.variantName = _title;
            itemVariantObj.variantId = _id;
            itemVariantObj.id = this.variantGalerySelect;
            this.variantsGaleryData.push(itemVariantObj);
        }

        try {
            this.changeDetectorRef.detectChanges();
        } catch (ex) { }
    }

    onVariantsPopupHidden() {
        this.variantsData = [];
    }

    convertBinaryToBase64(imgByte) {
        let TYPED_ARRAY = new Uint8Array(imgByte); //binary data gelecek
        const STRING_CHAR = String.fromCharCode.apply(null, TYPED_ARRAY);
        let base64String = btoa(STRING_CHAR);
        return 'data:image/jpg;base64,' + base64String; //this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + base64String);
        /*const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {
            return data + String.fromCharCode(byte);
        }, ''));*/
    }

    connectionListValueChanged(event) {
        if (event.value > 0) {
            this.genericService.getTables(event.value).subscribe((result: any) => {
                this.tableList = result;
            });
        }
    }


    tablesValueChanged() {
        this.data.storage.query = "SELECT * FROM " + this.selectedTable;
        /*if (this.data.storage.connectionStringId > 0 && this.selectedTable != "") {
          this.genericService.getTableColumns(this.selectedTable, this.data.storage.connectionStringId).subscribe((result: any) => {
            this.columnList = result;
          });
        }*/
    }

    monacoOnInit() {
        this.isMonacoLoaded = true;
    }

    setVariantsSelected(key) {
        let itemWithVariant = this.data.value.find(f => f.galleryKey == key);
        let _variant = this.variantsGaleryData.find(f => f.id == key);
        if (typeof itemWithVariant != "undefined" && typeof _variant != "undefined") {//typeof this.selectedCategory == "undefined"
            itemWithVariant.variantName = _variant.variantName;
            itemWithVariant.variantId = _variant.variantId;
        }
    }

    increase(key) {
        this.data.storage.multiplesOfQuantity = typeof this.data.storage.multiplesOfQuantity == "undefined" ? 1 : this.data.storage.multiplesOfQuantity;
        let data_local = this.galeryData.find(f => f.key == key);
        let item = this.data.value.find(f => f.galleryKey == key);
        if (data_local.quantity < data_local.maxQuantity) {
            data_local.quantity = data_local.quantity + this.data.storage.multiplesOfQuantity;

            if (item) {
                item.galleryQuantity = data_local.quantity;
            }
            else {
                let val: any = data_local.fields;
                val.galleryKey = data_local.key;
                val.galleryTitle = data_local.title;
                val.galleryPrice = data_local.price;
                val.galleryQuantity = data_local.quantity;
                val.galleryUnit = data_local.unit;
                this.data.value.push(val);
            }

        }

        this.setVariantsSelected(key);

        this.categoryDisabled = this.galeryData.filter(f => f.quantity > 0).length > 0;

        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        //  try { this.calculateTotal(); } catch (ex) {  }
    }

    decrease(key) {
        this.data.storage.multiplesOfQuantity = typeof this.data.storage.multiplesOfQuantity == "undefined" ? 1 : this.data.storage.multiplesOfQuantity;

        let data_local = this.galeryData.find(f => f.key == key);
        let item = this.data.value.find(f => f.galleryKey == key);
        //let item = this.data.value[this.data.value.indexOf(data)];
        if (data_local.quantity > 0) {
            data_local.quantity = data_local.quantity - this.data.storage.multiplesOfQuantity;
            item.galleryQuantity = data_local.quantity;// item.galleryQuantity - 1;
            if (item.galleryQuantity <= 0) {
                this.data.value.splice(this.data.value.indexOf(item), 1);
            }

        }

        this.setVariantsSelected(key);

        this.categoryDisabled = this.galeryData.filter(f => f.quantity > 0).length > 0;

        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        //   try { this.calculateTotal(); } catch (ex) { }

    }

    categoryDisabled = false;
    onQuantityChange(e, key) {
        this.data.storage.multiplesOfQuantity = typeof this.data.storage.multiplesOfQuantity == "undefined" ? 1 : this.data.storage.multiplesOfQuantity;
        let data_local = this.galeryData.find(f => f.key == key);
        let item = this.data.value.find(f => f.galleryKey == key);
        if (item) {
            if (data_local.quantity > 0) {
                if (data_local.quantity <= data_local.maxQuantity) {
                    item.galleryQuantity = data_local.quantity;
                }
                else {
                    item.galleryQuantity = data_local.maxQuantity;
                }
            }
            else {
                this.data.value.splice(this.data.value.indexOf(item), 1);
            }
        }
        else if (data_local.quantity > 0) {
            let val: any = data_local.fields;
            val.galleryKey = data_local.key;
            val.galleryTitle = data_local.title;
            val.galleryPrice = data_local.price;
            val.galleryQuantity = data_local.quantity <= data_local.maxQuantity ? data_local.quantity : data_local.maxQuantity;
            val.galleryUnit = data_local.unit;
            this.data.value.push(val);
        }

        this.setVariantsSelected(key);

        this.categoryDisabled = this.galeryData.filter(f => f.quantity > 0).length > 0;

        this.calculateTotal();
    }

    categoryAHrefClasses(catVal) {
        return this.categoryDisabled && this.data.options.QuantityCategoryDisable ? "gallery-menu-items disable-anchor-tag" : "gallery-menu-items";
    }

    categoryLiClasses(catVal) {
        let classActive = this.selectedCategory && this.selectedCategory.value == catVal ? "active" : "";
        let classDisable = this.categoryDisabled && this.data.options.QuantityCategoryDisable ? " disable-anchor-tag" : "";
        return classDisable + classActive;
    }

    calculateTotal() {

        if ((this.data.storage.totalColumn || !this.data.isVideoGallery) && this.data.storage.totalColumnElement) {
            let total = 0;
            this.data.value.forEach(item => {
                let priceNewObj = this.allData.find(f => f[this.data.storage.keyColumn] == item.galleryKey);
                if (priceNewObj) {
                    let newPrice = this.galleryChangeColumns(priceNewObj, this.data.storage.priceColumn, item.galleryPrice)
                    let subElement = newPrice;// item.galleryPrice;
                    if (subElement) {
                        total += item.galleryQuantity * subElement;
                    }
                }
            });
            this.eventEmitterService.setColumnSumToComponent(this.data.storage.totalColumnElement, total);
        }

        if (typeof this.data.storage.totalColumnElements != "undefined" /*&& Array.isArray(this.data.storage.totalColumnElements)*/) {
            Object.keys(this.data.storage.totalColumnElements).forEach(val => {

                let total = 0;
                this.data.value.forEach(item => {
                    let subElement = item[val];
                    if (subElement) {
                        total += item.galleryQuantity * subElement;
                    }
                });
                this.eventEmitterService.setColumnSumToComponent(this.data.storage.totalColumnElements[val], total);

            });
        }
    }

    onUnitValueChanged(value, key) {
        let item = this.data.value.find(f => f.galleryKey == key);
        if (item) {
            item.galleryUnit = value;
        }
    }

    editPrice(item) {

        item.isEditable = true;
    }

    editQuantity(item) {
        console.log("editQuantity");
        //console.log(item);
        item.isEditableQuantity = true;
    }

    changeEditPriceMode(item) {
        item.isEditable = false;

        if (this.data.isPriceEditable && this.data.storage.queryPrice != null && this.data.storage.queryPrice != "") {
            let msg = "<i>" + this.languageLocal.messageSave + "</i>"
            let result = confirm(msg, this.languageLocal.warning);
            result.then((dialogResult) => {

                let _queryPrice: string = this.data.storage.queryPrice;
                const _query = _queryPrice.replace("{fiyat}", item.price).replace("{id}", item.key)

                this.genericService.executeQuerySecurited(_query, this.data.images.connectionId).subscribe(result => {
                    //console.log(_query);
                    //console.log(result);
                })

            });
        }


    }

    changeEditQuantityMode(item) {
        item.isEditableQuantity = false;

        if (this.data.isQuantityEditable && this.data.storage.queryQuantity != null && this.data.storage.queryQuantity != "") {
            let msg = "<i>" + this.languageLocal.messageSave + "</i>"
            let result = confirm(msg, this.languageLocal.warning);
            result.then((dialogResult) => {

                let _queryQuantity: string = this.data.storage.queryQuantity;
                const _query = _queryQuantity.replace("{miktar}", item.maxQuantity).replace("{id}", item.key)

                this.genericService.executeQuerySecurited(_query, this.data.images.connectionId).subscribe(result => {
                    //console.log(_query);
                    //console.log(result);
                })

            });

        }

    }

    popupImagesItem = [];
    imageZoomPopupGallery = false;
    openZoomPopup(src, key) {
        //connectionId

        if (this.data.images.connectionId > 0 && this.data.images.query && this.data.images.query != "") {
            var date = new Date();
            var timestamp = date.getTime();
            let _query = "SELECT * FROM(" + this.data.images.query + ") [IMAGES " + timestamp + "] WHERE " + this.data.images.itemValueCol + "='" + key + "'";
            this.genericService.executeQuerySecurited(_query, this.data.images.connectionId).subscribe(result => {
                if (result && result.length > 0) {

                    let keys = Object.keys(result[0]);
                    if (keys.length > 0) {
                        src = this.strtoBasePre(src);
                        this.popupImagesItem.push(src);
                        for (let i = 0; i < result.length; i++) {
                            let _src = this.strtoBasePre(result[i][this.data.images.displayCol]);
                            this.popupImagesItem.push(_src);
                        }

                        if (this.popupImagesItem.length > 1) {
                            this.selectedItemSrc = null;
                            this.imageZoomPopup = true;
                            this.imageZoomPopupGallery = true;
                        }
                        else
                            this.videoAndImageFirst(src, key);
                    }
                    else
                        this.videoAndImageFirst(src, key);
                }
                else
                    this.videoAndImageFirst(src, key);
            });

        }
        else
            this.videoAndImageFirst(src, key);

    }

    imageVariantZoomPopupGallery = false;
    popupVariantImagesItem = [];
    openVariantZoomPopup(src, key) {
        if (this.data.variants.images.connectionId > 0 && this.data.variants.images.query && this.data.variants.images.query != "") {
            var date = new Date();
            var timestamp = date.getTime();
            let _query = "SELECT * FROM(" + this.data.variants.images.query + ") [IMAGES " + timestamp + "] WHERE " + this.data.variants.images.itemValueCol + "='" + key + "'";
            this.genericService.executeQuerySecurited(_query, this.data.variants.images.connectionId).subscribe(result => {
                if (result && result.length > 0) {

                    let keys = Object.keys(result[0]);
                    if (keys.length > 0) {
                        src = this.strtoBasePre(src);
                        this.popupVariantImagesItem.push(src);
                        for (let i = 0; i < result.length; i++) {
                            let _src = this.strtoBasePre(result[i][this.data.variants.images.displayCol]);
                            this.popupVariantImagesItem.push(_src);
                        }


                        this.imageVariantZoomPopup = true;
                        this.imageVariantZoomPopupGallery = true;
                        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                    }
                }
                else {
                    src = this.strtoBasePre(src);
                    this.popupVariantImagesItem.push(src);
                    this.imageVariantZoomPopup = true;
                    this.imageVariantZoomPopupGallery = true;
                    try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                }

            });

        }
        else {
            src = this.strtoBasePre(src);
            this.popupVariantImagesItem.push(src);
            this.imageVariantZoomPopup = true;
            this.imageVariantZoomPopupGallery = true;
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        }

    }

    strtoBasePre(_imageData) {
        let str = _imageData ? _imageData.substring(0, 5) : "";
        if (str && str == "data:") _imageData = _imageData; // fotoğraf data tipi mime type varsa
        else _imageData = 'data:image/jpg;base64,' + _imageData;
        return _imageData;
    }
    videoAndImageFirst(src, key) {

        if (this.data.isVideoGallery) {
            let galery = this.galeryData.find(f => f.key == key);
            if (galery) {
                this.selectedItemSrc = galery.videoUrl;
                if (!this.selectedItemSrc || this.selectedItemSrc == "")
                    this.selectedItemSrc = src;
            }
            else {
                this.selectedItemSrc = src;
            }
        }
        else {
            this.selectedItemSrc = src;
        }
        this.imageZoomPopup = true;
    }

    onZoomPopupHidden() {
        this.selectedItemSrc = null;
        this.popupImagesItem = [];
        this.imageZoomPopupGallery = false;
    }

    onVariantZoomPopupHidden() {
        this.popupVariantImagesItem = [];
        this.imageVariantZoomPopupGallery = false;
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // Tüm charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    setLanguageToDatas() {
        this.layouts = [
            { "value": 1, "name": this.languageLocal.gallery/*"Galeri"*/ },
            { "value": 2, "name": "Slider" },
            { "value": 3, "name": "List" }
        ];
        this.currencyAll = [
            { "value": '₺', "name": "TL" },
            { "value": '$', "name": this.languageLocal.dollar/*"Dolar"*/ },
            { "value": '€', "name": "Euro" },
            { "value": '£', "name": this.languageLocal.sterling/*"Sterlin"*/ },
            { "value": '₽', "name": "Ruble" }
        ];


        this.itemPossition = [
            { "value": "bottom", "name": this.languageLocal.underPhoto/*"Fotoğrafın Altı"*/ },
            { "value": "top", "name": this.languageLocal.topPhoto/*"Fotoğrafın Üstü"*/ }
        ];


    }

}
