import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { Subscription } from 'rxjs';
import config from "devextreme/core/config";

@Component({
    selector: 'datebox-component',
    templateUrl: './datebox.component.html',
    styleUrls: ['./datebox.component.css']

})
export class DxDateboxComponent implements ComponentParameters, OnInit, OnDestroy {
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;
    @Input() anotherPopupId: number = 0;

    showDisplay: any = "flex";
    showDisplayBoolean = false;

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    invokeChangeEventLocal: Subscription;

    minDate: Date = null;
    maxDate: Date = null;
    languageLocal: any = "";

    constructor(private eventEmitterService: EventEmitterService, public changeDetectorRef: ChangeDetectorRef, public componentService: ComponentService) {

        config({ forceIsoDateParsing: true });

        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });

        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {
            if (sub.elementId == this.data.id) {
                if (sub.data) this.data.value = sub.data;
                else if (sub.value == "clean_element") this.data.value = null;
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });
        this.languageLocal = this.componentService.getLanguageSource(); //language

    }

    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
    }

    ngOnInit() {

        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;


        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });

        debugger;
        this.data.isTodaySelected = typeof this.data.isTodaySelected == "undefined" ? true : this.data.isTodaySelected;
        this.data.isTodayTimeSelected = typeof this.data.isTodayTimeSelected == "undefined" ? true : this.data.isTodayTimeSelected;
        if (!this.isEdit && this.data.isTodaySelected == true) {
            if (this.componentService.isNewSchemaPopup == true || this.data.value == null) {
                if (this.data.isTodayTimeSelected == true)
                    this.data.value = new Date();
                else
                    this.data.value = new Date(new Date().setHours(0, 0, 0, 0));//.setHours(0, 0, 0);
            }
        }


        this.data.isTimeOption = typeof this.data.isTimeOption == "undefined" ? false : this.data.isTimeOption;

        this.data.minToday = typeof this.data.minToday == "undefined" ? false : this.data.minToday;
        this.data.maxToday = typeof this.data.maxToday == "undefined" ? false : this.data.maxToday;
        this.data.dateRange = typeof this.data.dateRange == "undefined" ? null : this.data.dateRange;

        if (this.data.maxToday && this.data.minToday) {
            this.minDate = new Date(new Date().setHours(0, 0, 0, 0));
            this.maxDate = new Date(new Date().setHours(23, 59, 59, 0));

        } else {

            if (this.data.minToday) {
                this.minDate = new Date();
                if (this.data.dateRange != null && this.data.dateRange != 0) {
                    let range: Date = new Date(this.minDate.getTime() + ((1000 * 60 * 60 * 24) * this.data.dateRange));
                    range = new Date(new Date(range).setHours(23, 59, 59, 0));  // Okunurluk düzeltmesi yap
                    this.maxDate = range
                } else {
                    this.maxDate = null
                }
            }

            if (this.data.maxToday) {
                this.maxDate = new Date(new Date().setHours(23, 59, 59, 0));
                if (this.data.dateRange != null && this.data.dateRange != 0) {
                    let range: Date = new Date(this.maxDate.getTime() - ((1000 * 60 * 60 * 24) * this.data.dateRange));
                    range = new Date(new Date(range).setHours(0, 0, 0, 0));         // Okunurluk düzeltmesi yap
                    this.minDate = range
                } else {
                    this.minDate = null
                }
            }
        }
    }

    disableElements() {
        return typeof this.data.disabled != "undefined" ? (this.data.disabled == false ? this.data.outsideDisabled : this.data.disabled) : false;
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    customValidation(e) {
        if ((!!e.value) && (e.event != null && e.value != e.previousValue)) {
            let checkDate = new Date(e.value)
            if (!!this.minDate && this.maxDate == null) {
                if (checkDate >= this.minDate) {
                    this.data.value = checkDate
                } else {
                    this.data.value = new Date();
                }

            } else if ((!!this.maxDate) && (this.minDate == null)) {
                if (checkDate <= this.maxDate) {
                    this.data.value = checkDate
                } else {
                    this.data.value = new Date();
                }

            } else if (!!this.maxDate && !!this.minDate) {
                if ((checkDate >= this.minDate) && (checkDate <= this.maxDate)) {
                    this.data.value = checkDate
                } else {
                    this.data.value = new Date();
                }

            }
        }
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // Tüm charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    onValueChanged(e) {

        if (this.data.maxToday == true || this.data.minToday == true) {
            this.customValidation(e);
        }
        else {
            debugger;
            if (typeof e.event == "undefined") {
                this.data.isTodaySelected = typeof this.data.isTodaySelected == "undefined" ? true : this.data.isTodaySelected;
                this.data.isTodayTimeSelected = typeof this.data.isTodayTimeSelected == "undefined" ? true : this.data.isTodayTimeSelected;
                if (!this.isEdit && this.data.isTodaySelected == true) {
                    if (this.componentService.isNewSchemaPopup == true || this.data.value == null) {
                        if (this.data.isTodayTimeSelected == true)
                            e.value = new Date();
                        else
                            e.value = new Date(new Date().setHours(0, 0, 0, 0));//.setHours(0, 0, 0);
                    }
                }
            }
            this.data.value = e.value;
        }
        //this.eventEmitterService.onValueChangeEvent(this.data.id, "clean_element", "clean_element", null);
    }
}
