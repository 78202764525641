import { Component, Input, ChangeDetectorRef, Output, EventEmitter, OnChanges} from '@angular/core';
import { ComponentService } from '../../../formdesigner/component.service';
//import { isNumeric } from 'rxjs-compat/util/isNumeric';

@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})


export class DataTable implements OnChanges {

    @Input() dataSource: any[];
    @Input() selectedRowKeys: any;
    @Input() keyExpr: any = "";

    @Output() onSelect = new EventEmitter();

    query = ""                          // queryArray JSON array as string
    page = 1;                           // Start CurrentPage as 1
    pageSize = 20;                      // Page Size 
    queryArray = {}
    filterMetadata = { count: 0 };      // Filtered data's size
    sizes = [10, 20, 50]                // Page Size options
    customColumn = []                   
    calculated = false;

    sortType = "asc"
    sortField = ""

    constructor(public changeDetectorRef: ChangeDetectorRef, public componentService: ComponentService) { }

    ngOnChanges(changes) {
        this.page = 1;
        this.calculated = false;
        this.calculateHeaders()
    }

    calculateHeaders() {

        if (!this.calculated) {

            if (!!this.dataSource[0]) {

                let keys = Object.keys(this.dataSource[0])

                keys.forEach(element => {

                    this.queryArray[element] = { key: element, value: "", type: "string" }

                })

                Object.keys(this.queryArray).forEach(key => {

                    if (this.componentService.isInteger(this.dataSource[0][key])) {

                        this.queryArray[key].type = "numeric"

                    }

                })

                this.customColumn = keys

                this.calculated = true

                this.filterMetadata = { count: this.dataSource.length };

            }

        }

        return true

    }

    // Page Size Changed event
    onValueChangedSelectBox($event) {
        let event = $event
        this.pageSize = event.value
    }

    // On filter we update query as json string
    // Its json string because we need send array to filter pipe easily. 
    // Parsing on filter pipe
    // And page cleared as first page after data filtered
    modelChanged() {
        this.query = JSON.stringify(this.queryArray)
        this.page = 1
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }

    // Sort change event
    sortClick(value) {
        if (value != this.sortField) {
            this.sortField = value
        } else {
            if (this.sortType == "asc") {
                this.sortType = "desc"
            } else if (this.sortType == "desc") {
                this.sortType = "asc"
            }
        }
    }

    // Send data to parent with output emitter. 
    // Dx use keys as array.
    onMyClick($event) {
        let event = $event
        let array = []
        array.push(event[this.keyExpr])
        this.onSelect.emit(array);
    }

}

