import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { Subscription } from 'rxjs';
import config from "devextreme/core/config";

@Component({
    selector: 'pdfviewer-component',
    templateUrl: './pdfviewer.component.html',
    styleUrls: ['./pdfviewer.component.css']
})
export class DxPdfViewerComponent implements ComponentParameters, OnInit, OnDestroy {
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;
    @Input() anotherPopupId: number = 0;

    previewFileSrc: any = "";
    previewPopupFileSrc: any = "";

    showDisplay: any = "flex";
    showDisplayBoolean = false;

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    invokeChangeEventLocal: Subscription;
    languageLocal: any = "";

    constructor(private eventEmitterService: EventEmitterService, public changeDetectorRef: ChangeDetectorRef, public componentService: ComponentService) {

        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
        this.languageLocal = this.componentService.getLanguageSource(); //language



        //this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {
        //    if (sub.elementId == this.data.id) {
        //        if (sub.data) this.data.value = sub.data;
        //        else if (sub.elementId == "clean_element") this.data.value = null;
        //        this.changeDetectorRef.detectChanges();
        //    }
        //});
    }

    ngOnDestroy() {
        try { this.invokeChangeEventLocal.unsubscribe(); } catch (ex) { }
    }

    allButtonIcons = [];
    ngOnInit() {

        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (typeof this.data.show === "undefined") this.data.show = true;
        if (typeof this.data.isButton === "undefined") this.data.isButton = false;
        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.allButtonIcons = this.componentService.getAllIcons();

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

        if (!this.data.isButton) {
            this.previewFileSrc = "/ProcessPdf/" + this.changeFilePathAndName(this.data.path) + "/" + this.changeFilePathAndName(this.data.fileName) + "-" + this.componentService.currentInstanceId + ".pdf";///process_pdfs/undefined/undefined
        } debugger;
        if (typeof this.data.property === "undefined") {
            this.data.property = { icon: "", fontSize: "", backgroundColor: '#ffffff', fontColor: '#000000' };
        }
        this.setPageStyle(this.data.property.fontColor, this.data.property.backgroundColor);

        if (typeof this.data.property.backgroundColor === "undefined" || typeof this.data.property.fontColor === "undefined") {
            if (typeof this.data.property.backgroundColor === "undefined")
                this.data.property.backgroundColor = '#ffffff';
            if (typeof this.data.property.fontColor === "undefined")
                this.data.property.fontColor = '#000000';
        }



        if (typeof this.data.height === "undefined" || this.data.height == "") this.data.height = "100px";
        if (typeof this.data.width === "undefined" || this.data.width == "") this.data.width = "100%";

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });

    }
    changeFilePathAndName(_str) {
        try {
            let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
            let curentComponents = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
            let fStr = _str.replace(/[\u200B-\u200D\uFEFF]/g, '');
            //fStr = this.replaceAll(fStr, "{" + this.data.id + "}", this.data.value);
            curentComponents.forEach(c => {
                if (c.id != this.data.id) {
                    let cId = "{" + c.id + "}";
                    fStr = this.componentService.replaceAll(fStr, cId, c.value);
                }
            });
            return fStr;
        } catch (_e) {
            return _str;
        }
    }
    disableElements() {
        return typeof this.data.disabled != "undefined" ? (this.data.disabled == false ? this.data.outsideDisabled : this.data.disabled) : false;
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // T�m charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }










    setPageStyle(_color, _backgroundColor) {
        try {
            let _el = document.getElementById("styleDynamic_" + this.data.id);
            _el.remove();
        } catch (ex) { }
        //85
        let styles = ` 
#`+ this.data.id + ` { 
  color: `+ _color + `;
  background-color: `+ _backgroundColor + `;
}  

#`+ this.data.id + `.dx-state-hover {  
  background-color:  `+ _backgroundColor + `c2;  
  color:  `+ _color + `;  
}  

#`+ this.data.id + `.dx-state-active {  
  background-color:  `+ _backgroundColor + `c2;  
  color:  `+ _color + `;  
}  `;
        let styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = styles;
        styleSheet.id = "styleDynamic_" + this.data.id;
        styleSheet.className = "style-dynamic";
        document.head.appendChild(styleSheet);
    }

    buttonIconPopoverOpen = false;
    hideEmojiPopoper(e) {
        //this.chatMessageInputStartPos = null;
        //this.chatMessageInputEndPos = null;
        //let myField = this.chatMessageInput.nativeElement;
        //if (!(this.chatMessageInputStartPos)) {
        //    myField.selectionStart = this.chatMessageInputStartPos;
        //    myField.selectionEnd = this.chatMessageInputEndPos;
        //}
        //myField.focus();
    }

    toggleIconPanel() {
        debugger;
        this.buttonIconPopoverOpen = !this.buttonIconPopoverOpen;
    }
    setButtonIcon(_icon) {
        debugger;
        if (_icon && _icon != "") {
            this.data.property.icon = _icon.icon;
        }
        else {
            this.data.property.icon = '';
        }
        this.buttonIconPopoverOpen = !this.buttonIconPopoverOpen;
        //let myField = this.chatMessageInput.nativeElement;
        //if (!(this.chatMessageInputStartPos)) {
        //    this.chatMessageInputStartPos = myField.selectionStart;
        //    this.chatMessageInputEndPos = myField.selectionEnd;
        //}
        //let myValueClear = this.insertAtCursor(_icon);
        //this.chatMessageText = myValueClear;
    }
    getButtonIconClass(_type) {
        if (typeof _type == 'undefined')
            return "icon-setting-button-delete";

        return typeof this.data.property.icon == 'undefined' || this.data.property.icon == '' ? 'icon-setting-button-empty' : 'icon-setting-button';
    }
    showPdfPopup = false;
    openPdfViewer(_e) {
        this.showPdfPopup = true;
        debugger;
        this.previewPopupFileSrc = "/ProcessPdf/" + this.changeFilePathAndName(this.data.path) + "/" + this.changeFilePathAndName(this.data.fileName) + "-" + this.componentService.currentInstanceId + ".pdf";
    }
    hidePdfPopup(_e) {
        this.previewPopupFileSrc = "";
    }
    fontColorValueChange(_e) {
        let newValue = _e.value;
        if (this.data.property) {
            this.data.property.fontColor = newValue;
        }
        else {
            this.data.property = { icon: "", fontSize: "", backgroundColor: '#ffffff', fontColor: '#000000' };
        }
        this.setPageStyle(this.data.property.fontColor, this.data.property.backgroundColor);
    }

    backgroundValueChange(_e) {
        let newValue = _e.value;
        if (this.data.property) {
            this.data.property.backgroundColor = newValue;
        }
        else {
            this.data.property = { icon: "", width: "", height: "", fontSize: "", backgroundColor: '#ffffff', fontColor: '#000000' };
        }
        this.setPageStyle(this.data.property.fontColor, this.data.property.backgroundColor);
    }

    getButtonGeneralCss() {
        let _myStyle = {};
        if (this.data.height)
            _myStyle["height"] = this.data.property.height + (this.componentService.isInteger(this.data.property.height) ? "px" : "");
        if (this.data.width)
            _myStyle["width"] = this.data.property.width + (this.componentService.isInteger(this.data.property.width) ? "px" : "");

        return _myStyle;
    }

    getButtonGeneralCssText(type) {
        //    font-size: 103px;
        //width: 103px;
        //height: 18px;
        /*
        14   18
        20    x
         20*18/14
         */
        let _myStyle = {};
        if (this.data.property.fontSize) {
            if (type == "i")
                _myStyle["font-size"] = this.data.property.fontSize + (this.componentService.isInteger(this.data.property.fontSize) ? "px" : "");
            else
                _myStyle["font-size"] = this.componentService.isInteger(this.data.property.fontSize) ? ((this.data.property.fontSize - 3) + "px") : ("calc(" + this.data.property.fontSize + " - 3px)");


            //this.data.property.fontSize + this.componentService.isInteger(this.data.property.fontSize) ? "px" : "";

            _myStyle["width"] = this.data.property.fontSize + (this.componentService.isInteger(this.data.property.fontSize) ? "px" : "");
        } else {
            if (type == "i")
                _myStyle["font-size"] = "17px";
            else
                _myStyle["font-size"] = "14px";

            _myStyle["width"] = "18px";
        }
        _myStyle["height"] = "18px";

        return _myStyle;
    }


}
