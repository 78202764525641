import { Component, OnInit, NgModule,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { AgmCoreModule } from '@agm/core';
import { DevExtremeModule } from '../../devextreme.module';

import { FormBuilder } from "./_formbuilder/formbuilder";
import { FormRender } from "./_formrender/formrender";
import { NgxCaptureModule } from 'ngx-capture';
import {
    DxLabelComponent,
    DxButtonComponent,
    DxCheckboxComponent,
    DxCodeditorComponent,
    DxEtrDataGridComponent,
    DxCustomDataGridComponent,
    DxDateboxComponent,
    DxEmailComponent,
    DxFileuploadComponent,
    DxImageuploadComponent,
    DxPictureBoxComponent,
    DxMapComponent,
    DxImageviewerComponent,
    DxVideoviewerComponent,
    DxVideouploadComponent,
    DxGridComponent,
    DxNumberComponent,
    DxPasswordComponent,
    DxPhoneComponent,
    DxRadiogroupComponent,
    DxSelectboxComponent,
    DxDatagridSelectComponent,
    DxDataListComponent,
    DxTextboxComponent,
    DxSchedulerComponent,
    DxLiveComponent,
    DxChildFormComponent,
    DxPaymentComponent,
    DxTextareaComponent,
    DxAccordionComponent,
    DxHtmleditorComponent,
    DxDatasourceComponent,
    DxDisplayComponent,
    DxFormResultComponent,
    DxGalleryComponent,
    DxMenuComponent,
    DxTabsComponent,
    DxDashboardComponent,
    DxSignatureComponent,
    DxPdfViewerComponent,
    DxPrintDesignerComponent,
    DxMultiSelectBoxComponent,
    DxKanbanComponent,
    DxGanttComponent,
    DxEtrDiagramComponent,
    DxCommentComponent,
} from "."

import { DxLanguageGridComponent, DxSqlcommandComponent, DxPermissionComponent, DxPopootoolboxComponent, DxLanguageButtonComponent } from './_partialcomponents';
import { ComponentService } from './component.service';
import { RenderComponent } from './render.component';
import { ComponentDirective } from './component.directive';
import { SafePipe } from './safepipe.component ';
import { LocalStorageService } from './local-storage.service';

import { JwPaginationComponent } from 'jw-angular-pagination';
import { SearchFilterPipe } from '../shared/pipes/filter-pipe.pipe';
import { PaginationPipe } from '../shared/pipes/pagination-pipe.pipe';
import { SortByPipe } from '../shared/pipes/sort-pipe.pipe';
import { DataTable } from '../shared/components/data-table/data-table.component';
import { DataTablePaginator } from '../shared/components/data-table-paginator/data-table-paginator.component';
import { WhitePageViewer } from '../pages/admin/whitepageviewer/whitepageviewer.component';
import { DxReportViewerModule } from 'devexpress-reporting-angular';
import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
    imports: [
        MonacoEditorModule.forRoot(),
        AgmCoreModule.forRoot({
            // please get your own API key here:AIzaSyDXpgm5y_tUBAAAS7JMPmZgMQvjpUvjh4M
            // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
            apiKey: atob(typeof localStorage.getItem("GMAPI") != "undefined" ? localStorage.getItem("GMAPI") : '') //'AIzaSyBfeMCzcIXRN-rBUA19hqwFgngihg99VwQ'  QUl6YVN5QmZlTUN6Y0lYUk4tckJVQTE5aHF3RmduZ2loZzk5VndR
            ,
            libraries: ["places"]
        }),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        DevExtremeModule,
        DxReportViewerModule,
        ColorPickerModule,
        NgxCaptureModule,
    ],
    declarations: [
        SafePipe,
        FormBuilder,
        FormRender,
        DxLabelComponent,
        DxButtonComponent,
        DxCheckboxComponent,
        DxDateboxComponent,
        DxNumberComponent,
        DxPasswordComponent,
        DxSelectboxComponent,
        DxDatagridSelectComponent,
        DxDataListComponent,
        DxTextareaComponent,
        DxTextboxComponent,
        DxSchedulerComponent,
        DxLiveComponent,
        DxChildFormComponent,
        DxPaymentComponent,
        DxGridComponent,
        RenderComponent,
        DxEtrDataGridComponent,
        DxCustomDataGridComponent,
        ComponentDirective,
        DxCodeditorComponent,
        DxRadiogroupComponent,
        DxFileuploadComponent,
        DxImageuploadComponent,
        DxPictureBoxComponent,
        DxMapComponent,
        DxVideouploadComponent,
        DxPhoneComponent,
        DxEmailComponent,
        DxAccordionComponent,
        DxHtmleditorComponent,
        DxLanguageGridComponent,
        DxLanguageButtonComponent,
        DxImageviewerComponent,
        DxVideoviewerComponent,
        DxSqlcommandComponent,
        DxPermissionComponent,
        DxPopootoolboxComponent,
        DxDatasourceComponent,
        DxDisplayComponent,
        DxFormResultComponent,
        DxGalleryComponent,
        DxMenuComponent,
        DxTabsComponent,
        JwPaginationComponent,
        DxDashboardComponent,
        DxSignatureComponent,
        DxPdfViewerComponent,
        PaginationPipe,
        SearchFilterPipe,
        SortByPipe,
        DataTable,
        DataTablePaginator,
        DxPrintDesignerComponent,
        WhitePageViewer,
        DxMultiSelectBoxComponent,
        DxKanbanComponent,
        DxGanttComponent,
        DxEtrDiagramComponent,
        DxCommentComponent
    ],
    providers: [ComponentService, LocalStorageService],
    exports: [FormBuilder, FormRender, DevExtremeModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RiverFormModule { }