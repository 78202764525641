import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { Subscription } from 'rxjs';
import { InstanceService } from '../../shared/services/data/instance.service';
import notify from "devextreme/ui/notify";
import { AppInfoService } from '../../shared/services';
@Component({
    selector: 'live-component',
    templateUrl: 'live.component.html',
    styleUrls: ['./live.component.css']
})
export class DxLiveComponent implements ComponentParameters, OnInit, OnDestroy {
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;
    @Input() anotherPopupId: number = 0;

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;

    showDisplay: any = "flex";

    textComponentList: any = [];

    mettingTypeList: any = [{ id: "Live Web" }, { id: "Zoom" }];

    invokeChangeEventLocal: Subscription;
    languageLocal: any = "";

    // processFormIdGlobal: any = 0;

    constructor(private eventEmitterService: EventEmitterService,
        public changeDetectorRef: ChangeDetectorRef,
        public componentService: ComponentService,
        public appInfo: AppInfoService,
        public instanceService: InstanceService) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
        this.languageLocal = this.componentService.getLanguageSource(); //language

        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {

            if (sub.elementId == this.data.id) {
                if (sub.data) this.data.value = sub.data;
                else this.data.value = "";
                //setTimeout(() => {
                //    try {
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                //    } catch{ }
                //}, 300);

            }
        });
        //this.invokeGetProcessInfoLocal = this.eventEmitterService.invokeGetProcessInfo.subscribe(sub => {

        //    if (sub.elementId == this.data.id) {
        //        // 
        //        if (sub.data) this.processFormIdGlobal = sub.data.processFormId;
        //        //else this.data.value = "";
        //        //setTimeout(() => {
        //        //    try {
        //        this.changeDetectorRef.detectChanges();
        //        //    } catch{ }
        //        //}, 300);

        //    }
        //});

        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        this.textComponentList = this.componentService.getInputComponentList(currentFormSelected.components, this.anotherPopupId);
    }
    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
    }
    ngOnInit() {
        //
        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (this.data.isAuto) {
            this.data.disabled = true;
        }
        this.data.upperCaseLetter = this.data.upperCaseLetter == null ? false : this.data.upperCaseLetter;

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });


        this.data.meetingType = typeof this.data.meetingType == "undefined" ? "LiveWeb" : this.data.meetingType;
        //this.data.hostUrl = typeof this.data.hostUrl == "undefined" ? "" : this.data.hostUrl;
        //this.data.participantUrl = typeof this.data.participantUrl == "undefined" ? "" : this.data.participantUrl;

        if (this.data.value != "" && this.data.value != null && typeof this.data.value != "undefined") {

            let _urlLiveWeb = JSON.parse(this.data.value);
            this.data.hostUrl = _urlLiveWeb.hostUrl;
            this.data.participantUrl = _urlLiveWeb.participantUrl;
        }
    }

    isDisable = false;
    liveClick() {
        //let fff=this.data; 

        this.isDisable = true;
        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        let componentLst = this.componentService.getInputComponentList(currentFormSelected.components, this.anotherPopupId);
        let nameRoom = componentLst.find(x => x.id == this.data.meetingRoomName);
        let startDateRoom = componentLst.find(x => x.id == this.data.startDate);
        let localProcessFormId = this.componentService.currentProcessFormId;
        this.instanceService.liveOpen(nameRoom.value, startDateRoom.value, this.data.hostUrl, this.data.participantUrl, this.data.meetingType, localProcessFormId).subscribe((s: any) => {
            if (s.success) {
                if (/*this.data.meetingType == "LiveWeb" &&*/ localProcessFormId == 0) {
                    this.data.value = JSON.stringify({ participantUrl: s.participantUrl, hostUrl: s.hostUrl });
                    this.showToast(s.message, "success");
                }
                else {

                    let _domain = window.location.protocol + "//" + window.location.host + "/" + s.liveUrl.replace("{{USERNAME}}", this.appInfo.currentUser.name);
                    window.open(_domain, "_blank");
                    this.showToast(s.message, "success");
                    this.eventEmitterService.updateTasks(this.anotherPopupId );
                }
            }
            else {
                this.isDisable = false;
                this.showToast(s.message, "error");
            }
        });
    }

    showToast(message, status) {
        notify(message, status, 3000);
    }

    onEditPopopShown() {
        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        this.textComponentList = this.componentService.getInputComponentList(currentFormSelected.components, this.anotherPopupId);
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);

            //let systemInfoTemp = this.componentService.currentSystemInfo.find(x => x.id == this.data.id);
            //if (systemInfoTemp)
            //    this.componentService.currentSystemInfo.find(x => x.id == this.data.id).value = this.systemInfoPage.find(x => x.id == this.data.systemInfoId);
            //else
            //    this.componentService.currentSystemInfo.push({ id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) });
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }
    //getTextboxStyles() {//text-transform: uppercase

    //    let myStyles = {
    //        'text-transform': this.data.upperCaseLetter != null && this.data.upperCaseLetter != false ? 'uppercase' : 'none'
    //    };
    //    return myStyles;
    //}
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // T�m charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);


                //let systemInfoTemp = this.componentService.currentSystemInfo.find(x => x.id == this.data.id);
                //if (systemInfoTemp)
                //    this.componentService.currentSystemInfo.find(x => x.id == this.data.id).value = this.systemInfoPage.find(x => x.id == this.data.systemInfoId);
                //else
                //    this.componentService.currentSystemInfo.push({ id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) });
                // this.componentService.currentSystemInfo = { id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) };//this.systemInfoPage.find(x => x.id == this.data.systemInfoId);
            }
        });
    }

}
