import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { confirm } from 'devextreme/ui/dialog';
import { Subscription } from 'rxjs';

import { EventEmitterService, GenericService, AppInfoService } from '../../shared/services';
import { ComponentService } from '../component.service';
import { ComponentParameters } from '../component.parameters';

export class SqlScriptModelMultiSelect {
    id: number;
    connectionId: number;
    elementId: string;
    query: string;
}

@Component({
    selector: 'multiselectbox-component',
    templateUrl: './multiselectbox.component.html',
    styleUrls: ['./multiselectbox.component.css']
})
export class DxMultiSelectBoxComponent implements ComponentParameters, OnInit, OnDestroy {

    // #region Definitions

    @Input() data: any;
    @Input() isEdit: any;
    @Input() lang: string;
    @Input() anotherPopupId: number = 0;

    showDisplay: any = "flex";
    showDisplayBoolean = false;

    languageCodes = []

    classFormComponent = "col-md-12";
    classFormElements = "col-md-6 hidden-element";

    designerDataSourceList: any;
    designerDatasourceModel: any;

    dataSources: any;
    dataSourcePopup: boolean = false;
    isFromDataSource: boolean;
    showSettingPopup: boolean = false;
    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showChamgeEventPopup: boolean = false;
    isMonacoLoaded: boolean = false;

    formComponentsScripts: any;
    formElementsScripts: any;

    invokeChangeEventLocal: Subscription;
    // editorOptions = { theme: 'vs-dark', language: 'sql', automaticLayout: true };
    connections: any = [];
    selecboxComponents: any = [];

    sqlScriptModel: SqlScriptModelMultiSelect;

    registeredLanguage: any;

    formElements: any;

    showFunctionSettingPopup: any = false;
    isMonacoFuncParentLoaded: any = false;
    functionScript = { code: "", elementId: "", column: "" };
    functionScriptIndex = -1;

    isMonacoFuncLoaded: any = false;
    languageLocal: any = "";
    myCustomButtonConfig = {
        onClick: (e) => {
            // ...
        },
        disabled: false
    };
    isGridBoxOpened = false;
    // #endregion

    // #region LifeCycles : Constructor, NgOnInıt , NgOnDestroy
    constructor(private appInfo: AppInfoService,
                private eventEmitterService: EventEmitterService,
                private componentService: ComponentService,
                private genericService: GenericService,
                private changeDetectorRef: ChangeDetectorRef) {

        this.languageLocal = this.componentService.getLanguageSource(); //language
        this.languageCodes = this.componentService.getlanguageCodes();

        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }

        });

        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {
            
            if (this.data.id != sub.id && sub.elementId == this.data.id) {

                if (sub.data) {

                    let result = sub.data;
                    if (result && result.length > 0 && Array.isArray(result)) {
                        this.data.dataSource = result;

                        let keys = Object.keys(this.data.dataSource[0]);
                        this.data.displayColumnsExpr = keys

                        let tempValue = this.data.isDefaultFirstValue && this.data.dataSource.length > 0 && this.data.value == "" ? this.data.dataSource[0][this.data.valueExpr] : this.data.value;
                        tempValue.forEach(el => {
                            if (!this.data.value.includes(el)) {

                                this.data.value.push(tempValue)
                                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                            }
                        })


                        let _event_ = { value: this.data.value };
                        this.onValueChanged(_event_);
                    }
                    else {
                        this.data.value = result;
                    }

                }
                else if (sub.value == "clean_element") { this.data.dataSource = []; this.data.value = [] }

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });
    }

    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
    }

    ngOnInit() {

        if (typeof this.functionScript == "undefined")
            this.functionScript = { code: "", elementId: "", column: "" };

        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);
        this.connections = this.appInfo.RiverEntity.connections;

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });

        if (!this.isEdit) {
            this.executeScript();
        } else {
            this.fillDesignerDataSource();
        }

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

        if (typeof this.data.isDefaultFirstValue === "undefined")
            this.data.isDefaultFirstValue = false;

        if (this.componentService.currentInstanceId != 0 && this.data.value.length > 0) {
            this.onValueChange(this.data.value, true)
        }

    }

    // #endregion 

    //#region Default Events

    /**
      * Return true if data.outsideDisabled
      */
    disableElements() {
        return typeof this.data.disabled != "undefined" ? (this.data.disabled == false ? this.data.outsideDisabled : this.data.disabled) : false;
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace).replace(/[\u200B-\u200D\uFEFF]/g, '');
    }
    // #endregion

    // #region Settings (Save and Cancel)
    saveSetting() {

        this.data.desktopDatasource = this.designerDatasourceModel.ID;

        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        } else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }
    // #endregion

    // #region Datasource Events
    fillDesignerDataSource() {
        this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };

        this.genericService.fillDesignerDataSource().subscribe((result: any) => {

            this.designerDataSourceList = result;
            this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };
            if (this.data.desktopDatasource != null) {
                this.designerDatasourceModel = this.designerDataSourceList.find(x => x.ID == this.data.desktopDatasource);

                if (typeof this.designerDatasourceModel.ID === "undefined")
                    this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };
            }

            if (!this.isEdit) {
                this.genericServiceExecuteScript(this.designerDatasourceModel.SQLSTR, this.designerDatasourceModel.CONNID);
            }

        });

    }

    resetDataSource() {
        this.data.dataSource = [];
        this.data.dataSourceKey = "";
    }

    onChangeDatasourceValue(event) {
        if (event.value) {

            this.designerDatasourceModel = this.designerDataSourceList.find(x => x.ID == event.value);

            //  this.designerDatasourceModel = { ID: 0, NAME: "", SQLSTR: "" };

        }
    }

    onDataSourceValueChanged(event) {
        this.data.dataSourceKey = event.value
    }
    // #endregion

    // #region onValueChange Events
    onValueChanged(event) {

        
    }

    onChangeformElementsScriptsValue(e) {
        if (e.value != "") {
            let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
            let fCompms = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
            let fCompm = fCompms.find(x => x.id == e.value);
            if (fCompm.type == "gallery") {

                // = this.setFormElementsScripts(fCompm);// fCompm.storage.sqlHideColumns.map(m => m.name);
                this.setFormElementsScripts(fCompm);
                this.classFormComponent = "col-md-6";
                this.classFormElements = "col-md-6";
            }
            else {
                this.classFormComponent = "col-md-12";
                this.classFormElements = "col-md-6 hidden-element";
            }
        }
    }

    setFormElementsScripts(dataGallery) {
        let _return = [];
        if (dataGallery.storage.query != "" && dataGallery.storage.connectionStringId > 0) {
            this.genericService.executeQuerySecurited(dataGallery.storage.query, dataGallery.storage.connectionStringId).subscribe((result: any) => {
                if (result && result.length > 0) {

                    let allColumnsSql = Object.keys(result[0]);
                    let keysSearch = [];
                    keysSearch = allColumnsSql.filter(f =>
                        f != dataGallery.storage.keyColumn);

                    keysSearch.forEach(item => {
                        this.formElementsScripts.push(item);
                    });

                }

            });
        }

    }

    // #endregion


    onValueChange(eventValue,init = false) {
        debugger;
        if (eventValue && eventValue.length > 0) {
            //this.eventEmitterService.onValueChangeEvent(this.data.id, "clean_element", event.value, null);
            this.data.value = eventValue;

            let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
            let curentComponents = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
            
            if (typeof this.data.functionScripts != "undefined") {

                let galleryArray = [];

                

                this.data.functionScripts.forEach(f => {
                    
                    let fCode = f.code.replace(/[\u200B-\u200D\uFEFF]/g, '');
                    console.log(this.data.value)
                    fCode = this.replaceAll(fCode, "{" + this.data.id + "}", JSON.stringify(this.data.value) );
                    curentComponents.forEach(c => {
                        if (c.id != this.data.id) {
                            let cId = "{" + c.id + "}";
                            fCode = this.componentService.replaceAllUsingType(fCode, cId, c.value);// this.replaceAll(fCode, cId, c.value);
                        }
                    });

                    let fCompm = curentComponents.find(x => x.id == f.elementId);
                    if (fCompm.type == "gallery") {
                        
                        let result = null
                        try {
                            result = eval(fCode);   //"[IDs]*1"  
                        } catch (ex) {
                            console.log(ex)
                        }
                            
                        let galArrayObj = galleryArray.find(l => l.element == f.elementId);
                        if (galArrayObj) {
                            galArrayObj.values.push({ value: result, column: f.column });
                        }
                        else {
                            let resVal = { element: f.elementId, values: [] };
                            resVal.values.push({ value: result, column: f.column });
                            galleryArray.push(resVal);
                        }

                    }
                    else {
                        try {
                            let result = eval(fCode);
                            console.log(result)
                            this.eventEmitterService.selectboxChangeEvent(this.data.id, f.elementId, result, result);
                        } catch (ex) {
                            console.log(ex)
                        }
                    }

                });

                galleryArray.forEach(f => {
                    this.eventEmitterService.galleryChangeEvent(this.data.id, f.element, f.values);
                });

            }
            this.data.sqlScripts.forEach(s => {
                
                let pref = "{" + this.data.id + "}";
                let value = eventValue;
                let array = []
                value.forEach(el => {
                    array.push("'" + el +"'")
                })
                let query = this.componentService.replaceAllUsingType(s.query, pref, array); //s.query.replace(pref, array);

                var pattern = /\{(\w)+\}/ig;
                var patt = new RegExp(pattern);
                if (patt.test(query)) {
                    let _match = query.match(pattern);
                    if (_match.length > 0) {
                        _match.forEach(f => {
                            let _k = curentComponents.find(k => k.id == f.replace("{", "").replace("}", ""));
                            if (_k) {
                                let _value = _k.value == null ? "" : _k.value;
                                query = this.componentService.replaceAllUsingType(query, f, _value);// query.replace(f, _value);
                            }
                        });
                    }
                }
                console.log(query)
                this.genericService.executeQuerySecurited(query, s.connectionId).subscribe((result: any) => {
                    
                    console.log(result);
                    if (result) {
                        let element = this.componentService.getById(s.elementId, currentFormSelected.components, this.anotherPopupId);
                        if (element.type) {
                            if (element.type == "datagrid" && init) return;
                            if (element.component == "DxDatagridSelectComponent" || element.type == "datalist" || element.type == "selectbox" || element.type == "gallery" || element.type == "datagrid" || element.type == "multiselectbox") {
                                let isShowComfirm = true;
                                this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, eventValue, result);
                            }
                            else {
                                let finalData = [];
                                if (result.length > 0) {
                                    for (let i = 0; i < result.length; i++) {
                                        let keys = Object.keys(result[i]);
                                        if (keys.length > 0) {
                                            keys.forEach(f => {
                                                
                                                finalData.push(result[i][f]);
                                            });
                                        }
                                    }
                                }
                                let dataText = finalData.join(",");
                                this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, eventValue, dataText);
                            }
                        }
                    }
                });
            });
        }
        else {
            this.clearScriptRecursive(this.data.id);
        }

        this.isGridBoxOpened = false;
    }
    // #region Open Popup Events
    openDataPopup(type) {
        this.isFromDataSource = type == "1";
        if (this.isFromDataSource) {
            this.data.isManual = false;
            let selectboxes = this.componentService.getComponentsByType("selectbox", this.anotherPopupId);
            this.selecboxComponents = [];
            if (selectboxes) {
                selectboxes.forEach(item => {
                    if (item.id != this.data.id) {
                        let element: any = {};
                        element.id = item.id;
                        element.name = this.componentService.getLang(this.lang, item.language).Label;
                        this.selecboxComponents.push(element);
                    }
                });
            }
            //this.dataSources = this.componentService.getComponentsByType("datasource");
        }
        else {
            this.data.isManual = true;
        }

        this.dataSourcePopup = true;
    }

    openChamgeEventPopup() {
        this.isMonacoLoaded = false;
        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        let elements = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId).map(m => m.id);
        this.formElements = elements.filter(f => f != this.data.id);
        this.sqlScriptModel = new SqlScriptModelMultiSelect();
        this.sqlScriptModel.query = 'SELECT * FROM TABLE WHERE KEY={' + this.data.id + '}';
        this.showChamgeEventPopup = true;
    }

    openFunctionSettingPopup() {
        //this.functionScript.id = 0;
        this.functionScriptIndex = -1;
        this.functionScript.code = "";
        this.functionScript.elementId = "";
        this.functionScript.column = "";
        this.formElementsScripts = [];

        this.isMonacoFuncLoaded = true;
        this.showFunctionSettingPopup = true;

        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        this.formComponentsScripts = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId).map(m => m.id);


        setTimeout(() => { this.isMonacoFuncParentLoaded = true; }, 1000);
    }

    /** Save Data source on dataSourcePopup */
    saveDataSource() {
        if (this.data.isManual && this.data.dataStorage.connectionId > 0 && this.data.dataStorage.query != "") {

            let msg = "<i>" + this.languageLocal.messageSqlLinkDelete+"</i>";
            let result = confirm(msg, this.languageLocal.warning);
            result.then((dialogResult) => {
                if (dialogResult) {
                    this.data.dataStorage.connectionId = 0;
                    this.data.dataStorage.query = "";
                    this.dataSourcePopup = false;
                    this.data.valueExpr = "value";
                    this.data.displayExpr = "text";
                }
            });

        } else {

            if (this.data.isManual) {
                this.data.valueExpr = "value";
                this.data.displayExpr = "text";
            }

            this.dataSourcePopup = false;
        }
    }

    // #endregion

    // #region Monaco Initialize and Keywords
    monacoOnInit() {
        this.isMonacoLoaded = true;
        let monaco = window['monaco'];
        this.setCustomKeywords(monaco);
    }

    monacoFunctionOnInit(e) {

        this.isMonacoFuncLoaded = false;
        let monaco = window['monaco'];
        this.setCustomKeywords(monaco);
    }

    setCustomKeywords(monaco) {
        if (this.registeredLanguage)
            this.registeredLanguage.dispose();

        this.registeredLanguage = monaco.languages.registerCompletionItemProvider('plaintext', {
            provideCompletionItems: () => {
                var suggestions = [];
                let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
                let elements = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId).map(m => m.id);
                for (let i = 0; i < elements.length; i++) {
                    suggestions.push({
                        label: elements[i],
                        kind: monaco.languages.CompletionItemKind.Snippet,
                        insertText: "" + elements[i] + "",
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        documentation: 'method'
                    })
                }
                return { suggestions: suggestions };
            }
        });
    }
    // #endregion

    // #region Formül CRUD and CDK

    saveFuncParam() {
        if (this.functionScript.code != "" && this.functionScript.elementId != "") {
            let functionScriptModel = this.componentService.cloneModel(this.functionScript);
            if (typeof this.data.functionScripts == "undefined") this.data.functionScripts = [];

            if (this.functionScriptIndex == -1) {
                this.data.functionScripts.push(functionScriptModel);
            }
            else {
                let dataFunctionScript = this.data.functionScripts[this.functionScriptIndex];
                dataFunctionScript.code = functionScriptModel.code;
                dataFunctionScript.elementId = functionScriptModel.elementId;
                dataFunctionScript.column = functionScriptModel.column;
            }
            this.functionScriptIndex = -1;
            this.functionScript.code = "";
            this.functionScript.elementId = "";
            this.functionScript.column = "";
            this.formElementsScripts = [];
        }
    }

    updateFunctionScript(index) {
        this.functionScriptIndex = index;
        let dataFunctionScript = this.data.functionScripts[this.functionScriptIndex];
        this.functionScript.code = dataFunctionScript.code;
        this.functionScript.elementId = dataFunctionScript.elementId;
        this.functionScript.column = dataFunctionScript.column;
    }

    deleteFunctionScript(index) {
        this.data.functionScripts.splice(index, 1);
    }

    //* Formül tablosunda sürükle bırak özelliğini sağlamaktadır. */
    colShorting(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.data.functionScripts, event.previousIndex, event.currentIndex);
    }

    // #endregion
    
    // #region Sql Script CRUD
    saveSqlScript() {
        let model = this.componentService.cloneModel(this.sqlScriptModel);

        if (model.id > 0) {
            let scr = this.data.sqlScripts.find(f => f.id == model.id);
            scr.connectionId = model.connectionId;
            scr.elementId = model.elementId;
            scr.query = model.query;
        }
        else {
            model.id = this.data.sqlScripts.length + 1;
            this.data.sqlScripts.push(model);
        }

        this.sqlScriptModel = new SqlScriptModelMultiSelect();
        this.sqlScriptModel.query = 'SELECT * FROM TABLE WHERE KEY={' + this.data.id + '}';
    }

    updateSqlScript(i) {
        let scr = this.data.sqlScripts[i];
        this.sqlScriptModel = new SqlScriptModelMultiSelect();
        this.sqlScriptModel.id = scr.id;
        this.sqlScriptModel.connectionId = scr.connectionId;
        this.sqlScriptModel.elementId = scr.elementId;
        this.sqlScriptModel.query = scr.query;
    }

    deleteSqlScript(i) {
        this.data.sqlScripts.splice(i, 1);
    }
    // #endregion

    /**
         * If Connection Selected And Query is not empty Data will be loaded from Generic Service
         * Else
         * Else
         * 
     */
    public executeScript() {

        if (this.data.dataStorage.connectionId > 0 && this.data.dataStorage.query != "") {
            this.genericServiceExecuteScript(this.data.dataStorage.query, this.data.dataStorage.connectionId);

        } else if (this.data.dataSource && this.data.dataSource.length > 0) {

            let filteredData = this.data.dataSource.filter(f => f.lang == this.lang);
            if (filteredData && filteredData.length > 0) {
                this.data.dataSource = filteredData;
                let keys = Object.keys(this.data.dataSource[0]);
                this.data.displayColumnsExpr = keys
                //this.data.isDefaultFirstValue && this.data.dataSource.length > 0 && this.data.value == [] ? this.data.value.push(this.data.dataSource[0][this.data.valueExpr]) : this.data.value;

                let _event_ = { value: this.data.value };
                this.onValueChanged(_event_);
            }//displayExpr

        } else if (this.data.desktopDatasource != null) {

            this.fillDesignerDataSource();
        }
    }

    /**
        * Execute SQL Script with generic service.
        * @param _ConnectionId Connection id on TABLE_CONNECTIONS
     */
    public genericServiceExecuteScript(_Query, _ConnectionId) {

        this.genericService.executeQuerySecurited(_Query, _ConnectionId).subscribe((result: any) => {
            if (result && result.length > 0) {
                this.data.dataSource = result;

                let keys = Object.keys(this.data.dataSource[0]);
                this.data.displayColumnsExpr = keys

                //this.data.value = this.data.isDefaultFirstValue && this.data.dataSource.length > 0 && this.data.value == [] ? this.data.value.push(this.data.dataSource[0][this.data.valueExpr]) : this.data.value;
                try {
                    try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                } catch (ex) { }

                let _event_ = { value: this.data.value };
                this.onValueChanged(_event_);
            } else {
                this.data.dataSource = [];
            }
        });
    }


    clearScriptRecursive(componentId) {

        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        let curentComponents = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
        let currentComp = curentComponents.find(el => el.id == componentId)

        if (currentComp) {
            this.clearScriptCode(componentId, currentComp);
        }
        else if (this.componentService.tempDatagridPopupComponent.components.length > 0) {

            if (this.componentService.datagridColumnHasUserChanges[componentId] == false) {
                let _selectElement = this.componentService.tempDatagridPopupComponent.components.find(x => x.id == componentId);
                this.clearScriptCode(componentId, _selectElement);
            }
            else {

            }
        }

    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // Tüm charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    clearScriptCode(componentId, currentComp) {
        if (this.data.id != componentId) {
            this.eventEmitterService.selectboxChangeEvent(this.data.id, componentId, "clean_element", null);
        }

        if (typeof currentComp.sqlScripts != "undefined" && typeof currentComp.sqlScripts.length != "undefined" && currentComp.sqlScripts.length > 0) {
            currentComp.sqlScripts.forEach(el => {
                this.clearScriptRecursive(el.elementId)
            })
        }
    }
}
