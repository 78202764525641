import { Component, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AppInfoService, EventEmitterService } from '../../shared/services';
import { ComponentParameters } from '../component.parameters';
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.css'],
        providers: [DatePipe]
})
export class DxCommentComponent implements ComponentParameters {
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;
    @Input() anotherPopupId: number = 0;

    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    defaultLanguage: any = {};

    currentObj = { "id": 0, "userId": 0, "userFullName": "", "date": "", "comment": "", "lastUpdate": "" }
    sizeTypes = ["%", "px"];

    accordion: any = [{"id":1,"name":""}];
    showDisplay: any = "flex";
    showDisplayBoolean = false;
    invokeChangeEventLocal: Subscription;
    languageLocal: any = "";
    isShowComments: boolean = true;
    showHeight = "";
    tempArray = [];
    constructor(private eventEmitterService: EventEmitterService,
        public changeDetectorRef: ChangeDetectorRef,
        public componentService: ComponentService, private datePipe: DatePipe, private appInfoService: AppInfoService) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });

        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {
            if (sub.elementId == this.data.id) {

                if (sub.data != null && typeof sub.data != "undefined") this.data.value = sub.data;
                else if (sub.elementId == "clean_element" || sub.value == "clean_element") this.data.value = "";
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });
        this.languageLocal = this.componentService.getLanguageSource(); //language

       

    }

    ngOnInit() {

        console.log(this.componentService)
        console.log(this.appInfoService)
        this.data.uuid = this.componentService.createuuid();

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            console.log(sub)
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";

                this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;


                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });

        this.data.width = typeof this.data.width == "undefined" ? "40%" : this.data.width;
        this.data.height = typeof this.data.height == "undefined" ? "250px" : this.data.height;
        this.showHeight = typeof this.data.height == "undefined" ? "250px" : this.data.height;

        //Kullan�c� ismi de�i�mi� ise alg�lamak i�in
        this.setUsernamesToDatas();

        console.log(this.data)
    }

    setUsernamesToDatas() {
        if (this.data.value != null && this.data.value.length > 0) {
            this.data.value.forEach(data => {
                let findUser = this.appInfoService.RiverEntity.users.find(user => user.id == data.userId)
                if (typeof findUser != "undefined") {
                    data.userFullName = findUser.fullName
                } else {
                    data.userFullName = "Unknown User"
                }
            })
        }
    }

    myDate = new Date();
    saveComment() {

        let data = JSON.parse(JSON.stringify(this.currentObj))
        if (data.comment == '') return;
        let newDate = new Date()
        data.date = this.datePipe.transform(newDate, ' hh:mm dd-MM-yyyy')
        data.userId = this.appInfoService.currentUser.id;
        data.userFullName = this.appInfoService.currentUser.fullName;
        if (data.id != 0) {
            let updateIndex = this.data.value.findIndex(f => f.id == data.id)
            if (typeof updateIndex != "undefined") {
                this.data.value[updateIndex] = data
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        } else {
            if (this.data.value.length == 0)
                data.id = 1
            else if (this.data.value.length > 0)
                data.id = this.data.value[this.data.value.length - 1].id + 1
           
            this.data.value.push(data)
            this.tempArray.push(data.id)
        }
        
        
        this.currentObj = { "id": 0, "userId": 0, "userFullName":"", "date": "", "comment": "", "lastUpdate": "" }
        console.log(this.currentObj.userFullName)

        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }


    showComments() {
        this.isShowComments = !this.isShowComments
        if (this.isShowComments) {
            this.showHeight = this.data.height;
        } else {
            this.showHeight = "100";
        }
        this.changeDetectorRef.detectChanges();
        
    }
    /*
    createId() {
        let array = [{ id: 3 }, { id: 2 }, { id: 6}]
        let test = array.map(e => e.id)
        console.log(test)
    }*/

    editComUser(val) {

        this.currentObj = val;

    }

    deleteComUser(val) {
        let index = this.data.value.findIndex(e => e.id == val.id)
        this.data.value.splice(index,1)
       

    }

    disableElements() {
        return typeof this.data.disabled != "undefined" ? (this.data.disabled == false ? this.data.outsideDisabled : this.data.disabled) : false;
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // T�m charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

}

