import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';

@Component({
    selector: 'formresult-component',
    templateUrl: './formresult.component.html',
    styleUrls: ['./formresult.component.css']
})
export class DxFormResultComponent implements ComponentParameters {
    @Input() data: any;
    @Input() lang: any;
    @Input() isEdit: any;
    @Input() anotherPopupId: number = 0;
      
    showDisplay: any = "flex";

    dataTypes = [{ "name": "Decimal", "format": "#,##0.00", "type": "int" }, { "name": "Number", "format": "#", "type": "decimal" }];
    possitions = [{ "name": "Sol", "pos": "left" }, { "name": "Sağ", "pos": "right" }];
    isFormatDisabled: boolean = false;

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    languageLocal: any = "";

    constructor(private eventEmitterService: EventEmitterService, public componentService: ComponentService, public changeDetectorRef: ChangeDetectorRef) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
        
        this.eventEmitterService.invokeColumnSumFunction.subscribe(sub => {
            
            if (sub.id === this.data.id || sub.id == "sys_re_set_val") {
                this.data.value = sub.sum;
                try { 
                    this.changeDetectorRef.detectChanges();
                } catch (ex) { }
            }
        });
        this.languageLocal = this.componentService.getLanguageSource(); //language
        this.setLanguageToDatas()

    }

    ngOnInit() {
        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
         
        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                 
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });

    }

    update(): void {
        this.data.value = this.data.value;
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    onChange() {
        //this.eventEmitterService.executeExpretion();
    }

    onValueChanged(event) {
        this.isFormatDisabled = event.value == "#";
        if (this.isFormatDisabled) this.data.inputType = "int";
        else this.data.inputType = "decimal";
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // Tüm charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    setLanguageToDatas() {
        
        this.possitions = [
            { "name": this.languageLocal.left /*"Sol"*/, "pos": "left" },
            { "name": this.languageLocal.right /*"Sağ"*/, "pos": "right" }
        ];

    }

}
