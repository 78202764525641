import { Injectable } from '@angular/core';   

export class Task {
    id: number;

    parentId: number;

    title: string;

    start: Date;

    end: Date;

    progress: number;

    
}

export class Dependency {
    id: number;

    predecessorId: number;

    successorId: number;

    type: number;
}

export class Resource {
    id: number;

    text: string;
}

export class ResourceAssignment {
    id: number;

    taskId: number;

    resourceId: number;
}

const tasks: Task[] = [];

const dependencies: Dependency[] = [];

const resources: Resource[] = [{
    id: 1,
    text: 'Management',
}, {
    id: 2,
    text: 'Project Manager',
}, {
    id: 3,
    text: 'Analyst',
}, {
    id: 4,
    text: 'Developer',
}, {
    id: 5,
    text: 'Testers',
}, {
    id: 6,
    text: 'Trainers',
}, {
    id: 7,
    text: 'Technical Communicators',
}, {
    id: 8,
    text: 'Deployment Team',
}];

const resourceAssignments: ResourceAssignment[] = [];

@Injectable({
  providedIn: 'root'
})
export class GanttDemoService {

    constructor() { }    

    getTasks(): Task[] {
        return tasks;
    }

    getDependencies(): Dependency[] {
        return dependencies;
    }

    getResources(): Resource[] {
        return resources;
    }

    getResourceAssignments(): ResourceAssignment[] {
        return resourceAssignments;
    }
}
