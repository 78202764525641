import { Component, OnInit, ViewChild, Input, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService, FormService, GenericService, AppInfoService, ReportService } from '../../shared/services';
import CustomStore from 'devextreme/data/custom_store';
import { ComponentService } from '../component.service';

import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';
import { EditorComponent } from 'ngx-monaco-editor';
import { Subscription } from 'rxjs';

import { locale } from 'devextreme/localization';
import { DashboardControl, ResourceManager } from 'devexpress-dashboard';

@Component({
    selector: 'dashboard-component',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DxDashboardComponent implements ComponentParameters, OnInit, OnDestroy {
   
    @Input() data: any;
    @Input() isEdit: any;
    @Input() lang: string;
    @Input() anotherPopupId: number = 0;

    defaultLanguage: any = {};
    showDisplay: any = "flex";
    showDisplayBoolean = false;

    permissionPopup = false;
    languagePopup = false;
    showSettingPopup = false;
    languageCodes = []
   
    systemInfoPage: any;
    systemValTemp: any = "";
    systemValTempActive: any = false;

    
    private dashboardControl!: DashboardControl;
    languageLocal: any = "";

    dashboards: any = [];
    formComponents = [];
    selectedComponentId: string;
    sizeTypes = ["%", "px"];
    constructor(public appInfo: AppInfoService,
        private element: ElementRef,
        private eventEmitterService: EventEmitterService,
        private componentService: ComponentService,
        private genericService: GenericService,
        public changeDetectorRef: ChangeDetectorRef,
        public reportService: ReportService) {

        this.languageLocal = this.componentService.getLanguageSource(); //language
        this.languageCodes = this.componentService.getlanguageCodes();

        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });

       
        let _locale = this.appInfo.currentUser.deflang;
        locale(componentService.getLocaleLang(_locale));
        ResourceManager.embedBundledResources();
    }

   
    ngOnInit() {
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);
        this.getDashboards();
        this.loadComponents();
        if (!this.isEdit) {
            this.eventEmitterService.invokeOnValueChageEvent.subscribe(sub => {
                if (sub && sub.id) {
                    const comp = this.data.components.find(f => f.id == sub.id);
                    if (comp) {
                        comp.value = sub.value;
                        this.loadDashboard();
                    }
                }
            });
        }
    }

    ngAfterViewInit(): void {
        if (!this.isEdit && this.data.value !== "0") {
            this.loadDashboard();
        }
    }
    ngOnDestroy(): void {
        this.dashboardControl && this.dashboardControl.dispose();
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    loadComponents() {
        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        const componentList = this.componentService.getInputComponentsByReturnArray(currentFormSelected.components, [], this.anotherPopupId);
        this.formComponents = componentList.map(m => { return {id : m.key, value : m.value }});
    }

    getParams() {
        const params = [];
        this.data.components.forEach(item => {
            if (item.value) {
                const param = "{"+item.id+"}" + "|" + item.value;
                params.push(param);
            }
        });
        return params.join("-");
    }

    loadDashboard() {
        const params = this.getParams();
        let initialDashboardId = this.data.dashboardId + "_" + this.appInfo.currentUser.id;
        if (params && params !== "")
            initialDashboardId += "_" + params;

        if (this.dashboardControl) {
            this.dashboardControl = new DashboardControl(this.element.nativeElement.querySelector(".dashboard-container"), {
                // Specifies a URL of the Web Dashboard's server.
                endpoint: this.appInfo.appUrl + "api/dashboard",
                initialDashboardId: initialDashboardId,
                workingMode: "Viewer",
            });
            this.dashboardControl.refresh();
        }
        else {
            this.dashboardControl = new DashboardControl(this.element.nativeElement.querySelector(".dashboard-container"), {
                // Specifies a URL of the Web Dashboard's server.
                endpoint: this.appInfo.appUrl + "api/dashboard",
                initialDashboardId: initialDashboardId,
                workingMode: "Viewer",
            });
            this.dashboardControl.render();
        }
        

        //this.dashboardControl.render();
    }

    getDashboards() {
        this.reportService.getReportWithoutCategory().subscribe(result => {
            this.dashboards = result;
        });
    }


    addComponent() {
        const comp = this.formComponents.find(f => f.id === this.selectedComponentId);
        const obj: any = {};
        obj.id = this.selectedComponentId;
        obj.value = "";
        const index = this.data.components.indexOf(obj);
        if (index < 0) {
            this.data.components.push(obj);
        }
    }
    removeComponent(index) {
        this.data.components.splice(index, 1);
    }

    saveSetting() {
        const isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);

            /*const systemInfoTemp = this.componentService.currentSystemInfo.find(x => x.id == this.data.id);
            if (systemInfoTemp)
                this.componentService.currentSystemInfo.find(x => x.id == this.data.id).value = this.systemInfoPage.find(x => x.id == this.data.systemInfoId);
            else
                this.componentService.currentSystemInfo.push({ id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) });*/
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }
    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
                let systemInfoTemp = this.componentService.currentSystemInfo.find(x => x.id == this.data.id);
                if (systemInfoTemp)
                    this.componentService.currentSystemInfo.find(x => x.id == this.data.id).value = this.systemInfoPage.find(x => x.id == this.data.systemInfoId);
                else
                    this.componentService.currentSystemInfo.push({ id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) });
                // this.componentService.currentSystemInfo = { id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) };//this.systemInfoPage.find(x => x.id == this.data.systemInfoId);
            }
        });
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // T�m charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
}
