import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AppInfoService } from '../app-info.service';
import { MailhtmlModel } from '../../models/mailhtml-model';

@Injectable({
    providedIn: 'root'
})
export class MailService {

    constructor(public appInfo: AppInfoService, private http: HttpClient) {

    }

    getHtmlBody(_guid: string): Observable<any> {
        let params = new HttpParams().set("guid", _guid);

        return this.http.get<any>(this.appInfo.appUrl + "api/mail/gethtmlbody", { params: params });
    }

    postHtmlBody(model:any): Observable<MailhtmlModel> {
        return this.http.post<MailhtmlModel>(this.appInfo.appUrl + "api/mail/posthtmlbody", JSON.stringify(model), this.appInfo.httpOptions);
    }

}
