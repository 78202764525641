import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { AppInfoService, EventEmitterService, FormService, TaskService } from '../../shared/services';
import { Subscription, Observable } from 'rxjs';
//import timeZoneUtils from 'devextreme/time_zone_utils';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DxSchedulerComponent as DxSchedulerComponentDevxtreme } from 'devextreme-angular';

@Component({
    selector: 'app-scheduler',
    templateUrl: './scheduler.component.html',
    styleUrls: ['./scheduler.component.css']
})
export class DxSchedulerComponent implements ComponentParameters, OnInit, OnDestroy {
    //@ViewChild("schedulerEtr", { static: false }) schedulerEtr: DxSchedulerComponentDevxtreme;

    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;
    @Input() anotherPopupId: number = 0;

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;

    showDisplay: any = "flex";

    invokeChangeEventLocal: Subscription;


    currentDate: Date = new Date(Date.now());
    timezone: string;
    locations: string[];

    dataSource: any = [];
    resourcesDataSource: any = [];

    demoLocations: string[];

    designerMenuList: any = [];
    designerSubMenuList: any = [];
    designerMenuBrowserList: any = [];

    designerSubMenuListFilter: any = [];
    designerMenuBrowserListFilter: any = [];
    languageLocal: any = "";

    constructor(private eventEmitterService: EventEmitterService,
        public taskService: TaskService,
        private formService: FormService,
        private appInfo: AppInfoService,
        public changeDetectorRef: ChangeDetectorRef,
        private componentService: ComponentService) {
        // this.demoLocations = this.getLocations(this.currentDate);

        this.timezone = 'Europe/London';
        this.currentDate = this.currentDate;
        this.languageLocal = this.componentService.getLanguageSource(); //language

        //let thisPageMap = this;
        //setTimeout(function () {
        //    thisPageMap.mapsAPILoader.load().then(() => {
        //        thisPageMap.autocompleteService = new google.maps.places.AutocompleteService();
        //    });
        //}, 1500);

        //this.setClassMarker();
    }

    //getLocations = (date) => {
    //    const timeZones = timeZoneUtils.getTimeZones(date);
    //    return timeZones.filter((timeZone) => service.getLocations().indexOf(timeZone.id) !== -1);
    //};

    getGroupDatasource() {
        return [{
            text: this.defaultLanguage.Label,//this.data.,
            id: 1,
            color: this.data.eventColor,
            //avatar: 'images/gym/coach-man.png',
            //age: 27,
            //discipline: 'ABS, Fitball, StepFit',
        }
            //, {
            // text: 'Sandra Johnson',
            // id: 2,
            // color: '#ff9747',
            // avatar: 'images/gym/coach-woman.png',
            // age: 25,
            // discipline: 'ABS, Fitball, StepFit',
            //}
        ];
    }

    monthNamesEng = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    monthNamesTr = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
        "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
    ];
    getDatasource() {
        //visibilityElement: any = []; selectedFormData
        //   data.menuData.desc    data.menuData.image
        //  data.menuData.name    data.menuData.beginDate   data.menuData.endDate 
        let returnDS = [];
        this.selectedFormData.forEach(frmData => {

            let _start = new Date(frmData[this.data.menuData.beginDate]);
            let _end = new Date(frmData[this.data.menuData.endDate]);
            let getH_start = _start.getHours() > 9 ? _start.getHours() : "0" + _start.getHours();
            let getM_start = _start.getMinutes() > 9 ? _start.getMinutes() : "0" + _start.getMinutes();
            let getH_end = _end.getHours() > 9 ? _end.getHours() : "0" + _end.getHours();
            let getM_end = _end.getMinutes() > 9 ? _end.getMinutes() : "0" + _end.getMinutes();
            let returnDSVal = {
                groupID: 1,
                ID: frmData.ID,
                text: frmData[this.data.menuData.name],
                image: this.data.menuData.image ? frmData[this.data.menuData.image] : "", //"https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/movies/HisGirlFriday.jpg",//this.data.menuData.image ? frmData[this.data.menuData.image] : "",
                startDate: _start,
                endDate: _end,
                allDay: false,
                startDateStr: _start.getDate() + " " + this.monthNamesTr[_start.getMonth()] + " " + _start.getFullYear() + " , " + getH_start + ":" + getM_start,
                endDateStr: _end.getDate() + " " + this.monthNamesTr[_end.getMonth()] + " " + _end.getFullYear() + " , " + getH_end + ":" + getM_end,

                //,
                //recurrenceRule: 'FREQ=DAILY'
            };

            returnDS.push(returnDSVal);
        });

        return returnDS;
        /*[{
            text: 'Stand-up meeting',
            groupID: 1,
            startDate: '2021-04-26T15:30:00.000Z',
            endDate: '2021-04-26T15:45:00.000Z',
            recurrenceRule: 'FREQ=DAILY',
        }, {
            text: 'Book Flights to San Fran for Sales Trip',
            groupID: 1,
            startDate: '2021-04-28T18:00:00.000Z',
            endDate: '2021-04-28T19:00:00.000Z',
        }, {
            text: 'New Brochures',
            groupID: 1,
            startDate: '2021-04-30T18:30:00.000Z',
            endDate: '2021-04-30T18:45:00.000Z',
        }, {
            text: 'Website Re-Design Plan',
            groupID: 1,
            startDate: '2021-04-27T12:30:00.000Z',
            endDate: '2021-04-27T13:30:00.000Z',
        }, {
            text: 'Book Flights to San Fran for Sales Trip',
            groupID: 1,
            startDate: '2021-04-28T16:00:00.000Z',
            endDate: '2021-04-28T15:00:00.000Z',
        }, {
            text: 'Prepare 2021 Marketing Plan',
            groupID: 1,
            startDate: '2021-04-26T07:00:00.000Z',
            endDate: '2021-04-26T09:30:00.000Z',
        }, {
            text: 'Launch New Website',
            groupID: 1,
            startDate: '2021-04-28T08:00:00.000Z',
            endDate: '2021-04-28T10:00:00.000Z',
        }, {
            text: 'Submit New Website Design',
            groupID: 1,
            startDate: '2021-04-29T09:30:00.000Z',
            endDate: '2021-04-29T11:00:00.000Z',
        }, {
            text: 'Upgrade Server Hardware',
            groupID: 1,
            startDate: '2021-04-30T06:30:00.000Z',
            endDate: '2021-04-30T08:00:00.000Z',
        }, {
            text: 'Approve New Online Marketing Strategy',
            groupID: 1,
            startDate: '2021-04-30T11:00:00.000Z',
            endDate: '2021-04-30T12:30:00.000Z',
        }, {
            text: 'Final Budget Review',
            groupID: 1,
            startDate: '2021-04-27T09:00:00.000Z',
            endDate: '2021-04-27T10:35:00.000Z',
        }];*/
    }
    //onContentReady(e) {
    //    let dxSchedulerDateTableCell = e.element.getElementsByClassName('dx-scheduler-date-table-cell');
    //    for (let _i = 0; _i < dxSchedulerDateTableCell.length; _i++) {
    //        dxSchedulerDateTableCell[_i].addEventListener('click', function (arg) {
    //            arg.stopPropagation();
    //            arg.preventDefault();
    //        }, false);
    //    }
    //    //dxSchedulerDateTableCell.forEach(el => el.addEventListener('dxclick', arg => {
    //    //    arg.stopPropagation();
    //    //    arg.preventDefault();
    //    //}));
    //    //let _event = e;
    //    ///* (<HTMLElement>*/e.element/*)*/.getElementsByClassName('dx-scheduler-date-table-cell').addEventListener("dxclick", function (arg) {/*.on('dxclick', function (arg) {*/
    //    //     //.find('.dx-scheduler-date-table-cell').on('dxclick', function (arg) {
    //    //     arg.stopPropagation();
    //    //     arg.preventDefault();
    //    //     //let ff = _event;
    //    //     //
    //    // });
    //}
    onValueChanged(e: any) {
        this.timezone = e.value;
    }
    onAppointmentFormCreated(event) {
        event.cancel = true
    }

    onAppointmentUpdating(event) {
        //   this.menuFormGeneral
        let _nodeData = this.selectedFormData.find(x => x.ID == event.oldData.ID);
        _nodeData[this.data.menuData.beginDate] = event.newData.startDate;
        _nodeData[this.data.menuData.endDate] = event.newData.endDate;

        //_nodeData
        //this.menuFormGeneral.nodeData.find(x=>x.ID==);
        //  let menuFormGeneralClone = this.componentService.cloneModel(this.menuFormGeneral);

        //this.selectedFormData = JSON.parse(this.menuFormGeneral.nodeData);
        //this.dataSource = this.getDatasource();
        //this.resourcesDataSource = this.getGroupDatasource();
        let _nodeDataStr = JSON.stringify(_nodeData);
        //let formJustData = {
        //    formId: this.menuFormGeneral.formId,
        //    nodeData: _nodeDataStr,
        //    nodeKey: this.menuFormGeneral.nodeKey
        //};
        let _schedulerChangeData = undefined;
        if (this.componentService.schedulerChangeData) {
            if (this.componentService.schedulerChangeData[this.data.id])
                _schedulerChangeData = this.componentService.schedulerChangeData[this.data.id].find(x => x.id == event.oldData.ID);
        }

        if (_schedulerChangeData) {
            _schedulerChangeData.nodeData = _nodeDataStr;
        }
        else {
            this.componentService.schedulerChangeData = this.componentService.schedulerChangeData || {};
            this.componentService.schedulerChangeData[this.data.id] = this.componentService.schedulerChangeData[this.data.id] || [];
            this.componentService.schedulerChangeData[this.data.id].push({ id: event.oldData.ID, formId: this.menuFormGeneral.formId, nodeKey: this.menuFormGeneral.nodeKey, nodeData: _nodeDataStr });
        } //this.formService.updateJustMenuData(formJustData).subscribe((result: any) => {

        //});

    }

    //overrideDefaultPopup(event) {
    //    if (event.appointmentData) {
    //       event.cancel = true
    //        let appt = event.appointmentData
    //        //this.showCustomPopup(appt)
    //    }
    //}
    onAppointmentFormOpening(e: any) {
        e.cancel = true
        //return false;
        //const form = e.form;

        //const startDateTimezoneEditor = form.getEditor('startDateTimeZone');
        //const endDateTimezoneEditor = form.getEditor('endDateTimeZone');
        //const startDateDataSource = startDateTimezoneEditor.option('dataSource');
        //const endDateDataSource = endDateTimezoneEditor.option('dataSource');

        //startDateDataSource.filter(['id', 'contains', 'Europe']);
        //endDateDataSource.filter(['id', 'contains', 'Europe']);

        //startDateDataSource.load();
        //endDateDataSource.load();
    }

    //onOptionChanged(e) {
    //    //if (e.name === 'currentDate') {
    //    //    this.demoLocations = this.getLocations(e.value);
    //    //}
    //}




















    themePopup = false;

    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
    }

    //showSchedulerVisible = false;
    ngOnInit() {

        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);



        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });

        this.eventEmitterService.invokeSchedulerInit.subscribe(sub => {
            // if (sub.key === this.data.uuid) {
            // this.schedulerInitShow();
            // }
        });

        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {

            if (sub.elementId == this.data.id) {
                //let thisPageMapTimeout = this;
                //setTimeout(function () {
                //    if (sub.data && sub.data != "") thisPageMapTimeout.data.markers = sub.data.markers;
                //    else thisPageMapTimeout.data.markers = [];

                //    thisPageMapTimeout.changeDetectorRef.detectChanges();
                //}, 100);
            }
        });

        //this.dataSource = this.getDatasource();


        this.designerMenuList = [];
        this.designerSubMenuList = [];
        this.designerMenuBrowserList = [];
        this.taskService.getMenuList().subscribe(data => {

            this.designerMenuList = [];
            for (var i = 0; i < data.length; i++) {
                let jsonData = JSON.parse(data[i].sidebarJson);
                let _objMenu = { id: jsonData.id, name: jsonData.name };
                this.designerMenuList.push(_objMenu);

                for (var j = 0; j < jsonData.items.length; j++) {
                    let jsonDataItems = jsonData.items[j];
                    let _objSubMenu = { id: jsonDataItems.id, name: jsonDataItems.name, parentId: jsonData.id };
                    this.designerSubMenuList.push(_objSubMenu);

                    for (var k = 0; k < jsonDataItems.items.length; k++) {
                        let jsonDataBrowserItems = jsonDataItems.items[k];
                        let _objMenuBrowser = { id: jsonDataBrowserItems.id, name: jsonDataBrowserItems.name, parentId: jsonDataItems.id, formId: jsonDataBrowserItems.formId };
                        this.designerMenuBrowserList.push(_objMenuBrowser);
                        //
                    }
                    //
                }
                //
            }


            let _browser = this.designerMenuBrowserList.find(x => x.id == this.data.menuData.menuBrowserNode);
            if (this.isEdit) {
                this.designerSubMenuListFilter = [];
                this.designerMenuBrowserListFilter = [];
                this.visibilityElement = [];
                this.designerSubMenuListFilter = this.designerSubMenuList.filter(x => x.parentId == this.data.menuData.menuNodeKey);
                this.designerMenuBrowserListFilter = this.designerMenuBrowserList.filter(x => x.parentId == this.data.menuData.subMenuNodeKey);
            }
            if (_browser)
                this.getMenuForm(_browser);
            //  if (!this.isEdit) {

            //if (_browser)
            //    this.getMenuForm(_browser);
            // } 

        });



        this.resourcesDataSource = this.getGroupDatasource();

        this.setDefaultProperties();
        //this.schedulerInitShow();


    }

    onMenuValueChanged(event) {
        //this. designerMenuList  
        if (event.event) {
            this.designerSubMenuListFilter = [];
            this.data.menuData.subMenuNodeKey = "";
            this.data.menuData.menuBrowserNode = "";
            this.clearMiddleElement();
            this.designerSubMenuListFilter = this.designerSubMenuList.filter(x => x.parentId == event.value);
        }
    }

    onSubMenuValueChanged(event) {
        if (event.event) {
            this.clearMiddleElement();
            //  this. designerSubMenuList 
            this.designerMenuBrowserListFilter = this.designerMenuBrowserList.filter(x => x.parentId == event.value);
        }
    }

    clearMiddleElement() {
        this.designerMenuBrowserListFilter = [];
        this.visibilityElement = [];
        this.data.menuData.name = "";
        this.data.menuData.image = "";
        this.data.menuData.beginDate = "";
        this.data.menuData.endDate = "";
    }

    menuFormGeneral: any;
    visibilityElement: any = [];
    menuOptions = [];
    selectedForm: any;
    selectedFormData: any = [];
    onMenuBrowserValueChanged(event) {
        if (event.event) {
            this.visibilityElement = [];
            let _browser = this.designerMenuBrowserListFilter.find(x => x.id == event.value);
            if (_browser) {
                this.getMenuForm(_browser);
            }
        }
    }
    getDatasourceSch() {
        return this.dataSource;
    }
    getMenuForm(_browser) {
        this.formService.getMenuForm(_browser.formId, _browser.id, 0, this.appInfo.currentUser.id.toString()).subscribe((result: any) => {
            //this.taskService.getMenuList(data.menuData.menuBrowserNode).subscribe(data => { 
            this.menuFormGeneral = result;
            this.menuOptions = JSON.parse(this.menuFormGeneral.menuOptions);    //[{"visibility":["datebox1","selectbox1","selectbox2","textarea1"]},{"sqlColumns":[]}]
            this.visibilityElement = this.menuOptions[0].visibility;


            if (!this.isEdit) {
                this.selectedFormData = JSON.parse(this.menuFormGeneral.nodeData);
                this.selectedForm = JSON.parse(this.menuFormGeneral.formJson);
                this.componentService.formComponentTempList = [];
                this.componentService.componentListObjectTemp(this.selectedForm.components);

                this.dataSource = this.getDatasource();
                this.resourcesDataSource = this.getGroupDatasource();
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }

                this.schedulerFiles();
            }

        });
    }
    schedulerFiles() {
        debugger;
        let dataImage = this.dataSource.map(x => x.image[0]);
        this.formService.getMenuFilesData(dataImage).subscribe((s: any) => {
            debugger;
            if (s) {

                this.dataSource.forEach(_dSource => {
                    //fileData  fileType id: 1
                    let _fileData = s.files.find(x1 => x1.id == _dSource.image[0]);
                    if (_fileData)
                        _dSource.image = 'data:' + _fileData.fileType + ';base64,' + _fileData.fileData;
                    //if (_dSource.base64BlobData && document.base64BlobData != "")
                    //    document.src = 'data:' + document.fileType + ';base64,' + document.base64BlobData;
                    //else
                    //    document.src = '/assets/img/default-placeholder.png';
                    //_dSource.image =
                });
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }

            }
        });
    }
    setDefaultProperties() {
        if (typeof this.data.show === "undefined")
            this.data.show = true;
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide ? "flex" : "none") : "flex";
        //this.data.width = this.data.width > 0 ? this.data.width : 500;
        this.data.height = this.data.height > 0 ? this.data.height : 600;
        this.data.disabled = typeof this.data.disabled == "undefined" ? false : this.data.disabled;


        this.data.weekendColor = this.data.weekendColor ? this.data.weekendColor : "rgba(51, 122, 183, 0.2)";
        this.data.eventColor = this.data.eventColor ? this.data.eventColor : "#327dbf";
        this.data.weekdayColor = this.data.weekdayColor ? this.data.weekdayColor : "rgba(51, 122, 183, 0.1)";
        this.data.isChangeDefaultColor = this.data.isChangeDefaultColor ? this.data.isChangeDefaultColor : false;
        this.data.canUserChange = this.data.canUserChange ? this.data.canUserChange : true;
        this.data.startTime = this.data.startTime ? this.data.startTime : 8;
        this.data.endTime = this.data.endTime ? this.data.endTime : 18;

        this.data.viewTypes = this.data.viewTypes ? this.data.viewTypes : ["month"];
        this.selectedViewTypes["month"] = typeof this.data.viewTypes.find(x => x == "month") != "undefined";
        this.selectedViewTypes["agenda"] = typeof this.data.viewTypes.find(x => x == "agenda") != "undefined";
        this.selectedViewTypes["day"] = typeof this.data.viewTypes.find(x => x == "day") != "undefined";
        this.selectedViewTypes["week"] = typeof this.data.viewTypes.find(x => x == "workWeek") != "undefined" || typeof this.data.viewTypes.find(x => x == "week") != "undefined";
        this.selectedViewTypes["weekend"] = typeof this.data.viewTypes.find(x => x == "workWeek") != "undefined";
        //if (this.selectedViewTypes["month"] == false && this.selectedViewTypes["day"] == false && this.selectedViewTypes["agenda"] == false && this.selectedViewTypes["week"] == false && this.selectedViewTypes["weekend"] == false) {

        //    if (this.data.viewTypes.length == 0)
        //        this.data.viewTypes.push("month");
        //}
        this.selectedViewTypes["month"] = true;


        //this.currenViewGlobal = "TimelineWeek";//this.data.viewTypes[0];
        //try {
        //    let _elElement = document.getElementById(this.data.id);
        //    let _elWrapper = _elElement.querySelector(".dx-tabs-wrapper");
        //    let _elFirstTab = _elWrapper.querySelectorAll(".dx-item")[0];
        //    (<HTMLElement>_elFirstTab).click();
        //}
        //catch (ex) { 
        //}

        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        //this.data.viewTypes.push('month');  = { "month": true, "workWeek": false, "week": false };
    }

    currenViewGlobal = "month";
    selectedViewTypes = { "month": true, "week": false, "weekend": false, "day": false, "agenda": false };
    changeViewType() {
        if (this.isEdit) {

            let _month = this.selectedViewTypes["month"];
            let _week = this.selectedViewTypes["week"];
            let _weekend = this.selectedViewTypes["weekend"];

            let _agenda = this.selectedViewTypes["agenda"];
            let _day = this.selectedViewTypes["day"];

            this.data.viewTypes = ["month"];
            //if (_month == true)
            //    this.data.viewTypes.push('month');
            if (_agenda == true)
                this.data.viewTypes.push('agenda');
            if (_day == true)
                this.data.viewTypes.push('day');

            if (_week == true) {
                if (_weekend == true)
                    this.data.viewTypes.push('week');
                else
                    this.data.viewTypes.push('workWeek');
            }
            else {
                this.selectedViewTypes["weekend"] = false;
            }

            this.selectedViewTypes["month"] = true;
            //if (_month == false && _week == false && _weekend == false && _agenda == false && _day == false) {
            //    this.selectedViewTypes["month"] = true;
            //}
            try {
                let _elElement = document.getElementById(this.data.id);
                let _elWrapper = _elElement.querySelector(".dx-tabs-wrapper");
                let _elFirstTab = _elWrapper.querySelectorAll(".dx-item")[0];
                (<HTMLElement>_elFirstTab).click();

                this.changeDetectorRef.detectChanges();
            }
            catch (ex) { }
        }
    }

    schedulerInitShow() {
        try {
            // let _this = this;
            //setTimeout(function () {
            //    debugger;
            //    //_this.showSchedulerVisible = true;
            //}, 5000);
        } catch (ex) {
            /* this.showSchedulerVisible = true;*/
        }
    }
    changeDefaultColor(e) {
        if (e.value == false) {
            this.data.weekdayColor = "rgba(51, 122, 183, 0.1)";
            this.data.weekendColor = "rgba(51, 122, 183, 0.2)";
            this.data.eventColor = "#327dbf";
        }
    }
    markWeekEnd(_cellData) {
        const styles = {};
        let _day = _cellData.startDate.getDay();
        let _weekEnd = _day === 0 || _day === 6;

        if (_weekEnd) {
            this.data.weekendColor = this.data.weekendColor ? this.data.weekendColor : "rgba(51, 122, 183, 0.2)";
            styles["background-color"] = this.data.weekendColor;
        }
        else {
            this.data.weekdayColor = this.data.weekdayColor ? this.data.weekdayColor : "rgba(51, 122, 183, 0.1)";
            styles["background-color"] = this.data.weekdayColor;
        }

        this.data.eventColor = this.data.eventColor ? this.data.eventColor : "#327dbf";

        //background-color: rgba(86, 202, 133, 0.2);

        //if (_type == "container")
        //    styles['display'] = this.showDisplay;
        //else
        //    styles['display'] = "block!important";
        return styles;
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide ? "flex" : "none") : "flex";
        this.resourcesDataSource = this.getGroupDatasource();
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    openPermissionPopup() {
        this.permissionPopup = true;
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // Tüm charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    schedulerDivStyleFunc(_type) {
        let width = (this.data.width && typeof this.data.width != "undefined" && this.data.width != null) ? (this.data.width + (this.componentService.isInteger(this.data.width) ? "px" : "")) : "100%";
        //this.data.width > 0 ? this.data.width + 'px' : '500px';
        let height = (this.data.height && typeof this.data.height != "undefined" && this.data.height != null) ? (this.data.height + (this.componentService.isInteger(this.data.height) ? "px" : "")) : "100%";
        //this.data.height > 0 ? this.data.height + 'px' : '600px';
        const styles = { 'width': width, 'height': height };
        if (_type == "container")
            styles['display'] = this.showDisplay;
        else
            styles['display'] = "block!important";

        return styles;
    }











}
