import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';
import * as mimes from '../../../assets/libs/mimetypes/mime-types.json'
@Injectable({
  providedIn: 'root'
})
export class GlobalService {

    currentFormComponents:any;
    constructor() { }    

    getMimeType(extension) {
        return mimes["default"][extension]
    }

}
