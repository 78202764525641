import { Component, OnInit, ElementRef, ChangeDetectorRef, NgZone, ViewChild, OnDestroy } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, TaskService, EventEmitterService, AppInfoService, GenericService, FormService } from 'src/app/shared/services';
import { ComponentService } from 'src/app/formdesigner/component.service';
import { WebmsgModel } from '../../shared/models/webmsg-model';
import { WebMessageService } from '../../shared/services/data/webmessage.service';
import notify from 'devextreme/ui/notify';
import { DataRefreshModel } from '../../shared/models/datarefresh-model';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ReOpenFormModel, ReOpenMenuModel } from '../../shared/models/reopenform-model';
import { OpenLoadingModel } from '../../shared/models/openloading-model';
import { DomSanitizer } from '@angular/platform-browser';
import { ChatMessageModel } from '../../shared/models/chatmessage-model';



import { locale, loadMessages, formatMessage } from 'devextreme/localization';

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, OnDestroy {
    public isCollapsed = false;
    private listTitles: any[];
    public location: Location;
    private sidebarVisible: boolean;
    private mobile_menu_visible: any = 0;
    private toggleButton: any;

    favoriteMenuItems = [];

    schemaList: any = [];

    languageLocal: any = "";

    selectedFlag: any = "ENEN";

    isDesigner: any = false;
    isDmsPermission: any = false;

    filter: any = {
        schemaId: 0,
        keyword: "",

        start1: null,
        start2: null,
        end1: null,
        end2: null,
        status: 0,
        userId: 0,
        insideSearch: false
    }

    module: any = {
        home: 1,
        menu: 2
    }


    userList: any = [];

    completionStatusItems = [];

    constructor(location: Location, private authService: AuthService, private element: ElementRef, private router: Router,
        public taskService: TaskService, public formService: FormService, public componentService: ComponentService, private eventEmitterService: EventEmitterService,
        private route: ActivatedRoute, public changeDetectorRef: ChangeDetectorRef, private webMessageService: WebMessageService,
        private _ngZone: NgZone, public appInfo: AppInfoService,
        private domSanitizer: DomSanitizer,
        private genericService: GenericService) {
        this.location = location;
        this.sidebarVisible = false;

        this.signalRFunctions();

        this.selectedFlag = this.appInfo.currentUser.deflang.replace(/-/gi, '').toUpperCase();


        this.initMessages();

        this.onSelectedFlag(this.selectedFlag, "");

        this.completionStatusItems = [
            { id: 0, name: this.componentService.languageSource.all },
            { id: 1, name: this.componentService.languageSource.completions },
            { id: 2, name: this.componentService.languageSource.ongoing }
        ];
    }
    invokeSearchSetDataLocal: any;
    invokeColorSetDataLocal: any;
    ngOnDestroy() {
        try {

            this.invokeSearchSetDataLocal.unsubscribe();
            this.invokeColorSetDataLocal.unsubscribe();
        } catch (ex) {

        }
    }

    @ViewChild('myDropdown', { static: true }) myDropdown: NgbDropdown;
    languageSourceObject: any;
    localeLang: string;
    async onSelectedFlag(Code, Key) {

        this.selectedFlag = Code;
        this.languageSourceObject = JSON.parse(localStorage.getItem("languageSourceStorage"));
        if (Key != "") {
            await this.genericService.setUserLanguage(this.appInfo.currentUser.id, Key).subscribe((result: any) => {
                this.getLanguageFunc(Code);

                let entityLocalStorage = JSON.parse(localStorage.getItem("localUserSession"));
                entityLocalStorage.currentUser.deflang = this.componentService.countryFlags.find(x => x.Code == Code).Key;
                localStorage.setItem("localUserSession", JSON.stringify(entityLocalStorage));

                this.localeLangGetAndSet(Key);
            });
        }
        else {
            this.getLanguageFunc(Code);
            this.localeLangGetAndSet(Key);
        }

    }
    localeLangGetAndSet(Key) {
        this.localeLang = this.getLocaleLang();
        locale(this.localeLang);
        if (Key != "")
            parent.document.location.reload();
    }
    getLocaleLang() {
        let locale = this.appInfo.currentUser.deflang;
        return locale != null && locale != "" && locale.indexOf("-") > -1 ? locale.split("-")[0] : "tr";
    }
    getLanguageFunc(Code) {

        if (localStorage.getItem("languageSourceStorage") && this.languageSourceObject && this.selectedFlag == this.languageSourceObject.languageCode) {

            //this.componentService.languageSource = this.languageSourceObject.langSource;
            this.componentService.setLanguageSource(this.languageSourceObject.langSource);
            this.setCompletionStatusAndUser();
        }
        else {
            this.genericService.getlogininit(Code, "dictionary").subscribe((result: any) => {
                localStorage.setItem("languageSourceStorage", JSON.stringify(result.languages));
                this.languageSourceObject = JSON.parse(localStorage.getItem("languageSourceStorage"));
                //this.componentService.languageSource = this.languageSourceObject.langSource;
                this.componentService.setLanguageSource(this.languageSourceObject.langSource);
                this.setCompletionStatusAndUser();

            });
        }
        try {
            this.myDropdown.close();
        } catch { }

    }

    setCompletionStatusAndUser() {
        this.completionStatusItems = [
            { id: 0, name: this.componentService.languageSource.all },
            { id: 1, name: this.componentService.languageSource.completions },
            { id: 2, name: this.componentService.languageSource.ongoing }
        ];


        this.userList = this.appInfo.RiverEntity.users;
        var userListIndex = this.userList.findIndex(x => x.id == 0);
        if (userListIndex > -1)
            this.userList.splice(userListIndex, 1);
        //var _index = this.userList.indexOf(x => x.id == 0);
        //this.userList.splice(_index, 1);
        this.userList.unshift({ id: 0, fullName: this.componentService.languageSource.all });
        //if (!this.userList.find(x => x.id == 0))

        //else
        //    this.userList.find(x => x.id == 0).fullName = this.componentService.languageSource.all;//.unshift({ id: 0, fullName: this.componentService.languageSource.all });

        this.eventEmitterService.setLanguageFunc(this.languageSourceObject.langSource);
    }
    signalRFunctions() {
        this.messageSubscribeToEvents();
        this.dataRefreshToEvents();
        this.reOpenFormEvents();
        this.reOpenMenuEvents();
        this.openLoadingEvents();
        this.getSignalRMessage();
    }

    canChat: any = false;
    groupDefaultImage: any;
    chatUserList: any = [];
    chatUserListTemp: any = [];
    chatGroupAllUsers = [];
    ngOnInit() {
        this.chatGroupAllUsers = this.appInfo.RiverEntity.users.filter(x => x.id != this.appInfo.currentUser.id && x.canChat);

        this.canChat = this.appInfo.RiverEntity.users.find(x => x.id == this.appInfo.currentUser.id).canChat;
        this.canChat = typeof this.canChat != "undefined" ? this.canChat : false;

        this.checkDesigner();
        this.listTitles = ROUTES.filter(listTitle => listTitle);
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.router.events.subscribe((event) => {
            this.sidebarClose();
            var $layer: any = document.getElementById('bodyClick');
            if ($layer) {
                $layer.remove();
                this.mobile_menu_visible = 0;
            }
        });

        this.fillFavorite();

        this.eventEmitterService.invokeFavoriteChangeEvent.subscribe(sub => {
            this.favoriteMenuItems = sub.data;
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        });
        //

        this.userList = this.appInfo.RiverEntity.users;
        var userListIndex = this.userList.findIndex(x => x.id == 0);
        if (userListIndex > -1)
            this.userList.splice(userListIndex, 1);
        this.userList.unshift({ id: 0, fullName: this.componentService.languageSource.all });
        //if (!this.userList.find(x => x.id == 0))
        //    this.userList.unshift({ id: 0, fullName: this.componentService.languageSource.all });
        //else {
        //    this.userList.find(x => x.id == 0).fullName = this.componentService.languageSource.all;//.unshift({ id: 0, fullName: this.componentService.languageSource.all });
        //    this.changeDetectorRef.detectChanges();
        //} //  this.userList.forEach(f => { f.isChecked = false });

        this.getSchemaList();

        this.invokeSearchSetDataLocal = this.eventEmitterService.invokeSearchSetDataEvent.subscribe(sub => {
            this.filter = sub.data;
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        });
        //let _this = this;
        //setTimeout(function () {
        //    
        //    _this.filter = _this.componentService.taskListFilter;
        //}, 8000);
        this.invokeColorSetDataLocal = this.eventEmitterService.invokeColorSetDataEvent.subscribe(sub => {
            this.chatColors(true);
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        });

        this.getChatPanelUserList();

        this.chatColors(false);


        let _thisPage = this;
        let promise = this.getBase64Image("/assets/img/group2.png");
        promise.then(function (dataURL) {
            _thisPage.groupDefaultImage = dataURL
        });

        //  let defLang = this.appInfo.currentUser.deflang;

        //let gMApi = atob(typeof localStorage.getItem("GMAPI") != "undefined" ? localStorage.getItem("GMAPI") : '');
        //var script = document.createElement("script");
        //script.setAttribute("src", "https://maps.googleapis.com/maps/api/js?libraries=places&key=" + gMApi);
        //document.getElementsByTagName("head")[0].appendChild(script);

        this.getChatUserStatus();

        if (this.languageLocal == "") {
            let languageSourceObject = JSON.parse(localStorage.getItem("languageSourceStorage"));
            this.languageLocal = languageSourceObject.langSource;
            this.componentService.setLanguageSource(this.languageLocal);
        }
       // console.log(this.languageLocal)
        this.changeDetectorRef.detectChanges();
    }

    chatUserListImage: any = [];
    chatUnReadGlobal = 0;
    getChatPanelUserList() {
        let loginUserId = this.appInfo.currentUser.id;
        this.genericService.getChatUserList(loginUserId).subscribe((result: any) => {
            this.chatUserList = result;
            this.chatUserListTemp = result;

            this.chatUnReadGlobal = 0;
            this.chatUserList.forEach(f => {
                this.chatUnReadGlobal = this.chatUnReadGlobal + f.unReadCount;
                let _image = { id: f.id, photo: f.photo };
                this.chatUserListImage.push(_image);
                //f.photo = "";
            });

            this.chatUserListSearch(null);
            let _this = this;
            setTimeout(function () {
                _this.userListColor();
            }, 1000);

        });
    }
    chatUserListSearchModel = "";
    chatUserListSearch(event) {
        this.chatUserListTemp = this.chatUserList.filter(o => o.name.toLowerCase().indexOf(this.chatUserListSearchModel.toLowerCase()) > -1);
    }
    isChatOpen: any = false;
    chatOpenChange() {
        this.isChatOpen = this.isChatOpen ? false : true;
    }

    isChatMessageOpen: any = true;
    chatMessageOpenChange() {
        this.isChatMessageOpen = this.isChatMessageOpen ? false : true;
    }
    chatMessageCloseChange(chatUserSelected) {
        if (typeof chatUserSelected == "undefined") {
            this.appInfo.chatBoxMessageOpen = this.appInfo.chatBoxMessageOpen ? false : true;
            if (!this.appInfo.chatBoxMessageOpen)
                this.appInfo.chatSelectedUserGlobal = null;
            this.chatMessageText = "";
            this.chatMessageFileRemove();
        } else {
            this.appInfo.chatSelectedUserGlobal = chatUserSelected;
            //if (this.appInfo.chatSelectedUserGlobal && chatUserSelected.id == this.appInfo.chatSelectedUserGlobal.id)
            this.messageWithPage(0);

            //this.getSignalRMessage();

            this.appInfo.chatBoxMessageOpen = true;
        }
    }

    @ViewChild('scrollMe') private myScrollContainer: ElementRef;
    @ViewChild('chatMessageInput') private chatMessageInput: ElementRef;
    @ViewChild('downloadMessageFile') private downloadMessageFile: ElementRef;
    chatMessageboxImage: any = [];
    chatMessageAll: any = [];
    chatMessageText: any;
    sendFileLoading = false;
    chatMessageSend() {
        if (this.sendFileLoading == false) {
            this.sendFileLoading = true;
            let chatMessage = {
                UserId: this.appInfo.currentUser.id,
                ReceiverId: this.appInfo.chatSelectedUserGlobal.id,
                Message: this.chatMessageText,
                FileId: 0,
                UserType: this.appInfo.chatSelectedUserGlobal.type
            };
            if (this.chatMessageSelectedFile != null) {
                this.getDocument(this.chatMessageSelectedFile).then(document => {
                    let fileData = document;
                    let fileName = this.chatMessageSelectedFile.name;
                    let fileExtension = this.chatMessageSelectedFile.name.split(".").pop();
                    let fileType = this.chatMessageSelectedFile.type;
                    let fileIsImage = this.componentService.isImage(fileExtension);
                    let fileSize = this.chatMessageSelectedFile.size;
                    let chatMessageFile = {
                        fileData: fileData,
                        fileName: fileName,
                        fileExtension: fileExtension,
                        fileType: fileType,
                        fileIsImage: fileIsImage,
                        fileSize: fileSize
                    };
                    this.genericService.chatMessageFileSend(chatMessageFile).subscribe((result: any) => {
                        chatMessage.FileId = result;
                        this.chatMessageSendApi(chatMessage);
                    });
                });
            }
            else {
                this.chatMessageSendApi(chatMessage);
            }
        }
    }
    downloadLoading = false;
    chatMessageFileDownload(_id) {
        if (this.downloadLoading == false) {
            this.downloadLoading = true;
            this.genericService.chatMessageFileDownload(_id).subscribe((result: any) => {
                if (result) {
                    this.downloadMessageFile.nativeElement.href = "data:" + result.type + ";base64," + result.fileData; //Image Base64 Goes here
                    this.downloadMessageFile.nativeElement.download = result.fileName; //File name Here
                    this.downloadMessageFile.nativeElement.click();
                    this.downloadLoading = false;
                }
            });
        }
    }
    chatMessageSendApi(chatMessage) {
        this.genericService.chatMessageSend(chatMessage).subscribe((result: any) => {
            this.chatMessageText = "";
            this.getChatPanelUserList();

            if (result != null)
                this.appInfo.chatSelectedUserGlobal = result;
            if (this.appInfo.chatBoxMessageOpen && this.appInfo.chatSelectedUserGlobal && chatMessage.ReceiverId == this.appInfo.chatSelectedUserGlobal.id)
                this.messageWithPage(0);
            this.sendFileLoading = false;
            this.chatMessageFileRemove();
        });
    }
    getChatFileIcon(_fileExtension) {
        //webm   mkv    flv  vob ogv ogg avi mov wmv 3gp mp4
        _fileExtension = _fileExtension.toLowerCase();
        if (this.componentService.isImage(_fileExtension)) {
            return "assets/img/chat/image.svg";
        }
        else if (_fileExtension == "txt" || _fileExtension == "doc" || _fileExtension == "docx" || _fileExtension == "xls" || _fileExtension == "xlsx") {
            return "assets/img/chat/doc.svg";
        }
        else if (_fileExtension == "m4a" || _fileExtension == "flac" || _fileExtension == "mp3" || _fileExtension == "wav" || _fileExtension == "wma" || _fileExtension == "aac") {
            return "assets/img/chat/music.svg";
        }
        else if (_fileExtension == "pdf") {
            return "assets/img/chat/pdf.svg";
        }
        else if (_fileExtension == "zip" || _fileExtension == "rar") {
            return "assets/img/chat/zip.svg";
        }
        else {
            return "assets/img/chat/file.svg";
        }
    }
    getChatFileFormatSize(bytes, decimalPoint) {
        if (bytes == 0) return '0 Bytes';
        var k = 1000,
            dm = decimalPoint || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    messageWithPage(page): void {
        if (this.appInfo.chatSelectedUserGlobal.type != this.appInfo.chatMessageEnum.NotChat) {
            this.genericService.getMessageWithPage(this.appInfo.currentUser.id, this.appInfo.chatSelectedUserGlobal.id, page).subscribe((result: any) => {
                this.chatMessageAll = result;
                this.chatMessageAll.forEach(f => {
                    this.chatUnReadGlobal = this.chatUnReadGlobal + f.unReadCount;
                    let _image = { id: f.userId, photo: f.photo };
                    this.chatMessageboxImage.push(_image);
                    f.photo = "";
                });

                this.scrollToBottom();
                this.getChatPanelUserList();
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            });
        }
        else {
            this.chatMessageAll = [];
            this.scrollToBottom();
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        }
    }

    scrollToBottom(): void {
        try {
            let _thisPage = this;
            setTimeout(function () {
                _thisPage.myScrollContainer.nativeElement.scrollTop = _thisPage.myScrollContainer.nativeElement.scrollHeight;
            }, 100);

        } catch (err) { }
    }

    chatOnlineUsers = [];
    getChatUserStatus(): void {

        let _thisPage = this;
        try {
            setInterval(function () {
                _thisPage.chatOnlineUsers.forEach(f => {
                    let _d = f.date;
                    let date = /*this.componentService.cloneModel()*/_d.setSeconds(f.date.getSeconds());
                    let _dNow = new Date();
                    let dateNow = (_dNow).setSeconds(_dNow.getSeconds() - 7);
                    if (date < dateNow) {
                        let _i = _thisPage.chatOnlineUsers.findIndex(x => x.id == f.id);
                        _thisPage.chatOnlineUsers.splice(_i);
                    }
                });
            }, 2000);
        } catch (err) { }

        try {
            setInterval(function () {
                _thisPage.webMessageService.sendChatUserId();
            }, 5000);

        } catch (err) { }


        try {
            this.webMessageService.chatUserStatus.subscribe((s) => {
                let _i = this.chatOnlineUsers.findIndex(x => x.id == s);
                if (_i == -1)
                    this.chatOnlineUsers.push({ id: s, date: new Date() });
                else
                    this.chatOnlineUsers[_i].date = new Date();
            });

        } catch (err) { }

    }

    userStatusClass(_groupId, _type) {
        /*_userId*/
        let _fAll = this.chatUserListTemp.find(x => x.id == _groupId && x.type == _type);// ("," + x.UserIds + ",").indexOf("," + _userId + ",")); 
        let _f = _fAll.userIds;
        //let _fArray = _f.split(",");
        if (_type != 2) {
            let fClear1 = "," + _f + ",";
            let fClear2 = "," + this.appInfo.currentUser.id + ",";
            let _fClear = fClear1.replace(fClear2, ",");
            return this.chatOnlineUsers.findIndex(x => _fClear.indexOf("," + x.id + ",") != -1) == -1 ? " offline " : " online ";
        }
        else {
            return this.chatOnlineUsers.findIndex(x => x.id == _groupId) == -1 ? " offline " : " online ";
        }
    }

    @ViewChild('fileSelectInputDialog') fileSelectInputDialog: ElementRef;
    public OpenAddFilesDialog() {
        const e: HTMLElement = this.fileSelectInputDialog.nativeElement;
        e.click();
    }

    chatMessageFileUploadSuccess = false;
    chatMessageSelectedFile = null;
    chatMessageSelectedFileObj = { icon: "", name: "", size: "" };
    chatMessageImportFile(e) {
        this.chatMessageSelectedFileObj = { icon: "", name: "", size: "" };
        var file = e.target.files[0];
        this.chatMessageSelectedFile = file;

        this.chatMessageFileUploadSuccess = true;

        this.chatMessageSelectedFileObj.name = file.name;
        let fileExtensionArray = file.name.split(".");
        this.chatMessageSelectedFileObj.icon = this.getChatFileIcon(fileExtensionArray[fileExtensionArray.length - 1]);
        this.chatMessageSelectedFileObj.size = this.getChatFileFormatSize(file.size, 1);

        //var fileSize = file.size;
    }
    chatMessageFileRemove() {
        if (this.sendFileLoading == false) {
            this.chatMessageSelectedFile = null;
            this.chatMessageFileUploadSuccess = false;
            this.fileSelectInputDialog.nativeElement.value = '';
            this.chatMessageSelectedFileObj = { icon: "", name: "", size: "" };
        }
    }
    allEmoji = ["&#128512;", "&#128513;", "&#128514;", "&#128516;", "&#128517;", "&#128518;", "&#128519;", "&#128520;", "&#128521;", "&#128522;", "&#128523;", "&#128524;", "&#128525;", "&#128526;", "&#128527;",
        "&#128528;", "&#128529;", "&#128530;", "&#128531;", "&#128533;", "&#128536;", "&#128544;", "&#128546;", "&#128548;", "&#128555;"];
    emojiChatPopoverOpen = false;
    toggleWithEmojiChat() {
        this.emojiChatPopoverOpen = !this.emojiChatPopoverOpen;
    }
    chatMessageBlur() {

    }

    chatMessageInputStartPos = null;
    chatMessageInputEndPos = null;
    chatMessageFocus() {
        let _thisPage = this;
        setTimeout(function () {
            let myField = _thisPage.chatMessageInput.nativeElement;
            _thisPage.chatMessageInputStartPos = myField.selectionStart;
            _thisPage.chatMessageInputEndPos = myField.selectionEnd;
        }, 500);

    }
    chatMessageSetEmoji(_emoji) {
        let myField = this.chatMessageInput.nativeElement;
        if (!(this.chatMessageInputStartPos)) {
            this.chatMessageInputStartPos = myField.selectionStart;
            this.chatMessageInputEndPos = myField.selectionEnd;
        }
        let myValueClear = this.insertAtCursor(_emoji);
        this.chatMessageText = myValueClear;
    }
    hideEmojiPopoper(e) {
        //this.chatMessageInputStartPos = null;
        //this.chatMessageInputEndPos = null;
        let myField = this.chatMessageInput.nativeElement;
        if (!(this.chatMessageInputStartPos)) {
            myField.selectionStart = this.chatMessageInputStartPos;
            myField.selectionEnd = this.chatMessageInputEndPos;
        }
        myField.focus();
    }
    insertAtCursor(myValue) {
        ////IE support
        //if (document.selection) {
        //    myField.focus();
        //    sel = document.selection.createRange();
        //    sel.text = myValue;
        //}
        ////MOZILLA and others
        //else
        //: HTMLElement 
        var txtValueEl = document.createElement('textarea');
        txtValueEl.innerHTML = myValue;

        let myField = this.chatMessageInput.nativeElement;
        if (this.chatMessageInputStartPos || this.chatMessageInputStartPos == '0') {
            myField.value = myField.value.substring(0, this.chatMessageInputStartPos)
                + txtValueEl.value
                + myField.value.substring(this.chatMessageInputEndPos, myField.value.length);
        } else {
            myField.value += txtValueEl.value;
        }
        return myField.value;
    }
    //notificationNew: any = false;
    private getSignalRMessage(): void {
        // if (this.appInfo.chatSelectedUserGlobal) {
        let loginUserId = this.appInfo.currentUser.id;
        //let receiver = this.appInfo.chatSelectedUserGlobal.id;
        this.webMessageService.chatMessageGetAll.subscribe((message: ChatMessageModel) => {
            this._ngZone.run(() => {
                //&& this.module.home
                if (message.groupUserIds.split(",").indexOf(loginUserId.toString()) > -1) {


                    if (message.userId != loginUserId) {
                        this.playAudio();
                        //  this.notificationNew = true;
                    }
                    //let _thisPage = this;
                    //setTimeout(function () {
                    //    _thisPage.notificationNew = false;
                    //}, 500);

                    if (this.appInfo.chatBoxMessageOpen && this.appInfo.chatSelectedUserGlobal && message.receiverId == this.appInfo.chatSelectedUserGlobal.id)
                        this.messageWithPage(0);

                    this.getChatPanelUserList();
                }
            });
        });
        //  }
    }

    playAudio() {
        let audio = new Audio();
        audio.src = "/assets/others/chat_notification.mp3";
        audio.loop = false;
        audio.load();
        audio.play();
    }
    searchPanelPopoverOpen = false;
    toggleSearchPanel() {
        this.searchPanelPopoverOpen = !this.searchPanelPopoverOpen;
    }
    userListSelectSearch(_e) { this.searchSelectboxAriaOwns(_e); }
    schemaListSelectSearch(_e) { this.searchSelectboxAriaOwns(_e); }
    start2DateSearch(_e) { this.searchSelectboxAriaOwns(_e); }
    start1DateSearch(_e) { this.searchSelectboxAriaOwns(_e); }
    end1DateSearch(_e) { this.searchSelectboxAriaOwns(_e); }
    end2DateSearch(_e) { this.searchSelectboxAriaOwns(_e); }
    completionStatusItemsSelectSearch(_e) { this.searchSelectboxAriaOwns(_e); }
    searchSelectboxAriaOwns(_e) {
        let _ariaOwns = _e.currentTarget.attributes.getNamedItem("aria-owns");//.find(x => x.name == "aria-owns");
        if (_ariaOwns) {
            let _ariaOwnsHtml = document.getElementById(_ariaOwns.nodeValue);
            if (_ariaOwnsHtml) {
                let _parentHtml = _ariaOwnsHtml.parentNode;
                if (_parentHtml) {
                    let _parent2Html = _parentHtml.parentNode;
                    if (_parent2Html) {
                        (<HTMLElement>_parent2Html).style.zIndex = "100000000000";
                    }
                }
            }
        }
    }

    groupChatPopoverOpen = false;
    toggleWithGroupChat() {
        this.groupAddName = "";
        this.groupAddUsers = [];
        this.groupAddImage = "";
        this.groupChatPopoverOpen = !this.groupChatPopoverOpen;
    }
    groupAddName: any = "";
    groupAddUsers: any = [];
    uploadedFilesGroupAdd: any = [];
    imgUpVisibleGroupAdd = false;
    onBlurGroupAdd() {
        this.imgUpVisibleGroupAdd = false;
    }
    onFocusGroupAdd() {
        this.imgUpVisibleGroupAdd = true;
    }


    groupAdd() {

        let chatGroupAdd = {
            CreatedUserId: this.appInfo.currentUser.id,
            Name: this.groupAddName,
            Users: this.groupAddUsers.toString(),
            File: this.groupAddImage || this.groupAddImage == "" ? this.groupDefaultImage : this.groupAddImage
        };
        this.genericService.chatGroupAddService(chatGroupAdd).subscribe((result: any) => {
            if (result) {
                this.getChatPanelUserList();
            }
        });
    }


    getBase64Image(url) {
        var promise = new Promise(function (resolve, reject) {

            var img = new Image();
            // To prevent: "Uncaught SecurityError: Failed to execute 'toDataURL' on 'HTMLCanvasElement': Tainted canvases may not be exported."
            img.crossOrigin = "Anonymous";
            img.onload = function () {
                var canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL("image/png");
                resolve(dataURL.replace(/^data:image\/(png|jpg|jpeg|pdf);base64,/, ""));
            };
            img.src = url;
        });

        return promise;
    }

    bodyColor: any = "#e56744";
    searchColor: any = "#f18567";
    searchTextColor: any = "#3f1c13";
    headerColor: any = "#DB2F00";
    otherBackground: any = "#ffb5a0";

    chatColors(isListLoaded) {
        // this.appInfo.currentUser.color
        //   var _localUserSession = JSON.parse(localStorage.getItem("localUserSession"));
        //  _localUserSession.currentUser.color

        let color = this.appInfo.currentUser.color;
        this.bodyColor =
            color == "yellow" ? "#c0aa71" : (color == "blue" ? "#429bc4" : (color == "orange" ? "#e56744" :
                (color == "red" ? "#a14850" : (color == "green" ? "#35ae65" : (color == "black" ? "#565656" : "#e56744")))));

        this.searchColor =
            color == "yellow" ? "#d5c396" : (color == "blue" ? "#a3ccdf" : (color == "orange" ? "#f18567" :
                (color == "red" ? "#d78f8f" : (color == "green" ? "#8fdfaf" : (color == "black" ? "#a3a3a3" : "#f18567")))));

        this.searchTextColor =
            color == "yellow" ? "#493d20" : (color == "blue" ? "#2b7395" : (color == "orange" ? "#3f1c13" :
                (color == "red" ? "#301515" : (color == "green" ? "#315540" : (color == "black" ? "#070606" : "#3f1c13")))));

        this.headerColor =
            color == "yellow" ? "#A7925B" : (color == "blue" ? "#0D87C0" : (color == "orange" ? "#DB2F00" :
                (color == "red" ? "#87222C" : (color == "green" ? "#097735" : (color == "black" ? "#292929" : "#DB2F00")))));

        this.otherBackground =
            color == "yellow" ? "#feefc9" : (color == "blue" ? "#c3ecff" : (color == "orange" ? "#ffb5a0" :
                (color == "red" ? "#ffc6c6" : (color == "green" ? "#c5ffdc" : (color == "black" ? "#d2d6de" : "#ffb5a0")))));



        this.scssSetVariable('.chat-all-panels', " --chat-search-color: " + this.searchColor + "; --chat-search-text-color: " + this.searchTextColor +
            "; --chat-panel-color: " + this.headerColor + "; --chat-body-color: " + this.bodyColor + "; --chat-other-color: " + this.otherBackground + ";");
        //this.scssSetVariable('.chat-panel', " --chat-panel-color: " + this.headerColor);
        //this.scssSetVariable('.friends-search-bar', " --chat-search-color: " + this.searchColor + "; --chat-search-text-color: " + this.searchTextColor);
        //this.scssSetVariable('.chat-body', " --chat-body-color: " + this.bodyColor);
        //this.scssSetVariable('.chat-users', " --chat-search-color: " + this.searchColor + "; --chat-search-text-color: " + this.searchTextColor + "; --chat-panel-color: " + this.headerColor);

        /*
        this.scssSetVariable('.box-header', " --chat-panel-color: " + this.headerColor);
        this.scssSetVariable('.chat-send-button', " --chat-panel-color: " + this.headerColor);
        */

        //this.scssSetVariable('.direct-chat-primary', " --chat-search-color: " + this.searchColor + "; --chat-search-text-color: " + this.searchTextColor +
        //    "; --chat-panel-color: " + this.headerColor);






        //var chatPanel = <HTMLElement>document.querySelector('.chat-panel');
        //if (chatPanel != undefined) {
        //    chatPanel.setAttribute('data-color', this.headerColor);
        //    chatPanel.setAttribute('style', " --chat-panel-color: " + this.headerColor);
        //}

        //var searchBar = <HTMLElement>document.querySelector('.friends-search-bar');
        //if (searchBar != undefined) {
        //    searchBar.setAttribute('data-color', this.searchColor);
        //    searchBar.setAttribute('style', " --chat-search-color: " + this.searchColor + "; --chat-search-text-color: " + this.searchTextColor);
        //}

        //var chatBody = <HTMLElement>document.querySelector('.chat-body');
        //if (chatBody != undefined) {
        //    chatBody.setAttribute('data-color', this.bodyColor);
        //    chatBody.setAttribute('style', " --chat-body-color: " + this.bodyColor);
        //}

        if (isListLoaded) {
            this.userListColor()
        }

    }
    scssSetVariable(selector, style) {
        var _element = <HTMLElement>document.querySelector(selector);
        if (_element != undefined) {
            _element.setAttribute('style', style);
        }
    }
    userListColor() {
        var listItems = document.querySelectorAll('.friends-list-item');
        if (listItems != undefined) {
            listItems.forEach(e => {
                let listItem = <HTMLElement>e;
                listItem.setAttribute('data-color', this.searchColor);
                listItem.setAttribute('style', " --chat-search-color: " + this.searchColor + "; --chat-search-text-color: " + this.searchTextColor);
            })
        }
    }
    photoAll = [];
    makeTrustedImage(type, photoOrId, _default = 'team2.jpg') {

        if (type == "message-box-header" || type == "group-add") {
            let item = (photoOrId && photoOrId != null &&
                photoOrId != "" &&
                photoOrId != "ZABhAHQAYQA6AGkAbQBhAGcAZQAvAHAAbgBnADsAYgBhAHMAZQA2ADQALABpAFYAQgA=" ?
                'data:image/png;base64,' + photoOrId :
                "/assets/img/" + _default);
            let imageString = JSON.stringify(item).replace(/\\n/g, '');
            let _style = 'url(' + imageString + ')';
            return this.domSanitizer.bypassSecurityTrustStyle(_style);
        }

        let _photo;
        if (type == "user-list") {

            _photo = this.chatUserListImage.find(x => x.id == photoOrId).photo;
        }
        else if (type == "message-box") {
            _photo = this.chatMessageboxImage.find(x => x.id == photoOrId).photo;
        }

        _photo = (_photo && _photo != null &&
            _photo != "" &&
            _photo != "ZABhAHQAYQA6AGkAbQBhAGcAZQAvAHAAbgBnADsAYgBhAHMAZQA2ADQALABpAFYAQgA=" ?
            'data:image/png;base64,' + _photo :
            "/assets/img/" + _default);

        let photoCssVar = "--photo_" + type + "_" + photoOrId;
        let photoTemp = this.photoAll.find(x => x.name == photoCssVar);
        if (!photoTemp) {
            this.photoAll.push({ name: photoCssVar, b64: _photo });
            let imageString = JSON.stringify(_photo).replace(/\\n/g, '');
            let photo = photoCssVar + ":" + 'url(' + imageString + ');';

            var _element = <HTMLElement>document.querySelector(".chat-all-panels");
            if (_element != undefined) {
                let f = _element.getAttribute('style');
                _element.setAttribute('style', f + photo);
            }
        }
        return "var(" + photoCssVar + ")";
        //else {
        //    let imageString = JSON.stringify(_photo).replace(/\\n/g, '');
        //    photo = photoCssVar + ":" + 'url(' + imageString + ');';
        //}

        //let item = (photo && photo != null &&
        //    photo != "" &&
        //    photo != "ZABhAHQAYQA6AGkAbQBhAGcAZQAvAHAAbgBnADsAYgBhAHMAZQA2ADQALABpAFYAQgA=" ?
        //    'data:image/png;base64,' + photo :
        //    "/assets/img/" + _default);
        //const _style = photo;
        //this.domSanitizer.bypassSecurityTrustStyle(_style);
    }

    getImageStyle(type, photoId, other) {

        let myStyles = {
            'background-size': "cover",
            'background-color': '#fff',
            'background-repeat': 'no-repeat',
            'position': "relative",
            'width': "30px",
            'height': "30px",
            "margin-right": "5px",
            "background-image": photoId ? this.makeTrustedImage(type, photoId) : (type == 'group-add' ? 'group2.png' : 'team2.jpg')
        };

        if (other) {
            let otherArr = other.split(";");
            otherArr.forEach(e => {
                let otherParse = e.split(":");
                myStyles[otherParse[0]] = otherParse[1];//.push({ name: otherParse[0], value: otherParse[1] });
            });
        }

        return myStyles;
    }

    groupAddImage: any;
    onValChngFileGroupAdd = (e: any): void => {
        let errors = [];
        if (e.value.length) {
            let checkSum = e.value[0].size;
            if (checkSum > (2 * 1000000)) {
                let msg = e.value[0].name + " yüklenemedi."
                errors.push(msg);
                return;
            }

            this.getDocument(e.value[0]).then(document => {
                this.groupAddImage = document;
            });
        }
    }
    getDocument(file) {
        return new Promise(resolve => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (args: any) => {
                if (reader.result) {
                    let base64BlobData: any = args.target.result.split(',').pop();
                    resolve(base64BlobData);
                }
            }

        });
    }

    private messageNotification(message): void {

        if (message.message_title == null || message.message_title == "") {
            notify({
                message: message.message
            }, message.message_type, 3000);
        }
        else {
            notify({
                contentTemplate: function () {
                    return $("<p />").html(("<b>" + message.message_title + "</b><div style=\"margin-top: -4px; line-height: 22px;\">" + message.message + "</div>"))
                        .attr("style", "margin-top: -44px;margin-left: 49px;margin-bottom: 0px;");
                }
            }, message.message_type, 3000);
        }
    }
    private webMessagePopupClick(): void {
        this.isShowWebMessagePopup = false;
    }
    message_screen = new WebmsgModel();
    isShowWebMessagePopup: boolean = false;
    private messageSubscribeToEvents(): void {
        let loginUserId = this.appInfo.currentUser.id;
        this.webMessageService.messageReceived.subscribe((message: WebmsgModel) => {
            this._ngZone.run(() => {
                if (message.user_id.split(",").indexOf(loginUserId.toString()) > -1/*message.clientuniqueid !== this.uniqueID && message.senduniqueid == this.uniqueID*/) {
                    // 0: pop-up 1:bildirim 2: ikisi birden 
                    if (message.msg_show_type == 0) {
                        this.isShowWebMessagePopup = true;

                        //let result = alert(message.message, "Favori Eklenecek!");
                        //result.then((dialogResult) => {

                        //});
                        //// alert("POPUP - " +message.message);

                    }
                    else if (message.msg_show_type == 1) {
                        this.messageNotification(message);
                        //   alert("BİLDİRİM - " + message.message);
                    }
                    else {
                        this.isShowWebMessagePopup = true;
                        // message.message_type = "received";
                        //  alert("İKİSİBİRDEN - " + message.message);
                        this.messageNotification(message);
                    }

                    this.message_screen = message;

                    this.webMessageService.saveMessageInfo(message).subscribe((r: any) => {

                    });

                }
            });
        });
    }

    private dataRefreshToEvents(): void {
        let loginUserId = this.appInfo.currentUser.id;
        this.webMessageService.changeModule.subscribe((message: DataRefreshModel) => {
            this._ngZone.run(() => {
                //&& this.module.home
                if (message.user_Id == loginUserId) {
                    let data_scheme = {
                        module: message.module
                    };
                    this.eventEmitterService.refreshGrid(data_scheme);

                    //if (this.router.url.toLowerCase() == "/home") {

                    //}
                    //else {

                    //}


                }
            });
        });
    }

    private reOpenFormEvents(): void {

        let loginUserId = this.appInfo.currentUser.id;
        this.webMessageService.reOpenForm.subscribe((message: ReOpenFormModel) => {
            this._ngZone.run(() => {
                if (message.userId == loginUserId) {

                    if (this.router.url.toLowerCase() == "/home") {
                        let data_scheme = {
                            instanceId: message.instanceId,
                            processFormId: message.processFormId,
                            schemeId: message.schemeId
                        };
                        this.eventEmitterService.openForm(data_scheme);
                    }
                    else {
                        this.router.navigate(['home'], { state: { id: message.schemeId, instanceId: message.instanceId, processFormsId: message.processFormId } });
                    }

                }
            });
        });
    }
    private reOpenMenuEvents(): void {

        let loginUserId = this.appInfo.currentUser.id;
        this.webMessageService.reOpenMenu.subscribe((message: ReOpenMenuModel) => {
            this._ngZone.run(() => {
                if (message.userId == loginUserId) {

                    let data_menu = {
                        menuFormGuid: message.menuFormGuid,
                        name: message.name,
                        schemeId: message.schemeId,
                        formId: 0
                    };
                    this.formService.getMenuFormId(message.menuFormGuid).subscribe((result: any) => {
                        if (result) {
                            data_menu.formId = result;
                            this.eventEmitterService.openMenu(data_menu);
                        }
                    });
                }
            });
        });
    }
    private openLoadingEvents(): void {

        let loginUserId = this.appInfo.currentUser.id;
        this.webMessageService.openLoading.subscribe((message: OpenLoadingModel) => {
            this._ngZone.run(() => {
                if (message.userId == loginUserId) {

                    if (this.router.url.toLowerCase() == "/home") {
                        let data_ = {
                            isShow: message.isShow,
                            text: message.text
                        };
                        this.eventEmitterService.setHomePopupVisibleFunc(data_);
                    }

                }
            });
        });
    }


    search() {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        }
        //this.minimizeSidebar();    
        // d


        let start1 = "", start2 = "", end1 = "", end2 = "", insideSearch = "";
        start1 = this.filter.start1 != null && this.filter.start1 != "null" ? this.filter.start1.getDate() + "-" + (this.filter.start1.getMonth() + 1) + "-" + this.filter.start1.getFullYear() : null;
        start2 = this.filter.start2 != null && this.filter.start2 != "null" ? this.filter.start2.getDate() + "-" + (this.filter.start2.getMonth() + 1) + "-" + this.filter.start2.getFullYear() : null;
        end1 = this.filter.end1 != null && this.filter.end1 != "null" ? this.filter.end1.getDate() + "-" + (this.filter.end1.getMonth() + 1) + "-" + this.filter.end1.getFullYear() : null;
        end2 = this.filter.end2 != null && this.filter.end2 != "null" ? this.filter.end2.getDate() + "-" + (this.filter.end2.getMonth() + 1) + "-" + this.filter.end2.getFullYear() : null;

        insideSearch = this.filter.insideSearch != null && this.filter.insideSearch != "null" && typeof this.filter.insideSearch != 'undefined' ? this.filter.insideSearch : false;

        this.filter.keyword = this.filter.keyword == "" ? null : this.filter.keyword;

        let _filterKeyDateControl = this.filter.keyword;
        try {
            let _dSlash = _filterKeyDateControl.split('/').length == 3;
            let _dDot = _filterKeyDateControl.split('.').length == 3;
            if (_dSlash || _dDot) {
                let _dHours = "";
                if (_filterKeyDateControl.split(':').length == 2 && _filterKeyDateControl.indexOf(' ') > -1) {
                    _dHours = _filterKeyDateControl.split(' ')[1];
                }
                let splitSep = _dSlash ? "/" : ".";
                let splitDate = _filterKeyDateControl.replace(_dHours, "").split(splitSep);

                let _dDateSplit = splitDate[2].trim() + "-" + splitDate[1] + "-" + splitDate[0] +
                    (_dHours == "" ? "" : " " + _dHours)

                if (_dHours != "")
                    _filterKeyDateControl = new Date(_dDateSplit).toISOString().replace(":00.000Z", "");
                else
                    _filterKeyDateControl = new Date(_dDateSplit).toISOString().replace(":00.000Z", "").replace("T00:00","");

            }
        }
        catch {
        }

        let _routerUrl = '/search/' +
            this.filter.schemaId + "/" +
            _filterKeyDateControl + "@@" + this.filter.keyword.replace(/\//gi, '__') + "/" +
            this.filter.status + "/" +
            this.filter.userId + "/" +

            start1 + "/" +
            start2 + "/" +
            end1 + "/" +
            end2 + "/" +
            insideSearch;
        debugger;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([
            _routerUrl
        ], { state: { formName: name } });
        //let _filter = JSON.stringify(this.filter);
        //this.taskService.getTaskList(_filter).subscribe(result => {

        //    

        //});
    }

    async getSchemaList() {
        await this.taskService.getSchemeList().subscribe(result => {
            //let resultSchema = this.componentService.allSchemaList;
            let resultSchema = [];
            resultSchema = result;
            //  this.componentService.allSchemaList = resultSchema;

            this.eventEmitterService.getAllSchemaList(resultSchema);

            //isScheme: true
            resultSchema.forEach(item => {

                if (!item.isScheme) {
                    this.filterSchemaList(item.items);
                }
                else {
                    this.schemaList.push(item);
                }
            });
            // this.schemaList = resultSchema.map(x => x.items);
        });
    }
    filterSchemaList(items) {
        items.forEach(item => {
            if (!item.isScheme) {
                this.filterSchemaList(item.items);
            }
            else {
                this.schemaList.push(item);
            }
        });
    }
    onClickFavorite(id, name) {
        let data_favorite_scheme = {
            id: id,
            name: name
        };
        this.eventEmitterService.createTaskAndStartForm(data_favorite_scheme);
        // this.createTask();
    }

    fillFavorite() {
        this.taskService.GetFavoriteList().subscribe((r: any) => {
            this.favoriteMenuItems = r;
        }, error => {
            // this.componentService.showAlert("Üzgünüz, beklenmedik bir hata oluştu,", "error", 2000);
        });
    }
    checkDesigner() {

        if (this.appInfo.currentUser.tabs.search("4") == -1) {
            this.isDesigner = false;
        } else {
            this.isDesigner = true;
        }

        if (this.appInfo.currentUser.tabs.search("5") != -1) {
            this.isDmsPermission = true;
        } else {
            this.isDmsPermission = false;
        }

    }
    getTitle() {

        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
        }
        for (let i = 0; i < this.listTitles.length; i++) {
            if (this.listTitles[i].type === "link" && this.listTitles[i].path === titlee) {
                return this.listTitles[i].title;
            } else if (this.listTitles[i].type === "sub") {
                for (let j = 0; j < this.listTitles[i].children.length; j++) {
                    let subtitle = this.listTitles[i].path + '/' + this.listTitles[i].children[j].path;

                    if (subtitle === titlee) {
                        return this.listTitles[i].children[j].title;
                    }
                }
            }
        }
        return '';
    }
    collapse() {
        this.isCollapsed = !this.isCollapsed;
        const navbar = document.getElementsByTagName('nav')[0];
        if (this.isCollapsed) {
            navbar.classList.remove('navbar-transparent');
            navbar.classList.add('bg-white');
        } else {
            navbar.classList.add('navbar-transparent');
            navbar.classList.remove('bg-white');
        }

    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
        const html = document.getElementsByTagName('html')[0];

        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);

        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        this.toggleButton.classList.remove('toggled');
        const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];

        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const html = document.getElementsByTagName('html')[0];
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];

        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
        const html = document.getElementsByTagName('html')[0];

        if (this.mobile_menu_visible == 1) {
            // $('html').removeClass('nav-open');
            html.classList.remove('nav-open');
            if ($layer) {
                $layer.remove();
            }
            setTimeout(function () {
                $toggle.classList.remove('toggled');
            }, 400);

            this.mobile_menu_visible = 0;
        } else {
            setTimeout(function () {
                $toggle.classList.add('toggled');
            }, 430);

            var $layer = document.createElement('div');
            $layer.setAttribute('id', 'bodyClick');


            if (html.getElementsByTagName('body')) {
                document.getElementsByTagName('body')[0].appendChild($layer);
            }

            $layer.onclick = function () { //asign a function
                html.classList.remove('nav-open');
                this.mobile_menu_visible = 0;
                setTimeout(function () {
                    $layer.remove();
                    $toggle.classList.remove('toggled');
                }, 400);
                const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];

                if (window.innerWidth < 991) {
                    setTimeout(function () {
                        mainPanel.style.position = '';
                    }, 500);
                }
            }.bind(this);

            html.classList.add('nav-open');
            this.mobile_menu_visible = 1;

        }
    };



    initMessages() {
        //loadMessages(deMessages);
        //loadMessages(ruMessages);
        loadMessages({
            'en': {
                "dxList-selectAll": "Select All",
                "dxDataGrid-ariaSelectAll": "Select All",
                Yes: "Yes",
                No: "No",
                Cancel: "Cancel",
                Clear: "Clear",
                Done: "Done",
                Loading: "Loading...",
                Select: "Select...",
                Search: "Search",
                Back: "Back",
                OK: "OK",
                "dxPager-infoText": "Page {0} of {1} ({2} items)",
                "dxDataGrid-headerFilterOK": "OK",
                "dxDataGrid-headerFilterCancel": "Cancel",

                "dxDataGrid-editingEditRow": "Edit",
                "dxDataGrid-editingSaveRowChanges": "Save",
                "dxDataGrid-editingCancelRowChanges": "Cancel",
                "dxDataGrid-editingDeleteRow": "Delete",
                "dxDataGrid-editingUndeleteRow": "Undelete",
                "dxDataGrid-editingConfirmDeleteMessage": "Are you sure you want to delete this record?",
                "dxDataGrid-validationCancelChanges": "Cancel changes",
                "dxDiagram-dialogButtonOK": "OK",
                "dxDiagram-dialogButtonCancel": "Cancel",
                "dxDataGrid-exportTo": "Export",
                "dxDataGrid-exportToExcel": "Export to Excel file",
                "dxDataGrid-exporting": "Exporting...",
                "dxDataGrid-excelFormat": "Excel file",
                "dxDataGrid-selectedRows": "Selected rows",
                "dxDataGrid-exportSelectedRows": "Export selected rows",
                //"dxDataGrid-exportAll": "Export all data",
                "dxDataGrid-searchPanelPlaceholder": "Search...",
                "dxDataGrid-noDataText": "No data",


                "dxScheduler-editorLabelTitle": "Subject",
                "dxScheduler-editorLabelStartDate": "Start Date",
                "dxScheduler-editorLabelEndDate": "End Date",
                "dxScheduler-editorLabelDescription": "Description",
                "dxScheduler-editorLabelRecurrence": "Repeat",
                "dxScheduler-openAppointment": "Open appointment",
                "dxScheduler-recurrenceNever": "Never",
                "dxScheduler-recurrenceMinutely": "Every minute",
                "dxScheduler-recurrenceHourly": "Hourly",
                "dxScheduler-recurrenceDaily": "Daily",
                "dxScheduler-recurrenceWeekly": "Weekly",
                "dxScheduler-recurrenceMonthly": "Monthly",
                "dxScheduler-recurrenceYearly": "Yearly",
                "dxScheduler-recurrenceRepeatEvery": "Repeat Every",
                "dxScheduler-recurrenceRepeatOn": "Repeat On",
                "dxScheduler-recurrenceEnd": "End repeat",
                "dxScheduler-recurrenceAfter": "After",
                "dxScheduler-recurrenceOn": "On",
                "dxScheduler-recurrenceRepeatMinutely": "minute(s)",
                "dxScheduler-recurrenceRepeatHourly": "hour(s)",
                "dxScheduler-recurrenceRepeatDaily": "day(s)",
                "dxScheduler-recurrenceRepeatWeekly": "week(s)",
                "dxScheduler-recurrenceRepeatMonthly": "month(s)",
                "dxScheduler-recurrenceRepeatYearly": "year(s)",
                "dxScheduler-switcherDay": "Day",
                "dxScheduler-switcherWeek": "Week",
                "dxScheduler-switcherWorkWeek": "Work Week",
                "dxScheduler-switcherMonth": "Month",
                "dxScheduler-switcherAgenda": "Agenda",
                "dxScheduler-switcherTimelineDay": "Timeline Day",
                "dxScheduler-switcherTimelineWeek": "Timeline Week",
                "dxScheduler-switcherTimelineWorkWeek": "Timeline Work Week",
                "dxScheduler-switcherTimelineMonth": "Timeline Month",
                "dxScheduler-recurrenceRepeatOnDate": "on date",
                "dxScheduler-recurrenceRepeatCount": "occurrence(s)",
                "dxScheduler-allDay": "All day",
                "dxScheduler-confirmRecurrenceEditMessage": "Do you want to edit only this appointment or the whole series?",
                "dxScheduler-confirmRecurrenceDeleteMessage": "Do you want to delete only this appointment or the whole series?",
                "dxScheduler-confirmRecurrenceEditSeries": "Edit series",
                "dxScheduler-confirmRecurrenceDeleteSeries": "Delete series",
                "dxScheduler-confirmRecurrenceEditOccurrence": "Edit appointment",
                "dxScheduler-confirmRecurrenceDeleteOccurrence": "Delete appointment",
                "dxScheduler-noTimezoneTitle": "No timezone",
                "dxScheduler-moreAppointments": "{0} more",

            },
            'tr': {
                "dxList-selectAll": "Tümünü Seç",
                "dxDataGrid-ariaSelectAll": "Tümünü Seç",
                Yes: "Evet",
                No: "Hayır",
                Cancel: "İptal",
                Clear: "Temizle",
                Done: "Tamam",
                Loading: "Yükleniyor...",
                Select: "Seç...",
                Search: "Ara",
                Back: "Geri",
                OK: "Tamam",
                "dxPager-infoText": "Toplam {1} sayfadan {0}. gösteriliyor ({2} kayıt)",
                "dxDataGrid-headerFilterOK": "Tamam",
                "dxDataGrid-headerFilterCancel": "İptal",
                "dxDataGrid-editingEditRow": "Düzenle",
                "dxDataGrid-editingSaveRowChanges": "Kaydet",
                "dxDataGrid-editingCancelRowChanges": "İptal",
                "dxDataGrid-editingDeleteRow": "Sil",
                "dxDataGrid-editingUndeleteRow": "Silmeyi geri al",
                "dxDataGrid-editingConfirmDeleteMessage": "Bu kaydı silmek istediğinize emin misiniz?",
                "dxDataGrid-validationCancelChanges": "Değişiklikleri İptal Et",
                "dxDiagram-dialogButtonOK": "Tamam",
                "dxDiagram-dialogButtonCancel": "İptal",
                "dxDataGrid-exportTo": "Dışa Aktar",
                "dxDataGrid-exportToExcel": "Excel Dosyasını Dışa Aktar",
                "dxDataGrid-exporting": "Dışa Aktarılıyor...",
                "dxDataGrid-excelFormat": "Excel Dosyası",
                "dxDataGrid-selectedRows": "Seçili Kolonlar",
                "dxDataGrid-exportSelectedRows": "Seçili Kolonları Dışa Aktar",
                //"dxDataGrid-exportAll": "Bütün Kayıtları Dışa Aktar",
                "dxDataGrid-searchPanelPlaceholder": "Ara...",
                "dxDataGrid-noDataText": "Veri Yok",



                "dxScheduler-editorLabelTitle": "Konu",
                "dxScheduler-editorLabelStartDate": "Başlangıç Tarihi",
                "dxScheduler-editorLabelEndDate": "Bitiş Tarihi",
                "dxScheduler-editorLabelDescription": "Açıklama",
                "dxScheduler-editorLabelRecurrence": "Tekrar",
                "dxScheduler-openAppointment": "Randevu Aç",
                "dxScheduler-recurrenceNever": "Asla",
                "dxScheduler-recurrenceMinutely": "Minutely",
                "dxScheduler-recurrenceHourly": "Hourly",
                "dxScheduler-recurrenceDaily": "Günlük",
                "dxScheduler-recurrenceWeekly": "Haftalık",
                "dxScheduler-recurrenceMonthly": "Aylık",
                "dxScheduler-recurrenceYearly": "Yıllık",
                "dxScheduler-recurrenceRepeatEvery": "Her tekrarla",
                "dxScheduler-recurrenceRepeatOn": "Tekrarla",
                "dxScheduler-recurrenceEnd": "Tekrarı bitir",
                "dxScheduler-recurrenceAfter": "Sonra",
                "dxScheduler-recurrenceOn": "İle",
                "dxScheduler-recurrenceRepeatMinutely": "minute(s)",
                "dxScheduler-recurrenceRepeatHourly": "hour(s)",
                "dxScheduler-recurrenceRepeatDaily": "günler",
                "dxScheduler-recurrenceRepeatWeekly": "haftalar",
                "dxScheduler-recurrenceRepeatMonthly": "aylar",
                "dxScheduler-recurrenceRepeatYearly": "yıllar",
                "dxScheduler-switcherDay": "Gün",
                "dxScheduler-switcherWeek": "Hafta",
                "dxScheduler-switcherWorkWeek": "Çalışma Haftası",
                "dxScheduler-switcherMonth": "Ay",
                "dxScheduler-switcherAgenda": "Ajanda",
                "dxScheduler-switcherTimelineDay": "Zaman Çizelgesi Günü",
                "dxScheduler-switcherTimelineWeek": "Zaman Çizelgesi Haftası",
                "dxScheduler-switcherTimelineWorkWeek": "Zaman Çizelgesi Çalışma Haftası",
                "dxScheduler-switcherTimelineMonth": "TZaman Çizelgesi Çalışma Ayı",
                "dxScheduler-recurrenceRepeatOnDate": "tarihinde",
                "dxScheduler-recurrenceRepeatCount": "olaylar",
                "dxScheduler-allDay": "Tüm gün",
                "dxScheduler-confirmRecurrenceEditMessage": "Yalnızca bu randevuyu veya tüm diziyi düzenlemek ister misiniz?",
                "dxScheduler-confirmRecurrenceDeleteMessage": "Yalnızca bu randevuyu veya tüm diziyi silmek istiyor musunuz?",
                "dxScheduler-confirmRecurrenceEditSeries": "Serileri düzenle",
                "dxScheduler-confirmRecurrenceDeleteSeries": "Serileri sil",
                "dxScheduler-confirmRecurrenceEditOccurrence": "Randevuyu düzenle",
                "dxScheduler-confirmRecurrenceDeleteOccurrence": "Randevuyu sil",
                "dxScheduler-noTimezoneTitle": "Saat dilimi yok",
                "dxScheduler-moreAppointments": "{0} daha",
            }
        });
    }


}
