import { Component, OnInit, Input } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { GenericService } from '../../shared/services';

@Component({
    selector: 'app-datasource',
    templateUrl: './datasource.component.html',
    styleUrls: ['./datasource.component.scss']
})
export class DxDatasourceComponent implements ComponentParameters, OnInit {
    @Input() data: any;
    @Input() isEdit: any;
    @Input() lang: string;
    @Input() anotherPopupId: number = 0;

      
    ismonocoActive: any = false;
    languageLocal: any = "";

    showSettingPopup: boolean = false;
    constructor(private eventEmitterService: EventEmitterService, private genericService: GenericService, public componentService: ComponentService) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
                setTimeout(f => {
                    this.ismonocoActive = true;
                }, 1000);
            }
        });
        this.languageLocal = this.componentService.getLanguageSource(); //language

    }

    ngOnInit() {
        this.data.uuid = this.componentService.createuuid();
        //if(!this.isEdit) this.executeScript();
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.ismonocoActive = false; 
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.ismonocoActive = false; 
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }


    openSettings() {
        this.data.showEditPop = true;
    }

    onValueChanged(event) {
        this.data.value = event.value;
        this.eventEmitterService.executeExpretion(this.anotherPopupId);
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // T�m charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    executeScript() {
        if (this.data.query != "" && this.data.connectionString != "") {
            this.genericService.getDataTable(this.data.query, this.data.connectionString).subscribe(result => {
                this.data.dataSource = result;
            });
        }
    }

}
