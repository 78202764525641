import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { DomSanitizer } from '@angular/platform-browser';
import { confirm } from 'devextreme/ui/dialog';
import { GenericService } from '../../shared/services';

@Component({
    selector: 'app-display',
    templateUrl: './display.component.html',
    styleUrls: ['./display.component.scss']
})
export class DxDisplayComponent implements OnInit {
    @ViewChild('gridContainer', { static: false }) dataGrid: DxDataGridComponent;
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;
    @Input() anotherPopupId: number = 0;
  
    showDisplay: any = "flex";

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;


    editButtonOptions: any;
    popupVisible = false;


    poVisible: boolean = false;

    startEditAction: string = "click";
    selectTextOnEditStart: boolean = true;

    gridData: any = [];
    btnSettings = {
        icon: "more",
        type: "default",
        onClick: () => {
            this.openDataPopup();
        }
    };

    dataSources: any;
    dataGridPopup: boolean = false;

    selectedImageSrc: any;
    selectedVideoSrc: any;
    showImage: boolean = false;
    showVideo: boolean = false;
    languageLocal: any = "";

    constructor(private eventEmitterService: EventEmitterService,
        private sanitizer: DomSanitizer,
        private genericService: GenericService,
        private componentService: ComponentService, public changeDetectorRef: ChangeDetectorRef) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
        this.languageLocal = this.componentService.getLanguageSource(); //language

    }

    ngOnInit() {
        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);
        this.getDataSources();
        if (!this.isEdit) {
            this.setData();
        }

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";


        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                 
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });

    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    setData() {
        if (this.data.dataSourceKey) {
            let ds = this.componentService.getDataSourceByKey(this.data.dataSourceKey, this.anotherPopupId);
            if (ds && ds.query != "" && ds.connectionString != "") {
                this.genericService.getDataTable(ds.query, ds.connectionString).subscribe(result => {
                    this.data.dataSource = result;
                    
                    this.setValue(this.data.index);
                });
            }
        }
    }

    onChange() {
        this.eventEmitterService.executeExpretion(this.anotherPopupId);
    }

    openDataPopup() {
        /*let ds = this.componentService.getDataSourceByKey(this.data.dataSourceKey);
        this.data.dataSource = ds.dataSource;*/
        this.dataGridPopup = true;
    }

    getDataSources() {
        this.dataSources = this.componentService.getComponentsByType("datasource", this.anotherPopupId);
    }

    selectedData(data) {
        let index = data.rowIndex;
        this.data.index = index;
        this.setValue(index);
    }

    setValue(index) {
        var cdata = this.data.dataSource[index];
        if (cdata) {
            this.data.value = cdata[this.data.dataSourceCol];
            this.data.imgSrc = cdata[this.data.imgCol];
            this.data.videoSrc = cdata[this.data.videoCol];
            this.dataGridPopup = false;
        }
    }


    showImagePopup(src) {
        this.selectedImageSrc = src;
        this.showImage = true;
    }

    showVideoPopup(src) {
        this.selectedVideoSrc = src;
        this.showVideo = true;
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // T�m charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    convertBinaryToBase64(imgByte) {
        let TYPED_ARRAY = new Uint8Array(imgByte); //binary data gelecek
        const STRING_CHAR = String.fromCharCode.apply(null, TYPED_ARRAY);
        let base64String = btoa(STRING_CHAR);
        return 'data:image/png;base64,' + base64String; //this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + base64String);
        /*const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {
            return data + String.fromCharCode(byte);
        }, ''));*/
    }
}
