import { Component, ChangeDetectorRef, OnInit, ViewChild, Input, OnDestroy, ElementRef } from '@angular/core';
import { TaskService, AppInfoService, ReportService, EventEmitterService, GenericService } from '../../shared/services';
import { InstanceService } from '../../shared/services/data/instance.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from "lodash";
import notify from "devextreme/ui/notify";

import { confirm } from 'devextreme/ui/dialog';
import { DxDataGridComponent } from 'devextreme-angular';
import { ComponentService } from '../../formdesigner/component.service';

import { DxTreeViewComponent, DxDrawerComponent } from 'devextreme-angular';
import { DashboardControl, ResourceManager } from 'devexpress-dashboard';
import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ReportModel } from '../../shared/models/report-model';
import { NodeModel } from '../../shared/models/node-model';
import { LocationStrategy, Location, PathLocationStrategy } from '@angular/common';

// import themes from "devextreme/ui/themes";


@Component({
    templateUrl: 'reportdesigner.component.html',
    styleUrls: ['./reportdesigner.component.scss'],
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]
})

export class ReportDesignerComponent implements OnInit {
    @Input() data: any;

    @ViewChild(DxDrawerComponent, { static: false }) drawer: DxDrawerComponent;


    reportModel: ReportModel;
    nodeModel: NodeModel;

    reportTypes = {
        grid: 1,
        pivot: 2,
        white: 3,
        dashboard: 4
    }

    localRules = [
        { value: 1, text: "Türkiye" },
        { value: 2, text: "Diğer" }
    ]
    localRule = 1;


    reports: any;

    reportUrl: string = '';
    hostUrl: string = '';
    invokeAction: string = ''

    selectedReportType: number = 0;
    selectedReportId: any = 0;
    selectedReportName: string = "Rapor Seçilmedi";
    selectedReportTypeName: string = "Rapor Tipi Seçilmedi";

    menuButtonOptions: any;
    backButtonOptions: any;
    refreshButtonOptions: any;
    userButtonOptions: any;
    gridButtonOptions: any;
    pivotButtonOptions: any;
    whitePaperButtonOptions: any;
    dashboardButtonOptions: any;

    connections: any = [];

    showReportTypePopup: boolean = false;
    showNodeFormPopop: boolean = false;
    showReportFormPopop: boolean = false;

    isAddingChild: boolean = false;

    processKey = {
        "addParent": "parent",
        "addChild": "child",
        "addReport": "report",
        "update": "update",
        "delete": "delete"
    }

    parentNodes = [];
    editorOptions = { theme: 'vs', language: 'sql', automaticLayout: true };

    dataStorage = {
        "connectionStringId": 0,
        "query": "",
        "data": [],
        "storage": {}
    }

    permissions = { "groups": [], "users": [] }
    groups: any = [];
    users: any = [];


    constructor(
        private router: Router,
        private appInfo: AppInfoService,
        private reportingService: ReportService,
        private genericService: GenericService,
        private eventEmitterService: EventEmitterService,
        private http: HttpClient,
        private _location: Location) {
        this.reportModel = new ReportModel();
        this.reportModel.useInWeb = true;
        this.reportModel.useInMobile = true;

        this.nodeModel = new NodeModel();
        this.nodeModel.orderNo = 1;
        
        // let routerState1 = this.router.getCurrentNavigation();
        // let routerState2 = this.router.getCurrentNavigation().extras;
        let _router = this.router.getCurrentNavigation();
        let routerState = null;
        if (_router)
            routerState = _router.extras.state;

        
        if (typeof routerState !== "undefined" && typeof routerState.id !== "undefined") {
            this.selectItemReport(routerState.id, routerState.name, routerState.isReport);
        }

        this.eventEmitterService.subsStartInstance = this.eventEmitterService.invokePageWithRouteChangeEvent.subscribe(sub => {
            
            if (routerState != null || typeof _router === "undefined" || typeof routerState === "undefined")
                this.selectItemReport(sub.data.id, sub.data.name, sub.data.isReport);
        });



        // this.route.paramMap.subscribe( params => {
        //     
        //     let idd=this.route.getCurrentNavigation().extras.state.example;
        //     let id = params.get('id'); 
        //     let name = params.get('name'); 
        //     let isReport = params.get('isReport'); 
        //     this.selectItemReport(id, name, isReport);   
        //   });

    }


    ngOnInit() {
        let userSession = localStorage.getItem("localUserSession");
        let entity = JSON.parse(userSession);
        this.appInfo.currentUser = entity.currentUser;
        this.appInfo.RiverEntity = entity;
        this.groups = entity.userGroups;
        this.users = entity.users;
        
        this.users.forEach(f => { f.selected = false; });
        this.groups.forEach(f => { f.selected = false; });

        this.connections = entity.connections;
        this.reportModel.connectionId = this.connections ? this.connections[0].id : 0;
        this.prepareToolbar();
        this.getReports();
    }

    getReports() {
        this.reportingService.getAllReports().subscribe(data => {
            this.reports = data;
        });

        this.reportingService.getParentNodes().subscribe((data: any) => {
            this.parentNodes = data;
        });
    }

    prepareToolbar() {
        this.menuButtonOptions = {
            icon: 'detailslayout',
            onClick: () => {
                this.drawer.instance.toggle();
            }
        };

        this.refreshButtonOptions = {
            icon: 'refresh',
            onClick: () => {
                notify('Yenilendi!');
            }
        };


        this.backButtonOptions = {
            icon: 'back',
            onClick: () => {
                this._location.back();
            }
        };


        this.userButtonOptions = {
            icon: 'user',
            text: this.appInfo.currentUser.name,
            onClick: () => {
                //notify('Add button has been clicked!');
            }
        };

        this.gridButtonOptions = {
            icon: 'edit',
            text: "Grid",
            onClick: () => {
                this.selectedReportType = this.reportTypes.grid;
                this.selectedReportTypeName = "Grid";
            }
        };

        this.pivotButtonOptions = {
            icon: 'edit',
            text: "Pivot",
            onClick: () => {
                this.selectedReportType = this.reportTypes.pivot;
                this.selectedReportTypeName = "Pivot";
            }
        };

        this.whitePaperButtonOptions = {
            icon: 'edit',
            text: "Beyaz Sayfa",
            onClick: () => {
                this.selectedReportType = this.reportTypes.white;
                this.selectedReportTypeName = "Beyaz Sayfa Raporu";
            }
        };

        this.dashboardButtonOptions = {
            icon: 'edit',
            text: "Dashboard",
            onClick: () => {
                this.selectedReportType = this.reportTypes.dashboard;
                this.selectedReportTypeName = "Dashboard";
            }
        };


    }


    selectItemReport(/*event,*/ id, name, isReport) {
        
        if (isReport) {
            this.selectedReportId = id;
            this.selectedReportName = name;
            //this.drawer.instance.toggle();
            this.showReportTypePopup = true;
        }

        /*setTimeout(f => {
          this.selectedReportId = event.itemData.id;
        }, 100);*/
    }




    selectItem(event) {
        if (event.itemData.isReport) {
            this.selectedReportId = event.itemData.id;
            this.selectedReportName = event.itemData.name;
            //this.drawer.instance.toggle();
            this.showReportTypePopup = true;
        }

        /*setTimeout(f => {
          this.selectedReportId = event.itemData.id;
        }, 100);*/
    }

    itemClick(key, data) {
        
        
        if (key == this.processKey.addParent) {
            this.nodeModel.parentId = 0;
            this.nodeModel.name = "";
            this.isAddingChild = false;
            this.showNodeFormPopop = true;
        }
        else if (key == this.processKey.addChild) {
            this.nodeModel.parentId = data.id;
            this.nodeModel.name = "";
            this.isAddingChild = true;
            this.showNodeFormPopop = true;
        }
        else if (key == this.processKey.addReport) {
            this.nodeModel.name = data.name;
            this.reportModel.nodeId = data.id;
            this.showReportFormPopop = true;
        }
        else if (key == this.processKey.update) {
            if (data.isReport) {
                this.reportingService.getReportById(data.id).subscribe((result: ReportModel) => {
                    
                    this.reportModel = result;
                    let ids = result.users.split(',');
                    if (ids && ids.length > 0) {
                        this.users.forEach(f => {
                            for (let i = 0; i < ids.length; i++) {
                                if (ids[i] == f.id) {
                                    f.selected = true;
                                }
                            }
                        });
                    }

                    this.showReportFormPopop = true;
                });
            }
            else {
                this.reportingService.getNodeById(data.id).subscribe((result: NodeModel) => {
                   
                    this.nodeModel = result;
                    this.showNodeFormPopop = true;
                });
            }
        }
        else if (key == this.processKey.delete) {
            let msg = "<i>Kaydı silmek istediğinizden emin misiniz?</i>"
            let result = confirm(msg, "Uyarı!");
            result.then((dialogResult) => {
                if (dialogResult) {
                    if (data.isReport) {
                        this.reportingService.deleteReport(data.id).subscribe(result => {
                            if (result) {
                                this.getReports();
                                this.nodeModel = new NodeModel();
                                this.reportModel = new ReportModel();
                                this.reportModel.useInWeb = true;
                                this.reportModel.useInMobile = true;
                                this.showToast("Rapor Silindi", "success");
                            }
                        });
                    }
                    else {
                        this.reportingService.deleteNode(data.id).subscribe(result => {
                            if (result) {
                                this.getReports();
                                this.nodeModel = new NodeModel();
                                this.reportModel = new ReportModel();
                                this.reportModel.useInWeb = true;
                                this.reportModel.useInMobile = true;
                                this.showToast("Kırılım Silindi", "success");
                            }
                        });
                    }
                }
            });
        }
    }

    resetNodeModel() {
        this.nodeModel.id = 0;
        this.nodeModel.parentId = 0;
        this.nodeModel.name = "";
        this.nodeModel.orderNo = 1;
    }


    onConnectionValueChanged(event) {
        this.reportModel.connectionId = event.value;
    }

    changeUserPermission(isChecked, id) {
        if (isChecked) {
            let index = this.permissions.users.indexOf(id);
            if (index == -1)
                this.permissions.users.push(id);
        }
        else {
            let index = this.permissions.users.indexOf(id);
            if (index > -1)
                this.permissions.users.splice(index, 1);
        }
    }

    changeGrpoupPermission(isChecked, id) {
        if (isChecked) {
            let index = this.permissions.groups.indexOf(id);
            if (index == -1)
                this.permissions.groups.push(id);
        }
        else {
            let index = this.permissions.groups.indexOf(id);
            if (index > -1)
                this.permissions.groups.splice(index, 1);
        }
    }

    selectAllUser(isSelected) {
        this.permissions.users = [];
        if (isSelected) {
            this.users.forEach(f => {
                f.selected = true;
                this.permissions.users.push(f.id);
            });
        }
        else {
            this.users.forEach(f => {
                f.selected = false;
            });
        }
    }

    selectAllGroup(isSelected) {
        this.permissions.groups = [];
        if (isSelected) {
            this.groups.forEach(f => {
                f.selected = true;
                this.permissions.groups.push(f.id);
            });
        }
        else {
            this.groups.forEach(f => {
                f.selected = false;
            });
        }
    }

    saveNode() {
        if (this.nodeModel.name == null || this.nodeModel.name == "") {
            this.showToast("Lütfen kırılım adını giriniz", "warning");
        }
        else {
            this.reportingService.saveNode(this.nodeModel).subscribe((result: any) => {
                if (result) {
                    this.getReports();
                    this.resetNodeModel();
                    this.showNodeFormPopop = false;
                    this.showToast("Kırılım Eklendi", "success");
                }
                else this.showToast("Hata oluştu", "error");
            });
        }

    }

    saveReport() {
        if (this.reportModel.connectionId <= 0) {
            this.showToast("Lütfen bağlantı adresi seçiniz", "warning");
        }
        else if (this.reportModel.mainSql == null || this.reportModel.mainSql == "") {
            this.showToast("Lütfen sql script giriniz", "warning");
        }
        else if (this.reportModel.name == null || this.reportModel.name == "") {
            this.showToast("Lütfen rapor adını giriniz", "warning");
        }
        else {
            this.dataStorage.connectionStringId = this.reportModel.connectionId;
            this.dataStorage.query = this.reportModel.mainSql;
            this.reportModel.gridStorage = JSON.stringify(this.dataStorage);
            this.reportModel.pivotStorage = JSON.stringify(this.dataStorage);
            this.setPermissions();
            this.reportingService.saveReport(this.reportModel).subscribe((result: any) => {
                if (result) {
                    this.getReports();
                    this.reportModel = new ReportModel();
                    this.reportModel.useInWeb = true;
                    this.reportModel.useInMobile = true;
                    this.users.forEach(f => { f.selected = false; })
                    this.groups.forEach(f => { f.selected = false; })
                    this.showReportFormPopop = false;
                    this.showToast("Rapor Eklendi", "success");
                }
                else this.showToast("Hata oluştu", "error");
            });
        }

    }

    setPermissions() {
        if (this.permissions.users.length > 0) {
            this.reportModel.users = this.permissions.users.join(",");
        }
        else {
            this.reportModel.users = "";
        }
        if (this.permissions.groups.length > 0) {
            this.reportModel.groups = this.permissions.groups.join(",");
        }
        else {
            this.reportModel.groups = "";
        }
    }

    executeScript(isMain) {
        
        let query = "";
        if (isMain) query = this.reportModel.mainSql;
        else query = this.reportModel.preSql;
        var currentUserId = this.appInfo.currentUser.id;
        if (query != null && query != "" && this.reportModel.connectionId > 0) {
            this.genericService.executeQuerySecurited(query, this.reportModel.connectionId/*, currentUserId*/).subscribe(result => {
                if (result && result.length > 0) {
                    notify("Bağlantı başarılı. İşlenecek veri sayısı: " + result.length, "success", 3000);
                }
                else {
                    notify("Veri bulunamadı! Lütfen bağlantınızı ve sql komutunuzu kontrol ediniz", "warning", 3000);
                }
            }, err => {
                notify("Bağlantı sağlanamadı! Lütfen bağlantınızı ve sql komutunuzu kontrol ediniz", "warning", 3000);
            });
        }
        else {
            notify("Lütfen bağlantınızı ve sql komutunuzu doldurunuz", "warning", 3000);
        }

    }

    sqlParseParametersValues: any = [];
    showReportFilterPopup: boolean = false;

    saveReportFilter() {
        
        this.genericService.sqlParseParameters = this.sqlParseParametersValues;
        this.showReportFilterPopup = false;
    }
    openReport(type, name) {
        
        this.genericService.sqlParseParameters = this.sqlParseParametersValues;
        this.sqlParseParametersValues = [];
        this.selectedReportType = type;
        this.selectedReportTypeName = name;
        let selectedId = this.selectedReportId;
        this.selectedReportId = 0;
        setTimeout(f => {
            this.selectedReportId = selectedId;
        }, 100);
        this.showReportTypePopup = false;
    }

    openReportFilter() {
        this.sqlParseParametersValues = [];
        //left: " where USERNAME like "
        //right Name: "Kullanıcı Adı"
        //rightvalue: "USERNAME"
        //SingleQuote: true
        //PercentageMark: false
        //value: " where {USERNAME like '[Kullanıcı Adı,USERNAME]'}"
        this.reportingService.getReportStorage(this.selectedReportId, this.reportTypes.grid).subscribe((result: any) => {
            if (result && result.query != null && result.query != "") {
                var queryString = result.query;
                queryString = this.genericService.replaceAll(queryString, "{CURRENTUSERID}", this.appInfo.currentUser.id);
                queryString = this.genericService.cleanNewline(queryString, " ");
                queryString = this.genericService.cleanSpaces(queryString, " ");
                var parserSql = this.genericService.ParseSql(queryString);


                
                for (var i = 0; i < parserSql.length; i++) {

                    //   var _header = parserSql[i].rightName.indexOf("|||") > -1 ? parserSql[i].rightName.split("|||")
                    if (parserSql[i].rightName.indexOf("||") > -1) {
                        var _headerSplit = parserSql[i].rightName.split("||");
                        var _valueSplit = parserSql[i].rightvalue.split("||");
                        for (var j = 0; j < _headerSplit.length; j++) {
                            this.sqlParseParametersValues.push({
                                header: _headerSplit[j],
                                name: _valueSplit[j],
                                value: "",
                                singleQuote: parserSql[i].SingleQuote,
                                percentageMark: parserSql[i].PercentageMark
                            });
                        }
                    }
                    else {
                        this.sqlParseParametersValues.push({
                            header: parserSql[i].rightName,
                            name: parserSql[i].rightvalue,
                            value: "",
                            singleQuote: parserSql[i].SingleQuote,
                            percentageMark: parserSql[i].PercentageMark
                        });
                    }
                }                
                this.genericService.sqlParseParameters = this.sqlParseParametersValues;
                this.showReportFilterPopup = true;

            }
        });
    }
    showWhitePage() {
        this.reportUrl = 'TestReport';
        this.hostUrl = 'http://localhost:51857';
        this.invokeAction = "/DXXRDV";
    }

    showToast(message, status) {
        notify(message, status, 3000);
    }








}
