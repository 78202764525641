import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { AppInfoService, GenericService } from '../../shared/services';

export class SqlScriptCheckBoxModel {
    id: number;
    connectionId: number;
    elementId: string;
    query: string;
}

@Component({
    selector: 'checkbox-component',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss']
})
export class DxCheckboxComponent implements ComponentParameters, OnInit {
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;
    @Input() anotherPopupId: number = 0;

    showDisplay: any = "flex";
    showDisplayBoolean = false;

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    showEditorPopup: boolean = false;
    showTextArea: boolean = false;      // onEditorPopup
    // Monaco
    editorOptions = { theme: 'vs-dark', language: 'dummy' };
    registeredLanguage: any;
    tempMonacoText: string = ""

    activeCase = ""
    activeStatus = ""
    status = {
        active: {
            enable: { count: 0 , value: ""},
            disable: { count: 0, value: "" },
            invisible: { count: 0, value: "" },
            visible: { count: 0, value: "" },
        },
        passive: {
            enable: { count: 0, value: "" },
            disable: { count: 0, value: "" },
            invisible: { count: 0, value: "" },
            visible: { count: 0, value: "" },
        }
    }
    languageLocal: any = "";

    formElements: any;
    sqlScriptModel: SqlScriptCheckBoxModel;
    showChangeEventPopup: boolean = false;
    isMonacoLoaded: boolean = false;
    connections: any = [];
        

    constructor(
        private eventEmitterService: EventEmitterService,
        public componentService: ComponentService,
        public changeDetectorRef: ChangeDetectorRef,
        private appInfoService: AppInfoService,
        private genericService: GenericService    ) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
        this.languageLocal = this.componentService.getLanguageSource(); //language

    }

    ngOnInit() {
        this.connections = this.appInfoService.RiverEntity.connections;
        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

        this.data.sqlScripts = typeof this.data.sqlScripts == "undefined" ? [] : this.data.sqlScripts;

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                 
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });

        if (typeof this.data.status != "undefined" && this.data.status.length > 0) {
            let activeData = this.data.status.find(val => val.text.toLowerCase() == "active")
            let passiveData = this.data.status.find(val => val.text.toLowerCase() == "passive")

            if (typeof activeData != "undefined") {
                Object.keys(activeData).forEach(key => {
                    
                    if (key != "text") {
                        this.status.active[key].value = activeData[key]
                        if (activeData[key] != "") {
                            let array: any[] = activeData[key].split(",")
                            this.status.active[key].count = array.length
                        }
                    }
                   
                })
            }

            if (typeof passiveData != "undefined") {
                Object.keys(passiveData).forEach(key => {

                    if (key != "text") {
                        this.status.passive[key].value = passiveData[key]
                        if (passiveData[key] != "") {
                            let array: any[] = passiveData[key].split(",")
                            this.status.passive[key].count = array.length
                        }
                    }

                })
            }
        }
    }

    disableElements() {
        return typeof this.data.disabled != "undefined" ? (this.data.disabled == false ? this.data.outsideDisabled : this.data.disabled) : false;
    }
    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));

        if (typeof this.data.status == "undefined") {
            this.shouldCreate('active');
            this.shouldCreate('passive');
        } else {

            if (this.data.status.length > 0) {

                let activeData = this.data.status.find(val => val.text.toLowerCase() == "active")
                let passiveData = this.data.status.find(val => val.text.toLowerCase() == "passive")

                if (typeof activeData == "undefined") {
                    this.shouldCreate('active');
                } else {
                    console.log("find active and update")
                    this.updateItems('active')
                }

                if (typeof passiveData == "undefined") {
                    this.shouldCreate("passive")
                } else {
                    this.updateItems('passive')
                }

            }
        }

    }

    //value : 'active' || 'passive'
    shouldCreate(_value) {
        let _shouldCreate = false;
        Object.keys(this.status[_value]).forEach(key => {
            if (this.status[_value][key].value != "") {
                _shouldCreate = true
                console.log("should create" + _value)
            }
        })

        if (_shouldCreate) {
            let obj = { text: _value }
            Object.keys(this.status[_value]).forEach(key => {
                obj[key] = this.status[_value][key].value
            })
            if (typeof this.data.status == "undefined") {
                this.data.status = []
            } 
            this.data.status.push(obj)
            console.log(this.data.status)
        }
        
    }

    updateItems(value) {
        this.data.status.forEach(element => {
            if (element.text.toLowerCase() == value) {
                Object.keys(this.status[value]).forEach(key => {
                    element[key] = this.status[value][key].value
                })
            }
        })
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    counter = 0;
    onValueChanged(e) {

        this.eventEmitterService.onValueChangeEvent(this.data.id, "clean_element", "clean_element", null);
        this.executeScripts();
        // If any status exist to
        if (typeof this.data.status != "undefined" && this.data.status.length > 0) {
            this.emitStatusEventHandler(e);
        }
        
    }

    emitStatusEventHandler(e) {
        if (e.value == true) {
            let activeData = this.data.status.find(val => val.text.toLowerCase() == "active")
            if (typeof activeData != "undefined" && activeData != null) {
                this.emitStatusEvent(activeData)
            }
        } else {
            let passiveData = this.data.status.find(val => val.text.toLowerCase() == "passive")
            if (typeof passiveData != "undefined" && passiveData != null) {
                this.emitStatusEvent(passiveData)
            }
        }
    }

    emitStatusEvent(e) {

        //let componentList = this.componentService.formComponentList;
        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        let componentList = this.componentService.getAllComponentList(currentFormSelected.components, this.anotherPopupId);

        // On Disable
        if (typeof e.disable != "undefined" && e.disable != "") {
            e.disable.split(",").forEach(f => {
                let _k = componentList.find(k => k.id == f.replace("{", "").replace("}", ""));
                if (typeof _k != "undefined") {
                    _k.outsideDisabled = true;
                    //let _sub = { key: _k.uuid, outsideDisabled: true };
                    //this.eventEmitterService.ChangeDisableAndVisible(_sub);
                }
            });
        }

        // On Enable
        if (typeof e.enable != "undefined" && e.enable != "") {////
            e.enable.split(",").forEach(f => {
                let _k = componentList.find(k => k.id == f.replace("{", "").replace("}", ""));
                if (typeof _k != "undefined") {
                    _k.outsideDisabled = false;
                    //let _sub = { key: _k.uuid, outsideDisabled: false };
                    //this.eventEmitterService.ChangeDisableAndVisible(_sub);
                }
            });
        }

        // On Visible
        if (typeof e.visible != "undefined" && e.visible != "") {//////
            e.visible.split(",").forEach(f => {
                f = f.replace("{", "").replace("}", "")
                let button = [] // Button name list
                if (f.includes(";")) {

                    let temp = f.split(";")[1]
                    if (temp.includes(":")) {
                        button = temp.split(":")
                    } else {
                        button.push(temp)
                    }
                    f = f.split(";")[0]
                }

                let _k = componentList.find(k => k.id == f.replace("{", "").replace("}", ""));
                if (typeof _k != "undefined") {
                    _k.outsideVisible = true;
                    let _sub = { key: _k.uuid, outsideVisible: true, hideButtonList: [] };
                    this.eventEmitterService.ChangeVisible(_sub);
                }
            });
        }

        // On Invisible
        if (typeof e.invisible != "undefined" && e.invisible != "") {
            
            e.invisible.split(",").forEach(f => {
                
                f = f.replace("{", "").replace("}", "")
                let button = [] // Button name list
                if (f.includes(";")) {

                    let temp = f.split(";")[1]
                    if (temp.includes(":")) {
                        button = temp.split(":")
                    } else {
                        button.push(temp)
                    }
                    f = f.split(";")[0]
                }

                let _k = componentList.find(k => k.id == f.replace("{", "").replace("}", ""));
                if (typeof _k != "undefined") {
                    _k.outsideVisible = false;
                    let _sub = { key: _k.uuid, outsideVisible: false };
                    if (button.length > 0) {
                        _sub.outsideVisible = true
                        _sub["hideButtonList"] = button
                    }

                    this.eventEmitterService.ChangeVisible(_sub);
                }
            });
        } 
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }

    // status : ('active' || 'passive' )
    onSelectCase(_value, _status) {
        this.showEditorPopup = true
        this.activeCase = _value;
        this.activeStatus = _status;
        this.tempMonacoText = this.status[_status][_value].value
    }

    monacoOnInit(e) {
        this.isMonacoLoaded = true;
        let monaco = window['monaco'];
        this.setCustomKeywords(monaco);
    }

    setCustomKeywords(monaco) {

        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        //console.log(this.componentService.getComponentListWithButton(currentFormSelected.components, this.anotherPopupId))
        let elements = this.componentService.getComponentListWithButton(currentFormSelected.components, this.anotherPopupId).map(m => m.id);

        if (this.registeredLanguage)
            this.registeredLanguage.dispose();

        this.registeredLanguage = monaco.languages.registerCompletionItemProvider('plaintext', {
            provideCompletionItems: () => {
                var suggestions = [];

                for (let i = 0; i < elements.length; i++) {
                    suggestions.push({
                        label: elements[i],
                        kind: monaco.languages.CompletionItemKind.Snippet,
                        insertText: "{" + elements[i] + "}",
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet
                    })
                }

                return { suggestions: suggestions };
            }
        });

    }

    saveMonaco(_value) {
        let _array: any[] = _value.split(",")
        //_array.forEach(element => { console.log(element) })

        this.status[this.activeStatus][this.activeCase].value = _value
        this.status[this.activeStatus][this.activeCase].count = _array.length

        this.showEditorPopup = false;
        this.activeCase = ""
        this.activeStatus = "";
    }

    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // T�m charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }

    exitMonaco() {
        this.tempMonacoText = ""
        this.showEditorPopup = false;
        this.activeCase = ""
        this.activeStatus = "";
    }

    openChangeEventPopup() {
        debugger;
        this.isMonacoLoaded = false;
        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        let elements = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId).map(m => m.id);
        this.formElements = elements.filter(f => f != this.data.id);
        this.sqlScriptModel = new SqlScriptCheckBoxModel();
        this.sqlScriptModel.query = "SELECT * FROM TABLE WHERE KEY='{" + this.data.id + "}'";
        this.showChangeEventPopup = true;
    }

    saveSqlScript() {
        let model = this.componentService.cloneModel(this.sqlScriptModel);

        if (model.id > 0) {
            let scr = this.data.sqlScripts.find(f => f.id == model.id);
            scr.connectionId = model.connectionId;
            scr.elementId = model.elementId;
            scr.query = model.query;
        }
        else {
            model.id = this.data.sqlScripts.length + 1;
            this.data.sqlScripts.push(model);
        }

        this.sqlScriptModel = new SqlScriptCheckBoxModel();
        this.sqlScriptModel.query = "SELECT * FROM TABLE WHERE KEY='{" + this.data.id + "}'";
    }

    updateSqlScript(i) {
        let scr = this.data.sqlScripts[i];
        this.sqlScriptModel = new SqlScriptCheckBoxModel();
        this.sqlScriptModel.id = scr.id;
        this.sqlScriptModel.connectionId = scr.connectionId;
        this.sqlScriptModel.elementId = scr.elementId;
        this.sqlScriptModel.query = scr.query;
    }

    deleteSqlScript(i) {
        this.data.sqlScripts.splice(i, 1);
    }

    executeScripts() {
        if (!this.isEdit) {
            this.data.sqlScripts.forEach(s => {

                let pref = "{" + this.data.id + "}";
                let value = this.data.value;
                let query = this.componentService.replaceAllUsingType(s.query, pref, value); // s.query.replace(pref, value);

                let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
                let currentComponents = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId)

                var pattern = /\{(\w)+\}/ig;
                var patt = new RegExp(pattern);
                if (patt.test(query)) {
                    let _match = query.match(pattern);
                    if (_match.length > 0) {
                        _match.forEach(f => {
                            let _k = currentComponents.find(k => k.id == f.replace("{", "").replace("}", ""));
                            if (_k) {
                                let _value = _k.value == null ? "" : _k.value;
                                query = this.componentService.replaceAllUsingType(query, f, _value);//query.replace(f, _value);
                            }
                        });
                    }
                    //query = this.genericService.cleanSqlSyntax(query.replace(pattern, ""))
                }

                this.genericService.executeQuerySecurited(query, s.connectionId).subscribe((result: any) => {

                    if (result) {
                        let element = this.componentService.getById(s.elementId, currentComponents, this.anotherPopupId);
                        if (element.type) {

                            if (element.component == "DxDatagridSelectComponent" || element.type == "datalist" || element.type == "selectbox" || element.type == "gallery" || element.type == "datagrid" || element.type == "multiselectbox") {
                                this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, this.data.value, result);
                            }
                            else {
                                let keys = Object.keys(result[0]);
                                if (keys.length > 0) {
                                    let finalData = [];
                                    keys.forEach(f => {
                                        finalData.push(result[0][f]);
                                    });
                                    let dataText = finalData.join(",");
                                    this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, this.data.value, dataText);
                                }
                            }
                        }
                    } else {
                        console.log("empty")
                    }
                });
            });
        }
    }

}
