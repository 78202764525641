import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { AppInfoService, TaskService } from '../../shared/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-videoupload',
    templateUrl: './videoupload.component.html',
    styleUrls: ['./videoupload.component.css']
})
export class DxVideouploadComponent implements ComponentParameters, OnInit {
    @ViewChild('videoPlayer', { static: false }) videoPlayer: ElementRef;
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;
    @Input() anotherPopupId: number = 0;
    
    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;

    showFilesPopup: boolean = false;
    showPreviewFilesPopup: boolean = false;
    previewFileSrc: any;

    uploadedFiles: any = [];
    uploadResult = { isShow: false, status: "success", message: "" };
    totalSize: any = 0;

    showDisplay: any = "flex";
    invokeWarningChangeEventLocal: Subscription;
    languageLocal: any = "";

    borderColor = "#dedede"

    constructor(public taskService: TaskService, public appInfo: AppInfoService,private eventEmitterService: EventEmitterService, public componentService: ComponentService, public changeDetectorRef: ChangeDetectorRef) {

        this.languageLocal = this.componentService.getLanguageSource(); //language

        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });

        this.invokeWarningChangeEventLocal = this.eventEmitterService.invokeWarningChangeEvent.subscribe(sub => {
            if (!!sub.data && !!sub.data.requiredFiles) {
                if (sub.data.requiredFiles.includes(this.data.id)) {
                    this.changeToWarningColor()
                }
            }
        });
    }

    deleteButtonVisible = false;
    ngOnInit() {
        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
         
        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                 
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });


        if (typeof this.data.valueTemp == "undefined")
            this.data.valueTemp = [];

        if (this.componentService.currentProcessFormId != 0) {
            this.taskService.GetProcessStarterId(this.componentService.currentProcessFormId).subscribe((r: any) => {
                if (r == this.appInfo.currentUser.id)
                    this.deleteButtonVisible = true;
            }, error => {
            });
        }
        else
            this.deleteButtonVisible = true;

    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    onValueChanged = (e: any): void => {

        let errors = [];
        if (e.value.length) {
            this.changeToDefaultColor();
            //this.data.valu e = [];
            this.totalSize = 0;
            this.uploadResult.isShow = true;
            this.uploadResult.status = "primary";
            this.uploadResult.message = this.languageLocal.filesLoading;
            for (let i = 0; i < e.value.length; i++) {
              
                let checkSum = this.totalSize + e.value[i].size;
                if (checkSum > (this.data.maxFileSize * 1024 * 1024)) {
                    let msg = e.value[i].name + " (" + Math.round(checkSum / 1024) + this.languageLocal.couldnotbeLoaded;
                    errors.push(msg);
                    this.uploadResult.status = "danger";
                    this.uploadResult.message = this.languageLocal.fileSizeExceeded + errors.join(" ") + (this.languageLocal.fileSizeLimit + this.data.maxFileSize * 1024 + " KB )");
                    continue;
                }

                this.getDocument(e.value[i]).then(document => {
                    this.data.valueTemp.push(document);
                    this.totalSize += e.value[i].size;
                    if (i === (e.value.length - 1)) {
                        if (errors.length) {
                            this.uploadResult.status = "danger";
                            this.uploadResult.message = this.languageLocal.fileSizeExceeded + errors.join(" ") + (this.languageLocal.fileSizeLimit + this.data.maxFileSize * 1024 + " KB )");
                        }
                        else {
                            this.uploadResult.status = "success";
                            this.uploadResult.message = this.languageLocal.filesUploaded;
                        }
                    }
                    try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                });

            }
        }
    }

    getDocument(file) {
        return new Promise(resolve => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (args: any) => {
                if (reader.result) {
                    let fileName = file.name;
                    let fileType: string = file.type;
                    let base64BlobData: any = args.target.result.split(',').pop();
                    let fileExtension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2);
                    let fileSize = file.size;
                    let document: any = {
                        base64BlobData: base64BlobData,
                        name: fileName,
                        fileType: fileType,
                        extension: fileExtension,
                        size: fileSize,
                        isImg: false
                    };
                    resolve(document);

                }
            }

        });
    }

    showFiles() {
        for (let i = 0; i < this.data.valueTemp.length; i++) {
            
            this.data.valueTemp[i].src = 'data:' + this.data.valueTemp[i].fileType + ';base64,' + this.data.valueTemp[i].base64BlobData;
        }
        this.showFilesPopup = true;
    }

    deleteFiles() {
        let result = confirm("<i>" + this.languageLocal.filesSureDelete + "</i>", this.languageLocal.filesWillDeleted);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.uploadedFiles = [];
                for (let i = 0; i < this.data.valueTemp.length; i++) {
                    this.eventEmitterService.setDeletedFileIds(this.data.valueTemp[i].id);
                } 
                this.data.valueTemp = [];
                this.data.value = [];
                this.uploadResult.isShow = false;
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });
    }

    deleteFile(i) {
        let result = confirm("<i>" + this.languageLocal.fileDelete + "</i>", this.languageLocal.filesWillDeleted);
        result.then((dialogResult) => {
            if (dialogResult) {
                if (this.data.valueTemp && this.data.valueTemp.length > 0 && this.data.valueTemp[i])
                    this.eventEmitterService.setDeletedFileIds(this.data.valueTemp[i].id);
                this.data.valueTemp.splice(i, 1);
                this.data.value.splice(i, 1);
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                this.showFiles();
            }
        });

    }

    previewFile(src) {
        this.previewFileSrc = src;
        this.showPreviewFilesPopup = true;
    }

    changeToWarningColor() {
        if (this.data.required) {
            this.borderColor = "#d9534f"
        }

        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // T�m charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    changeToDefaultColor() {
        this.borderColor = "#dedede"

        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }

}
