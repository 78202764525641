import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AppInfoService, EventEmitterService, GenericService } from '../../shared/services';
import { ComponentService } from '../component.service';

import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';
import { DxDiagramComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';

@Component({
    selector: 'diagram-component',
    templateUrl: './diagram.component.html',
    styleUrls: ['./diagram.component.css']
})
export class DxEtrDiagramComponent implements OnInit, AfterViewInit {
    @ViewChild("diagramEtr", { static: false }) diagram: DxDiagramComponent;
    @Input() data: any;
    @Input() isEdit: any;
    @Input() lang: string;
    @Input() anotherPopupId: number = 0;

    invokeButtonClickChangeEventLocal: Subscription;
    languageLocal: any = "";

    constructor(public appInfo: AppInfoService,
        private eventEmitterService: EventEmitterService,
        private componentService: ComponentService,
        private genericService: GenericService,
        public changeDetectorRef: ChangeDetectorRef) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });

        this.invokeButtonClickChangeEventLocal = this.eventEmitterService.invokeButtonClick.subscribe(sub => {
            this.saveChanges();
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        });
        this.languageLocal = this.componentService.getLanguageSource(); //language

    }

    diagramData = {
        "page": {
            "width": 8391,
            "height": 11907,
            "pageWidth": 8391,
            "pageHeight": 11906,
            "pageLandscape": false,
            "gridSize": 180,
            "snapToGrid": true
        },
        "connectors": [{
            "key": "116",
            "points": [{
                "x": 2160,
                "y": 1800
            }, {
                "x": 2160,
                "y": 2340
            }
            ],
            "zIndex": 0,
            "beginItemKey": "107",
            "beginConnectionPointIndex": 2,
            "endItemKey": "108",
            "endConnectionPointIndex": 0
        }, {
            "key": "117",
            "points": [{
                "x": 2160,
                "y": 3420
            }, {
                "x": 2160,
                "y": 3960
            }
            ],
            "zIndex": 0,
            "beginItemKey": "108",
            "beginConnectionPointIndex": 2,
            "endItemKey": "118",
            "endConnectionPointIndex": 0
        }, {
            "key": "122",
            "points": [{
                "x": 3420,
                "y": 4680
            }, {
                "x": 5760,
                "y": 4680
            }
            ],
            "zIndex": 0,
            "texts": {
                "0.45": "No"
            },
            "beginItemKey": "118",
            "beginConnectionPointIndex": 1,
            "endItemKey": "121",
            "endConnectionPointIndex": 3
        }, {
            "key": "123",
            "points": [{
                "x": 6840,
                "y": 4140
            }, {
                "x": 3420,
                "y": 2880
            }
            ],
            "zIndex": 0,
            "beginItemKey": "121",
            "beginConnectionPointIndex": 0,
            "endItemKey": "108",
            "endConnectionPointIndex": 1
        }, {
            "key": "126",
            "points": [{
                "x": 3780,
                "y": 9180
            }, {
                "x": 2880,
                "y": 10440
            }
            ],
            "zIndex": 0,
            "texts": {
                "0.78": "A popular scenario?"
            },
            "beginItemKey": "120",
            "beginConnectionPointIndex": 3,
            "endItemKey": "125",
            "endConnectionPointIndex": 0
        }, {
            "key": "130",
            "points": [{
                "x": 5220,
                "y": 9180
            }, {
                "x": 6120,
                "y": 10440
            }
            ],
            "zIndex": 0,
            "texts": {
                "0.77": "Missing info in\nthe documentation?"
            },
            "beginItemKey": "120",
            "beginConnectionPointIndex": 1,
            "endItemKey": "127",
            "endConnectionPointIndex": 0
        }, {
            "key": "132",
            "points": [{
                "x": 2160,
                "y": 5400
            }, {
                "x": 2160,
                "y": 6120
            }
            ],
            "zIndex": 0,
            "texts": {
                "0.47": "Yes"
            },
            "beginItemKey": "118",
            "beginConnectionPointIndex": 2,
            "endItemKey": "131",
            "endConnectionPointIndex": 0
        }, {
            "key": "124",
            "points": [{
                "x": 2160,
                "y": 7200
            }, {
                "x": 4500,
                "y": 8820
            }
            ],
            "zIndex": 0,
            "beginItemKey": "131",
            "beginConnectionPointIndex": 2,
            "endItemKey": "120",
            "endConnectionPointIndex": 0
        }, {
            "key": "134",
            "points": [{
                "x": 3420,
                "y": 6660
            }, {
                "x": 5760,
                "y": 6660
            }
            ],
            "zIndex": 0,
            "texts": {
                "0.5": "Need developer\nassistance?"
            },
            "beginItemKey": "131",
            "beginConnectionPointIndex": 1,
            "endItemKey": "133",
            "endConnectionPointIndex": 3
        }, {
            "key": "135",
            "points": [{
                "x": 6840,
                "y": 7200
            }, {
                "x": 4500,
                "y": 8820
            }
            ],
            "zIndex": 0,
            "beginItemKey": "133",
            "beginConnectionPointIndex": 2,
            "endItemKey": "120",
            "endConnectionPointIndex": 0
        }
        ],
        "shapes": [
            {
                "key": "107",
                "type": "terminator",
                "text": "A new ticket",
                "x": 1440,
                "y": 1080,
                "width": 1440,
                "height": 720,
                "zIndex": 0
            },
            {
                "key": "108",
                "type": "process",
                "text": "Analyze\nthe issue",
                "x": 900,
                "y": 2340,
                "width": 2520,
                "height": 1080,
                "zIndex": 0
            },
            {
                "key": "118",
                "type": "decision",
                "text": "Do we have all\ninformation to\nwork with?",
                "x": 900,
                "y": 3960,
                "width": 2520,
                "height": 1440,
                "zIndex": 0
            },
            {
                "key": "120",
                "type": "terminator",
                "text": "Answered",
                "x": 3780,
                "y": 8640,
                "width": 1440,
                "height": 720,
                "zIndex": 0
            },
            {
                "key": "121",
                "type": "process",
                "text": "Request additional\ninformation or clarify\nthe scenario",
                "x": 5760,
                "y": 4140,
                "width": 2160,
                "height": 1080,
                "zIndex": 0
            },
            {
                "key": "125",
                "type": "process",
                "text": "Prepare an example in\nCode Central",
                "x": 1620,
                "y": 10440,
                "width": 2520,
                "height": 1080,
                "zIndex": 0
            },
            {
                "key": "127",
                "type": "process",
                "text": "Update the\ndocumentation",
                "x": 4860,
                "y": 10440,
                "width": 2520,
                "height": 1080,
                "zIndex": 0
            },
            {
                "key": "131",
                "type": "process",
                "text": "Process the\nticket",
                "x": 900,
                "y": 6120,
                "width": 2520,
                "height": 1080,
                "zIndex": 0
            },
            {
                "key": "133",
                "type": "process",
                "text": "Work with the\nR&D team",
                "x": 5760,
                "y": 6120,
                "width": 2160,
                "height": 1080,
                "zIndex": 0
            },
            {
                "key": "136",
                "type": "text",
                "locked": true,
                "text": "Ticket Processing Flow",
                "x": 360,
                "y": 360,
                "width": 7740,
                "height": 360,
                "zIndex": 0,
                "styleText": {
                    "font-size": "14pt",
                    "font-weight": "bold"
                }
            }
        ]
    }
    height = 500
    defaultLanguage: any = {};
    connections: any = [];

    dataSources: any;
    dataSourcePopup: boolean = false;
    isFromDataSource: boolean;
    showSettingPopup: boolean = false;
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showChamgeEventPopup: boolean = false;
    isMonacoLoaded: boolean = false;
    showDisplay: any = "flex";
    designerDataSourceList: any;
    designerDatasourceModel: any;
    users = []


    ngOnInit() {

        this.users = this.appInfo.RiverEntity.users

        if (!this.isEdit) {
            this.executeScript();
        }
        else {
            this.fillDesignerDataSource();
        }

        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);
        this.connections = this.appInfo.RiverEntity.connections;
        this.height = typeof this.data.height == "undefined" ? 500 : this.data.height;

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });

        if (!this.isEdit) {
            this.data.customShapes.forEach(el => {
                debugger;
                this.genericService.executeQuerySecurited(el.Sql, el.connectionId).subscribe(data => {
                    el.result = data
                    this.changeDetectorRef.detectChanges()
                })

            })
        }
        
       
    }

    ngAfterViewInit() {

        this.data.customShapes.forEach(el => {
            this.diagram.toolbox.groups.push({ title: el.name, category: el.name, displayMode: 'texts' })
            this.diagram.customShapes.push()
        })
        setTimeout(el => {

            if (this.data.value) {
                console.log(this.data.value)
                this.diagram.instance.import(this.data.value)
            }

            console.log(this.diagram)

            this.diagram.instance._refresh()

            this.changeDetectorRef.detectChanges()

        }, 100)
        
    }

    saveChanges() {
        var exportData = this.diagram.instance.export();
        this.data.value = exportData;
    }

    activeShapes: any = { id: 0, text: "", name: "", columnName: "", connectionId : 0 ,Sql: "" }

    onEditShape(data) {
        this.activeShapes = data
    }

    saveCustomShape(val) {
        debugger;
        if (this.data.customShapes.length == 0) {
            this.activeShapes.id = 1;
            this.data.customShapes.push(val)
        } else {
            if (val.id == 0) {
                const lastItem = this.data.customShapes[this.data.customShapes.length - 1]
                this.activeShapes.id = lastItem.id + 1;
                this.data.customShapes.push(this.activeShapes)
            } else {
                let index = this.data.customShapes.findIndex(el => el.id == val.id)
                this.data.customShapes[index] = val
            }
        }
        this.activeShapes = { id: 0, text: "", name: "", columnName: "", connectionId: 0, Sql: ""  }

    }

    onCustomCommand(e) {
        if (e.name === 'clear') {
            const result = confirm(this.languageLocal.diagramClear, 'Warning');
            result.then(
                (dialogResult) => {
                    if (dialogResult) {
                        e.component.import('');
                    }
                },
            );
        }
    }

    currentFormSelectedGlobal;
    public genericServiceExecuteScript(_Query, _ConnectionId) {
        debugger;
        this.currentFormSelectedGlobal = this.componentService.currentFormSelected(this.anotherPopupId);
        let curentComponents = this.componentService.getComponentList(this.currentFormSelectedGlobal.components, this.anotherPopupId);
        var pattern = /\{(\w)+\}/ig;
        var patt = new RegExp(pattern);
        if (patt.test(_Query)) {
            let _match = _Query.match(pattern);
            if (_match.length > 0) {
                _match.forEach(f => {
                    let _k = curentComponents.find(k => k.id == f.replace("{", "").replace("}", ""));
                    if (_k) {
                        let _value = _k.value == null ? "" : _k.value;
                        _Query = _Query.replace(f, _value);
                    }
                });
            }
            //query = this.genericService.cleanSqlSyntax(query.replace(pattern, ""))
        }

        this.genericService.executeQuerySecurited(_Query, _ConnectionId).subscribe((result: any) => {
            debugger;
            if (result && result.length > 0) {
                let cols = Object.keys(result[0]);
                if (cols) {

                }
                this.data.dataSource = result;
                this.tasks = result;

                this.tasks.forEach(el => {
                    let user: any = this.users.filter((e: any) => e.id.toString() === el[this.data.assignedColumnName])
                    if (!!user[0] && !!user[0].fullName)
                        el[this.data.assignedColumnName] = user[0].fullName
                    else
                        el[this.data.assignedColumnName] = ""
                })

                this.genericService.executeQuerySecurited(this.data.groupSql, this.data.dataStorage.connectionId).subscribe((res: any) => {
                    debugger;
                    if (res && res.length > 0) {
                        res.forEach(el => {
                            this.statuses.push(el[this.data.groupColumnName])
                        })
                        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }

                        this.statuses.forEach((status) => {
                            this.lists.push(this.tasks.filter((task) => task[this.data.groupColumnName] === status));
                        });


                    }
                    else {
                        this.data.dataSource = [];
                    }

                    try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                });


                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
            else {
                this.data.dataSource = [];
            }
        });

    }

    public executeScript() {
        debugger;
        if (this.data.dataStorage.connectionId > 0 && this.data.dataStorage.query != "") {
            this.genericServiceExecuteScript(this.data.dataStorage.query, this.data.dataStorage.connectionId);
        }
        else if (this.data.dataSource && this.data.dataSource.length > 0) {

            let filteredData = this.data.dataSource.filter(f => f.lang == this.lang);
            if (filteredData && filteredData.length > 0)
                this.data.dataSource = filteredData;

        }
        else if (this.data.desktopDatasource != null) {

            this.fillDesignerDataSource();

        }
    }

    selecboxComponents: any = [];

    openDataPopup(type) {
        this.isFromDataSource = type == "1";
        if (this.isFromDataSource) {
            this.data.isManual = false;
            let selectboxes = this.componentService.getComponentsByType("selectbox", this.anotherPopupId);
            this.selecboxComponents = [];
            if (selectboxes) {
                selectboxes.forEach(item => {
                    if (item.id != this.data.id) {
                        let element: any = {};
                        element.id = item.id;
                        element.name = this.componentService.getLang(this.lang, item.language).Label;
                        this.selecboxComponents.push(element);
                    }
                });
            }
        }
        else {
            this.data.isManual = true;
        }

        this.dataSourcePopup = true;
    }

    saveSetting() {

        this.data.desktopDatasource = this.designerDatasourceModel.ID;

        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);

        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    onChangeDatasourceValue(event) {
        if (event.value) {

            this.designerDatasourceModel = this.designerDataSourceList.find(x => x.ID == event.value);

        }
    }

    saveDataSource() {
        if (this.data.isManual && this.data.dataStorage.connectionId > 0 && this.data.dataStorage.query != "") {
            let msg = "<i>" + this.languageLocal.messageSqlLinkDeleted + "</i>"
            let result = confirm(msg, this.languageLocal.warning);
            result.then((dialogResult) => {

                if (dialogResult) {
                    this.data.dataStorage.connectionId = 0;
                    this.data.dataStorage.query = "";
                    this.dataSourcePopup = false;
                    this.data.valueExpr = "value";
                    //this.data.displayExpr = "text";
                }
            });
        }
        else {

            if (this.data.isManual) {
                this.data.valueExpr = "value";
                //this.data.displayExpr = "text";
            }

            this.dataSourcePopup = false;
        }
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    fillDesignerDataSource() {
        this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };

        this.genericService.fillDesignerDataSource().subscribe((result: any) => {

            this.designerDataSourceList = result;
            this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };
            if (this.data.desktopDatasource != null) {
                this.designerDatasourceModel = this.designerDataSourceList.find(x => x.ID == this.data.desktopDatasource);

                if (typeof this.designerDatasourceModel.ID === "undefined")
                    this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };
            }


        });

    }

    tasks = []


    lists: any[] = [];

    statuses: string[] = [];

    onListReorder(e) {
        const list = this.lists.splice(e.fromIndex, 1)[0];
        this.lists.splice(e.toIndex, 0, list);

        const status = this.statuses.splice(e.fromIndex, 1)[0];
        this.statuses.splice(e.toIndex, 0, status);
    }

    onTaskDragStart(e) {
        e.itemData = e.fromData[e.fromIndex];
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // Tüm charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    onTaskDrop(e) {
        e.fromData.splice(e.fromIndex, 1);
        e.toData.splice(e.toIndex, 0, e.itemData);
    }
   
}
