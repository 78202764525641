import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { Subscription } from 'rxjs';
import { InstanceService } from '../../shared/services/data/instance.service';
import notify from "devextreme/ui/notify";
import { AppInfoService, TaskService } from '../../shared/services';
@Component({
    selector: 'childform-component',
    templateUrl: 'childform.component.html',
    styleUrls: ['./childform.component.css']
})
export class DxChildFormComponent implements ComponentParameters, OnInit, OnDestroy {
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;
    @Input() anotherPopupId: number = 0;

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;

    showDisplay: any = "flex";

    pageComponentList: any = [];

    processList: any = [];

    invokeChangeEventLocal: Subscription;
    languageLocal: any = "";

    // processFormIdGlobal: any = 0;

    constructor(private eventEmitterService: EventEmitterService,
        public changeDetectorRef: ChangeDetectorRef,
        public componentService: ComponentService,
        public appInfo: AppInfoService,
        public taskService: TaskService,
        public instanceService: InstanceService) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
        this.languageLocal = this.componentService.getLanguageSource(); //language

        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {

            if (sub.elementId == this.data.id) {
                if (sub.data) this.data.value = sub.data;
                else this.data.value = "";

                try {
                    this.changeDetectorRef.detectChanges();
                } catch (ex) { }
            }
        });

    }
    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
    }

    allButtonIcons = [];
    changedValueProcess = "";
    ngOnInit() {
        this.getSchemaList();

        this.data.isFormSaveAndClose = typeof this.data.isFormSaveAndClose === "undefined" ? false : this.data.isFormSaveAndClose;

        this.data.processId = typeof this.data.processId === "undefined" ? 0 : this.data.processId;

        this.allButtonIcons = this.componentService.getAllIcons();

        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (typeof this.data.show === "undefined")
            this.data.show = true;
         
        if (typeof this.data.property === "undefined") {
            this.data.property = { icon: "", width: "", height: "", fontSize: "", backgroundColor: '#ffffff', fontColor: '#000000' };
        }
        this.setPageStyle(this.data.property.fontColor, this.data.property.backgroundColor);

        if (typeof this.data.property.backgroundColor === "undefined" || typeof this.data.property.fontColor === "undefined") {
            if (typeof this.data.property.backgroundColor === "undefined")
                this.data.property.backgroundColor = '#ffffff';
            if (typeof this.data.property.fontColor === "undefined")
                this.data.property.fontColor = '#000000';
        }

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";

                try {
                    this.changeDetectorRef.detectChanges();
                } catch (ex) { }
            }
        });
        this.changedValueProcess = this.data.processId;
        // this.onProcessChanged({ value: this.data.processId });
    }

    setPageStyle(_color, _backgroundColor) {
        try {
            let _el = document.getElementById("styleDynamic_" + this.data.id);
            _el.remove();
        } catch (ex) { }
        //85
        let styles = ` 
#`+ this.data.id + ` { 
  color: `+ _color + `;
  background-color: `+ _backgroundColor + `;
}  

#`+ this.data.id + `.dx-state-hover {  
  background-color:  `+ _backgroundColor + `c2;  
  color:  `+ _color + `;  
}  

#`+ this.data.id + `.dx-state-active {  
  background-color:  `+ _backgroundColor + `c2;  
  color:  `+ _color + `;  
}  `;
        let styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = styles;
        styleSheet.id = "styleDynamic_" + this.data.id;
        styleSheet.className = "style-dynamic";
        document.head.appendChild(styleSheet);
    }

    //firstLoad = true;
    selectedFormComponentList = [];
    onProcessChanged(event) {
        if (event.value) {

            // if (!this.firstLoad) {
            if (this.data.processId != this.changedValueProcess) {
                this.data.addedComponents = [];
                this.data.addedComponents.push({ pageComponent: "-1", selectedFormComponent: "-1" });
            }
            this.changedValueProcess = this.data.processId;
            //  this.firstLoad = false;
            // }
            this.selectedFormComponentList = [];
            if (this.data.processId) {
                this.instanceService.getSchemaForm(this.data.processId, false).subscribe(_form => {
                    let _formObj: any;
                    _formObj = _form;
                    let formJson = JSON.parse(_formObj.formJson);
                    this.selectedFormComponentList = this.componentService.getComponentList(formJson.components, this.anotherPopupId);
                });
            }

        }
    }

    onComponentList(event) {
        this.data.addedComponents = typeof this.data.addedComponents == "undefined" ? [] : this.data.addedComponents;

        let addedComponentsEmptyOrNull = this.data.addedComponents.filter(x =>
            (x.pageComponent == "-1" || x.pageComponent == "" || x.pageComponent == null) &&
            (x.selectedFormComponent == "-1" || x.selectedFormComponent == "" || x.selectedFormComponent == null)
        );
        if (addedComponentsEmptyOrNull.length > 1) {
            for (let i = 0; i < this.data.addedComponents.length; i++) {
                if (this.data.addedComponents[i].pageComponent == "-1" || this.data.addedComponents[i].pageComponent == "" || this.data.addedComponents[i].pageComponent == null) {
                    if (this.data.addedComponents[i].selectedFormComponent == "-1" || this.data.addedComponents[i].selectedFormComponent == "" || this.data.addedComponents[i].selectedFormComponent == null) {
                        this.data.addedComponents.splice(i, 1);
                    }
                }
            }
        }

        let addedComponentsEmpty = this.data.addedComponents.find(x => x.pageComponent == "-1" && x.selectedFormComponent == "-1");
        if (typeof addedComponentsEmpty == "undefined") {
            this.data.addedComponents.push({ pageComponent: "-1", selectedFormComponent: "-1" });
        }

        //let addedComponentsEmptyArray = this.data.addedComponents.filter(x => x.pageComponent == "-1" && x.selectedFormComponent == "-1");
        //if (addedComponentsEmptyArray.length > 1) {
        //    this.data.addedComponents.push({ pageComponent: "-1", selectedFormComponent: "-1" });
        //}
        //if (event.value) {
        //    
        //    this.data.addedComponents = typeof this.data.addedComponents == "undefined" ? [] : this.data.addedComponents;
        //    //this.data.addedComponents.forEach(item => {
        //    //    if ((item.pageComponent == "" || item.pageComponent == 0) && (item.selectedFormComponent == "" || item.selectedFormComponent == 0))

        //    //});

        //    let addedComponentsEmpty = this.data.addedComponents.filter(x => x.pageComponent != "-1" || x.selectedFormComponent != "-1");
        //    this.data.addedComponents = [];
        //    this.data.addedComponents = addedComponentsEmpty;

        //    this.data.addedComponents.push({ pageComponent: "-1", selectedFormComponent: "-1" });
        //}
        //else { 
        //    let addedComponentsEmpty = this.data.addedComponents.find(x => x.pageComponent == "-1" && x.selectedFormComponent == "-1");
        //    if (typeof addedComponentsEmpty == "undefined") { 
        //        this.data.addedComponents.push({ pageComponent: "-1", selectedFormComponent: "-1" });
        //    }
        //}
    }

    async getSchemaList() {

        await this.taskService.getJustSchemeList().subscribe(result => {
            this.processList = result;
            //resultSchema.forEach(item => {

            //    if (!item.isScheme) {
            //        this.processList(item.items);
            //    }
            //    else {
            //        this.processList.push(item);
            //    }
            //});
        });
    }
    openChildForm(args) {
        if (!args.validationGroup.validate().isValid) {
            this.componentService.showAlert(this.languageLocal.alertFillRequiredFields, "error", 5000)
            return;
        }

        if (typeof this.data.isFormSaveAndClose == "undefined")
            this.data.isFormSaveAndClose = false;

        let _data = {
            processId: this.data.processId,
            isFormSaveAndClose: this.data.isFormSaveAndClose,
            addedComponents: this.data.addedComponents
        }

        this.eventEmitterService.openChildForm(_data);

        //let nameRoom = componentLst.find(x => x.id == this.data.meetingRoomName);
        //let startDateRoom = componentLst.find(x => x.id == this.data.startDate);
        //let localProcessFormId = this.componentService.currentProcessFormId;
        //this.instanceService.liveOpen(nameRoom.value, startDateRoom.value, this.data.hostUrl, this.data.participantUrl, this.data.meetingType, localProcessFormId).subscribe((s: any) => {
        //    if (s.success) {
        //        if ( localProcessFormId == 0) {
        //            this.data.value = JSON.stringify({ participantUrl: s.participantUrl, hostUrl: s.hostUrl });
        //            this.showToast(s.message, "success");
        //        }
        //        else {

        //            let _domain = window.location.protocol + "//" + window.location.host + "/" + s.liveUrl.replace("{{USERNAME}}", this.appInfo.currentUser.name);
        //            window.open(_domain, "_blank");
        //            this.showToast(s.message, "success");
        //             this.eventEmitterService.updateTasks();
        //        }
        //    }
        //    else { 
        //        this.showToast(s.message, "error");
        //    }
        //});
    }

    showToast(message, status) {
        notify(message, status, 3000);
    }

    onEditPopopShown() {

        this.pageComponentList = this.componentService.getComponentList(this.componentService.currentForm.components, this.anotherPopupId);

        if (typeof this.data.addedComponents == "undefined") {
            this.data.addedComponents = [];
        }

        let addedComponentsEmpty = this.data.addedComponents.find(x => x.pageComponent == "-1" && x.selectedFormComponent == "-1");
        if (typeof addedComponentsEmpty == "undefined") {
            this.data.addedComponents.push({ pageComponent: "-1", selectedFormComponent: "-1" });
        }

        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    getFormComponent() {

    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);

        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);

            }
        });
    }

    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // T�m charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }

    buttonIconPopoverOpen = false;
    hideEmojiPopoper(e) {
        //this.chatMessageInputStartPos = null;
        //this.chatMessageInputEndPos = null;
        //let myField = this.chatMessageInput.nativeElement;
        //if (!(this.chatMessageInputStartPos)) {
        //    myField.selectionStart = this.chatMessageInputStartPos;
        //    myField.selectionEnd = this.chatMessageInputEndPos;
        //}
        //myField.focus();
    }

    toggleIconPanel() {
        debugger;
        this.buttonIconPopoverOpen = !this.buttonIconPopoverOpen;
    }
    setButtonIcon(_icon) {
        debugger;
        if (_icon && _icon != "") {
            this.data.property.icon = _icon.icon;
        }
        else {
            this.data.property.icon = '';
        }
        this.buttonIconPopoverOpen = !this.buttonIconPopoverOpen;
        //let myField = this.chatMessageInput.nativeElement;
        //if (!(this.chatMessageInputStartPos)) {
        //    this.chatMessageInputStartPos = myField.selectionStart;
        //    this.chatMessageInputEndPos = myField.selectionEnd;
        //}
        //let myValueClear = this.insertAtCursor(_icon);
        //this.chatMessageText = myValueClear;
    }
    getButtonIconClass(_type) {
        if (typeof _type == 'undefined')
            return "icon-setting-button-delete";

        return typeof this.data.property.icon == 'undefined' || this.data.property.icon == '' ? 'icon-setting-button-empty' : 'icon-setting-button';
    }

    fontColorValueChange(_e) {
        let newValue = _e.value;
        if (this.data.property) {
            this.data.property.fontColor = newValue;
        }
        else {
            this.data.property = { icon: "", width: "", height: "", fontSize: "", backgroundColor: '#ffffff', fontColor: '#000000' };
        }
        this.setPageStyle(this.data.property.fontColor, this.data.property.backgroundColor);
    }

    backgroundValueChange(_e) {
        let newValue = _e.value;
        if (this.data.property) {
            this.data.property.backgroundColor = newValue;
        }
        else {
            this.data.property = { icon: "", width: "", height: "", fontSize: "", backgroundColor: '#ffffff', fontColor: '#000000' };
        }
        this.setPageStyle(this.data.property.fontColor, this.data.property.backgroundColor);
    }

    getButtonGeneralCss() {
        let _myStyle = {};
        if (this.data.property.height)
            _myStyle["height"] = this.data.property.height + (this.componentService.isInteger(this.data.property.height) ? "px" : "");
        if (this.data.property.width)
            _myStyle["width"] = this.data.property.width + (this.componentService.isInteger(this.data.property.width) ? "px" : "");

        return _myStyle;
    }

    getButtonGeneralCssText(type) {
        //    font-size: 103px;
        //width: 103px;
        //height: 18px;
        /*
        14   18
        20    x
         20*18/14
         */
        let _myStyle = {};
        if (this.data.property.fontSize) {
            if (type == "i")
                _myStyle["font-size"] = this.data.property.fontSize + (this.componentService.isInteger(this.data.property.fontSize) ? "px" : "");
            else
                _myStyle["font-size"] = this.componentService.isInteger(this.data.property.fontSize) ? ((this.data.property.fontSize - 3) + "px") : ("calc(" + this.data.property.fontSize + " - 3px)");


            //this.data.property.fontSize + this.componentService.isInteger(this.data.property.fontSize) ? "px" : "";

            _myStyle["width"] = this.data.property.fontSize + (this.componentService.isInteger(this.data.property.fontSize) ? "px" : "");
        } else {
            if (type == "i")
                _myStyle["font-size"] = "17px";
            else
                _myStyle["font-size"] = "14px";

            _myStyle["width"] = "18px";
        }
        _myStyle["height"] = "18px";

        return _myStyle;
    }


}
