import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewChecked, Input, ElementRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { ComponentService } from "../component.service";
import { CdkDragDrop, CdkDragStart, CdkDragMove, CdkDragEnd, moveItemInArray, copyArrayItem, transferArrayItem } from '@angular/cdk/drag-drop';

import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';

import { FormModel } from '../../shared/models/form-model';
import { AuthService, EventEmitterService, AppInfoService, FormService, TaskService, GenericService, GlobalService } from '../../shared/services';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDrawerComponent } from 'devextreme-angular';
import { environment } from '../../../environments/environment';
import { MailService } from '../../shared/services/data/mail.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'dx-builder',
    templateUrl: './formbuilder.html',
    styleUrls: ['./formbuilder.css'],
    preserveWhitespaces: true
})

export class FormBuilder implements OnInit, AfterViewChecked {
    currentApplicationVersion = environment.appVersion;
    //@ViewChild("HadiLan", { read: ElementRef, static: false }) child: ElementRef;
    @ViewChild(DxDrawerComponent, { static: false }) drawer: DxDrawerComponent;
    @Input() lang: any;
    // languageCode: any;
    componentList: any[];
    history: any = [];
    currentIndex: number = 0;

    showSavePopup: boolean = false;

    renderData: any = [];
    isRender: boolean = false;
    languageLocal: any = "";
    selectedFormLanguage: any;
    formList: any;

    // liveWebButton: any;

    keyPress(event: any) {

        const pattern = /[A-Za-z0-9_]/;
        const inputChar = String.fromCharCode((event as KeyboardEvent).charCode);
        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }
    onDragClass: string = "";

    actions = [
        {
            icon: "edit",
            alignment: "left",
            hint: "Edit",
            action: "edit"
        },
        {
            icon: "trash",
            alignment: "right",
            hint: "Delete",
            action: "delete"
        },
    ];

    formColor: any = "#ffffff";
    formBackground = null;
    formBackgroundExtension = null;

    isEdit: boolean = false;
    currentField: any = {};
    toolbarContent = [];

    category = { parent: 0, sub: 0, isGeneric: true };
    categoryList: any = [];
    subCategoryList: any = [];
    connections: any[] = []
    languagePopup: boolean = false;

    constructor(private route: ActivatedRoute,
        private appInfo: AppInfoService,
        private authService: AuthService,
        private componentService: ComponentService,
        public formService: FormService,
        public taskService: TaskService,
        private eventEmitterService: EventEmitterService,
        private http: HttpClient,
        public changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private genericService: GenericService,
        private globalService: GlobalService,
        public _sanitizationService: DomSanitizer,
        private mailService: MailService) {
        this.authService.isFormBuilder = true;
        this.route.queryParams.subscribe(params => {
            let localLang = "TRTR"
            console.log(params)
            if (typeof params.languageCode != "undefined" && params.languageCode != "") {
                localLang = params.languageCode
            }
            this.genericService.getlogininit(localLang, "dictionary").subscribe(data => {
                this.componentService.setLanguageSource(data.languages.langSource)
                this.languageLocal = this.componentService.getLanguageSource(); //language
                //this.componentService.setLanguageSource(data)
            })

            this.lang = localLang;
            this.checkUser(params.username, params.password);
        });
        debugger;
        this.componentService.createCdkList(0);

        // console.log(this.languageLocal);
        this.setToolbarContext();

    }

    getDbName() {
        if (!!this.connections && this.connections.length > 0) {
            let test = this.connections.find(value => value.isMainConn == "True")
            return test.databaseName
        }
        return ""
    }


    prefGlobal = { formOpenedEvents: [], formOpenedMultiElementEvents: [] };
    formOpenedEvent: { connectionId: 0, elementId: "", query: "" };
    formOpenedMultiElementEvent: { connectionId: 0, query: "" };
    formElements = [];
    showPrefPopup = false;
    isMonacoLoaded = false;
    updateIndex = -1;
    multpileElementUpdateIndex = -1;
    getPreferences() {//preferences
        debugger;
        this.clearPrefPopup();
        this.formElements = this.componentService.getComponentList(this.componentService.currentForm.components, 0).map(m => m.id);
        if (this.componentService.currentForm.preferences != null)
            this.prefGlobal = this.componentService.currentForm.preferences;
        else
            this.prefGlobal = { formOpenedEvents: [], formOpenedMultiElementEvents: [] };
        this.showPrefPopup = true;
    }
    clearPrefPopup() {
        this.updateIndex = -1;
        this.multpileElementUpdateIndex = -1;
        this.formOpenedEvent = { connectionId: 0, elementId: "", query: "" };
        this.formOpenedMultiElementEvent = { connectionId: 0, query: "" };
    }
    closePrefPopup() {
        this.componentService.currentForm.preferences = this.prefGlobal;
        this.clearPrefPopup();
    }
    saveFormOpenSqlScript() {
        if (this.updateIndex == -1) {
            this.prefGlobal.formOpenedEvents.push(this.formOpenedEvent);
        } else {
            this.prefGlobal.formOpenedEvents[this.updateIndex] = this.formOpenedEvent;
        }
        this.clearPrefPopup();
    }
    saveFormOpenMultiElementSqlScript() {
        this.prefGlobal.formOpenedMultiElementEvents = typeof this.prefGlobal.formOpenedMultiElementEvents != 'undefined' ? this.prefGlobal.formOpenedMultiElementEvents : [];
        if (this.multpileElementUpdateIndex == -1) {
            this.prefGlobal.formOpenedMultiElementEvents.push(this.formOpenedMultiElementEvent);
        } else {
            this.prefGlobal.formOpenedMultiElementEvents[this.multpileElementUpdateIndex] = this.formOpenedMultiElementEvent;
        }
        this.clearPrefPopup();
    }
    updateFormOpenedScript(i) {
        this.updateIndex = i;
        this.formOpenedEvent = this.componentService.cloneModel(this.prefGlobal.formOpenedEvents[i]);
    }
    deleteFormOpenedScript(i) {
        this.prefGlobal.formOpenedEvents.splice(i, 1);
    }
    updateFormOpenedMultiElementScript(i) {
        this.multpileElementUpdateIndex = i;
        this.formOpenedMultiElementEvent = this.componentService.cloneModel(this.prefGlobal.formOpenedMultiElementEvents[i]);
    }
    deleteFormOpenedMultiElementScript(i) {
        this.prefGlobal.formOpenedMultiElementEvents.splice(i, 1);
    }

    monacoOnInit() {
        this.isMonacoLoaded = true;
        let monaco = window['monaco'];
        this.setCustomKeywords(monaco);
    }
    registeredLanguage: any;
    setCustomKeywords(monaco) {
        if (this.registeredLanguage)
            this.registeredLanguage.dispose();

        this.registeredLanguage = monaco.languages.registerCompletionItemProvider('plaintext', {
            provideCompletionItems: () => {
                var suggestions = [];
                let elements = this.componentService.getComponentList(this.componentService.currentForm.components, 0).map(m => m.id);
                for (let i = 0; i < elements.length; i++) {
                    suggestions.push({
                        label: elements[i],
                        kind: monaco.languages.CompletionItemKind.Snippet,
                        insertText: "" + elements[i] + "",
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        documentation: 'method'
                    })
                }
                return { suggestions: suggestions };
            }
        });
    }

    getDbVisible() {
        if (!!this.connections && this.connections.length > 0) {
            let test = this.connections.find(value => value.isMainConn == "True")
            if (test.databaseName != "" && test.databaseName != null && !!test.databaseName) {
                return true
            }
            return false
        }
        return false
    }

    menuButtonOptions: any;
    preferencesButtonOptions: any;
    plusButtonOptions: any;
    saveButtonOptions: any;
    undoButtonOptions: any;
    trashButtonOptions: any;
    copyButtonOptions: any;
    exportImportButtonOptions: any;
    videoButtonOptions: any;
    blackquoteButtonOptions: any;
    databaseButtonOptions: any;
    formBackgroundFileUploadButtonOptions: any;

    setToolbarContext() {
        this.formColor = this.formColor ? this.formColor : "#ffffff";
        let _this = this;


        this.menuButtonOptions = {
            icon: 'menu',
            onClick: () => this.drawer.instance.toggle()
        }

        this.preferencesButtonOptions = {
            icon: 'preferences',
            text: this.languageLocal.events, //languagelocal DB'den tr ing ceviri 
            onClick: () => this.getPreferences()
        }

        this.plusButtonOptions = {
            icon: 'plus',
            text: this.languageLocal.new,
            onClick: () => this.createForm()
        }

        this.saveButtonOptions = {
            icon: 'save',
            text: this.languageLocal.save,
            onClick: () => this.openSavePopup()
        }

        this.undoButtonOptions = {
            icon: 'undo',
            text: this.languageLocal.undo,
            disabled: () => this.disableUndo(),
            onClick: () => this.undo()
        }

        this.trashButtonOptions = {
            icon: 'trash',
            text: this.languageLocal.delete,
            onClick: () => this.deleteForm()
        }

        this.copyButtonOptions = {
            icon: 'copy',
            text: this.languageLocal.copy,
            onClick: () => this.copyFormPopup()
        }

        this.exportImportButtonOptions = {
            icon: 'sorted',
            text: this.languageLocal.exportImport,
            onClick: () => this.exportImportForm()
        }

        this.videoButtonOptions = {
            icon: 'video',
            text: this.languageLocal.preview,
            onClick: () => this.openPreviewPopup()
        }

        this.blackquoteButtonOptions = {
            icon: 'blockquote',
            text: "JSON",
            onClick: () => this.openJsonPopup()
        }

        this.formBackgroundFileUploadButtonOptions = {
            icon: 'upload',
            text: this.languageLocal.uploadBg,
            onClick: () => this.openShowFormBackgroundFileUploadPopup()
        }

        this.databaseButtonOptions = {
            icon: 'info',
            visible: this.getDbVisible(),
            disabled: true,
            text: this.getDbName() + " - " + this.currentApplicationVersion,
        }

        /*
        this.toolbarContent = [{
            widget: 'dxButton',
            location: 'before',
            options: {
                icon: 'menu',
                onClick: () => this.drawer.instance.toggle()
            }
        },
        {
            widget: 'dxButton',
            location: 'before',
            options: {
                icon: 'preferences',
                text: 'Preferences',
                onClick: () => this.getPreferences()
            }
        },
        {
            widget: 'dxButton',
            location: 'before',
            options: {
                icon: 'plus',
                text: "New",
                onClick: () => this.createForm()
            }
        },
        {
            widget: 'dxButton',
            location: 'before',
            options: {
                icon: 'save',
                text: "Save",
                onClick: () => this.openSavePopup()
            }
        },
        {
            widget: 'dxButton',
            location: 'before',
            options: {
                icon: 'undo',
                text: "Undo",
                disabled: () => this.disableUndo(),
                onClick: () => this.undo()
            }
        },
        {
            widget: 'dxButton',
            location: 'before',
            options: {
                icon: 'trash',
                text: "Delete",
                onClick: () => this.deleteForm()
            }
        },
        {
            widget: 'dxButton',
            location: 'before',
            options: {
                icon: 'copy',
                text: "Copy",
                onClick: () => this.copyFormPopup()
            }
        },
        {
            widget: 'dxButton',
            location: 'before',
            options: {
                icon: 'video',
                text: "Overview",
                onClick: () => this.openPreviewPopup()
            }
        },
        {
            widget: 'dxButton',
            location: 'before',
            options: {
                icon: 'blockquote',
                text: "JSON",
                onClick: () => this.openJsonPopup()
            }
        },
        {
            widget: 'dxButton',
            location: 'after',
            visible: this.getDbVisible(),
            options: {
                visible: this.getDbVisible(),
                disabled: true,
                text: this.getDbName() + " - " + this.currentApplicationVersion,
            }
        },
        /*,
        {
            widget: 'dxColorBox',
            location: 'after',
            options: {
                value: this.formColor,
                onValueChanged(args) {

                    if (args.value) {
                        _this.formColor = args.value;
                    }
                    //   else {
                    //    this.formColor = "#ffffff";
                    //} 
                }
            }
        }
        ];
        */
    }
    clearCopyPopup() {
        this.copyForm.name = "";
        this.copyForm.description = "";
        this.copyForm.variableName = "";
        this.copyForm.category.parent = 0;
        this.copyForm.category.sub = 0;
    }
    CopyPopupTitle = "";
    showCopyPopup = false;
    emailValue: any = "";
    copyForm = {
        name: "",
        description: "",
        variableName: "",
        category: { parent: 0, sub: 0, isGeneric: true }
    };
    copyFormPopup() {
        this.CopyPopupTitle = this.componentService.currentForm.name != "" ? this.languageLocal.formCopy + ' "' + this.componentService.currentForm.name + '"' : this.languageLocal.formCopy;
        this.taskService.getCategoryList().subscribe((result: any) => {
            if (result && result.length > 0) {
                this.categoryList = result;
                if (this.componentService.currentForm.formId > 0) {
                    if (this.componentService.currentForm.categoryId > 0) {
                        let cat = result.find(f => f.id == this.componentService.currentForm.categoryId);
                        if (cat) {
                            this.copyForm.category.parent = this.componentService.currentForm.categoryId;
                        }
                        else {
                            for (let i = 0; i < result.length; i++) {
                                var item = result[i].items.find(f => f.id == this.componentService.currentForm.categoryId);
                                if (item) {
                                    this.copyForm.category.parent = this.componentService.currentForm.categoryId;
                                    this.subCategoryList = result[i].items;
                                    this.copyForm.category.sub = item.id;
                                    break;
                                }
                            }
                        }

                        this.copyForm.category.isGeneric = false;
                    }
                    else {
                        this.copyForm.category.isGeneric = true;
                    }
                }
                else {
                    this.copyForm.category.parent = 0;
                    this.subCategoryList = [];
                    this.copyForm.category.sub = 0;
                    this.copyForm.category.isGeneric = false;
                }
            }
            else {
                this.copyForm.category.parent = 0;
                this.subCategoryList = [];
                this.copyForm.category.sub = 0;
                this.copyForm.category.isGeneric = false;
            }

            this.showCopyPopup = true;
        });
    }

    ExportImportPopupTitle = "";
    formExportTextError = "";
    formImportTextError = "";
    exportImportFormPopup = false;
    exportButtonDisable = true;
    exportFileStr = "";
    exportFormFileName = "";
    exportImportForm() {
        debugger;
        if (this.componentService.currentForm.components.length <= 0 || this.componentService.currentForm.formId <= 0)
            this.formExportTextError = "<div class=\"log-html-error\">Forma eklenen komponent yok ya da form kaydedilmemiş. İşlemin hatalı çalışmaması için lütfen bu sorunu düzeltiniz.</div>";
        else
            this.formExportTextError = "";

        //this.formImportTextError = "<div class=\"log-html-error\"><b>UYARI:</b> Yüklemek istediğiniz satır sayısının fazla olması durumunda, internet tarayıcınızda <b>(BEKLE)</b> uyarısı gelebilir. Bu durumda ekranı kapatmayınız ve lütfen bekleyiniz.</div>";

        this.ExportImportPopupTitle = this.componentService.currentForm.name != "" ? ("Form" + this.languageLocal.export + " / " + this.languageLocal.import) + ' "' + this.componentService.currentForm.name + '"' : ("Form" + this.languageLocal.export + " / " + this.languageLocal.import);

        let currentFormJsonStr = JSON.stringify(this.componentService.currentForm);
        this.exportImportFormPopup = true;

        this.formService.formExport(currentFormJsonStr).subscribe((result: any) => {
            debugger;
            if (result && result.fileData) {
                //let textFile = null,
                //    makeTextFile = function (text) {
                //        var data = new Blob([text], { type: 'text/plain' });
                //        if (textFile !== null) {
                //            window.URL.revokeObjectURL(textFile);
                //        }

                //        textFile = window.URL.createObjectURL(data);

                //        return textFile;
                //    };

                //makeTextFile(result);

                var dataBlob = new Blob([result.fileData], { type: 'text/plain' });
                this.exportFileStr = window.URL.createObjectURL(dataBlob);
                this.exportFormFileName = (this.componentService.currentForm.name != "" ? this.componentService.currentForm.name : "empty") + ".rfe";
                this.exportButtonDisable = false;
            }
            else {
                this.exportButtonDisable = true;
                this.exportFileStr = "";
            }
        }, err => {
            debugger;
            this.exportFileStr = "";
            this.exportButtonDisable = true;
            console.log(err);
        });
        //let reg = new RegExp(/"connectionId":\d/gm)
        //let regexResults = currentFormJsonStr.match(reg);
        //let connections = [];
        //regexResults.forEach(regexResult => {
        //    if (connections.indexOf(regexResult.replace("\"connectionId\":", "")) == -1)
        //        connections.push(regexResult.replace("\"connectionId\":", ""));
        //});
        //let connectionsStr = connections.join(",");

        //console.log(connectionsStr);
        //this.exportImportFormPopup = true;


    }
    downloadExportButtonDisable() {
        let myStyles = {
            'cursor': 'not-allowed',//this.minPopopWidth,
            'opacity': '0.5',
            'text-decoration': 'none',
            'pointer-events': 'none'
        };
        return this.exportButtonDisable ? myStyles : {};
    }

    @ViewChild("importFormFileElement") importFormFileElement: ElementRef;
    importFormFile() {
        this.importFormFileElement.nativeElement.value = '';
        this.importFormFileElement.nativeElement.click();
    }

    formImportJson;
    connectionImportJson;
    connectionImportSelected = [];
    importFormFileElementChange(e) {

        let fr = new FileReader();
        let _this = this;
        fr.onload = function () {
            let formFile = fr.result;
            debugger;
            _this.formService.formFileControl(formFile).subscribe((result: any) => {
                debugger;
                if (result && result.success) {
                    _this.connectionImportJson = JSON.parse(result.connectionJson);
                    _this.formImportJson = result.formJson;
                    _this.connectionImportSelected = [];
                    for (let _i = 0; _i < _this.connectionImportJson.length; _i++) {
                        _this.connectionImportSelected.push({ importId: _this.connectionImportJson[_i].id, currentId: "" });
                    }
                    try {
                        let importMainConnIndex = _this.connectionImportJson.findIndex(x => x.main == "True");
                        _this.connectionImportSelected[importMainConnIndex].currentId = _this.appInfo.RiverEntity.connections.find(x => x.isMainConn == "True").id
                    } catch (ex) { };
                }
                else {
                }
            }, err => {
                debugger;
            });

        }

        fr.readAsText(e.target.files[0]);

    }

    importFormFileConfirm() {
        let msg = "<i>" + this.languageLocal.messageNeworupdate + "</i>"
        let result = confirm(msg, this.languageLocal.warning);

        result.then((dialogResult) => {
            if (dialogResult) {

                this.resetCurrentForm();
                this.isEdit = false;

                let changeForm = this.formImportJson;
                debugger;
                this.connectionImportSelected.forEach(connectionImport => {
                    changeForm = this.componentService.replaceAll(changeForm, '"connectionId":' + connectionImport.importId, '"connectionId":' + connectionImport.currentId);
                });
                let formLast = JSON.parse(changeForm);//.components;
                let comps = this.userAuthorizationClear(formLast.components);
                formLast.components = comps;

                debugger;
                this.componentService.currentForm.name = formLast.name;
                this.componentService.currentForm.description = formLast.description;
                this.componentService.currentForm.variableName = formLast.variableName;

                if (typeof formLast.category == "undefined") formLast.category = { parent: 0, sub: 0, isGeneric: true };
                if (this.componentService.currentForm.categoryId > 0) {
                    this.category.parent = formLast.category.parent;
                    this.category.sub = formLast.category.sub;
                }
                else {
                    this.category = { parent: 0, sub: 0, isGeneric: true };
                }

                this.componentService.currentForm = formLast;
                this.componentService.usedForms = [];
                this.componentService.menuFormNodeOptions = [];

                this.saveForm(this.saveFormType.import);

            }
        });

    }

    userAuthorizationClear(components) {
        for (let i = 0; i < components.length; i++) {
            if (components[i].permissions) {
                components[i].permissions = { readonlyGroup: [], readonlyUsers: [], hideGroup: [], hideUsers: [] };//readonlyGroup  hideGroup   readonlyUsers  hideUsers
            }
            if (components[i].type == "grid" || components[i].type == "gallery") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    components[i].rows[j].permissions = { readonlyGroup: [], readonlyUsers: [], hideGroup: [], hideUsers: [] };
                    if (components[i].type == "grid") {
                        for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                            this.userAuthorizationClear(components[i].rows[j].cols[k].components);
                        }
                    }
                }
            }
            else if (components[i].type == "panel" || components[i].type == "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    components[i].items[j].permissions = { readonlyGroup: [], readonlyUsers: [], hideGroup: [], hideUsers: [] };
                    this.userAuthorizationClear(components[i].items[j].components);
                }
            }
            //else if (components[i].type == "menu" && currentFormSelected.menuComponents) {
            //    for (let m = 0; m < currentFormSelected.menuComponents.length; m++) {
            //        this.createComponentList(currentFormSelected.menuComponents[m], anotherPopupId);
            //        if (currentFormSelected.menuComponents[m].type == "grid") {
            //            for (let j = 0; j < currentFormSelected.menuComponents[m].rows.length; j++) {
            //                for (let k = 0; k < currentFormSelected.menuComponents[m].rows[j].cols.length; k++) {
            //                    this.createComponentList(currentFormSelected.menuComponents[m].rows[j].cols[k].components, anotherPopupId);
            //                }
            //            }
            //        }
            //        else if (currentFormSelected.menuComponents[m].type == "panel" || currentFormSelected.menuComponents[m].type == "tabs") {
            //            for (let j = 0; j < this.currentForm.menuComponents[m].items.length; j++) {
            //                this.createComponentList(currentFormSelected.menuComponents[m].items[j].components, anotherPopupId);
            //            }
            //        }
            //    }
            //}
        }
        return components;
    }

    showPreviewPopup: boolean = false;
    showJsonPopup: boolean = false;

    showFormBackgroundFileUploadPopup: boolean = false;
    uploadedFiles: any = [];

    openJsonPopup() {
        this.showJsonPopup = true;
    }

    closeJsonPopup() {
        this.showJsonPopup = false;
    }

    openPreviewPopup() {
        this.previewFormStyle = this.getPreviewFormStyle()
        this.showPreviewPopup = true;
        this.render();
    }

    closePreviewPopup() {
        this.previewFormStyle = {
            'background': this.formColor,
            'padding': '20px'
        };
        this.showPreviewPopup = false;
        this.reset();
    }

    openShowFormBackgroundFileUploadPopup() {
        this.previewFormStyle = this.getPreviewFormStyle()
        this.showFormBackgroundFileUploadPopup = true;
    }

    closeShowFormBackgroundFileUploadPopup() {
        this.showFormBackgroundFileUploadPopup = false;
    }

    previewFormStyle: any = {
        'background': this.formColor,
        'padding': '20px'
    };

    copyFormSave() {
        this.componentService.currentForm.name = this.copyForm.name;
        this.componentService.currentForm.description = this.copyForm.description;
        this.componentService.currentForm.variableName = this.copyForm.variableName;
        this.category.parent = this.copyForm.category.parent;
        this.category.sub = this.copyForm.category.sub;

        this.saveForm(this.saveFormType.copy);
    }
    onKeyPress(e) {

        let keyCode = e.event.key;
        this.componentService.currentForm.variableName = "";
        this.emailValue = "";
    }

    public arrayColors: any = {
        color1: '#2883e9',
        color2: '#e920e9',
        color3: 'rgb(255,245,0)',
        color4: 'rgb(236,64,64)',
        color5: 'rgba(45,208,45,1)'
    };

    public selectedColor: string = '#2889e9';

    public color1: string = '#2889e9';
    public color2: string = '#e920e9';

    ngOnInit() {
        this.authService.isFormBuilder = true;
        // this.languageCode = this.componentService.languageCode;
        //this.lang = this.componentService.languageCode;
        this.componentList = this.componentService.componentList;
        this.componentList.unshift({ group: "Form" })
        this.getFormList();
        this.invokeFunctions();
        this.pushHistory(this.componentService.currentForm);
        debugger
        this.setToolbarContext();
        console.log(this.componentService.currentForm.language)
        console.log(this.componentService.currentForm)

        if (typeof this.componentService.currentForm.language == "undefined") {
            this.componentService.currentForm.language = {
                "ENEN": "",
                "TRTR": "",
            }
        } else {
            let find = this.componentService.currentForm.language.find(f => f.Code.toLowerCase() == this.lang.toLowerCase())
            if (typeof find != "undefined" && typeof find.Title != "undefined" && find.Title != "") {
               // console.log(find+"--"+find.name+"--"+find.Title)
               // find.title = find.Title
               // console.log(find + "--" + find.name + "--" + find.Title)

            }
        }


    }

    ngAfterViewChecked() {
        try {
            this.changeDetectorRef.detectChanges();
        } catch (ex) {
            console.log("River Error : " + ex.toString())
        }
    }

    getFormStyle() {
        if (this.formBackgroundExtension && this.formBackground) {
            let mimetype = this.globalService.getMimeType(this.formBackgroundExtension)
            let val = "data:" + mimetype + ";base64," + this.formBackground;
            //console.log(val)
            let myStyles = {
                'min-height': "calc(100vh - 100px)",
                'background-image': "url(" + val + ")",
                'background-size': "cover",
                'background-repeat': "no-repeat",
                'background-position': "center",
                'background-color': this.formColor
            };
            return myStyles;
        }
        let myStyles = {
            'min-height': "calc(100vh - 100px)",
            'background': this.formColor,
        };
        return myStyles;
    }

    getPreviewFormStyle() {
        if (this.formBackgroundExtension && this.formBackground) {
            let mimetype = this.globalService.getMimeType(this.formBackgroundExtension)
            let val = "data:" + mimetype + ";base64," + this.formBackground;
            //console.log(val)
            let myStyles = {
                'background-image': "url(" + val + ")",
                'background-size': "cover",
                'background-repeat': "no-repeat",
                'background-position': "center",
                'background-color': this.formColor,
                'padding': '20px',
            };
            return myStyles;
        }
        let myStyles = {
            'min-height': "calc(100vh - 100px)",
            'background': this.formColor,
            'padding': '20px',
        };
        return myStyles;
    }

    invokeFunctions() {
        this.eventEmitterService.invokeHistoryFunction.subscribe(sub => {
            if (sub.action === this.componentService.historyActions.save) {

                this.pushHistory(this.componentService.currentForm);
            }
            else if (sub.action === this.componentService.historyActions.undo) {
                this.undo();
            }
        });
        this.eventEmitterService.invokeFormChangesFunction.subscribe((name: string) => {
            this.componentService.evulateExpretion(this.componentService.currentForm.Expretion);
        });
        this.eventEmitterService.invokeLayoutChangeFunction.subscribe((name: string) => {
            debugger;
            //this.getCdkList();
        });
    }

    //changeFormColor(args)  {
    //    
    //    this.formColor = args.value;
    //}

    createForm() {
        //let msg = "<i>Var olan form üzerinde yaptığınız değişiklikler silenecektir. Devam edilsin mi? </i>";
        let msg = "<i>" + this.languageLocal.messageNeworupdate + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.resetCurrentForm();
                this.isEdit = false;
            }
        });
    }

    itemClickAccordion(e) {
        console.log(e)
        if (!(e.component.shouldNotStop)) {
            e.component.shouldNotStop = true;
            e.component.option("selectedItem", this.componentList);
            e.removedItems = [];
            e.component.shouldNotStop = false;
        }
    };

    editForm(e) {

        if (e.itemData.isScheme) {
            if (this.componentService.currentForm && this.componentService.currentForm.components.length > 0) {
                //let msg = "<i>Var olan form üzerinde yaptığınız değişiklikler silenecektir. Devam edilsin mi? </i>";
                let msg = "<i>" + this.languageLocal.messageNeworupdate + "</i>"
                let result = confirm(msg, this.languageLocal.warning);
                result.then((dialogResult) => {
                    if (dialogResult) {
                        this.removeCustomStyle();
                        this.formService.getSingleForm(e.itemData.id).subscribe((result: any) => {

                            this.componentService.currentForm = JSON.parse(result.forM_JSON);
                            this.componentService.currentForm.formId = result.id;
                            this.componentService.currentForm.categoryId = result.categorY_ID;
                            this.pushHistory(this.componentService.currentForm);

                            this.formColor = result.formcolor;
                            this.formBackground = result.forM_BACKGROUND_FILE
                            this.formBackgroundExtension = result.forM_BACKGROUND_FILE_EXTENSION


                            this.setToolbarContext();

                            this.componentService.currentSystemInfo = [];

                            if (typeof result.usedforms != "undefined")
                                this.componentService.usedForms = result.usedforms != null ? result.usedforms.split(",") : [];
                            else if (typeof result.usedForms != "undefined")
                                this.componentService.usedForms = result.usedforms != null ? result.usedForms.split(",") : [];

                            if (typeof this.componentService.currentForm.language == "undefined") {
                                this.componentService.currentForm.language = [{ "Code": "trTR", "Title": this.componentService.currentForm.name }]
                            } else {
                                let find = this.componentService.currentForm.language.find(f => f.Code.toLowerCase() == this.lang.toLowerCase())
                                if (typeof find != "undefined" && typeof find.Title != "undefined" && find.Title != "") {
                                  //  find.title = find.Title
                                }
                            }
                        });
                    }
                    try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                });
            }
            else {
                this.removeCustomStyle();
                this.formService.getSingleForm(e.itemData.id).subscribe((result: any) => {

                    this.componentService.currentForm = JSON.parse(result.forM_JSON);
                    this.formColor = result.formcolor;
                    this.formBackground = result.forM_BACKGROUND_FILE
                    this.formBackgroundExtension = result.forM_BACKGROUND_FILE_EXTENSION


                    this.setToolbarContext();

                    this.componentService.currentForm.formId = result.id;
                    this.componentService.currentForm.categoryId = result.categorY_ID;
                    this.pushHistory(this.componentService.currentForm);


                    this.componentService.currentSystemInfo = [];

                    if (typeof result.usedforms != "undefined")
                        this.componentService.usedForms = result.usedforms != null ? result.usedforms.split(",") : [];
                    else if (typeof result.usedForms != "undefined")
                        this.componentService.usedForms = result.usedforms != null ? result.usedForms.split(",") : [];

                    if (typeof this.componentService.currentForm.language == "undefined") {
                        this.componentService.currentForm.language = [{ "Code": "trTR", "Title": this.componentService.currentForm.name }]
                    } else {
                        let find = this.componentService.currentForm.language.find(f => f.Code.toLowerCase() == this.lang.toLowerCase())
                        if (typeof find != "undefined" && typeof find.Title != "undefined" && find.Title != "") {
                            //find.name = find.Title
                        }
                    }

                    try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                });
            }

        }


    }

    deleteForm() {
        if (this.componentService.currentForm.components.length > 0) {
            let result = confirm("<i>" + this.languageLocal.messageDelete + "</i>", this.languageLocal.confirmChanges);
            result.then((dialogResult) => {
                if (dialogResult) {
                    if (this.componentService.currentForm.formId > 0) {
                        this.formService.deleteForm(this.componentService.currentForm.formId).subscribe(result => {
                            if (result) {
                                this.showToast(this.languageLocal.toastDeleted, "success");
                                this.getFormList();
                                this.resetCurrentForm();
                            } else {
                                this.showToast(this.languageLocal.toastError, "error");
                            }

                        }, err => this.errorHandler(err));
                    }
                    else {
                        this.resetCurrentForm();
                    }
                }

            });
        }
    }

    tabPrefSelectG = "formOpen";
    tabFormPrefSelectChanged(event) {
        debugger;
        this.tabPrefSelectG = event.addedItems[0].name;
    }
    checkUser(userName: string, password: string) {
        console.log(this.appInfo.currentUser)
        let params = new HttpParams().set("userName", userName).set("password", password);
        this.http.get<string>(this.appInfo.appUrl + "api/auth/login", { params: params }).subscribe((entity: any) => {

            if (entity.isConnect && entity.currentUser.isDesigner) {
                console.log(entity)
                this.connections = entity.connections
                this.setToolbarContext();
                this.appInfo.RiverEntity = entity;
                this.appInfo.currentUser = entity.currentUser;
            } else if (entity.isConnect && !entity.isDesigner) {
                this.router.navigateByUrl('/');
                this.showToast(this.languageLocal.toastUserpermission, "warning");
            } else {
                this.router.navigateByUrl('/');
                this.showToast(entity.message, "warning");
            }
        });
    }

    getFormList() {
        this.formService.getGroupedForms().subscribe(forms => {
            console.log(forms);
            this.formList = forms;
            this.formList.forEach(list => {
                list.items.forEach(el => {
                    if (typeof el.languages != "undefined" && el.languages != "") {
                        let languages = JSON.parse(el.languages)
                        let find = languages.find(f => f.Code.toLowerCase() == this.lang.toLowerCase())
                        if (typeof find != "undefined" && typeof find.Title != "undefined" && find.Title != "") {
                            el.name = find.Title
                        }
                    }
                })
            })

        })
    }

    openSavePopup() {
        this.taskService.getCategoryList().subscribe((result: any) => {
            if (result && result.length > 0) {
                this.categoryList = result;
                if (this.componentService.currentForm.formId > 0) {
                    if (this.componentService.currentForm.categoryId > 0) {
                        let cat = result.find(f => f.id == this.componentService.currentForm.categoryId);
                        if (cat) {
                            this.category.parent = this.componentService.currentForm.categoryId;
                        }
                        else {
                            for (let i = 0; i < result.length; i++) {
                                var item = result[i].items.find(f => f.id == this.componentService.currentForm.categoryId);
                                if (item) {
                                    this.category.parent = this.componentService.currentForm.categoryId;
                                    this.subCategoryList = result[i].items;
                                    this.category.sub = item.id;
                                    break;
                                }
                            }
                        }

                        this.category.isGeneric = false;
                    }
                    else {
                        this.category.isGeneric = true;
                    }
                }
                else {
                    this.category.parent = 0;
                    this.subCategoryList = [];
                    this.category.sub = 0;
                    this.category.isGeneric = false;
                }
            }
            else {
                this.category.parent = 0;
                this.subCategoryList = [];
                this.category.sub = 0;
                this.category.isGeneric = false;
            }
            let find = this.componentService.currentForm.language.find(f => f.Code.toLowerCase() == this.lang.toLowerCase())
            if (typeof find != "undefined" && typeof find.Title != "undefined" && find.Title != "") {
                this.componentService.currentForm.name = find.Title
            }
            this.showSavePopup = true;
        });
    }

    onCategoryValueChanged(id) {
        let category = this.categoryList.find(f => f.id == id);
        if (category) this.subCategoryList = category.items;
        else this.subCategoryList = [];
    }

    dragStart(field) {
        this.onDragClass = "active";
        localStorage.setItem("currentField", JSON.stringify(field));
    }

    onDragEnded(event: CdkDragEnd): void {
        debugger;
        this.onDragClass = "";
    }

    cdkDrop(event: CdkDragDrop<any[]>) {
        debugger;
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        }
        else {
            let type = event.previousContainer.element.nativeElement.dataset.type;
            if (type && type == "copy") {
                let currentField: any = JSON.parse(localStorage.getItem("currentField"));
                //localStorage.removeItem(key);localStorage.clear();
                currentField.id = this.componentService.createId(currentField.type, this.componentService.currentForm.components, 0);
                if (currentField.type == "codeditor") {
                    if (currentField.id == "codeditor1") this.componentService.currentForm.components.splice(this.componentService.currentForm.components.length, 0, currentField);
                    else this.showToast(this.languageLocal.toastCodeeditor, "warning");
                }
                else this.componentService.currentForm.components.splice(event.currentIndex, 0, currentField);

                if (currentField.type == "grid" || currentField.type == "panel" || currentField.type == "tabs") {
                    this.componentService.createCdkList(0);
                }
                localStorage.removeItem("currentField");

                /*copyArrayItem(event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex);*/
            }
            else {
                transferArrayItem(event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex);
            }
            this.onDragClass = "";
        }

        this.pushHistory(this.componentService.currentForm);
    }


    itemClick(e, item) {
        if (e.itemData.action == "edit") {
            this.eventEmitterService.showPopop(item.uuid);
            //item.showEditPop = true;
        }
        else if (e.itemData.action == "delete") {
            let msg = item.type == "grid" || item.type == "panel" ? "<i>" + this.languageLocal.messageDeletesure + "</i>" : "<i>" + this.languageLocal.messageSure + "</i>"
            let result = confirm(msg, this.languageLocal.warning);
            result.then((dialogResult) => {
                if (dialogResult) this.removeField(item);
            });
        }
    }

    removeField(item) {
        this.componentService.currentForm.components.splice(this.componentService.currentForm.components.indexOf(item), 1);
        this.pushHistory(this.componentService.currentForm);
        this.showToast(this.languageLocal.toastDeleted, "success");
    }
    toggleValue(item) {
        item.selected = !item.selected;
    }

    pushHistory(data) {
        let cloneData = this.componentService.cloneModel(data);
        this.history.push(cloneData);

    }

    disableUndo(): boolean {
        return this.history.length < 2;
    }

    undo() {
        if (this.history.length > 1) {
            this.history.splice(this.history.length - 1, 1);
            this.componentService.currentForm = {};
            this.componentService.currentForm = this.history[this.history.length - 1];// this.componentService.cloneModel(this.history[this.history.length -1]);

        }
        else {
            this.resetCurrentForm();
            this.history[0] = this.componentService.currentForm;
        }
    }
    saveFormType = { 
        normal: 0,
        copy: 1,
        import: 2 
    }
    saveForm(/*isCopy = false,isImport=false*/_type = this.saveFormType.normal) {
        const pattern = /[A-Za-z_]/;
        debugger;
        if (this.componentService.currentForm.components.length <= 0) {
            this.showToast(this.languageLocal.toastEmptyFormMessage, "warning");
        }
        else if (this.componentService.currentForm.name == "") {
            this.showToast(this.languageLocal.toastGiveName, "warning");
        }
        else if (this.componentService.currentForm.variableName == "") {
            this.showToast(this.languageLocal.toastGiveVariableName, "warning");
        }
        //ustteki ile ayni oldugundan comment satirina aldim
        // else if (this.componentService.currentForm.variableName == "") {
        //   this.showToast("Lütfen formonuza bir değişken adı veriniz", this.languageLocal.warning); }
        else if (!pattern.test(this.componentService.currentForm.variableName[0])) {
            this.showToast(this.languageLocal.toastVariableNameNumberWarning, "warning");
        }
        else {

            this.componentService.currentForm.data = [];
            this.createValuesData(this.componentService.currentForm.components);
            let formData = new FormModel();
            if (_type == this.saveFormType.copy || _type == this.saveFormType.import)
                formData.ID = 0;
            else
                formData.ID = this.componentService.currentForm.formId;
            formData.USER = this.appInfo.currentUser.id;
            formData.NAME = this.componentService.currentForm.name;
            formData.DESCRIPTION = this.componentService.currentForm.description;

            let curentFormFiles = this.componentService.getFilesComponentList(this.componentService.currentForm.components, 0);

            // PictureBox
            let pictureBoxComp = curentFormFiles.filter(x => x.type == "picturebox");
            let pictureBoxTemp = [];
            pictureBoxComp.forEach(pict => {
                let objPict = {
                    valueTemp: pict.valueTemp,
                    id: pict.id
                };
                pictureBoxTemp.push(objPict);
                pict.valueTemp = "";
            });

            formData.FORM_JSON = JSON.stringify(this.componentService.currentForm, null, 4);
            formData.FORM_VARIABLE = JSON.stringify(this.componentService.currentForm.data, null, 4);

            pictureBoxTemp.forEach(pict => {
                let pictureBoxCompTemp = curentFormFiles.find(x => x.id == pict.id);
                pictureBoxCompTemp.valueTemp = pict.valueTemp;
            });

            // Signature
            let signatureComp = curentFormFiles.filter(x => x.type == "signature");
            let signatureTemp = [];
            signatureComp.forEach(pict => {
                let objPict = {
                    valueTemp: pict.valueTemp,
                    id: pict.id
                };
                signatureTemp.push(objPict);
                pict.valueTemp = "";
            });

            signatureTemp.forEach(pict => {
                let signatureCompTemp = curentFormFiles.find(x => x.id == pict.id);
                signatureCompTemp.valueTemp = pict.valueTemp;
            });


            formData.ISMENU = this.componentService.currentForm.components.filter(x => x.type == "menu").length > 0;

            formData.USEDFORMS = this.componentService.usedForms.toString();


            formData.MENUOPTIONS = JSON.stringify(this.componentService.menuFormNodeOptions);
            formData.FORMCOLOR = this.formColor;
            formData.FORM_BACKGROUND_FILE = (this.formBackground && this.formBackgroundExtension) ? this.formBackground.replace(/^data:image\/[a-z]+;base64,/, "") : null;;
            formData.FORM_BACKGROUND_FILE_EXTENSION = (this.formBackground && this.formBackgroundExtension) ? this.formBackgroundExtension : null;


            if (this.category.isGeneric) formData.CATEGORY_ID = 0;
            else if (this.category.sub && this.category.sub > 0) formData.CATEGORY_ID = this.category.sub;
            else if (this.category.parent && this.category.parent > 0) formData.CATEGORY_ID = this.category.parent;
            else formData.CATEGORY_ID = 0;


            if (this.componentService.currentForm.formId > 0 && _type == this.saveFormType.normal) {
                this.formService.updateForm(formData, this.appInfo.currentUser.id).subscribe((result: any) => {

                    if (result > 0) {
                        this.showToast(this.languageLocal.toastFormupdate, "success");
                        this.showSavePopup = false;
                        this.getFormList();
                    } else {
                        this.showToast(this.languageLocal.toastMistake, "error");
                    }

                }, err => this.errorHandler(err));
            }
            else {
                formData.ID = 0;
                this.formService.saveForm(formData).subscribe((result: any) => {

                    if (result > 0) {
                        this.resetCurrentForm();
                        //this.componentService.currentForm.id = result;
                        if (_type == this.saveFormType.copy ) {
                            this.showToast(this.languageLocal.toastCopied, "success");
                            this.showCopyPopup = false;
                        } else if (_type == this.saveFormType.import) {
                            this.showToast(this.languageLocal.toastImport, "success");
                            this.exportImportFormPopup = false;
                        } else {
                            this.showToast(this.languageLocal.toastSaved, "success");
                            this.showSavePopup = false;
                        }
                        this.getFormList();
                    } else {
                        this.showToast(this.languageLocal.toastMistake, "error");
                    }

                }, err => this.errorHandler(err));
            }

        }


    }

    createValuesData(components) {
        debugger;
        //console.log(this.componentService.currentSystemInfo);
        for (let i = 0; i < components.length; i++) {
            if (components[i].isInput == true
                || components[i].inputType == "datagrid"
                || components[i].inputType == "file"
                || components[i].inputType == "menu"
                || components[i].inputType == "map"
                || components[i].type == "gallery"
                || components[i].type == "codeeditor"
                || components[i].type == "htmleditor"
                || components[i].type == "comment") {
                var obj: any = {};
                obj["key"] = components[i].id;
                obj["value"] = components[i].value;
                obj["type"] = components[i].inputType;
                obj["componentType"] = components[i].type;

                obj["isAuto"] = components[i].isAuto;
                obj["autoText"] = components[i].autoText;



                if (components[i].type == "livestream") {

                    obj["meetingType"] = components[i].meetingType;
                }
                if (components[i].type == "button") {

                    //  let selectedButton = components[i];//.find(x => x.property.name == this.buttonNameGlobal);
                    obj["name"] = components[i].nameValue;
                    //obj["isLiveWeb"] = false;// selectedButton.isLiveWeb;
                    //obj["liveWeb"] = { isCreateLiveWeb: true, urlHost: "", urlParticipant: "" };//selectedButton.liveWeb;
                }
                if (components[i].type == "textbox") {

                    let systemInfoTemp = this.componentService.currentSystemInfo.find(x => x.id == obj["key"]);
                    obj["isSystemInfo"] = components[i].systemInfoId != 0 ? true : false;
                    console.log(systemInfoTemp);
                    try { obj["value"] = components[i].systemInfoId != 0 ? systemInfoTemp.value.value : obj["value"]; } catch (ex){}
                }


                if (components[i].inputType == "datagrid") {

                    var gridVal = [] //components[i].value;
                    components[i].value = []
                    var lastId = gridVal.length > 0 ? gridVal[gridVal.length - 1].ID : 0;
                    obj["lastid"] = lastId;

                    var headerAndType = this.getGridHeaders(components[i]);
                    obj["headers"] = headerAndType.headers;
                    obj["headerTypes"] = headerAndType.headerTypes;
                    obj["rowCount"] = components[i].value.length;
                    obj["selectedRowCount"] = components[i].selectedRows;
                    obj["userChangeColumns"] = components[i].userChangeColumns;
                }
                else if (components[i].type == "map") {
                    obj["markers"] = components[i].markers;
                }
                else {
                    obj["markers"] = [];
                    obj["headers"] = "";
                    obj["headerTypes"] = "";
                    obj["rowCount"] = 0;
                    obj["selectedRowCount"] = 0;
                    obj["userChangeColumns"] = [];
                }
                this.componentService.currentForm.data.push(obj);
            }
            else if (components[i].type == "grid") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                        this.createValuesData(components[i].rows[j].cols[k].components);
                    }
                }
            }
            else if (components[i].type == "panel" || components[i].type == "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    this.createValuesData(components[i].items[j].components);
                }
            }
        }
    }

    getGridHeaders(grid) {
        let headers = [];
        let headerTypes = [];

        grid.headers.forEach(header => {

            var obj: any = {};
            //if (header.type != "file") {
            headers.push(header.field);
            headerTypes.push(header.type);
            //}
        });
        return { headers: headers.join(","), headerTypes: headerTypes.join(",") };
    }


    resetCurrentForm() {
        this.componentService.currentForm = {
            formId: 0,
            user: '',
            name: '',
            description: '',
            permitions: '',
            expretion: '',
            components: [],
            data: [],
            preferences: { formOpenedEvents: [], formOpenedMultiElementEvents: [] },
            language: [
                { "Code": "trTR", "Title": "Form Adı" }
            ],
        };
        this.currentIndex = 0;
        this.history = [];
        this.uploadedFiles = [];
        this.formBackground = null;
        this.formBackgroundExtension = null

        this.removeCustomStyle();

        this.componentService.currentSystemInfo = [];
    }

    removeCustomStyle() {
        document.querySelectorAll(".style-dynamic").forEach(_doc => {
            try {
                _doc.remove();
            } catch (ex) { }
        });
    }

    minPopopWidth = "90%";
    render() {
        localStorage.setItem("renderFormComponents", JSON.stringify(this.componentService.currentForm.components));
        this.renderData = JSON.parse(localStorage.getItem("renderFormComponents"));
        this.componentService.checkUserAuthorization(false, this.renderData, this.appInfo.currentUser.id, 0)
        this.isRender = true;
    }

    reset() {
        this.isRender = false;
        this.renderData = [];
        localStorage.removeItem("renderFormComponents");
        this.eventEmitterService.setColumnSumToComponent("sys_re_set_val", 0);
    }

    showToast(message, status) {
        notify(message, status, 3000);
    }

    errorHandler(error) {
        let errorMessage = '';
        errorMessage = error.error instanceof ErrorEvent ? error.error.message : 'Error Code: ${error.status}\nMessage: ${error.message}';
        console.log(errorMessage);
        this.showToast(errorMessage, "error");
    }

    valueChangedVariableName($event) {
        this.componentService.currentForm.variableName = this.replaceSpecialChars(this.componentService.currentForm.variableName);
    }

    replaceSpecialChars(str) {
        //if (typeof str == "undefined") return ""
        var specialChars = [
            ["ş", "s"],
            ["ğ", "g"],
            ["ü", "u"],
            ["ı", "i"],
            ["ö", "o"],
            ["Ş", "S"],
            ["Ğ", "G"],
            ["Ç", "C"],
            ["ç", "c"],
            ["Ü", "U"],
            ["İ", "I"],
            ["Ö", "O"],
            //["_", "-"],
        ];
        if (typeof str != "undefined" && str) {
            for (var i = 0; i < specialChars.length; i++) {
                str = str.replace(specialChars[i][0], specialChars[i][1]);
            }
        }
        return str;
    }

    onValueChangeFormBackgroundFile($event) {
        debugger;
        let e = $event
        const file = e.value[0];
        const fileReader = new FileReader();

        var re = /(?:\.([^.]+))?$/;

        var ext = re.exec(file.name)[1];

        fileReader.onload = () => {

            if (!!ext) {
                let base64 = fileReader.result as string;
                console.log(this.formBackground)
                var reg = new RegExp('(?<=base64,)(.*$)')
                var RegexResult = reg.exec(base64); //base64.replace(/^.*base64,(?=\/)/, "");
                this.formBackground = RegexResult[0]
                this.formBackgroundExtension = ext;
                this.previewFormStyle = this.getPreviewFormStyle()
            }

        };

        fileReader.readAsDataURL(file);

    }

    clearFormBackgroundButton() {
        this.uploadedFiles = [];
        this.formBackground = null;
        this.formBackgroundExtension = null;
        this.previewFormStyle = this.getPreviewFormStyle()
    }

    getFormBackgroundForPopup() {
        if (this.formBackgroundExtension && this.formBackground) {
            let mimetype = this.globalService.getMimeType(this.formBackgroundExtension)
            let val = "data:" + mimetype + ";base64," + this.formBackground;
            return val;
        }
        else {
            return null
        }
    }


    addLanguage(panel) {

        this.languagePopup = true;
    }
    saveLanguagePopup() {
        this.languagePopup = false;
        let find = this.componentService.currentForm.language.find(f => f.Code.toLowerCase() == this.lang.toLowerCase())
        if (typeof find != "undefined" && typeof find.Title != "undefined" && find.Title != "") {
            this.componentService.currentForm.name = find.Title
        }
    }
    formNameModelChange(value) {
        console.log(value);          //Changed Value
        let find = this.componentService.currentForm.language.find(f => f.Code.toLowerCase() == this.lang.toLowerCase())
        if (typeof find != "undefined" && typeof find.Title != "undefined" && find.Title != "") {
            find.Title = value
        }
        
    }

}
