import { Component, OnInit, Input } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';

import notify from "devextreme/ui/notify";
import { ComponentService } from '../../component.service';

@Component({
    selector: 'languagegrid-component',
    templateUrl: './languagegrid.component.html'
})
export class DxLanguageGridComponent implements OnInit {
    @Input() dataSource: any;

    languageCodes = []
    languageLocal: any = "";

    gridCols: any = [];

    constructor(private componentService: ComponentService) {
        this.languageLocal = this.componentService.getLanguageSource(); //language
        this.languageCodes = this.componentService.getlanguageCodes();
    }

    ngOnInit() {
        this.gridCols = Object.keys(this.dataSource[0]);
        const index = this.gridCols.findIndex(item => item === "Code");
        this.gridCols.splice(index, 1);

        //this.buttonCols = Object.keys(this.dataSource[0]);
        //const index_Code = this.buttonCols.findIndex(item => item === "Code");
        //if (index_Code != -1)
        //    this.buttonCols.splice(index_Code, 1);


        //const indexNameLabel = this.gridCols.findIndex(item => item === "NameLabel");

        ////NameLabel 
        ////CardNoLabel 
        ////ExpiresLabel
        ////MonthLabel 
        ////YearLabel 
        ////CvvCvcLabel 



    }

}
