import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'data-table-paginator',
    templateUrl: './data-table-paginator.component.html',
    styleUrls: ['./data-table-paginator.component.scss']
})
export class DataTablePaginator implements OnChanges{

    constructor() { }


    @Input() page: number;
    @Input() pageSize: number

    active = 1;
    pages;          // 
    showPages = []  //

    showNext = false;
    showPrevious = false;
    showLast = true;
    showFirst = true;

    changePageEvent(value, page?) {

        if (value == 'previous') {
            this.active = this.active - 1
        } else if (value == 'next') {
            this.active = this.active + 1
        } if (value == "click" && !!page) {
            this.active = page
        }

        this.createShowPages()

    }

    createShowPages() {
        if (this.pages.length <= 5) {
            this.showPages = this.pages
            this.showNext = false;
            this.showPrevious = false;
            this.showFirst = false;
            this.showLast = false;
        } else {
            if (this.pages.length > 1) {
                if (this.active == 1) {
                    this.showPages = [this.active, this.active + 1, this.active + 2]
                    this.showNext = true;
                    this.showPrevious = false;
                    this.showFirst = false;
                    this.showLast = true;
                } else if (this.active == 2) {
                    this.showPages = [this.active - 1, this.active, this.active + 1]
                    this.showNext = true;
                    this.showPrevious = false;
                    this.showFirst = false;
                    this.showLast = true;
                } else if (this.active == this.pages.length) {
                    this.showPages = [this.active - 2, this.active - 1, this.active]
                    this.showNext = false;
                    this.showPrevious = true;
                    this.showFirst = true;
                    this.showLast = false;
                } else if (this.active == this.pages.length - 1) {
                    this.showPages = [this.active - 1, this.active, this.active + 1]
                    this.showNext = false
                    this.showPrevious = true
                    this.showFirst = true;
                    this.showLast = false;
                } else {
                    this.showPages = [this.active - 1, this.active, this.active + 1]
                    this.showNext = true;
                    this.showPrevious = true;
                    this.showFirst = true;
                    this.showLast = true;
                }
            }
        }
    }

    calculatePage(page) {

        let value1: number = this.page / this.pageSize
        let value2: number = Math.round(this.page / this.pageSize)

        let pageValue
        if (value1 > value2) {

            pageValue = Math.round(this.page / this.pageSize) + 2
            this.pages = Array(pageValue).fill(0).map((x, i) => i + 1);

        } else {

            pageValue = Math.round(this.page / this.pageSize) + 1
            this.pages = Array(pageValue).fill(0).map((x, i) => i + 1);

        }

        this.pages.pop();
        this.createShowPages()

    }

    ngOnChanges(changes: any) {
        this.calculatePage(changes.currentValue)
        this.active = 1
        this.createShowPages()
    }

    get currentPage() {
        return this.active;
    }

}

