import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { CdkDragDrop, CdkDragStart, CdkDragEnd, moveItemInArray, copyArrayItem, transferArrayItem } from '@angular/cdk/drag-drop';

import { ComponentService } from '../component.service';
import { EventEmitterService, AppInfoService } from '../../shared/services';

import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';
import { debug } from 'console';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss']
})
export class DxTabsComponent implements ComponentParameters {
    @Input() data: any;
    @Input() lang: any;
    @Input() isEdit: any;
    @Input() anotherPopupId: number = 0;
    queryArray = { hide: { key: "hide", value: "false", type: "string" } }
    query = JSON.stringify(this.queryArray)
    filterMetadata = { count: 0 };
    showDisplay: any = "block";

    onGridDragClass: string = "active";
    actions = [
        {
            icon: "edit",
            alignment: "center",
            hint: "Center",
            action: "edit"
        },
        {
            icon: "trash",
            alignment: "right",
            hint: "Delete",
            action: "delete"
        },
    ];

   // defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;

    panelPopup: boolean = false;
    panelPermissionPopup: boolean = false;
    selectedPanelPermission: any;
    selectedPanelLanguage: any;
    languageLocal: any = "";
    
    constructor(private componentService: ComponentService, private eventEmitterService: EventEmitterService, public appInfo: AppInfoService, public changeDetectorRef: ChangeDetectorRef) {

        this.onReorder = this.onReorder.bind(this);

        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
        this.languageLocal = this.componentService.getLanguageSource(); //language

    }
    //
    ngOnInit() {
        this.data.uuid = this.componentService.createuuid();
        if (!this.isEdit) this.checkUserAuthorization();

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;


        this.data.options = this.data.options ? this.data.options : { "bgColor": "rgba(255, 255, 255, 1)", "color": "rgb(0, 0, 0)" };

        this.showDisplay = !this.isEdit ? (!this.data.hide && this.data.outsideVisible ? "block" : "none") : "block";
       // this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (!this.data.hide && this.data.outsideVisible ? "block" : "none") : "block";

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });
       // debugger;
        console.log(this.data.items)
        if (this.data.items.length > 0) {
            this.data.items.forEach(el => {
                if (typeof el.id == "undefined") {
                    el.id = el.title
                }
                if (typeof el.language == "undefined") {
                    el.language = [
                        {
                        "Code": "trTR",
                        "Title": el.title
                        }
                    ]
                }
                console.log(el)
            })
            this.data.items.forEach(el => {
                let find = el.language.find(f => f.Code.toLowerCase() == this.lang.toLowerCase())
                if (typeof find != "undefined" && typeof find.Title != "undefined" && find.Title != "") {
                    el.title = find.Title
                } else {
                    el.title = el.id
                }
            })
        }
    }


    tabSelectionChanged(event) {
        this.eventEmitterService.menuTabComponentChange(null);
        this.eventEmitterService.closeTextAreaMicEvent(null);
    }

    onEditPopopShown() {
        this.tempDataItems = this.data.items
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (!this.data.hide && this.data.outsideVisible ? "block" : "none") : "block";
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }


    checkUserAuthorization() {
        
        for (let i = 0; i < this.data.items.length; i++) {
            let userId = this.appInfo.currentUser.id;
            let rGroupIndex = this.data.items[i].permissions.readonlyGroup.map(m => m.users.indexOf(userId) > -1).findIndex(x => x);
            let rUsersIndex = this.data.items[i].permissions.readonlyUsers.indexOf(userId);
            let hGroupIndex = this.data.items[i].permissions.hideGroup.map(m => m.users.indexOf(userId) > -1).findIndex(x => x);
            let hUsersIndex = this.data.items[i].permissions.hideUsers.indexOf(userId);

            let isHide = hGroupIndex != -1 || hUsersIndex != -1;
            let isReadonly = rGroupIndex != -1 || rUsersIndex != -1;

            if (isHide) this.data.items[i].hide = true;
            else this.data.items[i].hide = false;
            if (isReadonly) this.data.items[i].readonly = true;
            else this.data.items[i].readonly = false;
        }
    }

    itemClick(e, item, list) {
        if (e.itemData.action == "edit") {
            this.eventEmitterService.showPopop(item.uuid);
            //item.showEditPop = true;
        }
        else if (e.itemData.action == "delete") {
            let msg = item.type == "grid" || item.type == "panel" ? "<i>" + this.languageLocal.theseComponentElementsDeleted + "</i>" : "<i>" + this.languageLocal.messageSure + "</i>"
            let result = confirm(msg, this.languageLocal.warning);
            result.then((dialogResult) => {
                if (dialogResult) this.removeField(item, list);
            });
        }
    }


    dragStart() {
        this.onGridDragClass = "active";
    }


    onDragEnded(event: CdkDragEnd): void {

        //event.source._dragRef.reset();
    }
    cdkGridDrop(event: CdkDragDrop<string[]>, list?: any[]) {

        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            let type = event.previousContainer.element.nativeElement.dataset.type;
            if (type && type == "copy") {
                let field: any = event.previousContainer.data[event.previousIndex];
                if (field.type != "tabs") {
                    localStorage.setItem("currentFieldTabs", JSON.stringify(event.previousContainer.data[event.previousIndex]));
                    let currentFieldTab: any = JSON.parse(localStorage.getItem("currentFieldTabs"));
                    localStorage.removeItem("currentFieldTabs");
                    //localStorage.removeItem(key);localStorage.clear();
                    let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
                    currentFieldTab.id = this.componentService.createId(currentFieldTab.type, currentFormSelected.components, this.anotherPopupId);
                    list.splice(event.currentIndex, 0, currentFieldTab);
                }
                else {
                    this.componentService.showAlert(this.languageLocal.invalidTarget, "warning", 300);
                }

                /*copyArrayItem(event.previousContainer.data,
                  event.container.data,
                  event.previousIndex,
                  event.currentIndex);*/
            }
            else {
                transferArrayItem(event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex);
            }
        }
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        this.onGridDragClass = "";
    }

    removeField(item, list) {
        list.splice(list.indexOf(item), 1);
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        this.componentService.showAlert(this.languageLocal.deleted, "success", 300);
    }

    // tempDataItems : In case of tab order not saved do nothing
    tempDataItems = []
    onReorder(e) {
        try {

            let temp = this.data.items[e.fromIndex]

            this.tempDataItems = this.data.items;

            this.tempDataItems.splice(e.fromIndex, 1);

            this.tempDataItems.splice(e.toIndex, 0, temp);

        } catch (err) {
            console.log(err)
        }

    }

    onSaveTabs() {
        this.panelPopup = false
        let data = JSON.parse(JSON.stringify(this.data.items))
        this.data.items = data

        if (this.data.items.length > 0) {
            this.data.items.forEach(el => {
                if (typeof el.id == "undefined") {
                    el.id = el.title
                }
                if (typeof el.language == "undefined") {
                    el.language = [
                        {
                            "Code": "trTR",
                            "Title": el.title
                        }
                    ]
                }
                console.log(el)
            })
            this.data.items.forEach(el => {
                let find = el.language.find(f => f.Code.toLowerCase() == this.lang.toLowerCase())
                if (typeof find != "undefined" && typeof find.Title != "undefined" && find.Title != "") {
                    el.title = find.Title
                } else {
                    el.title = el.id
                }
            })
        }

    }

    addPanel() {
        //debugger
        var panel = {
            id: "Tab" + " " + (this.data.items.length + 1),
            title: "Tab" + " " + (this.data.items.length + 1),
            permissions: {
                "readonlyGroup": [],
                "readonlyUsers": [],
                "hideGroup": [],
                "hideUsers": []
            },
            language: [{
                "Code": "trTR",
                "Title": "Tabs"
            }],
            components: []
        }

        /*if (panel.id != 0) {
            let updateIndex = this.data.title.findIndex(f => f.id == panel.id)
            if (typeof updateIndex != "undefined") {
                this.data.items[updateIndex] = panel
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        } else {
            if (this.data.items.length == 0)
                panel.id = 1
            else if (this.data.items.length > 0)
                panel.id = this.data.items[this.data.items.length - 1].id + 1

            this.data.items.push(panel);
            console.log(panel)
            this.componentService.createCdkList(this.anotherPopupId);
        }*/

        let data = JSON.parse(JSON.stringify(this.data.items))
        data.push(panel);
        this.data.items = data;
        this.componentService.createCdkList(this.anotherPopupId);

        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }

    }


    deletePanel(id) {
        //debugger;
        let index = this.data.items.findIndex(el => el.id == id)
        this.data.items.splice(index, 1);
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }


    addPermission(panel) {
        this.selectedPanelPermission = panel.permissions;
        this.panelPermissionPopup = true;
    }
    addLanguage(panel) {
        this.selectedPanelLanguage = panel.language;
        this.languagePopup = true;
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // T�m charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    saveLanguagePopup() {
       // debugger;
        this.data.items.forEach(el => {
            let find = el.language.find(f => f.Code.toLowerCase() == this.lang.toLowerCase())
            if (typeof find != "undefined" && typeof find.Title != "undefined" && find.Title != "") {
                el.title = find.Title
            } else {
                el.title = el.id
            }
        })
        this.languagePopup = false;
    }

    openAddTabPopup() {
     //S   debugger;
        this.panelPopup = true
        if (this.data.items.length > 0) {
            this.data.items.forEach(el => {
                if (typeof el.id == "undefined") {
                    el.id = el.title
                }
                if (typeof el.language == "undefined") {
                    el.language = [
                        {
                            "Code": "trTR",
                            "Title": el.title
                        }
                    ]
                }
                console.log(el)
            })
            this.data.items.forEach(el => {
                let find = el.language.find(f => f.Code.toLowerCase() == this.lang.toLowerCase())
                if (typeof find != "undefined" && typeof find.Title != "undefined" && find.Title != "") {
                    el.title = find.Title
                } else {
                    el.title = el.id
                }
            })
        }
    }

}
