import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { Subscription } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GenericService } from '../../shared/services';

@Component({
    selector: 'number-component',
    templateUrl: './number.component.html',
    styleUrls: ['./number.component.css']

})
export class DxNumberComponent implements ComponentParameters, OnDestroy {
    @Input() data: any;
    @Input() lang: any;
    @Input() isEdit: any;
    @Input() disabled: boolean;
    @Input() anotherPopupId: number = 0;

    showDisplay: any = "flex";
    showDisplayBoolean = false;

    dataTypes = [{ "name": "Ondalıklı Sayı", "format": "#,##0.00", "type": "decimal" }, { "name": "Rakam", "format": "#", "type": "int" }];
 //   leftOrRight = [{ "value": "false", "name": "Sola Hizala" }, { "value": "true", "name": "Sağa Hizala" }];

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    isFormatDisabled: boolean = false;
    languageLocal: any = "";
    invokeChangeEventLocal: Subscription;
    constructor(private eventEmitterService: EventEmitterService,
        public changeDetectorRef: ChangeDetectorRef,
        private genericService: GenericService,
        public componentService: ComponentService) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
        this.languageLocal = this.componentService.getLanguageSource(); //language
        this.setLanguageToDatas()
        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {
            if (sub.elementId == this.data.id) {
                if (!this.data.isUserChange) {
                    sub.data = sub.data == "" ? 0 : sub.data;
                    if (sub.data != null && typeof sub.data != "undefined") {
                        // this.data.inputType    format        dataTypes    .format=="#"  else    "format"
                        if (this.data.format == "#")
                            this.data.value = parseInt(sub.data);
                        else
                            this.data.value = parseFloat(sub.data);
                    }
                    else if (sub.value == "clean_element") this.data.value = "";
                    try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                }
            }
        });
    }



    validationCallback(e) {
        let bool = true;
        if (typeof e.value == "undefined" || e.value == null || (e.value == 0)) {
            bool = false;
        }
        return bool;
    }

    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
    }
    ngOnInit() {
       

        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (typeof this.data.show === "undefined") this.data.show = true;
        if (typeof this.data.suffix === "undefined") this.data.suffix = "";
        if (typeof this.data.prefix === "undefined") this.data.prefix = "";
        if (typeof this.data.width === "undefined" || this.data.width == "") this.data.width = "100%";

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });

        this.componentService.datagridColumnHasUserChanges[this.data.id] = typeof this.componentService.datagridColumnHasUserChanges[this.data.id] == 'undefined' ? false : this.componentService.datagridColumnHasUserChanges[this.data.id];

        //if (typeof this.componentService.datagridColumnHasUserChanges[this.data.id] == "undefined") {
        //    this.hasUserChange = false;
        //}
        //else {
        //    this.hasUserChange = this.componentService.datagridColumnHasUserChanges[this.data.id];
        //}

        this.data.min = typeof this.data.min == "undefined" ? null : this.data.min;
        this.data.max = typeof this.data.max == "undefined" ? null : this.data.max;
        this.data.showMinMaxInfo = typeof this.data.showMinMaxInfo == "undefined" ? false : this.data.showMinMaxInfo;
         this.data.align = typeof this.data.align == "undefined" ? false : this.data.align;
        this.data.value = this.data.value == "" ? 0 : this.data.value;
        this.data.isUserChange = typeof this.data.isUserChange == "undefined" ? false : this.data.isUserChange;
        //console.log(this.data.isUserChange)
        //  this.data.format = this.data.format == "#0" || this.data.format == "#0" ? "#,##0" : this.data.format;

    }
    getNumberContainerStyle() {
        let myStyles = {
            'width': (this.data.width && typeof this.data.width != "undefined" && this.data.width != null) ? (this.data.width +( this.componentService.isInteger(this.data.width ) ? "px" : "")) : "100%",
            'display': 'table'
        };
        return myStyles;
    }
    getNumberStyle() {
        //width: 100% !important; display: table-cell !important; border-right: 0px;
        let myStyles = {
            'width': "100% !important",
            'display': "table-cell", //    display: table-cell; 
        };

        if (this.data.suffix && this.data.suffix.length > 0) {
            myStyles["border-right"] = "0px";
            myStyles["border-top-right-radius"] = "0px";
            myStyles["border-bottom-right-radius"] = "0px";
        }

        if (this.data.prefix && this.data.prefix.length > 0) {
            myStyles["border-left"] = "0px";
            myStyles["border-top-left-radius"] = "0px";
            myStyles["border-bottom-left-radius"] = "0px";
        }

        return myStyles;
    }
    disableElements() {
        return typeof this.data.disabled != "undefined" ? (this.data.disabled == false ? this.data.outsideDisabled : this.data.disabled) : false;
    }
    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    onNumberValueChanged(e) {
        //debugger;
        if (this.data.upperCaseLetter) { this.data.value = this.data.value.toUpperCase(); }
        this.eventEmitterService.onValueChangeEvent(this.data.id, "clean_element", e.value, null);
        // this.eventEmitterService.executeExpretion(this.anotherPopupId);

        if (typeof this.data.functionScripts != "undefined") {

            let galleryArray = [];

            let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
            let curentComponents = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);

            let iFuncScr = 0;
            this.data.functionScripts.forEach(f => {
                iFuncScr = iFuncScr + 1;
                let fCode = f.code.replace(/[\u200B-\u200D\uFEFF]/g, '');
                fCode = this.replaceAll(fCode, "{" + this.data.id + "}", this.data.value);
                curentComponents.forEach(c => {
                    if (c.id != this.data.id) {
                        let cId = "{" + c.id + "}";
                        fCode = this.replaceAll(fCode, cId, c.value);
                    }
                });

                let fCompm = curentComponents.find(x => x.id == f.elementId);
                if (fCompm) {
                    this.jsScriptExecuteResult(fCompm, fCode, galleryArray, f);
                }
                else if (this.componentService.tempDatagridPopupComponent.components.length > 0) {

                    //let hasUserChangeService = this.componentService.datagridColumnHasUserChanges[this.data.id];
                    //if (typeof hasUserChangeService != 'undefined') {
                    //    this.hasUserChange = hasUserChangeService;
                    //    delete this.componentService.datagridColumnHasUserChanges[this.data.id];
                    //}


                    // if (this.hasUserChange == false) {
                    if (this.componentService.datagridColumnHasUserChanges[f.elementId] == false) {
                        let _selectElement = this.componentService.tempDatagridPopupComponent.components.find(x => x.id == f.elementId);
                        fCode = f.code.replace(/[\u200B-\u200D\uFEFF]/g, '');
                        fCode = this.replaceAll(fCode, "{" + this.data.id + "}", this.data.value);
                        this.componentService.tempDatagridPopupComponent.components.forEach(c => {
                            if (c.id != this.data.id) {
                                let cId = "{" + c.id + "}";
                                fCode = this.replaceAll(fCode, cId, c.value);
                            }
                        });
                        if (_selectElement && _selectElement.type) {
                            this.jsScriptExecuteResult(_selectElement, fCode, galleryArray, f);
                        }
                    }
                    else {

                        //setTimeout(() => { this.componentService.datagridColumnHasUserChanges[f.elementId] = false; }, 3000);

                        //if (typeof this.componentService.datagridColumnHasUserChanges[this.data.id] == 'undefined')
                        //    this.hasUserChange = false;
                        //else if (this.data.functionScripts.length == iFuncScr)
                        //    this.hasUserChange = false;
                    }
                }

            });
            // try { delete this.componentService.datagridColumnHasUserChanges[this.data.id]; } catch (ex) { }

            galleryArray.forEach(f => {
                this.eventEmitterService.galleryChangeEvent(this.data.id, f.element, f.values);
            });

        }

        this.isUserChangeCheck();
    }

    hasUserChange = false;
    tempValue = undefined

    onKeydown(e) {
        //this.hasUserChange = true;
        this.tempValue = JSON.parse(JSON.stringify(this.data.value))
        this.componentService.datagridColumnHasUserChanges[this.data.id] = true;


    }

    jsScriptExecuteResult(fCompm, fCode, galleryArray, f) {
        if (fCompm.type == "gallery") {

            if (fCode.indexOf('"[') > -1 || fCode.indexOf("'[") > -1) {
                try {
                    let result = eval(fCode);

                    let galArrayObj = galleryArray.find(l => l.element == f.elementId);
                    if (galArrayObj) {
                        galArrayObj.values.push({ value: result, column: f.column });
                    }
                    else {
                        let resVal = { element: f.elementId, values: [] };
                        resVal.values.push({ value: result, column: f.column });
                        galleryArray.push(resVal);
                    }
                } catch (ex) { }

            }

        }
        else {
            try {
                let result = eval(fCode);
                this.eventEmitterService.selectboxChangeEvent(this.data.id, f.elementId, result, result);
            } catch (ex) { }
        }
    }

    /*onChange() {
        this.eventEmitterService.executeExpretion();
    }*/



    classFormComponent = "col-md-12";
    classFormElements = "col-md-6 hidden-element";

    formComponentsScripts: any;
    formElementsScripts: any;

    showFunctionSettingPopup: any = false;
    isMonacoFuncParentLoaded: any = false;
    functionScript = { code: "", elementId: "", column: "" };
    functionScriptIndex = -1;
    openFunctionSettingPopup() {
        //this.functionScript.id = 0;
        this.functionScriptIndex = -1;
        this.functionScript.code = "";
        this.functionScript.elementId = "";
        this.functionScript.column = "";
        this.formElementsScripts = [];

        this.isMonacoFuncLoaded = true;
        this.showFunctionSettingPopup = true;

        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        this.formComponentsScripts = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId).map(m => m.id);


        setTimeout(() => { this.isMonacoFuncParentLoaded = true; }, 1000);
    }
    saveFuncParam() {
        if (this.functionScript.code != "" && this.functionScript.elementId != "") {
            let functionScriptModel = this.componentService.cloneModel(this.functionScript);
            if (typeof this.data.functionScripts == "undefined") this.data.functionScripts = [];

            if (this.functionScriptIndex == -1) {
                this.data.functionScripts.push(functionScriptModel);
            }
            else {
                let dataFunctionScript = this.data.functionScripts[this.functionScriptIndex];
                dataFunctionScript.code = functionScriptModel.code;
                dataFunctionScript.elementId = functionScriptModel.elementId;
                dataFunctionScript.column = functionScriptModel.column;
            }
            this.functionScriptIndex = -1;
            this.functionScript.code = "";
            this.functionScript.elementId = "";
            this.functionScript.column = "";
            this.formElementsScripts = [];
        }
    }
    updateFunctionScript(index) {
        this.functionScriptIndex = index;
        let dataFunctionScript = this.data.functionScripts[this.functionScriptIndex];
        this.functionScript.code = dataFunctionScript.code;
        this.functionScript.elementId = dataFunctionScript.elementId;
        this.functionScript.column = dataFunctionScript.column;
    }
    deleteFunctionScript(index) {
        this.data.functionScripts.splice(index, 1);
    }
    isMonacoFuncLoaded: any = false;
    monacoFunctionOnInit(e) {

        this.isMonacoFuncLoaded = false;
        //let monaco = window['monaco'];
        //this.setCustomKeywords(monaco);
    }

    colShorting(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.data.functionScripts, event.previousIndex, event.currentIndex);
    }

    onChangeformElementsScriptsValue(e) {
        if (e.value != "") {
            let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
            let fCompms = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
            let fCompm = fCompms.find(x => x.id == e.value);
            if (fCompm.type == "gallery") {

                // = this.setFormElementsScripts(fCompm);// fCompm.storage.sqlHideColumns.map(m => m.name);
                this.setFormElementsScripts(fCompm);
                this.classFormComponent = "col-md-6";
                this.classFormElements = "col-md-6";
            }
            else {
                this.classFormComponent = "col-md-12";
                this.classFormElements = "col-md-6 hidden-element";
            }
        }
    }
    setFormElementsScripts(dataGallery) {
        let _return = [];
        if (dataGallery.storage.query != "" && dataGallery.storage.connectionStringId > 0) {
            this.genericService.executeQuerySecurited(dataGallery.storage.query, dataGallery.storage.connectionStringId).subscribe((result: any) => {
                if (result && result.length > 0) {

                    let allColumnsSql = Object.keys(result[0]);
                    let keysSearch = [];
                    keysSearch = allColumnsSql.filter(f =>
                        f != dataGallery.storage.keyColumn);

                    keysSearch.forEach(item => {
                        this.formElementsScripts.push(item);
                    });

                }

            });
        }

    }

    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace).replace(/[\u200B-\u200D\uFEFF]/g, '');
    }

    onValueChanged(event) {
        this.isFormatDisabled = event.value == "#";
        if (this.isFormatDisabled) this.data.inputType = "int";
        else this.data.inputType = "decimal";
    }
  /*  onAlignChanged(event) {
        this.data.align = event.value;
        console.log(event.value);
    }*/
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // Tüm charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }

    isUserChangeCheck() {
        if (this.data.value == "") {     // UserChange sıfırlanmak istenirse
            //this.data.isUserChange = false;
            //this.tempValue = undefined;
        } else {
            if (typeof this.tempValue != "undefined" && this.tempValue != this.data.value) {
                this.data.isUserChange = true;
            }
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        }
    }
    setLanguageToDatas() {
        this.dataTypes = [
            { "name": this.languageLocal.decimal /*"Ondalıklı Sayı"*/, "format": "#,##0.00", "type": "decimal" },
            { "name": this.languageLocal.integer /*"Tam sayı"*/, "format": "#", "type": "int" }
        ];
       // this.leftOrRight = [{ "value": "false", "name": this.languageLocal.alignLeft }, { "value": "true", "name": this.languageLocal.alignRight }];

    }

}
