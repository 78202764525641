import { Component, OnInit, Input, ElementRef, ChangeDetectorRef, } from '@angular/core';
import { EventEmitterService, GenericService, AppInfoService, ReportService } from '../../shared/services';
import { ComponentService } from '../component.service';

import { confirm } from 'devextreme/ui/dialog';

@Component({
    selector: 'printdesigner-component',
    templateUrl: './printdesigner.component.html',
    styleUrls: ['./printdesigner.component.css']
})
export class DxPrintDesignerComponent implements OnInit {
   
    @Input() data: any;
    @Input() isEdit: any;
    @Input() lang: string;
    @Input() anotherPopupId: number = 0;

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    showDisplayBoolean: boolean = false;
    showDisplay: any = "flex";
    systemInfoPage: any;

    reportList: any[] = []
    sizeTypes = ["%", "px"];
    languageLocal: any = "";

    tempReportIdValue = null; // Rapor seçimi değiştirildiğinde mevcut viewer'ı kapatıp yeniden init etmek için kullanılıyor.
    languageCodes = [];

    constructor(public appInfo: AppInfoService,
        private element: ElementRef,
        private eventEmitterService: EventEmitterService,
        private componentService: ComponentService,
        private genericService: GenericService,
        public changeDetectorRef: ChangeDetectorRef,
        public reportService: ReportService) {

        this.languageLocal = this.componentService.getLanguageSource(); //language
        this.languageCodes = this.componentService.getlanguageCodes();

        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });

    }

   
    ngOnInit() {
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);
        console.log(this.data);
        this.tempReportIdValue = this.data.reportId
        this.getReports();
    }

    getReports() {
        this.reportService.getReportWithoutCategory().subscribe((result: any) => {
            this.reportList = result;
        });
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    onValueChanged(e) {
        this.tempReportIdValue = null;

        setTimeout(() => {
            this.tempReportIdValue = e.value;
        }, 100)
    }

    saveSetting() {
        const isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);

            /*const systemInfoTemp = this.componentService.currentSystemInfo.find(x => x.id == this.data.id);
            if (systemInfoTemp)
                this.componentService.currentSystemInfo.find(x => x.id == this.data.id).value = this.systemInfoPage.find(x => x.id == this.data.systemInfoId);
            else
                this.componentService.currentSystemInfo.push({ id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) });*/
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // Tüm charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
                let systemInfoTemp = this.componentService.currentSystemInfo.find(x => x.id == this.data.id);
                if (systemInfoTemp)
                    this.componentService.currentSystemInfo.find(x => x.id == this.data.id).value = this.systemInfoPage.find(x => x.id == this.data.systemInfoId);
                else
                    this.componentService.currentSystemInfo.push({ id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) });
                // this.componentService.currentSystemInfo = { id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) };//this.systemInfoPage.find(x => x.id == this.data.systemInfoId);
            }
        });
    }

}
