import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { ComponentService } from '../component.service';
import { EventEmitterService, FormService, AppInfoService } from '../../shared/services';
import { confirm } from 'devextreme/ui/dialog';
import { DxDataGridComponent, DxTreeViewComponent, DxDrawerComponent } from 'devextreme-angular';
import { MenuNode } from '../../shared/models/menu-node-model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-label',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css']
})
export class DxMenuComponent implements ComponentParameters, OnInit {
    @ViewChild("browserDataGrid", { static: false }) browserDataGrid: DxDataGridComponent;
    @ViewChild("menuDrawer", { static: false }) menuDrawer: DxDrawerComponent;

    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;
    @Input() disabled: boolean;
    @Input() anotherPopupId: number = 0;


    formList: any;

    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;

    connections: any = [];

    showTreePopup: boolean = false;
    showItemFormPopup: boolean = false;
    showFormPopup: boolean = false;
    showDataGridPopup: boolean = false;
    showFormRenderPopup: boolean = false;

    nodeData = {
        "id": "",
        "parentId": "",
        "name": "",
        "desc": "",
        "browserName": "Browser",
        "formName": "Form",
        "isForm": false,
        "form": { formId: 0, nodeId: "" },
        "items": []
    }

    selectedParentName: any;
    selectedNode: any = {};
    selectedForm: any = {};
    selectedFormComponents = [];
    selectedDataGridItem: any = {};
    selectedDataGridItemForm: any = {};
    selectedDataGridRowIndex: number = -1;

    menuButtonOptions: any;
    insertButtonOptions: any;

    formNodeOptions: any = [];

    currentFormData = [];
    isFormRenderReadonly: boolean = false;
    formPopupWidth = ["30%", "50%", "80%", "90%", "100%"];
    minPopopWidth = "80%";
    languageLocal: any = "";

    constructor(private spinner: NgxSpinnerService,
        private eventEmitterService: EventEmitterService,
        private appInfo: AppInfoService,
        public formService: FormService,
        private componentService: ComponentService,
        public changeDetectorRef: ChangeDetectorRef) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
        this.languageLocal = this.componentService.getLanguageSource(); //language



    }

    ngOnInit() {
        this.data.uuid = this.componentService.createuuid();
        this.getFormList();

        //if(this.isEdit) this.setDefaultNode();
        this.prepareToolbar();
        this.selectedDataGridItemForm.components = [];

        let userSession = localStorage.getItem("localUserSession");
        let entity = JSON.parse(userSession);
        this.connections = entity.connections;
        console.log(this.lang)
        if (typeof this.data.language == "undefined") {
            this.data.language = {
                "ENEN": "",
                "TRTR": "",
            }
        } else {
            let find = this.data.language.find(f => f.Code.toLowerCase() == this.lang.toLowerCase())
            if (typeof find != "undefined" && typeof find.Title != "undefined" && find.Title != "") {
                this.nodeData.name = find.Title
            }
        }
    }
    changeAllCheckbox(e) {

        //showGridHeader
        this.selectedFormComponents.forEach(field => {
            field.showGridHeader = e.value;
        });
    }
    prepareToolbar() {
        this.menuButtonOptions = {
            icon: 'menu',
            onClick: () => {
                this.menuDrawer.instance.toggle();
            }
        };

        this.insertButtonOptions = {
            icon: 'plus',
            type: "default",
            onClick: () => {
                this.openFormRenderPopop();
            }
        };


    }


    setDefaultNode() {
        let defaultData: any = {};
        defaultData.id = this.componentService.createuuid();
        defaultData.name = this.languageLocal.general;
        defaultData.parentId = "";
        defaultData.desc = this.languageLocal.generalCategory;
        defaultData.isForm = false;
        defaultData.form = {};
        defaultData.items = [];
        this.data.nodes.push(defaultData);
    }

    getFormList() {
        this.formService.getAllForms().subscribe(forms => {
            this.formList = forms;
        });
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }//    datebox
    inputTypeItemList = [
        { id: "string", name: "string" },
        { id: "number", name: "number" },
        { id: "datetime", name: "datetime" }
    ];
    addedSqlColumns = [];
    sqlStringPopupShow = false;
    sqlStringTemp = { name: "", id: "", inputType: "String", sql: "", connectionId: 0 };
    addSqlColumn() {
        this.addedSqlColumns.push({ name: "", id: "", inputType: "String", sql: "", connectionId: 0 });
    }
    sqlStringPopup(_sqlObj) {
        this.sqlStringPopupShow = true;
        this.sqlStringTemp = _sqlObj;
    }
    saveSqlColumnString() {//SELECT USERNAME FROM ETR_USERLIST ID={selectbox1}
        this.sqlStringPopupShow = false;
        let indexObj = this.addedSqlColumns.findIndex(x => x.id == this.sqlStringTemp.id);// = this.sqlStringTemp;
        this.addedSqlColumns[indexObj] = this.sqlStringTemp;
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    openNodeForm(node) {
        this.selectedNode = node;
        this.showItemFormPopup = true;
    }

    formOptionVariables: any = [];
    editNode(node) {
        debugger;
        //this.selectedNode = node;
        this.selectedFormComponents = [];
        this.nodeData = node;
        if (node.isForm) {

            let _formOptionVariable = this.formOptionVariables.find(x => x.formId == node.form.formId);
            if (!_formOptionVariable) {
                this.formService.getMenuForm(node.form.formId, node.form.nodeId, 0, this.appInfo.currentUser.id.toString()).subscribe((result: any) => {
                    if (result) {
                        if (result.menuOptions != null && result.menuOptions != "") {
                            let resultoptions = JSON.parse(result.menuOptions);
                            let variableVisibility = resultoptions.find(y => typeof y["visibility"] != "undefined");
                            variableVisibility = variableVisibility ? variableVisibility["visibility"] : [];

                            let variableSqlColumns = resultoptions.find(y => typeof y["sqlColumns"] != "undefined")//["sqlColumns"];//.find(y => y == );
                            variableSqlColumns = variableSqlColumns ? variableSqlColumns["sqlColumns"] : [];

                            this.formOptionVariables.push({ formId: node.form.formId, nodeId: node.form.nodeId, visibility: variableVisibility, sqlColumns: variableSqlColumns });

                        }
                        this.showFormPopup = true;
                        this.onFormsValueChanged(this.nodeData.form.formId);
                    }
                });
            }
            else {
                this.showFormPopup = true;
                this.onFormsValueChanged(this.nodeData.form.formId);
            }

        }
        else
            this.showItemFormPopup = true;
    }

    openFormPopop(node) {

        this.formOptionVariables = [];

        this.selectedFormComponents = [];
        this.selectedNode = node;
        this.resetNodeData();
        this.showFormPopup = true;
    }

    onFormsValueChanged(id) {

        if (id && id > 0) {

            this.componentService.usedForms.push(id);

            this.formValueSetAll(id);

        }
        else {
            this.selectedFormComponents = [];
        }

    }

    formValueSetAll(id) {

        if (id && id > 0) {

            this.selectedForm = this.formList.find(f => f.id == id);
            let formJson = JSON.parse(this.selectedForm.forM_JSON);
            this.selectedFormComponents = this.componentService.getComponentList(formJson.components, this.anotherPopupId);

            let _variable = this.formOptionVariables.find(y => y.formId == this.nodeData.form.formId && y.nodeId == this.nodeData.form.nodeId);

            let variableVisibility = _variable ? _variable.visibility : null;
            this.addedSqlColumns = _variable ? _variable.sqlColumns : [];
            this.selectedFormComponents.forEach(field => {

                if (field.language)
                    field.name = this.componentService.getLang(this.lang, field.language).Label;

                field.show = true;
                if (variableVisibility && variableVisibility.length > 0) {
                    let _field = variableVisibility.indexOf(field.id);
                    field.showGridHeader = _field != -1 ? true : false;
                }
                else {
                    field.showGridHeader = true;
                }

            });

        }
        else {
            this.selectedFormComponents = [];
        }

    }
    addNode() {
        let clonedNodeData = this.componentService.cloneModel(this.nodeData);
        if (this.selectedNode) {
            if (clonedNodeData.id && clonedNodeData.id.length > 0) {
                let node = this.selectedNode.items.find(f => f.id == clonedNodeData.id);
                if (node) {
                    node.name = clonedNodeData.name;
                    node.desc = clonedNodeData.desc;
                }
            }
            else {
                clonedNodeData.id = this.componentService.createuuid();
                this.selectedNode.items.push(clonedNodeData);
            }
        }
        else {
            if (clonedNodeData.id && clonedNodeData.id.length > 0) {
                let node = this.data.nodes.find(f => f.id == clonedNodeData.id);
                node.name = clonedNodeData.name;
                node.desc = clonedNodeData.desc;
            }
            else {
                this.nodeData.id = this.componentService.createuuid();
                clonedNodeData.id = this.componentService.createuuid();
                this.data.nodes.push(clonedNodeData);
            }
        }
        this.refresTree();
        this.showItemFormPopup = false;
        this.resetNodeData();
    }

    saveForm() {
        let isFormEditNow = false;
        let isFormEditWithData = false;
        let clonedNodeData = this.componentService.cloneModel(this.nodeData);

        //let form = this.formList.find(f => f.id == this.selectedFormId);
        if (this.selectedForm && this.selectedForm.id > 0) {

            let node: any = {};

            if (clonedNodeData.id && clonedNodeData.id.length > 0) {
                // node = this.data.nodes.find(f => f.id == clonedNodeData.id); 

                //this.data.nodes.forEach(_item => {
                //    node = _item.items.find(f => f.id == clonedNodeData.id);
                //    if (node) {
                //        this.selectedNode = _item;
                //        return false;
                //    }
                //});

                for (let _item of this.data.nodes) {
                    node = _item.items.find(f => f.id == clonedNodeData.id);
                    if (node) {
                        this.selectedNode = _item;
                        break;
                    }
                }

                if (node && node.id) {
                    isFormEditNow = true;
                    //isFormEditWithData = true;
                }
                else {
                    node = {};
                    node.id = this.componentService.createuuid();
                    this.nodeData.id = node.id;
                    clonedNodeData.id = node.id;
                }
            }
            else {
                node.id = this.componentService.createuuid();
                this.nodeData.id = node.id;
                clonedNodeData.id = node.id;
            }


            node.name = this.nodeData.browserName;
            node.parentId = "";
            node.browserName = this.nodeData.browserName;
            node.formName = this.nodeData.formName;
            node.desc = this.selectedForm.name;
            node.isForm = true;
            node.items = [];

            let gridData = {
                "formId": this.selectedForm.id,
                "formJson": JSON.parse(this.selectedForm.forM_JSON),
                "nodeId": node.id,
                //"fields": [],
                "data": []
            }


            let formVisibility = this.selectedFormComponents.filter(x => x.showGridHeader == true).map(y => y.id);

            this.setFormOption(node.id, "visibility", formVisibility);
            this.setFormOption(node.id, "sqlColumns", this.addedSqlColumns);



            let _variable = this.formOptionVariables.find(y => y.formId == this.nodeData.form.formId && y.nodeId == this.nodeData.form.nodeId);
            if (_variable) {
                _variable.visibility = formVisibility;
                _variable.sqlColumns = this.addedSqlColumns;
            }

            this.componentService.menuFormNodeOptions = this.formNodeOptions;

            node.form = gridData;

            if (!isFormEditNow) {
                //if (isFormEditWithData)
                //    this.data.nodes.push(node);
                //else
                if (this.selectedNode)
                    this.selectedNode.items.push(node);
                else
                    this.data.nodes.push(node);
            }

            //this.data.value.push(gridData);

            this.refresTree();
        }

        this.selectedFormComponents = [];
        this.showFormPopup = false;
        this.resetNodeData();
    }

    setFormOption(nodeId, name, value) {

        let formOptionIndex = this.formNodeOptions.findIndex(x => x.form_id == this.selectedForm.id && x.node_key == nodeId);
        let formOption = this.formNodeOptions.find(x => x.form_id == this.selectedForm.id && x.node_key == nodeId);
        if (formOption) {
            formOption[name] = value;
            this.formNodeOptions[formOptionIndex] = formOption;
        }
        else {
            let formVisibilityOption = { node_key: nodeId, form_id: this.selectedForm.id };
            formVisibilityOption[name] = value;
            this.formNodeOptions.push(formVisibilityOption);
        }

    }
    refresTree() {
        let nodes = this.componentService.cloneModel(this.data.nodes);
        this.data.nodes = [];
        this.data.nodes = nodes;
    }

    resetNodeData() {
        this.nodeData = {
            "id": "",
            "parentId": "",
            "name": "",
            "desc": "",
            "browserName": "Browser",
            "formName": "Form",
            "isForm": false,
            "form": { formId: 0, nodeId: "" },
            "items": []
        }
    }

    deleteNode(id) {
        let msg = "<i>Kaydı silmek istediğinizden emin misiniz?</i>"
        let result = confirm(msg, "Uyarı!");
        result.then((dialogResult) => {
            if (dialogResult) {

                this.removeFromTree(this.data.nodes, id);
                this.refresTree();
            }
        });
    }

    removeFromTree(nodes, id) {
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].id == id) {
                if (nodes[i].isForm == true) {


                    let _usedFormIndex = this.componentService.usedForms.findIndex(x => x == nodes[i].form.formId);
                    this.componentService.usedForms.splice(_usedFormIndex, 1);
                }
                nodes.splice(i, 1);
                return;
            }
            else if (nodes[i].items.length > 0) {
                this.removeFromTree(nodes[i].items, id);
            }
        }
        return;
    }

    isMonacoLoaded: any = false;
    monacoOnInit() {
        this.isMonacoLoaded = true;
    }

    openDataGridPopop(node) {
        this.selectedNode = node;
        this.selectedDataGridItem = node.form;
        //this.componentService.currentForm = node.form.formJson;
        this.componentService.currentForm.menuComponents = node.form.formJson.components;
        this.componentService.checkUserAuthorization(this.componentService.currentForm.isReadonly, node.form.formJson.components, this.appInfo.currentUser.id, this.anotherPopupId)
        node.form.formJson.components = node.form.formJson.components.filter(f => !f.hide);
        this.selectedDataGridItemForm = this.componentService.cloneModel(node.form.formJson);
        // this.selectedDataGridItem = this.data.value.find(f => f.nodeId == node.id);
        try {
            //this.spinner.show("mainspinner");
            this.formService.getMenuForm(node.form.formId, node.form.nodeId, 0, this.appInfo.currentUser.id.toString()).subscribe((result: any) => {
                if (result && result.formId > 0) {
                    this.selectedDataGridItem.data = JSON.parse(result.nodeData);
                    if (this.componentService.currentUserStaus == this.componentService.userStatus.InterimApprove || this.componentService.currentUserStaus == this.componentService.userStatus.FinalApprove) {
                        this.selectedDataGridItem.data.forEach(item => {
                            if (item.IsChecked) {
                                item.Disabled = false;
                            }
                            else {
                                item.Disabled = true;
                            }
                        })
                    }
                    this.applyHeadersToDataGrid();
                }
                else {
                    this.applyHeadersToDataGrid();
                }
                //this.spinner.hide("mainspinner");
            }, err => {
                this.applyHeadersToDataGrid();
                //this.spinner.hide("mainspinner");
            });
        }
        catch (err) {
            console.log(err);
            this.applyHeadersToDataGrid();
            this.spinner.hide("mainspinner");
        }

    }


    applyHeadersToDataGrid() {
        this.browserDataGrid.instance.option('columns', []);
        this.browserDataGrid.instance.addColumn({ caption: "*", dataField: "IsChecked", width: 50, cellTemplate: "checkboxTemplate", allowEditing: false });
        this.browserDataGrid.instance.addColumn({ name: "ID", dataField: "ID", caption: "ID", dataType: "number", width: 50, alignment: 'left', allowEditing: false });

        let componentlist = this.componentService.getComponentList(this.selectedDataGridItemForm.components, this.anotherPopupId);
        componentlist.forEach(header => {
            //type: "button"
            //this.selectedDataGridItem.fields.forEach(header => {

            if ((header.show || typeof header.show === "undefined") && typeof header.showGridHeader) {
                if (header.type == "button") {

                }
                else if (header.type == "grid") {
                    //  this.gridGetDatagridHeader();
                }
                else if (header.type == "number") {
                    this.browserDataGrid.instance.addColumn({ name: header.id, dataField: header.id, caption: header.name, dataType: "number", width: 150, alignment: 'left', allowEditing: false });
                }
                else if (header.type == "datebox") {
                    this.browserDataGrid.instance.addColumn({ name: header.id, dataField: header.id, caption: header.name, dataType: "date", width: 150, format: header.displayFormat, allowEditing: false });
                }
                else {
                    this.browserDataGrid.instance.addColumn({ name: header.id, dataField: header.id, caption: header.name, width: 150, allowEditing: false });
                }
            }
        });
        this.browserDataGrid.instance.addColumn({ caption: "*", width: 50, cellTemplate: "cloneButton", allowEditing: false });
        this.browserDataGrid.instance.addColumn({ caption: "**", width: 120, cellTemplate: "actionsButton", allowEditing: false });
        this.browserDataGrid.instance.addColumn({ caption: "", allowEditing: false });
        this.browserDataGrid.instance.refresh();
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        this.showDataGridPopup = true;
    }

    cloneRow(e) {

        let Id = this.selectedDataGridItem.data.length == 0 ? 1 : (this.selectedDataGridItem.data[this.selectedDataGridItem.data.length - 1].ID + 1);
        var clonedItem = Object.assign({}, e.row.data, { ID: Id });
        clonedItem.IsChecked = false;
        clonedItem.Disabled = false;
        this.selectedDataGridItem.data.splice(this.selectedDataGridItem.data.length, 0, clonedItem);
        this.saveMenuTemp(JSON.stringify(clonedItem));
    }


    openFormRenderPopop() {

        this.selectedDataGridRowIndex = -1;
        this.selectedDataGridItemForm = this.componentService.cloneModel(this.selectedDataGridItem.formJson);
        this.selectedDataGridItemForm.components = this.selectedDataGridItemForm.components.filter(f => f.type != "button");
        this.isFormRenderReadonly = false;
        this.showFormRenderPopup = true;
        if (!this.isEdit) {
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        }
    }

    saveFormData(args) {
        if (!args.validationGroup.validate().isValid) {
            return;
        }

        this.currentFormData = [];
        this.createValuesData(this.selectedDataGridItemForm.components);
        this.addDataToGrid();
    }

    createValuesData(components) {
        for (let i = 0; i < components.length; i++) {
            if (components[i].isInput == true || components[i].inputType == "datagrid"
                || components[i].inputType == "file"
                || components[i].type == "gallery"
                || components[i].type == "codeeditor"
                || components[i].type == "htmleditor"
                || components[i].type == "comment") {
                var obj: any = {};
                obj["key"] = components[i].id;
                obj["value"] = components[i].value;
                obj["type"] = components[i].inputType;
                obj["componentType"] = components[i].type;

                obj["isAuto"] = components[i].isAuto;
                obj["autoText"] = components[i].autoText;

                if (components[i].type == "button")
                    obj["name"] = components[i].nameValue;

                if (components[i].inputType == "datagrid") {

                    var gridVal = components[i].value;
                    var lastId = gridVal.length > 0 ? gridVal[gridVal.length - 1].ID : 0;
                    obj["lastid"] = lastId;
                    var headerAndType = this.getGridHeaders(components[i]);
                    obj["headers"] = headerAndType.headers;
                    obj["headerTypes"] = headerAndType.headerTypes;
                    obj["rowCount"] = components[i].value.length;
                    obj["selectedRowCount"] = components[i].selectedRows;
                    obj["userChangeColumns"] = components[i].userChangeColumns;
                }
                else {
                    obj["headers"] = "";
                    obj["rowCount"] = 0;
                    obj["selectedRowCount"] = 0;
                    obj["userChangeColumns"] = [];
                }
                this.currentFormData.push(obj);
            }
            else if (components[i].type == "grid") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                        this.createValuesData(components[i].rows[j].cols[k].components);
                    }
                }
            }
            else if (components[i].type == "panel" || components[i].type == "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    this.createValuesData(components[i].items[j].components);
                }
            }
        }
    }

    getGridHeaders(grid) {

        let headers = [];
        let headerTypes = [];
        grid.headers.forEach(header => {
            var obj: any = {};
            //if (header.type != "file") {
                headers.push(header.field);
                headerTypes.push(header.type);
            //}
        });
        return { headers: headers.join(","), headerTypes: headerTypes.join(",") };
    }


    addDataToGrid() {
        let newData: any = {};
        for (let i = 0; i < this.currentFormData.length; i++) {
            let field = this.currentFormData[i];
            newData[field.key] = field.value;
        }

        if (this.selectedDataGridRowIndex >= 0) {
            newData.ID = this.selectedDataGridItem.data[this.selectedDataGridRowIndex].ID;
            newData.IsChecked = this.selectedDataGridItem.data[this.selectedDataGridRowIndex].IsChecked;
            newData.Disabled = this.selectedDataGridItem.data[this.selectedDataGridRowIndex].Disabled;
            this.selectedDataGridItem.data.splice(this.selectedDataGridRowIndex, 1, newData);
        }

        else {
            newData.ID = this.selectedDataGridItem.data.length == 0 ? 1 : (this.selectedDataGridItem.data[this.selectedDataGridItem.data.length - 1].ID + 1);
            newData.IsChecked = false;
            newData.Disabled = false;
            this.selectedDataGridItem.data.push(newData);
        }


        this.selectedDataGridRowIndex = -1;
        this.showFormRenderPopup = false;

        //let menuNode = new MenuNode();
        //menuNode.FormId = this.selectedDataGridItem.formId;
        //menuNode.NodeKey = this.selectedDataGridItem.nodeId;
        //menuNode.NodeData = JSON.stringify(newData);

        this.saveMenuTemp(JSON.stringify(newData));
    }


    //selectDataByEdit: any;
    //editPopupOpen(cellInfo, isReadonly) { 

    //    this.selectDataByEdit = cellInfo;
    //    this.selectedDataGridRowIndex = cellInfo.rowIndex;
    //    this.isFormRenderReadonly = isReadonly;
    //    this.showFormRenderPopup = true; 
    //}

    editData(cellInfo, isReadonly) {

        let components = this.componentService.getComponentList(this.selectedDataGridItemForm.components, this.anotherPopupId);
        for (let i = 0; i < components.length; i++) {
            if (cellInfo.data[components[i].id]) {
                components[i].value = cellInfo.data[components[i].id];
            }
        }

        this.selectedDataGridRowIndex = cellInfo.rowIndex;
        this.isFormRenderReadonly = isReadonly;
        this.showFormRenderPopup = true;
        //  this.saveMenuTemp(cellInfo);
    }

    removeRow(row) {
        let msg = "<i>" + this.languageLocal.removeRow+"</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {

                //this.saveMenuTemp();deleteMenuTemp
                this.selectedDataGridRowIndex = row.rowIndex;
                let menuNode = new MenuNode();
                menuNode.FormId = this.selectedDataGridItem.formId;
                menuNode.NodeKey = this.selectedDataGridItem.nodeId;
                menuNode.NodeData = JSON.stringify(row.data);

                this.formService.deleteMenuForm(menuNode, row.rowIndex).subscribe((result: any) => {

                    this.selectedDataGridItem.data.splice(row.rowIndex, 1);
                    // var clonedItem = Object.assign({}, row.data, { ID: this.selectedDataGridItem.data.length + 1 });
                });

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // Tüm charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    submitMenu(status) {
        //this.eventEmitterService.subsMenuSubmit = undefined;
        //let isAD = status == this.componentService.formStatus.Approve || status == this.componentService.formStatus.Disapprove;
        //this.data.value = [];
        //if (isAD) {
        //    this.selectedDataGridItem.data.forEach(item => {
        //        if (item.IsChecked) {
        //            item.Disabled = false;
        //            this.data.value.push(item);
        //        }
        //    });
        //}
        //else {
        //    this.selectedDataGridItem.data.forEach(item => {
        //        if (item.IsChecked && !item.Disabled) {
        //            item.Disabled = true;
        //            this.data.value.push(item);
        //        }
        //    });
        //}

        //if (this.data.value.length > 0) {

        //    let msg = "<i> " + this.data.value.length + " adet kayıt gönderilecek. Emin misiniz?</i>"
        //    let result = confirm(msg, "Uyarı!");
        //    result.then((dialogResult) => {
        //        if (dialogResult) {
        //            this.showDataGridPopup = false;

        //            let menuNode = new MenuNode();
        //            menuNode.FormId = this.selectedDataGridItem.formId;
        //            menuNode.NodeKey = this.selectedDataGridItem.nodeId;
        //            menuNode.NodeData = JSON.stringify(this.data.value);
        //            this.formService.checkMenuForm(menuNode).subscribe((result: any) => {
        //                
        //            });

        //            //  this.saveMenuTemp(null);
        //            this.eventEmitterService.submitMenu(status);
        //        }
        //    });
        //}
        //else {
        //    this.componentService.showAlert("Lütfen göndereceğiniz kayıtları seçiniz", "warning", 2000);
        //}
    }
    addLanguage() {
        this.languagePopup = true;
        console.log(this.lang)
    }

    saveLanguagePopup() {
        debugger;
        this.languagePopup = false;
        let find = this.data.language.find(f => f.Code.toLowerCase() == this.lang.toLowerCase())
        if (typeof find != "undefined" && typeof find.Title != "undefined" && find.Title != "") {
            this.nodeData.name= find.Title
        }
    }

    saveMenuTemp(rowData) {

        this.componentService.menuFormNodeOptions = this.formNodeOptions;

        let menuNode = new MenuNode();
        menuNode.FormId = this.selectedDataGridItem.formId;
        menuNode.NodeKey = this.selectedDataGridItem.nodeId;
        menuNode.NodeData = rowData;
        this.formService.saveMenuForm(menuNode).subscribe((result: any) => {

        });
    }
}
