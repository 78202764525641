/*/*/// <reference path="../shared/services/event-emitter.service.ts" />*/*/

import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import * as _ from "lodash";
import notify from "devextreme/ui/notify";
import { Subject } from 'rxjs';
import { EventEmitterService } from '../shared/services/event-emitter.service';


@Injectable({
    providedIn: 'root'
})
export class ComponentService {

    isSentFormToDb: boolean = false;

    userHasApprove: boolean = false;
    currentUserStaus: number = 0;
    currentProcessFormId: number = 0;
    currentInstanceId: number = 0;


    selectedSchemaId: number = 0;

    tempDatagridPopupComponent = { components: [] };

    runtimeSelectboxDatasource = [];

    datagridColumnHasUserChanges = {};

    isNewSchemaPopup: any = null;


    AnotherPopup: any = {


        schemaId: 0,
        keyword: "",
        start1: null,
        start2: null,
        end1: null,
        end2: null,
        status: 0,
        userId: 0
    }

    menuSaveFinish: any = true;

    schemaId: number = 0;

    datagridFilePicturebox = [];

    languageSourceObject: any;
    languageSource: any = {};

    elementsOutOfDatagrid: any = [];

    taskListFilter: any = {
        schemaId: 0,
        keyword: "",

        start1: null,
        start2: null,
        end1: null,
        end2: null,
        status: 0,
        userId: 0
    };

    systemInfoElements: any = [
        { id: 0, text: "Seçiniz", value: "" },
        { id: 1, text: "Başlatan Adı", value: "{|STARTED-USER-NAME|}" },
        { id: 2, text: "Başlatan ID", value: "{|STARTED-USER-ID|}" },
        { id: 3, text: "Başlama Tarihi", value: "{|START-DATE|}" },
        { id: 4, text: "Süreç Numarası", value: "{|PROCESS-INSTANCE-ID|}" },
        { id: 5, text: "Form ID", value: "{|FORM-ID|}" }
    ];

    languageCodes = [
        {
            "code": "trTR",
            "name": "Türkçe",
        },
        {
            "code": "enEN",
            "name": "English",
        },
        {
            "code": "deDE",
            "name": "Deutsche",
        },
        {
            "code": "esES",
            "name": "Español",
        },
        {
            "code": "frFR",
            "name": "French",
        },
        {
            "code": "caES",
            "name": "Catalan",
        },
        {
            "code": "roRO",
            "name": "Romanian",
        },//Alttakiler bileşenlerde vardı. Veritabanı langSource tablomuzda karşılığı yok. Kullanıldıysa sorun olmasın diye kaldırılmadı.
        {
            "code": "srRS",
            "name": "Српски",
        },
        {
            "code": "zhhansCN",
            "name": "中文",
        },
    ]

    countryFlags: any = [
        { Code: "TRTR", Key: "tr-TR" },
        { Code: "ENEN", Key: "en-EN" },
        { Code: "DEDE", Key: "de-DE" },
        { Code: "ESES", Key: "es-ES" },
        { Code: "FRFR", Key: "fr-FR" },
        { Code: "CAES", Key: "ca-ES" },
        { Code: "RORO", Key: "ro-RO" }
    ];

    currentSystemInfo: any = [];//{ id: 0, text: "", value: "" }

    isMenuForm: any = false;
    usedForms: any = [];
    menuFormNodeOptions: any = [];
    historyActions = { save: 1, undo: 2, redo: 3 };
    currentForm: any = {
        formId: 0,
        categoryId: 0,
        formNo: 1,
        isReadonly: false,
        user: '',
        name: '',
        variableName: '',
        isGeneric: false,
        description: '',
        permissions: '',
        expression: '',
        components: [],
        data: [],
        preferences: {
            formOpenedEvents: [], formOpenedMultiElementEvents: [],

        },
        language: [
            { "Code": "trTR", "Title": "Form Adı" }
        ],

    };

    currentForm_POPUP1: any = {
        formId: 0,
        categoryId: 0,
        formNo: 1,
        isReadonly: false,
        user: '',
        name: '',
        variableName: '',
        isGeneric: false,
        description: '',
        permissions: '',
        expression: '',
        components: [],
        data: [],
        language: [
            { "Code": "trTR", "Title": "Form Adı" }
        ],
    };

    childPopupObj = {
        id: 0,
        currentForm: this.currentForm_POPUP1,
        currentProcessFormId: 0,
        currentInstanceId: 0,
        selectedSchemaId: 0,
        isNewSchemaPopup: null,
        isSentFormToDb: false
    };

    childPopups = [];
    childPopupsClosed = [];


    languageSourceChange: Subject<any> = new Subject<any>();
    setLanguageSource(_langSource) {
        //this.languageSource = _langSource;
        this.languageSourceChange.next(_langSource);
    }

    getLanguageSource() {
        return this.languageSource;
    }

    //cdkListIds: any;
    formStatus =
        {
            Processing: 1, //while yes
            SaveAndSend: 2, //while break
            Approve: 3, // while break
            Disapprove: 4, //while break
            Returned: 5, //while yes
            Cancelled: 6, // while break
            DisapproveTerminate: 7, // Terminate WF
            Completed: 8
        }

    userStatus = {
        DefaultStatus: 0,
        InterimApprove: 1,
        FinalApprove: 2,
        Completed: 3,
    }

    //datagridFilterOutType = {
    //    SetElement: 0,
    //    SetFilter: 1 
    //}

    cdkListIds = ['cdkListItems', 'cdkListMain'];
    currentFormComponents: any;
    languageCode = "enEN";

    componentList = [
        {
            "group": "Temel",
            "components": [
                {
                    "component": "DxLabelComponent",
                    "uuid": "",
                    "id": "",
                    "name": "Label",
                    "type": "label",
                    "isInput": false,
                    "inputType": "",
                    "value": "Label",
                    "fontSize": "12",
                    "fontStyle": "normal",
                    "fontWeight": "normal",
                    "color": "#000000",
                    "underline": false,
                    "showEditPop": false,
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Etiket Yazısı"
                        }
                    ]
                },
                {
                    "component": "DxTextboxComponent",
                    "uuid": "",
                    "name": "Text Box",
                    "labelpossition": "top",
                    "id": "",
                    "type": "textbox",
                    "isInput": true,
                    "inputType": "string",
                    "showClearButton": true,
                    "value": "",
                    "required": false,
                    "showEditPop": false,
                    "width": "",
                    "maxLength": 500,
                    "isAuto": false,
                    "autoText": "",
                    "systemInfoId": 0,
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Metin Kutusu",
                            "Placeholder": "Text",
                            "Hint": "Metin Kutusu"
                        }
                    ]
                },
                {
                    "component": "DxPasswordComponent",
                    "uuid": "",
                    "name": "Password",
                    "id": "",
                    "type": "password",
                    "isInput": true,
                    "inputType": "string",
                    "showClearButton": true,
                    "showEditPop": false,
                    "value": "",
                    "required": false,
                    "width": "",
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Password",
                            "Placeholder": "Password",
                            "Hint": "Password"
                        }
                    ]
                },
                {
                    "component": "DxNumberComponent",
                    "uuid": "",
                    "name": "Number",
                    "id": "",
                    "type": "number",
                    "isInput": true,
                    "inputType": "decimal",
                    "format": "#,##0.00",
                    "showSpinButtons": false,
                    "showClearButton": false,
                    "showEditPop": false,
                    "showMinMaxInfo": false,
                    "min": null,
                    "max": null,
                    "value": 0,
                    "required": false,
                    "width": "",
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Sayı",
                            "Placeholder": "Sayı",
                            "Hint": "Sayı"
                        }
                    ]
                },
                {
                    "component": "DxPhoneComponent",
                    "uuid": "",
                    "name": "Phone",
                    "id": "",
                    "type": "phone",
                    "isInput": true,
                    "width": "",
                    "inputType": "string",
                    "showClearButton": true,
                    "mask": "+90 (000) 000-0000",
                    "value": "",
                    "required": false,
                    "showEditPop": false,
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Telefon",
                            "Placeholder": "Telefon",
                            "Hint": "Telefon"
                        }
                    ]
                },
                {
                    "component": "DxEmailComponent",
                    "name": "Email",
                    "id": "",
                    "type": "email",
                    "isInput": true,
                    "inputType": "string",
                    "showClearButton": true,
                    "value": "",
                    "required": false,
                    "showEditPop": false,
                    "width": "",
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Email",
                            "Placeholder": "Email",
                            "Hint": "Email"
                        }
                    ]
                },
                {
                    "component": "DxTextareaComponent",
                    "uuid": "",
                    "name": "Text Area",
                    "id": "",
                    "type": "textarea",
                    "isInput": true,
                    "inputType": "string",
                    "showClearButton": true,
                    "maxLength": 500,
                    "value": "",
                    "required": false,
                    "showEditPop": false,
                    "width": "",
                    "height": 90,
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Metin",
                            "Placeholder": "Text",
                            "Hint": "Metin"
                        }
                    ]
                },
                {
                    "component": "DxSelectboxComponent",
                    "uuid": "",
                    "name": "Select Box",
                    "id": "",
                    "type": "selectbox",
                    "isInput": true,
                    "inputType": "string",
                    "value": "",
                    "width": "",
                    "required": false,
                    "showClearButton": true,
                    "isManual": false,
                    "valueExpr": "value",
                    "displayExpr": "text",
                    "showEditPop": false,
                    "validations": [],
                    "sqlScripts": [],
                    "dataStorage": {
                        "connectionId": 0,
                        "query": ""
                    },
                    "dataSourceKey": "",
                    "dataSource": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Seçim",
                            "Placeholder": "Seçiniz",
                            "Hint": "Seçim"
                        }
                    ]
                },
                {
                    "component": "DxDatagridSelectComponent",
                    "uuid": "",
                    "name": "Lookup",
                    "id": "",
                    "type": "selectbox",
                    "isInput": true,
                    "inputType": "string",
                    "value": "",
                    "width": "",
                    "required": false,
                    "showClearButton": true,
                    "isManual": false,
                    "valueExpr": "value",
                    "displayExpr": "text",
                    "showEditPop": false,
                    "validations": [],
                    "sqlScripts": [],
                    "dataStorage": {
                        "connectionId": 0,
                        "query": ""
                    },
                    "dataSourceKey": "",
                    "dataSource": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Seçim",
                            "Placeholder": "Seçiniz",
                            "Hint": "Seçim"
                        }
                    ]
                },
                {
                    "component": "DxDateboxComponent",
                    "uuid": "",
                    "name": "Date Box",
                    "id": "",
                    "type": "datebox",
                    "isInput": true,
                    "inputType": "datetime",
                    "datetype": "date",
                    "displayFormat": "dd/MM/yyyy HH:mm",
                    "width": "",
                    "showEditPop": false,
                    "value": null,
                    "required": false,
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Tarih",
                            "Placeholder": "...",
                            "Hint": "Tarih"
                        }
                    ]
                },
                {
                    "component": "DxCheckboxComponent",
                    "uuid": "",
                    "name": "Check Box",
                    "id": "",
                    "type": "checkbox",
                    "isInput": true,
                    "inputType": "bool",
                    "width": 130,
                    "showEditPop": false,
                    "value": false,
                    "required": false,
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Check Box",
                            "Hint": "Check Box"
                        }
                    ]
                },
                {
                    "component": "DxRadiogroupComponent",
                    "uuid": "",
                    "name": "Radio Group",
                    "id": "",
                    "type": "radiogroup",
                    "isInput": true,
                    "inputType": "string",
                    "label": "Radio Group",
                    "showEditPop": false,
                    "layout": "horizontal",
                    "value": "",
                    "required": false,
                    "width": "",
                    "items": [
                        {
                            "text": "Radio Button 1",
                            "language": [
                                {
                                    "Code": "trTR",
                                    "Title": ""
                                }
                            ],
                        }
                    ],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Radio Group",
                            "Hint": "Radio Group"
                        }
                    ]
                },
                {
                    "component": "DxButtonComponent",
                    "uuid": "",
                    "name": "Buttons",
                    "nameValue": "",
                    "id": "",
                    "type": "button",
                    "label": "Button",
                    "value": 1,
                    "isInput": true,
                    "inputType": "int",
                    "style": "default",
                    "showEditPop": false,
                    "width": "",
                    "buttons": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Buton",
                            "Hint": "Buton"
                        }
                    ]
                },
                {
                    "component": "DxMultiSelectBoxComponent",
                    "uuid": "",
                    "name": "MultiSelectBox",
                    "id": "",
                    "type": "multiselectbox",
                    "isInput": true,
                    "inputType": "string",
                    "value": "",
                    "width": "",
                    "required": false,
                    "showClearButton": true,
                    "isManual": false,
                    "valueExpr": "value",
                    "displayExpr": "text",
                    "showEditPop": false,
                    "validations": [],
                    "sqlScripts": [],
                    "dataStorage": {
                        "connectionId": 0,
                        "query": ""
                    },
                    "dataSourceKey": "",
                    "dataSource": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Seçim",
                            "Placeholder": "Seçiniz",
                            "Hint": "Seçim"
                        }
                    ]
                },

                //,
                //{
                //    "component": "DxPaymentComponent",
                //    "uuid": "",
                //    "name": "Payment",
                //    "labelpossition": "top",
                //    "id": "",
                //    "type": "payment",
                //    "isInput": true,
                //    "inputType": "string",
                //    "showClearButton": true,
                //    "value": "",
                //    "required": false,
                //    "showEditPop": false,
                //    "width": "",
                //    "maxLength": 500,
                //    "isAuto": false,
                //    "autoText": "",
                //    "systemInfoId": 0,
                //    "permissions": {
                //        "readonlyGroup": [],
                //        "readonlyUsers": [],
                //        "hideGroup": [],
                //        "hideUsers": []
                //    },
                //    "language": [
                //        {
                //            "Code": "trTR",
                //            "Name": "Adı Soyadı",
                //            "CardNo": "Kart Numarası",
                //            "Expires": "Tarih", 
                //            "CvvCvc": "CVV / CVC"
                //        }
                //    ]
                //}
            ]
        },
        {
            "group": "Özel",
            "components": [
                {
                    "component": "DxMapComponent",
                    "uuid": "",
                    "name": "Map",
                    "id": "",
                    "type": "map",
                    "isInput": false,
                    "inputType": "map",
                    "showEditPop": false,
                    "required": false,
                    "draggable": true,
                    "userChangeDraggable": false,
                    "isAddMultipleMarker": true,
                    "maxMarkerLimit": 0,
                    "value": [],
                    "markers": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Harita",
                            "Hint": "Harita"
                        }
                    ]
                },
                {
                    "component": "DxLiveComponent",
                    "uuid": "",
                    "name": "Live Stream",
                    "labelpossition": "top",
                    "id": "",
                    "type": "livestream",
                    "isInput": true,
                    "inputType": "string",
                    "value": "",
                    "showEditPop": false,
                    "width": "",
                    "maxLength": 500,
                    "systemInfoId": 0,
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Canlı Yayın"
                        }
                    ]
                },
                {
                    "component": "DxSignatureComponent",
                    "uuid": "",
                    "id": "",
                    "name": "Signature",
                    "type": "signature",
                    "isInput": false,
                    "inputType": "file",
                    "showEditPop": false,
                    "required": false,
                    "maxFileSize": 5,
                    "value": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Elektronik Imza"
                        }
                    ]
                },
                {
                    "component": "DxPdfViewerComponent",
                    "uuid": "",
                    "id": "",
                    "name": "PdfViewer",
                    "type": "pdfviewer",
                    "width": "100%",
                    "height": "100px",
                    "isInput": false,
                    "inputType": "file",
                    "showEditPop": false,
                    "required": false,
                    "maxFileSize": 5,
                    "value": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Pdf Göster"
                        }
                    ]
                },
                {
                    "component": "DxCommentComponent",
                    "uuid": "",
                    "name": "Comment",
                    "id": "",
                    "type": "comment",
                    "isInput": false,
                    "inputType": "comment",
                    "value": [],
                    "width": "30",
                    "height": "400",
                    "required": false,
                    "showClearButton": true,
                    "isManual": false,
                    "showEditPop": false,
                    "validations": [],
                    "sizeTypeWidth": "%",
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Seçim",
                            "Placeholder": "Seçiniz",
                            "Hint": "Seçim"
                        }
                    ]
                },

            ]
        },


        {
            "group": "Data",
            "components": [
                {
                    "component": "DxDataGridComponent",
                    "uuid": "",
                    "name": "Data Grid",
                    "id": "",
                    "type": "datagrid",
                    "isInput": false,
                    "inputType": "datagrid",
                    "label": "Data Grid",
                    "text": "Data Grid",
                    "showEditPop": true,
                    "headers": [],

                    "dataSource": [],
                    "value": [],
                    "keyExpr": "ID",
                    "summaryCol": "",
                    "selectedRows": [],
                    "sqlExpressions": [],
                    "colorExpressions": [],
                    "total": 0,
                    "filter": {
                        "users": [],
                        "field": "",
                        "operator": "",
                        "value": 0,
                    },
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Data Grid",
                            "Hint": "Data Grid"
                        }
                    ]
                },
                {
                    "component": "DxDataListComponent",
                    "uuid": "",
                    "name": "DataList",
                    "id": "",
                    "type": "datalist",
                    "isInput": true,
                    "inputType": "string",
                    "value": "",
                    "width": "",
                    "required": false,
                    "showClearButton": true,
                    "isManual": false,
                    "valueExpr": "value",
                    "displayExpr": "text",
                    "showEditPop": false,
                    "validations": [],
                    "sqlScripts": [],
                    "dataStorage": {
                        "connectionId": 0,
                        "query": ""
                    },
                    "dataSourceKey": "",
                    "dataSource": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    }
                    ,
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "DataList",
                            "Placeholder": "DataList",
                            "Hint": "DataList"
                        }
                    ]
                },
                {
                    "component": "DxDatasourceComponent",
                    "uuid": "",
                    "name": "Data Source",
                    "id": "",
                    "type": "datasource",
                    "isInput": false,
                    "inputType": "",
                    "value": "",
                    "valueExpr": "",
                    "showEditPop": false,
                    "validations": [],
                    "query": "",
                    "connectionString": "",
                    "dataSource": [],
                },
                {
                    "component": "DxFormResultComponent",
                    "uuid": "",
                    "name": "Kolon Toplam",
                    "id": "",
                    "type": "number",
                    "isInput": true,
                    "inputType": "decimal",
                    "format": "#,##0.00",
                    "possition": "right",
                    "showEditPop": false,
                    "value": 0,
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Toplam",
                            "Placeholder": "Toplam",
                            "Hint": "Toplam"
                        }
                    ]
                },
                {
                    "component": "DxDisplayComponent",
                    "uuid": "",
                    "name": "Display Text",
                    "labelpossition": "top",
                    "id": "",
                    "type": "display",
                    "isInput": true,
                    "inputType": "string",
                    "width": 0,
                    "showClearButton": true,
                    "value": "Display",
                    "keyExpr": "ID",
                    "index": -1,
                    "showEditPop": false,
                    "showImage": false,
                    "imgSrc": "",
                    "showVideo": false,
                    "imgCol": false,
                    "videoCol": false,
                    "videoSrc": "",
                    "validations": [],
                    "dataSource": [],
                    "dataSourceCol": "",
                    "dataSourceKey": "",
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Display",
                            "Placeholder": "Text",
                            "Hint": "Display"
                        }
                    ]
                },
                {
                    "component": "DxGalleryComponent",
                    "uuid": "",
                    "name": "Galeri",
                    "id": "gallery",
                    "type": "gallery",
                    "isInput": false,
                    "inputType": "gallery",
                    "showEditPop": false,
                    "dashboardId": 0,
                    "headers": "",
                    "isPriceEditable": false,
                    "isVideoGallery": false,
                    "value": [],
                    "category": {
                        "connectionId": 0,
                        "query": "",
                        "valueCol": "",
                        "displayCol": "",
                        "filterType": 1
                    },
                    "storage": {
                        "connectionStringId": 0,
                        "query": "",
                        "keyColumn": "",
                        "categoryColumn": "",
                        "photoColumn": "",
                        "videoColumn": "",
                        "titleColumn": "",
                        "priceColumn": "",
                        "totalColumn": "",
                        "totalColumnElement": "",
                        "imageFormat": "2",
                        "unitConnectionId": 0,
                        "unitQuery": ""
                    },
                    "options": {
                        "layout": 1,
                        "cols": "3",
                        "borderColor": "",
                        "borderSize": "",
                        "infoPossition": "bottom",
                        "imgHeight": 144,
                    },
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "rows": [
                        {
                            "permissions": {
                                "readonlyGroup": [],
                                "readonlyUsers": [],
                                "hideGroup": [],
                                "hideUsers": []
                            },
                            "cols": [
                                {
                                    "size": 6,
                                    "components": []
                                },
                                {
                                    "size": 6,
                                    "components": []
                                }
                            ]
                        }
                    ]
                },
                {
                    "component": "DxDashboardComponent",
                    "uuid": "",
                    "name": "Dashboard",
                    "id": "",
                    "type": "dashboard",
                    "isInput": false,
                    "inputType": "dashboard",
                    "label": "Dashboard",
                    "text": "Dashboard",
                    "showEditPop": true,
                    "dashboardId": 0,
                    "components": [],
                    "sizeTypeWidth": "%",
                    "sizes": {
                        "width": "100",
                        "height": "300",
                    },
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Dashboard",
                            "Placeholder": "Dashboard seçiniz",
                            "Hint": "Dashboard"
                        }
                    ]
                },
                {
                    "component": "DxPrintDesignerComponent",
                    "uuid": "",
                    "name": "Print Designer",
                    "id": "",
                    "type": "printdesigner",
                    "isInput": false,
                    "inputType": "printdesigner",
                    "label": "PrintDesigner",
                    "text": "PrintDesigner",
                    "showEditPop": true,
                    "sizeTypeWidth": "%",
                    "sizes": {
                        "width": "100",
                        "height": "500",
                    },
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Beyaz Sayfa Raporu",
                            "Placeholder": "Beyaz Sayfa raporu seçiniz",
                            "Hint": "Beyaz Sayfa Raporu"
                        }
                    ]
                }
            ]
        },
        {
            "group": "Business",
            "components": [
                {
                    "component": "DxSchedulerComponent",
                    "uuid": "",
                    "name": "Scheduler",
                    "id": "",
                    "type": "scheduler",
                    "isInput": false,
                    "inputType": "scheduler",
                    "showEditPop": false,
                    "required": false,
                    "width": 500,
                    "height": 600,
                    "viewTypes": [],
                    "startTime": 8,
                    "endTime": 18,
                    "weekdayColor": "rgba(51, 122, 183, 0.1)",
                    "weekendColor": "rgba(51, 122, 183, 0.2)",
                    "eventColor": "#327dbf",
                    "canUserChange": true,
                    "isChangeDefaultColor": false,
                    "value": [],
                    "menuData": {
                        "menuNodeKey": "",
                        "subMenuNodeKey": "",
                        "menuBrowserNode": ""
                    },
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Planlayıcı",
                            "Hint": "Planlayıcı"
                        }
                    ]
                },
                {
                    "component": "DxKanbanComponent",
                    "uuid": "",
                    "name": "Kanban",
                    "id": "",
                    "type": "kanban",
                    "isInput": true,
                    "inputType": "string",
                    "value": "",
                    "width": "",
                    "height": "500",
                    "required": false,
                    "showClearButton": true,
                    "isManual": false,
                    "valueExpr": "value",
                    "displayExpr": "text",
                    "showEditPop": false,
                    "validations": [],
                    "sqlScripts": [],
                    "dataStorage": {
                        "connectionId": 0,
                        "query": ""
                    },
                    "groupSql": "",
                    "assignedColumnName": "",
                    "groupColumnName": "",
                    "taskPriorityColumnName": "",
                    "taskSubjectColumnName": "",
                    "taskDetailColumnName": "",
                    "dataSourceKey": "",
                    "dataSource": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    }
                    ,
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Kanban",
                            "Placeholder": "Kanban",
                            "Hint": "Kanban"
                        }
                    ]
                },
                {
                    "component": "DxGanttComponent",
                    "uuid": "",
                    "name": "Gantt",
                    "id": "",
                    "type": "gantt",
                    "isInput": true,
                    "inputType": "string",
                    "value": "",
                    "width": "",
                    "required": false,
                    "showClearButton": true,
                    "isManual": false,
                    "valueExpr": "value",
                    "displayExpr": "text",
                    "showEditPop": false,
                    "validations": [],
                    "sqlScripts": [],
                    "dataStorage": {
                        "connectionId": 0,
                        "query": ""
                    },
                    "task": {
                        "connectionId": 0,
                        "query": "",
                        "keyExpr": "",
                        "titleExpr": "",
                        "startExpr": "",
                        "endExpr": "",
                        "parentIdExpr": "",
                        "progressExpr": "",
                        "colorExpr": "",
                    },
                    "dataSourceKey": "",
                    "dataSource": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    }
                    ,
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Gantt",
                            "Placeholder": "Gantt",
                            "Hint": "Gantt"
                        }
                    ]
                },
                {
                    "component": "DxEtrDiagramComponent",
                    "uuid": "",
                    "name": "Diagram",
                    "id": "",
                    "type": "diagram",
                    "isInput": true,
                    "inputType": "string",
                    "value": "",
                    "width": "",
                    "required": false,
                    "isManual": false,
                    "valueExpr": "value",
                    "displayExpr": "text",
                    "showEditPop": false,
                    "validations": [],
                    "sqlScripts": [],
                    "dataStorage": {
                        "connectionId": 0,
                        "query": ""
                    },
                    "customShapes": [],
                    "dataSourceKey": "",
                    "dataSource": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    }
                    ,
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Gantt",
                            "Placeholder": "Gantt",
                            "Hint": "Gantt"
                        }
                    ]
                }
            ]
        },
        {
            "group": "Dosya",
            "components": [
                {
                    "component": "DxImageuploadComponent",
                    "uuid": "",
                    "name": "Image Upload",
                    "id": "",
                    "type": "imageupload",
                    "isInput": false,
                    "inputType": "file",
                    "showEditPop": false,
                    "required": false,
                    "maxFileSize": 5,
                    "value": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Fotoğraf Yükle",
                            "Hint": "Fotoğraf Yükle"
                        }
                    ]
                },
                {
                    "component": "DxPictureBoxComponent",
                    "uuid": "",
                    "name": "Picture Box",
                    "id": "",
                    "type": "picturebox",
                    "isInput": false,
                    "inputType": "file",
                    "showEditPop": false,
                    "required": false,
                    "maxFileSize": 5,
                    "value": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Fotoğraf Yükle",
                            "Hint": "Fotoğraf Yükle"
                        }
                    ]
                },
                {
                    "component": "DxVideouploadComponent",
                    "uuid": "",
                    "name": "Video Upload",
                    "id": "",
                    "type": "videoupload",
                    "isInput": false,
                    "inputType": "file",
                    "showEditPop": false,
                    "value": [],
                    "required": false,
                    "maxFileSize": 10,
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Video Yükle",
                            "Hint": "Video Yükle"
                        }
                    ]
                },
                {
                    "component": "DxFileuploadComponent",
                    "uuid": "",
                    "id": "",
                    "type": "fileupload",
                    "name": "File Upload",
                    "isInput": false,
                    "inputType": "file",
                    "showEditPop": false,
                    "value": [],
                    "files": [],
                    "required": false,
                    "maxFileSize": 5,
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Dosya Yükle",
                            "Hint": "Dosya Yükle"
                        }
                    ]
                }
            ]
        },
        {
            "group": "Layout",
            "components": [
                {
                    "component": "DxMenuComponent",
                    "uuid": "",
                    "name": "Menu",
                    "id": "",
                    "type": "menu",
                    "isInput": false,
                    "inputType": "menu",
                    "label": "Menu",
                    "showEditPop": false,
                    "nodes": [],
                    "value": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        { "Code": "trTR", "Title": "Form Adı" }
                    ],

                },
                {
                    "component": "DxGridComponent",
                    "uuid": "",
                    "name": "Grid",
                    "id": "grid",
                    "type": "grid",
                    "isInput": false,
                    "inputType": "",
                    "showEditPop": false,
                    "options": {
                        "showBorder": true,
                        "borderType": "dashed",
                        "borderColor": "#9f9f9f",
                        "borderSize": "1",
                        "marginTop": "0",
                        "bgColor": "rgba(0, 0, 0, 0)",
                    },
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "rows": [
                        {
                            "permissions": {
                                "readonlyGroup": [],
                                "readonlyUsers": [],
                                "hideGroup": [],
                                "hideUsers": []
                            },
                            "cols": [
                                {
                                    "size": 12,
                                    "components": []
                                }
                            ]
                        }
                    ]
                },
                {
                    "component": "DxAccordionComponent",
                    "uuid": "",
                    "name": "Panel",
                    "id": "accordion",
                    "type": "panel",
                    "isInput": false,
                    "inputType": "",
                    "showEditPop": false,
                    "options": {
                        "bgColor": "rgba(255, 255, 255, 1)",
                        "color": "rgb(0, 0, 0)"
                    },
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "items": [
                        {
                            "title": "Panel 1",
                            "permissions": {
                                "readonlyGroup": [],
                                "readonlyUsers": [],
                                "hideGroup": [],
                                "hideUsers": []
                            },
                            "components": []
                        }
                    ]
                },
                {
                    "component": "DxTabsComponent",
                    "uuid": "",
                    "name": "Tab",
                    "id": "tabs",
                    "type": "tabs",
                    "isInput": false,
                    "inputType": "",
                    "showEditPop": false,
                    "options": {
                        "bgColor": "rgba(255, 255, 255, 1)",
                        "color": "rgb(0, 0, 0)"
                    },
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "items": [
                        {
                            "title": "Tab 1",
                            "permissions": {
                                "readonlyGroup": [],
                                "readonlyUsers": [],
                                "hideGroup": [],
                                "hideUsers": []
                            },
                            "components": []
                        }
                    ],
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Tabs"
                        }
                    ]
                },
                {
                    "component": "DxChildFormComponent",
                    "uuid": "",
                    "name": "Child Form",
                    "labelpossition": "top",
                    "id": "",
                    "type": "childform",
                    "isInput": true,
                    "inputType": "string",
                    "value": "",
                    "showEditPop": false,
                    "width": "",
                    "maxLength": 500,
                    "systemInfoId": 0,
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Form Aç"
                        }
                    ]
                },
            ]
        },
        {
            "group": "Editorler",
            "components": [
                {
                    "component": "DxHtmleditorComponent",
                    "uuid": "",
                    "name": "HTML Editor",
                    "id": "",
                    "type": "htmleditor",
                    "isInput": false,
                    "inputType": "htmleditor",
                    "value": "",
                    "height": "350px",
                    "showEditPop": false,
                    "labels": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                },
                {
                    "component": "DxCodeditorComponent",
                    "uuid": "",
                    "name": "Code Editor",
                    "id": "",
                    "type": "codeditor",
                    "isInput": false,
                    "inputType": "codeeditor",
                    "value": "",
                    "height": "350px",
                    "label": "Kod Editor",
                    "showEditPop": false,
                    "labels": [],
                    "expretion": ""
                }
            ]
        }

    ]

    inputComponents: any = [];
    isCreateditorDict: boolean = false;

    formComponentList: any = [];

    formComponentTempList: any = [];
    schedulerChangeData: any;
    constructor(private eventEmitterService: EventEmitterService) {
        debugger;
        this.languageSourceChange.subscribe((value) => {
            this.languageSource = value
        });
    }

    createuuid() {
        return uuid();
    }

    //changeDisableAndVisible(_sub) {
    //    this.eventEmitterService.ChangeDisableAndVisible(_sub);
    //}
    //for undo and redo
    public cloneModel<T>(oldData: T): T {
        return _.cloneDeep(oldData);
    }

    isIdAvalible(id, uuid, anotherPopupId) {
        let currentFormSelected = this.currentFormSelected(anotherPopupId);
        this.formComponentList = [];
        this.createComponentList(currentFormSelected.components, anotherPopupId);
        let elm = this.formComponentList.find(x => x.id === id && x.uuid != uuid);
        return typeof (elm) == "undefined";
    }

    checkUserAuthorization(isReadonly, components, userId, anotherPopupId) {

        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        for (let component of this.formComponentList) {

            if (component.permissions) {
                let rUser = component.permissions.readonlyUsers.indexOf(userId) !== -1;
                let hUser = component.permissions.hideUsers.indexOf(userId) !== -1;
                let dhUser = component.permissions.deleteHideUsers ? component.permissions.deleteHideUsers.indexOf(userId) !== -1 : false;

                let rGroup: boolean = false;
                let hGroup: boolean = false;
                let dhGroup: boolean = false;
                component.permissions.readonlyGroup.forEach(group => {
                    if (group.users && group.users.indexOf(userId) !== -1) rGroup = true;
                });
                component.permissions.hideGroup.forEach(group => {
                    if (group.users && group.users.indexOf(userId) !== -1) hGroup = true;
                });
                if (component.permissions.deleteHideGroup)
                    component.permissions.deleteHideGroup.forEach(group => {
                        if (group.users && group.users.indexOf(userId) !== -1) dhGroup = true;
                    });

                component.disabled = isReadonly || rUser || rGroup;
                component.hide = hUser || hGroup;
                component.deleteHide = dhUser || dhGroup;
            }
            else {
                component.hide = false;
                component.disabled = false;
                component.deleteHide = false;
            }
        }
    }

    hideComponentList(components, anotherPopupId) {
        let currentFormSelected = this.currentFormSelected(anotherPopupId);
        for (let i = 0; i < components.length; i++) {
            if (components[i].type == "grid") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                        this.hideComponentList(components[i].rows[j].cols[k].components, anotherPopupId);
                    }
                }
            }
            else if (components[i].type == "panel" || components[i].type == "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    this.hideComponentList(components[i].items[j].components, anotherPopupId);
                }
            }
            else if (components[i].type == "menu" && currentFormSelected.menuComponents) {
                for (let m = 0; m < currentFormSelected.menuComponents.length; m++) {
                    this.hideComponentList(currentFormSelected.menuComponents[m], anotherPopupId);
                    if (currentFormSelected.menuComponents[m].type == "grid") {
                        for (let j = 0; j < currentFormSelected.menuComponents[m].rows.length; j++) {
                            for (let k = 0; k < currentFormSelected.menuComponents[m].rows[j].cols.length; k++) {
                                this.hideComponentList(currentFormSelected.menuComponents[m].rows[j].cols[k].components, anotherPopupId);
                            }
                        }
                    }
                    else if (currentFormSelected.menuComponents[m].type == "panel" || currentFormSelected.menuComponents[m].type == "tabs") {
                        for (let j = 0; j < currentFormSelected.menuComponents[m].items.length; j++) {
                            this.hideComponentList(currentFormSelected.menuComponents[m].items[j].components, anotherPopupId);
                        }
                    }
                }
            }
            else {
                if (components[i].hide) {
                    components.splice(i, 1);
                }
            }
        }
    }

    evulateExpretion(expretion) {
        eval(expretion);
    }

    setFormValues(components, formValues, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        for (let i = 0; i < formValues.length; i++) {
            let com = this.formComponentList.find(x => x.id == formValues[i].key);
            if (typeof com != "undefined") {
                com.value = formValues[i].value;
                if (com.isInput) {
                    com.isUserChange = typeof formValues[i].isUserChange == "undefined" ? false : formValues[i].isUserChange;
                }
                if (com.inputType == "datagrid") {
                    if (com.value == "[]") com.value = []
                    if (com.value.length > 0) {
                        com.value = this.fixDataGridHeadersAndValues(com.value, formValues[i].headers, formValues[i].headerTypes)
                    }
                    com.selectedRows = formValues[i].selectedRowCount;
                    com.userChangeColumns = formValues[i].userChangeColumns;
                }
                if (com.inputType == "map") {
                    com.markers = formValues[i].markers;
                }
                if (com.inputType == "datetime") {
                    com.isTodaySelected = typeof com.isTodaySelected == "undefined" ? true : com.isTodaySelected;
                    if (com.isTodaySelected && (typeof com.value == "undefined" || com.value == null || com.value == "")) {
                        com.value = new Date();
                    }
                }

                //if (com.type == "button") { 
                //    com.isLiveWeb = formValues[i].isLiveWeb;
                //    com.liveWeb = formValues[i].liveWeb;
                //}  
            }
        }
    }

    fixDataGridHeadersAndValues(compVal, headers, headerTypes) {
        // Datagrid valuelarının tanımsız gelme sorununu düzeltmek için yazıldı.
        // Aynı zamanda header type için default değer ile ilgili bir sorun yaşanırsa kullanılabilir.
        const headersList = headers.split(',')
        const headersTypeList = headerTypes.split(',')
        if (compVal.length > 0) {
            compVal.forEach(data => {

                for (var i = 0; i < headersList.length; i++) {
                    if (typeof data[headersList[i]] == "undefined") {

                        if (headersTypeList[i] == "file"
                            || headersTypeList[i] == "map"
                            || headersTypeList[i] == "picturebox"
                            || headersTypeList[i] == "fileviewer") {

                            data[headersList[i]] = []
                        } else if (headersTypeList[i] == "int" || headersTypeList[i] == "double") {
                            data[headersList[i]] = 0
                        } else if (headersTypeList[i] == "checkbox") {
                            data[headersList[i]] = false
                        } else if (headersTypeList[i] == "date" || headersTypeList[i] == "datetime") {
                            data[headersList[i]] = null
                        } else {
                            data[headersList[i]] = ""
                        }
                    }

                }
            })
        }


        return compVal
    }

    createCdkList(anotherPopupId) {
        this.cdkListIds = [];
        let grids: any[] = this.getComponentsByType("grid", anotherPopupId);
        let panels: any[] = this.getComponentsByType("panel", anotherPopupId);
        let tabs: any[] = this.getComponentsByType("tabs", anotherPopupId);

        for (let i = 0; i < grids.length; i++) {
            for (let j = 0; j < grids[i].rows.length; j++) {
                for (let k = 0; k < grids[i].rows[j].cols.length; k++) {
                    let gridId = "cdkListGrid" + grids[i].id + "" + j + "" + k;
                    this.cdkListIds.push(gridId);
                }
            }
        }
        for (let i = 0; i < panels.length; i++) {
            for (let j = 0; j < panels[i].items.length; j++) {
                let panelId = "cdkListPanel" + panels[i].id + j;
                this.cdkListIds.push(panelId);
            }
        }
        for (let i = 0; i < tabs.length; i++) {
            for (let j = 0; j < tabs[i].items.length; j++) {
                let tabId = "cdkListTab" + tabs[i].id + j;
                this.cdkListIds.push(tabId);
            }
        }
    }

    setComponent(components, selectBoxId, elementId, key, anotherPopupId) {
        let selectBox = this.getById(selectBoxId, components, anotherPopupId);
        let el = this.getById(elementId, components, anotherPopupId);
        if (selectBox.dataSource) {
            let item = selectBox.dataSource.find(x => x[selectBox.valueExpr] === selectBox.value);
            if (item) el.value = item[key];
        }
    }

    createId(type, components, anotherPopupId) {
        if (components) {
            this.formComponentList = [];
            this.createComponentList(components, anotherPopupId);
            let items = this.formComponentList.filter(x => x.type == type);
            let i = 1;
            let itemId = "";

            for (i = 1; i < (this.formComponentList.length + 100); i++) {
                itemId = type + (items.length + i);
                if (typeof this.formComponentList.find(x => x.id == itemId) == "undefined")
                    break;
            }
            itemId = itemId == "" ? type + (items.length + 1) : itemId;

            return itemId;//type + (items.length + 1);
        }
    }

    getAllComponentList(components, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        let items = this.formComponentList;
        return items;
    }

    getComponentList(components, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        let items = this.formComponentList.filter(x => (x.isInput == true && x.type != "button") || x.type == "datagrid" || x.type == "gallery" || x.type == "picturebox" || x.type == "signature" || x.type == "imageupload" || x.type == "videoupload" || x.type == "fileupload" || x.type == "map" || x.type == "comment");
        return items;
    }

    getComponentListWithButton(components, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        let items = this.formComponentList.filter(x => (x.isInput == true) || x.type == "datagrid" || x.type == "gallery" || x.type == "picturebox" || x.type == "map" || x.type == "signature" || x.type == "comment");
        return items;
    }

    getButtonComponentList(components, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        let items = this.formComponentList.filter(x => (x.isInput == true && x.type == "button"));
        return items;
    }

    getFilesComponentList(components, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        let items = this.formComponentList.filter(x => x.inputType == "file");
        return items;
    }


    getInputComponentList(components, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        let items = this.formComponentList.filter(x => (x.isInput == true && x.type != "button") && x.type != "datagrid" && x.type != "gallery" && x.type != "picturebox" && x.type != "map" && x.type != "signature");
        return items;
    }

    componentListObjectTemp(components) {
        for (let i = 0; i < components.length; i++) {
            this.formComponentTempList.push(components[i]);
            if (components[i].type == "grid") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                        this.componentListObjectTemp(components[i].rows[j].cols[k].components);
                    }
                }
            }
            else if (components[i].type == "panel" || components[i].type == "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    this.componentListObjectTemp(components[i].items[j].components);
                }
            }
        }
    }

    changeComponentListObject(components, changedComponent, changedObject, changedValue) {

        for (let i = 0; i < components.length; i++) {
            if (components[i].type == "grid") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                        this.changeComponentListObject(components[i].rows[j].cols[k].components, changedComponent, changedObject, changedValue);
                    }
                }
            }
            else if (components[i].type == "panel" || components[i].type == "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    this.changeComponentListObject(components[i].items[j].components, changedComponent, changedObject, changedValue);
                }
            }
            else {
                if (components[i].id == changedComponent) {
                    components[i][changedObject] = changedValue;
                    return components;
                }
            }
            //else if (components[i].type == "menu" && currentFormSelected.menuComponents) {
            //    for (let m = 0; m < currentFormSelected.menuComponents.length; m++) {
            //        this.createComponentList(currentFormSelected.menuComponents[m], anotherPopupId);
            //        if (currentFormSelected.menuComponents[m].type == "grid") {
            //            for (let j = 0; j < currentFormSelected.menuComponents[m].rows.length; j++) {
            //                for (let k = 0; k < currentFormSelected.menuComponents[m].rows[j].cols.length; k++) {
            //                    this.createComponentList(currentFormSelected.menuComponents[m].rows[j].cols[k].components, anotherPopupId);
            //                }
            //            }
            //        }
            //        else if (currentFormSelected.menuComponents[m].type == "panel" || currentFormSelected.menuComponents[m].type == "tabs") {
            //            for (let j = 0; j < this.currentForm.menuComponents[m].items.length; j++) {
            //                this.createComponentList(currentFormSelected.menuComponents[m].items[j].components, anotherPopupId);
            //            }
            //        }
            //    }
            //}

        }
        return components;
    }

    createComponentList(components, anotherPopupId) {
        let currentFormSelected = this.currentFormSelected(anotherPopupId);
        for (let i = 0; i < components.length; i++) {
            this.formComponentList.push(components[i]);
            if (components[i].type == "grid") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                        this.createComponentList(components[i].rows[j].cols[k].components, anotherPopupId);
                    }
                }
            }
            else if (components[i].type == "panel" || components[i].type == "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    this.createComponentList(components[i].items[j].components, anotherPopupId);
                }
            }
            else if (components[i].type == "menu" && currentFormSelected.menuComponents) {
                for (let m = 0; m < currentFormSelected.menuComponents.length; m++) {
                    this.createComponentList(currentFormSelected.menuComponents[m], anotherPopupId);
                    if (currentFormSelected.menuComponents[m].type == "grid") {
                        for (let j = 0; j < currentFormSelected.menuComponents[m].rows.length; j++) {
                            for (let k = 0; k < currentFormSelected.menuComponents[m].rows[j].cols.length; k++) {
                                this.createComponentList(currentFormSelected.menuComponents[m].rows[j].cols[k].components, anotherPopupId);
                            }
                        }
                    }
                    else if (currentFormSelected.menuComponents[m].type == "panel" || currentFormSelected.menuComponents[m].type == "tabs") {
                        for (let j = 0; j < this.currentForm.menuComponents[m].items.length; j++) {
                            this.createComponentList(currentFormSelected.menuComponents[m].items[j].components, anotherPopupId);
                        }
                    }
                }
            }
        }
    }

    getInputComponents(components, anotherPopupId) {
        let currentFormSelected = this.currentFormSelected(anotherPopupId);
        const list = [];
        for (let i = 0; i < components.length; i++) {
            if (components[i].isInput === true) {
                const obj = {};
                obj["key"] = components[i].id;
                obj["value"] = components[i].value;
                list.push(obj);
            }
            else if (components[i].type === "map") {
                const obj = {};
                obj["key"] = components[i].id;
                obj["value"] = components[i].value;
                obj["markers"] = components[i].markers;
                list.push(obj);
            }
            else if (components[i].type === "grid") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                        this.getInputComponents(components[i].rows[j].cols[k].components, anotherPopupId);
                    }
                }
            }
            else if (components[i].type === "panel" || components[i].type === "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    this.getInputComponents(components[i].items[j].components, anotherPopupId);
                }
            }
            else if (components[i].type === "menu" && currentFormSelected.menuComponents) {
                for (let k = 0; k < currentFormSelected.menuComponents.length; k++) {
                    this.createComponentList(currentFormSelected.menuComponents[k], anotherPopupId);
                }
            }
        }

        return list;
    }

    getInputComponentsByReturnArray(components, list: any, anotherPopupId) {
        let currentFormSelected = this.currentFormSelected(anotherPopupId);

        for (let i = 0; i < components.length; i++) {
            if (components[i].isInput === true) {
                const obj = {};
                obj["key"] = components[i].id;
                obj["value"] = components[i].value;
                list.push(obj);
            }
            else if (components[i].type === "map") {
                const obj = {};
                obj["key"] = components[i].id;
                obj["value"] = components[i].value;
                obj["markers"] = components[i].markers;
                list.push(obj);
            }
            else if (components[i].type === "grid") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                        this.getInputComponentsByReturnArray(components[i].rows[j].cols[k].components, list, anotherPopupId);
                    }
                }
            }
            else if (components[i].type === "panel" || components[i].type === "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    this.getInputComponentsByReturnArray(components[i].items[j].components, list, anotherPopupId);
                }
            }
            else if (components[i].type === "menu" && currentFormSelected.menuComponents) {
                for (let k = 0; k < currentFormSelected.menuComponents.length; k++) {
                    this.getInputComponentsByReturnArray(currentFormSelected.menuComponents[k], list, anotherPopupId);
                }
            }
        }

        return list;
    }


    getById(id, components, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        return this.formComponentList.find(x => x.id === id) || {};
    }

    getComponentsByType(key, anotherPopupId) {
        let currentFormSelected = this.currentFormSelected(anotherPopupId);
        this.formComponentList = [];
        this.createComponentList(currentFormSelected.components, anotherPopupId);
        return this.formComponentList.filter(f => f.type == key);
    }

    getComponentsByComponentName(name, anotherPopupId) {
        let currentFormSelected = this.currentFormSelected(anotherPopupId);
        this.formComponentList = [];
        this.createComponentList(currentFormSelected.components, anotherPopupId);
        return this.formComponentList.filter(f => f.component == name);
    }

    getFileComponents(anotherPopupId) {
        let currentFormSelected = this.currentFormSelected(anotherPopupId);
        this.formComponentList = [];
        this.createComponentList(currentFormSelected.components, anotherPopupId);
        return this.formComponentList.filter(f => { f.type == "fileupload" || f.type == "imageupload" || f.type == "picturebox" || f.type == "videoupload" || f.type == "signature" });
    }

    getDataSourceByKey(key, anotherPopupId) {
        return this.getById(key, this.currentForm.components, anotherPopupId)
    }

    getLocaleLang(locale) {
        return locale != null && locale != "" && locale.indexOf("-") > -1 ? locale.split("-")[0] : "tr";
    }

    sum(id, columnName, components, anotherPopupId) {
        let grid = this.getById(id, components, anotherPopupId);
        if (grid) {
            let total = 0;
            for (let i = 0; i < grid.dataSource.length; i++) {
                total += parseInt(grid.dataSource[i][columnName]);
            }
            return total;
        }
        return 0;
    }

    getLang(code, languages) {
        try {
            for (let i = 0; i < languages.length; i++) {
                if (languages[i].Code == code) {
                    return languages[i];
                }
            }
        }
        catch {
        }
        return this.getDefLang(languages);
    }

    getDefLang(languages) {
        try { return languages.find(f => f.Code == "trTR"); }
        catch {
        }
    }

    getFile(fileData) {
        return new Promise(resolve => {
            var reader = new FileReader();
            reader.readAsDataURL(fileData);
            reader.onload = (args: any) => {
                if (reader.result) {
                    let fileName = fileData.name;
                    let fileType: string = fileData.type;
                    let base64BlobData: any = args.target.result.split(',').pop();
                    let fileExtension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2);
                    let fileSize = fileData.size;
                    let document: any = {
                        base64BlobData: base64BlobData,
                        name: fileName,
                        fileType: fileType,
                        extension: fileExtension,
                        size: fileSize,
                        isImg: this.isImage(fileExtension)
                    };
                    resolve(document);
                }
            }
        });
    }
    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace).replace(/[\u200B-\u200D\uFEFF]/g, '');
    }
    replaceAllUsingType(query, find, replace, type = null) {
        if (replace != null && typeof replace != "undefined" && typeof replace.getMonth === 'function') {
            replace = replace.getFullYear() + "-" + (replace.getMonth() + 1) + "-" + replace.getDate() + " " + replace.getHours() + ":" + replace.getMinutes();
        }
        query = this.replaceAll(query, find, replace);
        return query;
    }

    isImage(extension) {
        let ext = extension.toLowerCase();
        if (ext == "png"
            || ext == "jpg"
            || ext == "jpeg"
            || ext == "jfif"
            || ext == "gif"
            || ext == "tif"
            || ext == "tiff") return true;
        else return false;
    }

    //code editor functions
    colSum(id, colm, components, anotherPopupId) {
        let dataGridValue = this.getById(id, components, anotherPopupId).value;
        if (dataGridValue) {
            let total = 0;
            for (let j = 0; j < dataGridValue.length; j++) {
                total += dataGridValue[j][colm];
            }
            return total;
        }
    }

    setValue(selectboxId, elementId, key, components, anotherPopupId) {
        let selectBox = this.getById(selectboxId, components, anotherPopupId);
        let el = this.getById(elementId, components, anotherPopupId);
        let item = selectBox.dataSource.find(x => x[selectBox.valueExpr] === selectBox.value);
        el.value = item[key];
    }

    showAlert(message, status, time) {
        notify(message, status, time);
    }

    setColSumToElement(elementId, field, gridData, components) {

        let sum = 0;
        for (let i = 0; i < gridData.length; i++) {
            let gridVal = parseFloat(gridData[i][field]);
            sum += (typeof gridVal === "undefined" || isNaN(gridVal) ? 0 : gridVal);
        }
        return sum;
        /* 
        let elm = this.getById(elementId, components);
        elm.value = sum;*/
    }


    currentFormSelected(anotherPopupId) {
        let currentFormSelected;
        // try {
        if (anotherPopupId == 0) {
            currentFormSelected = this.currentForm;
        } else {

            let childObj = this.childPopups.find(x => x.id == anotherPopupId);
            if (childObj) {
                currentFormSelected = childObj.currentForm;
            }
            else {
                let childClosedObj = this.childPopupsClosed.find(x => x.id == anotherPopupId);

                //console.log("--------------------------------------------------------------");
                //console.log(this.childPopupsClosed);
                //console.log("++++++++++++++++++++");
                //console.log(anotherPopupId); 

                currentFormSelected = childClosedObj.currentForm;
            }

        }
        //}
        //catch (ex) {
        //    console.warn("River Error: ");
        //    console.warn(ex);
        //}
        return currentFormSelected;
    }


    getAllIcons() {
        let _return = [];
        this.allIcons.forEach(_icon => {
            _return.push({ icon: _icon, html: "<i class='" + _icon + "'></i>" });
        });
        return _return;
    }


    isInteger(str) {
        if (typeof str != "undefined" && str != null) {
            str = (str + "").trim();
            if (!str) {
                return false;
            }
            str = str.replace(/^0+/, "") || "0";
            var n = Math.floor(Number(str));
            return String(n) === str && n >= 0;
        }
        else
            return false;
    }

    getlanguageCodes() {
        return this.languageCodes;
    }

    allIcons = [
        "dx-icon dx-icon-add",
        "dx-icon dx-icon-airplane",
        "dx-icon dx-icon-bookmark",
        "dx-icon dx-icon-box",
        "dx-icon dx-icon-car",
        "dx-icon dx-icon-card",
        "dx-icon dx-icon-cart",
        "dx-icon dx-icon-chart",
        "dx-icon dx-icon-check",
        "dx-icon dx-icon-clear",
        "dx-icon dx-icon-clock",
        "dx-icon dx-icon-close",
        "dx-icon dx-icon-coffee",
        "dx-icon dx-icon-comment",
        "dx-icon dx-icon-doc",
        "dx-icon dx-icon-download",
        "dx-icon dx-icon-dragvertical",
        "dx-icon dx-icon-edit",
        "dx-icon dx-icon-event",
        "dx-icon dx-icon-favorites",
        "dx-icon dx-icon-find",
        "dx-icon dx-icon-filter",
        "dx-icon dx-icon-folder",
        "dx-icon dx-icon-activefolder",
        "dx-icon dx-icon-food",
        "dx-icon dx-icon-gift",
        "dx-icon dx-icon-globe",
        "dx-icon dx-icon-group",
        "dx-icon dx-icon-help",
        "dx-icon dx-icon-home",
        "dx-icon dx-icon-image",
        "dx-icon dx-icon-info",
        "dx-icon dx-icon-key",
        "dx-icon dx-icon-like",
        "dx-icon dx-icon-map",
        "dx-icon dx-icon-menu",
        "dx-icon dx-icon-message",
        "dx-icon dx-icon-money",
        "dx-icon dx-icon-music",
        "dx-icon dx-icon-overflow",
        "dx-icon dx-icon-percent",
        "dx-icon dx-icon-photo",
        "dx-icon dx-icon-plus",
        "dx-icon dx-icon-minus",
        "dx-icon dx-icon-preferences",
        "dx-icon dx-icon-product",
        "dx-icon dx-icon-pulldown",
        "dx-icon dx-icon-refresh",
        "dx-icon dx-icon-remove",
        "dx-icon dx-icon-revert",
        "dx-icon dx-icon-runner",
        "dx-icon dx-icon-save",
        "dx-icon dx-icon-search",
        "dx-icon dx-icon-tags",
        "dx-icon dx-icon-tel",
        "dx-icon dx-icon-tips",
        "dx-icon dx-icon-todo",
        "dx-icon dx-icon-toolbox",
        "dx-icon dx-icon-trash",
        "dx-icon dx-icon-user",
        "dx-icon dx-icon-upload",
        "dx-icon dx-icon-floppy",
        "dx-icon dx-icon-arrowleft",
        "dx-icon dx-icon-arrowdown",
        "dx-icon dx-icon-arrowright",
        "dx-icon dx-icon-arrowup",
        "dx-icon dx-icon-spinleft",
        "dx-icon dx-icon-spinprev",
        "dx-icon dx-icon-spinright",
        "dx-icon dx-icon-spinnext",
        "dx-icon dx-icon-spindown",
        "dx-icon dx-icon-spinup",
        "dx-icon dx-icon-chevronleft",
        "dx-icon dx-icon-chevronprev",
        "dx-icon dx-icon-back",
        "dx-icon dx-icon-chevronright",
        "dx-icon dx-icon-chevronnext",
        "dx-icon dx-icon-chevrondown",
        "dx-icon dx-icon-chevronup",
        "dx-icon dx-icon-chevrondoubleleft",
        "dx-icon dx-icon-chevrondoubleright",
        "dx-icon dx-icon-equal",
        "dx-icon dx-icon-notequal",
        "dx-icon dx-icon-less",
        "dx-icon dx-icon-greater",
        "dx-icon dx-icon-lessorequal",
        "dx-icon dx-icon-greaterorequal",
        "dx-icon dx-icon-isblank",
        "dx-icon dx-icon-isnotblank",
        "dx-icon dx-icon-sortup",
        "dx-icon dx-icon-sortdown",
        "dx-icon dx-icon-sortuptext",
        "dx-icon dx-icon-sortdowntext",
        "dx-icon dx-icon-sorted",
        "dx-icon dx-icon-expand",
        "dx-icon dx-icon-collapse",
        "dx-icon dx-icon-columnfield",
        "dx-icon dx-icon-rowfield",
        "dx-icon dx-icon-datafield",
        "dx-icon dx-icon-fields",
        "dx-icon dx-icon-fieldchooser",
        "dx-icon dx-icon-columnchooser",
        "dx-icon dx-icon-pin",
        "dx-icon dx-icon-unpin",
        "dx-icon dx-icon-pinleft",
        "dx-icon dx-icon-pinright",
        "dx-icon dx-icon-contains",
        "dx-icon dx-icon-startswith",
        "dx-icon dx-icon-endswith",
        "dx-icon dx-icon-doesnotcontain",
        "dx-icon dx-icon-range",
        "dx-icon dx-icon-export",
        "dx-icon dx-icon-exportxlsx",
        "dx-icon dx-icon-exportpdf",
        "dx-icon dx-icon-exportselected",
        "dx-icon dx-icon-warning",
        "dx-icon dx-icon-more",
        "dx-icon dx-icon-square",
        "dx-icon dx-icon-clearsquare",
        "dx-icon dx-icon-repeat",
        "dx-icon dx-icon-selectall",
        "dx-icon dx-icon-unselectall",
        "dx-icon dx-icon-print",
        "dx-icon dx-icon-bold",
        "dx-icon dx-icon-italic",
        "dx-icon dx-icon-underline",
        "dx-icon dx-icon-strike",
        "dx-icon dx-icon-indent",
        "dx-icon dx-icon-increaselinespacing",
        "dx-icon dx-icon-font",
        "dx-icon dx-icon-fontsize",
        "dx-icon dx-icon-shrinkfont",
        "dx-icon dx-icon-growfont",
        "dx-icon dx-icon-color",
        "dx-icon dx-icon-background",
        "dx-icon dx-icon-fill",
        "dx-icon dx-icon-palette",
        "dx-icon dx-icon-superscript",
        "dx-icon dx-icon-subscript",
        "dx-icon dx-icon-header",
        "dx-icon dx-icon-blockquote",
        "dx-icon dx-icon-formula",
        "dx-icon dx-icon-codeblock",
        "dx-icon dx-icon-orderedlist",
        "dx-icon dx-icon-bulletlist",
        "dx-icon dx-icon-increaseindent",
        "dx-icon dx-icon-decreaseindent",
        "dx-icon dx-icon-decreaselinespacing",
        "dx-icon dx-icon-alignleft",
        "dx-icon dx-icon-alignright",
        "dx-icon dx-icon-aligncenter",
        "dx-icon dx-icon-alignjustify",
        "dx-icon dx-icon-link",
        "dx-icon dx-icon-video",
        "dx-icon dx-icon-mention",
        "dx-icon dx-icon-variable",
        "dx-icon dx-icon-clearformat",
        "dx-icon dx-icon-fullscreen",
        "dx-icon dx-icon-hierarchy",
        "dx-icon dx-icon-docfile",
        "dx-icon dx-icon-docxfile",
        "dx-icon dx-icon-pdffile",
        "dx-icon dx-icon-pptfile",
        "dx-icon dx-icon-pptxfile",
        "dx-icon dx-icon-rtffile",
        "dx-icon dx-icon-txtfile",
        "dx-icon dx-icon-xlsfile",
        "dx-icon dx-icon-xlsxfile",
        "dx-icon dx-icon-copy",
        "dx-icon dx-icon-cut",
        "dx-icon dx-icon-paste",
        "dx-icon dx-icon-share",
        "dx-icon dx-icon-inactivefolder",
        "dx-icon dx-icon-newfolder",
        "dx-icon dx-icon-movetofolder",
        "dx-icon dx-icon-parentfolder",
        "dx-icon dx-icon-rename",
        "dx-icon dx-icon-detailslayout",
        "dx-icon dx-icon-contentlayout",
        "dx-icon dx-icon-smalliconslayout",
        "dx-icon dx-icon-mediumiconslayout",
        "dx-icon dx-icon-undo",
        "dx-icon dx-icon-redo",
        "dx-icon dx-icon-hidepanel",
        "dx-icon dx-icon-showpanel",
        "dx-icon dx-icon-checklist",
        "dx-icon dx-icon-verticalaligntop",
        "dx-icon dx-icon-verticalaligncenter",
        "dx-icon dx-icon-verticalalignbottom",
        "dx-icon dx-icon-rowproperties",
        "dx-icon dx-icon-columnproperties",
        "dx-icon dx-icon-cellproperties",
        "dx-icon dx-icon-tableproperties",
        "dx-icon dx-icon-splitcells",
        "dx-icon dx-icon-mergecells",
        "dx-icon dx-icon-deleterow",
        "dx-icon dx-icon-deletecolumn",
        "dx-icon dx-icon-insertrowabove",
        "dx-icon dx-icon-insertrowbelow",
        "dx-icon dx-icon-insertcolumnleft",
        "dx-icon dx-icon-insertcolumnright",
        "dx-icon dx-icon-inserttable",
        "dx-icon dx-icon-deletetable",
        //"icon dx-icon-edittableheader",
        //"icon dx-icon-addtableheader",
        //"icon dx-icon-pasteplaintext",
        //"icon dx-icon-importselected",
        //"icon dx-icon-import",
        //"icon dx-icon-textdocument",
        //"icon dx-icon-jpgfile",
        //"icon dx-icon-bmpfile",
        //"icon dx-icon-svgfile",
        //"icon dx-icon-attach",
        //"icon dx-icon-return",
        //"icon dx-icon-indeterminatestate"
    ];


}
