import { Component, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})
export class FooterComponent implements OnInit {
    test: Date = new Date();
    currentApplicationVersion = environment.appVersion;

    constructor() {
        this.test=new Date(2020,4,14);
    }

    ngOnInit() {
        
    }
}
