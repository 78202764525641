import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AppInfoService } from '../app-info.service';

@Injectable({
    providedIn: 'root'
})
export class GenericService {
    sqlParseParameters: any = [];
    constructor(public appInfo: AppInfoService, private http: HttpClient) {

    }
    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace).replace(/[\u200B-\u200D\uFEFF]/g, '');
    }
    cleanSpaces(str, replace) {
        str = this.replaceAll(str, "   ", replace);
        str = this.replaceAll(str, "  ", replace);
        return str;
    }
    cleanNewline(str, replace) {
        str = this.replaceAll(str, "\r\n|\r|\n", replace);
        return str;
    }
    executeQuerySecurited(queryString, connectionStringId/*, currentUserId*/): Observable<string> {
        queryString = this.replaceAll(queryString, "{CURRENTUSERID}", this.appInfo.currentUser.id);
        let parametersAll = this.appInfo.RiverEntity.parameters;
        Object.keys(parametersAll).forEach(f => {
            queryString = this.replaceAll(queryString, f, parametersAll[f]);
        });
        let sqlParse = this.ParseSql(queryString);
        let sqlSpParse = this.ParseSpSql(queryString);
        if ((sqlParse && sqlParse.length > 0) || (sqlSpParse && sqlSpParse.length > 0)) {
            queryString = this.cleanNewline(queryString, " ");
            queryString = this.cleanSpaces(queryString, " ");
            queryString = this.ReplaceSqlParameters(queryString);
        }

        let queryStringEncode = encodeURIComponent(queryString);
        let params = new HttpParams().set("query", queryStringEncode).set("id", connectionStringId)/*.set("userId", currentUserId)*/;
        return this.http.get<string>(this.appInfo.appUrl + "api/generic/executequerysecurited", { params: params });
    }

    executeQueryMenu(arraySqlColumns): Observable<string> {
        let queryStringEncode = encodeURIComponent(JSON.stringify(arraySqlColumns));
        let params = new HttpParams().set("sqlColumns", queryStringEncode)/*.set("userId", currentUserId)*/;
        return this.http.get<string>(this.appInfo.appUrl + "api/generic/executequerymenu", { params: params });
    }

    fillDesignerDataSource(): Observable<any> {
        return this.http.get<any>(this.appInfo.appUrl + "api/generic/filldesignerdatasource");
    }

    //getIPAddress() {
    //    return this.http.get("https://api.ipify.org?format=jsonp");
    //} 

    getlogininit(languageCode, type): Observable<any> {
        return this.http.get<any>(this.appInfo.appUrl + "api/generic/getlogininit?lang=" + languageCode + "&type=" + type);
    }

    getChatUserList(userId): Observable<any> {
        return this.http.get<any>(this.appInfo.appUrl + "api/user/getchatuserlist?userId=" + userId);
    }

    chatMessageSend(chatMessage): Observable<string> {
        return this.http.post<string>(this.appInfo.appUrl + "api/generic/sendmessage", JSON.stringify(chatMessage), this.appInfo.httpOptions)
    }
    chatMessageFileDownload(_id): Observable<string> { 
        return this.http.get<any>(this.appInfo.appUrl + "api/generic/chatmessagefiledownload?id=" + _id);
    }

    chatMessageFileSend(chatMessageFile): Observable<string> {
        //fileData 
        //fileName 
        //fileExtension
        //fileType 
        //fileIsImage 
        //fileSize 
        return this.http.post<string>(this.appInfo.appUrl + "api/generic/sendmessagefile", JSON.stringify(chatMessageFile), this.appInfo.httpOptions)
    }

    chatGroupAddService(chatGroupAdd): Observable<string> {
        return this.http.post<string>(this.appInfo.appUrl + "api/generic/chatgroupadd", JSON.stringify(chatGroupAdd), this.appInfo.httpOptions)
    }

    getMessageWithPage(userId, receiverId, page): Observable<string> {
        return this.http.get<any>(this.appInfo.appUrl + "api/generic/getmessagewithpage?receiverId=" + receiverId + "&page=" + page + "&userId=" + userId)
    }

    sendPassword(pwChangeEmail): Observable<any> {
        return this.http.get<any>(this.appInfo.appUrl + "api/generic/sendpassword?email=" + pwChangeEmail);
    }

    setUserLanguage(userid, lang): Observable<any> {
        return this.http.get<any>(this.appInfo.appUrl + "api/generic/setuserlang?userId=" + userid + "&lang=" + lang);
    }

    ReplaceSqlParameters(queryString) {

        var queryStringClean = queryString;
        let sqlParse = this.ParseSql(queryString);

        var sqlParseParametersLocal = this.sqlParseParameters;
        if (sqlParseParametersLocal.length > 0) {

            for (var i = 0; i < sqlParseParametersLocal.length; i++) {

                if (sqlParseParametersLocal[i].value && sqlParseParametersLocal[i].value != "") {

                    var header = sqlParseParametersLocal[i].header.toLowerCase();
                    var name = sqlParseParametersLocal[i].name.toLowerCase();
                    //if (name.indexOf("||")) {
                    //  let headerSplit = header.split("||");
                    //  let nameSplit = name.split("||");
                    //  for (var j = 0; j < headerSplit.length; j++) {
                    //    let appendReplaceText = "[" + headerSplit[j] + "," + nameSplit[j]  + "]";
                    //    queryStringClean = queryStringClean.toLowerCase().replace(appendReplaceText, sqlParseParametersLocal[i].value); 
                    //  }
                    //}
                    //else {
                    let appendReplaceText = "[" + header + "," + name + "]";
                    queryStringClean = queryStringClean.toLowerCase().replace(appendReplaceText, sqlParseParametersLocal[i].value);
                    if (queryStringClean.indexOf("{[") > -1) {
                        var _type = sqlParseParametersLocal[i].type.toLowerCase();
                        let _appendReplaceText = "[" + header + "," + name + "," + _type + "]";
                        let _value = sqlParseParametersLocal[i].value;
                        if (_type == "date")
                            _value = _value.getFullYear() + "-" + (_value.getMonth() + 1) + "-" + _value.getDate();
                        queryStringClean = queryStringClean.toLowerCase().replace(_appendReplaceText, _value);
                    }
                    //}
                    queryStringClean = this.cleanSpaces(queryStringClean, " ");

                }
                else {
                    queryStringClean = this.SqlParseItemEmpty(sqlParse[i], queryStringClean);
                }

            }

        } else {

            for (var i = 0; i < sqlParse.length; i++) {

                queryStringClean = this.SqlParseItemEmpty(sqlParse[i], queryStringClean);

            }

        }
        queryStringClean = this.replaceAll(queryStringClean, "{", "");
        queryStringClean = this.replaceAll(queryStringClean, "}", "");
        queryStringClean = this.cleanSqlSyntax(queryStringClean);
        return queryStringClean;

    }
    public SqlParseItemEmpty(sqlParseItem, queryString): any {
        var queryStringClean = queryString;

        var replacedSql = sqlParseItem.value;
        var whereControlFalseStr = "";
        if (replacedSql.indexOf(" where ") > -1) {
            whereControlFalseStr = "";
            replacedSql = replacedSql.trim().toLowerCase().replace("where ", "");
            replacedSql = this.cleanSpaces(replacedSql.toLowerCase(), " ");
        }
        else {
            whereControlFalseStr = "";
            if (replacedSql.trim().toLowerCase().startsWith("or "))
                whereControlFalseStr = replacedSql.trim().replace(/^or /ig, '')
            else if (replacedSql.trim().toLowerCase().startsWith("and "))
                whereControlFalseStr = replacedSql.trim().replace(/^and /ig, '')
        }
        queryStringClean = queryStringClean.toLowerCase().replace(replacedSql.toLowerCase(), "");
        var whereOrAnd = this.cleanSpaces(queryStringClean.toLowerCase(), " ");
        if (whereControlFalseStr != "" && (whereOrAnd.indexOf("where or ") == -1 && whereOrAnd.indexOf("where and ") == -1))
            queryStringClean = queryStringClean.toLowerCase().replace(whereControlFalseStr.toLowerCase(), "");

        queryStringClean = this.cleanSqlSyntax(queryStringClean);

        if (queryStringClean.trim().toLowerCase().endsWith(' where'))
            queryStringClean = queryStringClean.trim().slice(0, -5);

        return queryStringClean;
    }
    cleanSqlSyntax(sqlText) {//
        sqlText = this.cleanSqlSyntaxPart(sqlText);
        sqlText = sqlText.toLowerCase().replace(" where or ", " where ").toLowerCase().replace(" where and ", " where ");
        sqlText = this.cleanSqlSyntaxPart(sqlText);
        return sqlText;
    }
    cleanSqlSyntaxPart(sqlText) {
        sqlText = this.cleanSpaces(sqlText.toLowerCase(), " ");
        sqlText = sqlText.replace(" where )", " where ");
        sqlText = this.cleanSpaces(sqlText.toLowerCase(), " ");
        sqlText = sqlText.replace("where ( )", " where ");
        sqlText = this.cleanSpaces(sqlText.toLowerCase(), " ");
        sqlText = sqlText.replace("where ()", " where ");
        sqlText = this.cleanSpaces(sqlText.toLowerCase(), " ");
        sqlText = sqlText.replace(" and ( )", " ");
        sqlText = this.cleanSpaces(sqlText.toLowerCase(), " ");
        sqlText = sqlText.replace(" or ( )", " ");
        sqlText = this.cleanSpaces(sqlText.toLowerCase(), " ");
        sqlText = sqlText.replace(" and ()", " ");
        sqlText = this.cleanSpaces(sqlText.toLowerCase(), " ");
        sqlText = sqlText.replace(" or ()", " ");
        sqlText = this.cleanSpaces(sqlText.toLowerCase(), " ");
        sqlText = sqlText.replace(" where ( or", " where ( ");
        sqlText = this.cleanSpaces(sqlText.toLowerCase(), " ");
        sqlText = sqlText.replace(" where ( and", " where ( ");
        sqlText = this.cleanSpaces(sqlText.toLowerCase(), " ");
        return sqlText;
    }
    public ParseSql(queryString): any {

        var pattern = /( and | or | where |)\{[a-zA-Z0-9 _\[\],'=ıİöÖçÇğĞ()üÜşŞ%]+\}/ig;
        //var res_pattern = queryString.match(pattern);
        var arrayResult = [];
        var patt = new RegExp(pattern);
        if (patt.test(queryString)) {
            //var str = "select * from ETR_USERLIST where {USERNAME=[Kullanıcı Adı,USERNAME]} AND FULLNAME like '%a%'";
            var res_pattern = queryString.match(pattern);//{USERNAME=[Kullanıcı Adı,USERNAME]}
            if (res_pattern && res_pattern.length) {
                for (var i = 0; i < res_pattern.length; i++) {
                    var matchSecond = "";
                    var whereChild = res_pattern[i];
                    // whereChild = whereChild.replace("{", "").replace("}", ""); // USERNAME=[Kullanıcı Adı,USERNAME]

                    var pattern_first = /( and | or | where |)[a-zA-Z0-9_]+(=| = |(\slike\s)|(\sbetween\s))/ig; // USERNAME
                    var pattern_second = /(=|(\slike\s)|(\sbetween\s))[a-zA-Z0-9_, \[\]ıİöÖç'%ÇğĞ()üÜşŞ]+/ig; // =[Kullanıcı Adı,USERNAME] ya da  like [Kullanıcı Adı,USERNAME] ya da  between [Kullanıcı Adı,USERNAME]

                    var whereChildClear = whereChild.replace(/{/ig, '').replace(/}/ig, '');

                    let whereVariable = whereChildClear.match(pattern_second);
                    if (whereVariable) {
                        matchSecond = whereVariable[0];

                        let equalityObject = "=";
                        if (matchSecond.toLowerCase().indexOf(" between '[") > -1 || matchSecond.toLowerCase().indexOf(" between [") > -1 || matchSecond.toLowerCase().indexOf(" between convert") > -1) {
                            equalityObject = "between";
                            matchSecond = matchSecond.toLowerCase().replace(" between ", "");
                        } else if (matchSecond.toLowerCase().indexOf(" like '[") > -1 || matchSecond.toLowerCase().indexOf(" like '%[") > -1 || matchSecond.toLowerCase().indexOf(" like [") > -1) {
                            equalityObject = "like";
                            matchSecond = matchSecond.toLowerCase().replace(" like ", "");
                        }
                        else {
                            equalityObject = "=";
                            matchSecond = matchSecond.toLowerCase().replace("=", "");
                        }
                        //convert(datetime,başlangıç tarihi,bastarih]',103) and convert(datetime,bitiş tarihi,bittarih]',103)
                        let secondHeader = "";
                        let secondValue = "";
                        let valSingleQuotes = false;
                        let valPercentageMark = false;

                        matchSecond = matchSecond.trim().replace(/^\[|\]$/ig, '');
                        if (matchSecond.indexOf("'%[") > -1 && matchSecond.indexOf("]%'") > -1) {
                            matchSecond = matchSecond.trim().replace(/'%\[|\]%'/ig, '');
                            valSingleQuotes = true;
                            valPercentageMark = true;
                        }
                        else if (matchSecond.indexOf("'[") > -1 && matchSecond.indexOf("]'") > -1) {
                            matchSecond = matchSecond.trim().replace(/'\[|\]'/ig, '');
                            valSingleQuotes = true;
                        }

                        if (equalityObject != "between") {

                            var matchSecondSplit = matchSecond.split(",");

                            if (matchSecondSplit.length == 1) {
                                secondHeader = matchSecondSplit[0];
                                secondValue = matchSecondSplit[0];
                            }
                            else {
                                secondHeader = matchSecondSplit[matchSecondSplit.length - 2];
                                secondValue = matchSecondSplit[matchSecondSplit.length - 1];
                            }

                        }
                        else if (equalityObject == "between") {
                            //convert(datetime,başlangıç tarihi,bastarih,103) and convert(datetime,bitiş tarihi,bittarih,103)  // 1 2        4 5 

                            //başlangıç tarihi,bastarih and bitiş tarihi,bittarih   // 0 1.split(" and ")[0]     1.split(" and ")[1] 2

                            //matchSecond
                            var matchSecondBetweenSplit = matchSecond.split(",");
                            if (matchSecond.toLowerCase().indexOf("convert") > -1) {
                                secondHeader = matchSecondBetweenSplit[1] + "||" + matchSecondBetweenSplit[4];
                                secondValue = matchSecondBetweenSplit[2] + "||" + matchSecondBetweenSplit[5];
                            }
                            else {
                                var matchSecondNotConvertSplit = matchSecond.split(",");
                                secondHeader = matchSecondBetweenSplit[0] + "||" + matchSecondBetweenSplit[1].split(" and ")[1];
                                secondValue = matchSecondBetweenSplit[1].split(" and ")[0] + "||" + matchSecondBetweenSplit[2];
                            }

                        }

                        ////"convert(datetime,başlangıç tarihi,bastarih,103) and convert(datetime,bitiş tarihi,bittarih,103)"
                        ////matchSecond
                        //matchSecond.replace("","");




                        //secondHeader = secondHeader.replace("[", "").replace("]", "").replace(/'/g, ""); 
                        //secondHeader = secondHeader.replace("[", "").replace("]", "").replace(/'/g, "");

                        let first = "";
                        first = whereChildClear.match(pattern_first)[0];
                        arrayResult.push({ left: first, rightName: secondHeader, rightvalue: secondValue, SingleQuote: valSingleQuotes, PercentageMark: valPercentageMark, value: whereChild });
                    }

                }
            }

        }

        return arrayResult;

    }
    public ParseSpSql(spString): any {

        var pattern = /exec [a-zA-Z0-9_]* (\{[a-zA-Z0-9 _\[\],'=ıİöÖçÇğĞ()üÜşŞ%]+\},*)*/ig;
        //var res_pattern = queryString.match(pattern);
        var arrayResult = [];
        var patt = new RegExp(pattern);
        if (patt.test(spString)) {
            spString = this.cleanNewline(spString, " ");
            spString = this.cleanSpaces(spString, " ");
            spString = this.replaceAll(spString, " , ", ",");
            spString = this.replaceAll(spString, ", ", ",");
            spString = this.replaceAll(spString, " ,", ",");
            spString = spString.trim();

            //exec spuserlist 5,'ADMIN'
            var patternParameters = /(\{[a-zA-Z0-9 _\[\],'=ıİöÖçÇğĞ()üÜşŞ%]+\},*)/ig
            var res_pattern = spString.match(patternParameters);
            if (res_pattern && res_pattern.length) {
                for (var i = 0; i < res_pattern.length; i++) {
                    let _temp = res_pattern[i];
                    _temp = _temp.replace("{[", "");
                    _temp = _temp.replace("]}", "");
                    let _tempParse = _temp.split(",");
                    arrayResult.push({ header: _tempParse[0], name: _tempParse[1], type: _tempParse[2] });
                }
            }
        }
        return arrayResult;
    }

    getDataTable(queryString, connectionString): Observable<string> {
        let params = new HttpParams().set("query", queryString).set("connectionString", connectionString);
        return this.http.get<string>(this.appInfo.appUrl + "api/generic/getquerydata", { params: params });
    }

    executeQuery(queryString, connectionString): Observable<string> {
        let params = new HttpParams().set("query", queryString).set("connectionString", connectionString);
        return this.http.get<string>(this.appInfo.appUrl + "api/generic/executequery", { params: params });
    }

    getTables(id): Observable<string> {
        let params = new HttpParams().set("connectionStringId", id);
        return this.http.get<string>(this.appInfo.appUrl + "api/generic/gettables", { params: params });
    }

    getTableColumns(tableName, id): Observable<string> {
        let params = new HttpParams().set("tableName", tableName).set("connectionStringId", id);
        return this.http.get<string>(this.appInfo.appUrl + "api/generic/gettablecolumns", { params: params });
    }

    getSpeechToText(audioBase64): Observable<string> {
        return this.http.post<string>(this.appInfo.appUrl + "api/generic/getspeechtotext", JSON.stringify(audioBase64), this.appInfo.httpOptions)
    }

    getConnection(id) {
        let params = new HttpParams().set("id", id);
        return this.http.get(this.appInfo.appUrl + "api/generic/getconnection", { params: params });
    }

    convertToBase64(data) {
        let params = new HttpParams().set("data", data);
        return this.http.get(this.appInfo.appUrl + "api/generic/converttobase64", { params: params });
    }

    errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
}
