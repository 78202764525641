import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { ComponentService } from "../component.service";
import { EventEmitterService } from '../../shared/services';
import { confirm } from 'devextreme/ui/dialog';

//declare var myfunction: any;
import notify from "devextreme/ui/notify";
import { Subscription } from 'rxjs';
@Component({
    selector: 'codeditor-component',
    templateUrl: './codeditor.component.html',
    styleUrls: ['./codeditor.component.css']
})
export class DxCodeditorComponent implements OnInit {
    @Input() form: any;
    @Input() item: any; //item, data yerine item olarak geliyor
    @Input() isEdit: boolean;
    @Input() anotherPopupId: number = 0;

    showDisplay: any = "flex";
    showDisplayBoolean = false;

    invokeChangeEventLocal: Subscription;
    permissionPopup: boolean = false;
    showSettingPopup: boolean = false;
    loadingPopup: boolean = true;
    isConfigured: boolean = false;
    editorOptions = { theme: 'vs-dark', language: 'typescript' };
    inputComponents: any = [];
    themes = [{
        "name": "Dark",
        "code": "vs-dark",
    }, {
        "name": "White",
        "code": "vs",
    }, {
        "name": "Hight Black",
        "code": "hc-black",
    }];
    languages = [{
        "name": "Javascript",
        "code": "javascript",
    }, {
        "name": "Typescript",
        "code": "typescript",
    }, {
        "name": "SQL",
        "code": "sql",
    }];
    languageLocal: any = "";
    constructor(private componentService: ComponentService, public changeDetectorRef: ChangeDetectorRef, private eventEmitterService: EventEmitterService) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.item.uuid) { 
                this.showSettingPopup = true;
            }
        });
        this.languageLocal = this.componentService.getLanguageSource(); //language
        this.setLanguageToDatas()

    }

    ngOnInit() {
        this.item.uuid = this.componentService.createuuid();

        if (typeof this.item.show === "undefined")
            this.item.show = true;

        this.item.outsideDisabled = typeof this.item.outsideDisabled == "undefined" ? false : this.item.outsideDisabled;
        this.item.outsideVisible = typeof this.item.outsideVisible == "undefined" ? true : this.item.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.item.show && !this.item.hide && this.item.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.item.show && !this.item.hide && this.item.outsideVisible ? true : false) : true;

       // this.item.width = typeof this.item.width == "undefined" ? "100%" : this.item.width;
      //  this.item.height = typeof this.item.height == "undefined" ? "350px" : this.item.height;

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.item.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.item.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.item.show && !this.item.hide && this.item.outsideVisible ? "flex" : "none") : "flex";
                this.showDisplayBoolean = !this.isEdit ? (this.item.show && !this.item.hide && this.item.outsideVisible ? true : false) : true;

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });

        if (typeof this.form.components == "undefined") this.form.components = JSON.parse(this.form.formJson)
    }

    onInit(editor) {
        this.loadingPopup = false;
        if (!this.componentService.isCreateditorDict) {
            let monaco = window['monaco'];
            this.setCustomKeywords(monaco);
            this.componentService.isCreateditorDict = true;
        }

        //if(!this.isEdit && this.form.expression && this.form.expression.length > 0) this.execute();
    }

    execute() {
        eval(this.form.expression);
    }

    setCustomKeywords(monaco) {
        monaco.languages.registerCompletionItemProvider('typescript', {
            provideCompletionItems: () => {
                var suggestions = this.createDependencyProposals(monaco) || [];
                let componentslist = this.componentService.getComponentList(this.form.components, this.anotherPopupId);
                for (let i = 0; i < componentslist.length; i++) {
                    suggestions.push({
                        label: componentslist[i].id,
                        kind: monaco.languages.CompletionItemKind.Snippet,
                        insertText: "var " + componentslist[i].id + ' = ' + 'this.getById("' + componentslist[i].id + '");',
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        documentation: 'method'
                    })
                }
                return { suggestions: suggestions };
            }
        });
    }

    createDependencyProposals(monaco) {
        return [{
            label: 'form',
            kind: monaco.languages.CompletionItemKind.Keyword,
            insertText: 'form',
        }, {
            label: 'components',
            kind: monaco.languages.CompletionItemKind.Keyword,
            insertText: 'components[]',
        }, {
            label: 'value',
            kind: monaco.languages.CompletionItemKind.Keyword,
            insertText: 'value',
        }, {
            label: 'data',
            kind: monaco.languages.CompletionItemKind.Keyword,
            insertText: 'data',
        }, {
            label: 'ifelse',
            kind: monaco.languages.CompletionItemKind.Snippet,
            insertText: [
                'if () {',
                '\t$0',
                '} else {',
                '\t',
                '}'
            ].join('\n'),
            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            documentation: 'If-Else Statement'
        }, {
            label: 'forEach',
            kind: monaco.languages.CompletionItemKind.Snippet,
            insertText: [
                'this.form.components.forEach(component => {',
                '\t console.log(component);',
                '});'
            ].join('\n'),
            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            documentation: 'forEach Statement'
        },
        {
            label: 'getById',
            kind: monaco.languages.CompletionItemKind.Snippet,
            insertText: 'this.getById("id")',
            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            documentation: 'method'
        },
        {
            label: 'colSum',
            kind: monaco.languages.CompletionItemKind.Snippet,
            insertText: 'this.colSum("Grid Id", kolon_index)',
            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            documentation: 'method'
        },
        {
            label: 'setValue',
            kind: monaco.languages.CompletionItemKind.Snippet,
            insertText: 'this.setValue("selectboxId","elementId","key")',
            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            documentation: 'method'
        }
        ];
    }

    getById(id) {
        return this.componentService.getById(id, this.form.components, this.anotherPopupId);
    }
    setValue(selectboxId, elementId, key) {
        this.componentService.setValue(selectboxId, elementId, key, this.form.components, this.anotherPopupId);
    }

    colSum(id, colm) {
        return this.componentService.colSum(id, colm, this.form.components, this.anotherPopupId);
        /*if (this.isNumeric(colm)) {
          let result = this.componentService.colSum(id, colm, this.form.components);
          if (this.isNumeric(result)) return result;
          else {
             this.showToast("Hatalı kolon indexi girildi!","warning");
             return 0;
          }
        
        }
        else {
          this.showToast("Hatalı kolon indexi girildi!","warning");
          return 0;
        }*/
    }

    isNumeric(val: any): boolean {
        return !(val instanceof Array) && (val - parseFloat(val) + 1) >= 0;
    }

    showToast(message, status) {
        notify(message, status, 3000);
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }
    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.item.id, this.item.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.item.show && !this.item.hide && this.item.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.item.show && !this.item.hide && this.item.outsideVisible ? true : false) : true;
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }
    disableElements() {
        return typeof this.item.disabled != "undefined" ? (this.item.disabled == false ? this.item.outsideDisabled : this.item.disabled) : false;
    }

    onValueChanged(e) {
        this.eventEmitterService.onValueChangeEvent(this.item.id, "clean_element", e.value, null);
    }

    setLanguageToDatas() {
        this.themes = [{
            "name": this.languageLocal.dark/* "Dark" */,
            "code": "vs-dark",
        }, {
            "name": this.languageLocal.white /*"White"*/,
            "code": "vs",
        }, {
            "name": this.languageLocal.hightBlack /* "Hight Black"*/,
            "code": "hc-black",
        }];
    }
   
    
}
