import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
    providedIn: 'root'
})
export class EventEmitterService {

    invokeSendFormFunction = new EventEmitter();
    invokeHistoryFunction = new EventEmitter();
    invokeFormChangesFunction = new EventEmitter();
    invokeSubmitFormFunction = new EventEmitter();
    invokeLayoutChangeFunction = new EventEmitter();
    invokeShowPopopFunction = new EventEmitter();
    invokeSchedulerInit = new EventEmitter();
    invokeChangeVisibleFunction = new EventEmitter();
    invokeChangeFilterOutElement = new EventEmitter(); 
    invokeColumnSumFunction = new EventEmitter();
    invokeDeletedFileIdsFunction = new EventEmitter();
    invokeMenuSubmitFunction = new EventEmitter();
    invokeNestedSelecboxFunction = new EventEmitter();
    invokeChangeEvent = new EventEmitter();
    invokeDatagridOpenEditRowPopupEvent = new EventEmitter();
    invokeGalleryChangeEvent = new EventEmitter();
    invokeButtonClick = new EventEmitter();
    invokeSaveSystemInfoEvent = new EventEmitter();
    invokeStartInstanceFunction = new EventEmitter();
    invokeStartMenuFunction = new EventEmitter();

    invokeGetAllComponentFunction = new EventEmitter();

    invokeUpdateTasksFuntion = new EventEmitter();
    invokeFavoriteChangeEvent = new EventEmitter();
    invokePageWithRouteChangeEvent = new EventEmitter();

    invokeChangeMenuTabComponentEvent = new EventEmitter();

    invokeCreateTaskAndStartFormEvent = new EventEmitter();

    invokeSetLanguageEvent = new EventEmitter();

    invokeSetHomePopupVisibleEvent = new EventEmitter();

    invokeGetProcessInfo = new EventEmitter();

    invokeSearchSetDataEvent = new EventEmitter();
    invokeColorSetDataEvent = new EventEmitter();
    invokeGetAllSchemaListEvent = new EventEmitter();

    invokeRefreshGridEvent = new EventEmitter();

    invokeHomeLoadingEvent = new EventEmitter();

    invokeProcessChageEvent = new EventEmitter();

    invokeOpenChildFormChageEvent = new EventEmitter();

    invokecloseTextAreaMicChageEvent = new EventEmitter();
    invokechangeToCaptureModeTextAreaEvent = new EventEmitter();

    // invokeTabClick = new EventEmitter();

    //tanju - 12.06.2021
    invokeOnValueChageEvent = new EventEmitter();

    //Used on signature to change color of box when its required but value is empty
    invokeWarningChangeEvent = new EventEmitter();

    subsSendForm: Subscription;
    subsFormChange: Subscription;
    subsFormElementChange: Subscription;
    subsSubmit: Subscription;
    subsLayoutChange: Subscription;
    subsMenuSubmit: Subscription;
    subsStartInstance: Subscription;

    deleteFileIdsArray: any[] = []



    constructor() { }

    applyHistory(act) {
        this.invokeHistoryFunction.emit({ action: act });
    }

    executeExpretion(anotherPopupIdLocal) {
        this.invokeFormChangesFunction.emit({ anotherPopupIdLocal: anotherPopupIdLocal });
    }

    sendForm(status, name, reason, anotherPopupIdLocal) {
        this.invokeSendFormFunction.emit({ status: status, name: name, reason: reason, anotherPopupIdLocal: anotherPopupIdLocal });
    }

    submitForm() {
        this.invokeSubmitFormFunction.emit();
    }

    updateFavoriteChange(data) {
        this.invokeFavoriteChangeEvent.emit({ data: data });
    }

    processChageEvent(data) {
        this.invokeFavoriteChangeEvent.emit({ data: data });
    }

    pageWithRouteChange(data) {
        this.invokePageWithRouteChangeEvent.emit({ data: data });
    }

    createTaskAndStartForm(data) {
        this.invokeCreateTaskAndStartFormEvent.emit({ data: data });
    }

    setLanguageFunc(data) {
        this.invokeSetLanguageEvent.emit({ data: data });
    }

    setHomePopupVisibleFunc(data) {
        this.invokeSetHomePopupVisibleEvent.emit({ data: data });
    }

    getProcessInfo(data) {
        this.invokeGetProcessInfo.emit({ data: data });
    }

    refreshGrid(data) {
        this.invokeRefreshGridEvent.emit({ data: data });
    }

    homeLoading(data) {
        this.invokeHomeLoadingEvent.emit({ data: data });
    }

    openChildForm(data) {
        this.invokeOpenChildFormChageEvent.emit({ data: data });
    }

    layoutChange() {
        this.invokeLayoutChangeFunction.emit();
    }

    showPopop(id) {
        this.invokeShowPopopFunction.emit({ key: id });
    }

    schedulerInit(id) {
        this.invokeSchedulerInit.emit({ key: id });
    }

    ChangeVisible(data) {
        this.invokeChangeVisibleFunction.emit({ data: data });
    }

    ChangeFilterOutElement(data) {
        //ChangeFilterOutElement element value
        this.invokeChangeFilterOutElement.emit({ data: data });
    }
    setColumnSumToComponent(id, sum) {
        this.invokeColumnSumFunction.emit({ id: id, sum: sum });
    }

    setDeletedFileIds(id) {
        this.deleteFileIdsArray.push(id)
        this.invokeDeletedFileIdsFunction.emit(this.deleteFileIdsArray)
    }

    clearDeleteFileIds() {
        this.deleteFileIdsArray = []
        this.invokeDeletedFileIdsFunction.emit(this.deleteFileIdsArray)
    }

    submitMenu(status, name, reason) {
        this.invokeMenuSubmitFunction.emit({ status: status, name: name, reason: reason });
    }

    colorSetData(data) {
        this.invokeColorSetDataEvent.emit({ data: data });
    }

    searchSetData(data) {
        this.invokeSearchSetDataEvent.emit({ data: data });
    }

    getAllSchemaList(data) {
        this.invokeGetAllSchemaListEvent.emit({ data: data });
    }

    applySelecboxData(id, data) {
        this.invokeNestedSelecboxFunction.emit({ id: id, data: data });
    }

    selectboxChangeEvent(id, elementId, value, data, isShowComfirm = true) {
        this.invokeChangeEvent.emit({ id: id, elementId: elementId, value: value, data: data, isShowComfirm: isShowComfirm });
    }

    datagridOpenEditRowPopup(data) {
        this.invokeDatagridOpenEditRowPopupEvent.emit({ data: data });
    }

    buttonClickChangeEvent(data) {
        this.invokeButtonClick.emit({ data: data });
    }

    warningChangeEvent(data) {
        this.invokeWarningChangeEvent.emit({ data: data });
    }

    galleryChangeEvent(id, elementId, data) {
        this.invokeGalleryChangeEvent.emit({ id: id, elementId: elementId, data: data });
    }

    saveSystemInfoEvent(elementId, data) {
        this.invokeSaveSystemInfoEvent.emit({ elementId: elementId, data: data });
    }

    menuTabComponentChange(data) {
        this.invokeChangeMenuTabComponentEvent.emit({ data: data });
    }

    openForm(data) {
        this.invokeStartInstanceFunction.emit({ data: data });
    }

    openMenu(data) {
        this.invokeStartMenuFunction.emit({ data: data });
    }

    getAllComponent(component) {
        this.invokeGetAllComponentFunction.emit({ component: component });
    }

    updateTasks(anotherPopupIdLocal = 0) {
        this.invokeUpdateTasksFuntion.emit({ anotherPopupIdLocal: anotherPopupIdLocal });
    }

    onValueChangeEvent(id, elementId, value, data) {
        this.invokeOnValueChageEvent.emit({ id: id, elementId: elementId, value: value, data: data });
    }

    closeTextAreaMicEvent(data) {
        this.invokecloseTextAreaMicChageEvent.emit({ data: data });
    }

    changeToCaptureModeTextAreaEvent(data) {
        this.invokechangeToCaptureModeTextAreaEvent.emit({data:data})
    }
}
