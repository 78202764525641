import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { Subscription } from 'rxjs';
@Component({
    selector: 'payment-component',
    templateUrl: 'payment.component.html',
    styleUrls: ['./payment.component.css']
})
export class DxPaymentComponent implements ComponentParameters, OnInit, OnDestroy {
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;
    @Input() anotherPopupId: number = 0;
     
    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;

    showDisplay: any = "flex";

    systemInfoPage: any;

    cardNoRules: any = { X: /[02-9]/ }
    monthRules: any = { X: /[0-1]/, Y: /[1-9]/ }
    yearRules: any = { X: /[2-3]/, Y: /[0-9]/ }
    cvvcvcRules: any = { X: /[0-9]/, Y: /[0-9]/, Z: /[0-9]/ }

    namePattern: any = /^[^0-9]+$/;

    invokeChangeEventLocal: Subscription;

    constructor(private eventEmitterService: EventEmitterService,
        public changeDetectorRef: ChangeDetectorRef,
        public componentService: ComponentService) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });

        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {

            if (sub.elementId == this.data.id) {
                if (sub.data) this.data.value = sub.data;
                else this.data.value = "";
                //setTimeout(() => {
                //    try {
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                //    } catch{ }
                //}, 300);

            }
        });
        //this.componentService.systemInfo.currentSystem Info

        this.systemInfoPage = this.componentService.systemInfoElements;

    }
    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
    }
    ngOnInit() {
        //
        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (this.data.isAuto) {
            this.data.disabled = true;
            //this.data.value = this.data.autoText + this.componentService.currentForm.formNo;
        }
        this.data.upperCaseLetter = this.data.upperCaseLetter == null ? false : this.data.upperCaseLetter;

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                 
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });



        this.data.systemInfoId = typeof this.data.systemInfoId == "undefined" ? 0 : this.data.systemInfoId;

        if (this.isEdit) {
            this.componentService.currentSystemInfo.push({ id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) });
        }

    }
    systemInfoChange() {
    }
    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);

            let systemInfoTemp = this.componentService.currentSystemInfo.find(x => x.id == this.data.id);
            if (systemInfoTemp)
                this.componentService.currentSystemInfo.find(x => x.id == this.data.id).value = this.systemInfoPage.find(x => x.id == this.data.systemInfoId);
            else
                this.componentService.currentSystemInfo.push({ id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) });
        }
        else {
            this.componentService.showAlert("Bu id başka bir yerde kullanılıyor, lütfen farklı bir id belirtiniz.", "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }
    getTextboxStyles() {//text-transform: uppercase

        let myStyles = {
            'text-transform': this.data.upperCaseLetter != null && this.data.upperCaseLetter != false ? 'uppercase' : 'none'
        };
        return myStyles;
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // Tüm charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    cancelSetting() {
        let msg = "<i>Yaptığınız değişikleri kaydetmeden çıkmak istediğinizden emin misiniz?</i>"
        let result = confirm(msg, "Uyarı!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);


                let systemInfoTemp = this.componentService.currentSystemInfo.find(x => x.id == this.data.id);
                if (systemInfoTemp)
                    this.componentService.currentSystemInfo.find(x => x.id == this.data.id).value = this.systemInfoPage.find(x => x.id == this.data.systemInfoId);
                else
                    this.componentService.currentSystemInfo.push({ id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) });
                // this.componentService.currentSystemInfo = { id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) };//this.systemInfoPage.find(x => x.id == this.data.systemInfoId);
            }
        });
    }

}
