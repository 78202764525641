import { Component, OnInit, Input } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';

import notify from "devextreme/ui/notify";
import { ComponentService } from '../../component.service';

@Component({
    selector: 'languagebutton-component',
    templateUrl: './languagebutton.component.html'
})
export class DxLanguageButtonComponent implements OnInit {
    @Input() dataSource: any;

    languageCodes = []

    buttonCols: any = [];
    languageLocal: any = "";


    constructor(private componentService: ComponentService) {
        this.languageLocal = this.componentService.getLanguageSource(); //language
        this.languageCodes = this.componentService.getlanguageCodes();

    }

    ngOnInit() {
        
        this.buttonCols = Object.keys(this.dataSource[0]);
        const index_Code = this.buttonCols.findIndex(item => item === "Code");
        if (index_Code != -1)
            this.buttonCols.splice(index_Code, 1);
        const button_Id = this.buttonCols.findIndex(item => item === "buttonId");
        if (button_Id != -1)
            this.buttonCols.splice(button_Id, 1);
        const index_DefaultLabel = this.buttonCols.findIndex(item => item === "DefaultLabel");
        if (index_DefaultLabel != -1)
            this.buttonCols.splice(index_DefaultLabel, 1);
    }

}
