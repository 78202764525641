import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, OnDestroy, AfterViewInit, ContentChild, ElementRef } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService, FormService, GenericService, AppInfoService } from '../../shared/services';
import CustomStore from 'devextreme/data/custom_store';
import { ComponentService } from '../component.service';

import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';
import { EditorComponent } from 'ngx-monaco-editor';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';

export class SqlScriptModelDatagrid {
    id: number;
    connectionId: number;
    elementId: string;
    query: string;
}


@Component({
    selector: 'datagridselect-component',
    templateUrl: './datagridselect.component.html',
    styleUrls: ['./datagridselect.component.css']
})
export class DxDatagridSelectComponent implements ComponentParameters, OnInit, OnDestroy, AfterViewInit {
    //@ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;
    //  @ContentChild('gridContainer') gridContainer: ElementRef
    @ViewChild(DxDataGridComponent, { static: false }) gridContainer;
    @Input() data: any;
    @Input() isEdit: any;
    @Input() lang: string;
    @Input() anotherPopupId: number = 0;

    dropDownOptions: object;


    showDisplay: any = "flex";
    showDisplayBoolean = false;


    languageCodes = []

    designerDataSourceList: any;
    designerDatasourceModel: any;

    dataSources: any;
    dataSourcePopup: boolean = false;
    isFromDataSource: boolean;
    showSettingPopup: boolean = false;
    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showChamgeEventPopup: boolean = false;
    isMonacoLoaded: boolean = false;

    invokeChangeEventLocal: Subscription;
    // editorOptions = { theme: 'vs-dark', language: 'sql', automaticLayout: true };
    connections: any = [];
    selecboxComponents: any = [];

    sqlScriptModel: SqlScriptModelDatagrid;
    languageLocal: any = "";

    constructor(public appInfo: AppInfoService,
        private eventEmitterService: EventEmitterService,
        private componentService: ComponentService,
        private genericService: GenericService,
        public changeDetectorRef: ChangeDetectorRef) {

        this.languageCodes = this.componentService.getlanguageCodes();
        this.languageLocal = this.componentService.getLanguageSource(); //language

        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {

            if (sub.elementId == this.data.id) {
                if (sub.data) {

                    //this.data.dataSource = sub.data;
                    let result = sub.data;
                    if (result && result.length > 0 && Array.isArray(result)) {
                        //let cols = Object.keys(result[0]);
                        //if (cols) {

                        //    result.forEach(item => {
                        //        if (!item.text) {

                        //            if (cols.length == 1) {
                        //                item.text = item[cols[0]];
                        //            }
                        //            else {
                        //                let displayList = [];
                        //                cols.forEach(f => {
                        //                    if (f != this.data.valueExpr) {
                        //                        displayList.push(item[f]);
                        //                    }
                        //                });
                        //                item.text = displayList.join(",");
                        //            }
                        //        }
                        //    });

                        //}
                        this.datagridHeaderFill(result);
                        this.data.dataSource = result;
                        // this.data.value = (this.data.isDefaultFirstValue && this.data.dataSource.length > 0) || (this.data.value == "" && this.data.dataSource.length > 0) ? this.data.dataSource[0][this.data.valueExpr] : this.data.value;


                        this.data.value = (this.data.isDefaultFirstValue && this.data.dataSource.length > 0 && this.data.value == "") ? this.data.dataSource[0][this.data.valueExpr] : this.data.value;

                        // İlk değer seçili gelsin denildiğinde scriptlerin tetiklenmesi için yapıldı.
                        let _event_ = [];
                        _event_.push(this.data.value);
                        this.onSelectionChanged(_event_);

                        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                    }
                    else {
                        this.data.value = result;
                    }

                }
                else if (sub.elementId == "clean_element" || sub.value == "clean_element") {

                    this.data.dataSource = [];
                    this.data.value = "";

                }
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }

            }
        });

        //this.dropDownOptions = { width: this.data.width };
    }
    getLookupContainerStyle() {
        let myStyles = {
            'max-width': " calc(100% + 30px)", //max width ile aşım çözülüyor px ile calısıyor; %li verdikten sonra bozuluyor %100den fazla verilmemesi gerekiyor
            'width': "auto",// (this.data.width && typeof this.data.width != "undefined" && this.data.width != null) ? (this.data.width + (this.componentService.isInteger(this.data.width) ? "px" : "")) : "auto",
            'display': this.showDisplay,

        };
        return myStyles;
    }
    getLookupStyle() {
        let myStyles = {
            'width': (this.data.width && typeof this.data.width != "undefined" && this.data.width != null) ? (this.data.width + (this.componentService.isInteger(this.data.width) ? "px" : "")) : "100%",
            'max-width': " calc(100% + 30px)",
        };
        return myStyles;
    }

    getTextboxStylesFunc() {
        let paddingright = '34px'
        if (this.data.showClearButton && !!this.data.value) {
            paddingright = '64px'
        }
        if (this.data.width != null && this.data.width != "" && typeof this.data.width != "undefined")

            return { 'width': this.data.width, 'padding-left': '5px', 'padding-right': paddingright };
        else
            return { 'padding-left': '5px', 'padding-right': paddingright };
        //, 'opacity': '.5', '-webkit-user-select': 'none', '-moz-user-select': 'none', '-ms-user-select': 'none', 'user-select': 'none', '-webkit-touch-callout': 'none', 'cursor': 'default' 

    }
    validateLookup(e) {
        return e.value != ""
    }
    getDatagridSourceGetValue() {
        let _item = this.data.dataSource.find(x => x[this.data.valueExpr] == this.data.value);
        return _item ? _item[this.data.displayExpr] : (this.defaultLanguage.Placeholder != "" && this.defaultLanguage.Placeholder != null && typeof this.defaultLanguage.Placeholder != "undefined" ? "" : "");
    }
    getDatagridSelectRowKey() {
        let arraySelected = [];
        if (typeof this.data != "undefined" && this.data.value != null && this.data.value != "") {
            arraySelected.push(this.data.value);
        }
        return arraySelected;
    }
    showDatagridPopup: any = false;
    openDatagridPopup() {
        this.showDatagridPopup = true;
    }
    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
    }
    isFirstLoad = true;
    isOnChangeExecute = true;
    selectedGridForm = [];
    ngOnInit() {
        let fff111 = this.data.dataSource;

        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);
        this.connections = this.appInfo.RiverEntity.connections;

        if (!this.isEdit) {
            this.executeScript();
            /*setTimeout(function () {
              this.executeScript();
            }.bind(this), 1000);*/
            let _thisVal = this.data.value;

            // İlk açılış kuralında eğer isDatagridSetChangeEvent true verirsek processformdata verisi açılırken yanlış gidiyor.
            let isDatagridSetChangeEvent = false
            if (this.componentService.currentProcessFormId == 0) {
                isDatagridSetChangeEvent = true;
                this.isOnChangeExecute = true;
            }
            else
                this.isOnChangeExecute = false;
            setTimeout(function () { this.isFirstLoad = true; this.sqlScriptExecute(_thisVal, isDatagridSetChangeEvent); }.bind(this), 1000);
        }
        else {

            this.fillDesignerDataSource();
        }

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });

        if (typeof this.data.isDefaultFirstValue === "undefined")
            this.data.isDefaultFirstValue = false;

        let fff = this.data;

        this.dropDownOptions = { width: this.data.width };
    }
    disableElements() {
        return typeof this.data.disabled != "undefined" ? (this.data.disabled == false ? this.data.outsideDisabled : this.data.disabled) : false;
    }

    datagridHeaderFill(_datasourceLocal) {
        this.selectedGridForm = [];
        if (_datasourceLocal.length > 0)
            this.selectedGridForm = Object.keys(_datasourceLocal[0]);
    }
    ngAfterViewInit() {
        if (!this.isEdit) {
            //let _this = this;
            // 
            // this.gridContainer.instance.columnOption("ID", "visible", "false");
            //setTimeout(() => {  
            //    
            //}, 1500);

        };
    }
    onSelectionChanged(event) {
        if (this.isOnChangeExecute == true) {
            this.clearScriptRecursive(this.data.id, false);
            if (event != "" && typeof event != "undefined" && event != null && event.length != 0) {
                this.data.value = event[0];
                this.sqlScriptExecute(event[0], true);
            }
            else {
                //this.eventEmitterService.selectboxChangeEvent (this.data.id, "clean_element", null, null);

            }
            this.showDatagridPopup = false;
        } else {
            this.isOnChangeExecute = true;
        }

        //selectedRowsData   
        /* if (event.currentSelectedRowKeys.length > 0) {
 
             let _data = event.selectedRowsData;
             if (_data) {
                 // let display = _data[0][this.data.valueExpr];
                 this.data.value = _data[0][this.data.valueExpr];//_data[0][this.data.valueExpr];//_data[0];
                 //  cellInfo.setValue(display);
                 //dropDownBoxComponent.close();
                  this.showDatagridPopup = false;
                 //let _thisLocal = this;
                 this.sqlScriptExecut e(event.value);
                  
             }
             else {
                 this.eventEmitterService.selectboxChangeEvent (this.data.id, "clean_element", null, null);
             }
 
         }*/


        //let header = this.data.headers.find(f => f.name == cellInfo.column.name);
        //if (header) this.selectedGridForm = header.dataGridSettings.formFields;

        //if (selectedRowKeys.length > 0) {

        //    let data = cellInfo.column.lookup.items.find(f => f[cellInfo.column.lookup.valueExpr] == selectedRowKeys[0][cellInfo.column.lookup.valueExpr]);
        //    if (data) {
        //        let display = data[cellInfo.column.lookup.valueExpr];
        //        cellInfo.setValue(display);
        //        dropDownBoxComponent.close();

        //        //  cellInfo.onValueChange d = args => {
        //        //  
        //        // cellInfo.setValue(args.value);

        //        var newRow = typeof cellInfo.row.isNewRow == "undefined" ? false : cellInfo.row.isNewRow;
        //        if (header)
        //            this.executeSqlExpression(header.field, cellInfo.row.data, cellInfo.row.rowIndex, newRow);
        //        // };

        //    }
        //}
    }

    sqlScriptExecute(_value, isDatagridSetChangeEvent) {
        let sqlScriptLength = 0;
        this.data.sqlScripts.forEach(s => {

            let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
            let curentComponents = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);

            let dataGrid = curentComponents.find(f => f.id == s.elementId)
            let isDataGrid = typeof dataGrid == "undefined" ? false : true;

            if (!this.isFirstLoad /*&& !isDataGrid*/) {
                this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, "clean_element", null, false);
            }

            let pref = "{" + this.data.id + "}";
            let value = this.data.value;
            let query = this.componentService.replaceAllUsingType(s.query, pref, value);// s.query.replace(pref, value);



            var pattern = /\{(\w)+\}/ig;
            var patt = new RegExp(pattern);
            if (patt.test(query)) {
                let _match = query.match(pattern);
                if (_match.length > 0) {
                    _match.forEach(f => {
                        let _k = curentComponents.find(k => k.id == f.replace("{", "").replace("}", ""));
                        if (_k) {
                            let _value = _k.value == null ? "" : _k.value;
                            query = this.componentService.replaceAllUsingType(query, f, _value);// query.replace(f, _value);
                        }
                        else if (this.componentService.tempDatagridPopupComponent.components.length > 0) {
                            let _selectElement = this.componentService.tempDatagridPopupComponent.components.find(x => x.id == f.replace("{", "").replace("}", ""));
                            if (_selectElement && _selectElement.type) {
                                let _value = _selectElement.value == null ? "" : _selectElement.value;
                                query = this.componentService.replaceAllUsingType(query, f, _value);// query.replace(f, _value);
                            }
                        }
                    });
                }
                //query = this.genericService.cleanSqlSyntax(query.replace(pattern, ""))
            }

            this.genericService.executeQuerySecurited(query, s.connectionId).subscribe((result: any) => {
                if (result && result.length > 0) {
                    //console.log("---------------------" + this.anotherPopupId);
                    //console.log("elementId: " + s.elementId);
                    //if (s.elementId == "istektipi") {
                    //    
                    //}
                    let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
                    let element = this.componentService.getById(s.elementId, currentFormSelected.components, this.anotherPopupId);
                    if (element && element.type) {
                        this.sqlScriptExecuteResult(element, isDatagridSetChangeEvent, s, _value, result);
                    }
                    else if (this.componentService.tempDatagridPopupComponent.components.length > 0) {

                        let _selectElement = this.componentService.tempDatagridPopupComponent.components.find(x => x.id == s.elementId);
                        if (_selectElement && _selectElement.type) {
                            this.sqlScriptExecuteResult(_selectElement, isDatagridSetChangeEvent, s, _value, result);
                        }

                    }

                    sqlScriptLength = sqlScriptLength + 1;
                }
            });
        });

        if (this.isFirstLoad) {
            let _tThis = this;
            let checksqlScriptExist = setInterval(function () {
                if (sqlScriptLength == _tThis.data.sqlScripts.length) {
                    clearInterval(checksqlScriptExist);
                    _tThis.isFirstLoad = false;
                }
            }, 300);
        }
    }

    sqlScriptExecuteResult(element, isDatagridSetChangeEvent, s, _value, result) {
        if (element.component == "DxDatagridSelectComponent" || element.type == "datalist" || element.type == "selectbox" || element.type == "gallery" || element.type == "datagrid" || element.type == "multiselectbox") {
            if (element.type != "datagrid" || (element.type == "datagrid" && isDatagridSetChangeEvent)) {
                if (this.isFirstLoad) {
                    this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, _value, result, false);
                } else {
                    this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, _value, result, true);
                }
            }
        }
        else {
            if (!(this.componentService.datagridColumnHasUserChanges[s.elementId])) {
                let keys = Object.keys(result[0]);
                if (keys.length > 0) {
                    let finalData = [];
                    keys.forEach(f => {
                        finalData.push(result[0][f]);
                    });
                    let dataText = finalData.join(",");
                    this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, _value, dataText);
                }
            }
        }
    }

    fillDesignerDataSource() {
        this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };

        this.genericService.fillDesignerDataSource().subscribe((result: any) => {

            this.designerDataSourceList = result;
            this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };
            if (this.data.desktopDatasource != null) {
                this.designerDatasourceModel = this.designerDataSourceList.find(x => x.ID == this.data.desktopDatasource);

                if (typeof this.designerDatasourceModel.ID === "undefined")
                    this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };
            }

            if (!this.isEdit) {

                this.genericServiceExecuteScript(this.designerDatasourceModel.SQLSTR, this.designerDatasourceModel.CONNID);
            }

        });

    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {

        this.data.desktopDatasource = this.designerDatasourceModel.ID;

        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);

            /*if (this.data.dataSourceKey == "" && this.data.dataSource.length <= 0)
              this.componentService.showAlert("Uyarı! Henüz veri eklenmedi.", "warning", 4000);*/
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    resetDataSource() {
        this.data.dataSource = [];
        this.data.dataSourceKey = "";
    }

    onChangeDatasourceValue(event) {
        if (event.value) {

            this.designerDatasourceModel = this.designerDataSourceList.find(x => x.ID == event.value);

            //  this.designerDatasourceModel = { ID: 0, NAME: "", SQLSTR: "" };

        }
    }


    onValueChanged(event) {

        //if (event.value) {
        //    this.data.value = event.value;
        //    this.data.sqlScripts.forEach(s => {
        //        let pref = "{" + this.data.id + "}";
        //        let value = event.value;
        //        let query = s.query.replace(pref, value);
        //        this.genericService.executeQuerySecurited(query, s.connectionId).subscribe((result: any) => {
        //           
        //            if (result) {
        //                let element = this.componentService.getById(s.elementId, this.componentServi ce.currentF orm.components);
        //                if (element.type) {
        //                    
        //                    if (element.type == "datagridselect" || element.type == "gallery") {
        //                        this.eventEmitterService.selectboxChangeEvent (this.data.id, s.elementId, event.value, result);
        //                    }
        //                    else {
        //                        let keys = Object.keys(result[0]);
        //                        if (keys.length > 0) {
        //                            let finalData = [];
        //                            keys.forEach(f => {
        //                                finalData.push(result[0][f]);
        //                            });
        //                            let dataText = finalData.join(",");
        //                            this.eventEmitterService.selectboxChangeEvent (this.data.id, s.elementId, event.value, dataText);
        //                        }
        //                    }
        //                }
        //            }
        //        });
        //    });
        //}
        //else {
        //    this.eventEmitterService.selectboxChangeEvent (this.data.id, "clean_element", null, null);
        //}
    }

    onDataSourceValueChanged(event) {
        this.data.dataSourceKey = event.value
    }

    public executeScript() {

        if (this.data.dataStorage.connectionId > 0 && this.data.dataStorage.query != "") {
            this.genericServiceExecuteScript(this.data.dataStorage.query, this.data.dataStorage.connectionId);
        }
        else if (this.data.dataSource && this.data.dataSource.length > 0) {

            this.data.dataSource.forEach(f => {
                delete f["__KEY__"];
                delete f["lang"];
            });

            this.datagridHeaderFill(this.data.dataSource);
            let filteredData = this.data.dataSource.filter(f => f.lang == this.lang);
            if (filteredData && filteredData.length > 0)
                this.data.dataSource = filteredData;

            this.data.value = this.data.isDefaultFirstValue && this.data.dataSource.length > 0 && this.data.value == "" ? this.data.dataSource[0][this.data.valueExpr] : this.data.value;
            //displayExpr
        }
        else if (this.data.desktopDatasource != null) {

            this.fillDesignerDataSource();

        }
    }

    public genericServiceExecuteScript(_Query, _ConnectionId) {
        this.genericService.executeQuerySecurited(_Query, _ConnectionId).subscribe((result: any) => {

            if (result && result.length > 0) {
                let cols = Object.keys(result[0]);
                if (cols) {
                    //displayExpr
                    ////result.forEach(item => {
                    ////    if (cols.length == 1) {
                    ////        item.text = item[cols[0]];
                    ////    }
                    ////    else {
                    ////        let displayList = [];
                    ////        cols.forEach(f => {
                    ////            if (f != this.data.valueExpr) {
                    ////                displayList.push(item[f]);
                    ////            }
                    ////        });
                    ////        item.text = displayList.join(",");
                    ////    }
                    ////});
                    //item.text = displayList.join(",");
                }
                this.datagridHeaderFill(result);
                this.data.dataSource = result;

                this.data.value = this.data.isDefaultFirstValue && this.data.dataSource.length > 0 && this.data.value == "" ? this.data.dataSource[0][this.data.valueExpr] : this.data.value;

                try {
                    this.changeDetectorRef.detectChanges();
                }
                catch (ex) { }
            }
            else {
                this.data.dataSource = [];
            }
        });
    }


    openDataPopup(type) {
        this.isFromDataSource = type == "1";
        if (this.isFromDataSource) {
            this.data.isManual = false;
            let selectboxes = this.componentService.getComponentsByType("selectbox", this.anotherPopupId);
            this.selecboxComponents = [];
            if (selectboxes) {
                selectboxes.forEach(item => {
                    if (item.id != this.data.id) {
                        let element: any = {};
                        element.id = item.id;
                        element.name = this.componentService.getLang(this.lang, item.language).Label;
                        this.selecboxComponents.push(element);
                    }
                });
            }
            //this.dataSources = this.componentService.getComponentsByType("datasource");
        }
        else {
            this.data.isManual = true;
        }

        this.dataSourcePopup = true;
    }

    saveDataSource() {
        if (this.data.isManual && this.data.dataStorage.connectionId > 0 && this.data.dataStorage.query != "") {
            let msg = "<i>" + this.languageLocal.messageSqlLinkDeleted + "</i>"
            let result = confirm(msg, this.languageLocal.warning);
            result.then((dialogResult) => {

                if (dialogResult) {
                    this.data.dataStorage.connectionId = 0;
                    this.data.dataStorage.query = "";
                    this.dataSourcePopup = false;
                    this.data.valueExpr = "value";
                    this.data.displayExpr = "text";
                }
            });
        }
        else {

            if (this.data.isManual) {
                this.data.valueExpr = "value";
                this.data.displayExpr = "text";
            }

            this.dataSourcePopup = false;
        }
    }

    resetDataSources() {
        this.data.dataSourceKey = "";
        this.data.dataSource = [];

        notify(this.languageLocal.notifyDataReset, "success", 3000);
    }

    apply() {
        this.executeScript();
    }

    prepareGrid() {
        new CustomStore({
            key: "Id",
            load: () => this.data.dataSource,
            insert: (values) => this.insert(JSON.stringify(values)),
            update: (key, values) => this.update(key, JSON.stringify(values)),
            remove: (key) => this.remove(key)
        });
    }

    executeExp() {
        //this.eventEmitterService.executeExpretion(this.anotherPopupId);
    }

    insert(values: any = {}): any {
        this.data.dataSource.push(values);
    }

    update(key, values: any = {}): any {
        const index = this.data.dataSource.findIndex(item => item.key === key);
        this.data.dataSource[index] = values;
    }

    remove(key): any {
        const index = this.data.dataSource.findIndex(item => item.key === key);
        this.data.dataSource.splice(index, 1);
    }

    monacoOnInit() {
        this.isMonacoLoaded = true;
        let monaco = window['monaco'];
        this.setCustomKeywords(monaco);
    }

    registeredLanguage: any;
    setCustomKeywords(monaco) {
        if (this.registeredLanguage)
            this.registeredLanguage.dispose();

        this.registeredLanguage = monaco.languages.registerCompletionItemProvider('plaintext', {
            provideCompletionItems: () => {
                var suggestions = [];
                let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
                let elements = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId).map(m => m.id);
                for (let i = 0; i < elements.length; i++) {
                    suggestions.push({
                        label: elements[i],
                        kind: monaco.languages.CompletionItemKind.Snippet,
                        insertText: "" + elements[i] + "",
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        documentation: 'method'
                    })
                }
                return { suggestions: suggestions };
            }
        });
    }

    formElements: any;
    openChamgeEventPopup() {
        this.isMonacoLoaded = false;
        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        let elements = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId).map(m => m.id);
        this.formElements = elements.filter(f => f != this.data.id);
        this.sqlScriptModel = new SqlScriptModelDatagrid();
        this.sqlScriptModel.query = 'SELECT * FROM TABLE WHERE KEY={' + this.data.id + '}';
        this.showChamgeEventPopup = true;
    }

    saveSqlScript() {
        let model = this.componentService.cloneModel(this.sqlScriptModel);

        if (model.id > 0) {
            let scr = this.data.sqlScripts.find(f => f.id == model.id);
            scr.connectionId = model.connectionId;
            scr.elementId = model.elementId;
            scr.query = model.query;
        }
        else {
            model.id = this.data.sqlScripts.length + 1;
            this.data.sqlScripts.push(model);
        }

        this.sqlScriptModel = new SqlScriptModelDatagrid();
        this.sqlScriptModel.query = 'SELECT * FROM TABLE WHERE KEY={' + this.data.id + '}';
    }

    updateSqlScript(i) {
        let scr = this.data.sqlScripts[i];
        this.sqlScriptModel = new SqlScriptModelDatagrid();
        this.sqlScriptModel.id = scr.id;
        this.sqlScriptModel.connectionId = scr.connectionId;
        this.sqlScriptModel.elementId = scr.elementId;
        this.sqlScriptModel.query = scr.query;
    }

    deleteSqlScript(i) {
        this.data.sqlScripts.splice(i, 1);
    }

    calculateFilterExpression(filterValue, selectedFilterOperation, target) {

        const column = this as any;

        if (column.dataType = "string" && selectedFilterOperation == "contains") {
            /*
            if (filterValue.match(/[\I]+/g)) {
                
                filterValue = filterValue.normalize('NFD').replace(/[\I]+/g, "ı")
            }
            if (filterValue.match(/[\İ]+/g)) {
               
                filterValue = filterValue.normalize('NFD').replace(/[\İ]+/g, "i")
            }
            */
            var getter = function (data) {
                return data[column.dataField].normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            };

            filterValue = filterValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "")

            return [getter, selectedFilterOperation || "contains", filterValue];
        }

        return column.defaultCalculateFilterExpression.apply(this, arguments);


    }

    clearScriptRecursive(componentId, isShowComfirm = true) {

        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        let curentComponents = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
        let currentComp = curentComponents.find(el => el.id == componentId)

        if (currentComp) {
            this.clearScriptCode(componentId, currentComp, isShowComfirm);
        }
        else if (this.componentService.tempDatagridPopupComponent.components.length > 0) {

            if (this.componentService.datagridColumnHasUserChanges[componentId] == false) {
                let _selectElement = this.componentService.tempDatagridPopupComponent.components.find(x => x.id == componentId);
                this.clearScriptCode(componentId, _selectElement, isShowComfirm);
            }
            else {

            }
        }

    }
    clearScriptCode(componentId, currentComp, isShowComfirm = true) {
        if (this.data.id != componentId) {
            this.eventEmitterService.selectboxChangeEvent(this.data.id, componentId, "clean_element", null, isShowComfirm);
        }

        if (typeof currentComp.sqlScripts != "undefined" && typeof currentComp.sqlScripts.length != "undefined" && currentComp.sqlScripts.length > 0) {
            currentComp.sqlScripts.forEach(el => {
                this.clearScriptRecursive(el.elementId, isShowComfirm)
            })
        }
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // Tüm charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    clearValue() {
        this.data.value = "";
        this.clearScriptRecursive(this.data.id);
    }


}
