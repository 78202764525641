import { EventEmitter, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { WebmsgModel } from '../../models/webmsg-model';
import { AppInfoService } from '../app-info.service';
import { Observable } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { DataRefreshModel } from '../../models/datarefresh-model';
import { ReOpenFormModel, ReOpenMenuModel } from '../../models/reopenform-model';
import { OpenLoadingModel } from '../../models/openloading-model';
import { ChatMessageModel } from '../../models/chatmessage-model';


@Injectable({
    providedIn: 'root'
})
export class WebMessageService {
    messageReceived = new EventEmitter<WebmsgModel>();
    changeModule = new EventEmitter<DataRefreshModel>();
    reOpenForm = new EventEmitter<ReOpenFormModel>();
    reOpenMenu = new EventEmitter<ReOpenMenuModel>();
    chatMessageGetAll = new EventEmitter<ChatMessageModel>();
    openLoading = new EventEmitter<OpenLoadingModel>();
    connectionEstablished = new EventEmitter<Boolean>();
    chatUserStatus = new EventEmitter<number>();

    private connectionIsEstablished = false;
    private _hubConnection: HubConnection;

    constructor(public appInfo: AppInfoService, private http: HttpClient) {
        this.createConnection();
        this.registerOnServerEvents();
        this.updateModule();
        this.reOpenFormEvent();
        this.reOpenMenuEvent();
        this.chatMessageGetAllEvent();
        this.openLoadingEvent();
        this.chatUserStatusEvent();
        this.startConnection();
    }

    sendMessage(message: WebmsgModel) {

        this._hubConnection.invoke('NewWebMessage', message);
    }

    saveMessageInfo(message: WebmsgModel): Observable<boolean> {
        let params = new HttpParams().set("id", message.id.toString())/*.set("userId", message.user_id.toString()).set("isRead", message.is_read.toString())*/;
        return this.http.get<boolean>(this.appInfo.appUrl + "api/generic/saveMessageInfo", { params: params });
    }


    sendMessageService(message: WebmsgModel): Observable<boolean> {
        let params = new HttpParams().set("user_id", message.user_id.toString()).set("msg_show_type", message.msg_show_type.toString()).set("message_type", message.message_type.toString())
            .set("message", message.message.toString()).set("message_title", message.message_title.toString()).set("is_read", message.is_read.toString()).set("id", message.id.toString());
        return this.http.get<boolean>(this.appInfo.appUrl + "api/generic/sendwebmessage", { params: params });
    }


    private createConnection() {

        this._hubConnection = new HubConnectionBuilder()
            .withUrl(this.appInfo.appUrl + 'RiverHub')
            .build();

        this._hubConnection.onclose(() => {
            let _this = this;
            setTimeout(function () {
                _this.startConnection(), 5000
            })
        });

    }

    private startConnection(): void {
        this._hubConnection
            .start()
            .then(() => {
                this.connectionIsEstablished = true;
                this.connectionEstablished.emit(true);
            })
            .catch(err => {
                let _this = this;
                setTimeout(function () { _this.startConnection(); }, 5000);
            });

    }


    private registerOnServerEvents(): void {

        this._hubConnection.on('MessageReceived', (data: any) => {
            this.messageReceived.emit(data);
        });
    }

    private updateModule(): void {

        this._hubConnection.on('ChangeModule', (data: any) => {
            this.changeModule.emit(data);
        });
    }

    private reOpenFormEvent(): void {

        this._hubConnection.on('ReOpenForm', (data: any) => {
            this.reOpenForm.emit(data);
        });
    }

    private reOpenMenuEvent(): void {

        this._hubConnection.on('ReOpenMenu', (data: any) => {
            this.reOpenMenu.emit(data);
        });
    }

    private chatMessageGetAllEvent(): void {

        this._hubConnection.on('SendChatMessage', (data: any) => {
            this.chatMessageGetAll.emit(data);
        });
    }
    private openLoadingEvent(): void {

        this._hubConnection.on('OpenLoading', (data: any) => {

            this.openLoading.emit(data);
        });
    }
    private chatUserStatusEvent(): void {

        this._hubConnection.on('ChatUserStatus', (data: any) => {
            this.chatUserStatus.emit(data);
        });
    }

    sendChatUserId() {
        this._hubConnection.invoke('ChatUserStatus', this.appInfo.currentUser.id);
    }
}  
