
export class ReportModel {
    id: number;
    nodeId: number;
    connectionId: number;
    name: string;
    description: string;
    preSql: string;
    preSqlDesc: string;
    preSqlIsActive: boolean;
    mainSql: string;
    useInWeb: boolean;
    useInMobile: boolean;
    users: string;
    groups: string;
    gridStorage: string;
    pivotStorage: string;
    reportType: number;
    addedUser: number;
    addedDate: Date
}
