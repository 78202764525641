import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService, FormService, GenericService, AppInfoService } from '../../shared/services';
import CustomStore from 'devextreme/data/custom_store';
import { ComponentService } from '../component.service';

import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';
import { EditorComponent } from 'ngx-monaco-editor';
import { Subscription } from 'rxjs';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { InstanceService } from '../../shared/services/data/instance.service';
import { DxSelectBoxComponent } from 'devextreme-angular';

export class SqlScriptModel {
    id: number;
    connectionId: number;
    elementId: string;
    query: string;
}


@Component({
    selector: 'selectbox-component',
    templateUrl: './selectbox.component.html',
    styleUrls: ['./selectbox.component.css']
})
export class DxSelectboxComponent implements ComponentParameters, OnInit, OnDestroy {
    @Input() data: any;
    @Input() isEdit: any;
    @Input() lang: string;
    @Input() anotherPopupId: number = 0;

    showDisplay: any = "flex";
    showDisplayBoolean = false;

    @ViewChild("selectBoxItem", { static: false }) selectBoxItem: DxSelectBoxComponent;

    languageCodes = []
    //        { id: 0, text: "Türkçe", val1: "TR", val2: "tr" },
    ////{ id: 1, text: "USA", val1: "US", val2: "en" },
    //{ id: 1, text: "English", val1: "EN", val2: "en" },
    //{ id: 2, text: "Français", val1: "FR", val2: "fr" },
    //{ id: 3, text: "Deutsche", val1: "DE", val2: "de" },
    //{ id: 4, text: "Español", val1: "ES", val2: "es" },
    //{ id: 5, text: "Italiano", val1: "IT", val2: "it" },
    //{ id: 6, text: "Pусский", val1: "RU", val2: "ru" },
    //{ id: 7, text: "日本語", val1: "JP", val2: "ja" },
    //{ id: 8, text: "中文", val1: "CN", val2: "cmn-Hans" },
    //{ id: 9, text: "한국어", val1: "KR", val2: "ko" },
    //{ id: 10, text: "हिन्दी", val1: "IN", val2: "hi" },
    //, almanca, sırpça, basitleştirilmiş çince, ispanyolca

    classFormComponent = "col-md-12";
    classFormElements = "col-md-6 hidden-element";

    designerDataSourceList: any;
    designerDatasourceModel: any;

    dataSources: any;
    dataSourcePopup: boolean = false;
    isFromDataSource: boolean;
    showSettingPopup: boolean = false;
    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showChamgeEventPopup: boolean = false;
    isMonacoLoaded: boolean = false;

    invokeChangeEventLocal: Subscription;
    // editorOptions = { theme: 'vs-dark', language: 'sql', automaticLayout: true };
    connections: any = [];
    selecboxComponents: any = [];
    allDatagridComponents: any = [];
    sqlScriptModel: SqlScriptModel;
    languageLocal: any = "";

    constructor(public appInfo: AppInfoService,
        private eventEmitterService: EventEmitterService,
        private componentService: ComponentService,
        private genericService: GenericService,
        public changeDetectorRef: ChangeDetectorRef,
        public instanceService: InstanceService) {

        this.languageLocal = this.componentService.getLanguageSource(); //language
        this.languageCodes = this.componentService.getlanguageCodes();

        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);//
                let currentComps = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
                let currentCompDatagrids = currentComps.filter(x => x.type == "datagrid");
                this.allDatagridComponents = currentCompDatagrids.map(m => m.id);
                this.showSettingPopup = true;
            }
        });

        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {
            if (sub.elementId == this.data.id) {

                if (sub.data) {


                    let result = sub.data;
                    if (result && result.length > 0 && Array.isArray(result)) {
                        let cols = Object.keys(result[0]);
                        if (cols) {
                            result.forEach(item => {
                                if (!item.text) {

                                    if (cols.length == 1) {
                                        item.text = item[cols[0]];
                                    }
                                    else {
                                        let displayList = [];
                                        cols.forEach(f => {
                                            if (f != this.data.valueExpr) {
                                                displayList.push(item[f]);
                                            }
                                        });
                                        item.text = displayList.join(",");
                                    }
                                }
                            });

                        }
                        this.data.dataSource = result;

                        let tThis = this;
                        setTimeout(() => {
                            let _dataVal = tThis.datasourceValChange(tThis.data.dataSource, tThis.data.value, tThis);

                            tThis.data.value = tThis.data.isDefaultFirstValue && tThis.data.dataSource.length > 0 && tThis.data.value == "" ? tThis.data.dataSource[0][tThis.data.valueExpr] : _dataVal;

                            try { tThis.changeDetectorRef.detectChanges(); } catch (ex) { }
                        }, 1000);

                        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }

                        let _event_ = { value: this.data.value };
                        this.onValueChanged(_event_);
                    }
                    else {
                        this.data.value = result;
                    }

                }
                else if (sub.value == "clean_element") {

                    this.data.dataSource = [];
                    this.data.value = "";

                }
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }


        });
    }
    datasourceValChange(_datasource, _val, tThis) {
        let _valReturn = _val;
        if (_datasource.length > 0) {
            let _valFind = _datasource.find(x => x[tThis.data.valueExpr] == _val);
            if (_valFind)
                _valReturn = _valFind[tThis.data.valueExpr];
        }
        return _valReturn;
    }
    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
    }
    formComponentsScripts: any;
    formElementsScripts: any;
    isFirstLoad = true;
    ngOnInit() {

        if (typeof this.functionScript == "undefined")
            this.functionScript = { code: "", elementId: "", column: "" };
        if (typeof this.data.isSelectboxInsertManualData == "undefined")
            this.data.isSelectboxInsertManualData = false;

        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);
        this.connections = this.appInfo.RiverEntity.connections;

        if (!this.isEdit) {
            this.executeScript();
            /*setTimeout(function () {
              this.executeScript();
            }.bind(this), 1000);*/

            if (this.data.isSelectboxInsertManualData) {
                this.setRuntimeDataSource();
            }
        }
        else {

            this.fillDesignerDataSource();
        }



        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });


        if (typeof this.data.isDefaultFirstValue === "undefined")
            this.data.isDefaultFirstValue = false;

        let fff = this.data;

        // İlk açılış kuralında eğer isDatagridSetChangeEvent true verirsek processformdata verisi açılırken yanlış gidiyor.
        let isDatagridSetChangeEvent = false
        if (this.componentService.currentProcessFormId == 0) {
            isDatagridSetChangeEvent = true;
        }
        //let _thisVal = this.data.value;
        setTimeout(function () { this.isFirstLoad = true; }.bind(this), 1000);

    }

    //changeDataFunctionScript() {
    //    this.data.functionScripts.forEach(f => {
    //        let result = eval(f.code.replace(/[\u200B-\u200D\uFEFF]/g, ''));
    //        this.eventEmitterService.selectboxChangeEvent (this.data.id, f.elementId, result, result); 
    //    });
    //}

    disableElements() {
        return typeof this.data.disabled != "undefined" ? (this.data.disabled == false ? this.data.outsideDisabled : this.data.disabled) : false;
    }

    fillDesignerDataSource() {
        this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };

        this.genericService.fillDesignerDataSource().subscribe((result: any) => {

            this.designerDataSourceList = result;
            this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };
            if (this.data.desktopDatasource != null) {
                this.designerDatasourceModel = this.designerDataSourceList.find(x => x.ID == this.data.desktopDatasource);

                if (typeof this.designerDatasourceModel.ID === "undefined")
                    this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };
            }

            if (!this.isEdit) {

                this.genericServiceExecuteScript(this.designerDatasourceModel.SQLSTR, this.designerDatasourceModel.CONNID);
            }

        });

    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {

        this.data.desktopDatasource = this.designerDatasourceModel.ID;

        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);

            /*if (this.data.dataSourceKey == "" && this.data.dataSource.length <= 0)
              this.componentService.showAlert("Uyarı! Henüz veri eklenmedi.", "warning", 4000);*/
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    resetDataSource() {
        this.data.dataSource = [];
        this.data.dataSourceKey = "";
    }

    onChangeDatasourceValue(event) {
        if (event.value) {

            this.designerDatasourceModel = this.designerDataSourceList.find(x => x.ID == event.value);

            //  this.designerDatasourceModel = { ID: 0, NAME: "", SQLSTR: "" };

        }
    }

    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace).replace(/[\u200B-\u200D\uFEFF]/g, '');
    }
    onValueChanged(event) {
        let _data = { element: this.data.id, value: event.value, elementLabel: this.defaultLanguage.Label, filteredElement: this.data.selectedDatagrid, filteredColumn: undefined };
        if (event.value) {

            if (typeof event.previousValue != "undefined" && event.previousValue != null && event.previousValue != event.value) {
                this.clearScriptRecursive(this.data.id, false);
            }

            //this.eventEmitterService.onValueChangeEvent(this.data.id, "clean_element", event.value, null);

            this.data.value = event.value;

            let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
            let curentComponents = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);


            if (this.data.selectedDatagrid && this.data.selectedDatagrid != "") {
                this.eventEmitterService.ChangeFilterOutElement(_data);
            }

            if (typeof this.data.functionScripts != "undefined") {

                let galleryArray = [];



                this.data.functionScripts.forEach(f => {
                    let fCode = f.code.replace(/[\u200B-\u200D\uFEFF]/g, '');
                    fCode = this.replaceAll(fCode, "{" + this.data.id + "}", this.data.value);
                    curentComponents.forEach(c => {
                        if (c.id != this.data.id) {
                            let cId = "{" + c.id + "}";
                            fCode = this.replaceAll(fCode, cId, c.value);
                        }
                    });

                    let fCompm = curentComponents.find(x => x.id == f.elementId);
                    if (fCompm.type == "gallery") {
                        //f.column;
                        if (fCode.indexOf('"[') > -1 || fCode.indexOf("'[") > -1) {

                            let result = eval(fCode);   //"[IDs]*1"  

                            let galArrayObj = galleryArray.find(l => l.element == f.elementId);
                            if (galArrayObj) {
                                galArrayObj.values.push({ value: result, column: f.column });
                            }
                            else {
                                let resVal = { element: f.elementId, values: [] };
                                resVal.values.push({ value: result, column: f.column });
                                galleryArray.push(resVal);
                            }

                            //let resVal = { element: f.elementId, values: [] };//{ value: result, column: f.column}
                            //galleryArray.push();

                        }

                    }
                    else {
                        let result = eval(fCode);
                        this.eventEmitterService.selectboxChangeEvent(this.data.id, f.elementId, result, result);
                    }

                });

                galleryArray.forEach(f => {
                    this.eventEmitterService.galleryChangeEvent(this.data.id, f.element, f.values);
                });

            }

            this.data.sqlScripts.forEach(s => {
                let pref = "{" + this.data.id + "}";
                let value = event.value;
                let query = this.componentService.replaceAllUsingType(s.query, pref, value);// s.query.replace(pref, value);

                var pattern = /\{(\w)+\}/ig;
                var patt = new RegExp(pattern);
                if (patt.test(query)) {
                    let _match = query.match(pattern);
                    if (_match.length > 0) {
                        _match.forEach(f => {
                            let _k = curentComponents.find(k => k.id == f.replace("{", "").replace("}", ""));
                            if (_k) {
                                let _value = _k.value == null ? "" : _k.value;
                                query = this.componentService.replaceAllUsingType(query, f, _value); // query.replace(f, _value);
                            }
                            else if (this.componentService.tempDatagridPopupComponent.components.length > 0) {
                                let _selectElement = this.componentService.tempDatagridPopupComponent.components.find(x => x.id == f.replace("{", "").replace("}", ""));
                                if (_selectElement && _selectElement.type) {
                                    let _value = _selectElement.value == null ? "" : _selectElement.value;
                                    query = this.componentService.replaceAllUsingType(query, f, _value);//query.replace(f, _value);
                                }
                            }
                        });
                    }
                    //query = this.genericService.cleanSqlSyntax(query.replace(pattern, ""))
                }


                this.genericService.executeQuerySecurited(query, s.connectionId).subscribe((result: any) => {

                    if (result) {
                        let element = this.componentService.getById(s.elementId, currentFormSelected.components, this.anotherPopupId);
                        //if (element.type) {

                        //    if (element.component == "DxDatagridSelectComponent" || element.type == "datalist" || element.type == "selectbox" || element.type == "gallery" || element.type == "datagrid" || element.type == "multiselectbox") {
                        //        if (element.type != "datagrid" || (element.type == "datagrid" && event.previousValue != null && (Object.keys(event).findIndex(x => x == "event") == -1 || typeof event.event != "undefined")))
                        //            this.eventEmitterService.selectboxChangeEvent (this.data.id, s.elementId, event.value, result);
                        //    }
                        //    else {
                        //        if (result[0] != null) {
                        //            let keys = Object.keys(result[0]);
                        //            if (keys.length > 0) {
                        //                let finalData = [];
                        //                keys.forEach(f => {
                        //                    finalData.push(result[0][f]);
                        //                });
                        //                let dataText = finalData.join(",");
                        //                this.eventEmitterService.selectboxChangeEvent (this.data.id, s.elementId, event.value, dataText);
                        //            }
                        //        }
                        //    }
                        //}

                        if (element && element.type) {
                            this.sqlScriptExecuteResult(element, event, s, result);
                        }
                        else if (this.componentService.tempDatagridPopupComponent.components.length > 0) {

                            let _selectElement = this.componentService.tempDatagridPopupComponent.components.find(x => x.id == s.elementId);
                            if (_selectElement && _selectElement.type) {
                                this.sqlScriptExecuteResult(_selectElement, event, s, result);
                            }

                        }


                    }
                });
            });

            //this.clearScriptRecursive (this.data.id);
            
            

        }
        else {
            this.data.value = ""
            if (this.data.selectedDatagrid && this.data.selectedDatagrid != "") {
                this.eventEmitterService.ChangeFilterOutElement(_data);
            }
            //this.eventEmitterService.selectboxChangeEvent (this.data.id, "clean_element", null, null);
            this.clearScriptRecursive(this.data.id);
        }

    }

    sqlScriptExecuteResult(element, event, s, result) {
        if (element.component == "DxDatagridSelectComponent" || element.type == "datalist" || element.type == "selectbox" || element.type == "gallery" || element.type == "datagrid" || element.type == "multiselectbox") {
            if (element.type != "datagrid" || (element.type == "datagrid" && event.previousValue != null && (Object.keys(event).findIndex(x => x == "event") == -1 || typeof event.event != "undefined")))
                if (this.isFirstLoad) {
                    this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, event.value, result, false);
                } else {
                    this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, event.value, result, true);
                }

            //
        }
        else {
            if (result[0] != null) {
                let keys = Object.keys(result[0]);
                if (keys.length > 0) {
                    let finalData = [];
                    keys.forEach(f => {
                        finalData.push(result[0][f]);
                    });
                    let dataText = finalData.join(",");
                    this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, event.value, dataText);
                }
            }
        }
    }

    onDataSourceValueChanged(event) {
        this.data.dataSourceKey = event.value
    }

    public executeScript() {
        if (this.data.dataStorage.connectionId > 0 && this.data.dataStorage.query != "") {
            this.genericServiceExecuteScript(this.data.dataStorage.query, this.data.dataStorage.connectionId);
        }
        else if (this.data.dataSource && this.data.dataSource.length > 0) {

            let filteredData = this.data.dataSource.filter(f => f.lang == this.lang);
            if (filteredData && filteredData.length > 0) {
                this.data.dataSource = filteredData;


                let tThis = this;
                setTimeout(() => {
                    let _dataVal = tThis.datasourceValChange(tThis.data.dataSource, tThis.data.value, tThis);
                    tThis.data.value = tThis.data.isDefaultFirstValue && tThis.data.dataSource.length > 0 && tThis.data.value == "" ? tThis.data.dataSource[0][tThis.data.valueExpr] : _dataVal;

                    try { tThis.changeDetectorRef.detectChanges(); } catch (ex) { }
                }, 1000);

                let _event_ = { value: this.data.value };
                this.onValueChanged(_event_);
            }//displayExpr
        }
        else if (this.data.desktopDatasource != null) {

            this.fillDesignerDataSource();

        }
    }

    public genericServiceExecuteScript(_Query, _ConnectionId) {
        this.genericService.executeQuerySecurited(_Query, _ConnectionId).subscribe((result: any) => {
            if (result && result.length > 0) {
                let cols = Object.keys(result[0]);
                if (cols) {
                    result.forEach(item => {
                        if (cols.length == 1) {
                            item.text = item[cols[0]];
                        }
                        else {
                            let displayList = [];
                            cols.forEach(f => {
                                if (f != this.data.valueExpr) {
                                    displayList.push(item[f]);
                                }
                            });
                            item.text = displayList.join(",");
                        }
                    });
                }
                this.data.dataSource = result;

                let tThis = this;
                setTimeout(() => {
                    let _dataVal = tThis.datasourceValChange(tThis.data.dataSource, tThis.data.value, tThis);
                    tThis.data.value = tThis.data.isDefaultFirstValue && tThis.data.dataSource.length > 0 && tThis.data.value == "" ? tThis.data.dataSource[0][tThis.data.valueExpr] : _dataVal;

                    try { tThis.changeDetectorRef.detectChanges(); } catch (ex) { }
                }, 1000);


                let _event_ = { value: this.data.value };
                this.onValueChanged(_event_);
            }
            else {
                this.data.dataSource = [];
            }
        });
    }

    getSelectboxStyle() {
        let myStyles = {};
        if (this.data.isSelectboxInsertManualData) {
            this.data.width = this.data.width == "" ? "100%" : this.data.width;
            myStyles = {
                'width': "100% !important",
                'display': "table-cell",
            };
            //myStyles["border-right"] = "0px";
            //  myStyles["display"] = "table-cell";
            //myStyles["border-left"] = "0px";
            //myStyles["border-top-left-radius"] = "0px";
            //myStyles["border-bottom-left-radius"] = "0px";
            //  myStyles["width"] = "100% !important";
        }
        //let myStyles = {
        //    'width': "100% !important",
        //    'display': "table-cell", 
        //};

        //if (this.data.suffix && this.data.suffix.length > 0) {

        //    myStyles["border-top-right-radius"] = "0px";
        //    myStyles["border-bottom-right-radius"] = "0px";
        //}

        //if (this.data.prefix && this.data.prefix.length > 0) {
        //    myStyles["border-left"] = "0px";
        //    myStyles["border-top-left-radius"] = "0px";
        //    myStyles["border-bottom-left-radius"] = "0px";
        //}

        return myStyles;
    }

    runtimeDatasourcePopup = false;
    saveRuntimeDataSource() {
        this.data.valueExpr = "value";
        this.data.displayExpr = "text";
        this.runtimeDatasourcePopup = false;

        this.selectBoxItem.instance.getDataSource().reload();
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        this.setRuntimeDataSource();
    }
    setRuntimeDataSource() {

        let runtimeSelectbox = this.componentService.runtimeSelectboxDatasource.find(x => x.componentId == this.data.id);
        if (runtimeSelectbox) {
            runtimeSelectbox.dataSource = this.data.dataSource;
        }
        else {
            this.componentService.runtimeSelectboxDatasource.push({ componentId: this.data.id, dataSource: this.data.dataSource });
        }
    }
    selectboxInsertManualData(eVal) {
        if (this.data.isSelectboxInsertManualData) {
            this.data.dataStorage.connectionId = 0;
            this.data.dataStorage.query = "";
            this.data.desktopDatasource = null;
            this.data.isManual = true;
        }
    }
    selectboxInsertManualDataPopup() {
        this.data.isManual = true;
        this.runtimeDatasourcePopup = true;
    }
    openDataPopup(type) {
        this.isFromDataSource = type == "1";
        if (this.isFromDataSource) {
            this.data.isManual = false;
            let selectboxes = this.componentService.getComponentsByType("selectbox", this.anotherPopupId);
            this.selecboxComponents = [];
            if (selectboxes) {
                selectboxes.forEach(item => {
                    if (item.id != this.data.id) {
                        let element: any = {};
                        element.id = item.id;
                        element.name = this.componentService.getLang(this.lang, item.language).Label;
                        this.selecboxComponents.push(element);
                    }
                });
            }
            //this.dataSources = this.componentService.getComponentsByType("datasource");
        }
        else {
            this.data.isManual = true;
        }

        this.dataSourcePopup = true;
    }

    saveDataSource() {
        if (this.data.isManual && this.data.dataStorage.connectionId > 0 && this.data.dataStorage.query != "") {
            let msg = "<i>" + this.languageLocal.messageSqlLinkDeleted + "</i>"
            let result = confirm(msg, this.languageLocal.warning);
            result.then((dialogResult) => {
                if (dialogResult) {
                    this.data.dataStorage.connectionId = 0;
                    this.data.dataStorage.query = "";
                    this.dataSourcePopup = false;
                    this.data.valueExpr = "value";
                    this.data.displayExpr = "text";
                }
            });
        }
        else {

            if (this.data.isManual) {
                this.data.valueExpr = "value";
                this.data.displayExpr = "text";
            }

            this.dataSourcePopup = false;
        }
    }

    resetDataSources() {
        this.data.dataSourceKey = "";
        this.data.dataSource = [];

        notify(this.languageLocal.notifyDataReset, "success", 3000);
    }

    apply() {
        this.executeScript();
    }

    prepareGrid() {
        new CustomStore({
            key: "Id",
            load: () => this.data.dataSource,
            insert: (values) => this.insert(JSON.stringify(values)),
            update: (key, values) => this.update(key, JSON.stringify(values)),
            remove: (key) => this.remove(key)
        });
    }

    executeExp() {
        this.eventEmitterService.executeExpretion(this.anotherPopupId);
    }

    insert(values: any = {}): any {
        this.data.dataSource.push(values);
    }

    update(key, values: any = {}): any {
        const index = this.data.dataSource.findIndex(item => item.key === key);
        this.data.dataSource[index] = values;
    }

    remove(key): any {
        const index = this.data.dataSource.findIndex(item => item.key === key);
        this.data.dataSource.splice(index, 1);
    }

    monacoOnInit() {
        this.isMonacoLoaded = true;
        let monaco = window['monaco'];
        this.setCustomKeywords(monaco);
    }

    registeredLanguage: any;
    setCustomKeywords(monaco) {
        if (this.registeredLanguage)
            this.registeredLanguage.dispose();

        this.registeredLanguage = monaco.languages.registerCompletionItemProvider('plaintext', {
            provideCompletionItems: () => {
                var suggestions = [];
                let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
                let elements = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId).map(m => m.id);
                for (let i = 0; i < elements.length; i++) {
                    suggestions.push({
                        label: elements[i],
                        kind: monaco.languages.CompletionItemKind.Snippet,
                        insertText: "" + elements[i] + "",
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        documentation: 'method'
                    })
                }
                return { suggestions: suggestions };
            }
        });
    }

    formElements: any;
    openChamgeEventPopup() {
        this.isMonacoLoaded = false;
        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        let elements = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId).map(m => m.id);
        this.formElements = elements.filter(f => f != this.data.id);
        this.sqlScriptModel = new SqlScriptModel();
        this.sqlScriptModel.query = 'SELECT * FROM TABLE WHERE KEY={' + this.data.id + '}';
        this.showChamgeEventPopup = true;
    }

    showFunctionSettingPopup: any = false;
    isMonacoFuncParentLoaded: any = false;
    functionScript = { code: "", elementId: "", column: "" };
    functionScriptIndex = -1;
    openFunctionSettingPopup() {
        //this.functionScript.id = 0;
        this.functionScriptIndex = -1;
        this.functionScript.code = "";
        this.functionScript.elementId = "";
        this.functionScript.column = "";
        this.formElementsScripts = [];

        this.isMonacoFuncLoaded = true;
        this.showFunctionSettingPopup = true;

        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        this.formComponentsScripts = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId).map(m => m.id);


        setTimeout(() => { this.isMonacoFuncParentLoaded = true; }, 1000);
    }
    saveFuncParam() {
        if (this.functionScript.code != "" && this.functionScript.elementId != "") {
            let functionScriptModel = this.componentService.cloneModel(this.functionScript);
            if (typeof this.data.functionScripts == "undefined") this.data.functionScripts = [];

            if (this.functionScriptIndex == -1) {
                this.data.functionScripts.push(functionScriptModel);
            }
            else {
                let dataFunctionScript = this.data.functionScripts[this.functionScriptIndex];
                dataFunctionScript.code = functionScriptModel.code;
                dataFunctionScript.elementId = functionScriptModel.elementId;
                dataFunctionScript.column = functionScriptModel.column;
            }
            this.functionScriptIndex = -1;
            this.functionScript.code = "";
            this.functionScript.elementId = "";
            this.functionScript.column = "";
            this.formElementsScripts = [];
        }
    }
    updateFunctionScript(index) {
        this.functionScriptIndex = index;
        let dataFunctionScript = this.data.functionScripts[this.functionScriptIndex];
        this.functionScript.code = dataFunctionScript.code;
        this.functionScript.elementId = dataFunctionScript.elementId;
        this.functionScript.column = dataFunctionScript.column;
    }
    deleteFunctionScript(index) {
        this.data.functionScripts.splice(index, 1);
    }
    isMonacoFuncLoaded: any = false;
    monacoFunctionOnInit(e) {

        this.isMonacoFuncLoaded = false;
        let monaco = window['monaco'];
        this.setCustomKeywords(monaco);
    }

    colShorting(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.data.functionScripts, event.previousIndex, event.currentIndex);
    }

    onChangeformElementsScriptsValue(e) {
        if (e.value != "") {
            let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
            let fCompms = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
            let fCompm = fCompms.find(x => x.id == e.value);
            if (fCompm.type == "gallery") {

                // = this.setFormElementsScripts(fCompm);// fCompm.storage.sqlHideColumns.map(m => m.name);
                this.setFormElementsScripts(fCompm);
                this.classFormComponent = "col-md-6";
                this.classFormElements = "col-md-6";
            }
            else {
                this.classFormComponent = "col-md-12";
                this.classFormElements = "col-md-6 hidden-element";
            }
        }
    }
    setFormElementsScripts(dataGallery) {
        let _return = [];
        if (dataGallery.storage.query != "" && dataGallery.storage.connectionStringId > 0) {
            this.genericService.executeQuerySecurited(dataGallery.storage.query, dataGallery.storage.connectionStringId).subscribe((result: any) => {
                if (result && result.length > 0) {

                    let allColumnsSql = Object.keys(result[0]);
                    let keysSearch = [];
                    keysSearch = allColumnsSql.filter(f =>
                        f != dataGallery.storage.keyColumn);

                    keysSearch.forEach(item => {
                        this.formElementsScripts.push(item);
                    });

                }

            });
        }

    }
    //isGalleryType = false;
    onChangeFormElementsValue(e) {
        // 
        //this.isGalleryType = false;
        let _val = e.value;
        if (_val) {

            //let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
            //let elements = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
            //let _valType = elements.find(f => f.id == _val);
            //if (_valType && _valType.type == "gallery") {
            //    this.isGalleryType = true;
            //}
        }
    }
    saveSqlScript() {
        let model = this.componentService.cloneModel(this.sqlScriptModel);

        if (model.id > 0) {
            let scr = this.data.sqlScripts.find(f => f.id == model.id);
            scr.connectionId = model.connectionId;
            scr.elementId = model.elementId;
            scr.query = model.query;
        }
        else {
            model.id = this.data.sqlScripts.length + 1;
            this.data.sqlScripts.push(model);
        }

        this.sqlScriptModel = new SqlScriptModel();
        this.sqlScriptModel.query = 'SELECT * FROM TABLE WHERE KEY={' + this.data.id + '}';
    }

    updateSqlScript(i) {
        let scr = this.data.sqlScripts[i];
        this.sqlScriptModel = new SqlScriptModel();
        this.sqlScriptModel.id = scr.id;
        this.sqlScriptModel.connectionId = scr.connectionId;
        this.sqlScriptModel.elementId = scr.elementId;
        this.sqlScriptModel.query = scr.query;
    }

    deleteSqlScript(i) {
        this.data.sqlScripts.splice(i, 1);
    }

    clearScriptRecursive(componentId, isShowComfirm = true) {

        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        let curentComponents = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
        let currentComp = curentComponents.find(el => el.id == componentId)

        if (currentComp) {
            this.clearScriptCode(componentId, currentComp, isShowComfirm);
        }
        else if (this.componentService.tempDatagridPopupComponent.components.length > 0) {

            if (this.componentService.datagridColumnHasUserChanges[componentId] == false) {
                let _selectElement = this.componentService.tempDatagridPopupComponent.components.find(x => x.id == componentId);
                this.clearScriptCode(componentId, _selectElement, isShowComfirm);
            }
            else {

            }
        }

    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // Tüm charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    clearScriptCode(componentId, currentComp, isShowComfirm = true) {
        if (this.data.id != componentId) {
            this.eventEmitterService.selectboxChangeEvent(this.data.id, componentId, "clean_element", null, isShowComfirm);
        }

        if (typeof currentComp.sqlScripts != "undefined" && typeof currentComp.sqlScripts.length != "undefined" && currentComp.sqlScripts.length > 0) {
            currentComp.sqlScripts.forEach(el => {
                this.clearScriptRecursive(el.elementId, isShowComfirm)
            })
        }
    }


    onChangeSelectedDatagrid(event) {

    }

}
