import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { Subscription } from 'rxjs';
import { AppInfoService, GenericService } from '../../shared/services';
import { debug } from 'console';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

export class SqlScriptTextboxModel {
    id: number;
    connectionId: number;
    elementId: string;
    query: string;
}

@Component({
    selector: 'textbox-component',
    templateUrl: 'textbox.component.html',
    styleUrls: ['./textbox.component.css']
})
export class DxTextboxComponent implements ComponentParameters, OnInit, OnDestroy {
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;
    @Input() anotherPopupId: number = 0;

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;

    showDisplay: any = "flex";
    showDisplayBoolean = false;

    classFormComponent = "col-md-12";
    classFormElements = "col-md-6 hidden-element";

    systemInfoPage: any;
    systemValTemp: any = "";
    systemValTempActive: any = false;

    designerDataSourceList: any;
    designerDatasourceModel: any;
    getTextboxStyles: any;
    invokeChangeEventLocal: Subscription;
    invokeSaveSystemInfoEventLocal: Subscription;
    languageLocal: any = "";

  /*  rules = {
        f: /[A-Za-z]/g, //yalnız harf
        g: /\D/g,      //harf ve sembol
        h: /[.*?\d-$€'"(){}&%#£!><|½^]/g,           //numara ve sembol
        j:/./g,           //hepsi
    } */

    stringInfo = [
        { value: "all", text: "Hepsi" },
        { value: "letter", text: "Yalnız Harf" },
        { value: "number", text: "Yalnız Sayı" },
        { value: "letterandnumber", text: "Harf ve Numara" },
        { value: "letterandsymbol", text: "Harf ve Sembol" },
        { value: "numberandsymbol", text: "Numara ve Sembol" },
    ];
    fontWeight = [{ value: "normal", text: "Normal" }, { value: "bold", text: "Bold" }]
    fontStyle = [{ value: "normal", text: "Normal" }, { value: "italic", text: "Italic" }]

    constructor(public appInfo: AppInfoService, private eventEmitterService: EventEmitterService,
        public changeDetectorRef: ChangeDetectorRef,
        private genericService: GenericService,
        public componentService: ComponentService) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });

        this.languageLocal = this.componentService.getLanguageSource(); //component services'ten geliyor. Formbuilder set ediyorum.
        this.setLanguageToDatas()

        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {

            if (sub.elementId == this.data.id) {
                if (!this.data.isUserChange) {
                    if (sub.data) this.data.value = sub.data;
                    else this.data.value = "";
                    this.data.isUserChange = false;
                    try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                }
            }

        });
         
        this.invokeSaveSystemInfoEventLocal = this.eventEmitterService.invokeSaveSystemInfoEvent.subscribe(sub => {
            if (sub.elementId == this.data.id) {
                if (this.data.value == "" && this.systemValTemp != "") {
                    
                    this.data.value = this.systemValTemp;
                    this.systemValTempActive = true;
                    this.getTextboxStyles = this.getTextboxStylesFunc();
                    try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                    this.changeDetectorRef.markForCheck();
                }
                //else
                //    this.systemValTempActive = false;
            }
        });

        this.systemInfoPage = this.componentService.systemInfoElements;

    }
    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
        this.invokeSaveSystemInfoEventLocal.unsubscribe();
    }

    connections: any = [];
    ngOnInit() {
        //
        if (typeof this.functionScript == "undefined")
            this.functionScript = { code: "", elementId: "", column: "" };

        this.data.isBarcode = typeof this.data.isBarcode === "undefined" ? false : this.data.isBarcode;

        this.connections = this.appInfo.RiverEntity.connections;

        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (this.data.isAuto) {
            this.data.disabled = true;
            //this.data.value = this.data.autoText + this.componentService.currentForm.formNo;
        }
        this.data.upperCaseLetter = this.data.upperCaseLetter == null ? false : this.data.upperCaseLetter;

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {

                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });
        
        this.data.systemInfoId = typeof this.data.systemInfoId == "undefined" ? 0 : this.data.systemInfoId;
        if (this.data.value == "{|STARTED-USER-NAME|}" ||
            this.data.value == "{|STARTED-USER-ID|}" ||
            this.data.value == "{|START-DATE|}" ||
            this.data.value == "{|PROCESS-INSTANCE-ID|}" ||
            this.data.value == "{|FORM-ID|}") {
            this.data.value = "";
            this.systemValTemp = this.systemInfoPage.find(x => x.id == this.data.systemInfoId).value; 
        }

        if (this.isEdit) {
            this.componentService.currentSystemInfo.push({ id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) });
        }

        this.data.stringType = typeof this.data.stringType == "undefined" ? "all" : this.data.stringType;
        this.data.fontWeight = typeof this.data.fontWeight == "undefined" ? "normal" : this.data.fontWeight;
        this.data.fontStyle = typeof this.data.fontStyle == "undefined" ? "normal" : this.data.fontStyle;

        this.designerDatasourceModel = { ID: 0, NAME: "", SQLSTR: "" };
        this.fillDesignerDataSource();

        this.getTextboxStyles = this.getTextboxStylesFunc();

        let _this = this;
        setTimeout(() => {
            if (_this.data.systemInfoId != 0) {
                _this.onEnterKey(null);
            }
        }, 1500);

        this.data.isUserChange = typeof this.data.isUserChange == "undefined" ? false : this.data.isUserChange;
       // let length = this.data.maxLength;
        //this.data.mask.length = length;
         
    }

    showChamgeEventPopup: boolean = false;
    sqlScriptModel: SqlScriptTextboxModel;
    isMonacoLoaded: boolean = false;
    formElements: any;
    openChamgeEventPopup() {
        this.isMonacoLoaded = false;
        let elements = this.componentService.getComponentList(this.componentService.currentForm.components, this.anotherPopupId).map(m => m.id);
        this.formElements = elements.filter(f => f != this.data.id);
        this.sqlScriptModel = new SqlScriptTextboxModel();
        this.sqlScriptModel.query = 'SELECT * FROM TABLE WHERE KEY={' + this.data.id + '}';
        this.showChamgeEventPopup = true;
    }

    saveSqlScript() {
        let model = this.componentService.cloneModel(this.sqlScriptModel);

        if (model.id > 0) {
            let scr = this.data.sqlScripts.find(f => f.id == model.id);
            scr.connectionId = model.connectionId;
            scr.elementId = model.elementId;
            scr.query = model.query;
        }
        else {
            if (typeof this.data.sqlScripts == "undefined")
                this.data.sqlScripts = [];

            model.id = this.data.sqlScripts.length + 1;
            this.data.sqlScripts.push(model);
        }

        this.sqlScriptModel = new SqlScriptTextboxModel();
        this.sqlScriptModel.query = 'SELECT * FROM TABLE WHERE KEY={' + this.data.id + '}';
    }

    updateSqlScript(i) {
        let scr = this.data.sqlScripts[i];
        this.sqlScriptModel = new SqlScriptTextboxModel();
        this.sqlScriptModel.id = scr.id;
        this.sqlScriptModel.connectionId = scr.connectionId;
        this.sqlScriptModel.elementId = scr.elementId;
        this.sqlScriptModel.query = scr.query;
    }

    deleteSqlScript(i) {
        this.data.sqlScripts.splice(i, 1);
    }


    formComponentsScripts: any;
    formElementsScripts: any;

    showFunctionSettingPopup: any = false;
    isMonacoFuncParentLoaded: any = false;
    functionScript = { code: "", elementId: "", column: "" };
    functionScriptIndex = -1;
    openFunctionSettingPopup() {
        //this.functionScript.id = 0;
        this.functionScriptIndex = -1;
        this.functionScript.code = "";
        this.functionScript.elementId = "";
        this.functionScript.column = "";
        this.formElementsScripts = [];

        this.isMonacoFuncLoaded = true;
        this.showFunctionSettingPopup = true;

        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        this.formComponentsScripts = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId).map(m => m.id);


        setTimeout(() => { this.isMonacoFuncParentLoaded = true; }, 1000);
    }
    saveFuncParam() {
        if (this.functionScript.code != "" && this.functionScript.elementId != "") {
            let functionScriptModel = this.componentService.cloneModel(this.functionScript);
            if (typeof this.data.functionScripts == "undefined") this.data.functionScripts = [];

            if (this.functionScriptIndex == -1) {
                this.data.functionScripts.push(functionScriptModel);
            }
            else {
                let dataFunctionScript = this.data.functionScripts[this.functionScriptIndex];
                dataFunctionScript.code = functionScriptModel.code;
                dataFunctionScript.elementId = functionScriptModel.elementId;
                dataFunctionScript.column = functionScriptModel.column;
            }
            this.functionScriptIndex = -1;
            this.functionScript.code = "";
            this.functionScript.elementId = "";
            this.functionScript.column = "";
            this.formElementsScripts = [];
        }
    }
    updateFunctionScript(index) {
        this.functionScriptIndex = index;
        let dataFunctionScript = this.data.functionScripts[this.functionScriptIndex];
        this.functionScript.code = dataFunctionScript.code;
        this.functionScript.elementId = dataFunctionScript.elementId;
        this.functionScript.column = dataFunctionScript.column;
    }
    deleteFunctionScript(index) {
        this.data.functionScripts.splice(index, 1);
    }
    isMonacoFuncLoaded: any = false;
    monacoFunctionOnInit(e) {

        this.isMonacoFuncLoaded = false;
        //let monaco = window['monaco'];
        //this.setCustomKeywords(monaco);
    }

    colShorting(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.data.functionScripts, event.previousIndex, event.currentIndex);
    }


    onChangeformElementsScriptsValue(e) {
        if (e.value != "") {
            let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
            let fCompms = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
            let fCompm = fCompms.find(x => x.id == e.value);
            if (fCompm.type == "gallery") {

                // = this.setFormElementsScripts(fCompm);// fCompm.storage.sqlHideColumns.map(m => m.name);
                this.setFormElementsScripts(fCompm);
                this.classFormComponent = "col-md-6";
                this.classFormElements = "col-md-6";
            }
            else {
                this.classFormComponent = "col-md-12";
                this.classFormElements = "col-md-6 hidden-element";
            }
        }
    }

    setFormElementsScripts(dataGallery) {
        let _return = [];
        if (dataGallery.storage.query != "" && dataGallery.storage.connectionStringId > 0) {
            this.genericService.executeQuerySecurited(dataGallery.storage.query, dataGallery.storage.connectionStringId).subscribe((result: any) => {
                if (result && result.length > 0) {

                    let allColumnsSql = Object.keys(result[0]);
                    let keysSearch = [];
                    keysSearch = allColumnsSql.filter(f =>
                        f != dataGallery.storage.keyColumn);

                    keysSearch.forEach(item => {
                        this.formElementsScripts.push(item);
                    });

                }

            });
        }

    }

    onEnterKey(event) {
        if (this.data.value && this.data.sqlScripts) {
            if (this.data.upperCaseLetter) { this.data.value = this.data.value.toUpperCase() }
            //this.data.value = event.value;
            this.data.sqlScripts.forEach(s => {
                let pref = "{" + this.data.id + "}";
                let value = this.data.value;
                let query = this.componentService.replaceAllUsingType(s.query, pref, value); // s.query.replace(pref, value);

                let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
                let currentComponents = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId)

                var pattern = /\{(\w)+\}/ig;
                var patt = new RegExp(pattern);
                if (patt.test(query)) {
                    let _match = query.match(pattern);
                    if (_match.length > 0) {
                        _match.forEach(f => {
                            let _k = currentComponents.find(k => k.id == f.replace("{", "").replace("}", ""));
                            if (_k) {
                                let _value = _k.value == null ? "" : _k.value;
                                query = this.componentService.replaceAllUsingType(query, f, _value);//query.replace(f, _value);
                            }
                        });
                    }
                    //query = this.genericService.cleanSqlSyntax(query.replace(pattern, ""))
                }


                this.genericService.executeQuerySecurited(query, s.connectionId).subscribe((result: any) => {
                   
                    if (result) {
                        let element = this.componentService.getById(s.elementId, currentComponents, this.anotherPopupId);
                        if (element.type) {
                            
                            if (element.component == "DxDatagridSelectComponent" || element.type == "datalist" || element.type == "selectbox" || element.type == "gallery" || element.type == "datagrid" || element.type == "multiselectbox") {
                                this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, this.data.value, result);
                            }
                            else {
                                let keys = Object.keys(result[0]);
                                if (keys.length > 0) {
                                    let finalData = [];
                                    keys.forEach(f => {
                                        finalData.push(result[0][f]);
                                    });
                                    let dataText = finalData.join(",");
                                    this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, this.data.value, dataText);
                                }
                            }
                        }
                    }
                });
            });
        }
        else {
            this.eventEmitterService.selectboxChangeEvent(this.data.id, "clean_element", null, null);
        }
    }



    disableElements() {
        // data.systemInfoId != 0 
        return this.data.systemInfoId == 0 ? (typeof this.data.disabled != "undefined" ? (this.data.disabled == false ? this.data.outsideDisabled : this.data.disabled) : false) : true;
    }
    fillDesignerDataSource() {
        this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };

        this.genericService.fillDesignerDataSource().subscribe((result: any) => {

            this.designerDataSourceList = result;
            this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };
            if (this.data.desktopDatasource != null) {
                this.designerDatasourceModel = this.designerDataSourceList.find(x => x.ID == this.data.desktopDatasource);

                if (typeof this.designerDatasourceModel.ID === "undefined")
                    this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };
            }

            if (!this.isEdit && this.componentService.currentProcessFormId == 0 && typeof this.data.desktopDatasource != "undefined"
                && this.designerDatasourceModel.SQLSTR != "") {

                this.genericServiceExecuteScript(this.designerDatasourceModel.SQLSTR, this.designerDatasourceModel.CONNID);
            }

        });

    }

    public genericServiceExecuteScript(_Query, _ConnectionId) {
        this.genericService.executeQuerySecurited(_Query, _ConnectionId).subscribe((result: any) => {

            if (result && result.length > 0) {
                this.data.value = result[0][this.data.valueExpr];
                try {
                    this.changeDetectorRef.detectChanges();
                }
                catch (ex) { }
            }
            else {
                if (this.data.value != "") {
                    this.data.value = "";
                    this.data.dataSource = [];
                }
            }
        });
    }
    onChangeDatasourceValue(event) {
        if (event.value) {

            this.designerDatasourceModel = this.designerDataSourceList.find(x => x.ID == event.value);
            this.genericServiceExecuteScript(this.designerDatasourceModel.SQLSTR, this.designerDatasourceModel.CONNID);
            //  this.designerDatasourceModel = { ID: 0, NAME: "", SQLSTR: "" };

        }
    }
    systemInfoChange() {
    }
    /*
    stringInfoChange($event) {
        let selected = $event.value;


        let a = "";
       
        this.data.mask = "";
        if (selected == "Yalnız Harf") {
            for (let i = 0; i < this.data.maxLength; i++) {
                a += "C"
            }
        }
        else if (selected == "Harf ve Sembol") { this.data.mask = "g"; }
        else if (selected == "Numara ve Sembol") { this.data.mask = "h"; }
        else if (selected == "Hepsi") { this.data.mask = "j"; }
        

        this.data.mask = a; 

    } */
    /*
    rules = {
        f: /[A-Za-z]/g, //yalnız harf
        g: /\D/g,      //harf ve sembol
        h: /[.*?\d-$€'"(){}&%#£!><|½^]/g,           //numara ve sembol
        j: /./g,           //hepsi

    }
    stringInfo = [
        { value: "all", text: "Hepsi" },
        { value: "letter", text: "Yalnız Harf" },
        { value: "letterandsymbol", text: "Harf ve Sembol" },
        { value: "letterandnumber", text: "Numara ve Sembol" }
    ];
    
    */

    tempValue = undefined

    onKeyDown($event) {
        this.tempValue = JSON.parse(JSON.stringify(this.data.value))
        if (this.data.stringType != "all") {
            let keyCode = $event.event.keyCode;
            let str = [";", ""]
            // 8 backspace 13 enter 37-40 arrows
            if (keyCode == 8 || keyCode == 13 || (keyCode >= 37 && keyCode <= 40) ||(keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // Tüm charlar girecek
                let regex = /./ // Her karakter kabul edilcek

                if (this.data.stringType == "letter") { regex = /[A-Za-z]/g }
                else if (this.data.stringType == "letterandsymbol") { regex = /\D/g }
                else if (this.data.stringType == "number") { regex = /^\d+$/ }
                else if (this.data.stringType == "numberandsymbol") { regex = /[.*?\d-$€'"(){}&%#£!><|½^]/g }
                else if (this.data.stringType == "letterandnumber") { regex = /[\w\d]/g }
                if (!(keyCode == 8 || keyCode == 13 || (keyCode >= 37 && keyCode <= 40))){
                    let test = regex.test($event.event.key);
                    if (!test) {
                        $event.event.preventDefault();
                    }
                }
                
            } 
        }
        
    }

    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // Tüm charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
/**/
    //"Yalnız Harf", "Harf ve Sembol", "Numara ve Sembol", "Hepsi"
    onEditPopopShown() {

        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        this.data.desktopDatasource = this.designerDatasourceModel.ID;
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);

            let systemInfoTemp = this.componentService.currentSystemInfo.find(x => x.id == this.data.id);
            if (systemInfoTemp)
                this.componentService.currentSystemInfo.find(x => x.id == this.data.id).value = this.systemInfoPage.find(x => x.id == this.data.systemInfoId);
            else
                this.componentService.currentSystemInfo.push({ id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) });
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000);
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }
    getTextboxStylesFunc() {//text-transform: uppercase

        let myStyles = {
            'text-transform': this.data.upperCaseLetter != null && this.data.upperCaseLetter != false ? 'uppercase' : 'none',
            'font-size': this.systemValTempActive ? "0px" : "",
            'font-weight': this.data.fontWeight,
            'font-style': this.data.fontStyle,
        };
        return myStyles;
    }
    cancelSetting() {
       // let msg = "<i>Yaptığınız değişikleri kaydetmeden çıkmak istediğinizden emin misiniz?</i>"
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"

        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);


                let systemInfoTemp = this.componentService.currentSystemInfo.find(x => x.id == this.data.id);
                if (systemInfoTemp)
                    this.componentService.currentSystemInfo.find(x => x.id == this.data.id).value = this.systemInfoPage.find(x => x.id == this.data.systemInfoId);
                else
                    this.componentService.currentSystemInfo.push({ id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) });
                // this.componentService.currentSystemInfo = { id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) };//this.systemInfoPage.find(x => x.id == this.data.systemInfoId);
            }
        });
    }



    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace).replace(/[\u200B-\u200D\uFEFF]/g, '');
    }

    onValueChanged(e) {

        if (this.data.upperCaseLetter) { this.data.value = this.data.value.toUpperCase();}

        this.eventEmitterService.onValueChangeEvent(this.data.id, "clean_element", e.value, null);

        this.onEnterKey(null);

        if (typeof this.data.functionScripts != "undefined") {

            let galleryArray = [];

            let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
            let curentComponents = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);

            this.data.functionScripts.forEach(f => {
                let fCode = f.code.replace(/[\u200B-\u200D\uFEFF]/g, '');
                fCode = this.replaceAll(fCode, "{" + this.data.id + "}", this.data.value);
                curentComponents.forEach(c => {
                    if (c.id != this.data.id) {
                        let cId = "{" + c.id + "}";
                        fCode = this.replaceAll(fCode, cId, c.value);
                    }
                });

                let fCompm = curentComponents.find(x => x.id == f.elementId);
                if (fCompm.type == "gallery") {
                    //f.column;
                    if (fCode.indexOf('"[') > -1 || fCode.indexOf("'[") > -1) {
                        try {
                            let result = eval(fCode);   //"[IDs]*1"  

                            let galArrayObj = galleryArray.find(l => l.element == f.elementId);
                            if (galArrayObj) {
                                galArrayObj.values.push({ value: result, column: f.column });
                            }
                            else {
                                let resVal = { element: f.elementId, values: [] };
                                resVal.values.push({ value: result, column: f.column });
                                galleryArray.push(resVal);
                            }
                        } catch (ex) { }
                        //let resVal = { element: f.elementId, values: [] };//{ value: result, column: f.column}
                        //galleryArray.push();

                    }

                }
                else {
                    let result = eval(fCode);
                    this.eventEmitterService.selectboxChangeEvent(this.data.id, f.elementId, result, result);
                }

            });

            galleryArray.forEach(f => {
                this.eventEmitterService.galleryChangeEvent(this.data.id, f.element, f.values);
            });

        }
        
        this.isUserChangeCheck();

    }

    isUserChangeCheck() {
        if (this.data.value == "") {     // UserChange sıfırlanmak istenirse
            //this.data.isUserChange = false;
            //this.tempValue = undefined;
        } else {
            if (typeof this.tempValue != "undefined" && this.tempValue != this.data.value) {
                this.data.isUserChange = true;
               // console.log("textboxunki "+this.data.isUserChange)

            }
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        }
    }
    setLanguageToDatas() {
        console.log(this.languageLocal)
        this.stringInfo = [
            { value: "all", text: this.languageLocal.allText /*"Hepsi"*/ },
            { value: "letter", text: this.languageLocal.letter /* "Yalnız Harf" */ },
            { value: "number", text: this.languageLocal.justnumber /* "Yalnız Sayı" */},
            { value: "letterandnumber", text: this.languageLocal.letterandnumber /*"Harf ve Numara" */ },
            { value: "letterandsymbol", text: this.languageLocal.letterandsymbol /*"Harf ve Sembol"*/ },
            { value: "numberandsymbol", text: this.languageLocal.numberandsymbol /*"Numara ve Sembol"*/ },
        ];


    }
}
    