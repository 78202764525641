import { HostListener, Component, OnInit, Input, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef, Inject, OnDestroy, ComponentFactoryResolver, ViewContainerRef, EmbeddedViewRef } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DxFormResultComponent } from "../formresult/formresult.component";
import DataSource from "devextreme/data/data_source";
import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';

import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { GenericService, TaskService } from '../../shared/services';
import { AppInfoService } from '../../shared/services';
import { ComponentService } from '../component.service';
import { DxProgressBarComponent, DxDataGridComponent } from 'devextreme-angular';
//import { error } from '@angular/compiler/src/util';
import { Subscription } from 'rxjs';
import { MouseEvent, MapsAPILoader } from '@agm/core';
import { marker } from '../../shared/models/map-marker';
import { DOCUMENT } from '@angular/common';
import { InstanceService } from '../../shared/services/data/instance.service';

import Popup from "devextreme/ui/popup";
import Button from "devextreme/ui/button";
import ScrollView from "devextreme/ui/scroll_view";
import { formatNumber } from "devextreme/localization";
import { FormRender } from '../../formdesigner/_formrender/formrender';
import { dataSource, expression } from 'devexpress-dashboard/model/index.metadata';
import { auto } from '@popperjs/core';
import { debug } from 'console';
import { CellInfo } from 'devexpress-richedit/lib/core/layout-formatter/table/info/cell-info';
//import { setInterval, clearInterval } from 'timers';
export class HeaderExpression {
    code: string;
    export: boolean;
    element: string;
    influenceAllRelatedRows: boolean;
    influenceAllRelatedRowsColumn = [];
}

@Component({
    selector: 'datagrid-component',
    templateUrl: './datagrid.component.html',
    styleUrls: ['./datagrid.component.scss']
})
export class DxEtrDataGridComponent implements ComponentParameters, OnInit, AfterViewInit, OnDestroy {
    @ViewChild("dataGridEtr", { static: false }) dataGridEtr: DxDataGridComponent;
    @ViewChild("gridContainerPopup", { static: false }) gridContainerPopup: DxDataGridComponent;
    @ViewChild(DxFormResultComponent, { static: false }) formResultComponent: DxFormResultComponent;
    @ViewChild('focusDiv') focusDiv: ElementRef;
    @ViewChild("uploaderExcel") uploaderExcel: ElementRef;
    @ViewChild("progressBarExcel", { static: false }) progressBarExcel: DxProgressBarComponent;

    @Input() data: any;
    @Input() isEdit: any;
    @Input() lang: string;
    @Input() anotherPopupId: number = 0;




    // #region DEFINITIONS
    /////////
    /// DEFINITIONS
    ////////
    productsDataSource: DataSource; //COMBOBOX DENEME

    showDisplay: any = "flex";

    dbQueryTypes = { "insert": 1, "update": 2, "delete": 3, "recursiveRelatedRowEvent": -1 };

    fieldTypes: any[] = [
        { "type": "text", "value": "text", "name": "Text" },
        { "value": "double", "name": "Ondalık Sayı" },
        { "value": "int", "name": "Tam Sayı" },
        { "value": "checkbox", "name": "Checkbox" },
        { "value": "date", "name": "Date" },
        { "value": "datetime", "name": "DateTime" },
        { "value": "datagrid", "name": "Data Grid" },
        { "value": "combobox", "name": "Combobox" },
        { "value": "customableCombobox", "name": "Customable Combobox" },
        { "value": "file", "name": "File" },
        { "value": "map", "name": "Map" },
        { "value": "picturebox", "name": "Picturebox" },
        { "value": "fileviewer", "name": "File Viewer" }
    ];

    numberRoundTypes: any[] = [
        { id: "ceil", value: "Her Zaman Üste Yuvarla" },
        { id: "floor", value: "Her Zaman Alta Yuvarla" },
        { id: "round", value: "Varsayılan" }
    ];

    headers: any = {
        "name": "",
        "caption": "",
        "field": "",
        "type": "text",
        "format": "#,##0.00",
        "properties": {
            "barcode": false, "required": false, "isUserUpload": false, "autoText": { isAuto: false, autoPreText: "" }, "roundType": "round", "dateMinMax": { minToday: false, maxToday: false, dateRange: null }, "color": "", "backgroundColor": "", "fontweight": "", "rowBackgroundColor": ""
        },
        "template": "",
        "width": 150,
        "dataSourceKey": "",
        "dataSource": [],
        "displayKey": "",
        "valueKey": "",
        "funcParam": {},
        "files": [],
        "changeEvents": [],
        "expression": {},
        "dataGridSettings": {
            "connectionStringId": 0,
            "query": "",
            "key": "ID",
            "table": "",
            "formFields": []
        },
        "sqlColumnSettings": {
            "connectionStringId": 0,
            "query": "",
            "fileFields": {
                "name": "",
                "file": "",
                "fileName": "",
                "extension": ""
            }
        },

        "permissions": {
            "readonlyGroup": [],
            "readonlyUsers": [],
            "hideGroup": [],
            "hideUsers": []
        },
        "language": [
            {
                "Code": "trTR",
                "Label": "Başlık"
            }
        ]
    };

    sqlExpression = {
        "field": "",
        "connectionStringId": 0,
        "query": ""
    }

    dataSources: any = [];
    fontWeightTypes = [{ value: "normal", text: "Normal" }, { value: "bold", text: "Bold" }]
    filterOperators = ["=", "!=", "<", ">", "<=", ">="];
    operators = ["+", "-", "*", "/"];
    sqlOperators = ["=", ">", "<", ">=", "<="];
    filterOperatorsColor = {
        "string": [{ type: "like" }, { type: "startsWith" }, { type: "endsWith" }, { type: "equals" }, { type: "notIncludes" }],
        "number": [{ type: "=" }, { type: "!=" }, { type: "<" }, { type: ">" }, { type: "<=" }, { type: ">=" }],
        "object": [{ type: "=" }, { type: "equals" }]
    }



    funcParam = {
        "blockoperator": "",
        "exp": "",
        "params": {
            "param1": "",
            "param2": "",
            "operator": ""
        }
    }

    //
    funcParamData = {
        "export": false,
        "element": "",
        "formula": [],
        "influenceAllRelatedRows": false,
        "influenceAllRelatedRowsColumn": []
    }

    outOfGridComponent: any;
    showDatagridPopup: any = false;
    connections: any;
    cellInfoGlobal: any;
    users: any = [];

    formulaCommands = [
        //".dg_count"
        //".dg_sum",
        //".dg_avg"
        { id: ".dg_count", example: "{xyz.dg_count}", operation: "[count]" },
        { id: ".dg_sum", example: "{xyz.dg_sum}", operation: "[sum]" },
        { id: ".dg_avg", example: "{xyz.dg_avg}", operation: "[sum]/[count]" },
        { id: ".dg_max", example: "{xyz.dg_max}", operation: "[max]" },
        { id: ".dg_min", example: "{xyz.dg_min}", operation: "[min]" }
    ];
    //formulaCommandsExamples = ["{xyz.dg_count}", "{xyz.dg_sum}", "{xyz.dg_avg}"];

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    filterPopup: boolean = false;
    showGridColPopop: boolean = false;
    showFuncPopup: boolean = false;
    showFormatPopup: boolean = false;
    showSqlExpressioPopup: boolean = false;
    showChanEventParamsPopup: boolean = false;

    selectedGridForm: any;

    selectionGridDataSource = [];
    dataGridSettingsPopop: boolean = false;
    allowCopy: boolean = true;
    isCalcCellFormula: boolean = false;

    colPermissionPopup: boolean = false;
    colLanguagePopup: boolean = false;
    selectedColPermission: any;
    perCol: string;
    selectedColLanguage: any;
    selectedConnection: number = 0;

    selectedHeader: any;
    selectedHeaderIndex: number = -1;
    selectedFuncIndex: number = -1;
    selectedFormatIndex: number = -1;

    formatHeaderType: any = "";
    registeredLanguage: any;
    outOfGridComponentDatas: any;
    tableList: any = [];

    headerExpression: HeaderExpression;
    isMonacoLoaded: boolean = false;

    loadingVisible = false;
    Visible = false;
    loadPanelText = "Loading...";

    showPreviewFilesPopup: boolean = false;
    uploadedFiles: any = [];

    checkBoxesMode: string;
    allMode: string;
    isLoadingDatasource = true;
    countFirstLoad = 0;
    dataGridEtrDatasorce: DataSource;
    isContentReadyInterval = false;
    contentReadyEvent;
    isLoadingNotHide = false;
    focusedRowKey;
    newRowShortcut = { isActive: false, index: -1/*, page: -1*/ };

    deleteFileButtonVisible: boolean = false;
    // #endregion DEFINITIONS
    languageLocal: any = "";


    formulaCommandsExampleStr = "";
    // #region SYSTEM CODE
    /////////
    /// SYSTEMS
    ////////
    windowGlobal;
    constructor(private eventEmitterService: EventEmitterService,
        public taskService: TaskService,
        public genericService: GenericService,
        public appInfo: AppInfoService,
        private componentService: ComponentService,
        public instanceService: InstanceService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private location: ViewContainerRef,

        @Inject(DOCUMENT) private document: Document,
        //@Inject(FormRender) private formRender: FormRender,
        public changeDetectorRef: ChangeDetectorRef, private elementHtml: ElementRef) {
        this.windowGlobal = this.document.defaultView;
        this.headerExpression = new HeaderExpression();
        // this.dropDownOptions = { width: 500 };
        this.users = this.appInfo.RiverEntity.users;
        this.users.forEach(f => { f.isChecked = false });
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);//
                let currentComps = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
                let currentCompDatagrids = currentComps.filter(x => x.type == "datagrid");
                this.allDatagridComponents = currentCompDatagrids.map(m => m.id);

                this.showSettingPopup = true;
            }

        });


        if (this.componentService.currentProcessFormId != 0) {
            this.taskService.GetProcessStarterId(this.componentService.currentProcessFormId).subscribe((r: any) => {
                if (r == this.appInfo.currentUser.id)
                    this.deleteFileButtonVisible = true;
            }, error => {
            });
        }
        else
            this.deleteFileButtonVisible = true;

        this.formulaCommandsExampleStr = this.formulaCommands.map((it) => {
            return "&nbsp;&nbsp;" + it.example.replace("xyz", "").replace("{", "").replace("}", "") + "&nbsp;&nbsp;";
        }).join(' - ');

        //this.formulaCommandsExampleStr = this.formulaCommands.map(it => it.example).join(' - ');
        //.map(it => it.example.replace("xyz", "").replace("{", "").replace("}", "")).join(' - ');

        this.allMode = 'allPages';
        this.checkBoxesMode = 'onClick';

        this.languageLocal = this.componentService.getLanguageSource(); //language
        this.setLanguageToDatas()
    }

    //filteredDatagridElementSelectedRows = [];
    filteredColumnDatagrid = undefined;
    classDatagridContainer = "";
    datagridErrorFilterOut = "";
    invokeChangeEventLocal: Subscription;
    isShowComfirmShown = false;
    ngOnInit() {
        //proddo debug gerekirse diye eklendi.
        this.data.uuid = this.componentService.createuuid();

        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        for (let i = 0; i < this.data.headers.length; i++) {
            try {
                let exp = this.data.headers[i].expression;
                if (exp && typeof (exp.code) != "undefined" && exp.code != '' && !Array.isArray(exp.influenceAllRelatedRowsColumn)) {
                    if (exp.influenceAllRelatedRowsColumn != "" && exp.influenceAllRelatedRowsColumn != null && typeof exp.influenceAllRelatedRowsColumn != "undefined") {
                        exp.influenceAllRelatedRowsColumn = [exp.influenceAllRelatedRowsColumn];
                    }
                    else {
                        exp.influenceAllRelatedRowsColumn = [];
                    }
                }
            } catch { }
        }


        //currentDatagridFormGlobal
        //this.data.editablePopup = true;

        if (typeof this.data.show === "undefined") this.data.show = true;
        if (typeof this.data.editablePopup === "undefined") this.data.editablePopup = false;
        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.data.colorExpressions = typeof this.data.colorExpressions == "undefined" ? [] : this.data.colorExpressions;
        this.data.outsideElementShouldDisabled = typeof this.data.outsideElementShouldDisabled == "undefined" ? true : this.data.outsideElementShouldDisabled;

        //showIdColumn shopCopyButton showDeleteButton
        this.data.showIdColumn = typeof this.data.showIdColumn == "undefined" ? true : this.data.showIdColumn;
        this.data.shopCopyButton = typeof this.data.shopCopyButton == "undefined" ? true : this.data.shopCopyButton;
        this.data.showDeleteButton = typeof this.data.showDeleteButton == "undefined" ? true : this.data.showDeleteButton;
        this.data.showAddButton = typeof this.data.showAddButton == "undefined" ? true : this.data.showAddButton;

        this.data.filterVisible = typeof this.data.filterVisible == "undefined" ? true : this.data.filterVisible;

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {

                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";

                this.changeDetectorRef.detectChanges();

            }
        });

        this.data.selectedDatagrid = Array.isArray(this.data.selectedDatagrid) ? this.data.selectedDatagrid : [];
        if (this.data.selectedDatagrid.length > 0 && (typeof this.data.selectedDatagrid[0].sourceField == "undefined" || this.data.selectedDatagrid[0].sourceField == "")) {
            this.data.selectedDatagrid.forEach(_dSd => {
                if (typeof _dSd.sourceField == "undefined" || _dSd.sourceField == "")
                    _dSd.sourceField = "ID";
            });
        }

        this.eventEmitterService.invokeChangeFilterOutElement.subscribe(sub => {
            if (sub.data.isDelete && sub.data.filteredElement == this.data.id) {
                //value    filteredColumn
                debugger;
                try {
                    let _indices = [];
                    if (Array.isArray(sub.data.filteredColumn)) {
                        let tempData = this.componentService.cloneModel(this.data.value);
                        sub.data.filteredColumn.forEach(function (f, _iF) {
                            let _ind = tempData.reduce((r, v, i) => r.concat(v[f] == sub.data.value[_iF] ? i : []), []);
                            var c = _indices.concat(_ind);
                            _indices = c.filter((item, pos) => c.indexOf(item) !== pos);
                        });
                    }
                    else {
                        _indices = this.data.value.reduce((r, v, i) => r.concat(v[sub.data.filteredColumn] == sub.data.value ? i : []), []);
                    }
                    for (let _ii = _indices.length - 1; _ii >= 0; _ii--)
                        this.data.value.splice(_indices[_ii], 1);
                } catch {
                    debugger;
                }
            }
            else if (sub.data.element && sub.data.filteredElement == this.data.id) {
                //value    filteredColumn 
                this.classDatagridContainer = typeof sub.data.value != "undefined" && sub.data.value != null && sub.data.value != "" ? "" : "disabled-datagrid-container";
                this.datagridErrorFilterOut = typeof sub.data.value != "undefined" && sub.data.value != null && sub.data.value != "" ? "" : '(Bu Datagrid <b>"' + sub.data.elementLabel + '"</b> elementine bağlıdır.)';
                this.filteredColumnDatagrid = sub.data.filteredColumn;

                this.filterOutElement(sub.data.element, sub.data.value, sub.data.filteredColumn, sub.data.isSqlExecute == true);

            }
        });

        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {
            //if (sub.elementId == this.data.id) {

            if (this.data.id != sub.id && sub.elementId == this.data.id) {

                if (sub.isShowComfirm) {
                    let msg = "";
                    if (this.defaultLanguage && this.defaultLanguage.Label)
                        msg = '"' + this.defaultLanguage.Label + '" tablosuna ait eski veriler silinsin mi?';
                    else
                        msg = this.languageLocal.deleteOldData;


                    if (this.isShowComfirmShown == false) {
                        let result = confirm(msg, this.languageLocal.warning);
                        this.isShowComfirmShown = true;
                        result.then((dialogResult) => {
                            //if (dialogResult) {
                            //    //this.data.value = [];
                            //    //this.changeDetectorRef.detectChanges();
                            //    this.changeInvokeOutOfGridFill(sub);
                            //}
                            //else
                            this.isShowComfirmShown = false;
                            this.changeInvokeOutOfGridFill(sub, dialogResult);
                        });
                    }

                }
                else
                    this.changeInvokeOutOfGridFill(sub, true);
            }

            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }

            //  }
        });

        //this.eventEmitterService.invokeDatagridOpenEditRowPopupEvent.subscribe(sub => {
        //    let _tempRow = { row: { data: { ID: sub.data.id } } };
        //    this.editRow(_tempRow, sub.data.isPopupOpen);
        //});

        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        this.outOfGridComponent = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);//.filter(x => x.type !== "datagrid");

        this.closeSqlExpressioPopup();


        
    }

    changeInvokeOutOfGridFill(sub, allDataDelete) {

        if (sub.data && sub.data.length > 0) {
            let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
            let arraySubMultiSelect = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId).filter(x => x.id == sub.id && x.type === "multiselectbox");
            if (typeof arraySubMultiSelect != "undefined" && arraySubMultiSelect.length > 0) {
                if (allDataDelete == false) {
                    let dataValueArray: any[] = JSON.parse(JSON.stringify(this.data.value))
                    dataValueArray.forEach(e => {
                        delete e.ID
                        delete e.DefaultColumn
                        sub.data.forEach((ell, index) => {
                            if (JSON.stringify(ell) == JSON.stringify(e)) {
                                sub.data.splice(index, 1);
                            }
                        })
                    })
                }
            }

            this.outOfGridFill(sub.data, 0, allDataDelete);

            let dataCols = this.data.headers.filter(f => f.type == "combobox" || f.type == "datagrid");//
            if (dataCols && dataCols.length > 0) {
                this.prepareDataGridColumn(dataCols, 0, false);
            }
            this.forceRefreshDatagrid();

        }
        else if (sub.elementId == "clean_element") {

            this.data.dataSource = [];
            this.data.value = [];
            this.changeDetectorRef.detectChanges();
            this.dataGridEtr.instance.refresh();
        }
    }

    isFirstItemZero = false;
    outOfGridFill(_dataTemp, iDataTemp, allDataDelete) {
        let _obj = {};

        //if (allDataDelete) {
        //    this.data.value = [];

        //    this.isFirstItemZero = true;
        //    this.isFirstLoad = true;
        //    this.loadedCompCount = 0;
        //    this.ngAfterViewInit();
        //}
        if (allDataDelete) {
            var _Id = 0
            let zeroTemp = this.data.value.find(x => x.ID == _Id);
            if (typeof zeroTemp == "undefined") {
                var clonedItem = Object.assign({}, {}, { ID: _Id, DefaultColumn: 1 });
                this.data.value.splice(this.data.value.length, 0, clonedItem);
                this.data.value.sort((a, b) => a.ID - b.ID);
            }
            this.data.value = this.data.value.filter(x => x.ID == 0 && x.DefaultColumn == 1/*values.indexOf(item) === -1*/);
            this.data.userChangeColumns = [];
            this.getDatasourcePageItem();
            this.forceRefreshDatagrid();
        }

        var _ID = this.data.value.length == 0 ? 1 : (this.data.value[this.data.value.length - 1].ID) + 1;

        this.data.headers.forEach(f => {
            if (f.type == "double") {
                _obj[f.field] = parseFloat(_dataTemp[iDataTemp][f.field]);
            }
            else if (f.type == "int") {
                _obj[f.field] = parseInt(_dataTemp[iDataTemp][f.field]);
            }
            else if (f.type == "checkbox") {
                _obj[f.field] = _dataTemp[iDataTemp][f.field] == true || _dataTemp[iDataTemp][f.field] == "true" ? true : false;
            }
            else if (f.type == "map") {
                _obj[f.field] = JSON.parse("{" + _dataTemp[iDataTemp][f.field] + "}");
            }
            else if (f.type == "combobox") {
                var _dataSource = this.selectionGridDataSource.find(f => f.id == _ID && f.field == f.field);
                var _dataSourceLast = typeof _dataSource == "undefined" ? f.dataSource : _dataSource.data;

                let fieldsValue = _dataSourceLast.find(x => x[f.valueKey] == _dataTemp[iDataTemp][f.field]);
                let _val = _dataTemp[iDataTemp][f.field];
                if (fieldsValue) {
                    _val = fieldsValue[f.valueKey];
                }

                _obj[f.field] = _val;
            }
            else if (f.type == "customableCombobox") {
                var _dataSource = this.selectionGridDataSource.find(f => f.id == _ID && f.field == f.field);
                var _dataSourceLast = typeof _dataSource == "undefined" ? f.dataSource : _dataSource.data;

                let fieldsValue = _dataSourceLast.find(x => x[f.valueKey] == _dataTemp[iDataTemp][f.field]);
                let _val = _dataTemp[iDataTemp][f.field];
                if (fieldsValue) {
                    _val = fieldsValue[f.valueKey];
                }

                _obj[f.field] = _val;
            }
            else {
                _obj[f.field] = _dataTemp[iDataTemp][f.field];
            }

        });

        this.addNewRowOrClone(/*_rowIndex,*/ _obj, null);

        if (iDataTemp == 0) {
            allDataDelete = false;
            let zeroIdIndex = this.data.value.findIndex(x => x.ID == 0);
            if (zeroIdIndex != -1)
                this.data.value.splice(zeroIdIndex, 1);
        }
        //if (iDataTemp == 0) {
        //    allDataDelete = false;
        //    let zeroIdIndex = this.data.value.findIndex(x => x.ID == 0 && x.DefaultColumn == 1);
        //    if (zeroIdIndex != -1)
        //        this.data.value.splice(zeroIdIndex, 1);

        //}

        iDataTemp = iDataTemp + 1;
        if (iDataTemp < _dataTemp.length) {
            setTimeout(() => { this.outOfGridFill(_dataTemp, iDataTemp, false); }, 1);
        }
        else {
            if (this.data.selectedDatagrid && Array.isArray(this.data.selectedDatagrid) && this.data.selectedDatagrid.length > 0) {
                try {
                    this.getDatasourcePageItem();
                    this.forceRefreshDatagrid();
                }
                catch { }

                if (this.data.selectedRows.length == 0) {

                    if (this.data.value.length > 0) {
                        this.data.selectedRows = [];
                        let firstDataValueId = this.data.value[0];
                        if (firstDataValueId && firstDataValueId.ID && firstDataValueId.ID != 0) {

                            this.data.selectedRows.push(firstDataValueId.ID);
                            this.focusedRowKey = firstDataValueId.ID;
                        }
                    }
                }
                else
                    this.focusedRowKey = this.data.selectedRows[0];

                if (this.data.value.length > 0)
                    this.selectedDatagridChangeFilter(this.data.value[0]);

            }

        }
    }

    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
    }

    ngAfterViewInit() {

        if (!this.isEdit) {
            //this.isSqlQuery = true;
            //this.showLoadPane l();


            this.showLoadPanel();
            let dataCols = this.data.headers.filter(f => f.type == "combobox" || f.type == "datagrid" || f.type == "customableCombobox");//

            this.totalCompCount = dataCols.length + 1 + (this.data.value.length == 0 ? (1 + this.data.sqlExpressions.length) : 0); // combobox ve datagrid sayısı + CreateDatagridColumns + addNewRowOrClone + outOfDatagridGetSq l

            if (dataCols && dataCols.length > 0) {
                this.prepareDataGridColumn(dataCols, 0, true);
            }
            else {
                this.CreateDatagridColumns();
            }
            this.connections = this.appInfo.RiverEntity.connections;

            this.getDatasourcePageItem();

            //let dataDatagridCols = this.data.headers.filter(f => f.type == "datagrid ");
            //if (dataDatagridCols && dataDatagridCols.length > 0) {





            //}

        };
    }

    //@HostListener('window:keydown', ['$event'])
    //onKeyDown(event) {

    //    if (event.ctrlKey) {
    //        let datagridInstance = this.dataGridEtr.instance;
    //        if (event.shiftKey) { // CTRL + SHIFT => NEW ROW 

    //            if (this.loadingVisible == false) {
    //                this. addNewRowFunctio n();
    //            }

    //        }
    //        else if (event.altKey) {// CTRL + ALT => SAVE ROW 

    //            datagridInstance.saveEditData();
    //            this.forceRefreshDatagrid();
    //            datagridInstance.clearSelection();
    //            datagridInstance.option("focusedRowIndex", -1);

    //        }

    //    }
    //}


    isFirstLoadTemp = true;
    isFirstLoad = true;
    totalCompCount = 0;
    loadedCompCount = 0;
    getDatasourcePageItem() {
        this.dataGridEtrDatasorce = new DataSource({
            store: {
                type: "array",
                key: "ID",
                data: this.data.value
            }
            //, onLoadingChanged: (isLoading) => {
            //    if (isLoading /*&& this.isFirstLoadTemp*/) {
            //        //  this.dddddddd();
            //        this.showLoadPanel();
            //    }
            //    this.countFirstLoad++;
            //    //if (this.countFirstLoad == 0)   
            //    this.isLoadingDatasource = isLoading; 
            //    let tThis = this; 
            //    if (this.isFirstLoad) { 
            //        this.firstLoadingIntervalFunction(tThis); 
            //    }
            //    else {
            //        if (tThis.contentReadyEvent) {
            //            setTimeout(() => {
            //                if (!tThis.isLoadingDatasource) { 
            //                    if (tThis.contentReadyEvent.component._controllers.data._dataSource != null) {
            //                        let _intervalTotal = tThis.contentReadyEvent.component._controllers.data._dataSource.totalCount();
            //                        let _intervalLoading = tThis.contentReadyEvent.component._controllers.data._dataSource._isLoading; 
            //                        if (_intervalTotal != -1 && !_intervalLoading /*&& this.countFirstLoad > 3*/) {
            //                            // this.dddddddd1 = true;
            //                            tThis.hideLoadPanel();
            //                            tThis.isFirstLoadTemp = false;
            //                        }
            //                    }
            //                }

            //            }, 500); 
            //        }
            //    } 
            //}
        });



    }
    //dddddddd1 = false;
    //dddddddd2 = 0;
    //dddddddd() {
    //    let ggg = this;
    //    var x = setInterval(function () {
    //        ggg.dddddddd2++;
    //        if (ggg.dddddddd1) {
    //            clearInterval(x);
    //          
    //        }
    //    }, 200);//5*200=1000
    //}

    addNewRowFunction() {
        let datagridInstance = this.dataGridEtr.instance;
        this.forceRefreshDatagrid();
        var _rowIndex = this.data.value.length;
        this.newRowShortcut.isActive = true;
        this.newRowShortcut.index = _rowIndex;

        var _data = this.addNewRowOrClone(/*_rowInde x,*/null, null);
        if (!this.isFirstLoad)
            this.focusDiv.nativeElement.focus();
        var _event = { data: _data };
        this.onInitNewRow(_event);
        let _thisPage = this;

        setTimeout(() => {
            datagridInstance.saveEditData();
            if (_thisPage.data.selectedRows.length == 0) {

                if (_thisPage.data.value.length > 0) {
                    _thisPage.data.selectedRows = [];
                    let lastDataValueId = _thisPage.data.value[_thisPage.data.value.length - 1];

                    if (lastDataValueId && lastDataValueId.ID && lastDataValueId.ID != 0) {
                        _thisPage.data.selectedRows.push(lastDataValueId.ID);
                        this.focusedRowKey = lastDataValueId.ID;
                    }
                }
            }


        }, 1000);

    }

    onContentReady(e) {

        let tThis = this;
        this.contentReadyEvent = e;
        if (this.newRowShortcut.isActive) {
            if (e.component._controllers.data._dataSource != null) {
                let _indexDatagridTotalCount = e.component._controllers.data._dataSource.totalCount();
                if ((_indexDatagridTotalCount - 1) == this.newRowShortcut.index) {
                    let datagridInstance = this.dataGridEtr.instance;

                    let newRowShortcutIndex = this.newRowShortcut.index % datagridInstance.pageSize();
                    this.newRowShortcut = { isActive: false, index: -1 };
                    let _pageCount = datagridInstance.pageCount();
                    //let _asdasdasdasd111 = datagridInstance.pageIndex();
                    datagridInstance.pageIndex(_pageCount - 1);
                    let _asdasdasdasd = datagridInstance.pageIndex();

                    let headerFirst = this.data.headers[0];
                    this.propertiesUndefinedControl(headerFirst);

                    if (headerFirst.properties.autoText.isAuto != true && !this.isFirstLoad) {
                        var cellObject = datagridInstance.getCellElement(newRowShortcutIndex, headerFirst.field);
                        datagridInstance.focus(cellObject);
                        datagridInstance.editCell(newRowShortcutIndex, headerFirst.field);
                    }

                    this.firstLoadingIntervalFunction(tThis);

                }
            }

        }

    }

    firstLoadingIntervalFunction(tThis) {
        if (this.isFirstLoad) {

            var _firstLoadingInterval = setInterval(function () {
                if (tThis.totalCompCount <= tThis.loadedCompCount) {
                    clearInterval(_firstLoadingInterval);
                    tThis.isFirstLoad = false;
                    tThis.hideLoadPanel();
                }
            }, 200);

        }
        else
            tThis.hideLoadPanel();
    }
    addFilterDatagrid() {
        this.data.selectedDatagrid = Array.isArray(this.data.selectedDatagrid) ? this.data.selectedDatagrid : [];
        this.data.selectedDatagrid.push({ sourceField: "", id: "", field: "" });
    }
    removeFilterDatagrid(_index) {
        this.data.selectedDatagrid.splice(_index, 1);
    }
    selectedRowsDataOnchange = { ID: 0 };
    onGridSelectionChanged(e) {

        if (this.data.selectedDatagrid.length > 0) {
            this.selectedRowsDataOnchange = e.selectedRowsData[0];

            this.selectedDatagridChangeFilter(this.selectedRowsDataOnchange);
        }

        //if (this.data.selectedDatagrid && Array.isArray(this.data.selectedDatagrid) && this.data.selectedDatagrid.length > 0) {
        //    let _thisPage = this;
        //    this.data.selectedDatagrid.forEach(function (_item) {
        //        if (_item.id && _item.id != "") {
        //            let itemField = _item.field == null || _item.field == "" ? undefined : _item.field ;
        //            //let _sourceField = _item.sourceField == null || _item.sourceField == "" ? undefined : _item.sourceField;
        //            let dataVal = _item.sourceField == null || _item.sourceField == "" || typeof _item.sourceField == "undefined" ? e.selectedRowKeys[0] :
        //                e.selectedRowsData[0][_item.sourceField];
        //            let _data = { element: _thisPage.data.id, value: dataVal, elementLabel: _thisPage.defaultLanguage.Label, filteredElement: _item.id, filteredColumn: itemField, isDelete: false };
        //            _thisPage.eventEmitterService.ChangeFilterOutElemen t (_data);
        //        }
        //    });
        //}


    }

    closeFormatPopup() {
        this.formatHeaderType = "";
        this.selectedFormatIndex = -1;
        this.showFormatPopup = false;
    }

    cloneRow(e) {

        var tThis = this;
        var _Id = (this.data.value.length == 0 ? 1 : (this.data.value[this.data.value.length - 1].ID + 1));
        let currArray = this.selectionGridDataSource.filter(f => f.id == e.row.data.ID);
        currArray.forEach(function (item) {
            let arr: any = {};
            arr.field = item.field;
            arr.id = _Id;
            arr.colIndex = item.colIndex;
            arr.data = item.data;
            tThis.selectionGridDataSource.push(arr);
        });

        let cloneItemTemp = this.addNewRowOrClone(/*e.rowInde x,*/ e.row.data, _Id);


        let _thisPage2 = this;
        setTimeout(() => {
            _thisPage2.executeExp({ data: cloneItemTemp }, _thisPage2.dbQueryTypes.recursiveRelatedRowEvent);
        }, 1);
    }

    selectedDatagridRowId = 0;
    showEditablePopup = false;
    languageCodeEditPopup = "trTR";
    currentDatagridFormGlobal = { components: [] };
    hideEditablePopup(e) {
        this.selectedDatagridRowId = 0;
        this.currentDatagridFormGlobal = { components: [] };
    }
    shownEditablePopup() {

        let fields = Object.keys(this.componentService.datagridColumnHasUserChanges);
        setTimeout(() => {
            fields.forEach(field => {
                this.componentService.datagridColumnHasUserChanges[field] = false;
            });
        }, 1000);
        // this.componentService.datagridColumnHasUserChanges[f.elementId] = false;
    }
    saveDatagridForm(args) {

        if (!args.validationGroup.validate().isValid) {
            this.componentService.showAlert(this.languageLocal.pleaseFillFields, "error", 5000)
            return;
        }

        let _valData = this.data.value.find(x => x.ID == this.selectedDatagridRowId);

        this.currentDatagridFormGlobal.components.forEach(headerComp => {
            let columnName = headerComp.id.split("_")[2];
            let columnValue = headerComp.value;

            if (headerComp.id.split("_")[1] == "combobox" || headerComp.id.split("_")[1] == "customableCombobox") {
                //var _dataSource = this.selectionGridDataSource.find(f => f.id == _ID && f.field == f.field);
                //var _dataSourceLast = typeof _dataSource == "undefined" ? f.dataSource : _dataSource.data;

                //let fieldsValue = _dataSourceLast.find(x => x[f.valueKey] == _dataTemp[iDataTemp][f.field]);
                //let _val = _dataTemp[iDataTemp][f.field];
                //if (fieldsValue) {
                //    _val = fieldsValue[f.valueKey];
                //} 
                _valData[columnName] = columnValue + "";
            }
            else
                _valData[columnName] = columnValue;
        });

        this.forceRefreshDatagrid();
        this.componentService.datagridColumnHasUserChanges = {};
        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        for (let i = 0; i < this.data.headers.length; i++) {
            let exp = this.data.headers[i].expression;
            if (exp.export === true && typeof (exp.element) != "undefined") {
                let sum = this.componentService.setColSumToElement(exp.element, this.data.headers[i].field, this.data.value, currentFormSelected.components);
                this.eventEmitterService.setColumnSumToComponent(exp.element, sum);
            }
        }

        this.showEditablePopup = false;
    }
    editRow(e, isPopupOpen = true) {
        //let _id = e.row.data.ID;
        this.selectedDatagridRowId = e.row.data.ID;
        let _valData = this.data.value.find(x => x.ID == this.selectedDatagridRowId);
        if (this.appInfo.currentUser.deflang) this.languageCodeEditPopup = this.appInfo.currentUser.deflang.replace(/-/gi, '');
        else this.languageCodeEditPopup = "trTR";

        //  this.currentDatagridFormGlobal = { components: [] };
        this.componentService.tempDatagridPopupComponent = { components: [] };
        this.data.headers.forEach(header => {
            if (header.hide == false) {
                let headerFormComp = this.onEditPopupForm(header, _valData[header.field]);
                this.currentDatagridFormGlobal.components.push(headerFormComp);
            }
        });
        this.componentService.tempDatagridPopupComponent = this.currentDatagridFormGlobal;

        this.showEditablePopup = true;

        // let _tThis = this;
        //  setTimeout(() => { _tThis.showEditablePopup = isPopupOpen; }, 400);

        //var tThis = this;
        //var _Id = (this.data.value.length == 0 ? 1 : (this.data.value[this.data.value.length - 1].ID + 1));
        //let currArray = this.selectionGridDataSource.filter(f => f.id == e.row.data.ID);
        //currArray.forEach(function (item) {
        //    let arr: any = {};
        //    arr.field = item.field;
        //    arr.id = _Id;
        //    arr.colIndex = item.colIndex;
        //    arr.data = item.data;
        //    tThis.selectionGridDataSource.push(arr);
        //});

        //this.addNewRowOrClon e(/*e.rowInde x,*/ e.row.data, _Id);

    }
    onEditPopupForm(_header, valDataResult) {
        //date" || header.type == "datetime"
        //f.type == "double"  f.type == "int"  f.type == "combobox"  f.type == "datagrid "

        let _temel = this.componentService.cloneModel(this.componentService.componentList.find(x => x.group == "Temel"));
        let formComp = [];
        formComp = this.componentService.cloneModel(_temel.components);
        let _returnComp;//
        // 

        if (_header.type == "text") {
            _returnComp = this.componentService.cloneModel(formComp.find(x => x.type == "textbox"));
            _returnComp.value = "";
            _returnComp.isAuto = false;
            _returnComp.autoText = "";
        }
        else if (_header.type == "double") {
            _returnComp = this.componentService.cloneModel(formComp.find(x => x.type == "number"));
            _returnComp.value = 0;
            _returnComp.format = _header.format;// "#,##0.00"; 
            _returnComp.inputType = "int";
            //_returnComp.hasUserChange = true;
            _returnComp.functionScripts = [];
            _returnComp.functionScripts = this.datagridPanelJsScriptsCreate(_header);
        }
        else if (_header.type == "int") {

            _returnComp = this.componentService.cloneModel(formComp.find(x => x.type == "number"));
            _returnComp.value = 0;
            _returnComp.format = "#0";
            _returnComp.inputType = "decimal";
            //_returnComp.hasUserChange = true;
            _returnComp.functionScripts = [];
            _returnComp.functionScripts = this.datagridPanelJsScriptsCreate(_header);
        }
        else if (_header.type == "date" || _header.type == "datetime") {
            let _defaultFormat = _header.type == "date" ? "dd/MM/yyyy" : "dd/MM/yyyy HH:mm";
            let _format = _header.format && _header.format != "" && _header.format != "#,##0.00" ? _header.format : _defaultFormat;
            _returnComp = this.componentService.cloneModel(formComp.find(x => x.type == "datebox"));
            _returnComp.value = "";
            _returnComp.displayFormat = _format;
            _returnComp.inputType = _header.type;
        }
        else if (_header.type == "datagrid") { //datagrid 
            _returnComp = this.componentService.cloneModel(formComp.find(x => x.name == "Lookup"));
            _returnComp.value = "";

            _returnComp.dataStorage = { connectionId: _header.dataGridSettings.connectionStringId, query: _header.dataGridSettings.query };
            _returnComp.displayExpr = _header.displayKey;
            _returnComp.valueExpr = _header.valueKey;

            _returnComp.sqlScripts = [];
            _returnComp.sqlScripts = this.datagridPanelSqlScriptsCreate(_header);


        } else if (_header.type == "combobox") { // combobox  
            _returnComp = this.componentService.cloneModel(formComp.find(x => x.type == "selectbox"));
            _returnComp.value = "";

            _returnComp.dataStorage = { connectionId: _header.dataGridSettings.connectionStringId, query: _header.dataGridSettings.query };
            _returnComp.displayExpr = _header.displayKey;
            _returnComp.valueExpr = _header.valueKey;

            _returnComp.sqlScripts = [];
            _returnComp.sqlScripts = this.datagridPanelSqlScriptsCreate(_header);

        }
        else if (_header.type == "customableCombobox") { // customableCombobox
            _returnComp = this.componentService.cloneModel(formComp.find(x => x.type == "selectbox"));
            _returnComp.value = "";

            _returnComp.dataStorage = { connectionId: _header.dataGridSettings.connectionStringId, query: _header.dataGridSettings.query };
            _returnComp.displayExpr = _header.displayKey;
            _returnComp.valueExpr = _header.valueKey;

            _returnComp.sqlScripts = [];
            _returnComp.sqlScripts = this.datagridPanelSqlScriptsCreate(_header);

        }

        _returnComp.language = _header.language;
        for (let _i = 0; _i < _returnComp.language.length; _i++) {
            let lang = _header.language.find(x => x.Code == _returnComp.language[_i].Code);
            _returnComp.language[_i].Placeholder = lang.Label;
            _returnComp.language[_i].Hint = lang.Label;
        }

        //"language": [
        //    {
        //        "Code": "trTR",
        //        "Label": "Seçim",
        //        "Placeholder": "Seçiniz",
        //        "Hint": "Seçim"
        //    }
        //];
        if (valDataResult) {
            this.componentService.isNewSchemaPopup = false;
            _returnComp.value = valDataResult;
        }
        else
            this.componentService.isNewSchemaPopup = false;
        _returnComp.isDefaultFirstValue = typeof _header.isDefaultFirstValue == "undefined" || _header.isDefaultFirstValue == null ? false : _header.isDefaultFirstValue;
        _returnComp.required = _header.properties.required;
        _returnComp.disabled = _header.editable == false;
        _returnComp.id = "datagridheader_" + _header.type + "_" + _header.field;

        if (valDataResult)
            this.componentService.datagridColumnHasUserChanges[_returnComp.id] = true;
        else
            this.componentService.datagridColumnHasUserChanges[_returnComp.id] = false;

        return _returnComp;
    }

    datagridPanelJsScriptsCreate(_header) { //onchange olduğunda bir alan değişecek
        let _jsScripts = [];

        let headerColumnJs = this.data.headers.filter(x => x.expression.code && x.expression.code.search("{" + _header.field + "}") > -1);
        headerColumnJs.forEach(_headerFor => { // değiştirilecek komponent

            let jsExpression = _headerFor.expression;
            if (jsExpression && jsExpression.code) {
                let replacedExpression = this.componentService.cloneModel(jsExpression);
                let datagridElementId = "datagridheader_" + _headerFor.type + "_" + _headerFor.field;
                _jsScripts.push({ "code": replacedExpression.code, "elementId": datagridElementId, "column": "" });
            }
        });

        if (headerColumnJs.length == 0 && _header.expression.code && _header.expression.code.indexOf("{") == -1 && _header.expression.code.indexOf("}") == -1) {
            let datagridElementId = "datagridheader_" + _header.type + "_" + _header.field;
            _jsScripts.push({ "code": _header.expression.code, "elementId": datagridElementId, "column": "" });
        }

        let allColumns = this.data.headers.map(m => m.field);
        _jsScripts.forEach(jsScript => {
            allColumns.forEach(_column => {
                let headerColumnNewElement = this.data.headers.find(x => x.field == _column);
                let datagridColumnElementId = "datagridheader_" + headerColumnNewElement.type + "_" + headerColumnNewElement.field;
                jsScript.code = this.replaceAll(jsScript.code, "{" + _column + "}", "{" + datagridColumnElementId + "}");
            });
        });











        //let jsExpression = _header.expression;
        //let replacedExpression = this.componentService.cloneModel(jsExpression);
        //if (replacedExpression && replacedExpression.code) {
        //    let allColumns = this.data.headers.map(m => m.field);

        //    let datagridElementId = "datagridheader_" + _header.type + "_" + _header.field;

        //    _jsScripts.push({ "code": replacedExpression.code, "elementId": datagridElementId, "column": "" });

        //    //allColumns.forEach(_column => {
        //    //    let headerColumnNewElement = this.data.headers.find(x => x.field == _column);
        //    //    if (headerColumnNewElement.expression.code && headerColumnNewElement.expression.code.search("{" + _header.field + "}") > -1) {
        //    //        let datagridColumnElementId = "datagridheader_" + headerColumnNewElement.type + "_" + headerColumnNewElement.field;
        //    //        _jsScripts.push({ "code": headerColumnNewElement.expression.code, "elementId": datagridColumnElementId, "column": "" });
        //    //    }
        //    //});

        //    _jsScripts.forEach(jsScript => {
        //        allColumns.forEach(_column => {
        //            let headerColumnNewElement = this.data.headers.find(x => x.field == _column);
        //            let datagridColumnElementId = "datagridheader_" + headerColumnNewElement.type + "_" + headerColumnNewElement.field;
        //            jsScript.code = this.replaceAll(jsScript.code, "{" + _column + "}", "{" + datagridColumnElementId + "}");
        //        });
        //    });

        //}
        return _jsScripts;
    }

    datagridPanelSqlScriptsCreate(_header) {
        let _sqlScripts = [];
        let allColumns = this.data.headers.map(m => m.field);
        let sqlExpressionsFilter = this.data.sqlExpressions.filter(x => x.query.search("{" + _header.field + "}") > -1);

        let _iSql = 0;
        sqlExpressionsFilter.forEach(exp => {
            _iSql = _iSql + 1;

            let replacedQuery = this.componentService.cloneModel(exp.query);
            let headerElement = this.data.headers.find(x => x.field == exp.field);
            let datagridElementId = "datagridheader_" + headerElement.type + "_" + exp.field;
            allColumns.forEach(_column => {
                let headerColumnNewElement = this.data.headers.find(x => x.field == _column);
                let datagridElementId = "datagridheader_" + headerColumnNewElement.type + "_" + headerColumnNewElement.field;
                replacedQuery = this.componentService.replaceAllUsingType(replacedQuery, "{" + _column + "}", "{" + datagridElementId + "}");// exp.query.replace();
            });

            _sqlScripts.push({ "query": replacedQuery, "connectionId": exp.connectionStringId, "elementId": datagridElementId, "id": _iSql });

        });
        return _sqlScripts;
    }
    addNewRowOrClone(/*rowInde x,*/ data, _ID) {
        let firstIdTemp = 1;
        if (this.isFirstItemZero) {
            firstIdTemp = 0;
            this.isFirstItemZero = false;
        }

        let _DefaultColumn = 0;
        if (this.data.value.length == 0) {
            _DefaultColumn = 1;
        }

        var _Id = _ID;
        if (_ID == null)
            _Id = (this.data.value.length == 0 ? firstIdTemp : (this.data.value[this.data.value.length - 1].ID + 1));

        let currArray = this.selectionGridDataSource.filter(f => f.id == _Id);
        var _this = this;
        var newRow = false; //typeof e.row.isNewRow == "undefined" ? false : e.row.isNewRow;

        currArray.forEach(function (item) {
            let arr: any = {};
            arr.field = item.field;
            arr.id = _Id;
            arr.colIndex = item.colIndex;
            arr.data = item.data;
            _this.selectionGridDataSource.push(arr);
        });
        var clonedItem = Object.assign({}, (data ? data : {}), { ID: _Id, DefaultColumn: _DefaultColumn });

        let currentFormSelected1 = this.componentService.currentFormSelected(this.anotherPopupId);
        let outFilterDatagrid1 = this.componentService.getComponentList(currentFormSelected1.components, this.anotherPopupId);
        if (outFilterDatagrid1 && (typeof this.filteredColumnDatagrid == "undefined" || (Array.isArray(this.filteredColumnDatagrid) && this.filteredColumnDatagrid.length == 0))) {
            //let selectedDatagridElements1 = outFilterDatagrid1.filter(x => typeof x.selectedDatagrid  != "undefined" && x.selectedDatagrid != null && x.selectedDatagrid != "" && x.selectedDatagrid == this.data.id);
            let selectedDatagridElements1 = outFilterDatagrid1.filter(x => typeof x.selectedDatagrid != "undefined" && x.selectedDatagrid != null && x.selectedDatagrid != "" &&
                ((Array.isArray(x.selectedDatagrid) && typeof x.selectedDatagrid.find(y => y.id == this.data.id) != undefined) ||
                    (!Array.isArray(x.selectedDatagrid) && x.selectedDatagrid == this.data.id)));

            selectedDatagridElements1.forEach(_sde => {
                if (_sde.type != "datagrid") {
                    let _filtCol = "filtercolumnsystem_" + _sde.id;
                    let outFilterDatagridFind1 = outFilterDatagrid1.find(xx => xx.id == _sde.id);
                    clonedItem[_filtCol] = outFilterDatagridFind1 ? outFilterDatagridFind1.value : "";
                }
            });
        }



        this.data.value.splice(this.data.value.length, 0, clonedItem);

        for (let i = 0; i < this.data.headers.length; i++) {
            let exp = this.data.headers[i].expression;
            if (exp.export === true && typeof (exp.element) != "undefined") {
                let sum = this.componentService.setColSumToElement(exp.element, this.data.headers[i].field, this.data.value, currentFormSelected1.components);
                this.eventEmitterService.setColumnSumToComponent(exp.element, sum);
            }
        }


        this.loadedCompCount = this.loadedCompCount + 1;
        this.forceRefreshDatagrid();
        return clonedItem;
    }
    defaultDatagridValue = {};
    CreateDatagridColumns() {
        if (this.data.headers.length) {
            // this.dataGridEtr.instance.addColumn({ name: "DefaultColumn", dataField: "DefaultColumn", caption: "DefaultColumn", dataType: "number", width: 50, alignment: 'left', allowEditing: false, visible: false });

            let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
            let outFilterDatagrid = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
            if (outFilterDatagrid && (typeof this.filteredColumnDatagrid == "undefined" || (Array.isArray(this.filteredColumnDatagrid) && this.filteredColumnDatagrid.length == 0))) {
                //let selectedDatagridElements = outFilterDatagrid.filter(x => typeof x.selectedDatagrid  != "undefined" && x.selectedDatagrid != null && x.selectedDatagrid != "" && x.selectedDatagrid == this.data.id);
                let selectedDatagridElements = outFilterDatagrid.filter(x => typeof x.selectedDatagrid != "undefined" && x.selectedDatagrid != null && x.selectedDatagrid != "" &&
                    ((Array.isArray(x.selectedDatagrid) && typeof x.selectedDatagrid.find(y => y.id == this.data.id) != undefined) ||
                        (!Array.isArray(x.selectedDatagrid) && x.selectedDatagrid == this.data.id)));

                selectedDatagridElements.forEach(_sde => {
                    //" filtercolumnsystem_" + element
                    if (_sde.type != "datagrid") {
                        let _filtCol = "filtercolumnsystem_" + _sde.id;
                        this.dataGridEtr.instance.addColumn({ name: _filtCol, dataField: _filtCol, caption: _filtCol, dataType: "string", width: 0, alignment: 'left', allowEditing: false, visible: false });
                    }
                });
            }




            let _editableDisable = !this.data.disabled && !this.data.outsideDisabled;
            try {
                this.dataGridEtr.instance.option('columns', []);
                this.dataGridEtr.columns = []
            } catch (e) {
                console.log(e)
                this.dataGridEtr.columns = []
            }
            this.dataGridEtr.instance.addColumn({ name: "ID", dataField: "ID", caption: "ID", dataType: "number", width: 50, alignment: 'left', allowEditing: false, visible: this.data.showIdColumn });
            this.dataGridEtr.instance.addColumn({ name: "DefaultColumn", dataField: "DefaultColumn", caption: "DefaultColumn", dataType: "number", width: 50, alignment: 'left', allowEditing: false, visible: false });

            this.defaultDatagridValue = {};
            this.data.headers.forEach(header => {
                //let _header_editableDisable = header.editable && _editableDisable;
                this.propertiesUndefinedControl(header);

                this.checkUserAuthorization(header);
                if (!header.hide) {
                    header.caption = this.componentService.getLang(this.lang, header.language).Label;
                    header.caption = header.properties.required == true ? header.caption + " (*)" : header.caption;

                    let _required = [];//header.properties.required == true ? [{ type: "required" }] : [];
                    if (header.type == "text") {
                        this.defaultDatagridValue[header.field] = "";

                        if (header.properties.autoText && header.properties.autoText.isAuto) {
                            header.editable = false;// data.headers[selectedFormatIndex].properties.autoText.autoPreText isAuto
                        }

                        this.dataGridEtr.instance.addColumn({
                            name: header.name,
                            dataField: header.field,
                            caption: header.caption,
                            cellTemplate: "textTemplate",
                            width: header.width,
                            allowEditing: header.editable && _editableDisable && !this.data.editablePopup,
                            //backgroundColor: header.properties.backgroundColor,
                            //color: header.properties.color,
                            // fontWeight: header.properties.fontWeight
                            /* validationRules: _required*/
                        });
                    }
                    else if (header.type == "double") {
                        this.defaultDatagridValue[header.field] = 0;
                        let params = header.expression;
                        if (params && typeof (params.code) != "undefined" && params.code != "") {
                            // header.editable = false;
                        }
                        //r-invalid
                        this.dataGridEtr.instance.addColumn(
                            {
                                name: header.name,
                                dataField: header.field,
                                caption: header.caption,
                                dataType: "number",
                                //cellTemplate: "doubleTemplate",
                                //editCellTemplate: "doubleEditTemplate",
                                format: header.format,
                                /*validationRules: _required,*/
                                width: header.width,
                                //backgroundColor: header.properties.backgroundColor,
                                //  color: header.properties.color,
                                //  fontWeight: header.properties.fontWeight,
                                allowEditing: header.editable && _editableDisable && !this.data.editablePopup
                            });
                    }
                    else if (header.type == "int") {
                        this.defaultDatagridValue[header.field] = 0;
                        let params = header.expression;
                        if (params && typeof (params.code) != "undefined" && params.code != "") {
                        }

                        this.dataGridEtr.instance.addColumn(
                            {
                                name: header.name,
                                dataField: header.field,
                                caption: header.caption,
                                //cellTemplate: "intTemplate",
                                //editCellTemplate: "intEditTemplate",
                                dataType: "number",
                                /*validationRules: _required,*/
                                format: "#,##0",
                                width: header.width,
                                allowEditing: header.editable && _editableDisable && !this.data.editablePopup,
                                //backgroundColor: header.properties.backgroundColor,
                                //   color: header.properties.color,
                                // fontWeight: header.properties.fontWeight
                            });
                    }
                    else if (header.type == "date" || header.type == "datetime") {
                        this.defaultDatagridValue[header.field] = null;
                        let defaultFormat = header.type == "date" ? "dd/MM/yyyy" : "dd/MM/yyyy HH:mm";
                        let _format = header.format && header.format != "" && header.format != "#,##0.00" ? header.format : defaultFormat;
                        header.format = _format;
                        this.dataGridEtr.instance.addColumn({
                            name: header.name, dataField: header.field, caption: header.caption, dataType: header.type, format: _format, width: header.width, cellTemplate: "dateTemplate", allowEditing: header.editable && _editableDisable && !this.data.editablePopup,
                            //backgroundColor: header.properties.backgroundColor,
                            // color: header.properties.color,
                            //  fontWeight: header.properties.fontWeight
                            /*, validationRules: _required*/
                        });
                    }
                    else if (header.type == "checkbox") {
                        this.defaultDatagridValue[header.field] = false;
                        this.dataGridEtr.instance.addColumn({
                            name: header.name, dataField: header.field, caption: header.caption, width: header.width, cellTemplate: "checkboxTemplate", headerCellTemplate: "checkboxHeaderTemplate", showEditorAlways: true, allowEditing: header.editable && _editableDisable && !this.data.editablePopup,
                            //backgroundColor: header.properties.backgroundColor,
                            // color: header.properties.color,
                            //  fontWeight: header.properties.fontWeight
                        });
                    }
                    else if (header.type == "file") {
                        this.defaultDatagridValue[header.field] = {};
                        this.dataGridEtr.instance.addColumn({
                            name: header.name, dataField: header.field, caption: header.caption, width: header.width, cellTemplate: "fileTemplate", editCellTemplate: "fileTemplate", allowEditing: header.editable && _editableDisable && !this.data.editablePopup,
                            //backgroundColor: header.properties.backgroundColor,
                            // color: header.properties.color,
                            // fontWeight: header.properties.fontWeight
                        });
                    }
                    else if (header.type == "picturebox") {
                        this.defaultDatagridValue[header.field] = {};
                        this.dataGridEtr.instance.addColumn({
                            name: header.name, dataField: header.field, alignment: 'center', caption: header.caption, showEditorAlways: true, width: header.width, editCellTemplate: "pictureboxTemplate", allowEditing: ((header.editable && _editableDisable && !this.data.editablePopup) || true),
                            //backgroundColor: header.properties.backgroundColor,
                            //color: header.properties.color,
                            //fontWeight: header.properties.fontWeight
                        });
                    }
                    else if (header.type == "fileviewer") {
                        this.defaultDatagridValue[header.field] = {};
                        this.dataGridEtr.instance.addColumn({
                            name: header.name, dataField: header.field, alignment: 'center', caption: header.caption, showEditorAlways: true, width: header.width, editCellTemplate: "fileviewerTemplate", allowEditing: ((header.editable && _editableDisable && !this.data.editablePopup) || true),
                            //backgroundColor: header.properties.backgroundColor,
                            //color: header.properties.color,
                            //fontWeight: header.properties.fontWeight
                        });
                    }
                    else if (header.type == "map") {

                        if (typeof header.isAddMultipleMarker == "undefined")
                            header.isAddMultipleMarker = false;
                        if (typeof header.maxMarkerLimit == "undefined")
                            header.maxMarkerLimit = 0;

                        this.defaultDatagridValue[header.field] = {};
                        this.dataGridEtr.instance.addColumn({
                            name: header.name, dataField: header.field, alignment: 'center', caption: header.caption, showEditorAlways: true, width: header.width, editCellTemplate: "mapTemplate", allowEditing: ((header.editable && _editableDisable && !this.data.editablePopup) || true),
                            //backgroundColor: header.properties.backgroundColor,
                            //color: header.properties.color,
                            //fontWeight: header.properties.fontWeight
                        });
                    }
                    else if (header.type == "datagrid") {
                        var _this = this;
                        this.defaultDatagridValue[header.field] = -1;
                        this.dataGridEtr.instance.addColumn(
                            {
                                name: header.name,
                                caption: header.caption,
                                dataField: header.field,
                                allowEditing: (header.editable && _editableDisable && !this.data.editablePopup) || true,
                                dataType: 'string',
                                alignment: 'left',
                                width: header.width,
                                showEditorAlways: true,
                                /* validationRules: _required,*/
                                editCellTemplate: "dropDownBoxEditor",
                                //backgroundColor: header.properties.backgroundColor,
                                //color: header.properties.color,
                                //fontWeight: header.properties.fontWeight
                            }
                        );
                    }
                    else if (header.type == "combobox") {

                        this.defaultDatagridValue[header.field] = null;
                        var _this = this;
                        this.dataGridEtr.instance.addColumn(
                            {
                                name: header.name,
                                caption: header.caption,
                                dataField: header.field,
                                allowEditing: header.editable && _editableDisable && !this.data.editablePopup,
                                dataType: 'string',
                                //editCellTemplate: "comboboxTemplate",
                                alignment: 'left',
                                showEditorAlways: true,
                                width: header.width,
                                // backgroundColor: header.properties.backgroundColor,
                                // color: header.properties.color,
                                //  fontWeight: header.properties.fontWeight,

                                /*  validationRules: _required,*/

                                lookup: {
                                    //disabled: (header.editable == true && _editableDisable == true && this.data.editablePopup!=true),
                                    allowClearing: header.clearButton,
                                    dataSource: function (options) {
                                        //var totalCount = _this.dataGridEtr.instance.totalCount();
                                        if (options.data) {
                                            //var rowInde x = typeof options.rowInde x == "undefined" || options.isNewRow ? totalCount : options.rowInde x;

                                            var _dataSource = _this.selectionGridDataSource.find(f => /*f.index == rowInde x*/f.id == options.data.ID && f.field == header.field);

                                            let dataSourceConfiguration = {
                                                store: {
                                                    type: "array",
                                                    key: header.valueKey,
                                                    data: typeof _dataSource == "undefined" ? header.dataSource : _dataSource.data
                                                }
                                                //store: typeof _dataSource == "undefined" ? header.dataSource : _dataSource.data
                                            };
                                            return typeof _dataSource == "undefined" ? header.dataSource : _dataSource.data;//dataSourceConfiguration;
                                        }
                                        else {
                                            let dataSourceConfiguration = {
                                                store: {
                                                    type: "array",
                                                    key: header.valueKey,
                                                    data: []
                                                }
                                                //store: typeof _dataSource == "undefined" ? header.dataSource : _dataSource.data
                                            };
                                            return [];//dataSourceConfiguration;
                                        }
                                    },
                                    displayExpr: "displayText",//header.displayKey,
                                    valueExpr: header.valueKey

                                }

                            }
                        );
                    }
                    else if (header.type == "customableCombobox") {

                        this.defaultDatagridValue[header.field] = null;
                        var _this = this;
                        this.dataGridEtr.instance.addColumn(
                            {
                                name: header.name,
                                caption: header.caption,
                                dataField: header.field,
                                allowEditing: header.editable && _editableDisable && !this.data.editablePopup || true,
                                dataType: 'string',
                                //editCellTemplate: "comboboxTemplate",
                                alignment: 'left',
                                showEditorAlways: true,
                                width: header.width,
                                editCellTemplate: "comboboxCustomTemplate",
                                valueKey : header.valueKey
                                // backgroundColor: header.properties.backgroundColor,
                                // color: header.properties.color,
                                //  fontWeight: header.properties.fontWeight,

                                /*  validationRules: _required,*/
                                /*
                                lookup: {
                                    //disabled: (header.editable == true && _editableDisable == true && this.data.editablePopup!=true),
                                    allowClearing: header.clearButton,
                                    dataSource: function (options) {
                                        //var totalCount = _this.dataGridEtr.instance.totalCount();
                                        if (options.data) {
                                            //var rowInde x = typeof options.rowInde x == "undefined" || options.isNewRow ? totalCount : options.rowInde x;

                                            var _dataSource = _this.selectionGridDataSource.find(f => /*f.index == rowInde x*//*f.id == options.data.ID && f.field == header.field);

        let dataSourceConfiguration = {
            store: {
                type: "array",
                key: header.valueKey,
                data: typeof _dataSource == "undefined" ? header.dataSource : _dataSource.data
            }
            //store: typeof _dataSource == "undefined" ? header.dataSource : _dataSource.data
        };
        return typeof _dataSource == "undefined" ? header.dataSource : _dataSource.data;//dataSourceConfiguration;
    }
    else {
        let dataSourceConfiguration = {
            store: {
                type: "array",
                key: header.valueKey,
                data: []
            }
            //store: typeof _dataSource == "undefined" ? header.dataSource : _dataSource.data
        };
        return [];//dataSourceConfiguration;
    }
},
displayExpr: "displayText",//header.displayKey,
valueExpr: header.valueKey

}
*/
                            }as any
                        );
                    }
                }
            });

            this.hideLoadPanel();
            this.loadedCompCount = this.loadedCompCount + 1;
            this.dataGridEtr.instance.addColumn({ caption: "", allowEditing: false });

            this.dataGridEtr.instance.addColumn({ caption: "*", width: 50, cellTemplate: "cloneButton", allowFixing: false, fixed: false, fixedPosition: "right", visible: this.data.shopCopyButton });

            if (this.data.editablePopup)
                this.dataGridEtr.instance.addColumn({ caption: "*", width: 50, cellTemplate: "editButton", allowFixing: true, fixed: true, fixedPosition: "right" });

            this.applyFilter();

            this.dataGridEtr.instance.refresh();

        }
        else {
            this.hideLoadPanel();
            this.loadedCompCount = this.loadedCompCount + 1;
        }


        if (this.data.value.length == 0) {
            this.addNewRowFunction();
        }
        else {
            //if (this.data.selectedDatagrid && Array.isArray(this.data.selectedDatagrid) && this.data.selectedDatagrid.length > 0) {
                let _varThis = this;
                setTimeout(() => {
                    if (_varThis.data.selectedRows.length == 0) {

                        if (_varThis.data.value.length > 0) {
                            _varThis.data.selectedRows = [];
                            let firstDataValueId = _varThis.data.value[0];
                            if (firstDataValueId && firstDataValueId.ID && firstDataValueId.ID != 0) {

                                _varThis.data.selectedRows.push(firstDataValueId.ID);
                                _varThis.focusedRowKey = firstDataValueId.ID;
                            }
                        }
                    }
                    else
                        _varThis.focusedRowKey = _varThis.data.selectedRows[0];

                    this.forceRefreshDatagrid()
                }, 500);
            //} 
        }

    }


    getCellTheme(cell) {
        let header = this.data.headers.find(el => el.field == cell.column.dataField);
        const styles = {
            'background-color': cell.column.backgroundColor ? cell.column.backgroundColor : header.properties.backgroundColor,//cell.column.backgroundColor,
            'color': cell.column.backgroundColor ? cell.column.color : header.properties.color,// cell.column.color,
            'font-weight': cell.column.fontWeight
        };
        //if (_type == "Preview") {
        //    if (headerObj.properties.isUserUpload) {
        //        styles["left"] = "calc(50% - 29px)";
        //        styles["top"] = "calc(50% - 10px)";
        //    }
        //    else {
        //        styles["left"] = "calc(50% - 16px)";
        //        styles["top"] = "calc(50% - 12px)";
        //    }
        //}
        return styles;

    }
    getCellValue(cell) {

        let header = this.data.headers.find(el => el.field == cell.column.dataField);
        let data = this.data.value.find(el => el.ID == cell.row.data.ID)
        let number: number = 0;
        let val = formatNumber(data[cell.column.dataField], header.format)
        return val
    }

    applyFilter() {
        if (
            this.data.filter.field
            && this.data.filter.field != ""
            && this.data.filter.users.length > 0
            && this.data.filter.operator != ""
        ) {
            if (this.isUserExistInFilter(this.data.filter.users)) {
                this.dataGridEtr.instance.filter([this.data.filter.field, this.data.filter.operator, this.data.filter.value]);
            }
        }
    }

    isUserExistInFilter(userList) {
        for (var i = 0; i < userList.length; i++) {
            if (userList[i] == this.appInfo.currentUser.id) {
                return true;
            }
        }

        return false;
    }

    checkUserAuthorization(header) {
        if (!this.isEdit) {
            let userId = this.appInfo.currentUser.id;
            let rGroupIndex = header.permissions.readonlyGroup.map(m => m.users.indexOf(userId) > -1).findIndex(x => x);
            let rUsersIndex = header.permissions.readonlyUsers.indexOf(userId);
            let hGroupIndex = header.permissions.hideGroup.map(m => m.users.indexOf(userId) > -1).findIndex(x => x);
            let hUsersIndex = header.permissions.hideUsers.indexOf(userId);

            let isHide = hGroupIndex != -1 || hUsersIndex != -1;
            let isReadonly = rGroupIndex != -1 || rUsersIndex != -1;

            if (isHide) header.hide = true;
            else header.hide = false;

            if (isReadonly) header.editable = false;
            else header.editable = true;
        }
        else {
            header.hide = false;
            header.editable = true;
        }
    }
    onColumnSettingPopopShow() {
        this.data.headers.filter(x => x.type == "fileviewer").forEach(_fileViewer => {
            if (typeof _fileViewer.sqlColumnSettings == "undefined") {
                _fileViewer.sqlColumnSettings = {
                    "connectionStringId": 0,
                    "query": "",
                    "fileFields": {
                        "name": "",
                        "file": "",
                        "fileName": "",
                        "extension": ""
                    }
                };
            }
        });



        //	"sqlColumnSettings": {
        //    "connectionStringId": 0,
        //    "query": "",
        //    "fileFields": {
        //        "name": "",
        //        "file": "",
        //        "fileName": "",
        //        "extension": "" 
        //    }
        //}
    }

    addCustomItem(data, cellInfo) {
        debugger;
        if (!data.text) {
            data.customItem = null;
            return;
        }
        var valueKey = this.data.headers.valueKey
        var customdataSource = this.data.headers.find(el => el.field == cellInfo.column.dataField)
        var obj = { customValue: true }
        obj[cellInfo.column.valueKey] = data.text
        customdataSource.dataSource.push(obj)
        console.log(cellInfo)
        var rowDataIndex = this.data.value.findIndex(f => f.ID == cellInfo.row.data["ID"])
        this.data.value[rowDataIndex][cellInfo.column.dataField] = data.text;

        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }



    saveColumnSettings() {
        let _control = true;
        const pattern = /[A-Za-z_]/;
        this.data.headers.forEach(f => {
            if (!pattern.test(f.field[0]))
                _control = false;
        });
        if (_control) {
            this.showGridColPopop = false;
        }
        else
            this.showToast(this.languageLocal.toastColumnNameNumber, "warning");
    }
    onHidingColumnPopup(event: any) {
        let _control = true;
        const pattern = /[A-Za-z_]/;
        this.data.headers.forEach(f => {
            if (!pattern.test(f.field[0]))
                _control = false;
        });
        if (_control) {
            event.cancel = false;
            this.showGridColPopop = false;
        }
        else {
            event.cancel = true;
            this.showToast(this.languageLocal.toastColumnNameNumber, "warning");
        }
    }

    fieldTypesValueChanged = (e: any, header: any): void => {

        if (e.value.length && typeof e.event != "undefined") {

            let headerDefault = this.componentService.cloneModel(this.headers);
            header.dataGridSettings = headerDefault.dataGridSettings;
            header.properties = headerDefault.properties;
            header.dataSource = headerDefault.dataSource;
            header.dataSourceKey = headerDefault.dataSourceKey;
            header.valueKey = headerDefault.valueKey;
            header.funcParam = headerDefault.funcParam;
            header.format = headerDefault.format;
            header.files = headerDefault.files;
            header.changeEvents = headerDefault.changeEvents;
            header.sqlColumnSettings = headerDefault.sqlColumnSettings;

            if (e.value == "datetime")
                header.format = "dd/MM/yyyy HH:mm";
            else if (e.value == "date")
                header.format = "dd/MM/yyyy";
            else if (e.value == "double" || e.value == "int")
                header.format = "#,##0.00";
            else if (e.value == "file")
                header.width = "200";
        }
    }

    keyPressVariables(event: any) {

        const pattern = /[A-Za-z0-9_]/;
        const inputChar = String.fromCharCode((event as KeyboardEvent).charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }


    filterOutElement(element, _val, filteredColumn, isSqlExecute) {
        //this.dataGrid.instance.filter([
        //   [ "Cost", ">", 1000 ],
        //   "and",
        //   [ "Cost", "<=", 2000 ]
        //]);
        if (element && element != "") {

            let _control = false;
            let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
            let outFilterDatagrid = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
            if (outFilterDatagrid) {
                let selectedDatagridElements = outFilterDatagrid.filter(x => typeof x.selectedDatagrid != "undefined" && x.selectedDatagrid != null && x.selectedDatagrid != "" &&
                    ((Array.isArray(x.selectedDatagrid) && typeof x.selectedDatagrid.find(y => y.id == this.data.id) != undefined) ||
                        (!Array.isArray(x.selectedDatagrid) && x.selectedDatagrid == this.data.id)));
                _control = selectedDatagridElements.length > 0;

                //selectedDatagridElements.forEach(_sde => {
                //    let _filtCol = "filtercolumnsystem _" + _sde.id;
                //    this.data.value.forEach(_sdeVal => {
                //        let outFilterDatagridFind = outFilterDatagrid.find(xx => xx.id == _sde.id);
                //        _sdeVal[_filtCol] = outFilterDatagridFind ? outFilterDatagridFind.value : "";
                //    });
                //    _control = true;
                //});

            }


            let _filterChangeTemp = { col: undefined, val: undefined };
            if (_control) {
                this.forceRefreshDatagrid();

                if (typeof filteredColumn == "undefined" || (Array.isArray(filteredColumn) && filteredColumn.length == 0)) {
                    this.dataGridEtr.instance.filter(["filtercolumnsystem_" + element, "=", _val]);
                    _filterChangeTemp.col = "filtercolumnsystem_" + element;
                    _filterChangeTemp.val = _val;
                }
                else {
                    if (Array.isArray(filteredColumn)) {
                        let _cols = [];
                        let _vals = [];
                        let datagridFilterArr = [];
                        filteredColumn.forEach((_e, _i) => {
                            _cols.push(_e);
                            _vals.push(_val[_i]);
                            datagridFilterArr.push([_e, '=', _val[_i]]);
                            if (filteredColumn.length - 1 > _i)
                                datagridFilterArr.push('and');
                        });
                        //[['ID','=',2],'or',['ID','=', 3]]
                        this.dataGridEtr.instance.filter(datagridFilterArr);

                        _filterChangeTemp = { col: _cols, val: _vals };
                    }
                    else {
                        this.dataGridEtr.instance.filter([filteredColumn, "=", _val]);
                        _filterChangeTemp = { col: filteredColumn, val: _val };
                    }
                }









                //if (filteredColumn) {
                //    this.dataGridEtr.instance.filter([filteredColumn, "=", _val]);
                //    _filterChangeTemp.col = filteredColumn;
                //} else {
                //    this.dataGridEtr.instance.filter(["filtercolumnsystem_" + element, "=", _val]);
                //    _filterChangeTemp.col = "filtercolumnsystem_" + element;
                //}
                //_filterChangeTemp.val = _val;
            }

            if (isSqlExecute) {

                this.filterChangeSqlExecute(_filterChangeTemp);
            }
        }
    }


    filterChangeSqlExecute(_filterChangeTemp) {
        let thisDataValueFilter = [];
        if (_filterChangeTemp && _filterChangeTemp.col != "" || (Array.isArray(_filterChangeTemp.col) && _filterChangeTemp.col.length > 0)) {
            //_filterChangeTemp = { col: undefined, val: undefined }
            if (Array.isArray(_filterChangeTemp.col)) {
                debugger;
                let _indices = [];
                let tempData = this.componentService.cloneModel(this.data.value);
                _filterChangeTemp.col.forEach(function (fC, _iFC) {
                    let _ind = tempData.reduce((r, v, i) => r.concat(v[fC] == _filterChangeTemp.val[_iFC] ? i : []), []);
                    var c = _indices.concat(_ind);
                    _indices = c.filter((item, pos) => c.indexOf(item) !== pos);
                });

                _indices.forEach(_in => {
                    thisDataValueFilter.push(this.data.value[_in]);
                });
            }
            else
                thisDataValueFilter = this.data.value.filter(x => x[_filterChangeTemp.col] == _filterChangeTemp.val);
        }
        else
            thisDataValueFilter = this.data.value;


        thisDataValueFilter.forEach(valEl => {
            let dataHeaderControl = 0;
            this.data.headers.forEach(el => {
                let replacedQuery = this.componentService.cloneModel(el.dataGridSettings.query);

                let fields = Object.keys(valEl);
                fields.forEach(f => {
                    let pref = "{" + f + "}";
                    let value = valEl[f];
                    if (value != null && typeof value != "undefined" && typeof value.getMonth === 'function') {
                        value = value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate();
                        replacedQuery = this.replaceAll(replacedQuery, pref, value);
                    }
                    else
                        replacedQuery = this.replaceAll(replacedQuery, pref, value);
                });

                this.outOfDatagridGetSql(replacedQuery, /*rowInde x,*/ el.dataGridSettings.connectionStringId, el.field, /*true,*/ "", valEl).then(val => {
                    dataHeaderControl++;
                    if (this.data.headers.length == dataHeaderControl) {

                        this.onInitNewRowBegin({ data: valEl });
                    }
                    else {
                        /*  console.log("");*/
                    }
                });
            });



        });
    }




    // #endregion SYSTEM CODE




    // #region DATAGRID IN DATAGRID COLUMN
    /////////
    /// DATAGRID IN DATAGRID COLUMN
    ////////
    visibileDatagridInDatagridOkButton(cellInfo) {
        if (cellInfo) {
            let header = this.data.headers.find(x => x.field == cellInfo.column.dataField);
            if (header && header.isMultipleSelect == true)
                return true;
            else
                return false;
        }
        else
            return false;
    }
    getSelectionMode(cellInfoGlobal) {
        let selectionMode = 'single';
        if (typeof cellInfoGlobal != "undefined") {
            let headerObj = this.data.headers.find(x => x.field == cellInfoGlobal.column.dataField);//this.data.headers[cellInfoGlobal.rowInde x].isMultipleSelect;
            if (typeof headerObj != "undefined" && headerObj.isMultipleSelect == true)
                selectionMode = 'multiple';
        }
        return selectionMode;
    }
    getDatagridSourceNew(cellInfo) {
        if (cellInfo) {
            var _dataSource = this.selectionGridDataSource.find(f => /*f.index == rowInde x*/f.id == cellInfo.data.ID && f.field == cellInfo.column.dataField);
            let header = this.data.headers.find(x => x.field == cellInfo.column.dataField);

            var dataSourceConfiguration = {
                //  store: typeof _dataSource == "undefined" ? header.dataSource : _dataSource.data
                store: {
                    type: "array",
                    key: header.valueKey,
                    data: typeof _dataSource == "undefined" ? header.dataSource : _dataSource.data
                }
            };


            let headerImageKey = ""
            if (typeof header.imageKeys != "undefined") {
                headerImageKey = header.imageKeys
            }

            this.gridHeaders = []
            if (dataSourceConfiguration.store.data.length > 0) {

                for (let i = 0; i < dataSourceConfiguration.store.data.length; i++) {

                    let shouldChange = Object.keys(dataSourceConfiguration.store.data[i]).includes(headerImageKey) ? (true) : (false);

                    if (dataSourceConfiguration.store.data[i][headerImageKey] == null) continue;

                    if (shouldChange && !dataSourceConfiguration.store.data[i][headerImageKey].includes('data:'))
                        dataSourceConfiguration.store.data[i][headerImageKey] = 'data:image/png;base64,' + dataSourceConfiguration.store.data[i][headerImageKey]
                    //else if (shouldChange && dataSourceConfiguration.store.data[i][headerImageKey].includes('data:'))
                    //    dataSourceConfiguration.store.data[i][headerImageKey] = dataSourceConfiguration.store.data[i][headerImageKey]
                }

                Object.keys(dataSourceConfiguration.store.data[0]).forEach(el => {

                    if (headerImageKey == el) {

                        this.gridHeaders.push({ name: el, isImage: true, type: "image", key: header.valueKey })

                    } else {

                        this.gridHeaders.push({ name: el, isImage: false, type: "text", key: header.valueKey })

                    }

                })

            }
            return dataSourceConfiguration;
        }
    }

    customComboBoxData = []
    onOpenedComboBoxTemplate(e, cellInfo) {
        this.getData(cellInfo)
    }

    getData(cellInfo) {
        debugger
        var customdataSource = this.data.headers.find(el => el.field == cellInfo.column.dataField)
        var cds = JSON.parse(JSON.stringify(customdataSource.dataSource))
        var filterData = cds.filter(f => f.customValue == false || (f[customdataSource.valueKey] == cellInfo.row.data[cellInfo.column.dataField] && f.customValue == true))
        this.customComboBoxData = filterData//customdataSource.dataSource
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }

    onEditorPreDatagridPopup(_e) {
        if (_e.parentType == 'filterRow') {

            // if (_e.dataField === "CustomerInfo.StoreCity") {
            //let oldHandler = _e.editorOptions.onValueChanged;
            //_e.editorOptions.onValueChanged = function (arg) {
            //    oldHandler.apply(arg.component, arguments);
            //    _e.element.find('.dx-apply-button').trigger('dxclick');
            //}
            //  }


            //_e.editorOptions.onEnterKey = function (arg) {
            //    _e.element.find('.dx-apply-button').trigger('dxclick');
            //}


            //return false;
        }
        return false;
    }

    onSelectionConfirmButton(selectedRowKeys, cellInfo) {

        if (cellInfo) {

            let header = this.data.headers.find(f => f.name == cellInfo.column.name);
            if (header) this.selectedGridForm = header.dataGridSettings.formFields;
            let addRows = []
            let emptyRows = []
            let dataIdTemp = [];
            if (header.isMultipleSelect == true) {

                let isSelectAsRow = typeof header.isSelectAsRow == "undefined" ? (false) : (header.isSelectAsRow)

                if (!isSelectAsRow) {

                    this.arraySelectedDatagridInDatagrid = selectedRowKeys;
                    // let data = dataArray.find(f => f[header.valueKey] == selectedRowKeys[0][header.valueKey]);
                    let isSelectAsRow = typeof header.isSelectAsRow == "undefined" ? (false) : (header.isSelectAsRow)
                    if (!isSelectAsRow) {
                        if (selectedRowKeys.length > 0) {
                            let selectIds = selectedRowKeys //.map(m => m[header.valueKey]);
                            if (selectIds.length > 0 && typeof selectIds[0] == "undefined") { selectIds = selectedRowKeys; }
                            this.data.value.find(x => x.ID == this.cellInfoGlobal.data.ID)[header.field] = selectIds.toString();
                            this.forceRefreshDatagrid();
                            this.executeSqlExpression(header.field, this.data.value.find(x => x.ID == this.cellInfoGlobal.data.ID)/*, this.cellInfoGlobal.data.ID, false*/);
                            this.elementOutOfDatagrid();
                            //this.showDatagridPopupClose();
                            //this.cellInfoGlobal = undefined;
                        }
                    }

                } else if (isSelectAsRow) {

                    // #region CheckAndFill
                    // Bu alandaki kod seçili Id'den sonraki boş satırları tarıyor. Değişiklik yapılmayan satırlar çoklu seçim ile dolduruluyor.

                    let values: any[] = this.data.value.filter(e => e.ID >= cellInfo.data["ID"]).map(e => e.ID)
                    values.forEach(el => {
                        if (this.checkIsRowEmpty(el)) emptyRows.push(el);
                    })

                    let lastId = this.data.value[this.data.value.length - 1].ID
                    for (let i = emptyRows.length; i < selectedRowKeys.length; i++) {
                        lastId++;
                        addRows.push(lastId)
                    }


                    let index = 0;
                    for (let i = 0; i < emptyRows.length; i++) {
                        let currentItem = selectedRowKeys[index];
                        if (typeof currentItem == "undefined") { break; }
                        else {
                            let updateIndex = this.data.value.findIndex(el => el.ID == emptyRows[i])
                            this.data.value[updateIndex][header.field] = JSON.parse(JSON.stringify(currentItem))
                            dataIdTemp.push(this.data.value[updateIndex]);
                            index++;

                        }

                    }

                    for (let i = 0; i < addRows.length; i++) {

                        let currentItem = selectedRowKeys[index];
                        if (typeof currentItem == "undefined") { break; }
                        else {

                            let dataTemplate = JSON.parse(JSON.stringify(cellInfo.data))

                            Object.keys(dataTemplate).forEach(key => {

                                if (key != "ID" && key != "DefaultColumn" /*&& key != header.field*/) {
                                    dataTemplate[key] = cellInfo.data[key]
                                }
                            });

                            if (typeof dataTemplate != "undefined") {
                                dataTemplate[header.field] = currentItem
                                var _data = this.addNewRowOrClone(dataTemplate, null)

                                var _event = { data: _data };
                                this.onInitNewRow(_event);
                                dataIdTemp.push(this.data.value[this.data.value.length - 1]);
                            }
                            index++;

                        }

                    }
                    // #endregion


                    addRows.forEach(el => {
                        this.executeSqlExpression(header.field, this.data.value.find(x => x.ID == el))
                    })

                    emptyRows.forEach(el => {
                        this.executeSqlExpression(header.field, this.data.value.find(x => x.ID == el))
                    })

                    //this.executeSqlExpression(header.field, this.data.value.find(x => x.ID == cellInfo.data.ID)/*, this.cellInfoGlobal.data.ID, false*/);




                    var selectedRowAllDatasource = this.selectionGridDataSource.filter(x => x.id == cellInfo.data.ID)

                    dataIdTemp.filter(x => x.ID != cellInfo.data.ID).forEach(dataIdTempItem => {
                        selectedRowAllDatasource.forEach(selectedRowAllDatasourceItem => {
                            let curr = this.selectionGridDataSource.find(f => /*f.index == rowInde x*/f.id == dataIdTempItem.ID && f.field == selectedRowAllDatasourceItem.field);
                            if (curr) {
                                curr.data = [];
                                //let find = selectedRowAllDatasource.find(x => x.field == selectedRowAllDatasourceItem.field)?.data; // selectedRowAllDatasource[selectedRowAllDatasourceItem.field];
                                curr.data = selectedRowAllDatasource[selectedRowAllDatasourceItem.field];
                            }
                            else {
                                let _tempSRAD = selectedRowAllDatasource.find(x => x.field == selectedRowAllDatasourceItem.field);
                                let arr: any = {};
                                arr.field = selectedRowAllDatasourceItem.field;
                                arr.id = dataIdTempItem.ID;
                                arr.data = _tempSRAD ? _tempSRAD.data : [];
                                this.selectionGridDataSource.push(arr);
                            }
                        });
                    });

                    // #region Eski kod 18.05.2022 aradan zaman geçtiyse ve hata için geri dönüş olmadıysa silinebilir. - Buğra Moğol
                    //f (selectedRowKeys.length > 0) {
                    //   let selectIds: any[] = selectedRowKeys
                    //
                    //   var firstChanged = false;
                    //   let dataIdTemp = [];
                    //   selectIds.forEach(el => {
                    //       
                    //       if (this.checkIsDataGridEmpty()) {
                    //           let dataTemplateFirstRow = (cellInfo.data)
                    //           dataTemplateFirstRow[header.field] = el
                    //           firstChanged = true
                    //           var _data = dataTemplateFirstRow
                    //
                    //           var _event = { data: _data };
                    //           this.onInitNewRow(_event);
                    //           dataIdTemp.push(this.data.value[this.data.value.length - 1]);
                    //       } else {
                    //           let dataTemplate = JSON.parse(JSON.stringify(cellInfo.data))
                    //           Object.keys(dataTemplate).forEach(key => {
                    //
                    //               if (key != "ID" && key != "DefaultColumn" /*&& key != header.field*/) {
                    //                   dataTemplate[key] = cellInfo.data[key]
                    //               }
                    //           });
                    //
                    //           if (typeof dataTemplate != "undefined") {
                    //               dataTemplate[header.field] = el
                    //               var _data = this.addNewRowOrClon e(dataTemplate, null)
                    //
                    //               var _event = { data: _data };
                    //               this.onInitNewRow(_event);
                    //               dataIdTemp.push(this.data.value[this.data.value.length - 1]);
                    //           }
                    //
                    //       }
                    //
                    //
                    //   })
                    //
                    //   //this.executeSqlExpression(header.field, this.data.value.find(x => x.ID == cellInfo.data.ID)/*, this.cellInfoGlobal.data.ID, false*/);
                    //   //
                    //   //var selectedRowAllDatasource = this.selectionGridDataSource.filter(x => x.id == cellInfo.data.ID)
                    //   //
                    //   //dataIdTemp.filter(x => x.ID != cellInfo.data.ID).forEach(dataIdTempItem => {
                    //   //    selectedRowAllDatasource.forEach(selectedRowAllDatasourceItem => {
                    //   //        let curr = this.selectionGridDataSource.find(f => /*f.index == rowInde x*/f.id == dataIdTempItem.ID && f.field == selectedRowAllDatasourceItem.field);
                    //   //        if (curr) {
                    //   //            curr.data = [];
                    //   //            let find = selectedRowAllDatasource.find(x => x.field == selectedRowAllDatasourceItem.field)?.data; // selectedRowAllDatasource[selectedRowAllDatasourceItem.field];
                    //   //            curr.data = selectedRowAllDatasource[selectedRowAllDatasourceItem.field];
                    //   //        }
                    //   //        else {
                    //   //            let arr: any = {};
                    //   //            arr.field = selectedRowAllDatasourceItem.field;
                    //   //            //arr.index = rowInde x;
                    //   //            arr.id = dataIdTempItem.ID;
                    //   //            //arr.colIndex = columnIndex;
                    //   //            arr.data = selectedRowAllDatasource.find(x => x.field == selectedRowAllDatasourceItem.field)?.data;
                    //   //            this.selectionGridDataSource.push(arr);
                    //   //        }
                    //   //    });
                    //   //});
                    //
                    //
                    //
                    //
                    //   //let thisDataValues = this.componentService.cloneModel(this.data.value);
                    //   //thisDataValues.filter(x=>x)
                    //
                    //   //let dataCols = this.data.headers.filter(f => f.type == "combobox" || f.type == "datagrid ");
                    //   //if (dataCols && dataCols.length > 0)
                    //   //    this.data.value.filter(x => x.ID > cellInfo.data.ID).forEach(elVal => {
                    //   //        dataCols.forEach(elField => {
                    //
                    //
                    //   //            let query = elField.dataGridSettings.query;
                    //   //            //let queryClear = query.toLowerCase().replace(/(\r\n|\n|\r|\t)/gm, "").replace(" ", "");
                    //   //            let _type = elField.type;
                    //   //            let connectionStringId = elField.dataGridSettings.connectionStringId;
                    //
                    //   //            let replacedQuery = this.componentService.cloneModel(query);
                    //   //            let fields = Object.keys(elVal);
                    //   //            fields.forEach(f => {
                    //   //                let pref = "{" + f + "}";
                    //   //                let value = elVal[f];
                    //   //                if (value != null && typeof value != "undefined" && typeof value.getMonth === 'function') {
                    //   //                    value = value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate();
                    //   //                    replacedQuery = this.replaceAll(replacedQuery, pref, value);
                    //   //                }
                    //   //                else
                    //   //                    replacedQuery = this.replaceAll(replacedQuery, pref, value);
                    //   //            });
                    //
                    //   //            this. outOfDatagridGetSq l(replacedQuery, /*rowInde x,*/ connectionStringId, elField.field, "", elVal);
                    //   //            ///this.executeSqlExpression(elField.field, elVal);
                    //   //        })
                    //   //    })
                    //
                    //   //let dataCols = this.data.headers.filter(f => f.type == "combobox" || f.type == "datagrid ");//
                    //   //if (dataCols && dataCols.length > 0) {
                    //   //    tThis.prepareDataGridColumn(dataCols, 0, false);
                    //   //}
                    //
                    // #endregion
                }

            }

        }

        this.showDatagridPopupClose();

    }

    onSelectionChanged(selectedRowKeys, cellInfo) {

        if (cellInfo) {


            let header = this.data.headers.find(f => f.name == cellInfo.column.name);
            if (header) this.selectedGridForm = header.dataGridSettings.formFields;
            // let rowInde xSelected = cellInfo.row.rowInde x;
            this.cellInfoGlobal.isLoading = true;
            if (header.isMultipleSelect == true) {
                this.arraySelectedDatagridInDatagrid = selectedRowKeys
            }
            else {
                if (selectedRowKeys.length > 0) {

                    let dataObj = this.selectionGridDataSource.find(f => /*f.index == cellInfo.rowInde x*/f.id == this.cellInfoGlobal.data.ID && f.field == header.field);
                    let dataArray = typeof dataObj != "undefined" ? dataObj.data : null;
                    let data = dataArray.find(f => f[header.valueKey] == selectedRowKeys[0][header.valueKey]);
                    if (typeof data == "undefined")
                        data = dataArray.find(f => f[header.valueKey] == selectedRowKeys[0]);

                    if (data) {
                        let display = data[header.valueKey];

                        this.data.value.find(x => x.ID == this.cellInfoGlobal.data.ID)[header.field] = display;
                        this.forceRefreshDatagrid();
                        this.executeSqlExpression(header.field, this.data.value.find(x => x.ID == this.cellInfoGlobal.data.ID)/*, this.cellInfoGlobal.data.ID, false*/);
                        this.elementOutOfDatagrid();
                    }

                    this.showDatagridPopupClose();
                    this.cellInfoGlobal = undefined;
                }

                //this.showDatagridPopup = false;
            }

        }
    }

    getDatagridkeyExpr(cellInfo) {
        if (cellInfo) {
            let _header = this.data.headers.find(x => x.field == cellInfo.column.dataField);
            return _header.valueKey;
        }
    }

    arraySelectedDatagridInDatagrid = [];
    getDatagridSelectRowKey(cellInfo) {
        let arraySelected = [];
        if (cellInfo) {
            //let header = this.data.headers.find(f => f.field == cellInfo.column.dataField);
            //if (header.isMultipleSelect == true) {
            //    arraySelected = cellInfo.data[cellInfo.column.dataField].split(",");
            //}
            //else if (cellInfo.value != null && cellInfo.value != "" && typeof cellInfo.value != "undefined") {
            //    arraySelected.push(cellInfo.value);
            //}
            arraySelected.push(cellInfo.value);
        }
        return arraySelected;
    }
    //details</a>
    getDatagridSourceGetValue(cellInfo) {
        if (cellInfo/* && cellInfo.column.lookup.items.length>0*/ && cellInfo.data) {
            let _header = this.data.headers.find(x => x.field == cellInfo.column.dataField);//header.dataSource /*-----*/
            let _items = this.selectionGridDataSource.find(f => f.id == cellInfo.data.ID && f.field == _header.field);
            let _item = typeof _items != "undefined" && typeof _items.data != "undefined" ? _items.data : _header.dataSource;//cellInfo.column.lookup.items.find(x => x[cellInfo.column.lookup.valueExpr] == cellInfo.value);

            let _itemObj;
            if (_item.length > 0) {
                //var _dataSource = this.selectionGridDataSource.find(f => f.id == cellInfo.data.ID && f.field == cellInfo.column.dataField);
                //let dataSourceDatas = typeof _dataSource == "undefined" ? _header.dataSource : _dataSource.data; 
                if (_header.isMultipleSelect) {
                    let cellInfoValueSplit = typeof cellInfo.value != "undefined" ? cellInfo.value.toString().split(',') : [];
                    let fieldsValues = _item.filter(x => cellInfoValueSplit.indexOf(x[_header.valueKey] + "") > -1);
                    _itemObj = fieldsValues.length > 0 ? fieldsValues : null;
                }
                else
                    _itemObj = _item.find(x => x[_header.valueKey] == cellInfo.value);
            }
            //if (_header.isMultipleSelect /*&& _item.length > 0*/) {
            //    var _dataSource = this.selectionGridDataSource.find(f => f.id == cellInfo.data.ID && f.field == cellInfo.column.dataField);
            //    let dataSourceDatas = typeof _dataSource == "undefined" ? _header.dataSource : _dataSource.data;

            //    let cellInfoValueSplit = typeof cellInfo.value != "undefined" ? cellInfo.value.split(',') : [];

            //    let fieldsValues = dataSourceDatas.filter(x => cellInfoValueSplit.indexOf(x[_header.valueKey] + "") > -1);
            //    _itemObj = fieldsValues.length > 0 ? fieldsValues : null;
            //}
            //else {
            //    _itemObj = _item.find(x => x[_header.valueKey] == cellInfo.value);
            //} 
            let returnval;
            //  let datagridInDatagridSelect = this.datagridInDatagridSelects.find(x => x.column == _header.field && x.id == cellInfo.data.ID /*x.rowInde x == cellInfo.rowInde x*/);
            if (_itemObj) {
                if (_header.isMultipleSelect)
                    returnval = _itemObj.map(m => m[_header.displayKey]).join(", ");
                else
                    returnval = _itemObj[_header.displayKey];
            }
            //else if (datagridInDatagridSelect) {
            //    returnval = datagridInDatagridSelect.valuetext;
            //    returnval = returnval == "" || typeof returnval == "undefined" ? "&nbsp;" : returnval;
            //}
            else if (cellInfo.value && cellInfo.value != "")
                returnval = cellInfo.value;
            else
                returnval = "&nbsp;";

            return returnval;
        }
    }
    popoverDatagridInDatagridObj = {};
    togglePopoverDatagridInDatagrid(cellInfo) {
        if (cellInfo && cellInfo.data) {

            let _togglePopoverDatagridInDatagrid = this.popoverDatagridInDatagridObj[cellInfo.column.dataField + "_" + cellInfo.data.ID];
            if (typeof _togglePopoverDatagridInDatagrid != "undefined" && _togglePopoverDatagridInDatagrid != null)
                this.popoverDatagridInDatagridObj[cellInfo.column.dataField + "_" + cellInfo.data.ID] = !_togglePopoverDatagridInDatagrid;
            else {
                this.popoverDatagridInDatagridObj[cellInfo.column.dataField + "_" + cellInfo.data.ID] = true;
            } //this.defaultVisible = !this.defaultVisible;
        }
    }
    //popoverDatagridInDatagridVisible(cellInfo) {
    //    let _togglePopoverDatagridInDatagrid = this.popoverDatagridInDatagridArray.find(x => x.id == cellInfo.data.ID && x.field == cellInfo.column.dataField);
    //    if (_togglePopoverDatagridInDatagrid)
    //       return _togglePopoverDatagridInDatagrid.visible;
    //    else
    //        return _togglePopoverDatagridInDatagrid.visible = true;
    //}
    getPopoverLinkId(cellInfo, _pre) {
        if (typeof cellInfo != "undefined" && cellInfo.data) {
            return _pre + "_" + cellInfo.column.dataField + "_" + cellInfo.data.ID;
        }
    }
    selectionModeDatagridInDatagrid = "single";
    //outOfDatagridGetSq lOpenPopupFinish = true;
    gridHeaders = []
    showDatagridPopupLoading = false;

    isLoading(cellInfo) {
        if (this.dataGridPopupLoading) return this.dataGridPopupLoading

        if (typeof cellInfo != "undefined" && this.isLoadingElements.length > 0) {
            let findElement = this.isLoadingElements.find(f => f.id == cellInfo.data.ID && f.field == cellInfo.column.dataField)
            if (!!findElement) {
                return true
            }
        }
        return false
    }

    dataGridPopupLoading = false;
    openDatagridPopup(cellInfo) {
        if (typeof cellInfo != "undefined") {

            let header = this.data.headers.find(f => f.name == cellInfo.column.name);
            this.cellInfoGlobal = cellInfo;
            this.dataGridPopupLoading = true;
            this.showDatagridPopup = true;

            if (header) {
                if (header.isMultipleSelect == true) {
                    let isSelectAsRow = typeof header.isSelectAsRow == "undefined" ? (false) : (header.isSelectAsRow)
                    if (isSelectAsRow) {
                        this.arraySelectedDatagridInDatagrid = []
                    } else {
                        this.arraySelectedDatagridInDatagrid = cellInfo.data[cellInfo.column.dataField].replace(" ", "").split(',')
                    }
                } else {
                    this.arraySelectedDatagridInDatagrid = []
                }
                let replacedQuery = this.componentService.cloneModel(header.dataGridSettings.query);
                let fields = Object.keys(cellInfo.data);
                fields.forEach(f => {
                    let pref = "{" + f + "}";
                    let value = cellInfo.data[f];
                    if (value != null && typeof value != "undefined" && typeof value.getMonth === 'function') {
                        value = value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate();
                        replacedQuery = this.replaceAll(replacedQuery, pref, value);
                    }
                    else
                        replacedQuery = this.replaceAll(replacedQuery, pref, value);
                });

                //this.outOfDatagridGetSq lOpenPopupFinish = false;
                //var newRow = typeof row.row.isNewRow == "undefined" ? false : row.row.isNewRow;

                this.outOfDatagridGetSql(replacedQuery, /*row.rowInde x,*/ header.dataGridSettings.connectionStringId, header.field, /*newRow,*/ "", cellInfo).then(val => {
                    if (val) {


                        this.dataGridOpenDataEvent(cellInfo)

                    } else {

                        let findElement = this.isLoadingElements.findIndex(f => f.id == cellInfo.data.ID && f.field == cellInfo.column.dataField)
                        if (!!findElement && findElement != -1) {
                            this.isLoadingElements.splice(findElement, 1);
                        }

                        this.dataGridOpenDataEvent(cellInfo)

                    }

                    this.dataGridPopupLoading = false;

                    //    }

                    //}, 300);
                });
                //this.executeSqlExpressio n(, row.row.data,, newRow);
            }

            //setTimeout(() => {
            // //   
            //    if (cellInfo.value && cellInfo.value.indexOf(",") > -1) {

            //        var _dataSource = this.selectionGridDataSource.find(f => f.id == cellInfo.data.ID && f.field == cellInfo.column.dataField);
            //        let dataSourceDatas = typeof _dataSource == "undefined" ? header.dataSource : _dataSource.data;

            //        let cellInfoValueSplit = typeof cellInfo.value != "undefined" ? cellInfo.value.split(',') : [];

            //        let fieldsValues = dataSourceDatas.filter(x => cellInfoValueSplit.indexOf(x[header.valueKey] + "") > -1);
            //        /* this.arraySelectedDatagridInDatagrid*/
            //        this.gridContainerPopup.selectedRowKeys = fieldsValues;
            //    }
            //    else
            //        this.gridContainerPopup.selectedRowKeys = [];

            //    try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            //    try { this.gridContainerPopup.instance.refresh(); } catch (ex) { }
            //    try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            //}, 700);
        }
    }

    dataGridOpenDataEvent(cellInfo) {
        
        let header = this.data.headers.find(f => f.name == cellInfo.column.name);
        let selectionMode = 'single';
        let headerObj = this.data.headers.find(x => x.field == cellInfo.column.dataField);
        if (typeof headerObj != "undefined" && headerObj.isMultipleSelect == true)
            selectionMode = 'multiple';
        this.selectionModeDatagridInDatagrid = selectionMode;
        this.getDataSourcePromise().then(resp => {

            if (!!this.gridContainerPopup && !!this.gridContainerPopup.instance)
                this.gridContainerPopup.instance.option("paging.pageIndex", 0);

            let tThis = this;
            //let checkExistOutOfDatagrid = setInterval(function () {
            //if (tThis.outOfDatagridGetSq lOpenPopupFinish) {
            //clearInterval(checkExistOutOfDatagrid);
            //tThis.outOfDatagridGetSq lOpenPopupFinish = false;

            //if (cellInfo.value && cellInfo.value.indexOf(",") > -1) {
            if (cellInfo.value && header.isMultipleSelect) {

                var _dataSource = tThis.selectionGridDataSource.find(f => f.id == cellInfo.data.ID && f.field == cellInfo.column.dataField);
                let dataSourceDatas = typeof _dataSource == "undefined" ? header.dataSource : _dataSource.data;

                let cellInfoValueSplit = typeof cellInfo.value != "undefined" ? cellInfo.value.toString().split(',') : [];

                //let fieldsValues = dataSourceDatas.filter(x => cellInfoValueSplit.indexOf(x[header.valueKey] + "") > -1);

                if (!!this.gridContainerPopup && !!this.gridContainerPopup.selectedRowKeys) tThis.gridContainerPopup.selectedRowKeys = cellInfoValueSplit;
                //tThis.arraySelectedDatagridInDatagrid = cellInfoValueSplit;
            }
            else
                if (!!this.gridContainerPopup && !!this.gridContainerPopup.selectedRowKeys) tThis.gridContainerPopup.selectedRowKeys = [];

            try { tThis.changeDetectorRef.detectChanges(); } catch (ex) { }
            try { tThis.gridContainerPopup.instance.refresh(); } catch (ex) { }
            try { tThis.changeDetectorRef.detectChanges(); } catch (ex) { }
        })
    }

    storageKeyDatagridInDatagrid(cellInfoGlobal) {
        if (typeof cellInfoGlobal != "undefined") {
            //let _rowInde x = cellInfoGlobal.rowInde x
            let _header = this.data.headers.find(f => f.name == cellInfoGlobal.column.name);
            if (_header)
                return "storage_" + _header.field;
            else
                return "storage_";
        }
        return "storage_";
    }

    // #endregion DATAGRID IN DATAGRID COLUMN




    // #region COMBOBOX COLUMN
    /////////
    /// COMBOBOX COLUMN
    ////////
    // datagridInDatagridSelects = [];
    dataValTemp = {};
    prepareDataGridColumn(cols, index, isCreateHeader) {
        //valueKey     displayKey   type: "datagrid"
        debugger
        let query = cols[index].dataGridSettings.query;
        let queryClear = query.toLowerCase().replace(/(\r\n|\n|\r|\t)/gm, "").replace(" ", "");
        let _type = cols[index].type;
        let connectionStringId = cols[index].dataGridSettings.connectionStringId;
        if (query != "" && connectionStringId > 0) {
            query = this.editSqlByFormData(query);
            if (_type == "datagrid" && cols[index].valueKey == cols[index].displayKey) {
                this.loadedCompCount = this.loadedCompCount + 1;
                if (index == cols.length - 1) {
                    if (isCreateHeader)
                        this.CreateDatagridColumns();
                    return;
                }
                else {
                    this.prepareDataGridColumn(cols, ++index, isCreateHeader);
                }
            }
            else if (_type == "datagrid" && cols[index].valueKey != cols[index].displayKey && !queryClear.startsWith('exec') && !queryClear.startsWith('execute') &&
                queryClear.indexOf('order by') == -1) {

                this.loadedCompCount = this.loadedCompCount + 1;
                if (this.data.value.length > 0 || cols[index].isDefaultFirstValue == true) {

                    //if (this.dataValTemp[cols[index].field]) {
                    //    this.dataValTemp[cols[index].field].push();
                    //}

                    //fields.forEach(f => {
                    //    if (f != cols[index].valueKey) {
                    //        displayList.push(item[f]);
                    //    }
                    //});
                    this.datagridInDatagridSqlSelect(cols, index,/* 0, */query, connectionStringId, isCreateHeader);
                }
                else {
                    if (index == cols.length - 1) {
                        if (isCreateHeader)
                            this.CreateDatagridColumns();
                        return;
                    }
                    else {

                        this.prepareDataGridColumn(cols, ++index, isCreateHeader);
                    }
                }

            }
            else {
                if ((_type == "datagrid" && (this.data.value.length > 1 || (this.data.value.length == 1 && this.data.value[0][cols[index].field] != ""))) || _type == "combobox" || _type == "customableCombobox") {/*-----*/
                    this.genericService.executeQuerySecurited(query, connectionStringId).subscribe((result: any) => {

                        let fields = result.length > 0 ? Object.keys(result[0]) : undefined;
                        if (fields && _type == "combobox") {
                            result.forEach(item => {
                                if (fields.length == 1) {
                                    item.displayText = item[fields[0]];
                                }
                                else {
                                    let displayList = [];
                                    fields.forEach(f => {
                                        if (f != cols[index].valueKey) {
                                            displayList.push(item[f]);
                                        }
                                    });
                                    item[cols[index].valueKey] = item[cols[index].valueKey].toString();
                                    item.displayText = displayList.join(",");
                                }
                            });

                        }
                        if (fields && _type == "customableCombobox") {
                            debugger
                            result.forEach(item => {
                                debugger
                                if (fields.length == 1) {
                                    item.displayText = item[fields[0]];
                                    item.customValue = false
                                }
                                else {
                                    let displayList = [];
                                    fields.forEach(f => {
                                        if (f != cols[index].valueKey) {
                                            displayList.push(item[f]);
                                        }
                                    });
                                    item[cols[index].valueKey] = item[cols[index].valueKey].toString();
                                    item.displayText = displayList.join(",");
                                    item.customValue = false

                                }
                                
                            });

                            if (this.data.value.length > 0) {
                                this.data.value.forEach(el => {
                                    if (el[cols[index].field] != "") {
                                        var findElRes = result.filter(f => f[cols[index].valueKey] == el[cols[index].field])
                                        if (findElRes.length == 0) {
                                            var obj = { customValue: true };
                                            obj[cols[index].valueKey] = el[cols[index].field]
                                            result.push(obj)
                                        }
                                        
                                    }
                                    
                                })
                            }
            

                        }
                        this.loadedCompCount = this.loadedCompCount + 1;
                        cols[index].dataSource = result;
                        if (index == cols.length - 1) {
                            if (isCreateHeader)
                                this.CreateDatagridColumns();
                            return;
                        }
                        else {

                            this.prepareDataGridColumn(cols, ++index, isCreateHeader);
                        }

                    }, error => {
                        this.loadedCompCount = this.loadedCompCount + 1;
                    });
                }
                else {
                    this.loadedCompCount = this.loadedCompCount + 1;
                    if (index == cols.length - 1) {
                        if (isCreateHeader)
                            this.CreateDatagridColumns();
                        return;
                    }
                    else {

                        this.prepareDataGridColumn(cols, ++index, isCreateHeader);
                    }
                }

            }

        }
        else {


            let sqlExpressionColumn = this.data.sqlExpressions.find(x => x.field == cols[index].field)
            if (sqlExpressionColumn/* && cols[index].type == "combobox"*/) {

                //  query = this.editSqlByFormData(query);
                this.data.value.forEach(f16 => {
                    try {
                        if (cols[index].type == "combobox" || cols[index].type == "customableCombobox")
                            f16[cols[index].field] = f16[cols[index].field].toString();
                    } catch (ex) { }

                    try { this.executeSqlExpression(/*cols[index].field,*/"", f16); } catch (ex) { }
                });
                //this.datagridInDatagridSqlSelect(cols, index,/* 0, */sqlExpressionColumn.query, sqlExpressionColumn.connectionStringId, isCreateHeader);
            }

            this.loadedCompCount = this.loadedCompCount + 1;

            if (index == cols.length - 1) {
                if (isCreateHeader)
                    this.CreateDatagridColumns();
                return;
            }
            else {

                this.prepareDataGridColumn(cols, ++index, isCreateHeader);
            }

        }
    }

    datagridInDatagridSqlSelect(cols, index/*, rowIndex */, query, connectionStringId, isCreateHeader) {


        let _fieldVals = this.data.value.map(x => x[cols[index].field]).toString();
        let date = new Date();
        let timestamp = date.getTime();
        //  let _valObj = this.data.value[rowIndex][cols[index].field];
        let _sqlcolumn = "";
        let _header = this.data.headers.find(f => f.field == cols[index].field);
        if (typeof _fieldVals != "undefined" && _fieldVals != "") {/*-----*/

            _sqlcolumn = "SELECT * FROM (" + query + ") [IMAGES " + timestamp + "]  WHERE " + cols[index].valueKey;
            //if (_header.isMultipleSelect)
            _sqlcolumn = _sqlcolumn + " IN ('" + this.replaceAll(_fieldVals, ",", "','") + "')"
            //else
            //    _sqlcolumn = _sqlcolumn + "='" + _valObj + "'"
        }

        if (_sqlcolumn == "" && cols[index].isDefaultFirstValue == true)
            _sqlcolumn = query;

        if (_sqlcolumn != "") {
            this.genericService.executeQuerySecurited(_sqlcolumn, connectionStringId).subscribe((result: any) => {
                //let _valDisplayKey;
                //if (_header.isMultipleSelect) {
                //    _valDisplayKey = result.map(m => m[_header.displayKey]).join(", ");//[0][cols[index].displayKey]
                //}
                //else
                //    _valDisplayKey = result[0][cols[index].displayKey];
                _header.dataSource = result;
                // this.datagridInDatagridSelects.push({ column: cols[index].field, rowIndex: rowIndex, id: this.data.value[rowIndex].ID, valuetext: _valDisplayKey });
                //cols[index].dataSource = result;
                //rowIndex++;
                //if (this.data.value.length > rowIndex) {
                //    this.datagridInDatagridSqlSelect (cols, index, rowIndex, query, connectionStringId, isCreateHeader);
                //}
                //else {

                if (index == cols.length - 1) {
                    if (isCreateHeader)
                        this.CreateDatagridColumns();
                    return;
                }
                else {

                    this.prepareDataGridColumn(cols, ++index, isCreateHeader);
                }
                // }
            });
        }
        else {
            // rowIndex++;
            //if (this.data.value.length > rowIndex) {
            //    this.datagridInDatagridSqlSelect (cols, index, rowIndex, query, connectionStringId, isCreateHeader);
            //}
            //else {
            if (index == cols.length - 1) {
                if (isCreateHeader)
                    this.CreateDatagridColumns();
                return;
            }
            else {
                this.prepareDataGridColumn(cols, ++index, isCreateHeader);
            }
        }
        //} 
    }
    //datagridInDatagridSqlSelect(cols, index, rowIndex, query, connectionStringId, isCreateHeader) {

    //    let date = new Date();
    //    let timestamp = date.getTime();
    //    let _valObj = this.data.value[rowIndex][cols[index].field];
    //    if (typeof _valObj != "undefined" && _valObj != "") {/*-----*/

    //        let _sqlcolumn = "SELECT " + cols[index].displayKey + " FROM (" + query + ") [IMAGES " + timestamp + "]  WHERE " + cols[index].valueKey;
    //        let _header = this.data.headers.find(f => f.field == cols[index].field);
    //        if (_header.isMultipleSelect)
    //            _sqlcolumn = _sqlcolumn + " IN ('" + this.replaceAll(_valObj, ",", "','") + "')"
    //        else
    //            _sqlcolumn = _sqlcolumn + "='" + _valObj + "'"

    //        this.genericService.executeQuerySecurited(_sqlcolumn, connectionStringId).subscribe((result: any) => {
    //            //let _valDisplayKey;
    //            //if (_header.isMultipleSelect) {
    //            //    _valDisplayKey = result.map(m => m[_header.displayKey]).join(", ");//[0][cols[index].displayKey]
    //            //}
    //            //else
    //            //    _valDisplayKey = result[0][cols[index].displayKey];
    //            _header.dataSource = result;
    //            // this.datagridInDatagridSelects.push({ column: cols[index].field, rowIndex: rowIndex, id: this.data.value[rowIndex].ID, valuetext: _valDisplayKey });
    //            //cols[index].dataSource = result;
    //            //rowIndex++;
    //            //if (this.data.value.length > rowIndex) {
    //            //    this.datagridInDatagridSqlSelect(cols, index, rowIndex, query, connectionStringId, isCreateHeader);
    //            //}
    //            //else {

    //            if (index == cols.length - 1) {
    //                if (isCreateHeader)
    //                    this.CreateDatagridColumns();
    //                return;
    //            }
    //            else {

    //                this.prepareDataGridColumn(cols, ++index, isCreateHeader);
    //            }
    //            // }
    //        });
    //    }
    //    else {
    //        // rowIndex++;
    //        //if (this.data.value.length > rowIndex) {
    //        //    this.datagridInDatagridSqlSelect(cols, index, rowIndex, query, connectionStringId, isCreateHeader);
    //        //}
    //        //else {
    //        if (index == cols.length - 1) {
    //            if (isCreateHeader)
    //                this.CreateDatagridColumns();
    //            return;
    //        }
    //        else {
    //            this.prepareDataGridColumn(cols, ++index, isCreateHeader);
    //        }
    //    }
    //    //} 
    //}

    editSqlByFormData(query) {
        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        let componentList = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);

        var pattern = /( and | or | where |)\{[a-zA-Z0-9 _\[\],'=ıİöÖçÇğĞ()üÜşŞ%]+\}/ig;
        var patt = new RegExp(pattern);
        if (patt.test(query)) {
            let _match = query.match(pattern);
            if (_match.length > 0) {
                _match.forEach(f => {
                    let _k = componentList.find(k => k.id == f.replace("{", "").replace("}", ""));
                    if (_k) {
                        let _value = _k.value == null ? "" : _k.value;
                        query = this.componentService.replaceAllUsingType(query, f, _value);
                    }
                });
            }
        }
        return query;
    }


    // #endregion COMBOBOX COLUMN




    // #region MAP COLUMN
    /////////
    /// MAP COLUMN
    ////////

    markerOptionPopup = false;
    zoom: number = 8;
    mapMarkerLocal = {
        id: 0
    }
    mapMarkerSelectedImage = "";
    // initial center position for the map
    lat: number = 39.925533;
    lng: number = 32.866287;
    map;
    public mapReady(map) {
        this.map = map;
        //this.map.addListener("dragend", function () {
        //    try { document.querySelector('div[style*="color: rgb(0, 0, 0); font-size: 14px; font-family: Roboto, Arial, sans-serif;"]').className = "marker-cls"; } catch (ex) { }
        //});
        //this.map.addListener("mouseup", function () {
        //    try { document.querySelector('div[style*="color: rgb(0, 0, 0); font-size: 14px; font-family: Roboto, Arial, sans-serif;"]').className = "marker-cls"; } catch (ex) { }
        //});
    }

    markerDragEnd(m: marker, $event: MouseEvent) {
        if (!this.selectedMapHeader.disabled) {
            let _id = m.id;

            let _val = this.data.value.find(x => x.ID == this.cellInfoGlobal.data.ID)[this.cellInfoGlobal.column.dataField];

            let indexDataMarker = _val.markers.findIndex(x => x.id == _id);
            m.lat = $event.coords.lat;
            m.lng = $event.coords.lng;
            _val.markers[indexDataMarker] = m;
        }
        this.setClassMarker();
    }

    clickedMarker(label: string, index: number, id: number) {
        //this.mapMarkerSelectedImage = "";
        if (!this.selectedMapHeader.disabled) {
            let _val = this.data.value.find(x => x.ID == this.cellInfoGlobal.data.ID)[this.cellInfoGlobal.column.dataField];

            this.mapMarkerLocal = _val.markers.find(x => x.id == id);
        }

        this.setClassMarker();
    }
    mapClicked($event: MouseEvent) {
        if (this.cellInfoGlobal) {

            let _val = this.data.value.find(x => x.ID == this.cellInfoGlobal.data.ID)[this.cellInfoGlobal.column.dataField];

            let _header = this.data.headers.find(f => f.name == this.cellInfoGlobal.column.name);

            let ismarkerMulti = (_header.isAddMultipleMarker && (_header.maxMarkerLimit > _val.markers.length || _header.maxMarkerLimit == 0)) ||
                (!_header.isAddMultipleMarker && _val.markers.length == 0);

            if (ismarkerMulti) {
                let _id = _val.markers.length > 0 ? _val.markers[_val.markers.length - 1].id + 1 : 1;
                _val.markers.push({
                    lat: $event.coords.lat,
                    lng: $event.coords.lng,
                    label: "",
                    uploadedFiles: [],
                    id: _id,
                    draggable: _header.draggable
                });
            }

            this.selectedMap = typeof _val == "undefined" || typeof _val == "undefined" ? [] : _val;
            this.selectedMapHeader = this.data.headers.find(f => f.name == this.cellInfoGlobal.column.name);

        }
        this.setClassMarker();
    }

    //badgeMapMarkersCount = 0;
    getBadgeMapMarkers(cellInfo) {
        if (cellInfo && cellInfo.data) {
            if (this.data.value.find(x => x.ID == cellInfo.data.ID)) {

                let _val = this.data.value.find(x => x.ID == cellInfo.data.ID)[cellInfo.column.dataField];
                if (_val && _val != "" && _val.markers) {
                    // this.badgeMapMarkersCount = _val.markers.length;
                    return _val.markers.length;
                }
                else {
                    // this.badgeMapMarkersCount = 0;
                    return 0;
                }
            }
        }
    }
    clickMarkerAddInfo(markerLocal: any, index: number, id: number) {
        if (!this.selectedMapHeader.disabled) {
            this.markerOptionPopup = true;
        }
        this.setClassMarker();
    }

    clickMarkerDelete(markerLocal: any, index: number, id: number) {
        if (!this.selectedMapHeader.disabled) {
            let _id = this.mapMarkerLocal.id;
            let _val = this.data.value.find(x => x.ID == this.cellInfoGlobal.data.ID)[this.cellInfoGlobal.column.dataField];
            let indexDataMarker = _val.markers.findIndex(x => x.id == _id);
            this.data.value.find(x => x.ID == this.cellInfoGlobal.data.ID)[this.cellInfoGlobal.column.dataField].markers.splice(indexDataMarker, 1);
        }
        this.setClassMarker();
    }

    cancelMarkerOption() {
        this.markerOptionPopup = false;
        let _id = this.mapMarkerLocal.id;

        let _val = this.data.value.find(x => x.ID == this.cellInfoGlobal.data.ID)[this.cellInfoGlobal.column.dataField];

        this.mapMarkerLocal = _val.markers.find(x => x.id == _id);
        this.setClassMarker();
    }

    saveMarkerOption() {
        this.markerOptionPopup = false;
        let _id = this.mapMarkerLocal.id;

        let _val = this.data.value.find(x => x.ID == this.cellInfoGlobal.data.ID)[this.cellInfoGlobal.column.dataField];

        let indexDataMarker = _val.markers.findIndex(x => x.id == _id);
        _val.markers[indexDataMarker] = this.mapMarkerLocal;
        this.setClassMarker();
    }

    setClassMarker() {
        try {
            document.querySelector('div[style*="color: rgb(0, 0, 0); font-size: 14px; font-family: Roboto, Arial, sans-serif;"]').className = "marker-cls";
        } catch (ex) { }
    }

    selectedMap = {};
    selectedMapHeader = { disabled: false };
    mapVisiblePopup = false;
    mapIntervalCheck = true;
    toggleMapPopup(cellInfo) {
        if (cellInfo) {

            this.cellInfoGlobal = cellInfo;
            let _val = this.data.value.find(x => x.ID == cellInfo.data.ID)[cellInfo.column.dataField];
            this.selectedMapHeader = this.data.headers.find(f => f.name == cellInfo.column.name);
            this.selectedMapHeader.disabled = typeof this.selectedMapHeader.disabled == "undefined" ? false : this.selectedMapHeader.disabled;

            if (typeof _val == "undefined" || _val == null || _val == "") {
                this.data.value.find(x => x.ID == cellInfo.data.ID)[cellInfo.column.dataField] = { markers: [] };
                _val = { markers: [] };
            }
            else if (typeof _val.markers == "undefined")
                _val.markers = [];

            this.selectedMap = _val;

            this.mapIntervalCheck = false;
            let tThis = this;
            let mapInterval = setInterval(function () {
                if (tThis.mapIntervalCheck)
                    clearInterval(mapInterval);
                try {
                    document.querySelector('div[style*="color: rgb(0, 0, 0); font-size: 14px; font-family: Roboto, Arial, sans-serif;"]').className = "marker-cls";
                } catch (ex) { }

            }, 5);

            this.mapVisiblePopup = true;
        }
    }

    onHidingMapPopup(e) {
        //this.cellInfoGlobal = null;
        this.selectedMap = {};
        this.mapIntervalCheck = true;
        this.selectedMapHeader = { disabled: false };
        this.zoom = 8;
        this.mapMarkerLocal = {
            id: 0
        }
    }

    // #endregion MAP COLUMN





    // #region FILEVIEWER COMPONENT COLUMN
    /////////
    /// FILEVIEWER COMPONENT COLUMN
    ////////
    showFileViewerPopup = false;
    popupSelectedImageValue = [];
    fileViewerDatasource = [];
    showFileViewerPanel(e, cellInfo) {
        this.popupSelectedImageValue = [];
        this.showFileViewerPopup = true;
        this.fileViewerDatasource = [];
        //name   extension    src    isImg    fileType   this.replaceAll(_exReplaced.toLowerCase(), ".", "");size
        //this.genericService.executeQuerySecurited(replacedQuery, connectionStringId).subscribe((result: any) => {

        //    if (result && result.length) {

        //    }
        //});

        //header.dataGridSettings.quer y        header.dataGridSettings.connectionStringId        header.field
        let header = this.data.headers.find(f => f.name == cellInfo.column.name);

        let replacedQuery = this.componentService.cloneModel(header.sqlColumnSettings.query);
        let fields = Object.keys(cellInfo.data);
        fields.forEach(f => {
            let pref = "{" + f + "}";
            let value = cellInfo.data[f];
            if (value != null && typeof value != "undefined" && typeof value.getMonth === 'function') {
                value = value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate();
                replacedQuery = this.replaceAll(replacedQuery, pref, value);
            }
            else
                replacedQuery = this.replaceAll(replacedQuery, pref, value);
        });
        replacedQuery = this.outOfDatagridGetSqlReplace(replacedQuery)

        this.genericService.executeQuerySecurited(replacedQuery, header.sqlColumnSettings.connectionStringId).subscribe((result: any) => {
            if (result && result.length) {
                //           "sqlColumnSettings": {
                //    "connectionStringId": 0,
                //    "query": "", 
                //    "fileFields": {
                //        "name": "",
                //        "file": "",
                //        "fileName": "",
                //        "extension": "" 
                //    }
                //},
                result.forEach(_resultRow => {
                    //tif   tiff   bmp   jpg   jpeg    gif    png   application/pdf
                    let _isImg = false;
                    let _size = 0;
                    let _fileType = "";
                    let _extension = "";
                    let _value = _resultRow[header.sqlColumnSettings.fileFields.file];
                    let _exReplaced = header.sqlColumnSettings.fileFields.extension != "" ? _resultRow[header.sqlColumnSettings.fileFields.extension] :
                        (header.sqlColumnSettings.fileFields.fileName != "" ? _resultRow[header.sqlColumnSettings.fileFields.fileName].split(".").pop() : "")

                    if (_exReplaced != "") {
                        _exReplaced = _exReplaced.toLowerCase().split('.').join('');
                        if (",tif,tiff,bmp,jpg,jpeg,gif,png,".indexOf("," + _exReplaced + ",") > -1)
                            _isImg = true;
                        _size = _value.length * (3 / 4);

                        if (_isImg) _fileType = "image/" + _exReplaced;
                        else if (_exReplaced == "pdf") _fileType = "application/pdf";

                        _extension = _exReplaced;
                    }

                    let document: any = {
                        base64BlobData: _value,
                        name: _resultRow[header.sqlColumnSettings.fileFields.name],
                        fileType: _fileType,
                        extension: _extension,
                        size: _size,
                        isImg: _isImg,
                        src: 'data:' + _fileType + ';base64,' + _value
                    };
                    this.fileViewerDatasource.push(document);

                });

                this.changeDetectorRef.detectChanges();

            }
        });

    }


    createBlob(file) {
        return new Promise(resolve => {
            fetch(file.src)
                .then(res => res.blob())
                .then(blob => { resolve(blob) })
                .catch(err => { console.log(err) })
        });
    }
    previewFile(file, type) {

        var a: any = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";

        this.createBlob(file).then(
            (res: any) => {
                if (!!res) {
                    const fileURL = window.URL.createObjectURL(res);

                    a.href = fileURL;
                    if (type == 'preview' && file.fileType == "application/pdf") {
                        a.target = "_blank"
                    } else {
                        a.download = file.name;
                    }
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                }

            }
        );

        //this.previewFileSrc = file.src;
        //this.showPreviewFilesPopup = true;
    }

    popupSelectedImage = "";
    imageZoomPopup = false;
    showImageFile(_src) {
        this.popupSelectedImage = _src;
        this.imageZoomPopup = true;
    }




    // #endregion FILEVIEWER COLUMN




    // #region PICTUREBOX COMPONENT COLUMN
    /////////
    /// PICTUREBOX COMPONENT COLUMN
    ////////

    pictureboxImageUploadVisibleObj = {};
    pictureboxUploaderStyleFunc(cellInfo, _type) {
        let headerObj = this.data.headers.find(x => x.field == cellInfo.column.dataField);
        var _block = cellInfo && this.pictureboxImageUploadVisibleObj[cellInfo.column.dataField + '_' + cellInfo.data.ID] && ((_type != "Preview" && headerObj.properties.isUserUpload) || _type == "Preview") ? 'block' : 'none';
        const styles = { 'display': _block };
        if (_type == "Preview") {
            if (headerObj.properties.isUserUpload) {
                styles["left"] = "calc(50% - 29px)";
                styles["top"] = "calc(50% - 10px)";
            }
            else {
                styles["left"] = "calc(50% - 16px)";
                styles["top"] = "calc(50% - 12px)";
            }
        }
        return styles;
        // return this.pictureboxImageUploadVisibleObj[cellInfo.column.dataField + '_' + cellInfo.data.ID] && cellInfo.column.properties.isUserUpload; //pictureboxTemplate
    }
    onBlurPicturebox(cellInfo) {
        if (cellInfo && cellInfo.data) {
            this.pictureboxImageUploadVisibleObj[cellInfo.column.dataField + "_" + cellInfo.data.ID] = false;
        }
    }

    onClickPicturebox(cellInfo) {
        this.popupSelectedImage = "";
        if (cellInfo) {
            let header = this.data.headers.find(f => f.name == cellInfo.column.name);
            let docs = header.files.filter(f => f.row == cellInfo.data.ID);
            if (docs.length > 0) {

                let _docsTemp = docs[0];
                let ss = _docsTemp.file;
                if (_docsTemp.file.base64BlobData && _docsTemp.file.base64BlobData != "") {
                    this.showImageFile('data:' + _docsTemp.file.fileType + ';base64,' + _docsTemp.file.base64BlobData);
                }
            }
            //else if (cellInfo.value && cellInfo.value !="" && ){

            //}
            //let pictureboxRowsValueTemp = this.pictureboxRowsValue.find(x => x.rowId == cellInfo.data.ID && x.columnId == cellInfo.column.dataField);
            //if (pictureboxRowsValueTemp)
            //    return pictureboxRowsValueTemp.src; 
        }
    }
    onFocusPicturebox(cellInfo) {
        if (cellInfo && cellInfo.data) {
            this.pictureboxImageUploadVisibleObj[cellInfo.column.dataField + "_" + cellInfo.data.ID] = true;
        }
    }

    pictureboxDivStyleFunc() {
        var width = '60px';// this.data.width > 0 ? this.data.width + 'px' : '50px';
        var height = '62px';//this.data.height > 0 ? this.data.height + 'px' : '52px';
        const styles = { 'width': width, 'height': height, 'display': this.showDisplay, 'position': 'relative' };
        return styles;
    }

    pictureboxStyleFunc() {
        var width = '60px';//this.data.width > 0 ? this.data.width + 'px' : '50px';
        var height = '60px';// this.data.height > 0 ? (this.data.height - 2) + 'px' : '50px';
        const styles = { 'width': width, 'height': height, 'display': this.showDisplay };
        return styles;
    }

    pictureboxShowImage(cellInfo) {
        if (cellInfo) {
            let header = this.data.headers.find(f => f.name == cellInfo.column.name);
            let docs = header.files.filter(f => f.row == cellInfo.data.ID);
            if (docs.length > 0) {
                let _docsTemp = docs[0];
                let ss = _docsTemp.file;
                if (_docsTemp.file.base64BlobData && _docsTemp.file.base64BlobData != "")
                    return 'data:' + _docsTemp.file.fileType + ';base64,' + _docsTemp.file.base64BlobData;
                else
                    return '/assets/img/default-placeholder.png';

            }
            //else if (cellInfo.value && cellInfo.value !="" && ){

            //}
            //let pictureboxRowsValueTemp = this.pictureboxRowsValue.find(x => x.rowId == cellInfo.data.ID && x.columnId == cellInfo.column.dataField);
            //if (pictureboxRowsValueTemp)
            //    return pictureboxRowsValueTemp.src; 
        }

        return '/assets/img/default-placeholder.png';
    }

    pictureboxRowsValue = [];
    onPictureboxValueChanged = (e: any, cellInfo: any): void => {
        //let errors = [];
        //this.data.valueTemp = [];
        if (e.value.length) {
            //this.data.valu e = [];
            //this.totalSize = 0;
            //this.uploadResult.isShow = true;
            //this.uploadResult.status = "primary";
            //this.uploadResult.message = "Dosyalar yükleniyor...";
            ////  for (let i = 0; i < .length; i++) {
            //let checkSum = this.totalSize + e.value[0].size;
            //if (checkSum > (this.data.maxFileSize * 1000000)) {
            //    let msg = e.value[0].name + " yüklenemedi."
            //    errors.push(msg);
            //    return;
            //}

            this.getDocument(e.value[0]).then((document: any) => {
                let header = this.data.headers.find(f => f.name == cellInfo.column.name);
                let doc: any = {};
                doc.row = cellInfo.data.ID;
                doc.file = document;

                let pictureboxRowsValueTempIndex = header.files.findIndex(x => x.row == cellInfo.data.ID);
                if (pictureboxRowsValueTempIndex == -1)
                    header.files.push(doc);
                else {
                    this.componentService.datagridFilePicturebox.push({ anotherPopupId: this.anotherPopupId, id: header.files[pictureboxRowsValueTempIndex].file.id });
                    header.files[pictureboxRowsValueTempIndex] = doc;
                }

                //let pictureboxRowsValueTempIndex = this.pictureboxRowsValue.findIndex(x => x.rowId == cellInfo.data.ID && x.columnId == cellInfo.column.dataField);
                //if (pictureboxRowsValueTempIndex == -1)
                //    this.pictureboxRowsValue.push(document);
                //else
                //    this.pictureboxRowsValue[pictureboxRowsValueTempIndex] = document;





                //this.totalSize += e.value[0].size;
                //if (i === (e.value.length - 1)) {
                //if (errors.length) {
                //    this.uploadResult.status = "danger";
                //    this.uploadResult.message = "Dosya boyutu aşıldı: " + errors.join(", ");
                //}
                //else {
                //    this.uploadResult.status = "success";
                //    this.uploadResult.message = "Dosyalar yüklendi";
                //}

                //if (this.isEdit) {

                //    let objArray = [];
                //    let obj: any = {
                //        componentId: this.data.id,
                //        rowId: "-1 ,
                //        columnId: "-1 ,
                //        fileData: this.data.valueTemp[0].base64BlobData,
                //        fileExtension: this.data.valueTemp[0].extension,
                //        fileType: this.data.valueTemp[0].fileType,
                //        isImage: this.data.valueTemp[0].isImg,
                //        name: this.data.valueTemp[0].name,
                //        size: this.data.valueTemp[0].size,
                //        description: ""
                //    };

                //    objArray.push(obj);

                //    this.instanceService.saveFormFilesData(objArray, 0, this.dat a.value).subscribe((s: any) => {

                //        let _fileVal = s.files.filter(x => x.ComponentId == this.data.id);
                //        if (_fileVal != null && typeof _fileVal != "undefined" && _fileVal.length > 0) {
                //            this.data.value = _fileVal.map(ele => ele.Id);

                //        }

                //    });

                //}

                this.changeDetectorRef.detectChanges();
                // }
            });

            // }
        }
    }

    // #endregion PICTUREBOX COLUMN




    // #region FILE COMPONENT COLUMN
    /////////
    /// FILE COMPONENT COLUMN
    ////////

    onValueChanged = (e: any, row): void => {
        //row.column.name
        if (e.value.length) {
            let header = this.data.headers.find(f => f.name == row.column.name);
            e.value.forEach(f => {
                this.getDocument(f).then(document => {

                    let doc: any = {};
                    doc.row = row.data.ID;
                    doc.file = document;
                    header.files.push(doc);

                });
            });
        }
    }
    getDocument(file) {
        return new Promise(resolve => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (args: any) => {
                if (reader.result) {
                    let fileName = file.name;
                    let fileType: string = file.type;
                    let base64BlobData: any = args.target.result.split(',').pop();
                    let fileExtension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2);
                    let fileSize = file.size;
                    let document: any = {
                        base64BlobData: base64BlobData,
                        name: fileName,
                        fileType: fileType,
                        extension: fileExtension,
                        size: fileSize,
                        isImg: this.componentService.isImage(fileExtension)
                    };
                    resolve(document);
                }
            }

        });
    }

    fileUploaderDisabled(row) {
        return !row.isEditing || (typeof row == 'undefined' || typeof row.data == 'undefined' || typeof row.data.ID == 'undefined' || row.data.ID == 0);
    }

    datagridHeaderFiles: any = []
    //datagridValueFiles: any = []
    showFile(row) {
        this.datagridHeaderFiles = []
        //this.datagridValueFiles = []
        let header = this.data.headers.find(f => f.name == row.column.name);
        let docs = header.files.filter(f => f.row == row.data.ID);
        if (docs.length > 0) {
            docs.forEach(f => {
                f.file.src = 'data:' + f.file.fileType + ';base64,' + f.file.base64BlobData;
                this.datagridHeaderFiles.push(f.file);
            });
        }

        this.showPreviewFilesPopup = true;
    }

    getFileCount(row) {
        let header = this.data.headers.find(f => f.name == row.column.name);
        let docs = header.files.filter(f => f.row == row.data.ID);
        let returnCount = docs.length;
        return returnCount == 0 ? "" : "(" + returnCount + ")"
    }


    deleteFiles() {
        let result = confirm("<i>" + this.languageLocal.filesSureDelete + "</i>", this.languageLocal.filesWillDeleted);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.uploadedFiles = [];

                let datagridHeader = this.data.headers.filter(x => x.type == "file");
                datagridHeader.forEach(_datagridHeader => {
                    _datagridHeader.files = [];
                    let dataFiles = _datagridHeader.files;
                    let columnId = _datagridHeader.field;
                    dataFiles.forEach(_dataFile => {
                        /*[parseInt()]*/
                        this.data.value.find(x => x.ID == _dataFile.row)[columnId] = [];
                    });
                });
                this.datagridHeaderFiles = [];
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    deleteFile(_id, _base64BlobData, _name) {
        let result = confirm("<i>" + this.languageLocal.fileDelete + "</i>", this.languageLocal.filesWillDeleted);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.deleteFileConfirm(_id, _base64BlobData, _name);
            }
        });

    }

    deleteFileConfirm(_id, _base64BlobData, _name) {
        if (typeof _id != "undefined") {
            let datagridHeader = this.data.headers.filter(x => x.type == "file");
            datagridHeader.forEach(_datagridHeader => {

                let dataFiles = _datagridHeader.files;
                let columnId = _datagridHeader.field;
                dataFiles.forEach(_dataFile => {
                    let _indexTemp = this.data.value.find(x => x.ID == parseInt(_dataFile.row));
                    if (_indexTemp) {
                        let _indexVal = _indexTemp[columnId].findIndex(x => x == _id);
                        if (_indexVal != -1) {
                            _indexTemp[columnId].splice(_indexVal, 1);
                        }
                    }

                });

                let _index = _datagridHeader.files.findIndex(x => x.file.id == _id);
                if (_index != -1)
                    _datagridHeader.files.splice(_index, 1);

                this.eventEmitterService.setDeletedFileIds(_id);
            });

            let _indexShowFiles = this.datagridHeaderFiles.findIndex(x => x.id == _id);
            if (_indexShowFiles != -1)
                this.datagridHeaderFiles.splice(_indexShowFiles, 1);
        }
        else {
            try {
                let datagridHeader = this.data.headers.filter(x => x.type == "file");
                datagridHeader.forEach(_datagridHeader => {
                    let _index = _datagridHeader.files.findIndex(x => x.file.base64BlobData == _base64BlobData && x.file.name == _name);
                    if (_index != -1)
                        _datagridHeader.files.splice(_index, 1);
                    //_base64BlobData, _name
                });
                let _indexShowFiles = this.datagridHeaderFiles.findIndex(x => x.base64BlobData == _base64BlobData && x.name == _name);
                if (_indexShowFiles != -1)
                    this.datagridHeaderFiles.splice(_indexShowFiles, 1);
            } catch (ex) { }
        }
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }


    // #endregion FILE COMPONENT COLUMN




    // #region DATAGRID CHANGE EVENTS ( ADD ROW - UPDATE ROW - DELETE ROW - ONCHANGE CELL )
    /////////
    ///  DATAGRID CHANGE EVENTS ( ADD ROW - UPDATE ROW - DELETE ROW - ONCHANGE CELL )
    ////////

    onEditorPreparing(e) {

        if (e.parentType === "dataRow" && !this.isCalcCellFormula) {
            e.editorOptions.onKeyDown = args => {
                if (args.event.ctrlKey) {
                    if (args.event.keyCode == 46/*.keyCode == 13*/) {// CTRL + DELETE => DELETE ROW 

                        let dataIndex = this.data.value.findIndex(x => x.ID == this.focusedRowKey);
                        if (dataIndex != -1)
                            this.dataGridEtr.instance.deleteRow(dataIndex);  // this.focusedRowKey 

                    }
                    else if (args.event.keyCode == 16) {  // CTRL + SHIFT => NEW ROW 
                        //let datagridInstance = this.dataGridEtr.instance;
                        if (this.loadingVisible == false) {
                            this.addNewRowFunction();
                        }
                    }
                }

            };

            if (e.row.data.ID != this.selectedRowsDataOnchange.ID && (e.row.isNewRow == true || e.row.isEditing == true)) {

                this.selectedRowsDataOnchange = e.row.data;
                this.data.selectedRows = [];
                this.data.selectedRows.push(this.selectedRowsDataOnchange.ID);
                this.focusedRowKey = this.selectedRowsDataOnchange.ID;
                //this.selectedDatagridChangeFilter (this.selectedRowsDataOnchange);
            }  //if (this.data.selectedDatagrid && Array.isArray(this.data.selectedDatagrid) && this.data.selectedDatagrid.length > 0) {
            //    let _thisPage = this;
            //    this.data.selectedDatagrid.forEach(function (_item) {
            //        if (_item.id && _item.id != "") {
            //            let itemField = _item.field == null || _item.field == "" ? undefined : _item.field ;
            //            let dataVal = _item.sourceField == null || _item.sourceField == "" || typeof _item.sourceField == "undefined" ? e.row.data.ID:
            //                e.row.data[_item.sourceField];
            //            //this.data.selectedDatagridFilterField = this.data.selectedDatagridFilterField == null ? undefined : this.data.selectedDatagridFilterField;
            //            let _data = { element: _thisPage.data.id, value: dataVal, elementLabel: _thisPage.defaultLanguage.Label, filteredElement: _item.id, filteredColumn: itemField, isDelete: false, isSqlExecute: true };
            //            _thisPage.eventEmitterService.ChangeFilterOutElemen t (_data);
            //        }
            //    });
            //}

            e.editorOptions.onFocusIn = (args: any) => {
                var input = args.element.querySelector('.dx-texteditor-input[inputmode="decimal"]');

                if (input != null) {
                    input.select();
                }
                //  this.isGridSelection = true;
            };

            //e.editorOptions.onFocusOut = (args: any) => { 
            //    this.isGridSelection = true;
            //};

            this.dateTimeMinMaxFormat(e);

            e.editorOptions.onValueChanged = args => {

                let _val = args.value;//dataField: "talepmiktari"
                let _datagridHeader = this.data.headers.find(x => x.field == e.dataField);
                if (_datagridHeader.type == "int") {
                    //if (Math.round(val) === -0) {
                    // Math.floor(   ceil     round
                    //e.component.option("value", 0)
                    if (_datagridHeader.properties.roundType == "ceil")
                        _val = Math.ceil(_val);
                    else if (_datagridHeader.properties.roundType == "floor")
                        _val = Math.floor(_val);
                    else
                        _val = Math.round(_val);
                }

                e.setValue(_val);

                this.forceRefreshDatagrid();
                setTimeout(() => {
                    //this.dataGridEtr.instance.saveEditData(); 
                }, 50);
                //, 
                if (typeof this.data.userChangeColumns == "undefined")
                    this.data.userChangeColumns = [];
                let _userChange = this.data.userChangeColumns.find(x => x.header == e.dataField && x.ID == e.row.data.ID);
                if (typeof _userChange == "undefined") {

                    let changeSystemField = [];
                    if (this.data.sqlExpressions.length > 0)
                        changeSystemField = this.data.sqlExpressions.map(m => m.field);

                    this.data.headers.forEach(f => {
                        if (typeof f.expression.code != "undefined")
                            changeSystemField.push(f.field);
                    });

                    if (typeof changeSystemField.find(x => x == e.dataField) != "undefined") {

                        this.data.userChangeColumns.push({ header: e.dataField, ID: e.row.data.ID });
                    }
                }


                var newRow = false;// typeof e.row.isNewRow == "undefined" ? false : e.row.isNewRow;
                this.executeSqlExpression(e.dataField, e.row.data/*, e.row.rowInde x, newRow*/);


            };

        }

    }

    onNumberFieldValueChanged(e: any, cellInfo) {

        if (e.value) {

            let _val: number = e.value

            let _datagridHeader = this.data.headers.find(x => x.field == cellInfo.column.dataField);
            if (_datagridHeader.type == "int") {
                //if (Math.round(val) === -0) {
                // Math.floor(   ceil     round
                //e.component.option("value", 0)
                if (_datagridHeader.properties.roundType == "ceil")
                    _val = Math.ceil(_val);
                else if (_datagridHeader.properties.roundType == "floor")
                    _val = Math.floor(_val);
                else
                    _val = Math.round(_val);
            }

            cellInfo.setValue(_val);

            this.forceRefreshDatagrid();
            setTimeout(() => {
                //this.dataGridEtr.instance.saveEditData(); 
            }, 50);
            //, 
            if (typeof this.data.userChangeColumns == "undefined")
                this.data.userChangeColumns = [];
            let _userChange = this.data.userChangeColumns.find(x => x.header == cellInfo.column.dataField && x.ID == cellInfo.row.data.ID);
            if (typeof _userChange == "undefined") {

                let changeSystemField = [];
                if (this.data.sqlExpressions.length > 0)
                    changeSystemField = this.data.sqlExpressions.map(m => m.field);

                this.data.headers.forEach(f => {
                    if (typeof f.expression.code != "undefined")
                        changeSystemField.push(f.field);
                });

                if (typeof changeSystemField.find(x => x == cellInfo.column.dataField) != "undefined") {

                    this.data.userChangeColumns.push({ header: cellInfo.column.dataField, ID: cellInfo.row.data.ID });
                }
            }


            var newRow = false;// typeof e.row.isNewRow == "undefined" ? false : e.row.isNewRow;
            this.executeSqlExpression(cellInfo.column.dataField, cellInfo.row.data/*, e.row.rowInde x, newRow*/);
        }


    }

    dateTimeMinMaxFormat(_e) {
        //this.data.headers[selectedFormatIndex].properties.dateMinMax.
        let _datagridHeader = this.data.headers.find(x => x.field == _e.dataField);
        if (_datagridHeader.type == "date" || _datagridHeader.type == "datetime") {

            let minDateTemp: Date = null;
            let maxDateTemp: Date = null;

            _datagridHeader.properties.dateMinMax.minToday = typeof _datagridHeader.properties.dateMinMax.minToday == "undefined" ? false : _datagridHeader.properties.dateMinMax.minToday;
            _datagridHeader.properties.dateMinMax.maxToday = typeof _datagridHeader.properties.dateMinMax.maxToday == "undefined" ? false : _datagridHeader.properties.dateMinMax.maxToday;
            _datagridHeader.properties.dateMinMax.dateRange = typeof _datagridHeader.properties.dateMinMax.dateRange == "undefined" ? null : _datagridHeader.properties.dateMinMax.dateRange;

            if (_datagridHeader.properties.dateMinMax.maxToday && _datagridHeader.properties.dateMinMax.minToday) {
                minDateTemp = new Date(new Date().setHours(0, 0, 0, 0));
                maxDateTemp = new Date(new Date().setHours(23, 59, 59, 0));

            } else {

                if (_datagridHeader.properties.dateMinMax.minToday) {
                    minDateTemp = new Date();
                    if (_datagridHeader.properties.dateMinMax.dateRange != null && _datagridHeader.properties.dateMinMax.dateRange != 0) {
                        let range: Date = new Date(minDateTemp.getTime() + ((1000 * 60 * 60 * 24) * _datagridHeader.properties.dateMinMax.dateRange));
                        range = new Date(new Date(range).setHours(23, 59, 59, 0));  // Okunurluk düzeltmesi yap
                        maxDateTemp = range
                    } else {
                        maxDateTemp = null
                    }
                }

                if (_datagridHeader.properties.dateMinMax.maxToday) {
                    maxDateTemp = new Date(new Date().setHours(23, 59, 59, 0));
                    if (_datagridHeader.properties.dateMinMax.dateRange != null && _datagridHeader.properties.dateMinMax.dateRange != 0) {
                        let range: Date = new Date(maxDateTemp.getTime() - ((1000 * 60 * 60 * 24) * _datagridHeader.properties.dateMinMax.dateRange));
                        range = new Date(new Date(range).setHours(0, 0, 0, 0));         // Okunurluk düzeltmesi yap
                        minDateTemp = range
                    } else {
                        minDateTemp = null
                    }
                }
            }

            _e.editorOptions.min = minDateTemp;
            _e.editorOptions.max = maxDateTemp;

        }

    }

    isLoadingElements = []

    executeSqlExpression(field, data/* ,rowIndex = null, newRow*/) {
        //this.outOfDatagridGetSq lFinish = true;

        let dataCols = this.data.headers.filter(f => f.type == "combobox" || f.type == "datagrid" || f.type == "customableCombobox");
        if (this.data.sqlExpressions.length > 0) {

            this.data.sqlExpressions.forEach(exp => {


                if (exp.query.includes("{" + field + "}")) {
                    let _data = { id: data.ID, field: exp.field, from: field, isLoading: true }

                    if (this.isLoadingElements.filter(f => f.id == _data.id && f.field == _data.field).length == 0) {
                        //console.log("eklenen eleman", _data)
                        this.isLoadingElements.push(_data)
                    }
                }

            })


            this.data.sqlExpressions.forEach(exp => {

                let tThis = this;
                //let checkExist = setInterval(function () {
                //    if (tThis.outOfDatagridGetSq lFinish) {
                //        clearInterval(checkExist);
                //        tThis.outOfDatagridGetSq lFinish = false;
                let replacedQuery = tThis.componentService.cloneModel(exp.query);
                let fields = Object.keys(data);

                fields.forEach(f => {
                    let pref = "{" + f + "}";
                    let value = data[f];
                    if (value != null && typeof value != "undefined" && typeof value.getMonth === 'function') {
                        value = value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate();
                        replacedQuery = tThis.replaceAll(replacedQuery, pref, value);
                    }
                    else
                        replacedQuery = tThis.replaceAll(replacedQuery, pref, value);
                });


                this.outOfDatagridGetSql(replacedQuery, /*rowInde x,*/ exp.connectionStringId, exp.field, /*newRow,*/ field, data).then(val => {

                    let findElement = this.isLoadingElements.findIndex(f => f.id == data.ID && f.field == exp.field)
                    if (typeof findElement != "undefined" && findElement != -1) {
                        this.isLoadingElements.splice(findElement, 1);
                    }
                    if (typeof this.cellInfoGlobal != "undefined")
                        this.dataGridOpenDataEvent(this.cellInfoGlobal)


                });
                //    }

                //}, 300);




            });



        }
    }
    disabledRowsIDs = []
    alertRowsIDs = []
    onRowPrepared(event) {
        if (event.rowType === "data" && this.data.colorExpressions.length > 0) {
            // console.log(event)
            
            this.data.colorExpressions.forEach(expression => {

                let replacedQuery = expression.conditionText
                replacedQuery = this.componentService.cloneModel(expression.conditionText);
                let fields = Object.keys(event.data);

                fields.forEach(f => {
                    let pref = "{" + f + "}";
                    let value = event.data[f];
                    if (value != null && typeof value != "undefined" && typeof value.getMonth === 'function') {
                        value = value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate();
                        replacedQuery = this.replaceAll(replacedQuery, pref, value);
                    }
                    else
                        replacedQuery = this.replaceAll(replacedQuery, pref, value);
                });


                let currentExpression = expression.expression
              //  console.log(expression.pointerEvent)

                if (expression.expression == "=") { currentExpression = "==" }
                let query = event.data[expression.fieldName] + " " + currentExpression + " " + replacedQuery
                if (expression.fieldType == "number") {
                    let functionn = "if(" + query.replace(/[\u200B-\u200D\uFEFF]/g, '') + ")" + "{return true;} else { return false; }";
                    let result = eval('(function() {' + functionn + '}())');
                    if (result == true) {
                        //boya

                        try {
                            if (expression.backgroundColor) event.rowElement.style.backgroundColor = expression.backgroundColor;
                            if (expression.fontColor) event.rowElement.style.color = expression.fontColor;

                            if (expression.alert) {
                                    if (this.alertRowsIDs.includes(event.data.ID)) {
                                    }
                                    else {
                                        this.componentService.showAlert(expression.alert, "error", 2500)
                                        this.alertRowsIDs.push(event.data.ID)
                                        console.log(event.data.ID)
                                    }
                            }
                            if (expression.pointerEvent == "undefined" || expression.pointerEvent == false) {
                                console.log(event.rowElement)
                                event.rowElement.style.pointerEvents = "all";
                            } else {
                                console.log(event.rowElement)

                                event.rowElement.style.pointerEvents = "none";
                               // console.log(expression.pointerEvent)
                                this.disabledRowsIDs.push(event.data.ID)
                            }
                            //alerti burada verdim ancak asagıda cıkıyor popup gibi isteniyorsa burayı düzeltmelisin.
                        } catch (err) {

                        }


                    }

                }

                if (expression.fieldType == "string") {

                    if (expression.expression == "like") {
                        if (event.data[expression.fieldName].includes(replacedQuery)) {
                            if (expression.backgroundColor) event.rowElement.style.backgroundColor = expression.backgroundColor;
                            if (expression.fontColor) event.rowElement.style.color = expression.fontColor;
                            if (expression.alert) {
                                if (this.alertRowsIDs.includes(event.data.ID)) {
                                }
                                else {
                                    this.componentService.showAlert(expression.alert, "error", 2500)
                                    this.alertRowsIDs.push(event.data.ID)
                                }
                            }
                            if (expression.pointerEvent == false) {
                                event.rowElement.style.pointerEvents = "all";
                            } else {
                                event.rowElement.style.pointerEvents = "none"; this.disabledRowsIDs.push(event.data.ID);
                            }
                        }
                    }
                    if (expression.expression == "startsWith") {
                        if (event.data[expression.fieldName].startsWith(replacedQuery)) {
                            if (expression.backgroundColor) event.rowElement.style.backgroundColor = expression.backgroundColor;
                            if (expression.fontColor) event.rowElement.style.color = expression.fontColor;
                            if (expression.alert) {
                                if (this.alertRowsIDs.includes(event.data.ID)) {
                                }
                                else {
                                    this.componentService.showAlert(expression.alert, "error", 2500)
                                    this.alertRowsIDs.push(event.data.ID)
                                }
                            }
                            if (expression.pointerEvent == false) {
                                event.rowElement.style.pointerEvents = "all";
                            } else {
                                event.rowElement.style.pointerEvents = "none"; this.disabledRowsIDs.push(event.data.ID)
                            }
                        }
                    }
                    if (expression.expression == "notIncludes") {
                        if (!event.data[expression.fieldName].includes(replacedQuery)) {
                            if (expression.backgroundColor) event.rowElement.style.backgroundColor = expression.backgroundColor;
                            if (expression.fontColor) event.rowElement.style.color = expression.fontColor;
                            if (expression.alert) {
                                if (this.alertRowsIDs.includes(event.data.ID)) {
                                }
                                else {
                                    this.componentService.showAlert(expression.alert, "error", 2500)
                                    this.alertRowsIDs.push(event.data.ID)
                                }
                            }
                            if (expression.pointerEvent == false) {
                                event.rowElement.style.pointerEvents = "all";
                            } else {
                                event.rowElement.style.pointerEvents = "none"; this.disabledRowsIDs.push(event.data.ID);
                            }
                        }
                    }

                    if (expression.expression == "endsWith") {
                        if (event.data[expression.fieldName].endsWith(replacedQuery)) {
                            if (expression.backgroundColor) event.rowElement.style.backgroundColor = expression.backgroundColor;
                            if (expression.fontColor) event.rowElement.style.color = expression.fontColor;
                            if (expression.alert) {
                                if (this.alertRowsIDs.includes(event.data.ID)) {
                                }
                                else {
                                    this.componentService.showAlert(expression.alert, "error", 2500)
                                    this.alertRowsIDs.push(event.data.ID)
                                }
                            }
                            if (expression.pointerEvent == false) {
                                event.rowElement.style.pointerEvents = "all";
                            } else {
                                event.rowElement.style.pointerEvents = "none"; this.disabledRowsIDs.push(event.data.ID)
                            }
                        }
                    }
                    if (expression.expression == "equals") {
                        if (event.data[expression.fieldName] == replacedQuery) {
                            if (expression.backgroundColor) event.rowElement.style.backgroundColor = expression.backgroundColor;
                            if (expression.fontColor) event.rowElement.style.color = expression.fontColor;
                            if (expression.alert) {
                                if (this.alertRowsIDs.includes(event.data.ID)) {
                                }
                                else {
                                    this.componentService.showAlert(expression.alert, "error", 2500)
                                    this.alertRowsIDs.push(event.data.ID)
                                }
                            }
                            if (expression.pointerEvent == false) {
                                event.rowElement.style.pointerEvents = "all";
                            } else {
                                event.rowElement.style.pointerEvents = "none"; this.disabledRowsIDs.push(event.data.ID)
                            }
                        }
                    }

                }

                if (expression.fieldType == "object") {
                    if (expression.expression == "equals") {
                        if (event.data[expression.fieldName].toString() == replacedQuery) {
                            if (expression.backgroundColor) event.rowElement.style.backgroundColor = expression.backgroundColor;
                            if (expression.fontColor) event.rowElement.style.color = expression.fontColor;
                            if (expression.alert) {
                                if (this.alertRowsIDs.includes(event.data.ID)) {
                                }
                                else {
                                    this.componentService.showAlert(expression.alert, "error", 2500)
                                    this.alertRowsIDs.push(event.data.ID)
                                }
                            }
                            if (expression.pointerEvent == false) {
                                event.rowElement.style.pointerEvents = "all";
                            } else {
                                event.rowElement.style.pointerEvents = "none"; this.disabledRowsIDs.push(event.data.ID)
                            }
                        }
                    }
                }
            });
        }

    }

    onInitNewRowBegin(event) {
        if (this.data.sqlExpressions.length > 0) {

            this.data.sqlExpressions.forEach(exp => {

                let tThis = this;

                tThis.loadedCompCount = tThis.loadedCompCount + 1;
                let replacedQuery = tThis.componentService.cloneModel(exp.query);

                let fields = Object.keys(event.data);
                fields.forEach(f => {
                    let pref = "{" + f + "}";
                    let value = event.data[f];
                    if (value != null && typeof value != "undefined" && typeof value.getMonth === 'function') {
                        value = value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate();
                        replacedQuery = tThis.replaceAll(replacedQuery, pref, value);
                    }
                    else
                        replacedQuery = tThis.replaceAll(replacedQuery, pref, value);
                });


                if ((typeof event.data.ID == "undefined" || event.data.ID == "") && event.data.ID != 0) {
                    let firstIdTemp = 1;
                    if (this.isFirstItemZero) {
                        firstIdTemp = 0;
                        this.isFirstItemZero = false;
                    }
                    event.data.ID = this.data.value.length === 0 ? firstIdTemp : this.data.value[this.data.value.length - 1].ID + 1;
                }

                tThis.outOfDatagridGetSql(replacedQuery, /*rowInde x,*/ exp.connectionStringId, exp.field, /*true,*/ "", event.data, false).then(val => {
                    //let ffffff = event;
                    //this.onInitRowBottom(event, false);
                    //let selectedIndex = this.data.value.findIndex(x => x.ID == event.data.ID);
                    //if (selectedIndex != -1) {
                    //    this.data.value[selectedIndex] = event.data;
                    //}
                    //else {
                    //    this.data.value.push(event.data);
                    //} 
                });



            });
        }
    }
    onInitNewRow(event) {

        //this.showLoadPane l();

        if (event && event.data) {

            if (this.data.selectedDatagrid.length == 0)
                this.onInitNewRowBegin(event);
            //else {
            //    //if ((typeof event.data.ID == "undefined" || event.data.ID == "") && event.data.ID != 0) {
            //    //    let firstIdTemp = 1;
            //    //    if (this.isFirstItemZero) {
            //    //        firstIdTemp = 0;
            //    //        this.isFirstItemZero = false;
            //    //    }
            //    //    event.data.ID = this.data.value.length === 0 ? firstIdTemp : this.data.value[this.data.value.length - 1].ID + 1;
            //    //}
            //    //this.onInitRowBottom(event, true);
            //}


            var elemVal_AllHeader = this.data.headers
            for (var j = 0; j < elemVal_AllHeader.length; j++) {

                if (elemVal_AllHeader[j] && elemVal_AllHeader[j].type && (elemVal_AllHeader[j].type == "number" || elemVal_AllHeader[j].type == "int" || elemVal_AllHeader[j].type == "double")) {
                    if (!event.data[elemVal_AllHeader[j].field] || event.data[elemVal_AllHeader[j].field] == "")
                        event.data[elemVal_AllHeader[j].field] = 0;
                }
                else if (elemVal_AllHeader[j] && elemVal_AllHeader[j].type && elemVal_AllHeader[j].type == "text") {
                    if (!event.data[elemVal_AllHeader[j].field] || event.data[elemVal_AllHeader[j].field] == "") {
                        if (elemVal_AllHeader[j].properties && elemVal_AllHeader[j].properties.autoText && elemVal_AllHeader[j].properties.autoText.isAuto) {

                            event.data[elemVal_AllHeader[j].field] = elemVal_AllHeader[j].properties.autoText.autoPreText + event.data["ID"];
                        }
                    }
                }
                else if (elemVal_AllHeader[j] && elemVal_AllHeader[j].type && elemVal_AllHeader[j].type == "checkbox") {
                    event.data[elemVal_AllHeader[j].field] = false;
                }
                else if (elemVal_AllHeader[j] && elemVal_AllHeader[j].type && elemVal_AllHeader[j].type == "datetime" || elemVal_AllHeader[j] && elemVal_AllHeader[j].type && elemVal_AllHeader[j].type == "date") {
                    event.data[elemVal_AllHeader[j].field] = null;
                }

                if ((typeof event.data.ID == "undefined" || event.data.ID == "") && event.data.ID != 0) {
                    let firstIdTemp = 1;
                    if (this.isFirstItemZero) {
                        firstIdTemp = 0;
                        this.isFirstItemZero = false;
                    }
                    event.data.ID = this.data.value.length === 0 ? firstIdTemp : this.data.value[this.data.value.length - 1].ID + 1;
                }

                event.data[elemVal_AllHeader[j].field] = this.getDataGridSelectValue(elemVal_AllHeader[j].field, event.data[elemVal_AllHeader[j].field]);

            }


            let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
            let outFilterDatagrid = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
            if (outFilterDatagrid) {
                let selectedDatagridElements = outFilterDatagrid.filter(x => typeof x.selectedDatagrid != "undefined" && x.selectedDatagrid != null && x.selectedDatagrid != "" &&
                    ((Array.isArray(x.selectedDatagrid) && typeof x.selectedDatagrid.find(y => y.id == this.data.id) != undefined) ||
                        (!Array.isArray(x.selectedDatagrid) && x.selectedDatagrid == this.data.id)));

                selectedDatagridElements.forEach(_sde => {
                    if (typeof this.filteredColumnDatagrid == "undefined" || (Array.isArray(this.filteredColumnDatagrid) && this.filteredColumnDatagrid.length == 0)) {
                        if (_sde.type != "datagrid") {
                            let _filtCol = "filtercolumnsystem_" + _sde.id;
                            let outFilterDatagridFind = outFilterDatagrid.find(xx => xx.id == _sde.id);
                            event.data[_filtCol] = outFilterDatagridFind ? outFilterDatagridFind.value : "";
                        }
                    }
                    else if (_sde.type == "datagrid" || _sde.type == "datalist") {
                        if (_sde.selectedRows > 0) {
                            let sourceCompCols = _sde.selectedDatagrid.filter(x => x.id == this.data.id);
                            let sourceValue = _sde.value.find(x => x.ID == _sde.selectedRows[0]);
                            sourceCompCols.forEach(_scc => {
                                event.data[_scc.field] = sourceValue[_scc.sourceField];
                                let _dataIndex = this.data.value.findIndex(x => x.ID == event.data.ID);
                                if (_dataIndex > -1) {
                                    this.data.value[_dataIndex][_scc.field] = sourceValue[_scc.sourceField];
                                }
                            });

                        }


                    }
                });

            }





        }
    }

    //onInitRowBottom(event, isInsertId) {
    //    var elemVal_AllHeader = this.data.headers
    //    for (var j = 0; j < elemVal_AllHeader.length; j++) {

    //        if (elemVal_AllHeader[j] && elemVal_AllHeader[j].type && (elemVal_AllHeader[j].type == "number" || elemVal_AllHeader[j].type == "int" || elemVal_AllHeader[j].type == "double")) {
    //            if (!event.data[elemVal_AllHeader[j].field] || event.data[elemVal_AllHeader[j].field] == "")
    //                event.data[elemVal_AllHeader[j].field] = 0;
    //        }
    //        else if (elemVal_AllHeader[j] && elemVal_AllHeader[j].type && elemVal_AllHeader[j].type == "text") {
    //            if (!event.data[elemVal_AllHeader[j].field] || event.data[elemVal_AllHeader[j].field] == "") {
    //                if (elemVal_AllHeader[j].properties && elemVal_AllHeader[j].properties.autoText && elemVal_AllHeader[j].properties.autoText.isAuto) {

    //                    event.data[elemVal_AllHeader[j].field] = elemVal_AllHeader[j].properties.autoText.autoPreText + event.data["ID"];
    //                }
    //            }
    //        }
    //        else if (elemVal_AllHeader[j] && elemVal_AllHeader[j].type && elemVal_AllHeader[j].type == "checkbox") {
    //            event.data[elemVal_AllHeader[j].field] = false;
    //        }
    //        else if (elemVal_AllHeader[j] && elemVal_AllHeader[j].type && elemVal_AllHeader[j].type == "datetime" || elemVal_AllHeader[j] && elemVal_AllHeader[j].type && elemVal_AllHeader[j].type == "date") {
    //            event.data[elemVal_AllHeader[j].field] = null;
    //        }

    //        if ((typeof event.data.ID == "undefined" || event.data.ID == "") && event.data.ID != 0) {
    //            let firstIdTemp = 1;
    //            if (this.isFirstItemZero) {
    //                firstIdTemp = 0;
    //                this.isFirstItemZero = false;
    //            }
    //            event.data.ID = this.data.value.length === 0 ? firstIdTemp : this.data.value[this.data.value.length - 1].ID + 1;
    //        }

    //        event.data[elemVal_AllHeader[j].field] = this.getDataGridSelectValue(elemVal_AllHeader[j].field, event.data[elemVal_AllHeader[j].field]);

    //    }
    //}


    outOfDatagridGetSqlReplace(replacedQuery) {
        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        this.outOfGridComponentDatas = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
        //this.outOfGridComponentDatas = this.componentService.formComponentList;
        if (this.outOfGridComponent.length > 0) {

            let sqlExpressionTag = replacedQuery.match(/\{(\w|\.)+\}/ig);
            if (sqlExpressionTag != null && typeof sqlExpressionTag != "undefined" && sqlExpressionTag.length > 0) {

                for (let _kk = 0; _kk < sqlExpressionTag.length; _kk++) {

                    sqlExpressionTag[_kk] = sqlExpressionTag[_kk].replace("{", "").replace("}", "");
                    let isActive;
                    let isActiveColumnValue = null;
                    if (sqlExpressionTag[_kk].indexOf(".") != -1) {
                        isActive = this.outOfGridComponentDatas.filter(x => x.id == sqlExpressionTag[_kk].split(".")[0]);
                        if (isActive.length > 0) {//sqlExpressionTag[_kk].split(".")[1]
                            let isActiveTempValue = isActive[0].value.find(x => x.ID == isActive[0].selectedRows[0]);
                            if (isActiveTempValue)
                                isActiveColumnValue = isActiveTempValue[sqlExpressionTag[_kk].split(".")[1]];
                        }
                        //sqlExpressionTag[_kk].split(".")[1] 
                        //isActiveColumnValue = this.outOfGridComponentDatas.find(x => x.id == isActive[0].id).value.find(x => x[]==);
                    }
                    else
                        isActive = this.outOfGridComponent.filter(x => x.id == sqlExpressionTag[_kk]);
                    //let isActive = this.outOfGridComponent.filter(x => x.id == sqlExpressionTag[_kk]);
                    if (isActive.length > 0) {
                        //let pref = "{" + isActive[0].id + "}";
                        let pref = "{" + sqlExpressionTag[_kk] + "}";
                        let value;
                        if (isActiveColumnValue != null) {
                            value = isActiveColumnValue;
                        }
                        else
                            value = this.outOfGridComponentDatas.find(x => x.id == isActive[0].id).value;
                        //let value = this.outOfGridComponentDatas.find(x => x.id == isActive[0].id).value;
                        if (value != null && typeof value != "undefined" && value != "") {//true
                            if (typeof value.getMonth === 'function') {
                                value = value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate();
                                replacedQuery = this.replaceAll(replacedQuery, pref, value);
                            }
                            else if (typeof value.match === 'function' && value.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z?/gm)) {
                                value = value.split("T")[0];
                                replacedQuery = this.replaceAll(replacedQuery, pref, value);
                            }
                            else
                                replacedQuery = this.replaceAll(replacedQuery, pref, value);
                        }
                        else {
                            replacedQuery = "";
                        }

                    }

                }


            }

        }

        let parametersAll = this.appInfo.RiverEntity.parameters;
        Object.keys(parametersAll).forEach(f => {
            replacedQuery = this.replaceAll(replacedQuery, f, parametersAll[f]);
        });

        return replacedQuery;
    }

    outOfDatagridGetSql(replacedQuery, /*rowInde x,*/ connectionStringId, field, /*newRow,*/ fieldGeneral, dataSelected, isExecuteExp = true) {

        return new Promise((resolve, reject) => {

            let dataSelectedData = dataSelected;
            if (typeof dataSelectedData.ID == "undefined")
                dataSelectedData = dataSelected.data;
            //let fullOutComponentValue = []; 

            replacedQuery = this.outOfDatagridGetSqlReplace(replacedQuery);

            let sqlExpressionTagControl = replacedQuery.match(/\{(\w|\.)+\}/ig);;
            //let ifControl = false;
            if (replacedQuery != "" && (sqlExpressionTagControl == null || typeof sqlExpressionTagControl == "undefined" || sqlExpressionTagControl.length == 0 || (
                (sqlExpressionTagControl.length == 1 && sqlExpressionTagControl[0] == "{CURRENTUSERID}")
            ))
            ) {
                // this.isSqlQuery = true;
                //this.showLoadPane l();
                this.genericService.executeQuerySecurited(replacedQuery, connectionStringId).subscribe((result: any) => {
                    console.log(result)
                    if (result && result.length) {


                        let header = this.data.headers.find(f => f.field == field);
                        if (header.type == "combobox" || header.type == "datagrid" || header.type == "customableCombobox") {
                            let fields = Object.keys(result[0]);
                            if (fields && header.type == "combobox" || fields && header.type == "customableCombobox") {
                                result.forEach(item => {
                                    if (fields.length == 1) {
                                        item.displayText = item[fields[0]];
                                    }
                                    else {
                                        let displayList = [];
                                        fields.forEach(f => {
                                            if (f != header.valueKey) {
                                                displayList.push(item[f]);
                                            }
                                        });
                                        item.displayText = displayList.join(",");
                                    }
                                    item[header.valueKey] = item[header.valueKey].toString();
                                });

                            }



                            //this.forceRefreshDatagrid();

                            if (fieldGeneral != field) {

                                let curr = this.selectionGridDataSource.find(f => /*f.index == rowInde x*/f.id == dataSelectedData.ID && f.field == field);
                                if (curr) {
                                    curr.data = [];
                                    curr.data = result;
                                }
                                else {
                                    let arr: any = {};
                                    arr.field = field;
                                    //arr.index = rowInde x;
                                    arr.id = dataSelectedData.ID;
                                    //arr.colIndex = columnIndex;
                                    arr.data = result;
                                    this.selectionGridDataSource.push(arr);
                                    curr = arr;
                                }

                                /* if (newRow && dataSelecte d != null && typeof dataSelecte d.ID != "undefined") {
                                     rowInde x = this.data.value.findIndex(x => x.ID == dataSelecte d.ID);
                                     newRow = false;
                                 }*/
                                //   rowInde x = newRow ? this.dataGridEtr.instance.totalCount() : rowInde x;

                                //if (rowInde x == -1) {
                                //    let selectedIndex = rowInde x;
                                //    if (newRow && dataSelecte d != null && typeof dataSelecte d.ID != "undefined") {
                                //        selectedIndex = this.data.value.findIndex(x => x.ID == dataSelecte d.ID);
                                //        newRow = false;
                                //    }
                                //    if (selectedIndex == -1)
                                //        selectedIndex = 0;
                                //    rowInde x = selectedIndex;
                                //}

                                //var cellObject = this.dataGridEtr.instance.getCellElement(rowInde x, field);

                                // if (typeof cellObject != "undefined") {

                                //var columnIndex = cellObject["cellIndex"];

                                //rowInde x = newRow ? 0 : rowInde x;
                                let _ifConControl = false;
                                var selectedValue = dataSelectedData[field];//dataSelected this.dataGridEtr.instance.cellValue(rowInde x, field);
                                if ((typeof selectedValue == "undefined" || selectedValue == null || selectedValue == "")) {

                                    this.data.value.find(x => x.ID == dataSelectedData.ID)[field] = "";
                                    this.forceRefreshDatagrid();
                                    _ifConControl = true;
                                }

                                var selectedValueFirstVal = this.getDataGridSelectValueWithDataSource(field, result);

                                let _userChange = undefined;
                                if (typeof this.data.userChangeColumns != "undefined") {
                                    let _id = dataSelectedData != null && typeof dataSelectedData.ID != "undefined" ? dataSelectedData.ID : 1;
                                    _userChange = this.data.userChangeColumns.find(x => x.header == field && x.ID == _id);
                                }
                                if (typeof _userChange == "undefined") {

                                    if ((typeof selectedValue == "undefined" || selectedValue == null || selectedValue == "") && !(typeof selectedValueFirstVal == "undefined" || selectedValueFirstVal == null || selectedValueFirstVal == "")) {
                                        this.data.value.find(x => x.ID == dataSelectedData.ID)[field] = selectedValueFirstVal;
                                        if (dataSelectedData)
                                            dataSelectedData[field] = selectedValueFirstVal;

                                        this.forceRefreshDatagrid();
                                        _ifConControl = true;
                                    }
                                    else if (!(typeof selectedValueFirstVal == "undefined" || selectedValueFirstVal == null || selectedValueFirstVal == "")) {
                                        let selectedIndex = this.data.value.findIndex(x => x.ID == dataSelectedData.ID);
                                        if (selectedIndex != -1) {
                                            this.data.value[selectedIndex][field] = selectedValueFirstVal;
                                            if (dataSelectedData) {
                                                dataSelectedData[field] = selectedValueFirstVal;
                                            }
                                            this.forceRefreshDatagrid();
                                            _ifConControl = true;
                                        }
                                    }

                                    if (_ifConControl == false)
                                        this.forceRefreshDatagrid();

                                }


                                // }

                            }

                        }
                        //if (header.type == "fileviewer") {
                        //    let fields = Object.keys(result[0]);

                        //    if (fieldGeneral != field) {

                        //        //let curr = this.selectionGridDataSource.find(f => f.id == dataSelectedData.ID && f.field == field);
                        //        //if (curr) {
                        //        //    curr.data = [];
                        //        //    curr.data = result;
                        //        //}
                        //        //else {
                        //        //    let arr: any = {};
                        //        //    arr.field = field; 
                        //        //    arr.id = dataSelectedData.ID; 
                        //        //    arr.data = result;
                        //        //    this.selectionGridDataSource.push(arr);
                        //        //    curr = arr;
                        //        //}

                        //        
                        //    }

                        //}
                        else {
                            let _userChange = undefined;
                            if (typeof this.data.userChangeColumns != "undefined") {
                                let _id = dataSelectedData != null && typeof dataSelectedData.ID != "undefined" ? dataSelectedData.ID : 1;
                                _userChange = this.data.userChangeColumns.find(x => x.header == field && x.ID == _id);
                            }
                            if (typeof _userChange == "undefined") {

                                let keys = Object.keys(result[0]);
                                if (keys.length > 0) {
                                    let finalData = [];
                                    keys.forEach(f => {
                                        finalData.push(result[0][f]);
                                    });
                                    let value = finalData.join(",");

                                    // let rowInde xSelected = rowInde x;
                                    // let selectedIndex = -2;
                                    /* if (newRow && dataSelecte d != null && typeof dataSelecte d.ID != "undefined") {
                                         selectedIndex = this.data.value.findIndex(x => x.ID == dataSelecte d.ID);
                                         rowInde xSelected = selectedIndex != -1 ? selectedIndex : rowInde x;
                                     }
                                     else if (dataSelecte d != null && typeof dataSelecte d.ID != "undefined") {
                                         selectedIndex = this.data.value.findIndex(x => x.ID == dataSelecte d.ID);
                                     }*/


                                    let header = this.data.headers.find(f => f.field == field);
                                    if (header.type == "picturebox") {
                                        //let header = this.data.headers.find(f => f.field == field);
                                        //let docs = header.files.filter(f => f.row == dataSelectedData.ID);

                                        let headerGeneral = this.data.headers.find(f => f.field == fieldGeneral);
                                        let generalName = headerGeneral ? headerGeneral.caption : "";
                                        let doc: any = {};
                                        let document: any = {
                                            base64BlobData: value,
                                            name: generalName,
                                            fileType: "image/png",
                                            extension: "png",
                                            size: -1,
                                            isImg: true
                                        };
                                        doc.row = dataSelectedData.ID;
                                        doc.file = document;

                                        let docsIndex = header.files.findIndex(f => f.row == dataSelectedData.ID);
                                        if (docsIndex != -1)
                                            header.files[docsIndex] = doc;
                                        else
                                            header.files.push(doc);
                                        //value = doc;
                                    }
                                    else {
                                        if (typeof dataSelectedData != "undefined" && typeof dataSelectedData.ID != "undefined") {
                                            let selectedIndex = this.data.value.findIndex(x => x.ID == dataSelectedData.ID);
                                            if (selectedIndex != -1) {
                                                this.data.value[selectedIndex][field] = value;
                                            }
                                            //else { 
                                            //    this.dataGridEtr.instance.cellValue(selectedIndex, field, value);

                                            //   // var cellObject = this.dataGridEtr.instance.getCellElement(rowInde x, field);
                                            //} 

                                        }

                                        if (dataSelectedData)
                                            dataSelectedData[field] = value;
                                    }


                                    try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                                    try { this.changeDetectorRef.markForCheck(); } catch (ex) { }
                                    try { this.dataGridEtr.instance.refresh(); } catch (ex) { }
                                    try { this.changeDetectorRef.detectChanges(); } catch (ex) { }

                                    let _evt = { data: {} };

                                    if (typeof dataSelectedData != "undefined" && typeof dataSelectedData.ID != "undefined") {
                                        let _tempValIndex = this.data.value.findIndex(x => x.ID == dataSelectedData.ID);
                                        if (this.data.value[_tempValIndex]) {
                                            _evt.data = this.data.value[_tempValIndex];
                                            if (_evt && _evt.data && isExecuteExp)
                                                this.executeExp(_evt, this.dbQueryTypes['update']);
                                        }
                                    }


                                }

                            }

                        }

                    }



                    this.changeDetectorRef.detectChanges();
                    resolve(result)
                    // this.hideLoadPane l(); 
                    //this.outOfDatagridGetSq lFinish = true;
                    //this.outOfDatagridGetSq lOpenPopupFinish = true;

                }, error => {

                    this.changeDetectorRef.detectChanges();
                    resolve(null)
                    //this.outOfDatagridGetSq lFinish = true;
                    //this.outOfDatagridGetSq lOpenPopupFinish = true;
                    console.log(error);
                    // this.hideLoadPane l();
                });
            }
            else {

                this.changeDetectorRef.detectChanges();
                resolve(null)
                //this.outOfDatagridGetSq lFinish = true;
                //this.outOfDatagridGetSq lOpenPopupFinish = true;
                //  this.hideLoadPane l();
            }
        })

    }

    dataValRelated = [];
    executeExp(event, process_type) {
        try {
            if (process_type != this.dbQueryTypes.recursiveRelatedRowEvent) {

                this.dataValRelated = [];

                if (!(typeof this.filteredColumnDatagrid == "undefined" || (Array.isArray(this.filteredColumnDatagrid) && this.filteredColumnDatagrid.length == 0))) {

                    let _filterChangeTemp;
                    if (Array.isArray(this.filteredColumnDatagrid)) {
                        let _cols = [];
                        let _vals = [];
                        this.filteredColumnDatagrid.forEach(_e => {
                            _cols.push(_e);
                            _vals.push(event.data[_e]);
                        });
                        _filterChangeTemp = { col: _cols, val: _vals };
                    }
                    else {
                        _filterChangeTemp = { col: this.filteredColumnDatagrid, val: event.data[this.filteredColumnDatagrid] };
                    }
                    this.filterOutElement(this.data.id, _filterChangeTemp.val, _filterChangeTemp.col, true)
                   /* this.filterChangeSqlExecute(_filterChangeTemp);*/
                }

                var elemVal_AllHeader = this.data.headers
                for (var j = 0; j < elemVal_AllHeader.length; j++) {
                    if (elemVal_AllHeader[j] && elemVal_AllHeader[j].type && (elemVal_AllHeader[j].type == "number" || elemVal_AllHeader[j].type == "int" || elemVal_AllHeader[j].type == "double")) {
                        if (!event.data[elemVal_AllHeader[j].field] || event.data[elemVal_AllHeader[j].field] == "")
                            event.data[elemVal_AllHeader[j].field] = 0;
                    }
                    else if (elemVal_AllHeader[j] && elemVal_AllHeader[j].type && elemVal_AllHeader[j].type == "text") {
                        if (!event.data[elemVal_AllHeader[j].field] || event.data[elemVal_AllHeader[j].field] == "") {
                            if (elemVal_AllHeader[j].properties && elemVal_AllHeader[j].properties.autoText && elemVal_AllHeader[j].properties.autoText.isAuto) {

                                event.data[elemVal_AllHeader[j].field] = elemVal_AllHeader[j].properties.autoText.autoPreText + event.data["ID"];
                            }
                        }
                    }
                }
                //if (process_type == this.dbQueryTypes.delete) {

                //} 
                this.elementOutOfDatagrid();
            }

            this.allowCopy = true;

            if (this.data.showAddButton) {
                let elements = this.elementHtml.nativeElement.querySelectorAll('.dx-datagrid-addrow-button');
                elements[0].classList.remove("dx-state-disabled");
            }



            let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
            this.outOfGridComponentDatas = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);

            for (let i = 0; i < this.data.headers.length; i++) {
                let exp = this.data.headers[i].expression;

                if (exp && typeof (exp.code) != "undefined" && exp.code != '') {

                    let _userChange = undefined;
                    if (typeof this.data.userChangeColumns != "undefined") {

                        _userChange = this.data.userChangeColumns.find(x => x.header == this.data.headers[i].field && x.ID == event.data.ID);
                    }
                    if (typeof _userChange == "undefined") {

                        let thisDataVals = [];
                        let func = this.componentService.cloneModel(exp.code);
                        let fields = Object.keys(event.data);

                        if (exp.influenceAllRelatedRows) {

                            let _iARRC = exp.influenceAllRelatedRowsColumn;

                            thisDataVals = _iARRC != "" && typeof _iARRC != "undefined" && Array.isArray(_iARRC) ?
                                this.data.value.filter(
                                    function (x) {
                                        let xControl = true;
                                        _iARRC.forEach(x_iARRC => {
                                            xControl = xControl != false ? x[x_iARRC] == event.data[x_iARRC] : false;
                                        });
                                        return xControl;
                                    }
                                ) : this.data.value;


                            if (thisDataVals.length != this.data.value.length) {
                                if (process_type != this.dbQueryTypes.recursiveRelatedRowEvent)
                                    this.dataValRelated = this.data.value;

                                this.dataValRelated = this.dataValRelated.filter(function (x) {
                                    return !(thisDataVals.map(y => y.ID).indexOf(x.ID) > -1)
                                });
                            }
                            else
                                this.dataValRelated = [];

                            var patternFuncOp = /\{[a-zA-Z0-9_,'=ıİöÖçÇğĞ()üÜşŞ%\.]+\}/ig;
                            //var pattFuncOp = new RegExp(patternFuncOp);
                            if ((new RegExp(patternFuncOp)).test(func)) {
                                func.match(patternFuncOp).forEach(pattF => {

                                    let patFTemp = pattF.replace("{", "").replace("}", "");
                                    patFTemp = patFTemp.split(".");
                                    let resCalc;
                                    if (patFTemp.length > 1) {
                                        resCalc = this.componentService.cloneModel(this.formulaCommands.find(x => x.id == "." + patFTemp[1]).operation);
                                        resCalc = this.replaceAll(resCalc, "\\[count\\]", thisDataVals.length);
                                        resCalc = this.replaceAll(resCalc, "\\[sum\\]", thisDataVals.map(item => item[patFTemp[0]]).reduce((prev, next) => prev + next));

                                        resCalc = this.replaceAll(resCalc, "\\[max\\]", thisDataVals.map(item => item[patFTemp[0]]).reduce((prev, next) => (prev < next ? next : prev)));
                                        resCalc = this.replaceAll(resCalc, "\\[min\\]", thisDataVals.map(item => item[patFTemp[0]]).reduce((prev, next) => (prev > next ? next : prev)));
                                        //dg_max  dg_min

                                        resCalc = eval(resCalc);
                                    }
                                    else
                                        resCalc = event.data[patFTemp[0]];

                                    func = this.replaceAll(func, "{" + patFTemp.join('.') + "}", resCalc);
                                });
                            }


                        }
                        else {
                            fields.forEach(f => {
                                let pref = "{" + f + "}";
                                let value = event.data[f];
                                func = this.replaceAll(func, pref, value);
                            });
                        }
                        // });

                        this.outOfGridComponent.forEach(f => {
                            let pref = "{" + f.id + "}";
                            let value = this.outOfGridComponentDatas.find(x => x.id == f.id).value;//f.value;//event.data[f];
                            func = this.replaceAll(func, pref, value)
                        });

                        var pattern = /\{[a-zA-Z0-9_,'=ıİöÖçÇğĞ()üÜşŞ%]+\}/ig;
                        var patt = new RegExp(pattern);

                        if (patt.test(func)) {
                            //   
                            var res_pattern = func.match(pattern);
                            for (var j = 0; j < res_pattern.length; j++) {
                                var elem = res_pattern[j];
                                var elemVal = this.data.headers.find(x => x.field === elem.replace("{", "").replace("}", ""));
                                if (!elemVal) {
                                    elemVal = this.outOfGridComponent.find(x => x.id == elem.replace("{", "").replace("}", ""));
                                }

                                if (elemVal && elemVal.type && (elemVal.type == "number" || elemVal.type == "int" || elemVal.type == "double")) {

                                    func = this.replaceAll(func, elem, "0");
                                    event.data[elem.replace("{", "").replace("}", "")] = 0;
                                }
                                else if (elemVal && elemVal.type && elemVal.type == "text") {
                                    func = this.replaceAll(func, elem, "");
                                    event.data[elem.replace("{", "").replace("}", "")] = "";
                                }

                            }
                        }

                        try {
                            let result = eval(func.replace(/[\u200B-\u200D\uFEFF]/g, ''));
                            if (exp.influenceAllRelatedRows) {
                                thisDataVals.forEach(_dV => {
                                    _dV[this.data.headers[i].field] = result;
                                });
                                this.forceRefreshDatagrid();
                            }
                            else
                                event.data[this.data.headers[i].field] = result;
                        } catch (ex) {
                            console.log("River Result ERROR: " + ex);
                            // console.error("River Result ERROR: " + ex);
                        }

                    }

                }

                if (exp.export === true && typeof (exp.element) != "undefined" && process_type != this.dbQueryTypes.recursiveRelatedRowEvent) {

                    let sum = this.componentService.setColSumToElement(exp.element, this.data.headers[i].field, this.data.value, currentFormSelected.components);
                    this.eventEmitterService.setColumnSumToComponent(exp.element, sum);
                }


            }

            if (this.dataValRelated.length > 0)
                this.executeExp({ data: this.dataValRelated[0] }, this.dbQueryTypes.recursiveRelatedRowEvent);

        } catch (_ex_) {
            console.error(_ex_.message);
            console.error(_ex_.stack);
        }

    }

    getDataGridSelectValueWithDataSource(columnField, dataSource) { //cellInfo.column.dataField
        let header = this.data.headers.find(x => x.field == columnField);
        return header.isDefaultFirstValue && header.isDefaultFirstValue == true && dataSource.length > 0 ? dataSource[0][header.valueKey] : "";
    }

    getDataGridSelectValue(columnField, _val) { //cellInfo.column.dataField
        ///////////////////////////////////////////////////////////////////////////////////////////// 
        let header = this.data.headers.find(x => x.field == columnField);
        //if (header.dataSource.length > 0) {
        _val = typeof _val == "undefined" || _val == null ? "" : _val;
        return header.isDefaultFirstValue && header.isDefaultFirstValue == true && header.dataSource.length > 0 ? header.dataSource[0][header.valueKey] : (header.type == "date" || header.type == "datetime" ? null : _val);
        //}
        //else {
        //    let dataCols = this.data.headers.filter(f => f.type == "combobox" || f.type == "datagrid ");
        //    if (dataCols && dataCols.length > 0) {
        //        this.prepareDataGridColumn(dataCols, 0, false);
        //    }
        //}
        /////////////////////////////////////////////////////////////////////////////////////////////
    }

    elementOutOfDatagrid(allEnable = false) {
        let elementsOutOfDatagrid = this.componentService.elementsOutOfDatagrid;
        if (this.outOfGridComponent.length > 0 && elementsOutOfDatagrid.length > 0) {
            this.outOfGridComponent.forEach(f => {
                if (elementsOutOfDatagrid.filter(x => x == f.id).length > 0) {
                    if ((this.data.value.length > 0 && !allEnable) && this.data.outsideElementShouldDisabled) {
                        f.disabled = true;
                    } else {
                        f.disabled = false;
                    }
                }
            });
        }
    }

    onRowInserting(e) {

        //let selectedDatagridElements = outFilterDatagrid.filter(x => typeof x.selectedDatagrid  != "undefined" && x.selectedDatagrid != null && x.selectedDatagrid != "" &&
        //              ((Array.isArray(x.selectedDatagrid) && typeof x.selectedDatagrid.find(y => y.id == this.data.id) != undefined) ||
        //                  (!Array.isArray(x.selectedDatagrid) && typeof x.selectedDatagrid == this.data.i d))) ;

        //let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        //let outFilterDatagrid = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);
        //if (outFilterDatagrid) {
        //    let selectedDatagridElements = outFilterDatagrid.filter(x => typeof x.selectedDatagrid != "undefined" && x.selectedDatagrid != null && x.selectedDatagrid != "" &&
        //        ((Array.isArray(x.selectedDatagrid) && typeof x.selectedDatagrid.find(y => y.id == this.data.id) != undefined) ||
        //            (!Array.isArray(x.selectedDatagrid) && x.selectedDatagrid == this.data.id)));

        //    //let selectedDatagridElements = outFilterDatagrid.filter(x => typeof x.selectedDatagrid  != "undefined" && x.selectedDatagrid != null && x.selectedDatagrid != "" && x.selectedDatagrid == this.data.id);

        //    selectedDatagridElements.forEach(_sde => {
        //        //let header = this.data.headers.find(f => f.field == field);
        //        // if (header.type == "combobox" || header.type == "datagrid ") 
        //        if (typeof this.filteredColumnDatagrid == "undefined" || (Array.isArray(this.filteredColumnDatagrid) && this.filteredColumnDatagrid.length == 0)) {
        //            if (_sde.type != "datagrid") {
        //                let _filtCol = "filtercolumnsystem_" + _sde.id;
        //                let outFilterDatagridFind = outFilterDatagrid.find(xx => xx.id == _sde.id);
        //                e.data[_filtCol] = outFilterDatagridFind ? outFilterDatagridFind.value : "";
        //            }
        //        }
        //        //!(typeof this.filteredColumnDatagrid == "undefined" || (Array.isArray(this.filteredColumnDatagrid) && this.filteredColumnDatagrid.length == 0))  &&
        //        else if (_sde.type == "datagrid" || _sde.type == "datalist") {
        //            if (_sde.selectedRows > 0) {
        //                let sourceCompCols = _sde.selectedDatagrid.filter(x => x.id == this.data.id);
        //                let sourceValue = _sde.value.find(x => x.ID == _sde.selectedRows[0]);
        //                sourceCompCols.forEach(_scc => {
        //                    e.data[_scc.field] = sourceValue[_scc.sourceField];
        //                    let _dataIndex = this.data.value.findIndex(x => x.ID == e.data.ID);
        //                    if (_dataIndex > -1) {
        //                        this.data.value[_dataIndex][_scc.field] = sourceValue[_scc.sourceField];
        //                    }
        //                });
        //                //field: "datagridID"
        //                //id: "datagrid2"
        //                //sourceField: "ID"

        //                //1. datagridde seçilen değer


        //            }


        //        }
        //    });

        //}









        //e.data.ID = e.data.ID;// this.data.value.length === 0 ? 1 : this.data.value[this.data.value.length - 1].ID + 1;

        //let elements = this.elementHtml.nativeElement.querySelectorAll('.dx-datagrid-addrow-button');
        //elements[0].classList.remove("dx-state-disabled");
        //e.component.refresh(true);

    }

    rowRemoving(event) {

        if (this.data.value.length == 1) {
            event.cancel = true;
            //this.data.value = [{ ID: 1 }];
            //this.forceRefreshDatagrid();

            var _Id = 0
            let zeroTemp = this.data.value.find(x => x.ID == _Id);
            if (typeof zeroTemp == "undefined") {
                var clonedItem = Object.assign({}, {}, { ID: _Id, DefaultColumn: 1 });
                this.data.value.splice(this.data.value.length, 0, clonedItem);
                this.data.value.sort((a, b) => a.ID - b.ID);
            }
            this.data.value = this.data.value.filter(x => x.ID == 0 && x.DefaultColumn == 1/*values.indexOf(item) === -1*/);
            this.getDatasourcePageItem();
            this.data.value[0].ID = 1;
            this.forceRefreshDatagrid();

            this.elementOutOfDatagrid(true);
        }

        this.selectedDatagridChangeFilter(this.data.value[0]);




        //if (this.data.selectedDatagrid && Array.isArray(this.data.selectedDatagrid) && this.data.selectedDatagrid.length > 0) {
        //    let _thisPage = this;
        //    this.data.selectedDatagrid.forEach(function (_item) {
        //        if (_item.id && _item.id != "") {
        //            let itemField = _item.field == null || _item.field == "" ? undefined : _item.field ;
        //            let dataVal = _item.sourceField == null || _item.sourceField == "" || typeof _item.sourceField == "undefined" ? _thisPage.data.selectedRows[0] :
        //                _thisPage.data.value[0][_item.sourceField];
        //            //this.data.selectedDatagridFilterField = this.data.selectedDatagridFilterField == null ? undefined : this.data.selectedDatagridFilterField;
        //            let _data = { element: _thisPage.data.id, value: dataVal, elementLabel: _thisPage.defaultLanguage.Label, filteredElement: _item.id, filteredColumn: itemField, isDelete: true };
        //            _thisPage.eventEmitterService.ChangeFilterOutElemen t (_data);
        //        }
        //    });
        //}

        let _thisPage2 = this;
        setTimeout(() => {
            _thisPage2.executeExp({ data: event.data }, _thisPage2.dbQueryTypes.recursiveRelatedRowEvent);

            // Satır silindiğinde toplamın tekrar hesaplanması için
            let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
            for (let i = 0; i < this.data.headers.length; i++) {
                let exp = this.data.headers[i].expression;
                if (exp.export === true && typeof (exp.element) != "undefined") {
                    let sum = this.componentService.setColSumToElement(exp.element, this.data.headers[i].field, this.data.value, currentFormSelected.components);
                    this.eventEmitterService.setColumnSumToComponent(exp.element, sum);
                }
            }

        }, 1);





    }

    onEditingStart(e) {

        //this.datagridColsSql();

        var added = typeof e.key == "undefined" ? true : false;
        if (added) {
            this.allowCopy = false;
            let elements = this.elementHtml.nativeElement.querySelectorAll('.dx-datagrid-addrow-button');
            elements[0].classList.add("dx-state-disabled");

            let datagridInstance = this.dataGridEtr.instance;
            datagridInstance.saveEditData();

            var _rowIndex = this.data.value.length - 1;
            this.newRowShortcut.isActive = true;
            this.newRowShortcut.index = _rowIndex;

            //datagridInstance.clearSelection();
            //datagridInstance.option("focusedRowInde x", -1); 


        }
        else {

            //let selRow = this.data.selectedRows.find(x => x == e.key);
            //if (typeof selRow == "undefined") {
            //    this.data.selectedRows = [];
            //    
            //    this.data.selectedRows.push(e.key);
            //}
        }


        //else
        //    this.isGridSelection = true;
    }

    onCheckboxFieldValueChanged(event, cell) {
        if (event.value) {

            cell.setValue(event.value);
            this.data.selectedRows = [];
            //this.data.selectedRows.pus h(e.key);
        }
    }

    selectedDatagridChangeFilter(rowData) {
        if (this.data.selectedDatagrid && Array.isArray(this.data.selectedDatagrid) && this.data.selectedDatagrid.length > 0) {

            var dataValItems = [];
            this.data.selectedDatagrid.forEach(_item => {
                if (typeof rowData != "undefined") {
                    let itemField = _item.field == null || _item.field == "" ? undefined : _item.field;
                    let dataVal = _item.sourceField == null || _item.sourceField == "" || typeof _item.sourceField == "undefined" ? rowData.ID :
                        rowData[_item.sourceField];
                    var findDataValItems = dataValItems.find(x => x.filteredElement == _item.id);
                    if (findDataValItems) {
                        findDataValItems.value.push(dataVal);
                        findDataValItems.filteredColumn.push(itemField);
                    }
                    else
                        dataValItems.push({ filteredElement: _item.id, value: [dataVal], filteredColumn: [itemField] });
                }
            });

            dataValItems.forEach(_item => {
                let _data = { element: this.data.id, value: _item.value, elementLabel: this.defaultLanguage.Label, filteredElement: _item.filteredElement, filteredColumn: _item.filteredColumn, isDelete: false, isSqlExecute: true };
                this.eventEmitterService.ChangeFilterOutElement(_data);
            });



            //let _data = { element: this.data.id, value: dataVal, elementLabel: this.defaultLanguage.Label, filteredElement: _item.id, filteredColumn: itemField, isDelete: false, isSqlExecute: true };
            //this.eventEmitterService.ChangeFilterOutElemen t (_data);


            //this.data.selectedDatagrid.forEach(_item => {
            //    if (_item.id && _item.id != "") {
            //        let itemField = _item.field == null || _item.field == "" ? undefined : _item.field;
            //        let dataVal = _item.sourceField == null || _item.sourceField == "" || typeof _item.sourceField == "undefined" ? rowData.ID :
            //            rowData[_item.sourceField];
            //        //this.data.selectedDatagridFilterField = this.data.selectedDatagridFilterField == null ? undefined : this.data.selectedDatagridFilterField;
            //        let _data = { element: this.data.id, value: dataVal **, elementLabel: this.defaultLanguage.Label, filteredElement: _item.id, filteredColumn: itemField **, isDelete: false, isSqlExecute: true };
            //        this.eventEmitterService.ChangeFilterOutElemen t (_data);
            //    }
            //});
        }
    }


    // #endregion  DATAGRID CHANGE EVENTS ( ADD ROW - UPDATE ROW - DELETE ROW - ONCHANGE CELL )




    // #region DESIGNER
    /////////
    /// DESIGNER
    ////////

    propertiesUndefinedControl(_headerTemp) {
        if (typeof _headerTemp.properties == "undefined") {
            _headerTemp.properties = { "barcode": false, "required": false, "isUserUpload": false, "autoText": { isAuto: false, autoPreText: "" }, "roundType": "round", "dateMinMax": { minToday: false, maxToday: false, dateRange: null } };
        }

        if (typeof _headerTemp.properties.autoText == "undefined") {
            _headerTemp.properties.autoText = { isAuto: false, autoPreText: "" };
        }

        if (typeof _headerTemp.properties.dateMinMax == "undefined") {
            _headerTemp.properties.dateMinMax = { minToday: false, maxToday: false, dateRange: null };
        }

        if (typeof _headerTemp.properties.roundType == "undefined") {
            _headerTemp.properties.roundType = "round";
        }

        if (typeof _headerTemp.properties.backgroundColor == "undefined") {
            _headerTemp.properties.backgroundColor = "";
        }

        if (typeof _headerTemp.properties.color == "undefined") {
            _headerTemp.properties.color = "";
        }

        if (typeof _headerTemp.properties.fontWeight == "undefined") {
            _headerTemp.properties.fontWeight = "";
        }





    }

    addEmptyHeader() {
        this.headers.name = this.componentService.createuuid();
        let header = this.componentService.cloneModel(this.headers);
        this.data.headers.push(header);
        //this.resetHeaderObject();
    }
    addHeader() {
        localStorage.setItem("dataGridHeader", JSON.stringify(this.headers));
        let header: any = JSON.parse(localStorage.getItem("dataGridHeader"));
        localStorage.removeItem("dataGridHeader");
        this.data.headers.push(header);
        this.showToast("Eklendi", "success");
        //this.resetHeaderObject();
    }
    deleteAllHeaders() {
        let result = confirm("<i>" + this.languageLocal.areYouSure + "</i>", this.languageLocal.allColumnDeleted);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.data.headers = [];
                this.dataGridEtr.instance.option('columns', []);
            }
        });
    }

    deleteHeader(index) {
        let hasSqlScriptArr = this.data.sqlExpressions.filter(el => this.data.headers[index].field == el.field)
        if (hasSqlScriptArr.length > 0) {
            this.showToast("Sql Scripti mevcut lütfen önce script'i siliniz", "warning");
            return;
        }
        let result = confirm("<i>" + this.languageLocal.areYouSure + "</i>", this.languageLocal.columnDeleted);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.data.headers.splice(index, 1);
            }
        });
    }

    addColPermission(header) {
        this.selectedColPermission = header.permissions;
        this.perCol = header.field;
        this.colPermissionPopup = true;
    }

    addColLanguage(header) {
        this.selectedColLanguage = header.language;
        this.colLanguagePopup = true;
    }

    selectedHeaderType = "";
    dataGridSettings(index) {
        this.connections = this.appInfo.RiverEntity.connections;

        this.selectedHeaderType = this.data.headers[index].type;

        if (this.data.headers[index].type == 'fileviewer') {
            this.selectedConnection = this.data.headers[index].sqlColumnSettings.connectionStringId || 0;
            this.data.headers[index].sqlColumnSettings.connectionStringId = this.selectedConnection;
        }
        else {
            this.selectedConnection = this.data.headers[index].dataGridSettings.connectionStringId || 0;
            this.data.headers[index].dataGridSettings.connectionStringId = this.selectedConnection;
        }

        this.selectedHeaderIndex = index;
        this.dataGridSettingsPopop = true;
    }

    mapSettingsPopop = false;
    mapSetting(index) {
        this.selectedHeaderIndex = index;
        //      userChangeDraggable     
        this.data.headers[this.selectedHeaderIndex].draggable = typeof this.data.headers[this.selectedHeaderIndex].draggable != "undefined" ? this.data.headers[this.selectedHeaderIndex].draggable : false;
        this.data.headers[this.selectedHeaderIndex].isAddMultipleMarker = typeof this.data.headers[this.selectedHeaderIndex].isAddMultipleMarker != "undefined" ? this.data.headers[this.selectedHeaderIndex].isAddMultipleMarker : false;
        this.data.headers[this.selectedHeaderIndex].userChangeDraggable = typeof this.data.headers[this.selectedHeaderIndex].userChangeDraggable != "undefined" ? this.data.headers[this.selectedHeaderIndex].userChangeDraggable : true;
        this.data.headers[this.selectedHeaderIndex].maxMarkerLimit = typeof this.data.headers[this.selectedHeaderIndex].maxMarkerLimit != "undefined" ? this.data.headers[this.selectedHeaderIndex].maxMarkerLimit : 0;
        this.mapSettingsPopop = true;
    }
    openFormatPopup(i) {

        this.selectedFormatIndex = i;
        let _header = this.data.headers[i];

        this.propertiesUndefinedControl(_header);

        this.formatHeaderType = _header.type;
        if (_header.type == "date" || _header.type == "datetime") {

            let defaultFormat = _header.type == "date" ? "dd/MM/yyyy" : "dd/MM/yyyy HH:mm";
            let _format = _header.format && _header.format != "" && _header.format != "#,##0.00" ? _header.format : defaultFormat;
            this.data.headers[i].format = _format;
        }
        else {
            if (typeof this.data.headers[i].format == "undefined" || this.data.headers[i].format == "" || this.data.headers[i].format.indexOf("##") == -1)
                this.data.headers[i].format = "#,##0.00";
        }
        this.showFormatPopup = true;
    }
    openFuncPopup(i) {

        this.isMonacoLoaded = false;
        this.selectedFuncIndex = i;
        let exp = this.data.headers[i].expression;
        this.headerExpression.code = exp.code;
        this.headerExpression.export = exp.export;
        this.headerExpression.element = exp.element;

        this.headerExpression.influenceAllRelatedRows = typeof exp.influenceAllRelatedRows == "undefined" ? false : exp.influenceAllRelatedRows;
        this.headerExpression.influenceAllRelatedRowsColumn = typeof exp.influenceAllRelatedRowsColumn == "undefined" || exp.influenceAllRelatedRowsColumn == "" ? [] : exp.influenceAllRelatedRowsColumn;

        this.showFuncPopup = true;
        setTimeout(() => { this.isMonacoLoaded = true; }, 1000);
    }

    saveFuncParam() {
        let exp: any = {};
        exp.code = this.headerExpression.code;
        exp.export = this.headerExpression.export;
        exp.element = this.headerExpression.element;
        exp.influenceAllRelatedRows = this.headerExpression.influenceAllRelatedRows;
        exp.influenceAllRelatedRowsColumn = this.headerExpression.influenceAllRelatedRowsColumn;
        this.data.headers[this.selectedFuncIndex].expression = exp;
        this.selectedFuncIndex = -1;
        this.headerExpression = new HeaderExpression();
        this.showFuncPopup = false;
    }

    monacoOnInit(e, isOutOfGridComponent, monacolanguage) {
        this.isMonacoLoaded = true;
        let monaco = window['monaco'];
        this.setCustomKeywords(monaco, isOutOfGridComponent, monacolanguage);
    }

    setCustomKeywords(monaco, isOutOfGridComponent, monacolanguage) {

        if (this.registeredLanguage)
            this.registeredLanguage.dispose();

        this.registeredLanguage = monaco.languages.registerCompletionItemProvider(monacolanguage, {
            provideCompletionItems: () => {
                var suggestions = [];

                for (let i = 0; i < this.data.headers.length; i++) {
                    suggestions.push({
                        label: this.data.headers[i].field,
                        kind: monaco.languages.CompletionItemKind.Snippet,
                        insertText: "{" + this.data.headers[i].field + "}",
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        documentation: 'method'
                    })
                }

                if (isOutOfGridComponent) {
                    for (let i = 0; i < this.outOfGridComponent.length; i++) {
                        suggestions.push({
                            label: this.outOfGridComponent[i].id,
                            kind: monaco.languages.CompletionItemKind.Snippet,
                            insertText: "{" + this.outOfGridComponent[i].id + "}",
                            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                            documentation: 'method'
                        })
                    }
                }

                if (this.headerExpression.influenceAllRelatedRows) {
                    for (let i = 0; i < this.formulaCommands.length; i++) {
                        suggestions.push({
                            label: this.formulaCommands[i].id,
                            kind: monaco.languages.CompletionItemKind.Property,
                            insertText: "" + this.formulaCommands[i].id.replace(".", "") + "",
                            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                            documentation: 'property'
                        })
                    }
                }
                return { suggestions: suggestions };
            },
            triggerCharacters: ["."]
        });
    }

    connectionListValueChanged(event) {
        this.selectedConnection = event.value;
        //let connectionStr = this.createConnectionString();
        if (event.value > 0) {
            if (this.selectedHeaderType == 'fileviewer')
                this.data.headers[this.selectedHeaderIndex].sqlColumnSettings.connectionStringId = event.value;
            else
                this.data.headers[this.selectedHeaderIndex].dataGridSettings.connectionStringId = event.value;

            this.genericService.getTables(event.value).subscribe((result: any) => {
                this.tableList = result;
            });
        }
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {

        let _control = true;
        const pattern = /[A-Za-z_]/;
        if (!pattern.test(this.data.id[0]))
            _control = false;
        if (_control) {
            this.showSettingPopup = false;

            let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
            if (isAvalible) {
                // this.applyHeadersToDataGrid();
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
            }
            else {
                this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
            }

            this.headers.dataGridSettings.key = "";
            this.headers.dataGridSettings.formFields = [];

            this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
            this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;
            this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        }
        else
            this.showToast(this.languageLocal.idCantStartNumber, "warning");

    }
    cancelSetting() {

        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
                this.CreateDatagridColumns();
            }
        });

    }

    colShorting(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.data.headers, event.previousIndex, event.currentIndex);
    }

    colShortingChangeEvent(event: CdkDragDrop<string[]>) {

        moveItemInArray(this.data.sqlExpressions, event.previousIndex, event.currentIndex);
    }

    showMonacoEditorColumnSqlScript = false;
    openSqlExpressioPopup() {
        this.connections = this.appInfo.RiverEntity.connections;
        this.showSqlExpressioPopup = true;
        setTimeout(() => { this.showMonacoEditorColumnSqlScript = true; }, 1000);
    }

    addSqlExpression() {

        let evt = this.data.sqlExpressions.find(f => f.field == this.sqlExpression.field);
        if (typeof evt == "undefined" && this.sqlExpression.field != "") {
            this.data.sqlExpressions.push(this.componentService.cloneModel(this.sqlExpression));
        }
    }

    deleteSqlExpressions(i) {
        this.data.sqlExpressions.splice(i, 1);
    }

    closeSqlExpressioPopup() {
        this.showSqlExpressioPopup = false;
        this.showMonacoEditorColumnSqlScript = false;
        this.componentService.elementsOutOfDatagrid = [];

        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        let componentList = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId);

        if (typeof this.data.sqlExpressions != "undefined" && this.data.sqlExpressions != null && typeof this.data.sqlExpressions.length != "undefined") {
            for (let i = 0; i < this.data.sqlExpressions.length; i++) {

                let sqlExpressionTag = this.data.sqlExpressions[i].query.match(/\{(\w)+\}/ig);;
                if (typeof sqlExpressionTag != "undefined" && sqlExpressionTag != null) {
                    for (let j = 0; j < sqlExpressionTag.length; j++) {
                        if (sqlExpressionTag != null && typeof sqlExpressionTag != "undefined" && sqlExpressionTag.length > 0) {
                            sqlExpressionTag[j] = sqlExpressionTag[j].replace("{", "").replace("}", "");
                            let isActive;
                            if (sqlExpressionTag[j].indexOf(".") != -1) {
                                isActive = this.outOfGridComponentDatas.filter(x => x.id == sqlExpressionTag[j].split(".")[0]);
                            }
                            else
                                isActive = componentList.filter(x => x.id == sqlExpressionTag[j]);

                            //let isActive = componentList.filter(x => x.id == sqlExpressionTag[j]);
                            if (isActive.length > 0)
                                this.componentService.elementsOutOfDatagrid.push(sqlExpressionTag[j]);
                        }

                    }
                }
            }

        }
    }

    exportElements = [];
    exportChange(val) {

        if (val) {
            this.exportElements = this.componentService.getComponentsByComponentName("DxFormResultComponent", this.anotherPopupId).map(m => m.id);
        }
        else {
            this.funcParamData.element = "";
            this.funcParamData.export = false;
            this.exportElements = [];
        }
    }
    influenceAllRelatedRowsElements = [];
    influenceAllRelatedRowsChange(val) {

        if (val) {
            this.influenceAllRelatedRowsElements = this.data.headers.map(m => m.field);
        }
        else {
            this.funcParamData.influenceAllRelatedRowsColumn = [];
            this.funcParamData.influenceAllRelatedRows = false;
            this.influenceAllRelatedRowsElements = [];
        }
    }

    // #endregion DESIGNER


    excelExport() {
        this.excelTemplateDownloadHtml = "";
        let _headerHtml = "";
        this.data.headers.forEach(f => {
            _headerHtml = _headerHtml + "<td>" + f.caption + "</td>";
        });
        _headerHtml = "<tr>" + "<td>ID</td>" + _headerHtml + "</tr>";

        let _rowHtml = "";
        if (this.data.value.length > 0) {
            this.data.value.forEach(fVal => {
                let _fVal = this.componentService.cloneModel(fVal);
                let _cellsHtml = "";
                this.data.headers.forEach(f => {
                    let _val = _fVal[f.field];
                    if (typeof _val == "undefined" || _val == null) {
                        if (f.type == "int" || f.type == "double")
                            _val = "0";
                        else
                            _val = "";
                    }
                    else if (f.type == "date" || f.type == "datetime") {
                        if (_val != null && typeof _val != "undefined" && _val != "") {
                            let _timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                            if (typeof _val.getMonth !== 'function')
                                _val = new Date(_val);
                            if (typeof _val.getMonth === 'function') {
                                _val = _val.toLocaleString('tr-TR', { timeZone: _timeZone })
                                if (f.type == "date")
                                    _val = _val.split(" ")[0];
                            }
                            else
                                _val = _fVal[f.field];
                        }
                    }
                    else if (f.type == "combobox" || f.type == "datagrid") {
                        let _valArray = this.selectionGridDataSource.find(x => x.id == fVal.ID && x.field == f.field);
                        _valArray = typeof _valArray == "undefined" ? f.dataSource : _valArray.data;

                        if (_valArray) {
                            let _valData = _valArray.find(x => x[f.valueKey] == _val);
                            if (_valData) {
                                if (f.type == "combobox")
                                    _val = _valData.displayText;
                                else
                                    _val = _valData[f.displayKey];
                            }

                        }
                    }
                    _cellsHtml = _cellsHtml + "<td>" + _val + "</td>"
                    //let pref = "{" + f + "}";
                    //let value = fVal[f];
                    //func = this.replaceAll(func, pref, value);
                });

                _rowHtml = _rowHtml + "<tr>" + "<td>" + _fVal.ID + "</td>" + _cellsHtml + "</tr>";

            });
        }
        this.excelTemplateDownloadHtml = _headerHtml + _rowHtml;

        //_rowHtml = "<tr>" + _rowHtml + "</tr>";

        //this.excelTemplateDownloadHtml = _headerHtml + _rowHtml;

        setTimeout(() => {
            let XLSX = window["XLSX"];//data-table-template-{{data.id}}, {raw:true}
            var elt = document.getElementById('data-table-template-' + this.data.id);
            elt.innerHTML = _headerHtml + _rowHtml
            var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS", raw: true });
            return XLSX.writeFile(wb, ((this.defaultLanguage ? this.defaultLanguage.Label : "Datagrid") + '.' + 'xlsx'));
        }, 1000);


    }




    // #region EXCEL IMPORT
    /////////
    /// EXCEL IMPORT
    ////////
    excelImportInputButtonDisabled = true;
    excelFileGlobal = null;
    json_ArrayExcel = [];
    excelImportCloseButton = true;
    excelImportPopupHeight = 510;
    _nExcelImportLoading = 0;
    _excelRowIndex = 0;
    _tickProcess = 0;
    excelTemplateDownloadHtml = "";
    excelImportText = "<div class=\"log-html-info\">Dosya Seçiniz</div>";
    excelImportTextError = "<div class=\"log-html-error\"><b>UYARI:</b> Yüklemek istediğiniz satır sayısının fazla olması durumunda, internet tarayıcınızda <b>(BEKLE)</b> uyarısı gelebilir. Bu durumda ekranı kapatmayınız ve lütfen bekleyiniz.</div>";
    excelImportPerformance = "";
    logExcelText = "";
    excelVisiblePopup = false;
    excelImport() {
        this.excelImportInputButtonDisabled = false;
        this._tickProcess = 0;
        this.excelImportText = "<div class=\"log-html-info\">Dosya Seçiniz</div>";
        this.excelImportTextError = "<div class=\"log-html-error\"><b>UYARI:</b> Yüklemek istediğiniz satır sayısının fazla olması durumunda, internet tarayıcınızda <b>(BEKLE)</b> uyarısı gelebilir. Bu durumda ekranı kapatmayınız ve lütfen bekleyiniz.</div>";
        this.excelImportPerformance = "";
        this.logExcelText = "";
        this.excelVisiblePopup = true;
        this.uploaderExcel.nativeElement.value = '';
        this._excelRowIndex = 0;
        this.excelImportCloseButton = true;
        this.excelImportPopupHeight = 510;
        this.json_ArrayExcel = [];
        this._nExcelImportLoading = 0;
        this.setLogExcelText("Excel Seçiliyor.");
        this.excelFileGlobal = null;
        (<HTMLInputElement>document.querySelector('.dx-trackbar-range.dx-progressbar-range')).style.minWidth = '0px';

        this.uploaderExcel.nativeElement.click();
    }
    excelTemplateDownload() {
        this.excelTemplateDownloadHtml = "";
        let _headerHtml = "";
        let _rowHtml = "";
        this.data.headers.forEach(f => {
            _headerHtml = _headerHtml + "<td>" + f.field + "</td>";
            if (f.type == "double") {
                _rowHtml = _rowHtml + "<td>0</td>";
            }
            else if (f.type == "int") {
                _rowHtml = _rowHtml + "<td>0</td>";
            }
            else if (f.type == "checkbox") {
                _rowHtml = _rowHtml + "<td>false</td>";
            }
            else if (f.type == "map") {
                _rowHtml = _rowHtml + "<td></td>";
            }
            else if (f.type == "combobox") {
                _rowHtml = _rowHtml + "<td></td>";
            }
            else if (f.type == "customableCombobox") {
                _rowHtml = _rowHtml + "<td></td>";
            }
            else {
                _rowHtml = _rowHtml + "<td></td>";
            }
        });
        _headerHtml = "<tr>" + _headerHtml + "</tr>";
        _rowHtml = "<tr>" + _rowHtml + "</tr>";

        this.excelTemplateDownloadHtml = _headerHtml + _rowHtml;

        setTimeout(() => {
            let XLSX = window["XLSX"];//data-table-template-{{data.id}}
            var elt = document.getElementById('data-table-template-' + this.data.id);
            elt.innerHTML = _headerHtml + _rowHtml;
            var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
            return XLSX.writeFile(wb, ('Template.' + ('xlsx')));
        }, 1000);

    }
    excelImportFile(e) {

        this.excelImportInputButtonDisabled = true;
        this.excelImportCloseButton = false;
        var parts = [];
        var file = e.target.files[0];
        this.excelFileGlobal = file;
        var fileSize = file.size;
        var sliceSize = parseInt((fileSize / 15).toString());
        var count = Math.ceil(fileSize / sliceSize);
        var index = 0;

        this.excelImportText = "<div class=\"log-html-warning\">" + file.name + " isimli excel içeri aktarılıyor.</div>";

        this.setLogExcelText("Excel Yükleniyor.");

        this.excelLoadingChange(this, 1);
        this.chunkFile(index, sliceSize, file, parts, count);

    }

    //    var start = window.performance.now();
    //var end = window.performance.now();
    //var time = end - start;
    millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = parseInt(((millis % 60000) / 1000).toFixed(0));
        seconds = seconds == 60 ? 59 : seconds;
        return (minutes == 0 ? "" : (minutes + " dakika ")) + (seconds < 10 ? '0' : '') + seconds + " saniye";
    }
    excelPerformanceStart = 0;
    excelPerformanceEnd = 0;
    excelPerformanceTotal = 0;
    //excelImportPopupHeight = 510; //excelImportPerformance;45                   excelImportTextError;64
    excelRowImport(tThis, deleteFirstColumn) {
        if (tThis.json_ArrayExcel.length > 0) {

            if (tThis._excelRowIndex == 0) {
                tThis.excelPerformanceStart = window.performance.now();
            }

            if (tThis._excelRowIndex == 100) {
                tThis.excelPerformanceEnd = window.performance.now();
                tThis.excelPerformanceTotal = tThis.excelPerformanceEnd - tThis.excelPerformanceStart;
            }

            if (tThis._excelRowIndex > 100) {
                tThis.excelImportPopupHeight = tThis.excelImportPopupHeight == 510 ? 555 : tThis.excelImportPopupHeight;
                let msTemp = tThis.excelPerformanceTotal * (tThis.json_ArrayExcel.length - tThis._excelRowIndex) / 100;
                tThis.excelImportPerformance = "<div class=\"log-html-secondary\">Tahmini Süre: " + tThis.millisToMinutesAndSeconds(msTemp) + "</div>";
            }

            let _obj = {};



            if (deleteFirstColumn && tThis.data.value.length == 1) {
                let firstIdIndex = this.data.value.findIndex(x => x.ID == 1);
                if (firstIdIndex != -1) {
                    var _dataValueFirst = this.data.value[firstIdIndex];
                    if (_dataValueFirst && _dataValueFirst.DefaultColumn == 1)
                        this.data.value[firstIdIndex].ID = 0;
                }
            }

            var _ID = tThis.data.value.length == 0 ? 1 : (tThis.data.value[tThis.data.value.length - 1].ID) + 1;

            //let _rowIndex = tThis.data.value.length;    
            tThis.data.headers.forEach(f => {
                if (f.type == "double") {
                    _obj[f.field] = parseFloat(tThis.json_ArrayExcel[tThis._excelRowIndex][f.field]);
                }
                else if (f.type == "int") {
                    _obj[f.field] = parseInt(tThis.json_ArrayExcel[tThis._excelRowIndex][f.field]);
                }
                else if (f.type == "checkbox") {
                    _obj[f.field] = tThis.json_ArrayExcel[tThis._excelRowIndex][f.field] == true || tThis.json_ArrayExcel[tThis._excelRowIndex][f.field] == "true" ? true : false;
                }
                else if (f.type == "map") {
                    _obj[f.field] = JSON.parse("{" + tThis.json_ArrayExcel[tThis._excelRowIndex][f.field] + "}");
                }
                else if (f.type == "combobox") {
                    var _dataSource = tThis.selectionGridDataSource.find(k => k.id == _ID && k.field == f.field);
                    var _dataSourceLast = typeof _dataSource == "undefined" ? f.dataSource : _dataSource.data;

                    let fieldsValue = _dataSourceLast.find(x => x.displayText == tThis.json_ArrayExcel[tThis._excelRowIndex][f.field]);
                    let _val = tThis.json_ArrayExcel[tThis._excelRowIndex][f.field];
                    if (fieldsValue) {
                        _val = fieldsValue[f.valueKey];
                    }

                    _obj[f.field] = _val;
                }
                else if (f.type == "customableCombobox") {
                    var _dataSource = tThis.selectionGridDataSource.find(k => k.id == _ID && k.field == f.field);
                    var _dataSourceLast = typeof _dataSource == "undefined" ? f.dataSource : _dataSource.data;

                    let fieldsValue = _dataSourceLast.find(x => x.displayText == tThis.json_ArrayExcel[tThis._excelRowIndex][f.field]);
                    let _val = tThis.json_ArrayExcel[tThis._excelRowIndex][f.field];
                    if (fieldsValue) {
                        _val = fieldsValue[f.valueKey];
                    }

                    _obj[f.field] = _val;
                }
                else if (f.type == "date") {    //header.type == "date" || header.type == "datetime"  2017-03-16T17:46:53.677           

                    let _v = tThis.json_ArrayExcel[tThis._excelRowIndex][f.field]; //  / . - dd/MM/yyyy HH:mm
                    if (_v.indexOf("T") > -1) {
                        let _dateTemp = new Date(Date.parse(_v));
                        _dateTemp.setHours(_dateTemp.getHours() + 1);

                        let _day = _dateTemp.getDate() < 10 ? "0" + _dateTemp.getDate() : _dateTemp.getDate();
                        let _mon = (_dateTemp.getMonth() + 1) < 10 ? "0" + (_dateTemp.getMonth() + 1) : (_dateTemp.getMonth() + 1);
                        let _yer = _dateTemp.getFullYear();

                        _obj[f.field] = _yer + "-" + _mon + "-" + _day + "T00:00:00Z"
                    }
                    else {
                        let _format = f.format;

                        let splitSeperant = "/";
                        if (_format.length > 3) {
                            splitSeperant = _format[2];
                            let _date = _v.split(splitSeperant);
                            let _dateFormat = _format.split(splitSeperant);
                            // let _day = "", _mon = "", _yer = ""; 
                            //   let _day = _dateFormat[0] == "dd" ? _date[0] : (_dateFormat[1] == "dd" ? _date[1] : (_dateFormat[2].indexOf(' ')>-1?(): _dateFormat[2] == "dd")); 

                            let _day = tThis.getDateParse("dd", _dateFormat, _date, 0);
                            let _mon = tThis.getDateParse("MM", _dateFormat, _date, 0);
                            let _yer = tThis.getDateParse("yyyy", _dateFormat, _date, 0);

                            //let _hor = "", _min = "";
                            //if (_date[2].indexOf(' ') > -1) {
                            //    let sepDot = _date[2].split(' ')[0].split(':');
                            //    if (sepDot.length > 1) {
                            //        _hor = sepDot[0];
                            //        _min = sepDot[1];
                            //    }
                            //} 


                            _obj[f.field] = _yer + "-" + _mon + "-" + _day + "T00:00:00Z" //2022-02-16T12:02:00Z;//tThis.json_ArrayExcel[tThis._excelRowIndex][f.field];
                        }
                    }
                }
                //else if (f.type == "datetime") {  
                //    
                //    let _v = tThis.json_ArrayExcel[tThis._excelRowIndex][f.field]; //  / . - dd/MM/yyyy HH:mm
                //    let _format = f.format;

                //    let splitSeperant = "/";
                //    if (_format.length > 3) {
                //        splitSeperant = _format[2];
                //        let _date = _v.split(splitSeperant);
                //        let _dateFormat = _format.split(splitSeperant);

                //        
                //        let _day = tThis.getDateParse("dd", _dateFormat, _date, 0);
                //        let _mon = tThis.getDateParse("MM", _dateFormat, _date, 0);
                //        let _yer = tThis.getDateParse("yyyy", _dateFormat, _date, 0);

                //        let _hor = "", _min = "";
                //        if (_date[2].indexOf(' ') > -1) {
                //            let sepDot = _date[2].split(' ')[0].split(':');
                //            if (sepDot.length > 1) {
                //                _hor = sepDot[0];
                //                _min = sepDot[1];
                //            }
                //        } 
                //        console.log(_day + " - " + _mon + " - " + _yer /*+ " - " + _hor + " - " + _min*/);
                //        

                //        _obj[f.field] = _yer + "-" + _mon + "-" + _day + "T00:00:00Z" //2022-02-16T12:02:00Z;//tThis.json_ArrayExcel[tThis._excelRowIndex][f.field];
                //    }
                //}
                else {
                    _obj[f.field] = tThis.json_ArrayExcel[tThis._excelRowIndex][f.field];
                }

            });

            tThis.addNewRowOrClone(/*_rowIndex,*/ _obj, null);

            tThis.updateLogExcelText(/<span file-total>(\d+) satır aktarıldı. \(Toplam (\d+) Satır\)<\/span>/g, "<span file-total>" + (tThis._excelRowIndex + 1) + " satır aktarıldı. (Toplam " + (tThis.json_ArrayExcel.length) + " Satır)</span>");

            tThis.excelLoadingChange(tThis, tThis._nExcelImportLoading);


            if (tThis._excelRowIndex == 0) {
                deleteFirstColumn = false;
                let zeroIdIndex = this.data.value.findIndex(x => x.ID == 0);
                if (zeroIdIndex != -1)
                    this.data.value.splice(zeroIdIndex, 1);
            }

            tThis._excelRowIndex = tThis._excelRowIndex + 1;
            if (tThis._excelRowIndex < tThis.json_ArrayExcel.length) {
                setTimeout(() => { tThis.excelRowImport(tThis, false); }, 1);
            }
            else {
                setTimeout(() => {
                    tThis._tickProcess = 100;
                }, 1);
                tThis.excelImportPerformance = "";
                tThis.excelImportTextError = "";
                tThis.excelImportInputButtonDisabled = false;
                tThis.excelImportCloseButton = true;
                tThis.excelImportPopupHeight = 446;// this.excelImportPopupHeight == 510 ? 446 : ;//excelImportPerformance;45                   excelImportTextError;64
                tThis.excelImportText = "<div class=\"log-html-success\">" + tThis.excelFileGlobal.name + " isimli excel aktarımı tamamlandı.</div>";
                tThis.setLogExcelText("Aktarım Tamamlandı.");
            }
        }
        else {
            setTimeout(() => {
                tThis._tickProcess = 100;
            }, 1);
            tThis.excelImportPerformance = "";
            tThis.excelImportTextError = "";
            tThis.excelImportInputButtonDisabled = false;
            tThis.excelImportCloseButton = true;
            tThis.excelImportPopupHeight = 446;// this.excelImportPopupHeight == 510 ? 446 : ;//excelImportPerformance;45                   excelImportTextError;64
            tThis.excelImportText = "<div class=\"log-html-success\">" + tThis.excelFileGlobal.name + " isimli excel aktarımı tamamlandı.</div>";
            tThis.setLogExcelText("Aktarım Tamamlandı.");
        }
    }

    getDateParse(_type, _formatArr, _dateArr, _index) {
        //let _day = _dateFormat[0] == "dd" ? _date[0] : (_dateFormat[1] == "dd" ? _date[1] : (_dateFormat[2].indexOf(' ') > -1 ? (): _dateFormat[2] == "dd"));
        let _return = "";
        if (_formatArr[_index] == _type || (_index == 2 && _formatArr[_index].split(" ")[0] == _type))
            _return = _dateArr[_index];
        else {
            _index++;
            _return = this.getDateParse(_type, _formatArr, _dateArr, _index);
        }
        return _return;
    }

    workbookG;
    chunkFileDone_Worker_1(parts, tThis) {
        tThis.excelLoadingChange(tThis, 1);
        let XLSX = window["XLSX"];

        var tmpResult = new Uint8Array();
        for (let j = 0; j < parts.length; j++) {
            tmpResult = tThis.appendBuffer(tmpResult, parts[j]);
            tThis.excelLoadingChange(tThis, 1);
        }

        setTimeout(() => { tThis.XLSXFill(tThis, XLSX, tmpResult); }, 10);
    }

    async XLSXFill(tThis, XLSX, tmpResult) {
        tThis.excelLoadingChange(tThis, 1);
        tThis.workbookG = XLSX.read(tmpResult, {
            type: 'buffer', cellDates: true
        });
        tThis.setLogExcelText("Excel Yüklemesi Tamamlandı.");
        setTimeout(() => { tThis.chunkFileDone_Worker_2(tThis, XLSX); }, 5);
    }

    chunkFileDone_Worker_2(tThis, XLSX) {
        let _headerDate = tThis.data.headers.find(x => x.type == "date" || x.type == "datetime");
        let _format = "";
        try { _format = _headerDate.format; } catch (ex) { }
        tThis.workbookG.SheetNames.forEach(function (sheetName) {
            var XL_row_object;
            try {
                XL_row_object = XLSX.utils.sheet_to_row_object_array(tThis.workbookG.Sheets[sheetName]/*, { raw: false }*/, { dateNF: _format });
            } catch (ex) {
                XL_row_object = XLSX.utils.sheet_to_row_object_array(tThis.workbookG.Sheets[sheetName], {});
            }
            var json_object = JSON.stringify(XL_row_object);
            tThis.json_ArrayExcel = JSON.parse(json_object);

            tThis.setLogExcelText("Veri Aktarımı Başladı.");

            tThis._nExcelImportLoading = 65 / tThis.json_ArrayExcel.length;


            tThis.setLogExcelText("<span file-total>0 satır aktarıldı. (Toplam 0 Satır)</span>");

            if (tThis.checkIsDataGridEmpty()) {

                tThis.excelRowImport(tThis, true);

                let dataCols = tThis.data.headers.filter(f => f.type == "combobox" || f.type == "datagrid" || f.type == "customableCombobox");//
                if (dataCols && dataCols.length > 0) {
                    tThis.prepareDataGridColumn(dataCols, 0, false);
                }

                tThis.forceRefreshDatagrid();

            } else {

                let msg = "";
                if (tThis.defaultLanguage && tThis.defaultLanguage.Label)
                    msg = '"' + tThis.defaultLanguage.Label + '" tablosuna ait eski veriler silinsin mi?';
                else
                    msg = tThis.languageLocal.deleteOldData;

                if (tThis.isShowComfirmShown == false) {
                    let result = confirm(msg, tThis.languageLocal.warning);
                    tThis.isShowComfirmShown = true;
                    result.then((dialogResult) => {
                        tThis.isShowComfirmShown = false;

                        if (dialogResult) {
                            var _Id = 0
                            let zeroTemp = tThis.data.value.find(x => x.ID == _Id);
                            if (typeof zeroTemp == "undefined") {
                                var clonedItem = Object.assign({}, {}, { ID: _Id, DefaultColumn: 1 });
                                tThis.data.value.splice(tThis.data.value.length, 0, clonedItem);
                                tThis.data.value.sort((a, b) => a.ID - b.ID);
                            }
                            tThis.data.value = tThis.data.value.filter(x => x.ID == 0 && x.DefaultColumn == 1/*values.indexOf(item) === -1*/);
                            tThis.getDatasourcePageItem();
                            tThis.forceRefreshDatagrid();
                        }

                        tThis.excelRowImport(tThis, true);

                        let dataCols = tThis.data.headers.filter(f => f.type == "combobox" || f.type == "datagrid" || f.type == "customableCombobox");//
                        if (dataCols && dataCols.length > 0) {
                            tThis.prepareDataGridColumn(dataCols, 0, false);
                        }

                        tThis.forceRefreshDatagrid();


                    });
                }

            }




        })
    }

    // Datagrid boş ise true döner dolu ise false döner
    checkIsDataGridEmpty() {
        let isEmpty = true;

        for (let i = 0; i < this.data.value.length; i++) {
            let shouldBreak = false
            this.data.headers.forEach(_dgridH => {
                if (typeof this.data.value[i][_dgridH.field] != "undefined" &&
                    (
                        (_dgridH.type == "int" && (this.data.value[i][_dgridH.field] != "" || this.data.value[i][_dgridH.field] != "0")) ||
                        (_dgridH.type == "double" && (this.data.value[i][_dgridH.field] != "" || this.data.value[i][_dgridH.field] != "0")) ||
                        (_dgridH.type == "text" && this.data.value[i][_dgridH.field] != "") ||
                        (_dgridH.type == "date" && this.data.value[i][_dgridH.field] != null) ||
                        (_dgridH.type == "datetime" && this.data.value[i][_dgridH.field] != null) ||
                        (_dgridH.type == "datagrid" && this.data.value[i][_dgridH.field] != "") ||
                    (_dgridH.type == "combobox" && this.data.value[i][_dgridH.field] != "") ||
                    (_dgridH.type == "customableCombobox" && this.data.value[i][_dgridH.field] != "")
                    )) {

                    isEmpty = false;
                    shouldBreak = true;

                }
            })

            if (shouldBreak) break;

        }
        return isEmpty
    }

    checkIsRowEmpty(_rowDataID) {

        let isEmpty = true;

        if (_rowDataID != 0) {
            let rowData = this.data.value.find(f => f.ID == _rowDataID);

            if (typeof rowData != "undefined") {
                this.data.headers.forEach(_dgridH => {
                    if (typeof rowData[_dgridH.field] != "undefined" &&
                        (
                            (_dgridH.type == "int" && (rowData[_dgridH.field] != "" || rowData[_dgridH.field] != "0")) ||
                            (_dgridH.type == "double" && (rowData[_dgridH.field] != "" || rowData[_dgridH.field] != "0")) ||
                            (_dgridH.type == "text" && rowData[_dgridH.field] != "") ||
                            (_dgridH.type == "date" && rowData[_dgridH.field] != null) ||
                            (_dgridH.type == "datetime" && rowData[_dgridH.field] != null) ||
                            (_dgridH.type == "datagrid" && rowData[_dgridH.field] != "") ||
                        (_dgridH.type == "combobox" && rowData[_dgridH.field] != "") ||
                        (_dgridH.type == "customableCombobox" && rowData[_dgridH.field] != "")
                        )) {

                        isEmpty = false;

                    }
                })
            }

        }
        return isEmpty

    }

    appendBuffer(buffer1, buffer2) {
        var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
        tmp.set(new Uint8Array(buffer1), 0);
        tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
        return tmp;
    };

    chunkFileDone(parts, tThis) {
        setTimeout(() => { tThis.chunkFileDone_Worker_1(parts, tThis); }, 5);

    }

    chunkFile(index, sliceSize, file, parts, count) {
        let tThis = this;
        var start = index * sliceSize;
        var end = Math.min(start + sliceSize, file.size);
        var slice = file.slice(start, end);
        var fr = new FileReader();

        this.excelLoadingChange(tThis, 1);
        fr.onload = function (e) {
            parts.push(this.result);
            index++;
            if (index < count) tThis.chunkFile(index, sliceSize, file, parts, count);
            else {
                setTimeout(() => { tThis.chunkFileDone(parts, tThis); }, 5);
            }
        };

        fr.readAsArrayBuffer(slice);

    }


    async excelLoadingChange(thisLoading, value) {
        setTimeout(() => {
            thisLoading._tickProcess = (thisLoading._tickProcess + value);

            let _progressbarRangeContainerWidth = document.querySelector('.dx-progressbar-range-container').clientWidth;
            let _progressbarRangeVal = _progressbarRangeContainerWidth * thisLoading._tickProcess / 100;
            (<HTMLInputElement>document.querySelector('.dx-trackbar-range.dx-progressbar-range')).style.minWidth = _progressbarRangeVal + 'px';

            try { thisLoading.changeDetectorRef.detectChanges(); } catch (ex) { }
        }, 5);
    }
    //async excelLoadingChangeFull(thisLoading) {
    //    setTimeout(() => {
    //        thisLoading._tickProcess = 100;

    //        let _progressbarRangeContainerWidth = document.querySelector('.dx-progressbar-range-container').clientWidth; 
    //        (<HTMLInputElement>document.querySelector('.dx-trackbar-range.dx-progressbar-range')).style.minWidth = _progressbarRangeContainerWidth + 'px';

    //        try { thisLoading.changeDetectorRef.detectChanges(); } catch (ex) { }
    //    }, 5);
    //}
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    formatProcessExcel(value) {
        return 'Yükleniyor: %' + parseInt((value * 100).toString());
    }
    async setLogExcelText(value) {
        this.logExcelText = this.logExcelText + value + "<br><br>";
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }
    async updateLogExcelText(_value, _replace) {
        this.logExcelText = this.logExcelText.replace(_value, _replace);
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }

    // #endregion EXCEL IMPORT




    // #region OTHERS
    /////////
    /// OTHERS
    ////////

    forceRefreshDatagrid() {
        try { this.dataGridEtr.instance.getDataSource().reload(); } catch (ex) { }
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        try { this.dataGridEtr.instance.refresh(); } catch (ex) { }
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }

    elementColumnDisabled(cell) {
        try {
            let header;
            if (typeof cell.column.dataField != "undefined") {
                header = this.data.headers.find(f => f.field == cell.column.dataField);
            }
            else {
                header = this.data.headers.find(f => f.field == cell.column.name);
            }
            let v1 = !this.data.disabled && !this.data.outsideDisabled;//true;//cell.isEditing;
            let v2 = header.editable;
            let v3 = v1 && v2;
            let v4 = !(v3);
            return v4 || this.data.editablePopup;
        }
        catch (e) {
        }
        return false;
    }
    showToast(message, status) {
        notify(message, status, 3000);
    }
    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace).replace(/[\u200B-\u200D\uFEFF]/g, '');
    }
    showLoadPanel() {
        if (!this.isEdit)
            this.loadingVisible = true;
    }
    hideLoadPanel() {
        let _this = this;
        let myVarTimer = setTimeout(function () {
            clearTimeout(myVarTimer);

            _this.loadingVisible = false;
            try {
                _this.changeDetectorRef.detectChanges();
            }
            catch (ex) { }

        }, 500);
    }
    onShown() {
        //setTimeout(() => {
        //    this.loadingVisible = false;
        //}, 3000);
    }

    onHidden() {
    }

    // Artık kullanılmıyor kontrol ediltikten sonra silinebilir
    getStyleCell(cell) {
        if (cell.column.dataField == "test2") console.log(cell);
        let val = this.data.headers.find(x => x.field == cell.column.dataField)

        let elements: any[] = this.elementHtml.nativeElement.querySelectorAll("td[aria-describedby='" + cell.column.headerId + "']");

        if (!!val) {
            for (let i = 0; i < elements.length; i++) {
                if (!!val && !!val.properties && !!val.properties.backgroundColor || !!val.properties.color)
                    try {
                        (<HTMLElement><unknown>((document.querySelectorAll("td[aria-describedby='" + cell.column.headerId + "']"))[i])).style.cssText = "background-color:" + val.properties.backgroundColor + "; color: " + val.properties.color;
                    } catch (ex) { }

            }
        }

    }

    findDateFormat(datafield) {
        let header = this.data.headers.find(el => el.field == datafield)
        return header.format
    }

    // #endregion OTHERS

    allDatagridComponents: any = [];

    getDatagridSourceNewData = null
    getDatagridkeyExprData = null
    getDataSourcePromise() {
        return new Promise((resolve, reject) => {
            try {
                this.getDatagridSourceNewData = this.getDatagridSourceNew(this.cellInfoGlobal)
                this.getDatagridkeyExprData = this.getDatagridkeyExpr(this.cellInfoGlobal)
                resolve(true)
            } catch (er) {
                reject()
            }

        })
    }

    showDatagridPopupClose() {
        this.showDatagridPopup = false;
        this.getDatagridSourceNewData = null;
        this.getDatagridkeyExprData = null;
    }

    currentColorExpression = { "id": 0, "backgroundColor": "", "fontColor": "", "fieldName": "", "fieldType": "", "conditionText": "", "alert": "", "pointerEvent": false }
    addColorExpressions() {

        let data = JSON.parse(JSON.stringify(this.currentColorExpression))
        if (data.fieldName == '') return
        data.fieldName = this.currentColorExpression.fieldName
        data.id = this.currentColorExpression.id
        data.backgroundColor = this.currentColorExpression.backgroundColor
        data.fontColor = this.currentColorExpression.fontColor
        data.fieldType = this.currentColorExpression.fieldType
        data.conditionText = this.currentColorExpression.conditionText
        data.alert = this.currentColorExpression.alert
        data.pointerEvent = this.currentColorExpression.pointerEvent;
        // if (data.pointerEvent = true) this.currentColorExpression.pointerEvent = "none"; else this.currentColorExpression.pointerEvent = "auto";
        // data.pointerEvent = this.currentColorExpression.pointerEvent;
        if (data.id != 0) {
            let updateIndex = this.data.colorExpressions.findIndex(f => f.id == data.id)
            if (typeof updateIndex != "undefined") {
                this.data.colorExpressions[updateIndex] = data
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        } else {
            if (this.data.colorExpressions.length == 0)
                data.id = 1
            else if (this.data.colorExpressions.length > 0)
                data.id = this.data.colorExpressions[this.data.colorExpressions.length - 1].id + 1

            this.data.colorExpressions.push(data)
        }

        this.currentColorExpression = { "id": 0, "backgroundColor": "", "fontColor": "", "fieldName": "", "fieldType": "", "conditionText": "", "alert": "", "pointerEvent": false }
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }

    }
    deleteColorExpressions(i) {
        this.data.colorExpressions.splice(i, 1);
    }

    editRowcolor(colorExp) {
        this.currentColorExpression = colorExp
    }

    onValueChangeColorExpressionFieldSelect($event) {

        if (!!$event && !!$event.value) {
            if ($event.value == "") { this.currentColorExpression.fieldType = ""; return; }
            let typeObject = this.data.headers.find(el => el.field == $event.value)

            if (typeObject.type.toLowerCase() == "text") this.currentColorExpression.fieldType = "string"
            if (typeObject.type.toLowerCase() == "double") this.currentColorExpression.fieldType = "number"
            if (typeObject.type.toLowerCase() == "int") this.currentColorExpression.fieldType = "number"
            if (typeObject.type.toLowerCase() == "checkbox") this.currentColorExpression.fieldType = "object"
            if (typeObject.type.toLowerCase() == "date") this.currentColorExpression.fieldType = "object"
            if (typeObject.type.toLowerCase() == "datetime") this.currentColorExpression.fieldType = "object"
            if (typeObject.type.toLowerCase() == "datagrid") this.currentColorExpression.fieldType = "object"
            if (typeObject.type.toLowerCase() == "combobox") this.currentColorExpression.fieldType = "object"
            if (typeObject.type.toLowerCase() == "customableCombobox") this.currentColorExpression.fieldType = "object"
            if (typeObject.type.toLowerCase() == "file") this.currentColorExpression.fieldType = "object"
            if (typeObject.type.toLowerCase() == "map") this.currentColorExpression.fieldType = "object"
            if (typeObject.type.toLowerCase() == "picturebox") this.currentColorExpression.fieldType = "object"
            if (typeObject.type.toLowerCase() == "fileviewer") this.currentColorExpression.fieldType = "object"

        }
    }

    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // Tüm charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }

    checkAll($event) {

        this.data.headers.forEach(header => {
            if (header.type == "checkbox") {
                this.data.value.forEach(e => {
                    if (!this.disabledRowsIDs.includes(e.ID)) {
                        if (this.data.allCheck == true)
                            e[header.field] = true;
                        else
                            e[header.field] = false;
                    }
                })
            }
        })

    }

    setLanguageToDatas() {
        this.numberRoundTypes = [
            { id: "ceil", value: this.languageLocal.alwaysRoundUp/*"Her Zaman Üste Yuvarla"*/ },
            { id: "floor", value: this.languageLocal.alwaysRoundBot/* "Her Zaman Alta Yuvarla"*/ },
            { id: "round", value: this.languageLocal.default/*"Varsayılan"*/ }
        ];
        this.fieldTypes = [
            { "type": "text", "value": "text", "name": "Text" },
            { "value": "double", "name": this.languageLocal.double /*"Ondalık Sayı" */ },
            { "value": "int", "name": this.languageLocal.integer /*"Tam Sayı"*/ },
            { "value": "checkbox", "name": "Checkbox" },
            { "value": "date", "name": "Date" },
            { "value": "datetime", "name": "DateTime" },
            { "value": "datagrid", "name": "Data Grid" },
            { "value": "combobox", "name": "Combobox" },
            { "value": "customableCombobox", "name": "Customable Combobox" },
            { "value": "file", "name": "File" },
            { "value": "map", "name": "Map" },
            { "value": "picturebox", "name": "Picturebox" },
            { "value": "fileviewer", "name": "File Viewer" }
        ];
        this.headers = {
            "name": "",
            "caption": "",
            "field": "",
            "type": "text",
            "format": "#,##0.00",
            "properties": { "barcode": false, "required": false, "isUserUpload": false, "autoText": { isAuto: false, autoPreText: "" }, "roundType": "round", "dateMinMax": { minToday: false, maxToday: false, dateRange: null }, "color": "", "backgroundColor": "", "fontweight": "", "rowBackgroundColor": "" },
            "template": "",
            "width": 150,
            "dataSourceKey": "",
            "dataSource": [],
            "displayKey": "",
            "valueKey": "",
            "funcParam": {},
            "files": [],
            "changeEvents": [],
            "expression": {},
            "dataGridSettings": {
                "connectionStringId": 0,
                "query": "",
                "key": "ID",
                "table": "",
                "formFields": []
            },
            "sqlColumnSettings": {
                "connectionStringId": 0,
                "query": "",
                "fileFields": {
                    "name": "",
                    "file": "",
                    "fileName": "",
                    "extension": ""
                }
            },
            "permissions": {
                "readonlyGroup": [],
                "readonlyUsers": [],
                "hideGroup": [],
                "hideUsers": []
            },
            "language": [
                {
                    "Code": "trTR",
                    "Label": this.languageLocal.title
                }
            ]
        };
    }



}
