import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { ComponentService } from '../component.service';
import { EventEmitterService } from '../../shared/services';
import { confirm } from 'devextreme/ui/dialog';
import { InstanceService } from '../../shared/services/data/instance.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-signature',
    templateUrl: './signature.component.html',
    styleUrls: ['./signature.component.css']
})
export class DxSignatureComponent implements ComponentParameters, OnInit, AfterViewInit {
    // Default definitions
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;
    @Input() disabled: boolean;
    @Input() anotherPopupId: number = 0;

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;

    showDisplay: any = "block";

    // Upload Variable
    uploadedFiles: any = [];
    uploadResult = { isShow: false, status: "success", message: "" };
    totalSize: any = 0;

    // EmptyPad is declared as clean pad to compare sigPad
    @ViewChild('emptyPad', { static: false }) emptyPad: ElementRef;
    @ViewChild('sigPad', { static: false }) sigPad: ElementRef;
    public context: CanvasRenderingContext2D;
    public emptyPadContext: CanvasRenderingContext2D;

    isDrawing = false; //Drawing something on mouseDown or mouseMove
    img = null; // Base64 value

    invokeChangeEventLocal: Subscription;
    invokeButtonClickChangeEventLocal: Subscription;
    invokeWarningChangeEventLocal: Subscription;
    languageLocal: any = "";

    borderColor = "#dedede"

    constructor(private eventEmitterService: EventEmitterService, private componentService: ComponentService, public changeDetectorRef: ChangeDetectorRef,
        public instanceService: InstanceService, private domSanitizer: DomSanitizer) {

        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
        this.languageLocal = this.componentService.getLanguageSource(); //language

        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {

            if (sub.elementId == this.data.id) {

                if (sub.data) {
                    this.data.valueTemp = [];
                    this.data.valueTemp.push(sub.data);
                }
                else this.data.valueTemp = [];

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }

            }
        });

        this.invokeButtonClickChangeEventLocal = this.eventEmitterService.invokeButtonClick.subscribe(sub => {
            this.save();
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        });

        this.invokeWarningChangeEventLocal = this.eventEmitterService.invokeWarningChangeEvent.subscribe(sub => {
            this.changeToWarningColor()
        });

    }

    changeToWarningColor() {
        if (this.data.required && this.isPadEmpty()) {
            this.borderColor = "#d9534f"
        }

        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }

    changeToDefaultColor() {

        this.borderColor = "#dedede"

        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }

    showImage = false
    ngOnInit() {
        this.clear()

        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }

        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (typeof this.data.show === "undefined")
            this.data.show = true;
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide ? "flex" : "none") : "flex";

        this.data.width = this.data.width > 0 ? this.data.width : 90;
        this.data.height = this.data.height > 0 ? this.data.height : 90;

        if (typeof this.data.valueTemp == "undefined")
            this.data.valueTemp = [];

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });
        
        this.instanceService.getFormFilesData(this.data.value).subscribe((s: any) => {


            if (!!s && !!s.files && s.files.length > 0) {

                this.showImage = true

                s.files.forEach(data => {
                    let document: any = {
                        id: data.id,
                        base64BlobData: data.fileData,
                        name: data.fileName,
                        fileType: data.fileType,
                        extension: data.fileExtension,
                        size: data.fileSize,
                        isImg: data.isImg
                    };
                    document.src = 'data:image/jpeg;base64,' + document.base64BlobData;

                    this.img = document.src

                    this.getSafeUrl()

                    try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                })
            }


                
        })

    }

    getSafeUrl() {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(this.img);
    }

    ngAfterViewInit() {
        var _this = this;
        setTimeout(() => {
            setTimeout(() => {
                this.clear()

                try { _this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }, 100);
        }, 100);

        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }

    // Upload Events
    onValueChanged() {
        let errors = [];
        this.data.valueTemp = [];
        if (!this.isPadEmpty()) {
            //this.data.value = [];
            this.totalSize = 0;
            this.uploadResult.isShow = true;
            this.uploadResult.status = "primary";
            this.uploadResult.message = this.languageLocal.loadingFiles;

            this.createDocument().then(document => {
                this.data.valueTemp.push(document);
                this.data.isPadEmpty = this.isPadEmpty();

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }

                if (errors.length) {
                    this.uploadResult.status = "danger";
                    this.uploadResult.message = this.languageLocal.fileSizeExceeded + errors.join(", ");
                }
                else {
                    this.uploadResult.status = "success";
                    this.uploadResult.message = this.languageLocal.filesLoaded;
                }

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }

            });

        }
    }

    createDocument() {
        return new Promise(resolve => {
            fetch(this.img)
                .then(res => res.blob())
                .then(blob => {
                    var date = new Date()
                    var reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onload = (args: any) => {
                        if (reader.result) {
                            let fileName = "signature-" + date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + "." + date.getMinutes() + '.' + date.getMilliseconds() + ".jpg";
                            let fileType: string = "image/jpeg";
                            let base64BlobData: any = args.target.result.split(',').pop();
                            let fileExtension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2);
                            //let fileSize = file.size;
                            let document: any = {
                                base64BlobData: base64BlobData,
                                name: fileName,
                                fileType: blob.type,
                                src: 'data:' + fileType + ';base64,' + base64BlobData,
                                extension: fileExtension,
                                size: blob.size,
                                isImg: true
                            };
                            resolve(document);

                        }
                    }
                })

        });
    }

    // Component Functions down below
    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    onMouseUp(e) {
        this.isDrawing = false;
    }

    onTouchEnd(e) {
        this.isDrawing = false;
    }

    onMouseDown(e) {
        this.isDrawing = true;

        const coords = this.relativeCoords(e,'mouse');
        this.context.moveTo(coords.x, coords.y);
    }

    onTouchDown(e) {
        this.isDrawing = true;

        const coords = this.relativeCoords(e, 'touch');
        this.context.moveTo(coords.x, coords.y);
    }

    onMouseMove(e) {
        if (this.isDrawing) {
            const coords = this.relativeCoords(e,'mouse');
            this.context.lineTo(coords.x, coords.y);
            this.context.stroke();
        }
    }

    onTouchMove(e) {
        if (this.isDrawing) {
            const coords = this.relativeCoords(e,'touch');
            this.context.lineTo(coords.x, coords.y);
            this.context.stroke();
        }
    }

    // type is declared to understand touch or mouse event
    // type can be 'touch' or 'mouse'
    private relativeCoords(event, type) {
        const bounds = event.target.getBoundingClientRect();
        let x = null
        let y = null

        if (type == 'touch') {
            x = event.touches[0].clientX - bounds.left;
            y = event.touches[0].clientY - bounds.top;
        } else if (type == 'mouse') {
            x = event.clientX - bounds.left;
            y = event.clientY - bounds.top;
        }

        return { x: x, y: y };
    }

    isPadEmpty() {
        if (!!this.sigPad && !!this.emptyPad && !!this.sigPad.nativeElement && !!this.emptyPad.nativeElement) {
            const img = this.sigPad.nativeElement.toDataURL("image/jpg");
            const empty = this.emptyPad.nativeElement.toDataURL("image/jpg");
            if (img == empty) {
                this.data.valueTemp = []
                return true;
            }
            this.changeToDefaultColor()
            return false;
        }
        return true;
    }

    clearButton() {
        this.showImage = false
        this.clear()
    }

    clear() {
        if (!!this.sigPad && !!this.emptyPad && !!this.sigPad.nativeElement && !!this.emptyPad.nativeElement) {
            this.data.valueTemp = []
            this.img = null

            this.context = this.sigPad.nativeElement.getContext('2d');
            this.context.clearRect(0, 0, this.sigPad.nativeElement.width, this.sigPad.nativeElement.height);
            this.context.beginPath();
            this.context.fillStyle = "white";
            this.context.fillRect(0, 0, this.sigPad.nativeElement.width, this.sigPad.nativeElement.height);

            this.emptyPadContext = this.emptyPad.nativeElement.getContext('2d');
            this.emptyPadContext.clearRect(0, 0, this.emptyPad.nativeElement.width, this.emptyPad.nativeElement.height);
            this.emptyPadContext.beginPath();
            this.emptyPadContext.fillStyle = "white";
            this.emptyPadContext.fillRect(0, 0, this.emptyPad.nativeElement.width, this.emptyPad.nativeElement.height);
        }
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // T�m charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    save() {
        if (!this.isPadEmpty()) {
            this.img = this.sigPad.nativeElement.toDataURL("image/jpg");
            this.onValueChanged()
        } else {
            
        }
    }

}
