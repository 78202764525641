import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "./components/components.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";

import { CurrencyPipe } from '@angular/common';
 import { BrowserModule } from '@angular/platform-browser'; 
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { AppInfoService, ScreenService, AuthService, AuthGuardService, FormbuilderGuardService, ReportingGuardService } from './shared/services';
import { RiverFormModule } from './formdesigner/riverform.module';

import { DevExtremeModule } from '../devextreme.module';
import { DxSelectBoxModule } from 'devextreme-angular';
import { ComponentService } from '../app/formdesigner/component.service';
import { AppRoutes } from "./app.routing";
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
 import { ReportDesignerComponent } from "./pages/reportdesigner/reportdesigner.component";
//import { CoreModule } from "./core/core.module";
//import { MainSafePipe } from './safepipemain.component ';

@NgModule({
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        ReportDesignerComponent,
        AuthLayoutComponent,
        /*, MainSafePipe*/],
    imports: [ BrowserModule, 
        BrowserAnimationsModule,
       // CoreModule,
        //ComponentService,
       // ComponentService,
    //    FormsModule,
    //    ReactiveFormsModule,
    //    MonacoEditorModule.forRoot(),
        DxSelectBoxModule,
        DevExtremeModule,
        RiverFormModule,
        RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
        NgbModule,
        ToastrModule.forRoot(), // ToastrModule added
        ComponentsModule
    ], 
    providers: [AuthGuardService, FormbuilderGuardService, ReportingGuardService, AppInfoService, ScreenService, AuthService, ComponentService, CurrencyPipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }
