import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { EventEmitterService } from '../../shared/services';
import { Subscription } from 'rxjs';
import { InstanceService } from '../../shared/services/data/instance.service';

@Component({
    selector: 'app-picturebox',
    templateUrl: './picturebox.component.html',
    styleUrls: ['./picturebox.component.css']
})
export class DxPictureBoxComponent implements ComponentParameters, OnInit {
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;
    @Input() anotherPopupId: number = 0;

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    showFilesPopup: boolean = false;

    showDisplay: any = "flex";
    languageLocal: any = "";

    uploadedFiles: any = [];
    uploadResult = { isShow: false, status: "success", message: "" };
    totalSize: any = 0;
    positions = [{ label: "Left", value: "flex-start" }, { label: "Center", value: "center" }, { label: "Right", value: "flex-end" }]
    invokeChangeEventLocal: Subscription;
    invokeWarningChangeEventLocal: Subscription;

    borderColor = "#dedede"

    constructor(private eventEmitterService: EventEmitterService, public changeDetectorRef: ChangeDetectorRef, private componentService: ComponentService,
        public instanceService: InstanceService) {

        this.languageLocal = this.componentService.getLanguageSource(); //language

        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });

        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {
            this.setLanguageToDatas()

            if (sub.elementId == this.data.id) {

                if (sub.data) {
                    this.data.valueTemp = [];
                    let _document: any = {
                        base64BlobData: sub.data,
                        name: "_" + this.data.id,
                        id: -1,
                        fileType: "image/png",
                        src: 'data:image/png;base64,' + sub.data,
                        extension: "png",
                        size: 0,
                        isImg: true,
                        description: ""
                    };
                    this.data.valueTemp.push(_document);
                }
                else this.data.valueTemp = [];

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }

            }
        });

        this.invokeWarningChangeEventLocal = this.eventEmitterService.invokeWarningChangeEvent.subscribe(sub => {
            if (!!sub.data && !!sub.data.requiredFiles) {
                if (sub.data.requiredFiles.includes(this.data.id)) {
                    this.changeToWarningColor()
                }
            }
        });

    }


    ngOnInit() {
        console.log(this.data)
        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";


        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });

        this.data.width = this.data.width > 0 ? this.data.width : 90;
        this.data.height = this.data.height > 0 ? this.data.height : 90;
        this.data.editableAfterUpload = typeof this.data.editableAfterUpload == "undefined" ? true : this.data.editableAfterUpload;

        this.data.position = typeof this.data.position == "undefined" ? "flex-start" : this.data.position;
        if (typeof this.data.valueTemp == "undefined")
            this.data.valueTemp = [];
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide ? "flex" : "none") : "flex";
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }
    imageZoomPopup = false;
    popupSelectedImage = "";
    onClickPicturebox() {
        this.imageZoomPopup = true;
        this.popupSelectedImage = this.data.valueTemp != null && this.data.valueTemp.length > 0 ? this.data.valueTemp[0].src : ''
    }
    pictureboxUploaderStyleFunc() {
        //this.imageUploadVisible
        var _block = this.imageUploadVisible ? 'block' : 'none';
        const styles = { 'display': _block };
        if (this.data.editableAfterUpload || (!this.data.editableAfterUpload && this.data.value < 1)) {
            styles["left"] = "calc(50% - 37px)";
            styles["top"] = "calc(50% - 14px)";
        }
        else {
            styles["left"] = "calc(50% - 16px)";
            styles["top"] = "calc(50% - 12px)";
        }
        return styles;
        //let headerObj = this.data.headers.find(x => x.field == cellInfo.column.dataField);
        //var _block = cellInfo && this.pictureboxImageUploadVisibleObj[cellInfo.column.dataField + '_' + cellInfo.data.ID] && ((_type != "Preview" && headerObj.properties.isUserUpload) || _type == "Preview") ? 'block' : 'none';
        //const styles = { 'display': _block };
        //if (_type == "Preview") {
        //    if (headerObj.properties.isUserUpload) {
        //        styles["left"] = "calc(50% - 29px)";
        //        styles["top"] = "calc(50% - 10px)";
        //    }
        //    else {
        //        styles["left"] = "calc(50% - 16px)";
        //        styles["top"] = "calc(50% - 12px)";
        //    }
        //}
        //return styles;
        // return this.pictureboxImageUploadVisibleObj[cellInfo.column.dataField + '_' + cellInfo.data.ID] && cellInfo.column.properties.isUserUpload; //pictureboxTemplate
    }

    openPermissionPopup() {
        this.permissionPopup = true;
    }

    onValueChanged = (e: any): void => {
        let errors = [];
        this.data.valueTemp = [];
        if (e.value.length) {
            this.changeToDefaultColor();
            //this.data.valu e = [];
            this.totalSize = 0;
            this.uploadResult.isShow = true;
            this.uploadResult.status = "primary";
            this.uploadResult.message = this.languageLocal.filesLoading;
            //  for (let i = 0; i < .length; i++) {
            let checkSum = this.totalSize + e.value[0].size;
            if (checkSum > (this.data.maxFileSize * 1024 * 1024)) {
                let msg = e.value[0].name + " (" + Math.round(checkSum / 1024) + this.languageLocal.couldnotbeLoaded;
                errors.push(msg);
                this.uploadResult.status = "danger";
                this.uploadResult.message = this.languageLocal.fileSizeExceeded + errors.join(" ") + (this.languageLocal.fileSizeLimit + this.data.maxFileSize * 1024 + " KB )");

                this.componentService.showAlert(this.uploadResult.message, "warning", 5000)
                return;
            }

            this.getDocument(e.value[0]).then(document => {
                this.data.value = []
                this.data.valueTemp = []
                this.data.valueTemp.push(document);//.push(document);
                this.totalSize += e.value[0].size;
                //if (i === (e.value.length - 1)) {
                if (errors.length) {
                    this.uploadResult.status = "danger";
                    this.uploadResult.message = this.languageLocal.fileSizeExceeded + errors.join(" ") + (this.languageLocal.fileSizeLimit + this.data.maxFileSize * 1024 + " KB )");
                }
                else {
                    this.uploadResult.status = "success";
                    this.uploadResult.message = this.languageLocal.filesUploaded;
                }

                if (this.isEdit) {

                    let objArray = [];
                    let obj: any = {
                        componentId: this.data.id,
                        rowId: "-1",
                        columnId: "-1",
                        fileData: this.data.valueTemp[0].base64BlobData,
                        fileExtension: this.data.valueTemp[0].extension,
                        fileType: this.data.valueTemp[0].fileType,
                        isImage: this.data.valueTemp[0].isImg,
                        name: this.data.valueTemp[0].name,
                        size: this.data.valueTemp[0].size,
                        description: ""
                    };

                    objArray.push(obj);

                    this.instanceService.saveFormFilesData(objArray, 0, this.data.value).subscribe((s: any) => {

                        let _fileVal = s.files.filter(x => x.ComponentId == this.data.id);
                        if (_fileVal != null && typeof _fileVal != "undefined" && _fileVal.length > 0) {
                            this.data.value = _fileVal.map(ele => ele.Id);

                        }

                    });

                }

                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                // }
            });

            // }
        }
    }

    getDocument(file) {
        return new Promise(resolve => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (args: any) => {
                if (reader.result) {
                    let fileName = file.name;
                    let fileType: string = file.type;
                    let base64BlobData: any = args.target.result.split(',').pop();
                    let fileExtension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2);
                    let fileSize = file.size;
                    let document: any = {
                        base64BlobData: base64BlobData,
                        name: fileName,
                        fileType: fileType,
                        src: 'data:' + fileType + ';base64,' + base64BlobData,
                        extension: fileExtension,
                        size: fileSize,
                        isImg: true,
                        description: ""
                    };
                    resolve(document);

                }
            }

        });
    }
    imageUploadVisible = false;
    onBlur() {
        this.imageUploadVisible = false;
    }
    onFocus() {
        this.imageUploadVisible = true;
    }
    imageDivStyleFunc() {

        var width = this.data.width > 0 ? this.data.width + 'px' : '90px';
        var height = this.data.height > 0 ? this.data.height + 'px' : '92px';
        const styles = { 'width': width, 'height': height, 'position': 'relative', 'border-color': this.borderColor };
        return styles;
    }
    imageStyleFunc() {

        var width = this.data.width > 0 ? this.data.width + 'px' : '90px';
        var height = this.data.height > 0 ? (this.data.height - 2) + 'px' : '88px';
        const styles = { 'width': width, 'height': height, 'display': this.showDisplay };
        return styles;
    }

    showFiles() {
        for (let i = 0; i < this.data.valueTemp.length; i++) {
            this.data.valueTemp[i].src = 'data:' + this.data.valueTemp[i].fileType + ';base64,' + this.data.valueTemp[i].base64BlobData;
        }
        this.showFilesPopup = true;
    }

    deleteFiles() {
        let result = confirm("<i>" + this.languageLocal.filesSureDelete + "</i>", this.languageLocal.filesWillDeleted);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.uploadedFiles = [];
                for (let i = 0; i < this.data.valueTemp.length; i++) {
                    this.eventEmitterService.setDeletedFileIds(this.data.valueTemp[i].id);
                }
                this.data.valueTemp = [];
                this.data.value = []
                this.uploadResult.isShow = false;
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });
    }

    deleteFile(i) {
        let result = confirm("<i>" + this.languageLocal.fileDelete + "</i>", this.languageLocal.filesWillDeleted);
        result.then((dialogResult) => {
            if (dialogResult) {
                if (this.data.valueTemp && this.data.valueTemp.length > 0 && this.data.valueTemp[i])
                    this.eventEmitterService.setDeletedFileIds(this.data.valueTemp[i].id);
                this.data.valueTemp.splice(i, 1);
                this.data.value.splice(i, 1);
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                this.showFiles();
            }
        });

    }

    setLanguageToDatas() {
        this.positions =
            [{ label: this.languageLocal.left/*"Left"*/, value: "flex-start" },
            { label: this.languageLocal.center/*"Center"*/, value: "center" },
            { label: this.languageLocal.right /*"Right"*/, value: "flex-end" }
            ];
    }

    changeToWarningColor() {
        if (this.data.required) {
            this.borderColor = "#d9534f"
        }

        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // T�m charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    changeToDefaultColor() {
        this.borderColor = "#dedede"

        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }

}
