import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { AppInfoService, FormService, GenericService } from '../../shared/services';
import { Subscription } from 'rxjs';
//import { DomSanitizationService } from '@angular/platform-browser/esm5/src/security/dom_sanitization_service';


declare var globalIpAddress: any;

@Component({
    selector: 'button-component',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.css']
})

export class DxButtonComponent implements ComponentParameters, OnInit, OnDestroy {
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: any;
    @Input() anotherPopupId: number = 0;


    buttonTypeEnum = {
        Kaydet: 1,
        //  KaydetGönder: 2,
        Onayla: 2,
        Reddet: 3,
        //   GeriGönder: 5,
        İptalEt: 4,
        ReddetBitir: 5,
        //   Tamamla: 8
    }

    buttonActions = [
        { "text": "Kaydet", "value": 1 },
        // { "text": "Kaydet ve Gönder", "value": 2 },
        { "text": "Onayla", "value": 2 },
        { "text": "Reddet", "value": 3 },
        //{ "text": "Geri Gönder", "value": 5 },
        { "text": "İptal Et", "value": 4 },
        { "text": "Reddet ve Bitir", "value": 5 },
        //{ "text": "Tamamla", "value": 8 }
    ];

    buttonTypes = [
        { "text": "Mavi", "value": "default" },
        { "text": "Gri", "value": "normal" },
        { "text": "Yeşil", "value": "success" },
        { "text": "Kırmızı", "value": "danger" }
    ];
    possitions = [
        { "text": "Sol", "value": "left" },
        { "text": "Sağ", "value": "right" }
    ];

    buttonModel = {
        //"text": "Kaydet",selectedButton.priceComponen t
        "name": "Kaydet",
        "status": 1,
        "type": "default",
        "possition": "left",
        "language": [
            {
                "buttonId": "0",
                "Code": "trTR",
                "Label": "Kaydet",
                "DefaultLabel": "Kaydet",
            }
        ]
    }


    textComponentList: any = [];
    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    usersAndGroupsPopup: boolean = false;
    buttonSettingPopup: boolean = false;
    buttonsPopup: boolean = false;
    showRejectReasonPopup: boolean = false;

    userPermissionAll: boolean = false;
    groupPermissionAll: boolean = false;

    rejectReason: string = "";

    users = [];
    groups = [];

    invokeChangeEventLocal: Subscription;

    userButtons = [];
    customerIp = "1.0.0.0";
    currentFormSelectedGlobal;

    showDisplay: any = "flex";
    showDisplayBoolean = false;
    hideButtonList = [];
    languageLocal: any = "";

    constructor(public appInfo: AppInfoService,
        public changeDetectorRef: ChangeDetectorRef,
        private eventEmitterService: EventEmitterService,
        private genericService: GenericService,
        public formService: FormService,
        //public _sanitizer: DomSanitizationService,
        public componentService: ComponentService) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
        this.languageLocal = this.componentService.getLanguageSource(); //language
        this.setLanguageToDatas()


        //let fff = this.componentService.currentFor m;


        this.textComponentList = this.componentService.getInputComponentList(this.componentService.currentForm.components, this.anotherPopupId);


        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {

            if (sub.elementId == "isReadonly") {
                this.currentFormSelectedGlobal = this.componentService.currentFormSelected(this.anotherPopupId);
                if (sub.data) this.currentFormSelectedGlobal.isReadonly = sub.data;
                else this.currentFormSelectedGlobal.isReadonly = false;
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });

        this.getFormList();

        this.customerIp = globalIpAddress;
        //this.genericService.getIPAddress().subscribe((result: any) => {
        //    this.customerIp = result.ip;
        //});
    }
    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
    }
    allButtonIcons = [];
    ngOnInit() {
        this.data.uuid = this.componentService.createuuid();

        this.allButtonIcons = this.componentService.getAllIcons();

        this.loadUserButtons();
        //  this.defaultLanguag e = this.componentService.getLang(this.lang, this.data.languag e);
        if (this.isEdit) {
            this.groups = this.appInfo.RiverEntity.userGroups;
            this.users = this.appInfo.RiverEntity.users;
        }

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;
        this.data.showBorder = typeof this.data.showBorder == "undefined" ? true : this.data.showBorder;

        this.data.maxPaymentHour = typeof this.data.maxPaymentHour == "undefined" ? null : this.data.maxPaymentHour;
        this.data.minPaymentHour = typeof this.data.minPaymentHour == "undefined" ? null : this.data.minPaymentHour;
        

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

                this.hideButtonList = sub.data.hideButtonList
            }
        });

        this.currentFormSelectedGlobal = this.componentService.currentFormSelected(this.anotherPopupId);
    }

    checkShow(val) {
        let bool = true;
        if (typeof this.hideButtonList != "undefined" && this.hideButtonList.length > 0) {
            if (this.hideButtonList.includes(val.property.name)) {
                bool = false;
            } else {
                bool = true;
            }
        } else {

        }
        return bool

    }

    formList
    getFormList() {
        this.formService.getAllForms().subscribe(forms => {
            //this.formList = ;
            this.formList = forms;
        })
    }
    formComponentList
    customerFormOnChange() {
        //

        let form = this.formList.find(x => x.id == this.selectedButton.customerFormId);
        if (form && form.forM_JSON) {
            let formjson = JSON.parse(form.forM_JSON);
            formjson.components = formjson.components.filter(f => !f.hide);
            this.formComponentList = this.componentService.getInputComponentList(formjson.components, this.anotherPopupId);

        }
    }

    loadUserButtons() {
        if (this.isEdit) {
            this.userButtons = this.data.buttons;
        }
        else {
            this.data.buttons.forEach(btn => {
                let userId = this.appInfo.currentUser.id;

                if (btn.users.length <= 0 && btn.groups.length <= 0) {
                    this.userButtons.push(btn);
                }
                else {
                    let isGroupUser = false;
                    if (btn.groups) {
                        btn.groups.forEach(g => {
                            if (g.users.indexOf(userId) >= 0) {
                                isGroupUser = true;
                            }
                        });
                    }
                    if (btn.users.indexOf(userId) >= 0 || isGroupUser) {
                        this.userButtons.push(btn);
                    }
                }



                //else if (btn.users.indexOf(userId) >= 0) {
                //    this.userButtons.push(btn);
                //}
                //else if (btn.groups) {
                //    btn.groups.forEach(g => {
                //        if (g.users.indexOf(userId) >= 0) {
                //            this.userButtons.push(btn);
                //        }
                //    });
                //}





            });


        }


    }

    selectedColLanguage: any;
    addButtonLanguage(button, i) {
        //
        //this.componentService.getLang(this.lang, button.property.language);

        let j = 0;
        for (j = 0; j < button.property.language.length; j++) {
            button.property.language[j].buttonId = i;
        }

        this.selectedColLanguage = button.property.language;
        this.languagePopup = true;
    }
    getTextValue(btn) {
        let _text = "";
        if (typeof btn.property.language === "undefined") {
            _text = btn.property.text;
        }
        else {
            let index = btn.property.language.findIndex(item => item.Code === this.lang);

            if (index > -1) {
                if (btn.property.language[index] && btn.property.language[index].Label)
                    _text = btn.property.language ? btn.property.language[index].Label : '-';

                else
                    _text = '-';

            }
            else
                _text = btn.property.language ? btn.property.language[0].Label : '-';
        }
        return _text;
    }
    statusOnChange(button, index) {


        let statusText = this.buttonActions.find(f => f.value == button.property.status).text;
        if (typeof button.property.language[0].Label === "undefined" || button.property.language[0].Label == "" || button.property.language[0].Label == button.property.language[0].DefaultLabel) {
            button.property.language[0].Label = statusText;
            button.property.language[0].DefaultLabel = statusText;
        } //   button.property.name = this.buttonActions.find(f => f.value == button.property.status).text;

    }

    //replaceTurkish(str) {
    //      ı ğ ş ü
    //    Ö Ç İ Ğ Ş Ü
    //    'ö': 'o', 'ç': 'c', 'ö': 'o', 'ö': 'o', 'ö': 'o', 'ö': 'o',
    //        'ö': 'o', 'ö': 'o', 'ö': 'o', 'ö': 'o', 'ö': 'o', 'ö': 'o',
    //    for (var x in { 'ö': 'o', 'b': 'o' }) {
    //        str = str.replace(new RegExp("ö", "gm"), "o");
    //        retStr = retStr.replace(new RegExp(x, 'g'), obj[x]);
    //    }


    //    return 
    //}

    onEditPopopShown() {
        this.textComponentList = this.componentService.getInputComponentList(this.componentService.currentForm.components, this.anotherPopupId);
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }
    defaultCustomerObj = { id: 0, name: "", surname: "", identityNumber: "", country: "", city: "", email: "", address: "", ip: this.customerIp };
    paymentProviders = [{ name: "IYZICO" }, { name: "YAPIKREDI" }, { name: "HPM" }];
    addNewButton() {
        let btn: any = {};
        btn.users = [];
        btn.groups = [];
        btn.isLocation = false;
        btn.isRequiredNotCheck = false;
        btn.isPayment = false;
        btn.isZoom = false;
        btn.zoomUrl = "";
        btn.isLiveWeb = false;
        btn.liveWeb = { isCreateLiveWeb: true, urlHost: "", urlParticipant: "" };
        this.defaultCustomerObj.ip = this.customerIp;
        btn.customer = this.defaultCustomerObj;
        btn.priceComponent = "";
        btn.hirePurchaseComponent = "";
        btn.descriptionComponent = "";
        btn.paymentProvider = "";
        btn.property = this.componentService.cloneModel(this.buttonModel);
        this.data.buttons.push(btn);
    }

    deleteButton(index) {
        let msg = "<i>" + this.languageLocal.messageButtonDelete + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.data.buttons.splice(index, 1);
            }
        });
    }

    saveButtons() {
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        this.buttonsPopup = false;
    }

    selectedButton: any;
    openUsersAndGroupPermissionPopup(btn) {
        this.selectedButton = btn;
        this.users.forEach(user => {
            if (btn.users.indexOf(user.id) != -1) {
                user.hasPermission = true;
            }
            else {
                user.hasPermission = false;
            }
        });

        this.groups.forEach(group => {
            let extGroup = btn.groups.find(f => f.id == group.id);
            if (extGroup) {
                group.hasPermission = true;
            }
            else {
                group.hasPermission = false;
            }
        });

        this.usersAndGroupsPopup = true;
    }

    openButtonSettingPopup(btn) {

        this.selectedButton = btn;
        this.selectedButton.isLocation = typeof this.selectedButton.isLocation == "undefined" ? false : this.selectedButton.isLocation;
        this.selectedButton.isRequiredNotCheck = typeof this.selectedButton.isRequiredNotCheck == "undefined" ? false : this.selectedButton.isRequiredNotCheck;
        this.selectedButton.isPayment = typeof this.selectedButton.isPayment == "undefined" ? false : this.selectedButton.isPayment;
        this.selectedButton.isZoom = typeof this.selectedButton.isZoom == "undefined" ? false : this.selectedButton.isZoom;
        this.selectedButton.isLiveWeb = typeof this.selectedButton.isLiveWeb == "undefined" ? false : this.selectedButton.isLiveWeb;
        this.selectedButton.liveWeb = typeof this.selectedButton.liveWeb == "undefined" ? { isCreateLiveWeb: true, urlHost: "", urlParticipant: "" } : this.selectedButton.liveWeb;
        this.selectedButton.zoomUrl = typeof this.selectedButton.zoomUrl == "undefined" ? "" : this.selectedButton.zoomUrl;
        this.selectedButton.priceComponent = typeof this.selectedButton.priceComponent == "undefined" ? "" : this.selectedButton.priceComponent;
        this.selectedButton.hirePurchaseComponent = typeof this.selectedButton.hirePurchaseComponent == "undefined" ? "" : this.selectedButton.hirePurchaseComponent;
        this.selectedButton.descriptionComponent = typeof this.selectedButton.descriptionComponent == "undefined" ? "" : this.selectedButton.descriptionComponent;
        this.selectedButton.paymentProvider = typeof this.selectedButton.paymentProvider == "undefined" ? "IYZICO" : this.selectedButton.paymentProvider;
        this.defaultCustomerObj.ip = this.customerIp;
        this.selectedButton.customer = typeof this.selectedButton.customer == "undefined" ? this.defaultCustomerObj : this.selectedButton.customer;
        this.buttonSettingPopup = true;
    }

    saveButtonSetting() {
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        this.buttonSettingPopup = false;
    }

    /* onStatusValueChanged(val, btn) {
      let status = this.buttonActions.find(f => f.value == val);
      if (status && (btn.property.text=="" || btn.property.text==null || typeof(btn.property.text) == "undefined")) {btn.property.text = status.text;}
    } */
    userPermissionChangeAll(isChecked) {
        this.users.forEach(user => {
            if (isChecked) {
                if (this.selectedButton.users.indexOf(user.id) == -1) {
                    this.selectedButton.users.push(user.id);
                }
            }
            else {
                if (this.selectedButton.users.indexOf(user.id) != -1) {
                    this.selectedButton.users.splice(this.selectedButton.users.indexOf(user.id), 1);
                }
            }
        });
        this.openUsersAndGroupPermissionPopup(this.selectedButton);
    }
    changeUserPermission(isChecked, id) {
        if (isChecked) {
            if (this.selectedButton.users.indexOf(id) == -1) {
                this.selectedButton.users.push(id);
            }
        }
        else {
            if (this.selectedButton.users.indexOf(id) != -1) {
                this.selectedButton.users.splice(this.selectedButton.users.indexOf(id), 1);
            }
        }

    }

    groupPermissionChangeAll(isChecked) {
        this.groups.forEach(group => {
            if (isChecked) {
                let extGroup = this.selectedButton.groups.find(f => f.id == group.id);
                if (!extGroup) {
                    let grp: any = {};
                    grp.id = group.id;
                    grp.users = [];
                    let _group = this.groups.find(f => f.id == group.id);
                    if (_group.users && _group.users.length > 0) {
                        let sp = _group.users.split(",");
                        sp.forEach(f => {
                            if (f) grp.users.push(parseInt(f));
                        });
                    }
                    this.selectedButton.groups.push(grp);
                }
            }
            else {
                let extGroup = this.selectedButton.groups.find(f => f.id == group.id);
                if (extGroup) {
                    this.selectedButton.groups.splice(this.selectedButton.groups.indexOf(extGroup), 1);
                }
            }
        });
        this.openUsersAndGroupPermissionPopup(this.selectedButton);
    }

    changeGroupPermission(isChecked, id, users) {
        if (isChecked) {
            let extGroup = this.selectedButton.groups.find(f => f.id == id);
            if (!extGroup) {
                let grp: any = {};
                grp.id = id;
                grp.users = [];
                if (users && users.length > 0) {
                    let sp = users.split(",");
                    sp.forEach(f => {
                        if (f) grp.users.push(parseInt(f));
                    });
                }
                this.selectedButton.groups.push(grp);
            }
        }
        else {
            let extGroup = this.selectedButton.groups.find(f => f.id == id);
            if (extGroup) {
                this.selectedButton.groups.splice(this.selectedButton.groups.indexOf(extGroup), 1);
            }
        }
    }

    selectedFormStatus: number;
    selectedButtonName: any;
    submitForm(args, status, name, isRequiredNotCheck = false, button = null) {
        debugger;
        if (!args.validationGroup.validate().isValid && status != 1 && isRequiredNotCheck == false) {
            this.componentService.showAlert(this.languageLocal.alertFillRequiredFields, "error", 5000)
            return;
        }

        let currentTime = new Date()
        let currentTimestr = currentTime.getHours() + ":" + currentTime.getMinutes() + ":00"

        if (button.minPaymentHour != null) {
            let date = new Date(button.minPaymentHour)
            let str = date.getHours() + ":" + date.getMinutes() + ":00"
            if (new Date('1/1/1999 ' + currentTimestr) < new Date('1/1/1999 ' + str)) { this.componentService.showAlert(this.languageLocal.paymentHourWarning, "error", 5000); return; }
        }

        if (button.maxPaymentHour != null) {
            let date = new Date(button.maxPaymentHour)
            let str = date.getHours() + ":" + date.getMinutes() + ":00"
            if (new Date('1/1/1999 ' + currentTimestr) > new Date('1/1/1999 ' + str)) { this.componentService.showAlert(this.languageLocal.paymentHourWarning, "error", 5000); return; }
        }


        this.eventEmitterService.buttonClickChangeEvent(null);
        let stop = false;
        let requiredFiles = []
        setTimeout(() => {
            let CheckRequiredComponents = this.componentService.getFilesComponentList(this.currentFormSelectedGlobal.components, this.anotherPopupId).filter(x => x.type == "signature" || x.type == "fileupload" || x.type == "picturebox" || x.type == "videoupload" || x.type == "imageupload");
            if (CheckRequiredComponents.length > 0 && status != 1) {
                CheckRequiredComponents.forEach(component => {
                    if (component.required && component.outsideVisible) {
                        console.log(component)
                        if (component.valueTemp.length == 0) {
                            stop = true;
                            requiredFiles.push(component.id)
                        } else {
                            stop == true ? stop = true : stop = false;
                        }
                    }
                });
            }
            if (stop && status != 1) {
                this.eventEmitterService.warningChangeEvent({ requiredFiles: requiredFiles });
                this.componentService.showAlert(this.languageLocal.alertFillRequiredFields, "error", 5000)
                return;
            };
            //let resu,lt = custom({
            //    title: "!", messageHtml: msg, buttons: [
            //        { text: "Evet", onClick: (e) => { return true; } },
            //        { text: "Hayır", onClick: (e) => { return false; } }
            //    ]
            //}).show();
            this.selectedFormStatus = status;
            this.selectedButtonName = name;
            let msg = "<i>" + this.languageLocal.messageApproveTransaction + "</i>"
            let result = confirm(msg, "!");
            result.then((dialogResult) => {
                if (dialogResult) {
                    if (status == this.componentService.formStatus.Disapprove) {
                        this.showRejectReasonPopup = true;
                        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                    }
                    else {
                        this.sendFormData();
                    }
                }
            });
        }, 100);

    }

    sendFormData(type?) {


        if (typeof type != "undefined") {
            if (type == this.buttonTypeEnum.Reddet) {
                if (this.rejectReason == "") {
                    this.componentService.showAlert(this.languageLocal.alertFillRequiredFields, "error", 5000)
                    return;
                }
            }
        }

        if (typeof this.componentService.isMenuForm != "undefined" && this.componentService.isMenuForm == true)
            this.eventEmitterService.submitMenu(this.selectedFormStatus, this.selectedButtonName, "");
        else
            this.eventEmitterService.sendForm(this.selectedFormStatus, this.selectedButtonName, this.rejectReason, this.anotherPopupId);
    }

    buttonIconPopoverOpen = false;
    hideEmojiPopoper(e) {
        //this.chatMessageInputStartPos = null;
        //this.chatMessageInputEndPos = null;
        //let myField = this.chatMessageInput.nativeElement;
        //if (!(this.chatMessageInputStartPos)) {
        //    myField.selectionStart = this.chatMessageInputStartPos;
        //    myField.selectionEnd = this.chatMessageInputEndPos;
        //}
        //myField.focus();
    }
    toggleIconPanel(btn) {
        debugger;
        this.selectedButton = btn;
        this.buttonIconPopoverOpen = !this.buttonIconPopoverOpen;
    }
    setButtonIcon(_icon) {
        debugger;
        if (_icon && _icon != "") {
            this.selectedButton.property.icon = _icon.icon;
        }
        else {
            this.selectedButton.property.icon = '';
        }
        this.buttonIconPopoverOpen = !this.buttonIconPopoverOpen;
        //let myField = this.chatMessageInput.nativeElement;
        //if (!(this.chatMessageInputStartPos)) {
        //    this.chatMessageInputStartPos = myField.selectionStart;
        //    this.chatMessageInputEndPos = myField.selectionEnd;
        //}
        //let myValueClear = this.insertAtCursor(_icon);
        //this.chatMessageText = myValueClear;
    }
    getButtonIconClass(btn) {
        if (typeof btn == 'undefined')
            return "icon-setting-button-delete";

        return typeof btn.property.icon == 'undefined' || btn.property.icon == '' ? 'icon-setting-button-empty' : 'icon-setting-button';
    }

    getButtonGeneralCss(btn) {
        let _myStyle = {};
        if (btn.property.height)
            _myStyle["height"] = btn.property.height + (this.componentService.isInteger(btn.property.height) ? "px" : "");
        if (btn.property.width)
            _myStyle["width"] = btn.property.width + (this.componentService.isInteger(btn.property.width) ? "px" : "");

        return _myStyle;
    }

    getButtonGeneralCssText(btn, type) {
        //    font-size: 103px;
        //width: 103px;
        //height: 18px;
        /*
        14   18
        20    x
         20*18/14
         */
        let _myStyle = {};
        if (btn.property.fontSize) {
            if (type == "i")
                _myStyle["font-size"] = btn.property.fontSize + (this.componentService.isInteger(btn.property.fontSize) ? "px" : "");
            else
                _myStyle["font-size"] = this.componentService.isInteger(btn.property.fontSize) ? ((btn.property.fontSize - 3) + "px") : ("calc(" + btn.property.fontSize + " - 3px)");

            //btn.property.fontSize +( this.componentService.isInteger(btn.property.fontSize) ? "px" : "");

            _myStyle["width"] = btn.property.fontSize + (this.componentService.isInteger(btn.property.fontSize) ? "px" : "");
        } else {
            if (type == "i")
                _myStyle["font-size"] = "17px";
            else
                _myStyle["font-size"] = "14px";

            _myStyle["width"] = "18px";
        }
        _myStyle["height"] = "18px";

        return _myStyle;
    }

    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // Tüm charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }

    setLanguageToDatas() {
        this.buttonActions = [
            { "text": this.languageLocal.save /*"Kaydet"*/, "value": 1 },
            { "text": this.languageLocal.saveandSend /*"Kaydet ve Gönder"*/, "value": 2 },
            { "text": this.languageLocal.approve /*"Onayla"*/, "value": 3 },
            { "text": this.languageLocal.reject /* "Reddet"*/, "value": 4 },
            //{ "text": this.languageLocal.sendBack /* "Geri Gönder"*/, "value": 5 },
            { "text": this.languageLocal.cancel /*"İptal Et"*/, "value": 5 },
            { "text": this.languageLocal.deslineAndEnd /*"Reddet ve Bitir"*/, "value": 6 },
            //{ "text": this.languageLocal.complete /*"Tamamla"*/, "value": 8 }
        ];

        this.buttonTypes = [
            { "text": this.languageLocal.blue /*"Mavi"*/, "value": "default" },
            { "text": this.languageLocal.grey /*"Gri"*/, "value": "normal" },
            { "text": this.languageLocal.green /*"Yeşil"*/, "value": "success" },
            { "text": this.languageLocal.red /*"Kırmızı"*/, "value": "danger" }
        ];

        this.possitions = [
            { "text": this.languageLocal.left /*"Sol"*/, "value": "left" },
            { "text": this.languageLocal.right /*"Sağ"*/, "value": "right" }
        ];

        this.buttonModel = {
            //"text": "Kaydet",selectedButton.priceComponen t
            "name": this.languageLocal.save /*"Kaydet"*/,
            "status": 1,
            "type": "default",
            "possition": "left",
            "language": [
                {
                    "buttonId": "0",
                    "Code": "trTR",
                    "Label": this.languageLocal.save /*"Kaydet"*/,
                    "DefaultLabel": this.languageLocal.save /* "Kaydet"*/,
                }
            ]
        }

    }

}
