import { Component, Input, OnInit } from '@angular/core';
import notify from "devextreme/ui/notify";

@Component({
    selector: 'whitepageviewer-component',
    templateUrl: './whitepageviewer.component.html',
    styleUrls: ['./whitepageviewer.component.scss']
})
export class WhitePageViewer implements OnInit {
    @Input() reportName;
    @Input() reportId;
    @Input() height;
    @Input() width;

    reportUrl = '';
    hostUrl = 'http://localhost:53007';
    invokeAction = "/DXXRDV";
    loading = true;
    _height = "";
    _width = "";
    sizeType = "%";

    constructor() { }

    ngOnInit() {

        console.log(this.height + "," + this.width + " ")
        this._height = this.height;
        this._width = this.width;
        let requestString = "{" + this.reportId + "}" + this.reportName;
        console.log(requestString)
        this.reportUrl = requestString;
        this.loading = false;
    }

    showToast(message, status) {
        notify(message, status, 3000);
    }

}
