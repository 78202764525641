import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AppInfoService } from '../../../shared/services';
import { ComponentService } from '../../component.service';
import { DxTreeViewComponent } from 'devextreme-angular';

import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';

@Component({
    selector: 'permission-component',
    templateUrl: './permission.component.html',
    styleUrls: ['./permission.component.css']
})
export class DxPermissionComponent implements OnInit, OnDestroy {
    @ViewChild(DxTreeViewComponent, { static: false }) treeView;
    @Input() permissions: any;
    @Input() componenttype: any;
    languageLocal: any = ""; 
    groups: any = [];
    users: any = [];
    constructor(public appInfo: AppInfoService, private componentService: ComponentService, public changeDetectorRef: ChangeDetectorRef) {
        this.languageLocal = this.componentService.getLanguageSource(); //language
}

    hideDeleteButtonPermission = true;
    ngOnInit() {
        debugger;
        if (this.componenttype == "imageupload" || this.componenttype == "picturebox" || this.componenttype == "videoupload" || this.componenttype == "fileupload")
            this.hideDeleteButtonPermission = false; 
        if (typeof this.permissions.deleteHideGroup == "undefined") this.permissions.deleteHideGroup = [];
        if (typeof this.permissions.deleteHideUsers == "undefined") this.permissions.deleteHideUsers = [];

        this.groups = this.appInfo.RiverEntity.userGroups;
        this.users = this.appInfo.RiverEntity.users;
        this.checkUserAndGroup();
    }

    ngOnDestroy() { 
    }

    checkUserAndGroup() {
        this.users.forEach(user => {
            user.readonly = this.permissions.readonlyUsers.indexOf(user.id) !== -1;
            user.hide = this.permissions.hideUsers.indexOf(user.id) !== -1;
            user.deleteHide = this.permissions.deleteHideUsers.indexOf(user.id) !== -1;
        }); 

        this.groups.forEach(group => {
            let rGroup = this.permissions.readonlyGroup.find(f => f.id == group.id);
            let hGroup = this.permissions.hideGroup.find(f => f.id == group.id);
            let dhGroup = this.permissions.deleteHideGroup.find(f => f.id == group.id);
            group.readonly = typeof (rGroup) != "undefined" && rGroup.id > 0;
            group.hide = typeof (hGroup) != "undefined" && hGroup.id > 0;
            group.deleteHide = typeof (dhGroup) != "undefined" && dhGroup.id > 0;
        });
    }

    checkSelections() {
        for (let i = 0; i < this.users.length; i++) {
            let rUsersIndex = this.permissions.readonlyUsers.indexOf(this.users[i].id);
            let hUsersIndex = this.permissions.hideUsers.indexOf(this.users[i].id);
            //this.users[i].hide = hGroupIndex != -1 || hUsersIndex != -1;
            //this.users[i].readonly = rGroupIndex != -1 || rUsersIndex != -1;
        }
    }

    changeReadonly(isChecked, id, isGroup) {
        if (isChecked) {
            this.addReadonlyList(id, isGroup);
        }
        else {
            this.removeReadonlyList(id, isGroup);
        }
 
    }

    changeHide(isChecked, id, isGroup) {
        if (isChecked) {
            this.addHideList(id, isGroup);
        }
        else {
            this.removeHideList(id, isGroup);
        }
    }

    changeDeleteButtonHide(isChecked, id, isGroup) {
        if (isChecked) {
            this.addDeleteButtonHideList(id, isGroup);
        }
        else {
            this.removeDeleteButtonHideList(id, isGroup);
        }
    }

    deleteButtonHideGroupAll = false;
    deleteButtonHideUserAll = false;
    readonlyGroupAll = false;
    hidingGroupAll = false;
    readonlyUserAll = false;
    hidingUserAll = false;
    readonlyChangeAll(isChecked, isGroup) {

        if (isGroup) {
            this.groups.forEach(group => {
                if (isChecked) {
                    this.readonlyGroupAll = true;
                    this.addReadonlyList(group.id, true);
                }
                else {
                    this.readonlyGroupAll = false;
                    this.removeReadonlyList(group.id, true);
                }
                try { this.changeDetectorRef.detectChanges(); } catch (e) { }
            });
        }
        else {
            this.users.forEach(user => {
                if (isChecked) {
                    this.readonlyUserAll = true;
                    this.addReadonlyList(user.id, false);
                }
                else {
                    this.readonlyUserAll = false;
                    this.removeReadonlyList(user.id, false);
                }
                try { this.changeDetectorRef.detectChanges(); } catch (e) { }
            });
        }

        this.checkUserAndGroup();
        // try { this.changeDetectorRef.detectChanges(); } catch (e) { }
       
    }
    
    deleteButtonHideChangeAll(isChecked, isGroup) {
       
        if (isGroup) {
            this.groups.forEach(group => {
                if (isChecked) {
                    this.deleteButtonHideGroupAll = true;
                    this.addDeleteButtonHideList(group.id, true);
                }  
                else {
                    this.deleteButtonHideGroupAll = false;
                    this.removeDeleteButtonHideList(group.id, true);
                }
                try { this.changeDetectorRef.detectChanges(); } catch (e) { }
            });
        }
        else {
            this.users.forEach(user => {
                if (isChecked) {
                    this.deleteButtonHideUserAll = true;
                    this.addDeleteButtonHideList(user.id, false);
                }
                else {
                    this.deleteButtonHideUserAll = false;
                    this.removeDeleteButtonHideList(user.id, false);
                }
                try { this.changeDetectorRef.detectChanges(); } catch (e) { }
            });
        }

        this.checkUserAndGroup();
    }
    hidingChangeAll(isChecked, isGroup) {
        if (isGroup) {
            this.groups.forEach(group => {
                if (isChecked) {
                    this.hidingGroupAll = true;
                    this.addHideList(group.id, true);
                }
                else {
                    this.hidingGroupAll = false;
                    this.removeHideList(group.id, true);
                }
                try { this.changeDetectorRef.detectChanges(); } catch (e) { }
            });
        }
        else {
            this.users.forEach(user => {
                if (isChecked) {
                    this.hidingUserAll = true;
                    this.addHideList(user.id, false);
                }
                else {
                    this.hidingUserAll = false;
                    this.removeHideList(user.id, false);
                }
                try { this.changeDetectorRef.detectChanges(); } catch (e) { }
            });
        }

        this.checkUserAndGroup();
    }

    addReadonlyList(id, isGroup) {
        if (isGroup) {
            let extGroup = this.permissions.readonlyGroup.find(f => f.id == id);
            let group = this.groups.find(f => f.id == id);
            if (group && !extGroup) {
                let grp: any = {};
                grp.id = id;
                grp.users = [];
                if (group.users && group.users.length > 0) {
                    let sp = group.users.split(",");
                    sp.forEach(f => {
                        if (f) grp.users.push(parseInt(f));
                    });
                }
                this.permissions.readonlyGroup.push(grp);
            }
        }
        else {
            let extUser = this.permissions.readonlyUsers.find(f => f == id);
            if (!extUser)
                this.permissions.readonlyUsers.push(id);
        }
    }

    removeReadonlyList(id, isGroup) {
        if (isGroup && this.permissions.readonlyGroup.length) {
            let extGroup = this.permissions.readonlyGroup.find(f => f.id == id);
            if (extGroup) {
                this.permissions.readonlyGroup.splice(this.permissions.readonlyGroup.indexOf(extGroup), 1);
            }
        }
        else if (this.permissions.readonlyUsers.length) {
            let _in = this.permissions.readonlyUsers.indexOf(id);
            if (_in != -1)
                this.permissions.readonlyUsers.splice(_in, 1);
        }
    }

    addDeleteButtonHideList(id, isGroup) {
        if (isGroup) {
            let extGroup = this.permissions.deleteHideGroup.find(f => f.id == id);
            let group = this.groups.find(f => f.id == id);
            if (group && !extGroup) {
                let grp: any = {};
                grp.id = id;
                grp.users = [];
                if (group.users && group.users.length > 0) {
                    let sp = group.users.split(",");
                    sp.forEach(f => {
                        if (f) grp.users.push(parseInt(f));
                    });
                }
                this.permissions.deleteHideGroup.push(grp);
            }
        }
        else {
            let extUser = this.permissions.deleteHideUsers.find(f => f == id);
            if (!extUser)
                this.permissions.deleteHideUsers.push(id);
        }
    }

    removeDeleteButtonHideList(id, isGroup) {
        if (isGroup && this.permissions.deleteHideGroup.length) {
            let extGroup = this.permissions.deleteHideGroup.find(f => f.id == id);
            if (extGroup) {
                this.permissions.deleteHideGroup.splice(this.permissions.deleteHideGroup.indexOf(extGroup), 1);
            }
        }
        else if (this.permissions.deleteHideUsers.length) {
            let _in = this.permissions.deleteHideUsers.indexOf(id);
            if (_in != -1)
                this.permissions.deleteHideUsers.splice(_in, 1);
        }
    }

    addHideList(id, isGroup) {
        if (isGroup) {
            let extGroup = this.permissions.hideGroup.find(f => f.id == id);
            let group = this.groups.find(f => f.id == id);
            if (group && !extGroup) {
                let grp: any = {};
                grp.id = id;
                grp.users = [];
                if (group.users && group.users.length > 0) {
                    let sp = group.users.split(",");
                    sp.forEach(f => {
                        if (f) grp.users.push(parseInt(f));
                    });
                }
                this.permissions.hideGroup.push(grp);
            }
        }
        else {
            let extUser = this.permissions.hideUsers.find(f => f == id);
            if (!extUser)
                this.permissions.hideUsers.push(id);
        }
    }

    removeHideList(id, isGroup) {
        if (isGroup && this.permissions.hideGroup.length) {
            let extGroup = this.permissions.hideGroup.find(f => f.id == id);
            if (extGroup) {
                this.permissions.hideGroup.splice(this.permissions.hideGroup.indexOf(extGroup), 1);
            }
        }
        else if (this.permissions.hideUsers.length) {
            let _in = this.permissions.hideUsers.indexOf(id);
            if (_in != -1)
                this.permissions.hideUsers.splice(_in, 1);
        }
    }


}
