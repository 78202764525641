import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { DxDataGridComponent, DxRadioGroupComponent } from 'devextreme-angular';
import { AppInfoService, GenericService } from '../../shared/services';

export class SqlScriptRadioGroupModel {
    id: number;
    connectionId: number;
    elementId: string;
    query: string;
}

@Component({
    selector: 'app-radiogroup',
    templateUrl: './radiogroup.component.html',
    styleUrls: ['./radiogroup.component.css']
})
export class DxRadiogroupComponent implements ComponentParameters {
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;
    @Input() anotherPopupId: number = 0;
    @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
    @ViewChild("RadioGroupComponent", { static: false }) radioGroupEtr: DxRadioGroupComponent;

    showDisplay: any = "flex";
    showDisplayBoolean = false;

    layouts = [{ "name": "Yatay", "value": "horizontal" }, { "name": "Dikey", "format": "vertical" }];

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    showItemsPopup: boolean = false;
    languageLocal: any = "";
    selectedRowLanguage: any;
    editorOptions = { theme: 'vs-dark', language: 'dummy' };
    tempMonacoText = {
        enable: "",
        disable: "",
        invisible: "",
        visible: ""
    }
    showEditorPopup: boolean = false;
    registeredLanguage: any;
    isShow = false;
    languageTextPopup: boolean = false;
    isMonacoLoaded: boolean = false;
    connections: any = [];

    valueExpressions = [{ value: "text", name: "Text" }, { value: "value", name: "Value" }]

    constructor(private eventEmitterService: EventEmitterService, public componentService: ComponentService, public changeDetectorRef: ChangeDetectorRef, private appInfoService: AppInfoService, private genericService: GenericService) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
        this.languageLocal = this.componentService.getLanguageSource(); //language
        this.setLanguageToDatas()

    }

    ngOnInit() {
        this.connections = this.appInfoService.RiverEntity.connections;
        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

        this.data.showLabel = typeof this.data.showLabel == "undefined" ? false : this.data.showLabel;
        this.data.valueExpr = typeof this.data.valueExpr == "undefined" ? "text" : this.data.valueExpr;

        this.data.sqlScripts = typeof this.data.sqlScripts == "undefined" ? [] : this.data.sqlScripts;

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
           
            if (sub.data.key === this.data.uuid) {
                console.log(sub)
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;
                 
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }
        });

        let _val = this.data.value;
        if (_val) {
            //if (_val.__KEY__) {
            //    this.data.value = this.data.items.find(x => x.__KEY__ == _val.__KEY__);
            //}
            //else {
            
            this.data.value = this.data.items.find(x => x[this.data.valueExpr] == _val[this.data.valueExpr]);

            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            // }
        }
        if (typeof this.data.items.language == "undefined") {
            this.data.items.language = {
                "ENEN": "",
                "TRTR": "",
            }
        } else {
            this.data.items.forEach(el => {
                let find = el.language.find(f => f.Code.toLowerCase() == this.lang.toLowerCase())
                if (typeof find != "undefined" && typeof find.Title != "undefined" && find.Title != "") {
                    this.data.items.id = ""
                    this.data.items.text = find.Title
                }
            })
        }
        console.log(this.data.value)
    }

    ngAfterViewInit() {
        console.log(this.radioGroupEtr)
    }

    monacoOnInit() {
        this.isMonacoLoaded = true;
        let monaco = window['monaco'];
        this.setCustomKeywords(monaco);
    }


    disableElements() {
        return typeof this.data.disabled != "undefined" ? (this.data.disabled == false ? this.data.outsideDisabled : this.data.disabled) : false;
    }


    formElements: any;
    sqlScriptModel: SqlScriptRadioGroupModel;
    showChangeEventPopup: boolean = false;

    openChangeEventPopup() {
        debugger;
        this.isMonacoLoaded = false;
        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        let elements = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId).map(m => m.id);
        this.formElements = elements.filter(f => f != this.data.id);
        this.sqlScriptModel = new SqlScriptRadioGroupModel();
        this.sqlScriptModel.query = 'SELECT * FROM TABLE WHERE KEY={' + this.data.id + '}';
        this.showChangeEventPopup = true;
    }

    saveSqlScript() {
        let model = this.componentService.cloneModel(this.sqlScriptModel);

        if (model.id > 0) {
            let scr = this.data.sqlScripts.find(f => f.id == model.id);
            scr.connectionId = model.connectionId;
            scr.elementId = model.elementId;
            scr.query = model.query;
        }
        else {
            model.id = this.data.sqlScripts.length + 1;
            this.data.sqlScripts.push(model);
        }

        this.sqlScriptModel = new SqlScriptRadioGroupModel();
        this.sqlScriptModel.query = 'SELECT * FROM TABLE WHERE KEY={' + this.data.id + '}';
    }

    updateSqlScript(i) {
        let scr = this.data.sqlScripts[i];
        this.sqlScriptModel = new SqlScriptRadioGroupModel();
        this.sqlScriptModel.id = scr.id;
        this.sqlScriptModel.connectionId = scr.connectionId;
        this.sqlScriptModel.elementId = scr.elementId;
        this.sqlScriptModel.query = scr.query;
    }

    deleteSqlScript(i) {
        this.data.sqlScripts.splice(i, 1);
    }

    executeScripts() {
        if (!this.isEdit) {
            this.data.sqlScripts.forEach(s => {

                let pref = "{" + this.data.id + "}";
                let value = this.data.value[this.data.valueExpr];
                let query = this.componentService.replaceAllUsingType(s.query, pref, value); // s.query.replace(pref, value);

                let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
                let currentComponents = this.componentService.getComponentList(currentFormSelected.components, this.anotherPopupId)

                var pattern = /\{(\w)+\}/ig;
                var patt = new RegExp(pattern);
                if (patt.test(query)) {
                    let _match = query.match(pattern);
                    if (_match.length > 0) {
                        _match.forEach(f => {
                            let _k = currentComponents.find(k => k.id == f.replace("{", "").replace("}", ""));
                            if (_k) {
                                let _value = _k.value == null ? "" : _k.value;
                                query = this.componentService.replaceAllUsingType(query, f, _value);//query.replace(f, _value);
                            }
                        });
                    }
                    //query = this.genericService.cleanSqlSyntax(query.replace(pattern, ""))
                }
                console.log(query)
                this.genericService.executeQuerySecurited(query, s.connectionId).subscribe((result: any) => {

                    if (result) {
                        let element = this.componentService.getById(s.elementId, currentComponents, this.anotherPopupId);
                        if (element.type) {

                            if (element.component == "DxDatagridSelectComponent" || element.type == "datalist" || element.type == "selectbox" || element.type == "gallery" || element.type == "datagrid" || element.type == "multiselectbox") {
                                this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, this.data.value, result);
                            }
                            else {
                                let keys = Object.keys(result[0]);
                                if (keys.length > 0) {
                                    let finalData = [];
                                    keys.forEach(f => {
                                        finalData.push(result[0][f]);
                                    });
                                    let dataText = finalData.join(",");
                                    this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, this.data.value, dataText);
                                }
                            }
                        }
                    } else {
                        console.log("empty")
                    }
                });
            });
        }
    }

    onValueChanged(e) {
        if(e.value == null) e.value = ""
        if (e.value != "") {

            this.data.value = this.data.items.find(x => x[this.data.valueExpr] == (typeof e.value[this.data.valueExpr] == "undefined" ? (e.value) : (e.value[this.data.valueExpr])));

            this.executeScripts();
            let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
            let componentList = this.componentService.getAllComponentList(currentFormSelected.components, this.anotherPopupId);

            // On Disable
            if (typeof e.value.disable != "undefined" && e.value.disable != "") {
                e.value.disable.split(",").forEach(f => {
                    let _k = componentList.find(k => k.id == f.replace("{", "").replace("}", ""));
                    if (typeof _k != "undefined") {
                        _k.outsideDisabled = true;
                        //let _sub = { key: _k.uuid, outsideDisabled: true };
                        //this.eventEmitterService.ChangeDisableAndVisible(_sub);
                    }
                });
            }

            // On Enable
            if (typeof e.value.enable != "undefined" && e.value.enable != "") {////
                e.value.enable.split(",").forEach(f => {
                    let _k = componentList.find(k => k.id == f.replace("{", "").replace("}", ""));
                    if (typeof _k != "undefined") {
                        _k.outsideDisabled = false;
                        //let _sub = { key: _k.uuid, outsideDisabled: false };
                        //this.eventEmitterService.ChangeDisableAndVisible(_sub);
                    } 
                });
            }

            // On Visible
            if (typeof e.value.visible != "undefined" && e.value.visible != "") {//////
                e.value.visible.split(",").forEach(f => {
                    f = f.replace("{", "").replace("}", "")
                    let button = [] // Button name list
                    if (f.includes(";")) {

                        let temp = f.split(";")[1]
                        if (temp.includes(":")) {
                            button = temp.split(":")
                        } else {
                            button.push(temp)
                        }
                        f = f.split(";")[0]
                    }
                    let _k = componentList.find(k => k.id == f.replace("{", "").replace("}", ""));
                    if (typeof _k != "undefined") {
                        _k.outsideVisible = true;
                        let _sub = { key: _k.uuid, outsideVisible: true, hideButtonList: [] };
                        this.eventEmitterService.ChangeVisible(_sub);
                    }  
                });
            }

            // On Invisible
            if (typeof e.value.invisible != "undefined" && e.value.invisible != "") {
                e.value.invisible.split(",").forEach(f => {
                    f = f.replace("{", "").replace("}", "")
                    let button = [] // Button name list
                    if (f.includes(";")) {

                        let temp = f.split(";")[1]
                        if (temp.includes(":")) {
                            button = temp.split(":")
                        } else {
                            button.push(temp)
                        }
                        f = f.split(";")[0]
                    }

                    let _k = componentList.find(k => k.id == f.replace("{", "").replace("}", ""));
                    if (typeof _k != "undefined") {
                        _k.outsideVisible = false;
                        let _sub = { key: _k.uuid, outsideVisible: false };
                        if (button.length > 0) {
                            _sub.outsideVisible = true
                            _sub["hideButtonList"] = button
                        }

                        this.eventEmitterService.ChangeVisible(_sub);
                    }  
                });
            }
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        } 
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid, this.anotherPopupId);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert(this.languageLocal.alertDifferentID, "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
        
    }


    cancelSetting() {
        let msg = "<i>" + this.languageLocal.messageUnsaved + "</i>"
        let result = confirm(msg, this.languageLocal.warning);
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }
    setLanguageToDatas() {
        this.layouts = [
            { "name": this.languageLocal.horizontal /*"Yatay"*/, "value": "horizontal" },
            { "name": this.languageLocal.vertical /* "Dikey"*/, "format": "vertical" }
        ];
    }
    onKeyDownObjectID($event) {
        let keyCode = $event.event.keyCode;
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105) || (keyCode >= 186 && keyCode <= 226) || (keyCode >= 169 && keyCode <= 171) || (keyCode <= 173)) { // T�m charlar girecek
            let regex = /([A-Za-z0-9\*\_\-\&\.\+\"\'\!\?\,\;\%\$\#])/g
            let test = regex.test($event.event.key);
            if (!test) {
                $event.event.preventDefault();
            }
        }
    }
    addLanguage(data) {
        this.selectedRowLanguage = data.row.data.language;
        this.languageTextPopup = true;
        console.log("selected",this.selectedRowLanguage)
        console.log("data lang",data)

    }

    saveLanguagePopup() {
      
        this.languageTextPopup = false;
        this.data.items.forEach(el => {
        let find = el.language.find(f => f.Code.toLowerCase() == this.lang.toLowerCase())
        if (typeof find != "undefined" && typeof find.Title != "undefined" && find.Title != "") {
            el.text = find.Title
            }
        })
    }

    addRow() {
        this.data.items.push({ text: "New " })
        if (this.data.items.length > 0) {
            this.data.items.forEach(el => {
                if (typeof el.text == "undefined") {
                    el.text = el.Title
                }
                if (typeof el.language == "undefined") {
                    el.language = [
                        {
                            "Code": "trTR",
                            "Title": el.text
                        }
                    ]
                }
                console.log(el)
            })
            this.data.items.forEach(el => {
                let find = el.language.find(f => f.Code == this.lang)
                if (typeof find != "undefined" && typeof find.Title != "undefined" && find.Title != "") {
                    el.text = find.Title
                }
            })
        }
    }
    openAddRadioPopup() {
       
        this.showItemsPopup = true
        
    }
    tempKey = null;

   /* onSelectCase(buttonData, _field) {
       
        //console.log("button data: "+buttonData)
        var finditem = this.data.items.find(el => el == buttonData.key)
        this.tempKey = buttonData.key
        console.log(finditem)
        this.showEditorPopup = true
        this.activeCase = _field;
        this.tempMonacoText['active'] = typeof finditem['active'] == "undefined" ? ("") : (finditem['active'])

        
        
    }*/

    resetRadioGroupValue() {
        this.radioGroupEtr.instance.reset();
        this.data.value = "";
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }

    setCustomKeywords(monaco) {

        let currentFormSelected = this.componentService.currentFormSelected(this.anotherPopupId);
        let elements = this.componentService.getComponentListWithButton(currentFormSelected.components, this.anotherPopupId).map(m => m.id);

        if (this.registeredLanguage)
            this.registeredLanguage.dispose();

        this.registeredLanguage = monaco.languages.registerCompletionItemProvider('plaintext', {
            provideCompletionItems: () => {
                var suggestions = [];

                for (let i = 0; i < elements.length; i++) {
                    suggestions.push({
                        label: elements[i],
                        kind: monaco.languages.CompletionItemKind.Snippet,
                        insertText: "{" + elements[i] + "}",
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet
                    })
                }

                return { suggestions: suggestions };
            }
        });

    }

    saveMonaco(_value) {

        debugger
        var finditemIndex = this.data.items.findIndex(el => el == this.tempKey)
        if (finditemIndex != -1) {
            this.data.items[finditemIndex]['enable'] = _value['enable'] == "" ? undefined : _value['enable']
            this.data.items[finditemIndex]['disable'] = _value['disable'] == "" ? undefined : _value['disable']
            this.data.items[finditemIndex]['invisible'] = _value['invisible'] == "" ? undefined : _value['invisible']
            this.data.items[finditemIndex]['visible'] = _value['visible'] == "" ? undefined : _value['visible']
        }
       
        this.showEditorPopup = false;
        this.tempMonacoText = {
            enable: "",
            disable: "",
            invisible: "",
            visible: ""
        };
        this.tempKey = null;
    }

    exitMonaco() {
        this.tempMonacoText = {
            enable: "",
            disable: "",
            invisible: "",
            visible: ""
        }
        this.showEditorPopup = false;
    }

    toggleDisplay(buttonData) {
        var finditem = this.data.items.find(el => el == buttonData.key)
        if (!!finditem) {
            this.tempKey = buttonData.key
            this.showEditorPopup = true;
            //this.tempMonacoText = nullde olur {}
            this.tempMonacoText['enable'] = typeof finditem['enable'] == "undefined" ? ("") : (finditem['enable'])
            this.tempMonacoText['disable'] = typeof finditem['disable'] == "undefined" ? ("") : (finditem['disable'])
            this.tempMonacoText['visible'] = typeof finditem['visible'] == "undefined" ? ("") : (finditem['visible'])
            this.tempMonacoText['invisible'] = typeof finditem['invisible'] == "undefined" ? ("") : (finditem['invisible'])
        }

    }

}
