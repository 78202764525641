import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

    transform(value: any, args?: any, filterMetadata?: any): any {

        if (!value) return null;
        if (!args) return value;

        let returnList = value

        let queryObject = JSON.parse(args)
        let objectKeys = Object.keys(queryObject)

        objectKeys.forEach(key => {
            if (queryObject[key].value != "") {
                
                returnList = returnList.filter(function (data) {
                    
                    return returnList = JSON.stringify(data[key]).toString().toLowerCase().includes(queryObject[key].value.toLowerCase());
                });

                let AllArray = this.fixTurkishLetter(value,queryObject[key].value)
                AllArray.forEach(element => {
                    if (!returnList.includes(element)) {
                        returnList.push(element)
                    }
                })

                return returnList
                
            }
        })

        filterMetadata.count = returnList.length;

        return returnList;     
       
    }

    fixTurkishLetter(value,stringValue) {

        let AllArray = []

        // Big I
        if (stringValue.includes("I")) {
            let turkish = stringValue.toLowerCase();

            turkish = turkish.replace(/[\i]+/g, "ı")

            const turkishArray = value.filter(function (data) {
                return JSON.stringify(data).toString().toLowerCase().includes(turkish);
            });

            AllArray = AllArray.concat(turkishArray)
        }

        // Big İ char ignored
        if (stringValue.includes("İ")) {
            let turkish2 = stringValue.toLowerCase();

            turkish2 = turkish2.normalize('NFD').replace(/[\u0300-\u036f]/g, "")

            const turkishArray2 = value.filter(function (data) {
                return JSON.stringify(data).toString().toLowerCase().includes(turkish2);
            });

            AllArray = AllArray.concat(turkishArray2)
        }

        // Small ı gerekli mi kontrol et
        if (stringValue.includes("ı")) {
            let turkish3 = stringValue.toLowerCase();
            
            turkish3 = turkish3.replace(/[\ı]+/g, "ı")
            

            const turkishArray3 = value.filter(function (data) {
                return JSON.stringify(data).toString().toLowerCase().includes(turkish3);
            });

            AllArray = AllArray.concat(turkishArray3)
        }

        return AllArray
    }

}